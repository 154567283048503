import { PropertyInfoCollector } from "./PropertyInfoCollector";
import { PropertyData, ReadyData } from "./PropertyInfoCollectorTypes";
import "../css/propertyInfoStyle.css";
import {
   GlassFoldUdBox,
   GroupSelector,
   KeyValuesBoxGrid,
   KeyValuesHorizontal,
   ShowSelectedGroup,
} from "./PropertyComponents";
import { PropInfoGroupFormatters } from "./PropertyInfoGroupFormatters";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import { Localization, SettingsManager } from "@viamap/viamap2-common";
import { MitStorage } from "src/managers/MitStorage";
import { BsGearFill } from "react-icons/bs";
import { FavEditor } from "./FavEditor";
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import { GlassButton } from "src/components/GlassButtons";
import { useContext, useState, useEffect } from "react";

type Props = {
   callBackOnLoaded?: (data:PropertyData) => void;
   bfeNr?: string | number
   allowExtendedInformation: boolean;
   foldetUd?: boolean;
   cols?: number;
};

export function CondominiumView(props: Props) {
   const { hasAccessToFeature } = useContext(ApplicationStateContext);
   const [collector] = useState(
      new PropertyInfoCollector({  
         BFEnr: Number(props.bfeNr)
      })
   );
   
   function setSelectedGroup(string) {
      MitStorage.setValue("CondominiumSelectedTab",string)
      _setSelectedGroup(string)
   }
   
   const [selectedGroup, _setSelectedGroup] = useState(
      MitStorage.getValue("CondominiumSelectedTab") || "Ejerlejlighed"
   );
   const [availableFavorites, _setAvailableFavorites] = useState<{[fav:string]:string[]}>(
      MitStorage.getValue("PropertyFavorites") as {[fav:string]:string[]} || {"minFavorit":[]}
   )
   
   function setAvailableFavorites(res) {
      MitStorage.setValue("PropertyFavorites",res)
      _setAvailableFavorites(res)
   }
   
   
   const [selectFav, setSelectedFav] = useState<string>(
      MitStorage.getValue("PropertySelectedFav") as string || "minFavorit"
   )
   
   const [showFavEditor, setShowFavEditor] = useState(false);
   
   function setFavorite(key:string, Folds: string[]) {
      const aFav = availableFavorites
      let res = {...aFav}
      if (Folds.length == 0) {
         if (Object.keys(aFav).includes(key)) {
            delete res[key]
         } else {
            res = {...res, [key]:[]}
         }
      } else {
         res = {...res, [key]:Folds}
      }
      MitStorage.setValue("PropertyFavorites",res)
      _setAvailableFavorites(res)
   }
   
   
   const [availableGroup, setAvailableGroup] = useState(hasAccessToFeature(Feature.PropertyInfoFavorite) ? ["Favoritter"] : []);
   const [data, setData] = useState<Partial<PropertyData>>({});
   const [loading, setLoading] = useState(true)
   let [select, setSelect] = useState("Kontor");
   
   
   useEffect(() => {
      getDataAsync();
   }, [hasAccessToFeature]);
   
   function getDataCache() {
      import("./TestData.json").then((a) => {
         setData(a.default as any as PropertyData);
      });
   }
   function getDataAsync() {
      function setOnCallback<T extends keyof PropertyData>(
         val: ReadyData<PropertyData[T]>,
         key: T
      ) {
         if (val.status == "Ready" && val.data) {
            setData((a) => {
               return { ...a, [key]: val.data };
            });
         }
      }
      if (props.allowExtendedInformation) {
         // collector.requestData("ViamapTingBog", setOnCallback);
      }
      collector.requestData("Vurd", setOnCallback);
      collector.requestData("BBRRel", setOnCallback);
      collector.requestData("BBRBFETeknik", setOnCallback);
      collector.addRequest("MatEjerlejlighed", {
         identDependency: "BFEnr",
         paramTransformer(ident, data) {
            if (ident["BFEnr"]) {return [ident["BFEnr"]] }
            return []
          },
          func: PropertyInfoInterface.EjerlejlighederEnkelt,
          hasThis: PropertyInfoInterface,
          returnTransformer(a) {
            return a.features[0].properties
          }
         }, (setOnCallback as any))
      collector.addRequest("BBRBFEEnhed", {
         identDependency: "BFEnr",
         paramTransformer(ident, data) {
            if (ident["BFEnr"]) {return [ident["BFEnr"]] }
            return []
         },
         func: PropertyInfoInterface.BBREnhedForBFE,
         hasThis: PropertyInfoInterface,
         returnTransformer(a) {
            return a
         }
      }, (setOnCallback as any))
      collector.addRequest("BBRBFEBygning", {
         identDependency: "BFEnr",
         paramTransformer(ident, data) {
            if (ident["BFEnr"]) {return [ident["BFEnr"]] }
            return []
            },
            func: PropertyInfoInterface.BBRBygningForBFE,
            hasThis: PropertyInfoInterface,
            returnTransformer(a) {
            return a
            }
         }, (setOnCallback as any))
      
         
      collector.addRequest("ESEjerlejlighed", {  
      identDependency: "BFEnr",
      paramTransformer(ident, data) {
         if (ident["BFEnr"]) {return [ident["BFEnr"]] }
         return []
       },
       func: PropertyInfoInterface.ejerAfEjerlejlighed,
       returnTransformer(a) {
         return a[0]
       }
      }, (setOnCallback as any)
      )
      collector.callBackOnFinished((data:PropertyData) => {
         props.callBackOnLoaded?.(data);
         setLoading(false)
      });
   }
   
   if (Object.keys(data).length === 0) {
      return <>Loading... {props.bfeNr}</>;
   }
   
   function PrintHeader() {
      let printDate = new Date().toLocaleDateString();
      
      return (
         <div className="only-print print-header" style={{}}>
         <div style={{ display: "inline-block" }}>
         <h4>Ejerlejlighed</h4>
         <h5>Printed: {printDate}</h5>
         </div>
         {/* <div style={{float:"right", marginRight:"25px"}}> <div className={"mit-viamap-icon"} id={"mit-viamap-icon"}/></div> */}
         </div>
      );
   }
   
   return (
      <>
      <PrintHeader />
      
      {SettingsManager.getSystemSetting("debuggingShowExtraDebugging") ?
      <GlassButton onClick={() => console.info(data)} >Log to console</GlassButton>
      : null}
      
      {/* ----------- AREAL ------------ */}
      <KeyValuesHorizontal>
      {PropInfoGroupFormatters.EjerLejligheden(data["ESEjerlejlighed"],data["MatEjerlejlighed"])}
      </KeyValuesHorizontal>
      <GroupSelector
      selected={selectedGroup}
      group={availableGroup}
      onSelect={setSelectedGroup}
      />
      {selectedGroup == "Favoritter" ?
      <>
      <div style={{marginLeft:"auto"}}>
      <GlassButton onClick={() => setShowFavEditor(true)} ><BsGearFill /> {Localization.getText("Edit")}</GlassButton>
      </div>
      <FavEditor show={showFavEditor} favBoxes={availableFavorites} setFavBoxes={setAvailableFavorites} onClose={() => setShowFavEditor(false)} />
      </>
      : null}
      <div className="Scroll-content">
      <ShowSelectedGroup
      keepSelected={true}
      loading={loading}
      columns={props.cols!}
      group={selectedGroup}
      groupCallBack={(groups) =>
         setAvailableGroup([...new Set(["Ejerlejlighed",...groups, "Vurderinger","Alle"])])
      }
      >
      
      {data["MatEjerlejlighed"] ? (
         <GlassFoldUdBox
         title="Arealer"
         boxKey="Arealer"
         group="Ejerlejlighed"
         foldetUd={props.foldetUd || false}
         >
         <KeyValuesBoxGrid minWidth="200px" maxCols={2}>
         {PropInfoGroupFormatters.LejAreal(data["MatEjerlejlighed"])}
         </KeyValuesBoxGrid>
         </GlassFoldUdBox>
      ) : (
         <></>
      )}

{data["ESEjerlejlighed"] ? (
         <GlassFoldUdBox
         title="Handelsoplysninger"
         boxKey="Handelsoplysninger"
         group="Ejerlejlighed"
         foldetUd={props.foldetUd || false}
         >
         {/* <KeyValuesBoxGrid minWidth="200px" maxCols={2}> */}
         {PropInfoGroupFormatters.LejHandel(data["ESEjerlejlighed"])}
         {/* </KeyValuesBoxGrid> */}
         </GlassFoldUdBox>
      ) : (
         <></>
      )}

   {data["ESEjerlejlighed"] ? (
      <GlassFoldUdBox
      title="Ejerforhold"
      group="Ejerlejlighed"
      boxKey="Ejerforhold"
      foldetUd={props.foldetUd || false}
      >
      {PropInfoGroupFormatters.EjerLejEjer(data["ESEjerlejlighed"])}
      </GlassFoldUdBox>
   ) : (
      <></>
   )}
      {data.BBRSFE ? (
         <GlassFoldUdBox
         title="Samlet Fast Ejendom"
         group="Ejerlejlighed"
         boxKey="Samlet Fast Ejendom"
         foldetUd={props.foldetUd || false}
         >
         {PropInfoGroupFormatters.SFE(data)}
         </GlassFoldUdBox>
      ) : (
         <></>
      )}   
      {"BBRBFEBygning" in data && (data.BBRBFEBygning as any)?.length ? (
         <GlassFoldUdBox 
            title={"BBR Bygning"}
            group="BBR"
            foldetUd={props.foldetUd || false}
         >
            {PropInfoGroupFormatters.BBREvery(data.BBRBFEBygning)}
         </GlassFoldUdBox>) : <></>} 
      {"BBRBFEEnhed" in data && (data.BBRBFEEnhed as any)?.length ? (
         <GlassFoldUdBox 
            title={"BBR Enhed"}
            group="BBR"
            foldetUd={props.foldetUd || false}
         >
            {PropInfoGroupFormatters.BBREvery(data.BBRBFEEnhed)}
         </GlassFoldUdBox>) : <></>} 
      {"BBRRel" in data && (data.BBRRel as any)?.length ? (
         <GlassFoldUdBox 
            title={"BBR Relation"}
            group="BBR"
            foldetUd={props.foldetUd || false}
         >
            {PropInfoGroupFormatters.BBREvery(data.BBRRel)}
         </GlassFoldUdBox>) : <></>} 
      {"BBRBFETeknik" in data && (data.BBRBFETeknik as any)?.length ? (
         <GlassFoldUdBox 
            title={"BBR Teknik"}
            group="BBR"
            foldetUd={props.foldetUd || false}
         >
            {PropInfoGroupFormatters.BBREvery(data.BBRBFETeknik)}
         </GlassFoldUdBox>) : <></>} 
      

      {data.ViamapTingBog ? (
         <GlassFoldUdBox
         group="Tingbog"
         title="Tingbog - Adkomster"
         boxKey="Tingbog - Adkomster"
         foldetUd={props.foldetUd || false}
         >
         {PropInfoGroupFormatters.TingbogAdkomster(data)}
         </GlassFoldUdBox>
      ) : (
         <></>
      )}
      {data.ViamapTingBog ? (
         <GlassFoldUdBox
         group="Tingbog"
         title="Tingbog - Hæftelser"
         boxKey="Tingbog - Hæftelser"
         foldetUd={props.foldetUd || false}
         >
         {PropInfoGroupFormatters.TingbogHæftelser(data)}
         </GlassFoldUdBox>
      ) : (
         <></>
      )}
      {data.ViamapTingBog ? (
         <GlassFoldUdBox
         group="Tingbog"
         title="Tingbog - Servitutter"
         boxKey="Tingbog - Servitutter"
         foldetUd={props.foldetUd || false}
         >
         {PropInfoGroupFormatters.TingbogServitutter(
            data,
            hasAccessToFeature
         )}
         </GlassFoldUdBox>
      ) : (
         <></>
      )}
            {data.ViamapTingBog ? (
               <GlassFoldUdBox
               group="Tingbog"
               title="Tingbog - Øvrigt"
               boxKey="Tingbog - Øvrigt"
               foldetUd={props.foldetUd || false}
               >
               {PropInfoGroupFormatters.TingbogEjendom(data, hasAccessToFeature)}
               </GlassFoldUdBox>
            ) : (
               <></>
            )}
            {PropInfoGroupFormatters.Vurderinger(data, props.foldetUd || false)}
            </ShowSelectedGroup>
            </div>
            </>
         );
      }
      