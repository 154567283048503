import { featureCollection } from "@turf/helpers";

export function WFStoGeoJson(str: string) {
   let dom = new DOMParser().parseFromString(str, "text/xml");
   let features = [
      ...dom.getElementsByTagName("gml:featureMember"),
      ...dom.getElementsByTagName("wfs:member")
   ].map((a) => {
      let fContent = a.firstElementChild;
      let coordinates:number[] | number[][] | number[][][] = [0,0]
      let properties = {}
      if (fContent) {
         let type = "Point";
         if (fContent.getElementsByTagName("gml:Polygon").length > 0) {
            let strCoord = [...fContent.getElementsByTagName("gml:Polygon")].map((polygon) => {
               return [...polygon.getElementsByTagName("gml:posList")].map((a, idx) => {
                  let postList = a.textContent?.split(" ").reduce(function(result, value, index, array) {
                     if (index % 2 === 0)
                       result.push([parseFloat(array[index + 1]),parseFloat(array[index])]);
                     return result;
                   }, [] as any[]) || []
                  return idx == 1 ? postList : postList.reverse(); 
               });
            });
            if (strCoord.length > 1) {
               type = "MultiPolygon";
            } else {
               type = "Polygon";
               strCoord = strCoord[0];
            }
            coordinates = strCoord
         }
         if (fContent.getElementsByTagName("gml:Point").length > 0) {
            type = "Point";
            let strCoord = fContent.getElementsByTagName("gml:Point")[0].getElementsByTagName("gml:coordinates")[0]?.textContent
            coordinates = strCoord?.split(",").map((a) => parseFloat(a)) as number[]
         }
         if (fContent.getElementsByTagName("gml:LineString").length > 0) {
            type = "LineString";
            let strCoord = fContent.getElementsByTagName("gml:LineString")[0].getElementsByTagName("gml:posList")[0]?.textContent
            let postList = strCoord?.split(" ").reduce(function(result, value, index, array) {
               if (index % 2 === 0)
                 result.push([parseFloat(array[index + 1]),parseFloat(array[index])]);
               return result;
             }, [] as any[]) || []
            coordinates = postList
         }

         
         [...fContent.children].forEach(element => {
            if (element.childNodes.length == 1) {
               let prop = element.tagName.split(":").at(-1)
               if (prop) {
                  properties = {...properties, [prop]: element.textContent}
               }
            }
         });
         return {
            type:"Feature",
            id: "",
            geometry: {type: type, coordinates:coordinates},
            properties: properties
         }
      }
   })
   return featureCollection(features as any)
}