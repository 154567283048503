import {SettingsManager, Utils} from '@viamap/viamap2-common';
import {Localization} from "@viamap/viamap2-common";
import { MitLatLng } from './MapFacade';

export type SecondsSinceMidnight = number;
export type DurationMinutes = number;
export type DurationSeconds = number;
export type DateYYYYMMDDAsNumber = number;
export enum TravelMode {
    "car"="car",
    "walk"="walk",
    "bike"="bike",
    "transit"="transit",
    "transitorbike"="transitorbike"
}
export enum TargomoTravelMode {
    "car"="car",
    "walk"="walk",
    "bike"="bike",
    "transit"="transit",
    "fly"="fly"
}
export type TravelSource = {
    latlng: MitLatLng, 
    earliestTime?:SecondsSinceMidnight, 
    latestTime?:SecondsSinceMidnight,
    name?:string
    fileName?:string
}
export type TravelOptions = {
    time?: SecondsSinceMidnight, 
    reverse?: boolean, 
    earliestArrival?:boolean,
    date?: DateYYYYMMDDAsNumber, 
    duration?: DurationSeconds, 
    rushHourMode?: boolean,
    earliestArrivalTime? : number, // seconds
    speed?: number, // 
    walkingSpeed?:number, // VIAMAP property (walking) speed to/from transit stop (km/h). Default = 5 km/h
    arrivalOrDepartureDuration?: number, // the time window for public transport
}

export type TravelTarget = {
    latlng: MitLatLng,
    name?:string 
}

export class CatchmentInterface {
    // cashe key values

    static catchmentGeoJSONCache:{[cacheKey:string]:{
        getTravelTimePolygonsResult: any} 
    }= {};

    static transportModes= [
        { file: 'car_3.svg', type: 'car' },
        { file: 'bike.svg', type: 'bike' },
        { file: 'walk.svg', type: 'walk' },
        { file: 'transit_2.svg', type: 'transit' },
        { file: 'transit_bike.svg', type: 'transitorbike' },
    ] as const;

    static settingsByTransportMode = {
        'car':{bufferSize:100},
        'bike':{bufferSize:25},
        'walk':{bufferSize:10},
        'transit':{bufferSize:25},
        'transitorbike':{bufferSize:25},
    };

    static getSourceSpec(type:TravelMode, sources:TravelSource[], options:TravelOptions) {
        let primitiveTypes:string[]=[];
        if (type==="transitorbike") {
            primitiveTypes=["transit","bike"];
        } else {
            primitiveTypes=[type];
        }
        return sources.reduce<any[]>((result, src, idx) => {
            let records=primitiveTypes.map((tpe) => {
            let arrivalOrDepartureDuration = 0; // 0 = inactive
            if (src.earliestTime) {
                arrivalOrDepartureDuration = (src.latestTime || 8* 60*60) - (src.earliestTime || 0)
            }
                return {
                    "id": src.name || idx+tpe,
                    "lat": src.latlng.lat,
                    "lng": src.latlng.lng,
                    "tm": 
                        {                            
                        [tpe]:{
                                "frame": {
                                    "time": src.latestTime  || 8 * 3600,
                                    "date": options.date,
                                    "duration":options.duration,
                                    "earliestArrival": options.earliestArrival,
                                    "arrivalOrDepartureDuration": arrivalOrDepartureDuration
                                },
                                "rushHour": options.rushHourMode,
                                "speed" : tpe === "transit" ? options.walkingSpeed : undefined
                            }
                        }
                }
            });
            return [...result, ...records];           
        }, []);
    }


    /**
     * Fetch with error handling. In case of error Targomo returns the explanation in the 'body' of the response.
     * @param url The url to call
     * @param fetchOptions optoins to fetch method
     * @returns 
     */
    static async fetchDataAndHandleErrors(url:string, fetchOptions:any):Promise<any> {
        return new Promise<any>((resolve, reject) => {

            fetch(url, fetchOptions).then(async (a) => {
                if (a.ok) {
                    resolve (a.json())
                } else {
                    let err = await a.text();
                    console.error("Error from targomo: "+err);
                    reject("error code: "+a.status);
                }
            }).catch((err) => {
                reject(err)
            });

        });

    }

    /**
     * Render travel time by GeoJSON. This provides much more detail.
     */

    static async getPolygonFromServer(
        type: TravelMode, 
        travelTimes: DurationSeconds[], 
        sources:TravelSource[], 
        options:TravelOptions, 
        infoMessage?: string) {
        let key = SettingsManager.getSystemSetting("targomoServiceKey"); //, '48Y7884MA3P7CVZADSJJ119781638');
        if (!key) {
            throw Utils.createErrorEventObject("No 'targomoServiceKey' setting found");
        }
        let params = "key=" + key;

        const getRequestBody2 = () => {
            let sourceSpec = CatchmentInterface.getSourceSpec(type, sources, options);
            return {
                "sources": sourceSpec,
                "edgeWeight": "time",
                "reverse": options.reverse,
                "polygon": {
                    "values": travelTimes,
                    "intersectionMode": "union",
                    "serializer": "geojson",
                    "srid": 4326,
                    "simplify":200,
                    "quadrantSegments":6,
                    "buffer": SettingsManager.getSystemSetting("catchmentStateDefaults.buffer", 0.002) || 0.0001
                }
            };
        };

        // buffer size by modeOfTransport
        // let bufferSize = this.settingsByTransportMode && this.settingsByTransportMode[type] && this.settingsByTransportMode[type].bufferSize;
        // var bufferLengthsMeters = r360.Util.metersInDegrees(bufferSize, 52);
        // travelOptionsGeoJSON.setBuffer(bufferLengthsMeters.lng);
        
        // appMessageDispatch(actionSetInfoMessage(
        //     infoMessage ? infoMessage : 
        //     Localization.getFormattedText(
        //         "Retrieving Catchment Areas for {noOfPoints} points...",
        //         { noOfPoints: latlngs.length }
        //     )
        // );

        return new Promise<any>((resolve, reject) => {
            const options = {
                protocol: "https:",
                port: 443,
                host: "api.targomo.com",
                path: "/westcentraleurope/v1/polygon" + "?" + params,
                method: "POST",
                body: JSON.stringify(getRequestBody2()),
                headers: { "Content-Type": "application/json" }
            };

            CatchmentInterface.fetchDataAndHandleErrors("https://api.targomo.com/westcentraleurope/v1/polygon"+"?"+params, options)
            .then((res) => {
                resolve(res.data)
            }).catch((err) => {
                reject(err)
            })
        })
    }


    static async generateCatchmentsGeoJSON(
        type: TravelMode, 
        travelTimes: DurationSeconds[], 
        source:{
            latlng: MitLatLng, 
            earliestTime?:SecondsSinceMidnight, 
            latestTime?:SecondsSinceMidnight
        }, 
        options:TravelOptions,
        ) {
            
            return new Promise((resolve, reject) => {
                try {
                    let data = this._getFromCache(type, source.latlng, options.rushHourMode || false);
                    if (data) {
                        resolve(data);                
                    } else {
                        this.getPolygonFromServer(type, travelTimes, [source], options /*, Localization.getText("Retrieving Catchment Areas...")*/)
                            .then((result: any) => {
                                this._addToCache(type!, source.latlng, options.rushHourMode || false, result);
                                resolve(result);
                            })
                            .catch((err:any) => {
                                reject("Isochrone generation failed "+(err.message || err));
                            })
                    }
                } catch (err:any) {
                    reject("Isochrone generation failed "+(err.message || err));
                }
            });
        }
    
    /**
     * Render travel time by GeoJSON. This provides much more detail.
    */
    static async generateCatchmentGeoJSONMultiple(
        type: TravelMode, 
        travelTimes: DurationSeconds[], 
        sources:{
            latlng: MitLatLng, 
            earliestTime?:SecondsSinceMidnight, 
            latestTime?:SecondsSinceMidnight
        }[], 
        options: TravelOptions, 
        calculateSeperately: boolean = false,
        callbackOnProgess?: (started:number, completed:number) => void
        ) {

            return new Promise((resolve, reject) => {
                try {
                    if (calculateSeperately) {
                        let promiseList: Promise<any>[] = [];
                        let infoMessage = Localization.getFormattedText(
                            "Retrieving Catchment Areas for {noOfPoints} points...",
                            { noOfPoints: sources.length }
                        );
                        let started:number = 0;
                        let completed:number = 0;
                        sources.forEach(src => {
                            started++;
                            promiseList.push(
                                new Promise((resolve, reject) => {
                                    this.getPolygonFromServer( type, travelTimes, [src],options, infoMessage)
                                    .then((res) => {
                                        completed++;
                                        callbackOnProgess && callbackOnProgess(started, completed);
                                        resolve(res);
                                    })
                                    .catch((err) => {
                                        completed++;
                                        reject(err);
                                    });
                                })
                            );
                        });
                        Promise.all(promiseList)
                            .then(results => {
                                resolve(results);
                            })
                            .catch((err:any) => {
                                reject("Isochrone generation failed "+(err.message || err));
                            })
        
                    } else {
                        this.getPolygonFromServer( type, travelTimes, sources ,options)
                        .then(res => {
                            resolve(res);
                        })
                        .catch((err:any) => {
                            reject("Isochrone generation failed "+(err.message || err));
                        })
                    }
                } catch (err:any) {
                    reject("Isochrone generation failed "+(err.message || err));
                }
            });

        }


    /*
http://uvm.viamap.net/v1/catchment/?times=1800,2700,3600,4500&mot=public2max&fromlatlng=55.97539834111433,12.00358578005246
    */

// ----------------------------------------------- Time API --------------------------------------------------------------------------

/** Gets Time between multiple points */
static async getTravelTimeFromServer(
    type: TravelMode, 
    targets:TravelTarget[], 
    sources:TravelSource[], 
    options:TravelOptions) {
    let key = SettingsManager.getSystemSetting("targomoServiceKey"); //, '48Y7884MA3P7CVZADSJJ119781638');
    if (!key) {
        throw Utils.createErrorEventObject("No 'targomoServiceKey' setting found");
    }
    let params = "key=" + key;

    const getRequestBody3 = () => {
        let sourceSpec = CatchmentInterface.getSourceSpec(type, sources, options);
        let targetSpec = targets.map<any>((src, idx) => {
                return {
                    "id": src.name || "Target"+idx,
                    "lat": src.latlng.lat,
                    "lng": src.latlng.lng,
                }
            });

        return {
            "sources": sourceSpec,
            "targets": targetSpec,
            "edgeWeight": "time",
            "maxEdgeWeight":7200,
            "reverse": options.reverse,
        };
    };

    return new Promise<any>((resolve, reject) => {
        const options = {
            protocol: "https:",
            port: 443,
            host: "api.targomo.com",
            path: "/westcentraleurope/v1/time" + "?" + params,
            method: "POST",
            body: JSON.stringify(getRequestBody3()),
            headers: { "Content-Type": "application/json" }
        };

        CatchmentInterface.fetchDataAndHandleErrors("https://api.targomo.com/westcentraleurope/v1/time"+"?"+params, options)
        .then((res) => {
            resolve(res.data)
        }).catch((err) => {
            reject(err)
        })
    });
}


// ----------------------------------------------- Route API as segment list --------------------------------------------------------------------------

static async getRouteFromServer(
    type: TravelMode, 
    targets:TravelTarget[], 
    sources:TravelSource[], 
    options:TravelOptions) {
    let key = SettingsManager.getSystemSetting("targomoServiceKey"); //, '48Y7884MA3P7CVZADSJJ119781638');
    if (!key) {
        throw Utils.createErrorEventObject("No 'targomoServiceKey' setting found");
    }
    let params = "key=" + key;

    const getRequestBody4 = () => {
        let sourceSpec = CatchmentInterface.getSourceSpec(type, sources, options);
        let targetSpec = targets.map<any>((src, idx) => {
                return {
                    "id": src.name || "Target"+idx,
                    "lat": src.latlng.lat,
                    "lng": src.latlng.lng,
                }
            });

        return {
            "sources": sourceSpec,
            "targets": targetSpec,
            "edgeWeight": "time",
            "maxEdgeWeight":7200,
            "reverse": options.reverse,
            // "pathSerializer": "geojson",
            // "polygon": {
            //     srid: 4326
            // }
        };
    };

    return new Promise<any>((resolve, reject) => {
        const options = {
            protocol: "https:",
            port: 443,
            host: "api.targomo.com",
            path: "/westcentraleurope/v1/route" + "?" + params,
            method: "POST",
            body: JSON.stringify(getRequestBody4()),
            headers: { "Content-Type": "application/json" }
        };
        CatchmentInterface.fetchDataAndHandleErrors("https://api.targomo.com/westcentraleurope/v1/route"+"?"+params, options)
        .then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })

    });
}

// ----------------------------------------------- Route as GeoJson ---------------------------------------------------------
/** 
 * Can be used to visualize the route on the map 
*/
static async getRouteFromServerGeoJSON(
    type: TravelMode, 
    targets:TravelTarget[], 
    sources:TravelSource[], 
    options:TravelOptions) {
    let key = SettingsManager.getSystemSetting("targomoServiceKey"); //, '48Y7884MA3P7CVZADSJJ119781638');
    if (!key) {
        throw Utils.createErrorEventObject("No 'targomoServiceKey' setting found");
    }
    let params = "key=" + key;

    const getRequestBody1 = () => {
        let sourceSpec = CatchmentInterface.getSourceSpec(type, sources, options);
        let targetSpec = targets.map<any>((src, idx) => {
                return {
                    "id": src.name || "Target"+idx,
                    "lat": src.latlng.lat,
                    "lng": src.latlng.lng,
                }
            });

        return {
            "sources": sourceSpec,
            "targets": targetSpec,
            "edgeWeight": "time",
            "maxEdgeWeight":7200,
            "reverse": options.reverse,
            "pathSerializer": "geojson",
            "polygon": {
                srid: 4326
            }
        };
    };

    return new Promise<any>((resolve, reject) => {
        const options = {
            protocol: "https:",
            port: 443,
            host: "api.targomo.com",
            path: "/westcentraleurope/v1/route" + "?" + params,
            method: "POST",
            body: JSON.stringify(getRequestBody1()),
            headers: { "Content-Type": "application/json" }
        };
        
        CatchmentInterface.fetchDataAndHandleErrors("https://api.targomo.com/westcentraleurope/v1/route"+"?"+params, options)
        .then((res) => {
            resolve(res)
        }).catch((err) => {
            reject(err)
        })

    })
}

// ----------------------------------------------- Caching ---------------------------------------------------------------------------


    static clearCache() {
        this.catchmentGeoJSONCache = {};
    }

   private static _createCacheKey(modeOfTransport:string, latlng:MitLatLng, rushHourMode:boolean):string {
        console.assert(modeOfTransport && latlng, "Missing parameters");
        let result = ""+modeOfTransport+(latlng?.toString()||"")+rushHourMode.toString();
        return result;
    }

    private static _addToCache(modeOfTransport:string, latlng:MitLatLng, rushHourMode:boolean, data:any) {
        this.catchmentGeoJSONCache[this._createCacheKey(modeOfTransport, latlng, rushHourMode)] = {
            getTravelTimePolygonsResult: data
        };
    }

    private static _getFromCache(modeOfTransport:string, latlng:MitLatLng, rushHourMode:boolean):any|null {
        let cacheObj = this.catchmentGeoJSONCache[this._createCacheKey(modeOfTransport, latlng, rushHourMode)];
        return cacheObj ? cacheObj.getTravelTimePolygonsResult : null;
    }
}