import { useContext, useEffect, useRef, useState } from "react"
import { createPortal } from "react-dom";
import { BsArrowsAngleContract, BsArrowsFullscreen, BsFullscreenExit, BsSquare, BsXLg } from "react-icons/bs";
import { MitStorage } from "src/managers/MitStorage";
import { ApplicationStateContext, Feature, hasAccessToFeature } from "src/states/ApplicationState";
import { ManagedWindow, WindowState } from "src/WindowManager/WindowState";

type PositionDefault = {
   left?: string,
   top?: string,
   width?: string,
   height?: string,
   transform?: string,
}

type PositionToSave = {
   left?:boolean,
   top?:boolean,
   width?:boolean,
   height?:boolean,
}

type ADMVariants = "Info" | "Small" | "NSWhite" | "NSDark" | "NSPadding" | "NoMinHeight"

export function AdvancedDragModal(props: {title, children, window?: ManagedWindow, topUpdate?:number, miniMizeUpdate?:number, allowFullscreen?: boolean, allowUserMiniMize?:boolean, variant?:ADMVariants | ADMVariants[], onClose?, subHeaderContent?,PosDefault?: PositionDefault, PosDefaultNS?: PositionDefault ,PositionToSave?:PositionToSave, saveKey?:string}) {
   const [showFullscreenIcon, setShowFullscreenIcon] = useState(false)
   const [isMinimized, setIsMinimized] = useState(false)

   const {hasAccessToFeature} = useContext(ApplicationStateContext)
   const refADM = useRef<HTMLDivElement>(null);
   const [headDown, topDown, leftDown, rightDown, bottomDown, minimize] = useDragRef(refADM, setShowFullscreenIcon, props.PositionToSave, props.saveKey);
   const [makeTop] = useAlwaysTop(refADM)
   const savedPos = props.saveKey ? MitStorage.getValue("ADM-Pos-"+props.saveKey) || {} : {};
   const defaultPos = hasAccessToFeature(Feature.ThemeNewSec) && props.PosDefaultNS ? props.PosDefaultNS : props.PosDefault
   const startPos = Object.keys(savedPos).length ? {...defaultPos, ...savedPos} : defaultPos;

   useEffect(() => {
      if (!refADM.current) {return}
      setIsMinimized(true)
      refADM.current.classList.add("js_minimized")
   },[props.miniMizeUpdate])

   useEffect(() => {
      if (!refADM.current) {return}
      makeTop()
      setIsMinimized(false)
      refADM.current.classList.remove("js_minimized")
   },[props.topUpdate])

   useEffect(() => {
      if (!refADM.current) {return}
      switch (props.window?.state) {
         case WindowState.Minimized:
            makeTop()
            setIsMinimized(true)
            refADM.current.classList.add("js_minimized")
            break;
         case WindowState.Open:
            refADM.current.classList.remove("js_minimized")
            setIsMinimized(false)
            break;
      }
   },[props.window, props.window?.updateCounter, props.window?.state])

   function jsMinimize(val:boolean) {
      if (!refADM.current) {return}
      const curRef = refADM.current;
      if (val) {
         curRef.classList.add("js_minimized")
      } else {
         curRef.classList.remove("js_minimized")
      }
   }

   useEffect(() => {
      makeTop()
      if (refADM.current) {
         let curRef = refADM.current
         let compStyle = window.getComputedStyle(curRef)
         let startOffsetX = Number(compStyle.getPropertyValue('--startingOffsetX').split("px").at(0)) || 0;
         let startOffsetY = Number(compStyle.getPropertyValue('--startingOffsetY').split("px").at(0)) || 0;
         
         let {left, top, width, bottom} = curRef.getBoundingClientRect()
         let {left:pLeft, width:pWidth, height:pHeight, top:pTop, bottom:pBottom} = curRef.parentElement!.getBoundingClientRect()
         let outside = 
            0 > (left - pLeft - startOffsetX) || 
            (left - pLeft - startOffsetX) > pWidth - width ||
            0 > (top - pTop - startOffsetY) ||
            (top - pTop - startOffsetY) > pHeight - 35;
         let bottomOutside = 0 < (bottom - pBottom - startOffsetY)
         if (bottomOutside) {
            curRef.style.height ||= (pHeight - top + startOffsetY) + "px"
         }
         if (outside) {
            curRef.style.left = clamp(0,left - pLeft - startOffsetX,pWidth - width) + "px";
            curRef.style.top = clamp(0, top - pTop - startOffsetY, pHeight - 35) + "px";
            curRef.style.transform = ""
            makeXRelative(curRef)
         }
      }

      

      const handlePrint = () => {
         let curRef = refADM.current
         if (curRef) {
            if (curRef.style.zIndex == "1000") {
               let printAreas = [...curRef.getElementsByClassName("js_ADMprint")];
               printAreas.forEach((a) => {
                  a.classList.add("js_printArea")
               })
               const removeAllPrintAreas = () => {
                  let printAreas = [...document.getElementsByClassName("js_printArea")]
                  printAreas.forEach((a) => {
                     a.classList.remove("js_printArea")
                  })
                  window.removeEventListener("afterprint",removeAllPrintAreas)
               }
               
               window.addEventListener("afterprint",removeAllPrintAreas)
            }
         }
      }
      window.addEventListener("beforeprint", handlePrint)
      return () => window.removeEventListener("beforeprint",handlePrint)
   },[])

   return (
      <>
      <div ref={refADM} style={startPos} className={ADMVariantHandler("AdvancedDragModal", props.variant)+"js_alwaysOnTop js_keepInside"}>
         <div onPointerDown={leftDown} className="resize V L" />
         <div onPointerDown={rightDown} className="resize V R" />
         <div onPointerDown={topDown} className="resize H T" />
         <div onPointerDown={bottomDown} className="resize H B" />
         <div onPointerDown={(e) => {topDown(e); leftDown(e)}} className="resizeCorner TL" />
         <div onPointerDown={(e) => {topDown(e); rightDown(e)}} className="resizeCorner TR" />
         <div onPointerDown={(e) => {bottomDown(e); rightDown(e)}} className="resizeCorner BR" />
         <div onPointerDown={(e) => {bottomDown(e); leftDown(e)}} className="resizeCorner BL" />
         <div onPointerDown={(e) => {headDown(e); makeTop(e)}} className="Header">
            {/* <div className="square" onPointerDown={(e) => e.stopPropagation()} onClick={(e:any) => minimize(e)} >Sss</div> */}
            <div className="Title">{props.title}</div>
            <div className="HeaderLeftButtons">
               {props.allowUserMiniMize ? <button onPointerDown={(e) => e.stopPropagation()} onClick={(e) => {
                  setIsMinimized(true)
                  jsMinimize(true)
               }} >
                  <BsArrowsAngleContract />
               </button>:null}
            </div>
            <div className="HeaderButtons">
            {props.allowFullscreen ? 
               <button onPointerDown={(e) => e.stopPropagation()} onClick={(e) => {
                  if (refADM.current?.classList.contains("js_fullscreen")) {
                     refADM.current?.classList.remove("js_fullscreen")
                     setShowFullscreenIcon(false)
                  } else {
                     refADM.current?.classList.add("js_fullscreen")
                     setShowFullscreenIcon(true)
                  }
               }} >
                  {!showFullscreenIcon ? 
                  <BsArrowsFullscreen /> :
                  <BsFullscreenExit />
                  }
               
               </button>
            : <></>}
            {props.onClose ? <button className="cls-btn" onPointerDown={(e) => e.stopPropagation()} onClick={(e) => props.onClose(e)}>
               <BsXLg />
            </button>: <></>}
            </div>
         </div>
         <div onClick={() => makeTop()} className="SubHeader">
            {props.subHeaderContent}
         </div>
         {props.children}
      </div>
      {isMinimized ? 
         createPortal(
            <div className="AdvancedDragModalMini" onClick={() => {
               setIsMinimized((e) => !e)
               jsMinimize(!isMinimized)
               makeTop()
            }} >
               <div className="Title">{props.title}</div>
            </div>
         ,document.getElementById("ADM_MiniContainer") || document.body) : <></>
         
      }
      </>
   )
}

function ADMVariantHandler(name:string, variant?:string | string[]):string {
   if (variant == undefined) return name + " "
   if (Array.isArray(variant)) {
      return (name+" "+variant.map((a) => name+"-"+a).join(" "))+" "
   }
   return (variant ? name+" "+name+"-"+variant : name)+" "

}

export function ADModalBody(props:{children}) {
   return (<div className="Body js_ADMprint js_printArea_test" >{props.children}</div>)
}

export function ADModalBodyProp(props:{children}) {
   return (<div className="BodyProp js_ADMprint js_printArea_test" >{props.children}</div>)
}

export function ADModalInfo(props:{children, center?}) {
   if (props.center) {
      return (<div className="ADMInfo" style={{justifyContent:"center"}} ><span>{props.children}</span></div>)
   }
   return (<div className="ADMInfo"><span>{props.children}</span></div>)
}

export function ADModalBodyNoScroll(props:{children, templateRows?, gap?, className?}) {
   return (<div className={"BodyVerticalScroll js_ADMprint js_printArea_test " + props.className} style={{"--templateCollumns":props.templateRows || "", "--Gap":props.gap || ""} as any}>{props.children}</div>)
}

export function ADModalFooter(props:{children}) {
   return (<div className="Footer" >{props.children}</div>)
}

export function ADMResizeObserver() {

   useEffect(() => {
      const resize = () => {
         let x = [...document.getElementsByClassName("js_keepInside")] as HTMLDivElement[]
         x.forEach((curRef) => {
            let {left, top, width} = curRef.getBoundingClientRect()
            let {left:pLeft, width:pWidth, height:pHeight, top:pTop} = curRef.parentElement!.getBoundingClientRect()
            let outside = 
               0 > (left - pLeft) || 
               (left - pLeft) > pWidth - width ||
               0 > (top - pTop) ||
               (top - pTop) > pHeight - 35;
            if (outside) {
            curRef.style.top = clamp(0, top - pTop, pHeight - 35) + "px";
            makeXRelative(curRef)
            }
         })
      }
      document.defaultView?.addEventListener("resize", resize)
      return () => document.defaultView?.removeEventListener("resize",resize) 
   },[])

   return <></>
}



function clamp(min, val, max) {
   return Math.max(Math.min(max, val), min)
}

function useAlwaysTop(refADM:React.RefObject<HTMLDivElement>) {
   function makeTop(e?:React.PointerEvent<HTMLDivElement>) {
      e?.preventDefault()
      if (!refADM?.current) {return}
      const curRef = refADM.current;
      curRef.style.touchAction = "none"
      const allElm = [...document.getElementsByClassName("js_alwaysOnTop")] as HTMLDivElement[];
      allElm.sort((a,b) => parseInt(b.style.zIndex) - parseInt(a.style.zIndex)).forEach((a, idx) => {a.style.zIndex = 999 - idx +""})
      curRef.style.zIndex = 1000+"";
      refADM.current.classList.remove("js_miniMized")
   }
   return [makeTop]
}



function useDragRef(refADM:React.RefObject<HTMLDivElement>, showFullscreenIcon:(val:false) => void ,posSave?: PositionToSave, saveLocation?: string) {
   let saveCo = useRef<any>(saveLocation ? MitStorage.getValue<any>("ADM-Pos-"+saveLocation) || {} : {});

   function touch() {
      if (!refADM.current) {return} 
      const curRef = refADM.current
      let {left:pLeft, width:pWidth, height:pHeight, top:pTop} = curRef.parentElement!.getBoundingClientRect();
      let {left, top, width} = curRef.getBoundingClientRect();
      curRef.style.top = clamp(0,top - pTop, pHeight - 35) + "px";
      curRef.style.left = clamp(0,left - pLeft,pWidth - width) + "px";
      curRef.style.transform = "";
   }

   function handleFullscreen(e:HTMLElement) {
      if (e.classList.contains("js_fullscreen")) {
         e.classList.remove("js_fullscreen")
         showFullscreenIcon(false)
         return true
      }
      return false
   }

   function savePosValue(boolean, location, value) {
      if (boolean && saveLocation) {
         saveCo.current = {...saveCo.current, [location]:value}
         MitStorage.setValue("ADM-Pos-"+saveLocation, saveCo.current)
      }
   }

   function getMinWidth(e: HTMLElement) {
      let {width: preWidth} = e.getBoundingClientRect();
      e.style.width = "0px";
      let {width} = e.getBoundingClientRect();
      e.style.width = preWidth + "px";
      return width
   }

   function headDown(e:React.PointerEvent<HTMLDivElement>) {
      const pointerID = e.pointerId
      if (!refADM?.current) {return}
      
      e.preventDefault()
      const curRef = refADM.current;
      curRef.style.touchAction = "none"
      const head = curRef.getElementsByClassName("Header")[0] as HTMLDivElement
      head.style.cursor = "grabbing";
      let [x,y] = [e.clientX, e.clientY];
      let {left, top, width} = curRef.getBoundingClientRect()
      let {left:pLeft, width:pWidth, height:pHeight, top:pTop} = curRef.parentElement!.getBoundingClientRect()
      const move = (e:PointerEvent) => {
         if (handleFullscreen(curRef)) {
            ({left, top, width} = curRef.getBoundingClientRect());
            left = ((x - pLeft) / pWidth) * (pWidth - width) + left
         }

         e.preventDefault();
         let [cx, cy] = [e.clientX, e.clientY];
         curRef.style.top = clamp(0,top + (cy - y) - pTop, pHeight - 35) + "px";
         curRef.style.left = clamp(0,left + (cx - x) - pLeft,pWidth - width) + "px";
         curRef.style.transform = "";
      }
      const release = (e:PointerEvent) => {
         document.body.releasePointerCapture(pointerID)
         head.style.cursor = "";
         makeXRelative(curRef)
         savePosValue(posSave?.left, "left", curRef.style.left)
         savePosValue(posSave?.left, "transform", curRef.style.transform)
         savePosValue(posSave?.top, "top", curRef.style.top)
         document.body.removeEventListener("pointerup",release)
         document.body.removeEventListener("pointermove",move)
      }
      touch()
      
      document.body.setPointerCapture(pointerID);
      document.body.addEventListener("pointerup",release)
      document.body.addEventListener("pointermove",move)
   }

   function leftDown(e:React.PointerEvent<HTMLDivElement>) {
      const pointerID = e.pointerId
      if (!refADM?.current) {return}
      
      e.preventDefault()
      const curRef = refADM.current;
      curRef.style.touchAction = "none"
      let [x,y] = [e.clientX, e.clientY];
      let {left:pLeft} = curRef.parentElement!.getBoundingClientRect()
      let {left, width, right} = curRef.getBoundingClientRect()
      let minWidth = getMinWidth(curRef)
      const move = (e:PointerEvent) => {
         e.preventDefault();
         let [cx, cy] = [e.clientX, e.clientY];
         let nextLeft = clamp(0,left + (cx - x) - pLeft, left - pLeft + width - minWidth)
         curRef.style.left = nextLeft + "px";
         if (nextLeft !== 0) {
            curRef.style.width =  width - (cx - x) + "px";
         } else {
            curRef.style.width =  (right - pLeft) + "px";
         }
         curRef.style.transform = "";
      }
      const release = (e:PointerEvent) => {
         makeXRelative(curRef)
         savePosValue(posSave?.left, "left", curRef.style.left)
         savePosValue(posSave?.left, "transform", curRef.style.transform)
         savePosValue(posSave?.width, "width", curRef.style.width)
         document.body.releasePointerCapture(pointerID)
         document.body.removeEventListener("pointerup",release)
         document.body.removeEventListener("pointermove",move)
      }
      touch()
      document.body.setPointerCapture(pointerID);
      document.body.addEventListener("pointerup",release)
      document.body.addEventListener("pointermove",move)
   }

   function rightDown(e:React.PointerEvent<HTMLDivElement>) {
      const pointerID = e.pointerId
      if (!refADM?.current) {return}
      
      e.preventDefault()
      const curRef = refADM.current;
      curRef.style.touchAction = "none"
      let [x,y] = [e.clientX, e.clientY];
      let {left, width} = curRef.getBoundingClientRect()
      let {left:pLeft, width:pWidth} = curRef.parentElement!.getBoundingClientRect()
      const move = (e:PointerEvent) => {
         e.preventDefault();
         let [cx, cy] = [e.clientX, e.clientY];
         curRef.style.width = clamp(0,width + (cx - x), pWidth-(left - pLeft)) + "px";
         curRef.style.left = left - pLeft + "px";
         curRef.style.transform = ""
         savePosValue(posSave?.width, "width", curRef.style.width)
      }
      const release = (e:PointerEvent) => {
         makeXRelative(curRef)
         savePosValue(posSave?.width, "width", curRef.style.width)
         document.body.releasePointerCapture(pointerID)
         document.body.removeEventListener("pointerup",release)
         document.body.removeEventListener("pointermove",move)
      }
      touch()
      document.body.setPointerCapture(pointerID);
      document.body.addEventListener("pointerup",release)
      document.body.addEventListener("pointermove",move)
   }

   function topDown(e:React.PointerEvent<HTMLDivElement>) {
      const pointerID = e.pointerId
      if (!refADM?.current) {return}
      
      e.preventDefault()
      const curRef = refADM.current;
      curRef.style.touchAction = "none"
      let [x,y] = [e.clientX, e.clientY];
      let {height, top} = curRef.getBoundingClientRect()
      let {left:pLeft, width:pWidth, height:pHeight, top:pTop} = curRef.parentElement!.getBoundingClientRect()
      const move = (e:PointerEvent) => {
         e.preventDefault();
         let [cx, cy] = [e.clientX, e.clientY];
         curRef.style.top = clamp(0,top + (cy - y) - pTop, pHeight - 35) + "px";
         curRef.style.height = height - (cy - y) + "px";
         savePosValue(posSave?.top, "top", curRef.style.top)
         savePosValue(posSave?.height, "height", curRef.style.height)
      }
      const release = (e:PointerEvent) => {
         makeXRelative(curRef)
         savePosValue(posSave?.top, "top", curRef.style.top)
         savePosValue(posSave?.height, "height", curRef.style.height)
         document.body.releasePointerCapture(pointerID)
         document.body.removeEventListener("pointerup",release)
         document.body.removeEventListener("pointermove",move)
      }
      touch()
      document.body.setPointerCapture(pointerID);
      document.body.addEventListener("pointerup",release)
      document.body.addEventListener("pointermove",move)
   }

   function bottomDown(e:React.PointerEvent<HTMLDivElement>) {
      const pointerID = e.pointerId
      if (!refADM?.current) {return}
      
      e.preventDefault()
      const curRef = refADM.current;
      curRef.style.touchAction = "none"
      let [x,y] = [e.clientX, e.clientY];
      let {height, top} = curRef.getBoundingClientRect()
      let {left:pLeft, width:pWidth} = curRef.parentElement!.getBoundingClientRect()
      const move = (e:PointerEvent) => {
         e.preventDefault();
         let [cx, cy] = [e.clientX, e.clientY];
         curRef.style.height = height + (cy - y) + "px";
      }
      const release = (e:PointerEvent) => {
         makeXRelative(curRef)
         savePosValue(posSave?.height, "height", curRef.style.height)
         document.body.releasePointerCapture(pointerID)
         document.body.removeEventListener("pointerup",release)
         document.body.removeEventListener("pointermove",move)
      }
      touch()
      document.body.setPointerCapture(pointerID);
      document.body.addEventListener("pointerup",release)
      document.body.addEventListener("pointermove",move)
   }

   function minimize(e:React.MouseEvent<Element, MouseEvent>) {
      console.log("miniMize")
      if (!refADM?.current) {return} 
      const curRef = refADM.current;
      let {top} = curRef.getBoundingClientRect()
      let {bottom:pBottom, height:pHeight} = curRef.parentElement!.getBoundingClientRect()
      if (top > pBottom - 100 ) {
         curRef.style.top = ""
      } else {
         curRef.style.top = (pHeight - 35) + "px";
      }
   }

   return [headDown, topDown, leftDown, rightDown, bottomDown, minimize]
}

function makeXRelative(ref:HTMLDivElement) {
   if (ref.parentElement) {
      const {left, width} = ref.getBoundingClientRect()
      const {left:pLeft, width:pWidth} = ref.parentElement.getBoundingClientRect()

      if (pWidth-width == 0) {
         ref.style.left = `${50}%`;
         ref.style.transform = `translateX(-${50}%)`;
         return
      }
      const xRelPos = Math.round(left-pLeft) / Math.round(pWidth-width) * 100
      ref.style.left = `${clamp(0,xRelPos,100)}%`;
      ref.style.transform = `translateX(-${clamp(0,Math.round(xRelPos * width / 100),width)}px)`;
   }
}