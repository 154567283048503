import { SettingsManager, Utils } from "@viamap/viamap2-common";
import { FeatureProperties } from "src/managers/WmsLayerFunc";
import ParserFeature from 'src/managers/ParserGetFeatureInfo';
import { Fragment } from "react";

/**
 * Used to display a popup when clicking on the map background. (when enabled)
 */
export class MapPinPopup {

  
  static utf8decodeKeysAndIndexes(object: FeatureProperties): FeatureProperties {
    let newObject: FeatureProperties = {};
    Object.keys(object).forEach((key) => {

      let newKey = key, newVal = object[key];

      try {
        newKey = key;
        newVal = typeof object[key] === "string" ? object[key] : object[key];
      // tslint:disable-next-line: no-empty
      } catch {}

      newObject[newKey] = newVal;
    });
    return newObject;
  }

  static formatFeatureInfoResponse(featuresToDisplay: FeatureProperties[], translator: (text:string) => string) {
    let TableElements = featuresToDisplay.map((element,id) => {
      return (
        <Fragment key={id} >
          {this.formatFeaturePropertiesInfoResponse(element, translator)}
          {/* {id < featuresToDisplay.length -1 ? <tr key={id+"spacer"} className="mit-popup-scroll-spacer"/>: null} */}
        </Fragment>
      );
    });
    
    return TableElements;
  }
  
  static formatFeaturePropertiesInfoResponse(propertiesToDisplay:FeatureProperties, translator: (text:string) => string) {
    propertiesToDisplay = this.utf8decodeKeysAndIndexes(propertiesToDisplay);
    let res;
    let rows:any[] = [];
    if (propertiesToDisplay) {
      Object.keys(propertiesToDisplay).forEach((key,idx) => {
        let name= translator(key);
        let value=propertiesToDisplay[key];
        let valElm;
        if (value && value.toString().toLowerCase().startsWith("http")) {
          // Is is a link. Show as a link
          valElm = (
            <a href={value} target="_blank">{value}</a>
          );
        } else  {
          valElm=(
            <div>{value}</div>
          );
        }
        let elm=(
          <div className="PopupPair" key={name}>
            <div className="PopupKey">{name}</div><div className="PopupValue">{valElm}</div>
          </div >
        );
        rows.push(elm);
      });
    }
    if (rows.length === 0) {
      return null;
    } else {
      return rows;
    }
    return res;
  }

  static async getFeatureInfoViaProxy(host:string, path:string) {
    let serviceHost = SettingsManager.getSystemSetting("mapitServicesHost") || "unknown";
    let query = 
      "https://"
      +serviceHost
      +"/"
      +SettingsManager.getSystemSetting("mapitGeoserverProxyResourceName","geoserverproxy");
    try {
        const response = await fetch(query,{

          method:"post",
          mode: "cors", // no-cors, cors, *same-origin
          headers: {
            "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
            "X-API-KEY":SettingsManager.getSystemSetting("mapitServicesAPIKey")
          },
          body: JSON.stringify({
            "host": host, 
            "path": path        
          })
        });
        const data = await response.json();
        return data.body ? JSON.parse(data.body) : data;
    } catch (err) {
        throw Utils.createErrorEventObject("MapScreen getFeatureInfo. Error"+err);
    }
  }

  

// -------------- TileLayer event handlers --------------

static async getFeatureInfo(url:string) {
  let query = url;
  try {
      let result;
      const response:Response = await fetch(query,{
        mode: "cors", // no-cors, cors, *same-origin
        headers: {
          
        },
      });
      const resultCode = await response.status;
      if (resultCode === 200) {
        // If needed convert data to json structure.
        const contentTypeStr = response.headers.get("Content-type");
        let contentTypeList = contentTypeStr && contentTypeStr.split(";") || [];
        let contentType = contentTypeList && contentTypeList.length > 0 && contentTypeList[0];
        switch (contentType) {
          case 'text/xml':

            let xml = await response.text();
            let jsonStr = "{}" //TODO:fix XMLJS.xml2json(xml, {compact: true, spaces: 4});
            let json = JSON.parse(jsonStr);
            let attrs = json.FeatureInfoResponse && json.FeatureInfoResponse.FIELDS && json.FeatureInfoResponse.FIELDS._attributes;
            result = {"features":[{"properties":attrs}]};
            break;
          case 'text/plain':
            let charset = contentTypeList?.find((a) => a.includes("charset"))?.split("=")?.[1] ?? "utf-8";
            let Decoder = new TextDecoder(charset)
            let decodedString = Decoder.decode(await response.arrayBuffer())
            result = {"features": ParserFeature.plainTextToFeatures(decodedString)};
            break;
        case 'application/json':
            result = await response.json();
            break;
        default:
            let text2 = await response.text();
            throw Utils.createErrorEventObject("unexpected: "+contentTypeStr);
        }  
      } else {
        throw Utils.createErrorEventObject("result code: "+resultCode);
      }
      return result;
  } catch (err) {
      throw Utils.createErrorEventObject("MapScreen getFeatureInfo. Error"+err);
  }
}


}