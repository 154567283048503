
export const BNBOAreaEditorStyling = [
   {
     'id': 'gl-draw-polygon-fill-inactive',
     'type': 'fill',
     'filter': ['all',
       ['==', 'active', 'false'],
       ['==', '$type', 'Polygon'],
       ['!=', 'mode', 'static']
     ],
     'paint': {
       'fill-color': '#ae41ba',
       'fill-outline-color': '#ae41ba',
       'fill-opacity': 0.4
     }
   },
   {
     'id': 'gl-draw-polygon-fill-active',
     'type': 'fill',
     'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
     'paint': {
       'fill-color': '#fbb03b',
       'fill-outline-color': '#fbb03b',
       'fill-opacity': 0.4
     }
   },
   {
     'id': 'gl-draw-polygon-stroke-inactive',
     'type': 'line',
     'filter': ['all',
       ['==', 'active', 'false'],
       ['==', '$type', 'Polygon'],
       ['!=', 'mode', 'static']
     ],
     'layout': {
       'line-cap': 'round',
       'line-join': 'round'
     },
     'paint': {
       'line-color': '#ae41ba',
       'line-width': 2
     }
   },
   {
     'id': 'gl-draw-polygon-stroke-active',
     'type': 'line',
     'filter': ['all', ['==', 'active', 'true'], ['==', '$type', 'Polygon']],
     'layout': {
       'line-cap': 'round',
       'line-join': 'round'
     },
     'paint': {
       'line-color': '#fbb03b',
       'line-dasharray': [0.2, 2],
       'line-width': 2
     }
   },
   {
     'id': 'gl-draw-line-inactive',
     'type': 'line',
     'filter': ['all',
       ['==', 'active', 'false'],
       ['==', '$type', 'LineString'],
       ['!=', 'mode', 'static']
     ],
     'layout': {
       'line-cap': 'round',
       'line-join': 'round'
     },
     'paint': {
       'line-color': '#ae41ba',
       'line-width': 2
     }
   },
   {
     'id': 'gl-draw-line-active',
     'type': 'line',
     'filter': ['all',
       ['==', '$type', 'LineString'],
       ['==', 'active', 'true']
     ],
     'layout': {
       'line-cap': 'round',
       'line-join': 'round'
     },
     'paint': {
       'line-color': '#fbb03b',
       'line-dasharray': [0.2, 2],
       'line-width': 2
     }
   },
   {
     'id': 'gl-draw-polygon-and-line-vertex-stroke-inactive',
     'type': 'circle',
     'filter': ['all',
       ['==', 'meta', 'vertex'],
       ['==', '$type', 'Point'],
       ['!=', 'mode', 'static']
     ],
     'paint': {
       'circle-radius': 7,
       'circle-color': '#fff'
     }
   },
   {
     'id': 'gl-draw-polygon-and-line-vertex-inactive',
     'type': 'circle',
     'filter': ['all',
       ['==', 'meta', 'vertex'],
       ['==', '$type', 'Point'],
       ['!=', 'mode', 'static']
     ],
     'paint': {
       'circle-radius': 4,
       'circle-color': '#fbb03b'
     }
   },
   {
     'id': 'gl-draw-point-point-stroke-inactive',
     'type': 'circle',
     'filter': ['all',
       ['==', 'active', 'false'],
       ['==', '$type', 'Point'],
       ['==', 'meta', 'feature'],
       ['!=', 'mode', 'static']
     ],
     'paint': {
       'circle-radius': 6,
       'circle-opacity': 1,
       'circle-color': '#fff'
     }
   },
   {
     'id': 'gl-draw-point-inactive',
     'type': 'circle',
     'filter': ['all',
       ['==', 'active', 'false'],
       ['==', '$type', 'Point'],
       ['==', 'meta', 'feature'],
       ['!=', 'mode', 'static']
     ],
     'paint': {
       'circle-radius': 4,
       'circle-color': '#ae41ba'
     }
   },
   {
     'id': 'gl-draw-point-stroke-active',
     'type': 'circle',
     'filter': ['all',
       ['==', '$type', 'Point'],
       ['==', 'active', 'true'],
       ['!=', 'meta', 'midpoint'],
       ['!=', 'meta', 'measure']
     ],
     'paint': {
       'circle-radius': 9,
       'circle-color': '#fff'
     }
   },
   {
     'id': 'gl-draw-point-active',
     'type': 'circle',
     'filter': ['all',
       ['==', '$type', 'Point'],
       ['!=', 'meta', 'midpoint'],
       ['!=', 'meta', 'measure'],
       ['==', 'active', 'true']],
     'paint': {
       'circle-radius': 7,
       'circle-color': '#fbb03b'
     }
   },
   {
     'id': 'gl-draw-polygon-midpoint-halo',
     'type': 'circle',
     'filter': ['all',
       ['==', '$type', 'Point'],
       ['==', 'meta', 'midpoint']],
     'paint': {
       'circle-radius': 5,
       'circle-color': '#fff'
     }
   },
   {
     'id': 'gl-draw-polygon-midpoint',
     'type': 'circle',
     'filter': ['all',
       ['==', '$type', 'Point'],
       ['==', 'meta', 'midpoint']],
     'paint': {
       'circle-radius': 4,
       'circle-color': '#3a8e87'
     }
   },
   {
     'id': 'gl-draw-polygon-fill-static',
     'type': 'fill',
     'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
     'paint': {
       'fill-color': '#404040',
       'fill-outline-color': '#404040',
       'fill-opacity': 0.1
     }
   },
   {
     'id': 'gl-draw-polygon-stroke-static',
     'type': 'line',
     'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Polygon']],
     'layout': {
       'line-cap': 'round',
       'line-join': 'round'
     },
     'paint': {
       'line-color': '#404040',
       'line-width': 2
     }
   },
   {
     'id': 'gl-draw-line-static',
     'type': 'line',
     'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'LineString']],
     'layout': {
       'line-cap': 'round',
       'line-join': 'round'
     },
     'paint': {
       'line-color': '#404040',
       'line-width': 2
     }
   },
   {
     'id': 'gl-draw-point-static',
     'type': 'circle',
     'filter': ['all', ['==', 'mode', 'static'], ['==', '$type', 'Point'], ['!=', 'meta', 'measure']],
     'paint': {
       'circle-radius': 5,
       'circle-color': '#404040'
     }
   },
   {
     'id': 'gl-draw-measure-segment',
     'type': 'symbol',
     'filter': ['all', ['==', '$type', 'LineString'], ['==', 'meta', 'measure']],
     'paint': {
       'text-halo-color':"#fffd",
       'text-halo-width':1.5,
       'text-halo-blur':0,
     },
     'layout': {
       "text-field": ["get", "length"],
       "text-font": ["Noto Sans Regular"],
       "symbol-placement":"line-center",
       "text-anchor":"bottom",
     }
   },
   {
     'id': 'gl-draw-measureCenter',
     'type': 'symbol',
     'filter': ['all', ['==', '$type', 'Point'], ['==', 'meta', 'measure']],
     'paint': {
       'text-halo-color':"#fffd",
       'text-halo-width':1.5,
       'text-halo-blur':0,
     },
     'layout': {
       "text-field": ["concat", ["get", "length"], ["concat", "\n", ["get", "area"]]],
       "text-font": ["Noto Sans Regular"]
     }
   }
 ]; 