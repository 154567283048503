import { Localization, SettingsManager } from "@viamap/viamap2-common";

import { MitLatLng, MitLayerHandle } from "src/managers/MapFacade";
import { Utils } from "@viamap/viamap2-common";
import { MapitUtils } from "src/managers/MapitUtils";
import { CatchmentSelectionState } from "src/components/CatchmentSelector";
import { CatchmentInterface, TravelMode, TravelOptions } from "src/managers/CatchmentInterface";
import { CatchmentHelperMaplibre } from "src/managers/CatchmentHelperMaplibre";
import { MapitState } from "./MapitState";
import { createContext } from "react";

export enum MessageType {
   Error = "Error",
   Success = "Success"
}

export enum CalculationMode {
   Simple_RegenerateAfterEachParameterChange,
   MultiPoint_RegenerateFromMultiPointFormOnly
}

// ---------------------------------------------------- STATE --------------------------------------------------------------

export type CatchmentState= {
   // housekeeping
   activeTransactions: { [guid: string]: any },
   message: string,
   messageType: MessageType,

   travelTimes:number[];
   colorScale:any[];
   legendLabels:string[];
   selectedModeOfTransport:CatchmentSelectionState;
   rushHourMode:boolean;
   showSpatialItemsCounts?:boolean;

   catchmentLocation?:MitLatLng;
   // time?: number;
   // timeSpan?:number; // Span between earliest and latest arrival/departure
   // reverse?: boolean;
   // date?: number; // as 20231229 for dec 29th, 2023
   // duration?: number;
   // earliestArrival?: boolean;

   polygons?: any // the geographic result of catchment calculation
   tableData?: any[] // the tabular result of catchment calculation
   layerHandle?: MitLayerHandle; // Handle to polygons shown on map.

   calculationMode: CalculationMode;

   getTravelTimeOptions: () => TravelOptions;
 };

export const defaultCatchmentState = {
   activeTransactions: {},
   message: "",
   messageType: MessageType.Success,

   travelTimes: [15 * 60, 30 * 60, 45 * 60, 60 * 60],  // seconds
   colorScale: ['rgb(255,203,66)', 'rgb(255,255,0)', 'rgb(102,184,137)', 'rgb(0,138,229)'],
   legendLabels: [], // Stores labels shown on legend. Maintained by clicking the legend
   selectedModeOfTransport: "car", // Stores the currently selected mode of transport
   rushHourMode: false,

   calculationMode: CalculationMode.Simple_RegenerateAfterEachParameterChange,

   getTravelTimeOptions: getTravelTimeOptions
 };

// ---------------------------------------------------- INITIAL STATE --------------------------------------------------------------

export function initialCatchmentState(): CatchmentState {

   return defaultCatchmentState;
}

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------
export enum CatchmentStateActionType {
   TxStarted,
   TxCompleted,
   TxFailed,

   // ----------- catchment areas --------------
   SetCatchmentState,
   GenerateCatchmentSimple
}

export interface TxStarted {
   type: CatchmentStateActionType.TxStarted;
   payload: { guid: string, action: CatchmentStateActions };
}

export interface TxCompleted {
   type: CatchmentStateActionType.TxCompleted;
   payload: { guid: string, action: CatchmentStateActions };
}

export interface TxFailed {
   type: CatchmentStateActionType.TxFailed;
   payload: { guid: string, action: CatchmentStateActions, error: any };
}


export interface SetCatchmentState {
   type: CatchmentStateActionType.SetCatchmentState;
   payload: { state: CatchmentState };
}

export interface GenerateCatchmentSimple {
   type: CatchmentStateActionType.GenerateCatchmentSimple;
   payload: { state: CatchmentState, mapitState:MapitState, latlng:MitLatLng };
   results?: { polygons?: any };
}

// -------------------- utility functions to create an Action object ---------------------------------

export const actionTxStarted = (guid: string, action: CatchmentStateActions): TxStarted => ({
   type: CatchmentStateActionType.TxStarted,
   payload: { guid, action }
});

export const actionTxCompleted = (guid: string, action: CatchmentStateActions): TxCompleted => ({
   type: CatchmentStateActionType.TxCompleted,
   payload: { guid, action }
});

export const actionTxFailed = (guid: string, action: CatchmentStateActions, error: any): TxFailed => ({
   type: CatchmentStateActionType.TxFailed,
   payload: { guid, action, error }
});



export const actionSetCatchmentState = (state: CatchmentState) : SetCatchmentState => ({
   type: CatchmentStateActionType.SetCatchmentState,
   payload: { state }
})

export const actionGenerateCatchmentSimple = (state: CatchmentState, mapitState:MapitState, latlng:MitLatLng) : GenerateCatchmentSimple => ({
   type: CatchmentStateActionType.GenerateCatchmentSimple,
   payload: { state, mapitState, latlng }
})

// ---------------------------------------------------- ACTIONS --------------------------------------------------------------

export type CatchmentStateActionsTransActional = SetCatchmentState | GenerateCatchmentSimple;
export type CatchmentStateActionsNonTransActional = TxStarted | TxCompleted | TxFailed
   | SetCatchmentState
   ;
export type CatchmentStateActions = CatchmentStateActionsTransActional | CatchmentStateActionsNonTransActional;


// ---------------------------------------------------- TRANSACTIONAL REDUCER --------------------------------------------------------------

export function transactionalCatchmentStateReducer(action: CatchmentStateActionsTransActional, dispatch: any) {

   switch (action.type) {
      case CatchmentStateActionType.GenerateCatchmentSimple: {

       let txId=MapitUtils.getNewUuid();
       dispatch(actionTxStarted(txId, action));

         // appMessageDispatch(actionSetInfoMessage(Localization.getText("Retrieving Travel Times")));
         let travelTimes = action.payload.state.travelTimes;
         if (typeof travelTimes === "string") {
            // tslint:disable-next-line: radix
            travelTimes = (action.payload.state.travelTimes as unknown as string).split(",").map(time => parseInt(time));
         }
         let options = getTravelTimeOptions();
         CatchmentInterface.generateCatchmentsGeoJSON(
            action.payload.state.selectedModeOfTransport as TravelMode, 
            action.payload.state.travelTimes,
            { 
               latlng:action.payload.latlng,
               latestTime: options.time,
               earliestTime: options.earliestArrivalTime
            },
            {
               ...options,
               rushHourMode:action.payload.state.rushHourMode
            }
            )
            .then((result) => {
               action.results = {polygons : result};
               dispatch(actionTxCompleted(txId, action));
               dispatch(action);
            })
            .catch((error) => {
               console.error("Got error " + error);
               dispatch(actionTxFailed(txId, action, "Error generating catchment: " + (error.message || error)));
            })
       break;
      }

      default:
         // proceed directly to non-transational reducer for other actions
         dispatch(action);
   }
}

// ---------------------------------------------------- REDUCER --------------------------------------------------------------

export function CatchmentStateReducer(state: CatchmentState, action: CatchmentStateActions): CatchmentState {
   function enum2String(type: any, value: number): string {
      return Object.values<string>(type)[value];
   }

   switch (action.type) {

      case CatchmentStateActionType.TxStarted:
         return {
            ...state,
            activeTransactions: { ...state.activeTransactions, [action.payload.guid]: action },
            message: Localization.getTextSafeMode("Retrieving Travel Times"),
            messageType: MessageType.Success
         };

      case CatchmentStateActionType.TxFailed: {
         let tmp = { ...state.activeTransactions };
         delete tmp[action.payload.guid];
         console.error(action.payload.error);
         let msg = Utils.formatString(Localization.getTextSafeMode("Error. Transaction {txname} returned with {error}"), {
            txname: CatchmentStateActionType[action.payload.action.type],
            error: action.payload.error
         });
         // Logger.logError("viamapLiceningState", CatchmentStateActionType[action.payload.action.type], msg);
         return {
            ...state,
            message: msg,
            activeTransactions: tmp,
            messageType: MessageType.Error
         };
      }

      case CatchmentStateActionType.TxCompleted: {
         let tmp = { ...state.activeTransactions };
         delete tmp[action.payload.guid];
         return {
            ...state,
            activeTransactions: tmp,
            message: ""
         };
      }

      // ----------------------------------------- catchments ----------------------------------------------------------------------

      case CatchmentStateActionType.SetCatchmentState: {
         return {
            ...state,
            ...action.payload.state
         }
      }

      case CatchmentStateActionType.GenerateCatchmentSimple: {
         let layer = CatchmentHelperMaplibre.createLayerForPolygons(action.results!.polygons!, 
            { travelTimes:action.payload.state.travelTimes, colorScale: action.payload.state.colorScale , catchmentIn3d: true, heightFactor:10});
         action.payload.mapitState.map.updateLayer(layer);
         return {
            ...state,
            ...action.results,
            layerHandle:layer,
            calculationMode: CalculationMode.Simple_RegenerateAfterEachParameterChange,
            catchmentLocation: action.payload.latlng,
            selectedModeOfTransport: action.payload.state.selectedModeOfTransport
         }
      }

      default:
         throw new Error("Unknown action " + (action as CatchmentStateActions).type);
   }
}

// ---------------------------------------------------- generic stuff --------------------------------------------------------------

export const CatchmentStateContext = createContext<{
   state: CatchmentState,
   dispatch: React.Dispatch<CatchmentStateActions>
}>({
   state: initialCatchmentState(),
   dispatch: () => undefined
});

export class GenericTransactionManager {

   static dispatchMiddleware<ActionTypes>(dispatch: any, transactionalReducer: (action: any, dispatch: any) => any) {

      return (action: ActionTypes) => {
         transactionalReducer(action, dispatch);
      };

   }
}


// ---------------------------------------------------- extra --------------------------------------------------------------
 // as 20231229 for dec 29th, 2023
 function DateToDay() {
    return new Date().toISOString().split('T')[0].replaceAll("-","");
 }

function getTravelTimeOptions():TravelOptions {
   let time= SettingsManager.getSystemSetting("catchmentStateDefaults.time", undefined, true);
   if (!time) { 
      time = 28800 
   }; // default 08:00
   let earliestArrivalTime= SettingsManager.getSystemSetting("catchmentStateDefaults.earliestArrivalTime", undefined, true);
   let reverse = SettingsManager.getSystemSetting("catchmentStateDefaults.reverse", false);
   let earliestArrival = SettingsManager.getSystemSetting("catchmentStateDefaults.earliestArrival", false);
   let dateAsYYYYMMDD:number = SettingsManager.getSystemSetting("catchmentStateDefaults.date", undefined, true);
   if (!dateAsYYYYMMDD) {
      dateAsYYYYMMDD = Number.parseInt(DateToDay());
   }
   let arrivalOrDepartureDuration = 0;
   if (earliestArrivalTime) {
      arrivalOrDepartureDuration = time - earliestArrivalTime;
   }
   let durationMins = SettingsManager.getSystemSetting("catchmentStateDefaults.duration", 180) // minutes. default 3 hours
   let result:TravelOptions = {
     time: time, 
     earliestArrivalTime: earliestArrivalTime,
     reverse: reverse,
     earliestArrival: earliestArrival,
     date:  dateAsYYYYMMDD ,
     duration: durationMins * 60,
     arrivalOrDepartureDuration : arrivalOrDepartureDuration,
     walkingSpeed : SettingsManager.getSystemSetting("catchmentStateDefaults.speed", 5, true), // default 5 km/h when walking to stop
   }
   return result;

 }
