import {Utils} from '@viamap/viamap2-common';
import {DeploymentMode} from './Types';
import { SettingsManager } from '@viamap/viamap2-common';
import AWS, { Credentials } from 'aws-sdk';

export type AWSUserInfo = {
   Username: string,
   Enabled: boolean,
   UserStatus: string,
   UserCreateDate: number,
   UserLastModifiedDate: number,
   UserAttributes: { Name:any, Value: any }[]
};

export type AWSUserInfoListItem = {
   Username: string,
   Enabled: boolean,
   UserStatus: string,
   UserCreateDate: number,
   UserLastModifiedDate: number,
   Attributes: { Name:any, Value: any }[]
};
   
export type AWSUserList = AWSUserInfo[];

export class AWSUserAdmin {
//    static _instance:AWSUserAdmin|null=null;

//    static getInstance():AWSUserAdmin {
//       if (!this._instance) {
//          this._instance = new AWSUserAdmin();
//       }
//       return this._instance;
//    }

   static async ensureCredentials(jwtToken: string): Promise<void> {
      return new Promise((resolve, reject) => {
         let authData = SettingsManager.getSystemSetting("authData");
         let creds = (AWS.config.credentials as any);
         if (creds && creds.params && creds.params.Logins) {
            resolve();
         } else {

            AWS.config.credentials = new AWS.CognitoIdentityCredentials(
               {                  
                  IdentityPoolId: authData.IdentityPoolId,
                  Logins: {
                     // Change the key below according to the specific region your user pool is in.
                     ['cognito-idp.' + authData.Region + '.amazonaws.com/' + authData.UserPoolId]: jwtToken
                  }
               },
               {
                  region: authData.Region
               }
            );

            (AWS.config.credentials as Credentials).refresh(async error => {
               if (error) {
                  reject();
               } else {
                  console.info('Credentials refreshed!');
                  resolve();
               }
            });
         }
      });
   }

   static getGroupsForUser(userPoolId:string, userId:string):Promise<any> {
      if (Utils.getDeploymentMode(process.env.REACT_APP_ENVIRONMENT) === DeploymentMode.Production) {
            return this.retrieveGroupsForUser(userPoolId, userId);
      } else {
            return new Promise<any>((resolve, reject) => {
                  resolve([{GroupName:"Demo"},{GroupName:"360North"}]);
            });
      }
   }

   static retrieveUsers(userPoolId:string, paginationToken?:string):Promise<AWSUserInfoListItem[]> {
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let params = {
         UserPoolId: userPoolId, /* required */
         // AttributesToGet: [
         //   'STRING_VALUE',
         //   /* more items */
         // ],
         // Filter: 'STRING_VALUE',
         Limit: 6,
         PaginationToken: paginationToken
         };
      return new Promise((resolve,reject) => {
         cognitoidentityserviceprovider.listUsers(params, function (err:any, data:any) {
            let result:AWSUserInfoListItem[]=[];
            if (err) {
                  // an error occurred
                  console.error(err, err.stack);
                  reject(err.message);
            } else {
               // successful response
               if (data.PaginationToken) {
                  AWSUserAdmin.retrieveUsers(userPoolId, data.PaginationToken)
                  .then((data2:any) => {
                     let result2:any[] = data2 ? data2 : [];
                     let yy:any[] = [];
                     result.forEach((item) => {
                        yy.push(item);
                     });
                     result2.forEach((item2) => {
                           yy.push(item2);
                     });
                     resolve(yy);
                  })
                  .catch((err2:any) => {
                        reject(err2.message ? err2.message : err2);
                  });
               } else {
                  resolve(data.Users);
               }
            }
         });
      });
   }

   static getUser(userPoolId:string, userId:string):Promise<AWSUserInfo> {
      let result;
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let params = {
         UserPoolId: userPoolId, /* required */
         Username: userId, /* required */
         };
      let creds = AWS.config.credentials;
      console.info(creds ? creds.sessionToken : "creds is null" );

      return new Promise((resolve,reject) => {
         cognitoidentityserviceprovider.adminGetUser(params, function(err:any, data:any) {
         if (err) {
               // an error occurred
               console.error(err, err.stack);
               reject(err.message);
         } else {
               resolve(data);
         }
         });
      });
   }

   // todo:nexttoken
   static retrieveGroupsForUser(userPoolId:string, userId:string):Promise<any[]> {
      let result;
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let params = {
         UserPoolId: userPoolId, /* required */
         Username: userId, /* required */
         Limit: 50,
   //            NextToken: 'STRING_VALUE'
         };
      return new Promise((resolve,reject) => {
         cognitoidentityserviceprovider.adminListGroupsForUser(params, function(err:any, data:any) {
         if (err) {
               // an error occurred
               console.error(err, err.stack);
               reject(err.message);
         } else {
               // successful response
               result = data.Groups;
               resolve(data.Groups);
         }
         });
      });
   }

   // todo:nexttoken
   // todo: this implementation of nexttoken does not work...
   static listGroups(userPoolId:string, nextToken?:string):Promise<any[]> {
      let result;
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let params = {
         UserPoolId: userPoolId, /* required */
         Limit: 7,
         NextToken: nextToken
         };
      return new Promise((resolve,reject) => {
         cognitoidentityserviceprovider.listGroups(params, function(err:any, data:any) {
         if (err) {
               // an error occurred
               console.error(err, err.stack);
               reject(err.message);
         } else {
               result = data.Groups;
//               let xx = result.splice(0);
               if (data.NextToken) {
                  AWSUserAdmin.listGroups(userPoolId, data.NextToken)
                  .then((data2:any) => {
                     let result2:any[] = data2 ? data2 : [];
                     let yy:any[] = [];
                     result.forEach((item) => {
                        yy.push(item);
                     });
                     result2.forEach((item2) => {
                           yy.push(item2);
                     });
                     resolve(yy);
                  })
                  .catch((err2:any) => {
                        reject(err2.message ? err2.message : err2);
                  });
               } else {
                  resolve(result);
               }
         }
         });
      });
   }

   static enableUser(userPoolId:string, userId:string):Promise<any[]|void> {
      let result;
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let params = {
         UserPoolId: userPoolId, /* required */
         Username: userId, /* required */
         };
      return new Promise((resolve,reject) => {
         cognitoidentityserviceprovider.adminEnableUser(params, function(err:any, data:any) {
         if (err) {
               // an error occurred
               console.error(err, err.stack);
               reject(err.message);
         } else {
               resolve();
         }
         });
      });
   }

   static disableUser(userPoolId:string, userId:string):Promise<any[]|void> {
      let result;
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let params = {
         UserPoolId: userPoolId, /* required */
         Username: userId, /* required */
         };
      return new Promise((resolve,reject) => {
         cognitoidentityserviceprovider.adminDisableUser(params, function(err:any, data:any) {
         if (err) {
               // an error occurred
               console.error(err, err.stack);
               reject(err.message);
         } else {
               resolve();
         }
         });
      });
   }

   static deleteUser(userPoolId:string, userId:string):Promise<any[]|void> {
      let result;
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let params = {
         UserPoolId: userPoolId, /* required */
         Username: userId, /* required */
         };
      return new Promise((resolve,reject) => {
         cognitoidentityserviceprovider.adminDeleteUser(params, function(err:any, data:any) {
         if (err) {
               // an error occurred
               console.error(err, err.stack);
               reject(err.message);
         } else {
               resolve();
         }
         });
      });
   }

   static updateCustomAttributes(userPoolId:string, userId:string, userAttributes:any):Promise<any[]|void> {
      let result;
//      let userPoolId = this._userPoolId;
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let params = {
         UserAttributes: userAttributes,
         UserPoolId: userPoolId, /* required */
         Username: userId, /* required */
         };
      return new Promise((resolve,reject) => {
         cognitoidentityserviceprovider.adminUpdateUserAttributes(params, function(err:any, data:any) {
         if (err) {
               // an error occurred
               console.error(err, err.stack);
               reject(err.message);
         } else {
               resolve();
         }
         });
      });
   }

   static addUserToGroup(userPoolId:string, userId:string, groupName:string):Promise<any[]|void> {
      let result;
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let params = {
         GroupName: groupName,
         UserPoolId: userPoolId, /* required */
         Username: userId, /* required */
         };
      return new Promise((resolve,reject) => {
         cognitoidentityserviceprovider.adminAddUserToGroup(params, function(err:any, data:any) {
         if (err) {
               // an error occurred
               console.error(err, err.stack);
               reject(err.message);
         } else {
               resolve();
         }
         });
      });
   }

   static removeUserFromGroup(userPoolId:string, userId:string, groupName:string):Promise<any[]|void> {
      let result;
      let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
      let params = {
         GroupName: groupName,
         UserPoolId: userPoolId, /* required */
         Username: userId, /* required */
         };
      return new Promise((resolve,reject) => {
         cognitoidentityserviceprovider.adminRemoveUserFromGroup(params, function(err:any, data:any) {
         if (err) {
               // an error occurred
               console.error(err, err.stack);
               reject(err.message);
         } else {
               resolve();
         }
         });
      });
   }

   
//    static addCustomAttributes(userPoolId:string, userId:string, userAttributes:any):Promise<any[]|void> {
//       let result;
//       let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
//       let params = {
//          UserAttributes: userAttributes,
//          UserPoolId: userPoolId, /* required */
//          Username: userId, /* required */
//          };
//       return new Promise((resolve,reject) => {
//          cognitoidentityserviceprovider.adminDisableUser(params, function(err:any, data:any) {
//          if (err) {
//                // an error occurred
//                console.error(err, err.stack);
//                reject(err.message);
//          } else {
//                resolve();
//          }
//          });
//       });
//    }

} 