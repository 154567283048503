import { BNBOAreaViewerDialog } from "src/BNBOModule/BNBOAreaViewerDialog";
import { BNBOOpgørelse } from "src/BNBOModule/BNBOOpgørelse";
import { BNBOSFEStatus, BNBOSingleInfo } from "src/BNBOModule/BNBOSFEStatus";
import OwnershipDiagram from "src/compentsModus/OwnershipDiagram";
import { SearchWindow } from "src/compentsModus/SearchWindow";
import { ObliqueViewer } from "src/components/ObliqueViewer";
import { PlotScreen } from "src/components/PlotScreen";
import { PropertyInformationDialog } from "src/components/PropertyInformationDialog";

export enum WindowId {
   ShowSearchField, InformationWindow, GettingStarted, ShowReleases, Legend, ShowLegendButton, DemographyReportDialog, StreetView,
   SelectCircle, GenerateReportDialog, GenerateReportResultDialog, PropertyInformationDialog, CompanyInformationDialog, ObliqueViewer, SaveMapDialog, SaveLinkDialog, SavedLinkDialog,
   CatchmentSelector, SpatialSelection, CustomerSettingsEditor, UserSettingsEditor, MapSettingsEditor, PointDistanceTable, ConfirmLayerList, FileTooBig, NameOnCreation, DownloadLayerDialog, GDPRNoticeApproval,
   Explore, PlotScreen,
   LoadedSettings, UserAccessRights, SimulateOtherUser, BNBOMarkAreaEditor,
   CloudSaveDialog, ViewCloudSaveDialog , AdminCloudSaveDialog, PersonInformationDialog, OwnerShipDiagram, CondominiumDialog, MapLinkOverview, 
   BNBODialog, BNBOAreaEditor, BNBOSingleElement, BNBOSFEStatus, BNBOOpgørelse, BNBOAreaViewer,

   // Add new windows here
};

export enum WindowState {
   Open='open', Minimized='mini', Closed='closed', Closing='closing'
}

export interface ManagedWindow {
   id: WindowId;
   uid?: string;
   state: WindowState;
   updateCounter: number;
   windowParams?: any;
}

export interface State {
   windows: ManagedWindow[];
}


// ACTIONS
export enum WindowStateActions {
   Open, Close, Minimize, Focus, SendParams, Disconnect
}

export interface Open {
   type: WindowStateActions.Open;
   windowId: WindowId;
   params?: any;
}

export interface Close {
   type: WindowStateActions.Close;
   windowId: WindowId | string;
}

export interface Minimize {
   type: WindowStateActions.Minimize;
   windowId: WindowId | string;
}

export interface Focus {
   type: WindowStateActions.Focus;
   windowId: WindowId | string;
}

export interface SendParams {
   type: WindowStateActions.SendParams;
   windowId: WindowId | string;
   params: any;
}

export interface Disconnect {
   type: WindowStateActions.Disconnect;
   windowId: WindowId | string;
   nextId: string;
}

export const openWindow = (windowId: WindowId, params?: any):Open => ({
   type: WindowStateActions.Open,
   windowId,
   params
})

export const closeWindow = (windowId: WindowId):Close => ({
   type: WindowStateActions.Close,
   windowId,
})

export const sendParams = (windowId: WindowId, params: any):SendParams => ({
   type: WindowStateActions.SendParams,
   windowId,
   params
})

export const minimizeWindow = (windowId: WindowId):Minimize => ({
   type: WindowStateActions.Minimize,
   windowId,
})

export const focusWindow = (windowId: WindowId):Focus => ({
   type: WindowStateActions.Focus,
   windowId,
})

export type Actions = Open | Close | Minimize | Focus | SendParams | Disconnect;

export function windowReducer(state: State, action: Actions): State {
   switch (action.type) {
      case WindowStateActions.Open:
         if (state.windows.find(w => w.id === action.windowId)) {
            return { ...state, windows: state.windows.map(w => w.id === action.windowId ? { ...w, state: WindowState.Open, windowParams: action.params } : w)};
         }
         return { ...state, windows: [...state.windows, { id: action.windowId, state: WindowState.Open, windowParams: action.params, updateCounter: 1 }] };

      case WindowStateActions.Close:
         return { ...state, windows: state.windows.filter(w => w.id !== action.windowId) };
      case WindowStateActions.Minimize:
         return { ...state, windows: state.windows.map(w => w.id === action.windowId ? { ...w, state: WindowState.Minimized, updateCounter: w.updateCounter++ } : w) };
      case WindowStateActions.Focus:
         return { ...state, windows: state.windows.map(w => w.id === action.windowId ? { ...w, state: WindowState.Open } : w) };
      case WindowStateActions.SendParams:
         return { ...state, windows: state.windows.map(w => w.id === action.windowId ? { ...w, windowParams: action.params, updateCounter: w.updateCounter++ } : w) };
      case WindowStateActions.Disconnect:
         return { ...state, windows: state.windows.filter(w => w.id !== action.windowId) };
      default:
         return state;
   }
}


