import { AppMessagesContext, Localization } from "@viamap/viamap2-common";
import { useContext, useState, createContext, useRef } from "react";
import { Button } from "react-bootstrap";
import { FileLoader } from "./FileLoader"
import { ProtectedFeature } from "./ProtectedFeature";
import { Feature } from "src/states/ApplicationStateFeatures";
import { FileImportOptions, FileImportType, ParsedFile } from "./FileImportHooks";
import { GlassInfoButton } from "./GlassButtons";

export const FileImportContext = createContext<FileImporterContext>({importState: {}, importCallBack: () => {}})

export default function FileImporter(props: {children:JSX.Element | JSX.Element[]}) {
  let fileWindow = useRef(null);
  let tempFileImportOptions = useRef<FileImportOptions | null>(null) 

  const {dispatch: appMessageDispatch} = useContext(AppMessagesContext)
  const [fileImportOtions, setFileImportOptions] = useState<FileImportOptions | null>(null)
  const [fileState, setFileState] = useState("A")

  function handleCallbacks(fileOptions:FileImportOptions) {
    switch (fileOptions.fileImportType) {
      case FileImportType.FileExplore:
        if (fileWindow.current) {
          tempFileImportOptions.current = fileOptions;
          (fileWindow.current as any).click();
        }
        return;
      case FileImportType.FileFetch:
          if (fileOptions.url) {
            setFileImportOptions(fileOptions)
          }
          break;
      case FileImportType.ImportFiles:
      case FileImportType.Drop:
      if (fileOptions.files) {
        setFileImportOptions(fileOptions)
      }
        break;
      default:
        throw new Error("Error FileImportType: " + fileOptions.fileImportType)
      break;
    }
  }

  function handleEvents(a: React.ChangeEvent<HTMLInputElement>) {
    if (tempFileImportOptions.current && a.target.files) {
      let fileOptions = tempFileImportOptions.current
      
      handleCallbacks({
        ...fileOptions,
        files: a.target.files,
        fileImportType: FileImportType.ImportFiles,
      })
    }
  }
  
  function handleFinish() {
    setFileImportOptions(null);
    if (fileWindow.current) {
      (fileWindow.current as any).value = null;
    }
  }
  
  return (
    <FileImportContext.Provider value={{importState:{a:fileState}, importCallBack:handleCallbacks}} >
      <FileLoader fileImportOptions={fileImportOtions || null} finishCallback={handleFinish} />
    {props.children}
    <input ref={fileWindow} style={{display:"none"}} type="file" id="fileImporter" multiple onChange={(event) => handleEvents(event)} />
    </FileImportContext.Provider>
  )
}

type FileImporterContext = {
  importState: any,
  importCallBack: (fileOptions:FileImportOptions) => void,
}

export function SimpleImportButton() {
  let {importState: importST, importCallBack: importCB} = useContext(FileImportContext)
  return (

    <button
      className='GlassButton'
      style={{ float: "right", display: "inline-block", height: "32px"}}
      onClick={() => {importCB({fileImportType:FileImportType.FileExplore})}}
      >
      {Localization.getText("Add Layer")}
    </button>
  )
}

export function ImportButton() {
  let {importState: importST, importCallBack: importCB} = useContext(FileImportContext)
  
  const [value, setValue] = useState("Click Me")

  function handleSucces(file: ParsedFile) {
    setValue(file.meta.name)
  }

  return <Button style={{position:"relative", zIndex:90000}} onClick={(e) => {importCB({fileImportType:FileImportType.FileExplore, callBackSuccess:handleSucces})}}>{value}</Button>
}

export function AlertClick() {
  let {importState: importST, importCallBack: importCB} = useContext(FileImportContext)

  return (
    <GlassInfoButton onClick={() => {importCB({fileImportType:FileImportType.FileExplore})}} >
      <span style={{fontSize: "16px", fontWeight:"semibold"}}>{Localization.getText("Add Layer")}</span><br/>
      <span style={{fontSize: "10px", fontWeight:"semibold"}}>{Localization.getText("Drag and Drop")}</span>
    </GlassInfoButton>
  )
}
