import { AppMessagesContext, Localization, actionSetErrorMessage } from "@viamap/viamap2-common";
import { useContext } from "react";
import { ColorByValueStyling, DataDivision, DataDivisionList, DataDivisionType, DivisionStylingType } from "src/common/managers/Types";
import { CatchmentStateContext, actionSetCatchmentState, actionGenerateCatchmentSimple, CalculationMode } from "src/states/CatchmentState";
import { LegendScale } from "./LegendScale";
import { MapitStateContext } from "src/states/MapitState";
import { CatchmentInterface } from "src/managers/CatchmentInterface";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import { GenerateGeom } from "src/managers/GenerateGeom";

/**
 * A wrapper for LegendScale focussing on its use for Catchment coloring
 * @param props 
 */
export const LegendScaleForCatchment = (props:{callBackOnEditStateChange?:(isEditing:boolean)=>void;}) => {
   const {state:catchmentState, dispatch:catchmentStateDispatch} = useContext(CatchmentStateContext);
   const { dispatch: appMessageDispatch } = useContext(AppMessagesContext);
   const { state: mapitState} = useContext(MapitStateContext);
   const {hasAccessToFeature} = useContext(ApplicationStateContext);
   
   function callBackOnCatchmentStyleChange(newStyle:ColorByValueStyling) {
      console.info("got callback "+ JSON.stringify(newStyle));

      // Convert style to catchmentState
      if (newStyle && newStyle.divisions) {
        // sort divisions nummerically
        let ns = newStyle.divisions.list.sort((aDiv,bDiv) => { 
          let a = aDiv.value && Number.parseInt(aDiv.value, 10) || 0; 
          let b = bDiv.value && Number.parseInt(bDiv.value, 10) || 0; 
          return a > b ? 1 : a < b ? -1 : 0;
        });
        let travelTimes:number[] = [];
        let colorScale:any[] = [];
        let legendLabels:string[] = [];
        ns.forEach((dd,idx) => {
          let num = Number.parseInt(dd.value!,10);
          if (num > 120) {
            appMessageDispatch(actionSetErrorMessage(Localization.getText("Error: Travel Time Interval cannot be more than 120")));
          }
          travelTimes.push(num*60);
          colorScale.push(dd.color!);
          legendLabels.push(dd.label || "");
        });
        // clear cache
        CatchmentInterface.clearCache();
        let newCatchmentState = { ...catchmentState, travelTimes:travelTimes, colorScale:colorScale, legendLabels:legendLabels}
         catchmentStateDispatch(actionSetCatchmentState(newCatchmentState));
         if (catchmentState.calculationMode === CalculationMode.Simple_RegenerateAfterEachParameterChange) {
            catchmentStateDispatch(actionGenerateCatchmentSimple(newCatchmentState , mapitState, catchmentState.catchmentLocation!));
         }
      }
    }

    function SpatialCheck(feature:any):number {
      if (!hasAccessToFeature(Feature.SpatialExportGeoJsonPreview)) {
        return 0
      }
      if (feature) {
        let spatialReturn = GenerateGeom.spatialSelectionGeoJSON(mapitState, feature.geometry);
        return Object.keys(spatialReturn).reduce((b,a) => {return spatialReturn[a].length + b},0)
      }
      return 0
    }

   //  useEffect(()=> {
      // Convert State to style
      let dataDivisions:DataDivision[] = [];
      let previousValue:null|number = null;
      let previousOverlappingHits=0;  // travel polygons overlap so points. Avoid counting more than once.
      catchmentState.travelTimes.forEach((time, idx) => {
        // calculate hit count if polygons has been created
        let hits=0;
        if (catchmentState.polygons && catchmentState.showSpatialItemsCounts) {
          let featureMatchingTime = catchmentState.polygons.features.filter((feat) => feat.properties.time === time);
          if (featureMatchingTime) {
            let polyHits = SpatialCheck(featureMatchingTime[0]);
            hits = polyHits - previousOverlappingHits;
            previousOverlappingHits += hits;
          }
        }
        dataDivisions.push(
          {
            from:previousValue,
            to:time, 
            value:""+time/60, 
            color:catchmentState.colorScale[idx],
            label:catchmentState.legendLabels[idx],
            count:hits
          });
        previousValue = time;
      });
      let divisions:DataDivisionList = {type:DataDivisionType.Discrete, valuesAreNumbers: true, otherValuesCount:0, list:dataDivisions};
      let newS:ColorByValueStyling = {dataColumn:-1, useColorRange:undefined, divisions:divisions, divisionStylingType:DivisionStylingType.Custom};

   return (
      <div className="Group">
       <LegendScale
         allowEdit={true} // {catchmentState.calculationMode === CalculationMode.Simple_RegenerateAfterEachParameterChange}
         colorRanges={[]}
         style={newS}
         maxDivisions={6}
         callBackOnEditStateChange={(isEditing:boolean)=>{
          props.callBackOnEditStateChange && props.callBackOnEditStateChange(isEditing);
         }}  
         callBackOnStyleChange={(newStyle)=> {
           callBackOnCatchmentStyleChange(newStyle);
          }}
          showElementCounts={Boolean(catchmentState.showSpatialItemsCounts)}
      />
      </div>
   )

}

