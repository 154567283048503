import {
  FeatureLayer,
  LayerVariantSelectors,
  LayerVariantValues,
} from "src/managers/WmsLayerFunc";
import { BsXLg } from "react-icons/bs";
import { Localization } from "@viamap/viamap2-common";
import {
  FilterSpec,
  FilterType,
  FilterValue,
} from "src/states/ExploreSearchState";
import { MitCheckBox, MitRadioBox } from "./ComponentUtils";
import { OpacityAdjuster, Slider } from "./MitInputComponents";
import { ADModalBody, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassCheckbox} from "./MitGlassComponents";

type props<T extends string[]> = {
  showWindow: number;
  layers: Record<T[number], FeatureLayer>;
  activeLayers: T;
  editingLayers: T;
  //
  callBackToSetVisibility: (key: T[number], show: boolean) => void;
  onChange: (key: T[number], value: any) => void;
  callBackToHideWindow: () => void;
  callBackToStopEditing: (key: T[number]) => void;
};

/**
 * @abrevation FLE
 * @param props
 * @returns
 */
export function FeatureLayerEditor<T extends string[]>(props: props<T>) {

  function closeAll() {
    props.callBackToHideWindow();
  }

  function onChange(a, b) {
    props.onChange(a, b);
  }

  function close(key: T[number]) {
    props.callBackToStopEditing(key);
  }

  if ((props.editingLayers || []).length == 0) {
    return <></>;
  }

  return (
    <AdvancedDragModal title={Localization.getText("Layer Settings")}
      onClose={closeAll}
      PosDefault={{width:"165px"}}
      variant={["Small","NSWhite"]}
    >
      <ADModalBody>
        {props.editingLayers.map((a) => {
          return (
            <FeatureLayerEditorSingle
              key={a}
              layerKey={a}
              layer={props.layers[a]}
              close={(key) => close(key)}
              onChange={onChange}
              activeLayers={props.activeLayers}
              callBackToSetVisibility={props.callBackToSetVisibility}
            />
          );
        })}
      </ADModalBody>
    </AdvancedDragModal>
  );
}

function FeatureLayerEditorSingle(props: {
  layerKey: string;
  layer: FeatureLayer;
  close: (key: string) => void;
  onChange: (key: string, val: any) => any;

  activeLayers: string[];
  callBackToSetVisibility: (key: string, checked: boolean) => any;
}) {
  function onChange(val) {
    props.onChange(props.layerKey, val);
  }

  const lang = Localization.getLanguage();
  const layer = props.layer;

  return (
    <div className="FeatureLayerEditor_Single FLE-S">
      <div className="FLE-Head">
        <GlassCheckbox
          checked={(props.activeLayers || []).includes(props.layerKey)}
          onClick={(a, b) => props.callBackToSetVisibility(props.layerKey, b)}
        />
        <span className="FLE-Title">
          {layer.layer.translationTable?.[lang]?.[layer.layer.label] ||
            layer.layer.label}
        </span>
        <FLECloseBtn onClick={() => props.close(props.layerKey)} />
      </div>
      <div className="FLE-Body">
        {props.layer.getType() === "WMS" ? (
          <WMSstyling values={layer.layer.variantValues} onChange={onChange} />
        ) : null}
        <VariantSelectorsParser
          parse={layer.layer.variantSelectors}
          values={layer.layer.variantValues}
          onChange={onChange}
        />
      </div>
    </div>
  );
}

export function FLECloseBtn(props: { onClick: () => void }) {
  return (
    <div onClick={props.onClick} className="FLE-close">
      <BsXLg />
    </div>
  );
}

type VariantSelectorsParserProps = {
  onChange: (value: any) => any;
  parse?: LayerVariantSelectors;
  values?: LayerVariantValues;
};
export function VariantSelectorsParser(props: VariantSelectorsParserProps) {
  let keys = Object.keys(props.parse || {});

  function onChange(key: string, value: any) {
    props.onChange({
      ...(props.values || {}),
      [key]: value,
    });
  }

  return (
    <>
      {/* {props.values?.["opacity"] as unknown as number ?? 100} */}
      {keys.map((a: keyof LayerVariantSelectors) => {
        let filter: FilterSpec | undefined = props.parse?.[a];
        let value = props.values?.[a] || undefined;
        if (typeof a === "string")
          return (
            <FilterSpecInput
              key={a}
              filter={filter}
              onChange={(val) => onChange(a, val)}
              value={value}
            />
          );
        return <></>;
      })}
    </>
  );
}

type WMS_StylingProps = {
  onChange: (value: any) => any;
  parse?: LayerVariantSelectors;
  values?: LayerVariantValues;
};
function WMSstyling(props: WMS_StylingProps) {
  function onChange(key: string, value: any) {
    props.onChange({
      ...(props.values || {}),
      [key]: value,
    });
  }

  return (
    <>
      <InputGroup title={Localization.getText("Transparency")}>
        <OpacityAdjuster
          value={props.values?.["raster-opacity"]?.[0] ?? 1}
          onChange={(val) => onChange("raster-opacity", [val])}
        />
      </InputGroup>
      <InputGroup title={Localization.getText("Saturation")}>
        <Slider
          value={props.values?.["raster-saturation"]?.[0] ?? 0}
          onChange={(val) => onChange("raster-saturation", [val])}
          step={0.05}
          min={-1}
          max={1}
        />
      </InputGroup>
      <InputGroup title={Localization.getText("Hue")}>
        <Slider
          value={props.values?.["raster-hue-rotate"]?.[0] ?? 0}
          onChange={(val) => onChange("raster-hue-rotate", [val])}
          step={1}
          min={0}
          max={360}
        />
      </InputGroup>
    </>
  );
}

type inputProps<T> = {
  value: T;
  onChange: (val: T) => any;
};

export function InputGroup(props: { title; children }) {
  return (
    <div className="FLE-InputGroup">
      <div className="FLE-InputTitle">{props.title}</div>
      <div className="FLE-Input">{props.children}</div>
    </div>
  );
}

export function FilterSpecInput(props: {
  filter: FilterSpec | undefined;
  value?: FilterValue;
  onChange?: (a: any) => void;
}) {
  const lang = Localization.getLanguage();
  const value = props.value ?? props.filter?.default ?? [];
  const title =
    props.filter?.translationTable?.[lang]?.[props.filter.title] ||
    props.filter?.title ||
    "";
  const transVal =
    props.filter?.translationTable?.[lang]?.[value[0]] ||
    props.filter?.options?.[value[0]] ||
    value[0] ||
    "";

  if (props.filter == undefined) {
    return <></>;
  }

  switch (props.filter.filterType) {
    case FilterType.SelectTextSlider:
      return (
        <InputGroup title={title}>
          <div>{transVal}</div>
          <Slider
            value={Object.keys(props.filter.options || {}).findIndex(
              (a) => a == value[0]
            )}
            onChange={(a) =>
              props.onChange?.([Object.keys(props?.filter?.options || {})[a]])
            }
            step={1}
            min={0}
            max={
              Number(Object.keys(props.filter?.options || {}).length || 0) - 1
            }
          />
        </InputGroup>
      );

    case FilterType.SingleSelect:
      return (
        <InputGroup title={title}>
          {Object.keys(props.filter.options || {}).map((a) => (
            <GlassCheckbox
              key={a}
              className="radio"
              onChange={() => props.onChange?.([a])}
              checked={value[0] == a}
            >
              {props.filter?.translationTable?.[lang]?.[a] ||
                props.filter?.options?.[a] ||
                a ||
                ""}
            </GlassCheckbox>
          ))}
        </InputGroup>
      );
    case FilterType.SelectText:
    case FilterType.Select:
      return (
        <InputGroup title={title}>
          {Object.keys(props.filter.options || {}).map((a) => (
            <GlassCheckbox
              key={a}
              onClick={(ev, l) => {
                props.onChange?.([
                  ...value.filter((b) => a !== b),
                  ...(l ? [a] : []),
                ]);
              }}
              checked={(value || []).includes(a) || false}
              value={a}
            >
              {props.filter?.translationTable?.[lang]?.[a] ||
                props.filter?.options?.[a] ||
                a ||
                ""}
            </GlassCheckbox>
          ))}
        </InputGroup>
      );
    case FilterType.Number: 
    return (
      <InputGroup title={title}>
        <div>{transVal}</div>
        <Slider
          value={Object.keys(props.filter.options || {}).findIndex(
            (a) => a == value[0]
          )}
          onChange={(a) =>
            props.onChange?.([Object.keys(props?.filter?.options || {})[a]])
          }
          step={1}
          min={0}
          max={
            Number(Object.keys(props.filter?.options || {}).length || 0) - 1
          }
        />
      </InputGroup>
    );

    case FilterType.String:
    case FilterType.DateRange:
    case FilterType.IntegerRange:
    case FilterType.FilterType:
    case FilterType.Integer:
    case FilterType.StaticValue:
    case FilterType.IntegerSet:
    case FilterType.BoundingBox:
    case FilterType.Polygon:
      break;
  }
  return <></>;
}
