import Button from 'react-bootstrap/Button'
import Form from 'react-bootstrap/Form'
import {Localization, SettingsManager} from "@viamap/viamap2-common";
import {GenerateGeom} from '../managers/GenerateGeom';
import {ObliqueFunc, ViewDirection} from '../managers/ObliqueFunc';
import {ObliqueInterfaces} from '../managers/ObliqueInterfaces';
import { MitLatLng } from 'src/managers/MapFacade';
import { MitLoading } from './ComponentUtils';
import { ADModalBody, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { useId, useEffect, useState } from 'react';
import { useWindowContext } from 'src/WindowManager/useWindowContext';
import { closeWindow, WindowId } from 'src/WindowManager/WindowState';


// ---------------------------------------------------------------------------------------------------------------------
type ImageTileProps = {
  point:any[];
  alternativePoint?:any[];
  image:HTMLImageElement;
  width:number;
  height:number;

  isLoading?:boolean;
  markerType:("Dot"|"Circle"|"None");
  pictureDate?: Date;
};
type ImageTileState = ImageTileProps;

/* Viewer receiving an image object */
export function ObliqueTileImage(props:ImageTileProps) {
  let Oblique_id = useId()

  useEffect(() => {
    onLoadImage()
  },[props])

  async function onLoadImage() {
    let c=document.getElementById(Oblique_id) as HTMLCanvasElement;
    let ctx=c && c.getContext("2d");
    let x = props.point![0];
    let y = props.point![1];

    // set viewport size relative to browser window.
    if (c.height !== Math.min(props.height, window.innerHeight - 200))
    c.height = Math.min(props.height, window.innerHeight - 200);
    if (c.width !== Math.min(props.width, window.innerHeight -200))
    c.width = Math.min(props.width, window.innerHeight -200);
    // if viewport is smaller than image. calculate offset to display center portion of image.
    let viewPortOffsetX = Math.max(0,Math.floor((props.width - c.width)/2));
    let viewPortOffsetY = Math.max(0,Math.floor((props.height - c.height)/2));
    // if target is off-center modify offsets to display portion of image closer to target.
    let targetOffsetX = x - props.width/2;
    let targetOffsetY = y - props.height/2;
    // Move the viewport af far as possible, if required
    viewPortOffsetX = viewPortOffsetX + Math.min(viewPortOffsetX,Math.max(-viewPortOffsetX, targetOffsetX));
    viewPortOffsetY = viewPortOffsetY + Math.min(viewPortOffsetY,Math.max(-viewPortOffsetY, targetOffsetY));

    if (ctx) {
      props.image && ctx.drawImage(props.image, viewPortOffsetX, viewPortOffsetY, c.width, c.height, 0, 0, c.width, c.height);
      if (props.markerType !== "None") {
        drawMarker(ctx, x-viewPortOffsetX, y-viewPortOffsetY, props.markerType);
        if (props.alternativePoint) {
          drawMarker(ctx, props.alternativePoint[0]-viewPortOffsetX, props.alternativePoint[1]-viewPortOffsetY, "Circle", "red");
        }
      }
      if (props.pictureDate) {
        let dateText = props.pictureDate!.toLocaleDateString();
        drawInformation(ctx, 10, c.height-20, dateText, "14px Verdana", "darkslategray", "lightgray");
      }
    }
  }

  function drawMarker(ctx:any, x:number, y:number, markerType: ImageTileProps["markerType"], color?:string) { 
  
    if (ctx) {
      ctx.beginPath();
      ctx.strokeStyle = color || "blue";
    
      ctx.arc(x, y, 10, 0, 2 * Math.PI);
      if (markerType === "Dot") {
        ctx.fillStyle = color || 'blue';
        ctx.fill();
      }
      ctx.stroke();
    }
  }

  function drawInformation(ctx:any, x:number, y:number, infoString: string, font?:string, color?:string, bgColor?:string) { 
  
    if (ctx) {
      if (infoString) {
        ctx.beginPath();
        drawTextBG(ctx, infoString, x, y, font, color, bgColor);
      }

      let debug=false;
      if (debug) {
        ctx.beginPath();
        drawTextBG(ctx, "x,y:"+Math.floor(x)+","+Math.floor(y), 20, 60);
        drawTextBG(ctx, "width, height:"+ctx.canvas.width+","+ctx.canvas.height,20,80);
      }
    }
  }
  
  function markerX(ctx:any, x:number, y:number ,text:string) {
      ctx.beginPath();
      ctx && ctx.arc(x, y, 10, 0, 2 * Math.PI);
      ctx && ctx.stroke();   
      ctx && ctx.strokeText(text,Math.abs(x-10),Math.abs(y-10));
  }
  
  /// expand with color, background etc.
  function drawTextBG(ctx:any, txt:string, x:number, y:number, font?:string, color?:string, bgColor?:string) {
    /// lets save current state as we make a lot of changes        
    ctx.save();

    /// set font
    ctx.font= font || "14px Verdana";

    /// draw text from top - makes life easier at the moment
    ctx.textBaseline = 'top';

    /// color for background
    if (bgColor) {
      ctx.fillStyle = bgColor || '#f50';

      /// get width of text
      var width = ctx.measureText(txt).width;

      /// draw background rect assuming height of font
      ctx.fillRect(x, y, width, parseInt(ctx.font, 10));
    }

    /// text color
    ctx.fillStyle = color || '#000';

    /// draw text on top
    ctx.fillText(txt, x, y);

    /// restore original state
    ctx.restore();
  }
 
    return (
      // <div className="oblique-tile">
      <canvas
        id={Oblique_id}
        onLoad={() => onLoadImage()}
      />
      // </div>
    );
}

type Props = {
  showWindow: number;
  imageProps:{latlng:MitLatLng};
  onFormClose?: ()=>void;
};
// type State = {
//   showWindow: boolean;
//   imageProps:{latlng:MitLatLng};
//   onFormClose?: ()=>void;
//   path?:string;
//   direction: ViewDirection;
//   zoomFactor: number;
//   year:string;
//   shallImageBeCentered: boolean;
//   point?:any[];
//   alternativePoint?:any[];
//   image?:any;
//   width?:number;
//   height?:number;
//   isLoading?:boolean;
//   runOnServer?:boolean;
//   markerType:MarkerType;
//   pictureDate?:Date;
// };

type imgView = {
  direction: ViewDirection;
  zoom: number;
  year: string;
}

type ImageMData = {
  point:any[];
  image:any;
  width:number;
  height:number;
  pictureDate:Date;
  alternativePoint?:any[];
}

export function ObliqueViewer (props:Props) {
  const AVAILABLE_YEARS = SettingsManager.getSystemSetting("obliquePhotoAvailableYears");
  const DEFAULT_YEAR = SettingsManager.getSystemSetting("obliquePhotoDefaultYear");

  const {dispatch: windowDispatch} = useWindowContext()
  
  const [imgView, _setImgView] = useState<imgView>({direction: ViewDirection.NORTH,zoom: ObliqueFunc.defaultZoom,year:""+DEFAULT_YEAR})
  const [markerType, setMarkerType] = useState<ImageTileProps["markerType"]>("Dot");
  const [image,setImage] = useState<ImageMData | null>(null);
  const [isLoading,setIsLoading] = useState(false)
  
  if (!(AVAILABLE_YEARS && DEFAULT_YEAR)) {
    throw new Error(`Missing system settings. "obliquePhotoAvailableYears" and "obliquePhotoDefaultYear" are required`)
  }

  const shallImageBeCentered = true
  const runOnServer = true

  function setImgView(b:{}) {
    _setImgView((a) => ({...a, ...b}))
  }

  useEffect(() => {
    setImgView({direction: ViewDirection.NORTH,zoom: ObliqueFunc.defaultZoom,year:""+DEFAULT_YEAR})
  },[props.imageProps.latlng])

  useEffect(() => {
    const controller = new AbortController();
    if (props.showWindow) {
      setIsLoading(true)

      let latlng = props.imageProps.latlng;
      let direction = imgView.direction;
      let startZoom = imgView.zoom;
      let startYEar = imgView.year;
      
      recalculateViewModel(latlng, direction, startZoom, true ,startYEar).then((a) => {
        if (controller.signal.aborted) {
          return
        }
        setIsLoading(false)
        setImage(a)
      }).catch((err) => console.error(err))
      
    }
    return () => { controller.abort() }
  },[props.imageProps.latlng, imgView, props.showWindow])

  async function recalculateViewModel(
      latlng:MitLatLng, 
      direction:string, 
      zoomFactor:number, 
      shallImageBeCentered: boolean, 
      year:string, 
    ) 
    :Promise<{point:any[], image:any, width:number, height:number, pictureDate: Date, alternativePoint?:any[]}> {
    let utmCoords = GenerateGeom.convertFromLatLngToUTM({lat:latlng.lat, lng:latlng.lng});
    let result;
    // if (runOnServer) {
      let imageSpec = await ObliqueInterfaces.getObliqueImage(utmCoords, direction, zoomFactor, shallImageBeCentered, year);
      let pictureDate:Date = imageSpec && imageSpec.timeutc && new Date(imageSpec.timeutc) || new Date();
      result = {point:[imageSpec.x,imageSpec.y], image:imageSpec.image, width:imageSpec.width, height:imageSpec.height, pictureDate: pictureDate};
    // } else {
    //   result = await ObliqueFuncOnClient.getObliqueImage(
    //     (imageId:string) => {return ObliqueInterfaces.getFootprintAndCameraDataByImageId(imageId);}, 
    //     (coords:UtmCoords, direction2:ViewDirection, maxRows?:number) => {return ObliqueInterfaces.getBestFootprintsFromDatabase(coords, direction2.toString(), maxRows);},
    //     ObliqueClientImpl.combineImagesAndCropJimp,
    //     utmCoords, 
    //     direction, 
    //     zoomFactor, 
    //     shallImageBeCentered
    //   );
    // }

    return result;
  }
  
    // -----------------------------------------------------------------------------------------------

function onClickClose() {
  windowDispatch(closeWindow(WindowId.ObliqueViewer))
  props.onFormClose?.()
}

function onChangeDirection(e:any) {
  setImgView({direction:e.target.value});
}

function onChangeYear(e:any) {
  setImgView({year:e.target.value});
}

function onChangeMarkerType(e:any) {
  setMarkerType(e.target.value);
}

function onChangeZoomFactor(e:any) {
  let zoomFactor = Number.parseInt(e.target.value);
  Math.max(ObliqueFunc.minZoom, Math.min(ObliqueFunc.maxZoom, zoomFactor));
  
  setImgView({zoom:zoomFactor});
}

// left = 37
// up = 38
// right = 39
// down = 40
function onKeyPress(e:any) {

  let key = e.keyCode;
  switch(key) {
    case 40: // key down
    case 189: // -
      e.preventDefault();
      setImgView({zoom: imgView.zoom-1})
      break;
    case 38:  // key up
    case 187: // +
      e.preventDefault(); 
      setImgView({zoom: imgView.zoom+1})
      break;
    case 39: // right arrow
      {e.preventDefault();
      // mode to previous direction mode.
      const currentIndex = Object.keys(ViewDirection).findIndex((obj,idx) => { return obj === imgView.direction; });
      const newDir=Object.keys(ViewDirection)[(currentIndex + 1) %4];
      setImgView({direction: newDir})}
      break;
      case 37: // left arrow
      {e.preventDefault();
      // mode to previous direction mode.
      const currentIndex2 = Object.keys(ViewDirection).findIndex((obj,idx) => { return obj === imgView.direction; });
      const newDir=Object.keys(ViewDirection)[(currentIndex2 - 1) %4];
      setImgView({direction: newDir})}
      break;
    default:
  }
}

    // let RenderZoomFactor3= (
    //     <Form.Group 
    //         controlId={"formControlsNumrtic"+name}
    //         // bsSize="small"
    //         style={{paddingBottom:"5pt"}}
    //     >
    //         <Form.Label>ZoomFactor</Form.Label>
    //         <Form.Control 
    //             as="input" 
    //             type="number"
    //             size="sm"
    //             placeholder=""
    //             onChange={(e) => this.onChangeZoomFactor(e)}

    //             value={this.state.zoomFactor}
    //             disabled={false}
    //         />
    //     </Form.Group>
    // );
    let RenderZoomFactorButtons = [10,11,12,13,14].map((zoom) => { 
      let style= zoom === imgView.zoom ? "primary" : "outline-success";
      // show zoom as 1-5;
      let text = zoom-9;
      return (
        <Button key={"zoomButton"+zoom} type="button" variant={style} onClick={()=> onChangeZoomFactor({target:{value:zoom}})}>{text}</Button>
      );
    });
    let RenderZoomFactor= (
        <Form.Group 
            controlId={"formControlsNumrtic"+name}
            // bsSize="small"
            style={{paddingBottom:"5pt"}}
        >
            <Form.Label>{Localization.getText("Zoom Factor")}</Form.Label>
            <div className="btn-group mit-zoom-factor-button-list" role="group" aria-label="...">
            {RenderZoomFactorButtons}
            </div>            
        </Form.Group>
    );

    let RenderSelectDirection = (
        <Form.Group 
            controlId={"formControlsSelect"+name}
            // bsSize="small"
            style={{paddingBottom:"5pt"}}
        >
            <Form.Label>{Localization.getText("Direction")}</Form.Label>
            <Form.Control 
                as="select" 
                size="sm"
                onChange={(e) => onChangeDirection(e)}
                value={imgView.direction}
                disabled={false}
            >
                { [ViewDirection.NORTH,ViewDirection.EAST,ViewDirection.SOUTH,ViewDirection.WEST,ViewDirection.TOP].map ((option, i) => { 
                  let text=Localization.getText("ObliqueViewDirection:"+option);
                  return (
                    <option key={option+i.toString()} className="small" value={option}>
                        {text}
                    </option>
                );
                }
                ) 
                }
            </Form.Control>
        </Form.Group>
        );

    let RenderSelectYear = (
          <Form.Group 
              controlId={"formControlsSelect"+name}
              style={{paddingBottom:"5pt"}}
          >
              <Form.Label>{Localization.getText("Year")}</Form.Label>
              <Form.Control 
                  as="select" 
                  size="sm"
                  onChange={(e) => onChangeYear(e)}
                  value={imgView.year}
                  disabled={false}
              >
                  { AVAILABLE_YEARS.map ((option, i) => { 
                    let text=option.toString();
                    return (
                      <option key={option+i.toString()} className="small" value={text}>
                          {text}
                      </option>
                  );
                  }
                  ) 
                  }
              </Form.Control>
          </Form.Group>
          );

          let RenderSelectMarker = (
            <Form.Group 
                controlId={"formControlsSelect"+name}
                style={{paddingBottom:"5pt"}}
            >
                <Form.Label>{Localization.getText("Marker Type")}</Form.Label>
                <Form.Control 
                    as="select" 
                    size="sm"
                    onChange={(e) => onChangeMarkerType(e)}
                    value={markerType}
                    disabled={false}
                >
                    { (["Dot", "Circle", "None"] as ImageTileProps["markerType"][]).map ((option, i) => { 
                      let text = Localization.getText("ObliqueViewMarkerStyle:"+option);
                      return (
                        <option key={option+i.toString()} className="small" value={option}>
                            {text}
                        </option>
                    );
                    }
                    ) 
                    }
                </Form.Control>
            </Form.Group>
            );
        // let RenderMarkerTypeButtons = [MarkerType.Dot, MarkerType.Circle, MarkerType.None].map((mtype) => { 
        //   let style= mtype === markerType ? "btn-primary" : "btn-default";
        //   let text = Localization.getText("ObliqueViewMarkerStyle:"+mtype);
        //   return (
        //     <Button key={"markerType"+mtype} type="button" className={"btn "+style} onClick={()=> setMarkerType(mtype)}>{text}</Button>
        //   );
        // });
        // let RenderSelectMarkerz= (
        //     <Form.Group 
        //         controlId={"formControlsNumrtic"+name}
        //         bsSize="small"
        //         style={{paddingBottom:"5pt"}}
        //     >
        //         <Control.Label>{Localization.getText("Marker Type")}</ControlLabel>
        //         <div className="btn-group mit-zoom-factor-button-list" role="group" aria-label="...">
        //         {RenderMarkerTypeButtons}
        //         </div>            
        //     </Form.Group>
        // );

    if (props.showWindow) {
    return (
        <AdvancedDragModal
          variant={"NSWhite"}
          topUpdate={props.showWindow}
          allowFullscreen={true}
          allowUserMiniMize={true}
          PosDefault={{width:"600px",height:"600px", left:"50%", top:"50%", transform:"translate(-50%, -50%)"}}
          title={Localization.getText("Oblique Photo")}
          subHeaderContent={<>
                    {RenderSelectDirection}
                    {RenderZoomFactor}
                    {/* {RenderSelectCenterImage} */}
                    {RenderSelectYear}
                    {RenderSelectMarker}
                    {/* {RenderSelectServer} */}
                    </>}
          onClose={onClickClose}
        >
            
            
          

            <ADModalBody>
            {isLoading ? 
              <MitLoading messages={[
                {timeSeconds: 0, message: Localization.getText("Please wait")}, 
                {timeSeconds: 20, message: Localization.getText("The action takes longer than expected")}, 
              ]} />: null}
            <ObliqueTileImage
                point={image?.point || [0,0]}
                image={image?.image}
                width={image?.width|| 750}
                height={image?.height|| 750}
                isLoading={isLoading}
                markerType={markerType}
                alternativePoint={image?.alternativePoint}
                pictureDate={image?.pictureDate}
            />
            </ADModalBody>
        </AdvancedDragModal>
    );
    } else {
        return(null);
    }

}

