import { useContext, useState } from "react"
import { GlassButton } from "./GlassButtons"
import { LicensingContext, Localization } from "@viamap/viamap2-common"

export function BacktoMapit3Banner() {
   const [closed, setClosed] = useState(false)
   const {state} = useContext(LicensingContext)


   const returnUrl = (state.currentlicense?.productVariant || "").toLowerCase().includes("explorer")  ? "https://estateexplorer.viamap.net/" : "https://mapit3.viamap.net"

   if (closed) {
      return null
   }

   if (!window.location.search.toLowerCase().includes("gotomapit4")) {
      return null
   }

   return (
      <div className="gotomapit4-banner">
         {Localization.getLanguage() == "da" ? 
            <p>Velkommen til Mapit 4</p> :
            <p>Welcome to Mapit 4</p>
         }
         
         <div>
         <GlassButton onClick={(e) => window.open(returnUrl+"?backtomapit3","_self")}>{Localization.getText("Back to Mapit 3")}</GlassButton>
         <GlassButton onClick={(e) => {
            history.pushState({}, "", window.location.pathname + window.location.search.replace("?gotomapit4", ""));
            setClosed(true)
         }
         }>{Localization.getText("Close")}</GlassButton>
         </div>
      </div>
   )
}