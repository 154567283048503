import MapboxDraw from "@mapbox/mapbox-gl-draw";
import { Map } from "maplibre-gl";
import { useEffect, useState, useContext, useRef } from "react";
import { GenerateGeom } from "src/managers/GenerateGeom";
import { MapInteractionState, MapitStateContext, MapitWindowId, actionAddDataLayer, actionSetDrawContext, actionSetMapInteractionState, actionSetShowWindow } from "src/states/MapitState";
import tLength from "@turf/length";
import * as Turf from "@turf/turf"
import tArea from "@turf/area";
import { Localization } from "@viamap/viamap2-common";
import { MitCount } from "./ComponentUtils";
import ReactDOM from "react-dom";
import { LayerFunc } from "src/managers/LayerFunc";
import { ProtectedFeature } from "./ProtectedFeature";
import { Feature } from "src/states/ApplicationStateFeatures";
import { LayerInfo } from "src/common/managers/Types";
import { SheetFunc } from "src/managers/SheetFunc";
import { RightPaneContext, RightPaneModus, actionGoToModus } from "src/states/RightPaneState";
import { DrawingToolExtras } from "./DrawingToolsExtra";
import { ExtendWithMeasureMents } from "./DrawingToolsMeasure";
import { formatArea, formatLength } from "./DrawingToolFunctions";
import { PiCircle, PiLineSegment, PiPolygon, PiTrash } from "react-icons/pi";
import { GlassButton } from "./GlassButtons";
import { ExtendedMenuButton } from "./MitGlassComponents";
import { ADModalBody, ADModalFooter, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassFoldUdBox } from "src/propertyInfoTemplates/PropertyComponents";


enum areaUnits {
  kilometers = "kilometers",
  kilometres = "kilometres",
  meters = "meters",
  metres = "metres",
  centimetres = "centimetres",
  millimeters = "millimeters",
  acres = "acres",
  miles = "miles",
  yards = "yards",
  feet = "feet",
  inches = "inches",
  hectares = "hectares",
}

enum lengthUnits {
  miles = "miles",
  nauticalmiles = "nauticalmiles",
  inches = "inches",
  yards = "yards",
  meters = "meters",
  metres = "metres",
  kilometers = "kilometers",
  centimeters = "centimeters",
  feet = "feet",
}






// Not in use
function showMeasurements(featureCollection: any) {
  const features = featureCollection.features;
  const polygons = features.filter((a) => {return a.geometry.type === "Polygon"})
  const polyline = features.filter((a) => {return a.geometry.type === "LineString"})
  
  
  const segments = features.flatMap((a) => {
    return a.geometry.coordinates.map((a,i,j) => {
      if (i === 0) {
        return false
      }
      return [j[i === 0? j.length-1: i-1],j[i]]
    }).filter((a) => a)
  })
  
  
  if (features.length > 0) {
    features.forEach(a => {
      const length = tLength(a)
      
    });
  }
  if (polygons.length > 0) {
    polygons.forEach(a => {
      const area = tArea(a)
      
    });
  }

  

}


export function DrawingTools(props:{show:boolean}) {
  const {state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext)
  const {dispatch: rightPaneDispatch} = useContext(RightPaneContext);
  const mapRef = useRef<Map>()

  useEffect(() => {
    if (mapitState?.map?.getMapPop?.() && props.show) {
      mapRef.current = mapitState.map.getMapPop()
      mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Override));
      return () => {mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Normal))} 
    }
  },[mapitState.map, props.show])

  if (mapitState?.map?.getMapPop?.()) {
    return <DrawingModeSelector map={mapitState.map.getMapPop()} show={props.show} />
  }
  return <></>
}

type changeModeModes = "draw_line_string" | "draw_polygon";




export function DrawingModeSelector(props: {map, show}) {
  const {state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext);
  const {dispatch: rightPaneDispatch} = useContext(RightPaneContext);
  const [showEkstraInfo, setShowExtraInfo] = useState(false)
  const  [selected, setSelected] = useState<string[]>([]);
  const drawRef = useRef<MapboxDraw>()
  const openRef = useRef<boolean>(false)

  useEffect(() => {
    openRef.current = !!props.show
  },[props.show])

  function updateState() {
    if (openRef.current && drawRef.current?.getSelectedIds?.()) {
      setSelected([...drawRef.current.getSelectedIds()])
    } 
    else {
      setSelected([])
    }
  }

  MapboxDraw.constants.classes.CONTROL_BASE = "maplibregl-ctrl";
  MapboxDraw.constants.classes.CONTROL_PREFIX = "maplibregl-ctrl-";
  MapboxDraw.constants.classes.CONTROL_GROUP = "maplibregl-ctrl-group";

  useEffect(() => {

    // Fix Compatibility with Maplibre 3
    let StaticMode:any = {};
    StaticMode.onSetup = function() {
      this.setActionableState(); // default actionable state is false for all actions
      return {};
    };
    
    StaticMode.toDisplayFeatures = function(state, geojson, display) {
      display(geojson);
    };

    let modes:any = {
      draw_line_string : ExtendWithMeasureMents(MapboxDraw.modes.draw_line_string),
      draw_point       : ExtendWithMeasureMents(MapboxDraw.modes.draw_point),
      draw_polygon     : ExtendWithMeasureMents(MapboxDraw.modes.draw_polygon),
      static           : ExtendWithMeasureMents(StaticMode),
      // draw_circle      : ExtendWithMeasureMents(CircleMode),
      // drag_circle      : ExtendWithMeasureMents(DragCircleMode),
      direct_select    : ExtendWithMeasureMents(MapboxDraw.modes.direct_select),
      simple_select    : ExtendWithMeasureMents(MapboxDraw.modes.simple_select),
    }

    const drawObj = new MapboxDraw({
      modes:modes,
      displayControlsDefault:false,
      styles: DrawingToolExtras.styles,
    });
    
    
    drawRef.current = drawObj;
    mapitStateDispatch(actionSetDrawContext(drawObj))
    props.map.addControl(drawObj);


    let x = updateState
    props.map.on('draw.modechange', x)
    props.map.on('draw.update', x)
    props.map.on('draw.create', x)
    props.map.on('draw.selectionchange', x)
    props.map.on('draw.delete', x)

    return () => {
      props.map.off('draw.modechange', x);
      props.map.off('draw.update', x);
      props.map.off('draw.create', x);
      props.map.off('draw.selectionchange', x);
      props.map.off('draw.delete', x);
      try {
        drawObj?.deleteAll?.();
        props.map?.removeControl?.(drawObj);
      } catch (error) {
        console.error(error)
      }
      drawRef.current = undefined;
    }
  },[props.map])

  useEffect(() => {
    if (props.show) {
      drawRef?.current?.changeMode?.('simple_select')
      let layers = props.map.getStyle().layers;
      let layerIDS = layers.map((layer) => layer.id);
      layerIDS.filter((layerID) => layerID.startsWith("gl-draw")).forEach((glDrawId) => {
        props.map.moveLayer(glDrawId);
      })
      return () => {
        if (props.map.hasControl(drawRef.current)) {
          drawRef?.current?.changeMode?.('static')
          // props.map.removeControl(drawRef.current)
        }
      }
    }
  },[props.show])
  
  function handleChangeMode(mode: string | "draw_line_string" | "draw_polygon" | "static") {
    if (drawRef.current) {
      drawRef.current.changeMode(mode)
      updateState()
    }
  }

  function handleDelete(all: boolean) {
    if (drawRef.current) {
      if (all || selected.length < 1) {
        drawRef.current.deleteAll()
      } else {
        drawRef.current.trash()
      }
    }
    drawRef.current?.changeMode?.('simple_select')
    updateState()
  }

  if(!props.show) {
    return <></>
  }

  function showPointsInArea() {
    const features = drawRef.current?.getSelected().features as any;
    if (features) {
      const polygons = features.filter((a) => {return a.geometry.type === "Polygon"}) as any[]
      const mulipolygon = Turf.multiPolygon(polygons.map((feature) => feature.geometry.coordinates))
      let spatialReturn = GenerateGeom.spatialSelectionGeoJSON(mapitState, mulipolygon.geometry);
      return Object.keys(spatialReturn).reduce((b,a) => {return spatialReturn[a].length + b},0)
    }
    return 0
  }

  function filterPointsInArea() {
    const features = drawRef.current?.getSelected().features as any;
    if (features) {
      const polygons = features.filter((a) => {return a.geometry.type === "Polygon"}) as any[]
      const mulipolygon = Turf.multiPolygon(polygons.map((feature) => feature.geometry.coordinates))
      let spatialSelection = GenerateGeom.spatialSelectionGeoJSON(mapitState, mulipolygon.geometry)
      Object.keys(spatialSelection).forEach((layerKey) => {
        let layer: LayerInfo = mapitState.layers[layerKey]
        if (!layer) {
          return
        }
        
        let IdsToInclude = spatialSelection[layerKey]
        if (layer.datasheet) {
          let linesToInclude = IdsToInclude.map((obj) => obj + 1);  // correct for 1 header row.
          let sheet = SheetFunc.filterWorkSheet(layer.datasheet!, linesToInclude);
          let data = SheetFunc.sheetToJson(sheet)
          
          const featuresToInclude = IdsToInclude.map((id) => layer.handle.source.data.features[id])
          const features = data.map((a,idx) => {
            const [lng, lat, ..._] = (featuresToInclude[idx].geometry.coordinates as number[])
            return Turf.point([lng,lat], a)
          })
          const newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureList(features, layer.datasetname)
          mapitStateDispatch(actionAddDataLayer(newLayerInfo))
          return
        }
        
        const featuresToInclude = IdsToInclude.map((id) => layer.handle.source.data.features[id])
        const newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureList(featuresToInclude, layer.datasetname)
        mapitStateDispatch(actionAddDataLayer(newLayerInfo))
        return
      })
    }
  }

  
  
  function showArea() {
    const features = drawRef.current?.getSelected().features;
    let area = 0
    if (features) {
      const polygons = features.filter((a) => {return a.geometry.type === "Polygon"})
      if (polygons.length > 0) {
        area = polygons.reduce((pre, cur) => {
          return pre + tArea(cur)
        },area);
      }
    }
    //m2
    return formatArea(area)
  }
  
  function gemSelection(continueToLayers = true) {
    const ls = drawRef.current?.getSelected();
    if (ls) {
      // let currentDate = new Date();
      ls.features = ls.features.map((a) => {return {...a, properties: {"Details":"Measurement", length: formatLength(Turf.length(a as any)) ,area: formatArea(Turf.area(a as any))}}})
      let newLayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureList(ls.features, Localization.getText("Measurement"))
      mapitStateDispatch(actionSetShowWindow(MapitWindowId.NameOnCreation, true, {layers: newLayerInfo})) 
      drawRef.current?.delete(drawRef.current?.getSelectedIds?.() || [])
      setSelected([])
      if (continueToLayers) {
        rightPaneDispatch(actionGoToModus(RightPaneModus.Map_Layer))
      }
    }
  }

  function showLength() {
    const features = drawRef.current?.getSelected().features;
    let length = 0
    if (features) {
      if (features.length > 0) {
        length = features.reduce((pre, cur) => {
          return pre + tLength(cur)
        },length);
      }
    }
    //Kilometers
    return formatLength(length)
  }

  
  
  const modus = drawRef.current?.getMode() || ""

  const areal = showArea()
  const pointCount = showPointsInArea()

  return (<>
  {ReactDOM.createPortal(
    <AdvancedDragModal
      variant={"NSWhite"}
      title={Localization.getText("Measurement")}
      PosDefault={{left: "calc( 100% - 10px)", transform:"translateX(-100%)", top:"10px", width:"400px"}}
    >
      
    {/* <div className="DrawingArea" style={{pointerEvents:"none"}}> */}
    {/* </div> */}
    {drawRef.current?.getAll().features.length && selected.length ? 
    <>
    <ADModalBody>
    {selected.length > 1 ? <h5>{Localization.getText("Selection")} {selected.length}</h5>:null}
    {areal !== "0m2" ? <div><b>{Localization.getText("M_Area")} </b>{showArea()}</div>: null}
    <div><b>{Localization.getText("Length")} </b>{showLength()}</div>
    {pointCount > 0 ? <ProtectedFeature feature={Feature.MeasurementToolSpatial} ><div><b>{Localization.getText("Points")} </b> <MitCount show={true} count={showPointsInArea()} /></div></ProtectedFeature>: <></>}
    </ADModalBody>
    <ADModalFooter>
    {selected.length ? <GlassButton  onClick={() => gemSelection()}>{Localization.getText("Gem")}</GlassButton> : <></>}
    </ADModalFooter>
    </>
    :<>
    <ADModalBody>
  <ol>
    <li>{Localization.getText("Select a tool")}</li>
    <li>{Localization.getText("Click the map")}</li>
    <li>{Localization.getText("Click the last point to finish the geometry")}</li>
  </ol>
  <GlassFoldUdBox
    foldetUd={false}
    title={"Tips"}
    variant="Small"
  >
  <ul>
    <li>{Localization.getText("Selected geometries are yellow")}</li>
    <li>{Localization.getText("Selected points are bigger")}</li>
    <li>{Localization.getText("Shift + click to select more")}</li>
    <li>{Localization.getText("Move selected geomtries by dragging the border")}</li>
    <li>{Localization.getText("Move selected points by dragging them")}</li>
    <li>{Localization.getText("Remove selected geometries or points by clicking trash")}</li>
    <li>{Localization.getText("Clicking trash deletes all geometries when nothing is selected")}</li>
    <li>{Localization.getText("Add new points to a geomtri by clicking the green points, that are show when clicking the border of selected geometri")}</li>
  </ul>
  </GlassFoldUdBox>
  </ADModalBody>
  </>
  }
  </AdvancedDragModal>
  , document.getElementById("Mit-MapOverlay") || document.body)}
  
   <div className="ExtendedSideBar">
    
    <div className='LeftInnerShadow' ></div>
    <div className="SidePanelScrolling">
    <ExtendedMenuButton className={"buttonOverride ExtendedMenuButton " + (modus === "draw_line_string" ? "active":"")} toolTip={Localization.getText("Line")} onClick={(e) => handleChangeMode("draw_line_string")}>
      <PiLineSegment />
    </ExtendedMenuButton>
    <ExtendedMenuButton className={"buttonOverride ExtendedMenuButton " + (modus === "draw_polygon" ? "active":"")} toolTip={Localization.getText("Polygon")} onClick={(e) => handleChangeMode("draw_polygon")}>
    <PiPolygon />
    </ExtendedMenuButton>
    <ProtectedFeature feature={Feature.DrawCircle} contentsIfNoAccess={<></>} >
    <ExtendedMenuButton className={"buttonOverride ExtendedMenuButton "} toolTip={Localization.getText("Circle")} onClick={(e) => handleChangeMode("drag_circle")} onDoubleClick={(e) => handleDelete(true)}>
      <PiCircle />
    </ExtendedMenuButton>
    </ProtectedFeature>
    <ExtendedMenuButton className={"buttonOverride ExtendedMenuButton "} toolTip={Localization.getText("Trash")} onClick={(e) => handleDelete(false)} onDoubleClick={(e) => handleDelete(true)}>
      <PiTrash />
    </ExtendedMenuButton>
    </div>
   </div>
  </>
  )
}