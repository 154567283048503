import { useState } from "react";
import { MitStorage } from "./MitStorage";

export function useMitStorage<T>(
  key: string,
  value: T
) {

  let [storeValue, setStoreValue] = useState(
    MitStorage.getValue<T>(key) ?? value
  );

  function setValue(value: T | ((prev: T) => T)) {
    setStoreValue(value);
    if (typeof value === "function") {
      MitStorage.setValue(key, (value as (prev: T) => T)(MitStorage.getValue(key) ?? storeValue));
      return
    }
    MitStorage.setValue(key, value);
  }

  return [storeValue, setValue] as const;
}