
import Nestable from 'react-nestable';

import { Localization } from "@viamap/viamap2-common";


import {
  LayerType, LayerInfo, 
  LayerHierarchy, LayerHierarchyEndNode, GroupVisibility, LayerVariabilityType,
  DataDivisionType} from '../common/managers/Types';
import { Utils } from '@viamap/viamap2-common';
import { LayerChangeAction } from './MapScreen';
import { Cookies, MitCookies } from '@viamap/viamap2-common';
import { ConfirmationDialog } from 'src/components/ConfirmationDialog';
import { HiFolderAdd } from 'react-icons/hi';
import { MapFacadeAbstract } from 'src/managers/MapFacade';
import { AlertClick, SimpleImportButton } from './FileImporter';
import { OldHierarchy } from 'src/managers/OldHierarchy';
import { useContext, useEffect, useRef, useState } from 'react';
import ReactDOM from 'react-dom';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';
import { AppMode } from 'src/states/ApplicationStateFeatureMapping';
import { MapitStateContext, MapitWindowId, actionAddDataLayer, actionSetShowWindow } from 'src/states/MapitState';
import { BsChevronRight, BsFillTrashFill, BsGearFill, BsImage, BsInfoCircleFill } from 'react-icons/bs';
import { AiFillPicture } from 'react-icons/ai';
import { PiFolderDashed } from "react-icons/pi";
import { FaMapMarker, FaMapMarkerAlt } from 'react-icons/fa';
import { HiDotsVertical } from 'react-icons/hi';
import { useDropImport } from './FileImportHooks';
import { LargeIconBtn, GlassCheckbox, PaddedContainer, SmallIconBtn, VLine } from './MitGlassComponents';
import { AnyLengthString } from 'aws-sdk/clients/comprehendmedical';
import { GenerateGeomUtils } from 'src/managers/GenerateGeomUtils';
import { coordEach } from '@turf/turf';

type Props = {
  layers: LayerInfo[]
  callbackOnLayerChanges: (action: LayerChangeAction, li: LayerInfo, zoomToFocus: boolean, stats?: (info: any) => void) => number;
  callbackOnHierarchyChanges: (layerHierarchy: LayerHierarchy) => void;
  selectedLayerToStyle: number | null,
  selectLayerToStyle: (layerId:number | null) => void;
  selectHierarchyToStyle: (layerHierarchy: LayerHierarchy) => void;
  selectLayerToReopen: (layerId:number) => void;
  layerHierarchy: LayerHierarchy;
  mapFacade: MapFacadeAbstract;
}

/**
 * React class containing a hierarchical layer list to showcase all user added layers on the map.
 */
export default function LayerList(props:Props) {
    const [isDragging,setIsDragging] = useState(false);
    const [numberOfLayers, setNumberOfLayers] = useState(props.layers.length)
    const {windowToBeShownOrder, dispatch: mapitStateDispatch} = useContext(MapitStateContext)
    const [elementToRemove,setElementToRemove] = useState<number| LayerHierarchy | undefined>(undefined);
    const [confirmationDialogMessage,setConfirmationDialogMessage] = useState<string | undefined>(undefined);
    const dropEvent = useDropImport({})

    const {state: applicationState, hasAccessToFeature} = useContext(ApplicationStateContext);

    useEffect(() => {
      if (numberOfLayers !== props.layers.length) {
        props.selectLayerToStyle(null)
        setNumberOfLayers(props.layers.length)
      }
    },[props.layers])

    // ToDo: change to add the listeners only to the map layer.
    useEffect(() => {
      if (applicationState.appMode == AppMode.ReadOnly) {
        return () => {}
      }
      const eventElement = document.body;
      
      const dragOver =(e) => {
        e.preventDefault();
        e.stopPropagation();
        if (isDragging === false) {
          document.body.dispatchEvent(new Event('mit-dragging-show'));
          //        map.element.dispatchEvent(new Event('modal-fade-in'));
      }
      setIsDragging((a) => true)
    };

    const dragLeave = (e) => {
      e.preventDefault();
      e.stopPropagation();
      setIsDragging(a => false );
      document.body.dispatchEvent(new Event('mit-dragging-hide'));
      // map.element.dispatchEvent(new Event('modal-fade-out'));
    };
    
    eventElement.addEventListener('dragover', dragOver);
    eventElement.addEventListener('dragleave', dragLeave);
    eventElement.addEventListener('drop', dropEvent);
    return () => {
      eventElement?.removeEventListener('dragover', dragOver);
      eventElement?.removeEventListener('dragleave', dragLeave);
      eventElement?.removeEventListener('drop', dropEvent);
    }
    
  },[isDragging])

  function isReadOnlyMap(): boolean {
    return applicationState.appMode === AppMode.ReadOnly;
  }
  

  

  /**
   * Adds a group to the bottom of the layer list and updates the state.
   * @param e Mouse click event when react item is clicked.
   */
  function onClickAddGroup(e: any): void {
    let layerHierarchy = props.layerHierarchy;
    layerHierarchy.children.push({ id: OldHierarchy.extractLowestId(props.layerHierarchy) - 1, name: Localization.getText("Group"), isCollapsed: false, children: [] });
    // Close any open import results windows
    e.preventDefault();
    props.callbackOnHierarchyChanges(layerHierarchy);
  }

  function onClickShowHideAll(majorityIsUnselected: boolean) {
    let action: ("Show"|"Hide") = majorityIsUnselected ? "Show" : "Hide";
    props.layers.forEach((layerInfo) => {
      layerInfo.layerVariability !== LayerVariabilityType.FixedFeatureLayer && props.callbackOnLayerChanges(action, layerInfo, true);
    });
  }

  function onClickRemove(layerListElement: number | LayerHierarchy) {
    if (typeof layerListElement === "number") {
      removeLayer(layerListElement);
    } else {
      removeGroup(layerListElement);
    }
  }

  function removeLayer(layerId: number) {
    // ToDo: Get confirmation from user.
    let layer = getLayerInfo(layerId);
    props.callbackOnLayerChanges("Remove", layer, false);
  }

  /**
   * Removes the given group from the state of this component.
   * @param groupToRemove The group to be removed.
   */
  function removeGroup(groupToRemove: LayerHierarchy): void {
    let layerHierarchy = props.layerHierarchy;

    layerHierarchy.children = _onClickRemoveGroup(groupToRemove.id, layerHierarchy, false);
    props.callbackOnHierarchyChanges(layerHierarchy);
  }

  /**
   * Help function for onClickRemoveGroup. Do not call this directly.
   * 
   * See documentation of onClickRemoveGroup.
   */
  function _onClickRemoveGroup(id: number, layerHierarchy: LayerHierarchy, shouldThisElementBeDeleted: boolean): (LayerHierarchy | LayerHierarchyEndNode)[] {

    let indexToRemove = -1;

    let found = layerHierarchy.children.map((element, index) => {
      if (!("isCollapsed" in element)) {
        if (shouldThisElementBeDeleted) {
          let layer = getLayer(element.id);
          if (layer) {
            props.callbackOnLayerChanges("Remove", layer, false);
          }
        }
        return element;
      } else {
        if (id === element.id) {
          indexToRemove = index;
          element.children = _onClickRemoveGroup(id, element, true);
        } else {
          element.children = _onClickRemoveGroup(id, element, shouldThisElementBeDeleted);
        }
        return element;
      }
    });

    if (indexToRemove !== -1) {
      found.splice(indexToRemove, 1);
    }

    return found;
  }

  /**
   * Update the state of this component to the new state after a layer list item has been moved.
   * @param newState The new state of the layer hierarchy's top level children.
   */
  function onDragMove(newState: (LayerHierarchyEndNode | LayerHierarchy)[]): void {
    let layerHierarchy = props.layerHierarchy;

    layerHierarchy.children = newState;

    props.callbackOnHierarchyChanges(layerHierarchy);
  }

  /**
   * Verifies whether or not the currently dragged element is allowed to move to a certain destination.
   * @param dragItem The currently dragged item.
   * @param destinationParent The destination element which the item wants to be placed on.
   * @returns True if the item is allowed to move, otherwise false.
   */
  function isAllowedToMove(dragItem: (LayerHierarchyEndNode | LayerHierarchy), destinationParent: (LayerInfo | LayerHierarchy)): boolean {
    let isReadonlyLayer = (!("isCollapsed" in dragItem) && getLayer(dragItem.id)!.readonly);
    let isReadonlyGroup = (("isCollapsed" in dragItem) && dragItem.isReadonly);

    if (isReadOnlyMap() || isReadonlyLayer || isReadonlyGroup) {
      return false;
    }

    if (destinationParent) {
      return ("isCollapsed" in destinationParent); // Only succeed if destination is a group.
    } else {
      return true;
    }
  }

  function onClickGroupSettings(layerHierarchy: LayerHierarchy) {
    props.selectHierarchyToStyle(layerHierarchy)
  }

  function onClickReOpenImportDetails(layerId: number) {
    props.selectLayerToReopen(layerId)
  }

  // -----------------------------------------------

  function getLayerInfo(layerId: number): LayerInfo {
    let layer = props.layers.find((val) => { return val.layerId === layerId; });
    if (!layer) {
      throw Utils.createErrorEventObject("LayerId not found: " + layerId);
    }
    return layer;
  }

  // ------------------------------------------------------------------------------


  function toggleLayerVisible(e: any, layerInfo: LayerInfo) {
    e.preventDefault();
    if (layerInfo.layerId === props.selectedLayerToStyle) {
      props.selectLayerToStyle(null)
    }
    props.callbackOnLayerChanges("Toggle", layerInfo, true);
  }

  /**
   * Toggles the visibility of the group and all of its children transitively. 
   * If the group is partially visible, the group visibility is hidden rather than toggled.
   * @param e React mouse event from the click used to call this onClick function.
   * @param layerHierarchy The group which is to be toggled.
   * @param visibility The current visibility of the group to be toggled.
   */
  function onClickToggleGroupVisibility(e: any, layerHierarchy: LayerHierarchy, visibility: GroupVisibility): void {

    let action:("Hide"|"Toggle") = visibility === GroupVisibility.PartiallyVisible ? "Hide" : "Toggle";

    layerHierarchy.children.reverse().map((element) => {
      if (!("isCollapsed" in element)) {
        e.preventDefault();
        props.callbackOnLayerChanges(action, getLayer(element.id)!, true);
      } else {
        onClickToggleGroupVisibility(e, element, visibility);
      }
    });
  }

  /**
   * Toggles the collapse of a group and updates the state.
   * @param id 
   * @param layerHierarchy 
   */
  function onClickToggleGroupCollapse(id: number, layerHierarchy: LayerHierarchy): void {

    let result = _onClickToggleGroupCollapse(id, layerHierarchy);

    layerHierarchy.children = result;

    props.callbackOnHierarchyChanges(layerHierarchy);
  }

  /**
   * Help function for onClickToggleGroupCollapse. Do not call this directly.
   * 
   * See documentation of onClickToggleGroupCollapse.
   */
  function _onClickToggleGroupCollapse(id: number, layerHierarchy: LayerHierarchy) {

    let newHierarchy = layerHierarchy.children.map((element) => {
      if (!("isCollapsed" in element)) {
        return element;
      } else {
        if (element.id === id) {
          element.isCollapsed = !element.isCollapsed;
        }
        element.children = _onClickToggleGroupCollapse(id, element);
        return element;
      }
    });

    return newHierarchy;
  }

  /**
   * Transitively calculates the visibility of a group based on all of its children.
   * @param layerHierarchy The group you wish to calculate the visibility of.
   * @returns The GroupVisibility group.
   */
  function getGroupVisibility(layerHierarchy: LayerHierarchy): GroupVisibility {

    // Transitively make given group into a list of GroupVisibilities.
    let visibilityList = layerHierarchy.children.map((element) => {
      if (!("isCollapsed" in element)) { // If LayerHierarchyEndNode
        let layer = getLayer(element.id);
        if (layer) {
          return layer.visible;
        }
      } else {                      // If LayerHierarchy
        let currentGroupVisibility = getGroupVisibility(element);
        if (currentGroupVisibility === GroupVisibility.NoChildren) {
          return;
        } else {
          return getGroupVisibility(element);
        }
      }
    });

    // All undefined indexes are to be ignored and are therefore removed.
    visibilityList = visibilityList.filter((element) => element !== undefined);

    // If no children, do not show icon.
    if (!visibilityList.length && !layerHierarchy.children.length) {
      return GroupVisibility.NoChildren;
    }

    // If every child is transitively visible, return visible.
    if (visibilityList.every((element) => element === true || element === GroupVisibility.Visible || element === GroupVisibility.NoChildren)) {
      return GroupVisibility.Visible;
    }

    // If some child is transitively visible, return partially visible.
    if (visibilityList.some((element) => element === true || element === GroupVisibility.Visible || element === GroupVisibility.PartiallyVisible)) {
      return GroupVisibility.PartiallyVisible;

    } else {
      return GroupVisibility.NotVisible;
    }
  }

  /**
   * Updates the state to show a confirmation dialog before deletion.
   * @param layerListElement The group or the layer id to be deleted.
   */
  function confirmDeletion(layerListElement: number | LayerHierarchy): void {
    if (typeof layerListElement === "number") {
      let isCookieSet = Cookies.getCookieBoolean(MitCookies.RememberDeleteLayer);

      if (isCookieSet) {
        removeLayer(layerListElement);
      } else {
        setElementToRemove(layerListElement)
        setConfirmationDialogMessage(Localization.getText("Are you sure you want to delete this layer?"))
        mapitStateDispatch(actionSetShowWindow(MapitWindowId.ConfirmLayerList, true))
      }
    } else {
      if (layerListElement.children.length) {
        setElementToRemove(layerListElement)
        setConfirmationDialogMessage(Localization.getText("Deleting this group will delete all of its children. Are you sure?"))
        mapitStateDispatch(actionSetShowWindow(MapitWindowId.ConfirmLayerList, true))
      } else {
        removeGroup(layerListElement);
      }
    }
  }

  function zoomToLayerHandler(handle?: any) {
    handle && props.mapFacade.zoomToFeature?.(handle);
  }

  function renderLine(layerInfo: LayerInfo, index: number): JSX.Element {

    let idx = "collapse" + index;
    let name = layerInfo.datasetname;

    // ToDo: move default color to mit-settings.ts
  
    const layerIcon = layerInfo.type === LayerType.PointUTM32 || layerInfo.type === LayerType.PointWGS84 || layerInfo.type === LayerType.GeoJSON_Point ? (
      <FaMapMarker
        className='ClickAble'
        style={{ color: layerInfo!.styling!.color }}
        data-toggle="tooltip"
        title={Localization.getText("Zoom to layer")}
        onClick={() => zoomToLayerHandler(layerInfo.handle)}
      />
    ) : (
      <BsImage
        className='ClickAble'
        style={{ color: layerInfo!.styling!.color }}
        data-toggle="tooltip"
        title={Localization.getText("Zoom to layer")}
        onClick={() => zoomToLayerHandler(layerInfo.handle)}
      />
    )


    const settingsIconOrReadOnly = layerInfo.readonly ?
      <></> : (
        <SmallIconBtn
          className={"shownOnHoverParent"}
          onClick={() => props.selectLayerToStyle(layerInfo.layerId)} >
        <BsGearFill
          color={(props.selectedLayerToStyle == layerInfo.layerId ? "green" : "") }
          data-toggle="tooltip"
          title={Localization.getText("Edit layer")}
          />
          </SmallIconBtn>
      );
    const reopenImport = layerInfo.readonly || layerInfo.hasNoDataBehind ? null : (
      <SmallIconBtn 
        className={"shownOnHoverParent"}
        onClick={() => onClickReOpenImportDetails(layerInfo.layerId)} >
      <BsInfoCircleFill 
        data-toggle="tooltip"
        title={Localization.getText("Show import details")}
        />
      </SmallIconBtn>
    );
    const deleteLayerIcon = isReadOnlyMap() ? null : (
      <SmallIconBtn
        className={"shownOnHoverParent"}
        onClick={() => confirmDeletion(layerInfo.layerId)} >
      <BsFillTrashFill
        data-toggle="tooltip"
        title={Localization.getText("Delete layer")}
      />
      </SmallIconBtn>
    );
    return (
      <div className='LayerListItem' >
                <GlassCheckbox 
                  checked={layerInfo.visible}
                  onClick={(e) => toggleLayerVisible(e, layerInfo)}
                  title={layerInfo.visible ? Localization.getText("Hide layer") : Localization.getText("Show layer")}
                  />
                {layerIcon}
                <div
                  className="LayerListName"
                  data-toggle="tooltip"
                  title={name}
                  onClick={() => zoomToLayerHandler(layerInfo.handle)}
                >
                {name}
                </div>
                {reopenImport}
                {(layerInfo.readonly) ? null : <MenuButton layerInfo={layerInfo} />} 
                {settingsIconOrReadOnly}
                {deleteLayerIcon}
      </div>
    );

  }

  function renderGroup(layerHierarchy: LayerHierarchy) {

    const settingsIconOrReadOnly = (isReadOnlyMap() || layerHierarchy.isReadonly) ?
      <></> : (
        <SmallIconBtn
          className={"shownOnHoverParent"}
          onClick={() => onClickGroupSettings(layerHierarchy)} >
        <BsGearFill
          data-toggle="tooltip"
          title={Localization.getText("Edit layer")}
          
        />
        </SmallIconBtn>
      );

    const deleteLayerIcon = isReadOnlyMap() ? null : (
      <SmallIconBtn 
        className={"shownOnHoverParent"}
        onClick={() => confirmDeletion(layerHierarchy)} >
      <BsFillTrashFill
        data-toggle="tooltip"
        title={Localization.getText("Delete layer")}
        />
        </SmallIconBtn>
    );

    let collapseIcon = "play";
    let collapseStyle = layerHierarchy.isCollapsed ? { width: "12px" } : { width: "12px", transform: "rotate(90deg)" };
    collapseIcon = !layerHierarchy.children.length ? "" : collapseIcon;

    const visibility = getGroupVisibility(layerHierarchy);
    let checkIcon = "";
    let checkIconStyle = { color: "black" };
    let checkIconTitle = "string";
    

    let groupTitle = {
      [GroupVisibility.Visible]: Localization.getText("Hide group"),
      [GroupVisibility.PartiallyVisible]: Localization.getText("Hide group"),
      [GroupVisibility.NotVisible]: Localization.getText("Show group")
    }

    let checkIconOrEmptyGroup = visibility === GroupVisibility.NoChildren ? ( // If no 
      <PiFolderDashed
        style={{ left: "2px", marginBottom: "6px" }}
        title={Localization.getText("Empty group")}
      />
    ) : (
      <GlassCheckbox checked={!visibility} indeterminate={visibility == 1} title={groupTitle[visibility]} onClick={(e, val) => onClickToggleGroupVisibility(e, layerHierarchy, visibility)} />
    );

    return (
      <div className='LayerListItem' >
                {checkIconOrEmptyGroup}
                {collapseIcon ? 
                <SmallIconBtn
                  onClick={() => onClickToggleGroupCollapse(layerHierarchy.id, props.layerHierarchy)}>
                  <BsChevronRight 
                  className='transformer' 
                  style={{transform:"Rotate("+(!layerHierarchy.isCollapsed?"90deg":"0deg")+")"}}
                  title={layerHierarchy.isCollapsed ? Localization.getText("Expand group") : Localization.getText("Collapse group")}
                  />
                </SmallIconBtn>
                : null}
                <div
                  className="LayerListName"
                  onClick={() => onClickToggleGroupCollapse(layerHierarchy.id, props.layerHierarchy)}
                  title={layerHierarchy.name} 
                  >
              
                  {layerHierarchy.name}
                </div>
              
                {settingsIconOrReadOnly}
                {deleteLayerIcon}
              
      </div>
    );
  }

  function getLayer(val: number) {
    return props.layers.find((value) => {
      return (val === value.layerId);
    });
  }

  /**
   * Updates an existing group in a given layer hierarchy.
   * @param newGroup The new group with an id which already exists in the layer hierarchy.
   * @param layerHierarchy The layer hierarchy to be changed.
   * @returns The new layer hierarchy containing the new group.
   */
  function updateGroup(newGroup: LayerHierarchy, layerHierarchy: LayerHierarchy): LayerHierarchy {
    let newChildren = layerHierarchy.children.map((element) => {
      if (!("isCollapsed" in element)) {
        return element;
      } else {
        if (element.id === newGroup.id) {
          return updateGroup(newGroup, newGroup);
        }
        return updateGroup(newGroup, element);
      }
    });
    layerHierarchy.children = newChildren;

    return layerHierarchy;
  }

  function confirmationDialogCallback(elementToRemove?: number | LayerHierarchy) {
    if (elementToRemove) {
      onClickRemove(elementToRemove);
    }
    setElementToRemove(undefined)
    mapitStateDispatch(actionSetShowWindow(MapitWindowId.ConfirmLayerList, false))
  }

  /**
   * Callback to save a layer as a custom feature layer
   * @param layer Layer to save
   */
    const renderItem = ({ item }) => {
      if (!("isCollapsed" in item)) { // React code if LayerHierarchyEndNode
        let layer = getLayer(item.id);
        if (layer) {
          let parent = OldHierarchy.getParentGroup(layer.layerId, props.layerHierarchy);
          if (parent && !parent.isCollapsed) {
            return (renderLine(layer, -1));
          }
        }
      } else {                   // React code if LayerHierarchy
        let parent = OldHierarchy.getParentGroup(item.id, props.layerHierarchy);
        if (parent && !parent.isCollapsed) {
          return (renderGroup(item));
        }
      }
    };

    
    let normalLayers = props.layers.filter(layer => layer.layerVariability !== LayerVariabilityType.FixedFeatureLayer);

    let createFirstLayerButton: JSX.Element | null = null
    if (normalLayers.length === 0) {
      createFirstLayerButton = isReadOnlyMap() ? null : (
        <AlertClick />
      );
    }

    let majorityIsUnselected =  normalLayers.filter( a => a.visible).length < (normalLayers.length / 2)
    let header = normalLayers.length ? (
      <>
        <PaddedContainer style={{paddingBlock:"0px"}}>
          <div className='verticalButtons'>
            <GlassCheckbox onClick={(e) => onClickShowHideAll(majorityIsUnselected)}
              checked={!majorityIsUnselected}
              title={majorityIsUnselected ? Localization.getText("Show All Layers") : Localization.getText("Hide All Layers")}
              style={{marginRight:"auto", fontSize:"20px"}} 
            />
            { isReadOnlyMap() ? <></> :
                  <LargeIconBtn onClick={(e)=> onClickAddGroup(e)}>
                  <HiFolderAdd
                    color={"var(--viamap-green)"}
                    title={Localization.getText("Add group")}
                  />
                  </LargeIconBtn>
            }
            { isReadOnlyMap() ? <></> : <SimpleImportButton />}
          </div>
        </PaddedContainer>
        <VLine color={"#ddd"} />
      </>
    ) : null;

        return (
          <>
              {/* <Card style={{ borderRadius: "0px", margin: "0px" }} className="Flat-Card"> */}
                {/* <Card.Header className={'LayerHeader' + (normalLayers.length ? "" : " Empty") }> */}
                {header}
                <div className='LayerListBody'>
                <PaddedContainer>
                  {createFirstLayerButton}
                  <Nestable
                    items={props.layerHierarchy.children}
                    renderItem={renderItem}
                    onChange={(newState) => onDragMove(newState.items as any[])}
                    confirmChange={(option) => {
                      return isAllowedToMove(option.dragItem as any, option.destinationParent as any)
                    }}
                    threshold={20}
                  />
                </PaddedContainer>
                </div>


      {elementToRemove ?
      ReactDOM.createPortal(<ConfirmationDialog
        showWindow={windowToBeShownOrder(MapitWindowId.ConfirmLayerList)}
        onSubmit={() => { confirmationDialogCallback(elementToRemove); }}
        onCancel={() => { setElementToRemove(undefined) }}
        cookieTag={(typeof elementToRemove === "number") ? MitCookies.RememberDeleteLayer:""}
      >
        
          {confirmationDialogMessage || ""}
        
      </ConfirmationDialog>,
      document.getElementById("Mit-MapOverlay") || document.body) : null
    }
      </>
    );
}

function MenuButton(props:{layerInfo:LayerInfo}) {
  const [pos, setPos] = useState({top:0,right:0});
  const btnRef = useRef<HTMLButtonElement>(null)
  const conRef = useRef<HTMLDivElement>(null)

  function updateState() {
    if (btnRef) {
      let {bottom: top, right:right} = btnRef.current?.getBoundingClientRect() || {bottom:0, right:0}
      setPos({top, right})
    }
    if (conRef.current?.contains(document.activeElement)) {
      btnRef.current?.focus()
    } else {
      conRef.current?.focus()
    }
  }

  return <>
  {ReactDOM.createPortal(<div className='MenuCont ShowIfFocus' tabIndex={0} ref={conRef}><LayerListDotDotMenu layerInfo={props.layerInfo} pos={pos} /></div>, document.body)}
  <SmallIconBtn ref={btnRef}
    className={"shownOnHoverParent"} 
    onClick={updateState} >
    <HiDotsVertical
  data-toggle="tooltip"
  title={Localization.getText("Settings")}
  />
  </SmallIconBtn>
  </>
}

function LayerListDotDotMenu(props:any) {
  const {state: mapitState, dispatch : mapitStateDispatch} = useContext(MapitStateContext);
  const {state: applicationState, hasAccessToFeature} = useContext(ApplicationStateContext);

  const innerRef = useRef<HTMLDivElement>(null);
  const actions = [
    {label: "Download", onClick: () => mapitStateDispatch(actionSetShowWindow(MapitWindowId.DownloadLayerDialog, true, {layers:props.layerInfo})), looseFocus: true},
  ];
  if (hasAccessToFeature((Feature.DuplicateLayer))) {
    actions.push({label: Localization.getText("Duplicate"), onClick: () => handleDuplicateLayer(props.layerInfo), looseFocus: true} );
  }
  if (hasAccessToFeature((Feature.DuplicateLayerFiltered))) {
    actions.push({label: Localization.getText("Duplicate Filtered"), onClick: () => handleDuplicateLayerFiltered(props.layerInfo), looseFocus: true} );
  }

  function handleDuplicateLayer(layerToCopy:LayerInfo) {
    // Necessary cloning the layerInfo.
    let layer = JSON.parse(JSON.stringify(layerToCopy));
    layer.datasetname = Localization.getText("Copy of")+" "+layer.datasetname;
    layer.layerId = -1;
    delete layer.handle;
    mapitStateDispatch(actionAddDataLayer(layer));
  }

  function handleDuplicateLayerFiltered(layerToCopy:LayerInfo) {
    // Necessary cloning the layerInfo.
    let layer = window.structuredClone(layerToCopy);
    layer.datasetname = Localization.getText("Subset of")+" "+layer.datasetname;
    layer.layerId = -1;

    let colorStyle = layer.styling.colorByValue
    let colorKey = layer.styling.colorByProperty
    if (colorKey && colorStyle?.divisions) {
      let valGetter = (a) => a.properties[colorKey]
      if (colorStyle.divisions.type === DataDivisionType.Discrete) {
        layer.geoJson.features = layer.geoJson.features.filter((a) => !GenerateGeomUtils.getColorByValueDiscrete({} as any, valGetter(a), colorStyle.divisions).hideMarker)
      } else {
        layer.geoJson.features = layer.geoJson.features.filter((a) => !GenerateGeomUtils.getColorByValueNummeric({} as any, valGetter(a), colorStyle.divisions).hideMarker)
      }
    }
    delete layer.handle;
    delete layer.styling.colorByValue;
    delete layer.styling.colorByProperty;
    mapitStateDispatch(actionAddDataLayer(layer));
  }

  return (<div ref={innerRef} tabIndex={0} style={{top: props.pos.top+"px", left: props.pos.right+"px", position:"absolute", zIndex:12000, transform: "translateX(-100%)"}}>
    <div className='LayerListDotDotMenu'>
    {actions.map((a) => 
      <button key={a.label} onClick={(ev) => {(a.onClick() as any); a.looseFocus ? (ev.target as HTMLButtonElement)?.blur?.():null}}>{a.label}</button>
      )}
    </div>
  </div>)
}