import { LayerInfo, LayerType, MitDataType } from "src/common/managers/Types";
import { v4 } from "uuid";
export class MapitUtils {
     // ------------------ map and data types ------------------

  static isNummericMitDatatype(myType:MitDataType): boolean {
    return [MitDataType.Number, MitDataType.AdmReg_DK_MunicipalityId, MitDataType.AdmReg_DK_RegionId, MitDataType.AdmReg_DK_ZipCodeId, 
        MitDataType.Coord_UTM32_X, MitDataType.Coord_UTM32_Y, MitDataType.Coord_WGS84_Lat, 
        MitDataType.Coord_WGS84_Lon].indexOf(myType) !== -1;
  }

/**
 * 
 * @param myType a LayerType
 * @returns true if the layer type represents an area layer and requires that static geo information (e.g. regions, municipalities, ...) has been loaded
 */
  static isAreaLayerRequireingGeoData(myType:LayerType):boolean {
    return ([
        LayerType.Area,
        LayerType.AreaMunicipality,
        LayerType.AreaRegion,
        LayerType.AreaZipcodes,
        LayerType.AreaMunicipalityId,
        LayerType.AreaRegionId,
        LayerType.AreaZipcodesId,    
        LayerType.AreaMunicipalityName,
        LayerType.AreaRegionName,
        LayerType.AreaZipcodesName,
        LayerType.AreaParishName,    
        LayerType.AreaParishId,    
        LayerType.AreaCountry,    
        LayerType.AreaCountryId,    
        LayerType.AreaCountryName,    
        LayerType.AreaMunicipalityId_RO,
        LayerType.AreaMunicipalityName_RO,
        LayerType.AreaMunicipalityId_DK_And_SydSlesvig,
        LayerType.AreaMunicipalityName_DK_And_SydSlesvig,
    ].find((obj,idx) => { return obj === myType;})) !== undefined;
  }

  /**
 * 
 * @param myType a LayerType
 * @returns true if the layer type represents an area layer
 */
  static isAreaLayer(myType:LayerType):boolean {
    return ([
        LayerType.Area,
        LayerType.AreaMunicipality,
        LayerType.AreaRegion,
        LayerType.AreaZipcodes,
        LayerType.AreaMunicipalityId,
        LayerType.AreaRegionId,
        LayerType.AreaZipcodesId,    
        LayerType.AreaMunicipalityName,
        LayerType.AreaRegionName,
        LayerType.AreaZipcodesName,
        LayerType.AreaParishName,    
        LayerType.AreaParishId,    
        LayerType.AreaCountry,    
        LayerType.AreaCountryId,    
        LayerType.AreaCountryName,    
        LayerType.AreaMunicipalityId_RO,
        LayerType.AreaMunicipalityName_RO,
        LayerType.AreaMunicipalityId_DK_And_SydSlesvig,
        LayerType.AreaMunicipalityName_DK_And_SydSlesvig,
        LayerType.AreaCadaster,
        LayerType.AreaProperty
    ].find((obj,idx) => { return obj === myType;})) !== undefined;
  }


/**
 * 
 * @param myType a LayerType
 * @returns true if the layer type represents a point layer
 */
  static isPointLayer(myType:LayerType):boolean {
    return ([
        LayerType.PointCadaster,
        LayerType.PointUTM32,
        LayerType.PointWGS84,
        LayerType.GeoJSON_Point,
        LayerType.Point,
    ].find((obj,idx) => { return obj === myType;})) !== undefined;
  }

    // ---------------------- sorting util ----------------------------------------------
    static sortByLayerId(l1:LayerInfo, l2:LayerInfo):number {
      return l1.layerId > l2.layerId ? 1 : l1.layerId === l2.layerId ? 0 : -1;
    }
  
  
  /**
   * Compares the order of two cadasters. Function to be used when sorting cadasters.
   * @param cadNr1 Cadaster number 1 as string.
   * @param cadNr2 Cadaster number 1 as string.
   * @returns 1 if cadNr1 comes before cadNr2 in order, 0 if they are equal, -1 otherwise.
   */
  static compareCadasterOrder(cadNr1: string, cadNr2: string): number {

    // Start digit comparison
    let digitPattern = /^[0-9]+/g;
    let letterPattern = /[a-zA-ZæøåÆØÅ]/g;

    let matchDigit1: any = cadNr1.match(digitPattern);
    let matchDigit2: any = cadNr2.match(digitPattern);

    if (matchDigit1 === null) { matchDigit1 = []; }
    if (matchDigit2 === null) { matchDigit2 = []; }

    // MatchDigits are now lists
    // tslint:disable-next-line: radix
    let digitsCad1 = parseInt(matchDigit1.join(''));
    // tslint:disable-next-line: radix
    let digitsCad2 = parseInt(matchDigit2.join(''));

    // Compare cadaster digits
    if (digitsCad1 > digitsCad2) {
      return 1;
    } else if (digitsCad1 < digitsCad2) {
      return -1;
    } else {

      // If digits are equal, start comparing letters
      let matchLetter1: any = cadNr1.match(letterPattern);
      let matchLetter2: any = cadNr2.match(letterPattern);

      if (matchLetter1 === null) { matchLetter1 = []; }
      if (matchLetter2 === null) { matchLetter2 = []; }

      let lettersCad1 = matchLetter1.join('');
      let lettersCad2 = matchLetter2.join('');

      if (lettersCad1 > lettersCad2) {
        return 1;
      } else if (lettersCad1 < lettersCad2) {
        return -1;
      } else {
        return 0;
      }
    }
  }

  static getNewUuid(): string {
    return v4();
 }

 // ------------------------------------------- layer info utilities ----------------------------------------
 static getElementCount(layerInfo:LayerInfo):number {
    if (!layerInfo.geoJson) {
      // throw new Error("No geojson for layer. Cannot count");
      // ToDo: create geojson for all layers
      return 1;
    }
    if (layerInfo.geoJson.type === "FeatureCollection") {
      return layerInfo.geoJson.features.length;
    } else {
      throw new Error(`Unexpected geojson type: ${layerInfo.geoJson.type}. Expects FeatureCollection`);
    }
 }
}