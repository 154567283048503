
import {Localization} from "@viamap/viamap2-common";
import {Utils} from '@viamap/viamap2-common';
import {MapInfo, PictureLayoutType, PictureSize, PopupLayout, ValidationType} from '../common/managers/Types';
import { MitCheckBox, SelectWithOptionsV2} from './ComponentUtils';
import { ADModalBody, ADModalFooter, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { GlassButton } from './GlassButtons';
import { GlassInputControl, GlassInputGroup, GlassTextInputTransformer } from './GlassInput';
import { useState } from "react";
import { createPortal } from "react-dom";
import { GlassCheckbox } from "./MitGlassComponents";
// import { SketchPicker } from 'react-color';

type Props = {
    showWindow: number;
    mapInfo: MapInfo;
    callbackOnMapStyling: (mapInfo:MapInfo)=>void;
    callbackOnDismiss: ()=>void;
};

export type OptionType = {
    [value:string]:string;
};

export function MapStyling(props:Props) {
    const [mapInfo, setMapInfo] = useState<MapInfo>(props.mapInfo)
    const [changeTable, setChangeTable] = useState<{[id:string]:any}>({})
    
    function handleChange(event:any) {
        setChangeTable((current) => ({...current, [event.target.id as string]: event.target.value}))
    }

    function validateForm() { return true;}
    function handleClose(e:any) { e.preventDefault(); props.callbackOnDismiss(); }

// ===================================== EVENTS ===================================
    function nameOnChange(evt:any) {
        nameOnBlur(evt);
    }

    function nameOnBlur(evt:any) {
        evt.preventDefault();
        let newMapInfo = {...mapInfo, mapTitle:evt.target.value}
        setMapInfo(newMapInfo)
        props.callbackOnMapStyling(newMapInfo);
    }

    function pictureLayoutTypeOnChange(evt:any) {
        let newMapInfo = {...mapInfo, popUpPictureLayout: evt.target.value || PictureLayoutType.Center};
        setMapInfo(newMapInfo)
        props.callbackOnMapStyling(newMapInfo);
    }

    function pictureSizeOnChange(evt:any) {
        let newMapInfo = {...mapInfo, popUpPictureSize: evt.target.value || PictureSize.Medium};
        setMapInfo(newMapInfo)
        props.callbackOnMapStyling(newMapInfo);
    }

    function popupLayoutOnChange(evt:any) {
        let newMapInfo = {...mapInfo, popUpLayout:evt.target.value || PopupLayout.ShowAll};
        setMapInfo(newMapInfo)
        props.callbackOnMapStyling(newMapInfo);
    }

    function generateSelectionList(list:string[]):{label:string, value:string}[] {
        return list.map((item) => {
            return {value: item, label: Localization.getText(item)};
        });
    }

    // generatePictureLayoutList(list:any[]):any[] {
    //     function getLabel(val:PictureLayoutType) { 
    //         return Localization.getText(val);
    //     }

    //     return list.map((item) => {
    //         return {value: item, label: getLabel(item)};
    //     });

    //     // return [ 
    //     //     {value: PictureLayoutType.Center, label: getLabel(PictureLayoutType.Center)},
    //     //     {value: PictureLayoutType.Left, label: getLabel(PictureLayoutType.Left)},
    //     //     {value: PictureLayoutType.Right, label: getLabel(PictureLayoutType.Right)}
    //     // ];
    // }

    // generatePictureSizeList(list:any[]):any[] {
    //     function getLabel(val:PictureSize) { 
    //         return Localization.getText(val);
    //     }

    //     return list.map((item) => {
    //         return {value: item, label: getLabel(item)};
    //     });
        
    //     return [ 
    //         {value: PictureSize.Small, label: getLabel(PictureSize.Small)},
    //         {value: PictureSize.Medium, label: getLabel(PictureSize.Medium)},
    //         {value: PictureSize.Large, label: getLabel(PictureSize.Large)}
    //     ];
    // }

    function getValidationStateForNumber(value:number):ValidationType {
        let val = Utils.parseNumber(value+"");
        if (isNaN(val!)) {
            return "error";
        } else {
            return "success";
        }
    }

    function numberOnChange(e:any, setterFunc:(value:number)=>void) {
        let value = e.target.value;
        if (getValidationStateForNumber(value) === "success" || value==="") {
            setterFunc(value);
        }
    }

    // let MapSettingsButton = true ? (
    //     <Glyphicon 
    //         style={{float:"left"}}
    //         glyph="cog" 
    //         onClick={(e) => this.onClickMapSettings(e)}
    //         title={Localization.getText("Map Settings")}
    //     />
    //   ) : null;
  
    let labelColumn:JSX.Element|null=null;
    labelColumn = true ? (
        <div className="row">
            <div className="col-sm-4" >                        
                {Localization.getText("Display fields")}
            </div>
            <div className="col-sm-7 mit-form-control" > 
            XXX
            </div>
            </div>
    ): null;

    let checkItem:JSX.Element|null=null;
    checkItem = true ? (
        <div className="row">
        <div className="col-sm-4" >                        
            {Localization.getText("Show in Legend")}
        </div>
        <div className="col-sm-7 mit-form-control" > 
        {
            <GlassCheckbox
                checked={true}
                onChange={(e) => {return null;}}
            />              
        }
        </div>
        </div>
    ) : null;

    // const keys = Object.keys(PictureLayoutType); // .filter(k => typeof PictureLayoutType[k as any] === "number"); // ["A", "B"]
//    let list = this.generatePictureLayoutList(keys);
    let selectPictureLayoutType = SelectWithOptionsV2(
        generateSelectionList(Object.keys(PictureLayoutType)), 
        "PictureLayoutType", 
        (evt) => pictureLayoutTypeOnChange(evt),
        Localization.getText("Picture Layout"),
        mapInfo && mapInfo.popUpPictureLayout ? mapInfo.popUpPictureLayout.toString() : "",
        () => {return false;}
    );
    let RenderPictureLayoutType = (
        <GlassTextInputTransformer label={Localization.getText("Picture Layout")}>
            <GlassInputControl>
            {selectPictureLayoutType}
            </GlassInputControl>
        </GlassTextInputTransformer>
    );

    let selectPopupLayout = SelectWithOptionsV2(
        generateSelectionList(Object.keys(PopupLayout)), 
        "PopupLayout", 
        (evt) => popupLayoutOnChange(evt),
        Localization.getText("Popup layout"),
        mapInfo && mapInfo.popUpLayout,
        () => {return false;}
    );
    let RenderPopupLayout = (
        <GlassTextInputTransformer label={Localization.getText("Popup layout")}>
            <GlassInputControl>
            {selectPopupLayout}
            </GlassInputControl>
        </GlassTextInputTransformer>
    );

    // const keys2 = Object.keys(PictureSize); // .filter(k => typeof PictureSize[k as any] === "number"); // ["A", "B"]
    // let list2 = this.generatePictureSizeList(keys2);
    let selectPictureSize = SelectWithOptionsV2(
        generateSelectionList(Object.keys(PictureSize)),
        "PictureSize", 
        (evt) => pictureSizeOnChange(evt),
        Localization.getText("Picture Size"),
        mapInfo && mapInfo.popUpPictureSize ? mapInfo.popUpPictureSize.toString() : "",
        () => {return false;}
    );
    let RenderPictureSize = (
        <GlassTextInputTransformer label={Localization.getText("Picture Size")}>
            <GlassInputControl>
            {selectPictureSize}
            </GlassInputControl>
        </GlassTextInputTransformer>
    );

    let InputMapTitle = (
        <input
            placeholder={Localization.getText("Savemap:defaultFileName")}
            type='text'
            value={(mapInfo && mapInfo.mapTitle) || ""}
            onChange={(evt) => nameOnBlur(evt)}
        />
    );

    let RenderMapTitle = (
        <GlassTextInputTransformer label={
            Localization.getText("Name")
        }>
            <GlassInputControl>
            {InputMapTitle}
            </GlassInputControl>
        </GlassTextInputTransformer>
    );

    return createPortal(
            <AdvancedDragModal
                variant={"NSWhite"}
                PosDefault={{left:"50%", top:"5%", transform:"translateX(-50%)"}}
                title={Localization.getText("Map Settings")}
            >
        
                <ADModalBody >
                    <GlassInputGroup
                        autoFocus={0}
                        onSubmit={(e) => {props.callbackOnMapStyling(mapInfo)}}
                        onEscape={(e) => {handleClose(e)}}
                    >
                    {RenderMapTitle}
                    {RenderPictureSize}
                    {RenderPictureLayoutType}
                    {RenderPopupLayout}
                    {/* {labelColumn}
                    {checkItem} */}
                    </GlassInputGroup>
                </ADModalBody>
                <ADModalFooter>
                <GlassButton
                                onClick={(e) => handleClose(e)}
                            >
                                {Localization.getText("Close")}
                            </GlassButton>
                </ADModalFooter>
            </AdvancedDragModal>
            , document.getElementById("Mit-MapOverlay") || document.body)
}
