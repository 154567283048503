import { useEffect } from "react";
import { message } from "src/components/Message";

export function AdvancedDragModalMiniContainer() {

   useEffect(() => {
      if (document.getElementsByClassName('ADM_MiniContainer').length > 1) {
         // Has to be Unique
         message.error('AdvancedDragModalMiniContainer is already loaded, Only one can exist');
      }
   }, []);
   
  return (
    <div id="ADM_MiniContainer" className={'ADM_MiniContainer'}>
    </div>
  );
}