import { ButtonHTMLAttributes, DetailedHTMLProps, HTMLAttributes } from "react"


type GlassButtonProps = {
   children: React.ReactNode
} & DetailedHTMLProps<ButtonHTMLAttributes<HTMLButtonElement>, HTMLButtonElement>

export function GlassButton(props: GlassButtonProps) {
   return (
      <button {...props} type="button" className={"GlassButton "+(props.className || "")} >
         {props.children}
      </button>
   )
} 

type GlassButtonDivProps = {
   children: React.ReactNode
} & DetailedHTMLProps<HTMLAttributes<HTMLDivElement>, HTMLDivElement>

export function GlassInfoButton(props: GlassButtonDivProps) {
   return (
      <div {...props} className={"GlassDivButton "+(props.className || "")}>
         {props.children}
      </div>
   )
}

export function GlassButtonRow(props: {children, wrap?}) {
   return (
      <div className="GlassButtonRow" style={{flexWrap:(props.wrap ? "wrap" : "nowrap")}}>
         {props.children}
      </div>
   )
}