import React, { useCallback, useEffect, useRef, useState } from 'react';
import { Distances, TMouseEventCallbackParams } from './components/Distances';
import { FloatMap } from './components/FloatMap'
// import './index.css';
import { PoiManager } from './managers/PoiManager';
// import 'bootstrap-icons/font/bootstrap-icons.css';

function App(props:{group:string, lngLat:{lng:number, lat:number}, className:string}) {
  let [floatMapVal, setFloatMapVal] = useState(undefined as TMouseEventCallbackParams | undefined)
  let [scrollY, setScrollY] = useState(0);

  let mainRef = useRef(null)


  useEffect(() => {
    window.addEventListener("scroll",function (ev){setScrollY(this.scrollY)})
  })

  useEffect(() => {
    PoiManager.instance().callNearest(props.lngLat.lat,props.lngLat.lng);
  },[props.lngLat])

  let handleDistanceCallback = useCallback((e:any) => {
    setFloatMapVal(e as TMouseEventCallbackParams)
  },[]);

  

  return (
    <div className={"HLED "+props.className}>

      {/* <ConsentForm /> */}
      <FloatMap params={floatMapVal} boundBox={mainRef} scroll={scrollY > 10} />
      {/* <div className='card'> */}
      <main ref={mainRef} >

        <Distances callBack={async (e) => handleDistanceCallback(e)}/>
      </main>
      {/* </div> */}
    </div>
    );
  }

  export default App;
  