export type FeatureType = string | number ; 
export enum Feature {
   SearchFallBack = "SearchFallBack",

   BasicMap = "BasicMap",
   GeoCoding = "GeoCoding",
   SearchBar = "SearchBar",
   POI = "POI",
   GeodataReport = "GeodataReport",
   DeviceLocation = "DeviceLocation",
   ShowMapScale = "ShowMapScale",
   SaveMap = "SaveMap",
   ShowLayerControls = "ShowLayerControls",
   ShowMenu = "ShowMenu",
   ShowMapSettings = "ShowMapSettings",
   ShowMolbakLogoOnGeodataReport = "ShowMolbakLogoOnGeodataReport",
   Show360NorthLogoOnGeodataReport = "Show360NorthLogoOnGeodataReport",
   TravelTime = "TravelTime",
   UserAdmin = "UserAdmin",
   TravelTimeMultiple = "TravelTimeMultiple",
   SpatialExport = "SpatialExport",
   ObliquePhoto = "ObliquePhoto",
   GettingStarted = "GettingStarted",
   GeodataReportGermany = "GeodataReportGermany",
   TravelTimeRushHourMode = "TravelTimeRushHourMode",
   TravelTimeDownloadPolygons = "TravelTimeDownloadPolygons",
   SpatialExportGeoJSON = "SpatialExportGeoJSON",
   SpatialExportGeoJSONInverse = "SpatialExportGeoJSONInverse",
   SpatialExportGeoJsonPreview = "SpatialExportGeoJsonPreview",
   IntersectingPolygons = "IntersectingPolygons",
   Debugging = "Debugging",
   ShowZoomLevelInHeader = "ShowZoomLevelInHeader",
   CreateMarker = "CreateMarker",
   CreateMarkerNavigation = "CreateMarkerNavigation",
   EditCustomFeatureLayers = "EditCustomFeatureLayers",
   ViewCustomFeatureLayers = "ViewCustomFeatureLayers",
   StreetView = "StreetView",
   LifaOIS = "LifaOIS",
   Kortviser = "Kortviser",
   PropertyInfo = "PropertyInfo",
   EjerlavMatrikelSearch = "EjerlavMatrikelSearch",
   MeasurementTool = "MeasurementTool",
   MeasurementToolSpatial = "MeasurementToolSpatial",
   DrawCircle = "DrawCircle",
   VisSamletFastEjendom = "VisSamletFastEjendom",
   VisVurderingsejendom = "VisVurderingsejendom",
   PiechartClustering = "PiechartClustering",
   CustomUserSettingAdmin = "CustomUserSettingAdmin",
   ImportSettings = "ImportSettings",
   ImportPhotos = "ImportPhotos",
   ImportEnableSydslesvigGeom = "ImportEnableSydslesvigGeom",
   ImportEnableGrid = "ImportEnableGrid",
   ImportEnableRomaniaGeom = "ImportEnableRomaniaGeom",
   POIEVCharger = "POIEVCharger",
   ShowReleases = "ShowReleases",
   EditDataLayer = "EditDataLayer",

   SaveLink = "SaveLink",
   SaveLinkReuseMapLink = "SaveLinkReuseMapLink",
   SaveLinkSetRestrictions = "SaveLinkSetRestrictions",
   SaveLinkSelectFeatureLayers = "SaveLinkSelectFeatureLayers",
   SaveMapCloud = "SaveMapCloud",
   SaveMapCustomerCloud = "SaveMapCustomerCloud",
   SaveMapAdminCloud = "SaveMapAdminCloud",
   
   SettingsCustomer = "SettingsCustomer",
   MapPlot = "MapPlot",
   MapPlotSuper = "MapPlotSuper",
   PowerUser="PowerUser",

   ShowMenuAndControlsOnMap = "ShowMenuAndControlsOnMap",
   TravelTimeCombinedBikeAndTransit = "TravelTimeCombinedBikeAndTransit",
   TravelTimeFromPoint = "TravelTimeFromPoint",
   TravelTimeCalculateSeparately = "TravelTimeCalculateSeparately",
   TravelTimeMultipleBufferSetting = "TravelTimeMultipleBufferSetting",

   // ----------------- Person Info Feature -----------------
   OpenPersonInfoDocument = "OpenPersonInfoDocument",
   PrintPersonInfo = "PrintPersonInfo",
   PropertyInfoFavorite = "PropertyInfoFavorite",

   // ----------------- Property Info Features --------------
   OpenPropetyInfoDocument = "OpenPropetyInfoDocument",
   BoligmarkedsStatistik = "BoligmarkedsStatistik",
   KortUdsnitEjendom = "KortUdsnitEjendom",
   PlandataAflyst = "PlandataAflyst",
   PlandataForslag = "PlandataForslag",
   LinkToMao = "LinkToMao",

   // ----------------- Explorer Features -------------------
   AddToProjectUnassignedList = "AddToProjectUnassignedList",
   Explorer = "Explorer",
   ExplorerDashboard = "ExplorerDashboard",
   ExplorerExplore = "ExplorerExplore",
   ExplorerOwnership = "ExplorerOwnership",
   ExplorerProjects = "ExplorerProjects",
   ExplorerToExcel = "ExplorerToExcel",
   ExplorerSaveAsNewLayer = "ExplorerSaveAsNewLayer",
   ExplorerImportExport = "ExplorerImportExport",
   ExplorerDiscover = "ExplorerDiscover",
   ExplorerMarkedsData = "ExplorerMarkedsData",
   ExplorerShowAsList ="ExplorerShowAsList",

   // ------------------ Theme Features -------------------
   ThemeNewSec = "ThemeNewSec",

   // ------------------ BNBO Features --------------------
   BNBOTool = "BNBOTool", // Access to BNBO Tool itself
   BNBOToolWriteAccess = "BNBOToolWriteAccess",
   BNBOToolArrondationCalc = "BNBOToolArrondationCalc",
   BNBOReplaceData = "BNBOReplaceData",
   BNBOProjectRead = "BNBOProjectRead", // On project level: Access to read project data
   BNBOProjectWrite = "BNBOProjectWrite", // On project level: Access to write project data
   BNBOProjectAdmin = "BNBOProjectAdmin", // On project level: Access to manage project properties/users
   BNBOToolAdmin = "BNBOToolAdmin", // Access to 1) customer projects 2) to import data 3) to manage users/organizations
   BNBOToolGlobalAdmin = "BNBOToolGlobalAdmin", // Access to all customers projects

   // ------------------ Map Styles ------------------------
   MapStyleViamap = "MapStyleViamap",
   MapStyleContrast = "MapStyleContrast",
   MapStyleLight = "MapStyleLight",
   MapStyleDark = "MapStyleDark",
   MapStyleAerial = "MapStyleAerial",
   MapStyleAerialNoMask = "MapStyleAerialNoMask",
   MapStyleBlank = "MapStyleBlank",

   // ------------------ ScreenSizes -----------------------
   DeviceScreenSizePhone = "DeviceScreenSizePhone",
   DeviceScreenSizeTablet = "DeviceScreenSizeTablet",
   DeviceScreenSizeNormal = "DeviceScreenSizeNormal",

   // ------------------ Background Layers -------------------
   FavoriteBackgroundLayers = "FavoriteBackgroundLayers",

   HexagonBackgroundLayerMoelbak = "HexagonBackgroundLayerMoelbak", 
   CowiBackgroundLayerMoelbak = "CowiBackgroundLayerMoelbak",
   BackgroundLayerOwnerInformation = "BackgroundLayerOwnerInformation",
   BackgroundLayerFloodingFromSea = "BackgroundLayerFloodingFromSea",
   BackgroundLayerRainFall = "BackgroundLayerRainFall",
   

   BackgroundLayerDHM = "BackgroundLayerDHM",
   BackgroundLayerFikspunkt = "BackgroundLayerFikspunkt",

   BackgroundLayerBBRData = "BackgroundLayerBBRData",
   BackgroundLayerEjerData = "BackgroundLayerEjerData",
   BackgroundLayerZoning = "BackgroundLayerZoning",

   BackgroundLayerLocalPlans = "BackgroundLayerLocalPlans",
   BackgroundLayerDAI = "BackgroundLayerDAI",
   BackgroundLayerDayNightPopulation = "BackgroundLayerDayNightPopulation",
   BackgroundLayerPrivatVej = "BackgroundLayerPrivatVej",
   BackgroundLayerStrandBeskyttelsesLinjer = "BackgroundLayerStrandBeskyttelsesLinjer",
   BackgroundLayerFredsskov = "BackgroundLayerFredsskov",
   BackgroundLayerForeloebigeSkel = "BackgroundLayerForeloebigeSkel",
   BackgroundLayerCadasterPoints = "BackgroundLayerCadasterPoints",
   BackgroundLayerPoliceDistricts = "BackgroundLayerPoliceDistricts",
   BackgroundLayerJordforureningV1 = "BackgroundLayerJordforureningV1",
   BackgroundLayerJordforurening_NuanceringV2 = "BackgroundLayerJordforurening_NuanceringV2",
   BackgroundLayerAnvendelse2021 = "BackgroundLayerAnvendelse2021",

   BackgroundLayerStøj = "BackgroundLayerStøj",
   BackgroundLayerStøjNat = "BackgroundLayerStøjNat",

   // Uddeleger til lag
   BackgroundLayerHistoriskeKort = "BackgroundLayerHistoriskeKort",
   BackgroundLayerHistoriskeOrto = "BackgroundLayerHistoriskeOrto",
   BackgroundLayerHistoriskeOrtofoto = "BackgroundLayerHistoriskeOrtofoto",
   BackgroundLayerOrtofotoSommer = "BackgroundLayerOrtofotoSommer",
   BackgroundLayerAdmGrænser = "BackgroundLayerAdmGrænser",
   BackgroundLayerAdmGrænserExtended = "BackgroundLayerAdmGrænserExtended",
   BackgroundLayerAdmGrænserStednavne = "BackgroundLayerAdmGrænserStednavne",
   BackgroundLayerCadaster = "BackgroundLayerCadaster",
   BackgroundLayerCadasterExpanded = "BackgroundLayerCadasterExpanded",
   BackgroundLayerCadasterForeløbigSimple = "BackgroundLayerCadasterForeløbigSimple",
   BackgroundLayerCadasterForeløbig = "BackgroundLayerCadasterForeløbig",
   BackgroundLayerCadasterSUT = "BackgroundLayerCadasterSUT",
   BackgroundLayerAnvendelse = "BackgroundLayerAnvendelse",
   BackgroundLayerPlanData = "BackgroundLayerPlanData",
   BackgroundLayerPlanDataForsyning = "BackgroundLayerPlanDataForsyning",
   BackgroundLayerPlanDataKonsekvens = "BackgroundLayerPlanDataKonsekvens",
   BackgroundLayerPlanDataFingerPlan = "BackgroundLayerPlanDataFingerPlan",
   BackgroundLayerFredningerKulturArv = "BackgroundLayerFredningerKulturArv",
   BackgroundLayerByggeBeskyt = "BackgroundLayerByggeBeskyt",
   BackgroundLayerBeskyttetNatur = "BackgroundLayerBeskyttetNatur",
   BackgroundLayerVeje = "BackgroundLayerVeje",
   BackgroundLayerVejeTrafikVolumen = "BackgroundLayerVejeTrafikVolumen",
   BackgroundLayerForurening = "BackgroundLayerForurening",
   BackgroundLayerMarkblokke = "BackgroundLayerMarkblokke",
   BackgroundLayerMarkblokkeHist = "BackgroundLayerMarkblokkeHist",
   BackgroundLayerGeoDanmark = "BackgroundLayerGeoDanmark",
   BackgroundLayerJordbrug = "BackgroundLayerJordbrug",
   BackgroundLayerJordbrugVand = "BackgroundLayerJordbrugVand",

   BackgroundLayerBiodiversitet = "BackgroundLayerBiodiversitet",
   BackgroundLayerVandløbOversvoemmelse = "BackgroundLayerVandløbOversvoemmelse",
   
   // ---------------- Features Demo and Experimental -------
   Datarapport = "Datarapport",
   BackgroundLayerDemo = "BackgroundLayerDemo",
   DownloadAktFraTinglysning = "DownloadAktFraTinglysning",
   ShowDemoQuickButtons = "ShowDemoQuickButtons",
   SwapLicense = "SwapLicense",
   AllowConsolidateDuplicates = "AllowConsolidateDuplicates",
   PrintPropertyInfo="PrintPropertyInfo",
   StylingSelectTextFont="StylingSelectTextFont",
   PoiSaveAsNewLayer="PoiSaveAsNewLayer",
   ExportFloodingProperties="ExportFloodingProperties", // Export Explore Result to Excel is extended with ground height and basement area information
   ExplorerSearchByMapLayer="ExplorerSearchByMapLayer", // Explorer may search by a polygon Map Layer (imported from json or drawn).
   DuplicateLayer = "DuplicateLayer",
   DuplicateLayerVisible = "DuplicateLayerVisible",
   DuplicateLayerFiltered = "DuplicateLayerFiltered",
   EarlyPreview = "EarlyPreview", // Access to preview of prototypes of new functionality

   // ----------------- Features not Appointed Rights --------
   NotActive = "NotActive"
}