import { useContext } from "react";
import { FileImportContext } from "./FileImporter";



export enum FileTypes {
    GeoJSON = "GeoJson",
    Excel = "Excel",
    Csv = "Csv",
    GeoImage = "GeoImage",
    RasterImage = "RasterImage",
    VectorImage = "VectorImage",
    MapitFile = "MapitFile",
    Xml = "Xml",
    Zip = "Zip",
    Url = "Url",
    MapDef = "MapDef",
    Unknown = "Unkown",
  }
  
export type ParsedFile = {
    type: FileTypes,
    content: any,
    meta: any,
  }
  
  export type FileImportOptions = {
    fileImportType: FileImportType,
    files?: FileList,
    url?: string,
    callBackSuccess?: (file: ParsedFile) => any,
    callBackOnError?: (message:string) => any,
    acceptedFiles?: FileTypes[],
  }
  

export enum FileImportType {
    Drop = "Drop",
    
    FileExplore = "FileExplore",
    FileFetch = "FileFetch",
  
    ImportFiles = "ImportFiles",
}

export function useDropImport({callBackSuccess, callBackOnError, acceptedFiles}:{callBackSuccess?: (file: ParsedFile) => any, callBackOnError?: (message:string) => any, acceptedFiles?: FileTypes[],}) {
    let {importState: importST, importCallBack: importCB} = useContext(FileImportContext)
  
    function DropEvent(e:DragEvent) {
      
      importCB({
        fileImportType: e.altKey ? FileImportType.ImportFiles : FileImportType.Drop,
        files: e.dataTransfer?.files,
        callBackSuccess:callBackSuccess,
        callBackOnError:callBackOnError,
        acceptedFiles:acceptedFiles,
      })
      e.preventDefault();
      e.stopPropagation();
    }
  
    return DropEvent
  }