import { PersistenceObjectType, PersistenceScope, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import AWS from "aws-sdk";
import { ViamapApi } from "./ViamapApiManager";
import { Feature } from "src/states/ApplicationStateFeatures";

type poiContentType = string | number | number[] | number[][];
export const POIS:{[index:string]:{[attr:string]:string | number | number[] | number[][] | any[]}} = {
  'daycare': {
    'dk': 'Daginstitution',
    'da': 'Daginstitution',
    'en': 'Daycare',
    'icon': 'daycare',
    'transtype': 'foot'
  },
  'doctor': {
    'dk': 'Læge',
    'da': 'Læge',
    'en': 'Doctor',
    'icon': 'doctor',
    'transtype': 'foot'
  },
  'hospital': {
    'dk': 'Hospital',
    'da': 'Hospital',
    'en': 'Hospital',
    'icon': 'hospital',
    'transtype': 'car'
  },
  'junction': {
    'dk': 'Motorvej',
    'da': 'Motorvej',
    'en': 'Junction',
    'icon': 'junction',
    'transtype': 'car'
  },
  'metro': {
    'dk': 'Metro',
    'da': 'Metro',
    'en': 'Metro',
    'icon': 'metro',
    'transtype': 'foot'
  },
  'school': {
    'dk': 'Skole',
    'da': 'Skole',
    'en': 'School',
    'icon': 'school',
    'transtype': 'foot'
  },
  'stop': {
    'dk': 'Stoppested',
    'da': 'Stoppested',
    'en': 'Stop',
    'icon': 'stop',
    "maxZoom": 14,
    'transtype': 'foot'
  },
  'strain': {
    'dk': 'S-Tog',
    'da':'S-Tog',
    'en': 'Strain',
    'icon': 'strain',
    'transtype': 'foot'
  },
  'supermarket': {
    'dk': 'Supermarked',
    'da': 'Supermarked',
    'en': 'Supermarket',
    'icon': 'supermarket',
    'transtype': 'foot'
  },
  'train': {
    'dk': 'Regionaltog',
    'da': 'Regionaltog',
    'en': 'Train',
    'icon': 'train',
    'transtype': 'foot'
  },
  'library': {
    'dk': 'Bibliotek',
    'da': 'Bibliotek',
    'en': 'Libary',
    'icon': 'libary',
    'transtype': 'foot'
  },
  'pharmacy': {
    'dk': 'Apotek',
    'da': 'Apotek',
    'en': 'Pharmacy',
    'icon': 'pharmacy',
    'transtype': 'auto'
  },
  'coast': {
    'dk': 'Kyst',
    'da': 'Kyst',
    'en': 'Coast',
    'icon': 'coast',
    'transtype': 'foot'
  },
  'forest': {
    'dk': 'Skov',
    'da': 'Skov',
    'en': 'Forest',
    'icon': 'forest',
    'transtype': 'foot'
  },
  'lake': {
    'dk': 'Sø',
    'da': 'Sø',
    'en': 'Lake',
    'icon': 'lake',
    'transtype': 'foot'
  },
  'airport': {
    'dk': 'Lufthavn',
    'da': 'Lufthavn',
    'en': 'Airport',
    'icon': 'airport',
    'transtype': 'car'
  },
  'sportshall': {
    'dk': 'Idrætshal',
    'da': 'Idrætshal',
    'en': 'Sports hall',
    'icon': 'sportshall',
    'transtype': 'foot'
  },
  'publicbath': {
    'dk': 'Svømmehal',
    'da': 'Svømmehal',
    'en': 'Public bath',
    'icon': 'publicbath',
    'transtype': 'foot'
  },
  'soccerfield': {
    'dk': 'Fodboldbane',
    'da': 'Fodboldbane',
    'en': 'Soccer field',
    'icon': 'soccerfield',
    'transtype': 'foot'
  },
  "lightrail": { 
    'dk': "Letbane",
    'da': "Letbane",
    'en' :"Light rail",
    'icon' :"lightrail",
    "transtype":"foot"
  },
  "bathinglake": { 
    'dk': "Badesøer",
    'da': "Badesøer",
    'en' :"Bathing lake",
    'icon' :"bathinglake",
    "transtype":"foot"
  },
  "park": { 
    'dk': "Haver og parker",
    'da':"Haver og parker",
    'en' :"Park",
    'icon' :"park",
    "transtype":"foot"
  },
  "builtheritage": { 
    'dk': "Bygningsværker og Bymiljøer",
    'da':"Bygningsværker og Bymiljøer",
    'en' :"Built heritage",
    'icon' :"builtheritage",
    "transtype":"foot"
  },
  "themeparks": { 
    'dk': "Forlystelses- og temaparker",
    'da':"Forlystelses- og temaparker",
    'en' :"Theme park",
    'icon' :"themepark",
    "transtype":"foot"
  },
  "church": { 
    'dk': "Kirker",
    'da': "Kirker",
    'en' :"Church",
    'icon' :"church",
    "transtype":"foot"
  },
  "monument": { 
    'dk': "Monumenter",
    'da': "Monumenter",
    'en' :"Monument",
    'icon' :"monument",
    "transtype":"foot"
  },
  "naturesite": {
    'dk': "Naturområder",
    'da': "Naturområder",
    'en' :"Nature site",
    'icon' :"naturesite",
    "transtype":"foot"
  },
  "ancientlandmark": {
    'dk': "Oldtidsminder og ruiner",
    'da': "Oldtidsminder og ruiner",
    'en' :"Ancient",
    'icon' :"ancient",
    "transtype":"foot"
  },
  "greatestate": {
    'dk': "Slotte og herregårde",
    'da': "Slotte og herregårde",
    'en' :"Great estate",
    'icon' :"greatestate",
    "transtype":"foot"
  },
  "zoos": {
    'dk': "Zoo'er og dyreparker",
    'da': "Zoo'er og dyreparker",
    'en' :"Zoo",
    'icon' :"zoos",
    "transtype":"foot"
  },
  "chargingstation": {
    'dk': "Ladestander",
    'da': "Ladestander",
    'en' :"Charging station",
    'icon' :"chargingstation",
    "transtype":"car",
    "featureGroup": Feature.POIEVCharger
  },
  "supercharger": {
    'dk': "Lynlader",
    'da': "Lynlader",
    'en' :"Super charger",
    'icon' :"supercharger",
    "transtype":"car",
    "featureGroup": Feature.POIEVCharger
  },
}


export class PoiManager {
  private static inst : PoiManager | undefined = undefined;
  private poi:{[index:string]:{[attr:string]:string | number | number[] | number[][]}} = POIS
  private subscribers: {[index:string]: React.Dispatch<number>[]} = {all:[]}
  private number : number = 0;
  private currentPos : number[] | undefined = undefined
  
  public static instance(): PoiManager {
    if (this.inst === undefined) {
      this.inst = new PoiManager()
    }
    return this.inst;
  }

  constructor() {
    this.currentPos = [0,0]
    this.poi = POIS
    AWS.config.update({
      "region" : "eu-central-1",
      "credentials" : new AWS.CognitoIdentityCredentials({
        IdentityPoolId: "eu-central-1:5e7f7844-2b62-4fbc-994c-ba732a15b687",
      })
    });
  let peristenceInterfaceInstance = new ViamapPersistenceLayer("viamap-portal-production")
  peristenceInterfaceInstance.getListing(PersistenceScope.Internal, PersistenceObjectType.Icon, "","")
    .then(async (data: any) => {
      let promises = (data.map(async (item: any):Promise<any> => {
        let IconData = await peristenceInterfaceInstance.getObjectS3(item.Key);
        return (IconData);
      }));
      
      const itemList = await Promise.all(promises);
              
      itemList.forEach((e) => {
        if (this.poi[e.Metadata.filename.split(".")[0]]) {
          let blob = new Blob([new Uint8Array((e.Body as Buffer))]);
          this.poi[e.Metadata.filename.split(".")[0]].url = URL.createObjectURL(blob);
        }

      })

    });

  }

  private _updateSubscribers(type:string) {
    this.number = this.number + 1;
    if (this.subscribers[type]) {
      this.subscribers[type].forEach((e) => {
        e(this.number);
      });
    }
  }

  public subscribe(type: string, disp : React.Dispatch<number>) {
    if (this.subscribers[type]) {
      this.subscribers[type].push(disp)
    } else {
      this.subscribers = {...this.subscribers, [type]:[disp]}
    }
  }

  public getPicture(type: string) {
    return this.poi[type].url || "";
  }

  public getPoi(type: string):{[index:string]: poiContentType} | any {
    return {...this.poi[type], type:type as string} || {};
  }
    
  public getTypes() {
    return Object.keys(this.poi)
  }

  public getCurrentPos() : [number, number] | undefined {
    return this.currentPos as [number, number] | undefined;
  }

  public callNearest(lat:number, lng:number) {
    this.clearPoi();
    if (lat && lng) {
      let types = this.getTypes();
      this.currentPos = [lng, lat];
      Promise.all(types.map(async element => {
        return new Promise((resolve, reject) => {
        ViamapApi.callNearestPoi(element,lat,lng).then((succes) => {
        let elmResult = succes[element];
        let x = {
          name: elmResult.name,
          routedmeters: elmResult.routedmeters,
          tolatlng: elmResult.tolatlng.reverse(),
          routepolyline: elmResult.routepolyline,
          travelseconds: elmResult.travelseconds,
          mot: elmResult.mot,
        }
        let k = {...this.poi[element], ...x}
        resolve({type:element,  value: k})
      }).catch((err) => {
        resolve({type:element, value:"error"})
      })
    })
    })).then((values) => {
      values.forEach((element:any) => {
        if (element.value !== "error") {
          this.poi[element.type as string] = element.value as any;
        } else {
          ViamapApi.recall(3, 5000 ,ViamapApi.callNearestPoi(element.type,lat,lng)).then((succes : any) => {
            let elmResult = succes[element.type];
            let x = {
              name: elmResult.name,
              routedmeters: elmResult.routedmeters,
              tolatlng: elmResult.tolatlng.reverse(),
              routepolyline: elmResult.routepolyline,
              travelseconds: elmResult.travelseconds,
              mot: elmResult.mot,
            }
            this.poi[element.type as string] = {...this.poi[element.type], ...x}
            this._updateSubscribers("POS")
          }).catch(() => {
            console.error("stopped" + element.type)
          })
        }

      });
      this._updateSubscribers("POS")
    }).catch((err) => {
      console.error(err)
    });
    } else {
      this._updateSubscribers("POS")
    }
  }

  public setAsCurrentPoi(lat:number, lng:number, type:string, name:string) {
    this.poi[type].tolatlng = [lat,lng];
    this.poi[type].name = name;
    this._updateSubscribers("POI")
  }

  public async getPoisBBox(type:string, bbox:any) {
    return ViamapApi.callPois(type, bbox).then((data) => {
      if (this.poi[type].poiArr) {
        let x = (this.poi[type].poiArr as any[]).map((poi:any) => poi.poi_rep_id);
        data[type].POIs.forEach((newPoi: any) => {
          if (x.includes(newPoi.poi_rep_id)) {
            return
          }
          (this.poi[type].poiArr as any[]).push(newPoi);
        });
      } else {
        this.poi[type].poiArr = data[type].POIs
      }

      this._updateSubscribers("ARR")
    })
  }

  public async getPolyFor(type:string, transport?:string) {
    if (this.getPoi(type).tolatlng === undefined) {
      this._updateSubscribers("POLY")
      return;
    }
    let [dx,dy]:[number,number] = this.getPoi(type).tolatlng.slice(0,2);
    let [x,y]:[number,number] = (this.getCurrentPos() as [number,number]).slice(0,2) as [number,number];
    let useTrans = this.getPoi(type).mot
    if (transport) {
      useTrans = transport
    }
    return ViamapApi.callRoute(useTrans,y,x,dy,dx).then((data) => {
      let x = {...this.poi[type]}
      x.routepolyline = data[0].routepolyline;
      x.routedmeters = data[0].routedmeters;
      x.travelseconds = data[0].travelseconds;
      x.mot = useTrans;
      this.poi = {...this.poi, [type]:{...x}};
      this._updateSubscribers("POLY")
    })
  }

  public clearPoi() {
    let types = this.getTypes();
    let x = {}
    types.forEach((e) => {
      let {icon, url, dk, transtype, poiArr} = PoiManager.instance().getPoi(e)
      x = {...x, [e]: {"icon":icon, "url":url, "dk":dk, "transtype":transtype, "poiArr":poiArr}}
    })
    this.poi = x
    this._updateSubscribers("CLEAR")
  }
}
