import { PropertyInfoInterface as PropertyInfoInterface2} from 'src/compentsModus/PropertyInfoInterface';
import { PropertyInfoInterface} from '../managers/PropertyInfoInterface';
import { GlassFoldUdBox, GroupSelector, KeyValueTable, RenderLinkToCompanyGraph, RenderLinkToPersonGraph, ShowSelectedGroup, ViewButtonIcon } from 'src/propertyInfoTemplates/PropertyComponents';
import { ViewButtonCheck } from 'src/propertyInfoTemplates/PropertyComponents';
import { ProtectedFeature } from './ProtectedFeature';
import { actionSetShowWindow, MapitStateContext, MapitWindowId } from 'src/states/MapitState';
import { PiGraphFill } from 'react-icons/pi';
import { AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { useState, useContext, useEffect } from "react";
import { Cookies, Localization, SettingsManager } from '@viamap/viamap2-common';
import { MarketData } from 'src/managers/MarketDataInterface';
import { ApplicationStateContext } from 'src/states/ApplicationState';
import { Feature } from 'src/states/ApplicationStateFeatures';
import { GlassButton } from './GlassButtons';


type propertyData = {
   matrikelNr?: string;
   ejerlavsKode?: string;
   ejerlav?: string;
   bfeNr?: string;
   kommunekode?:string;
   kommunenavn?:string;
   postnummer?:string;
   postbynavn?:string;
   tib?: any;
   bbr?: any;
   mat?: any;
   lokalPlan?: any;
   kommunePlanRamme?: any;
   ejd?: any;
   ejdAdresse?: any;
   stats?: any;
   dhm_overflade?: any;
   dhm_terraen?: any;
   bbrGrund?:any;
   bbrBygninger?: any;
   bbrEnheder?:any;
   samletFastEjendom?: any;
   vurderingsEjendom?: any;
   esr_ejendomsnummer?: any;
   handelsOplysning?:any;
   marketData?: {[ejendomsType:string]:MarketData};
   travelTimeInfo?:  {         catchmentState: any,   polygons: any };
   obliqueImage?: {point:any[], image:any, width:number, height:number, pictureDate: Date, alternativePoint?:any[]};
   overviewMapInfo?: {lat:number, lng:number};
   marketHeatMapData?:any;
};

type Props = {
   showWindow: number;
   callBackOnClose: () => void;
   cvrNr: number;
   cols?: number;
};
const COOKIE_InfoSelectedTab="CompanyInfoSelectedTab";
const COOKIE_AutoColapsed="CompanyAutoColapsed";
const COOKIE_ShowAsGroups="CompanyShowAsGroups";

export function CompanyInformationDialog (props:Props) {

   const [propertyDefaultCollapsed,setPropertyDefaultCollapsed] = useState(Cookies.getCookieBoolean(COOKIE_AutoColapsed))
   const {hasAccessToFeature} = useContext(ApplicationStateContext)
   const {dispatch: mapitStateDispatch} = useContext(MapitStateContext)
   const [data,setData] = useState<any>({})

   const [cvrNr,setCvrNr] = useState<number>(0);

   const [availableGroup, setAvailableGroup] = useState<string[]>([]);
   function setSelectedGroup(string) {
      Cookies.setCookie("CompanyInfoSelectedTab",string)
      _setSelectedGroup(string)
   }

   const [selectedGroup, _setSelectedGroup] = useState(
      Cookies.getCookie("CompanyInfoSelectedTab") || "Alle"
   );

   useEffect(() => {
      if (props.showWindow && props.cvrNr) {
         setData({})
         setCvrNr(props.cvrNr)

         getDataAsync(props);
      }
   },[props.showWindow,props.cvrNr])

   
   function onClickClose() {
      props.callBackOnClose();
   }

   async function getDataAsync(props:Props) {
      let data:any = {};
      let cvrNr = props.cvrNr;
      if (!cvrNr) {
         return;
      }

      let cvrData = await PropertyInfoInterface2.getCVRSearch(   { "query":{
         "bool": {
            "must": [
               {
                  "nested": {
                     "path": "Vrvirksomhed.livsforloeb",
                     "query": {
                        "bool": {
                           "must_not": [
                              {
                                 "exists": {
                                    "field": "Vrvirksomhed.livsforloeb.periode.gyldigTil"
                                 }
                              }
                           ]
                        }
                     }
                  }
               }, {
         "term":{"Vrvirksomhed.cvrNummer":""+cvrNr}
         }
      ]
         }
      }});
      let keyData = PropertyInfoInterface2.extractRelevantData(cvrData);

      let punitData = await PropertyInfoInterface.PEnhederforCVR(cvrNr);

      setData({...keyData, punits:punitData});
   }

      if (props.showWindow) {
      
         function RemberCollapseSettings(val:any) {
            Cookies.setCookieBoolean(COOKIE_AutoColapsed , !val);
         }

         let fold = (
            <div >

            <ViewButtonCheck
               checked={!propertyDefaultCollapsed} 
               onCheck={(e, val) => {RemberCollapseSettings(val); setPropertyDefaultCollapsed(!val)}}
            >
               {Localization.getText("Expanded")}
            </ViewButtonCheck>
            </div>
         );
         
         return (
            <AdvancedDragModal
               title={"Virksomhed"}
               PosDefault={{width:"800px", height: "calc(100% - 50px)", top: "50px", left: "50%", transform:"translateX(-50%)"}}
               PosDefaultNS={{width:"calc( 100% - 30px )", height: "calc(100% - 50px)", top: "50px", left:"15px"}}
               saveKey="CompanyInfo"
               onClose={() => props.callBackOnClose()}
               subHeaderContent={
               <>
               {SettingsManager.getSystemSetting("debuggingShowExtraDebugging") ?
                  <GlassButton onClick={() => console.info(data)} >Log to console</GlassButton>
               : null}
               <ProtectedFeature feature={Feature.ExplorerOwnership} contentsIfNoAccess={<></>}>
                  <ViewButtonIcon 
                  title={Localization.getText("Show ownership graph")}
                  className="no-print"
                  onClick={() => {
                     mapitStateDispatch(actionSetShowWindow(MapitWindowId.OwnerShipDiagram, true, {cvrNr: props.cvrNr}))
                  }}
                  >
                     <PiGraphFill />
                  </ViewButtonIcon>
               </ProtectedFeature>
               {fold}</>}
            >  
            {/* <ADModalBody> */}
            <GroupSelector
               selected={selectedGroup}
               group={availableGroup}
               onSelect={setSelectedGroup}
               />
               <div className="Scroll-content">
               <ShowSelectedGroup
                  keepSelected={true}
                  loading={false}
                  columns={props.cols!}
                  group={selectedGroup}
                  groupCallBack={(agroups) =>
                     setAvailableGroup([...agroups,"Alle"])
                  }
               >
                  <GlassFoldUdBox
                     group='Attributter'
                     title='Attributter'
                     foldetUd={!propertyDefaultCollapsed} 
                  >
                  <KeyValueTable>
                  {data.attributter && data.attributter.filter((att) => [
                     "FORMÅL", 
                     "KAPITAL", 
                     "KAPITALVALUTA", 
                     "TEGNINGSREGEL", 
                     "FØRSTE_REGNSKABSPERIODE_START",
                     "FØRSTE_REGNSKABSPERIODE_SLUT",
                     "VEDTÆGT_SENESTE"].includes(att.type)).map((att, i) => {
                        let formatted:string|undefined = undefined;
                        if ("decimal" == att.vaerditype) {
                           let float = parseFloat(att.vaerdi)
                           formatted = new Intl.NumberFormat("da-dk",{minimumFractionDigits:2}).format(float);
                        }
                     return (
                        <tr key={att.type+i}>
                           <td >
                              {att.type}
                           </td>
                           <td>
                              {formatted ?? att.vaerdi}
                           </td>
                        </tr>
                     );
                  }) || (
                   <tr><td><strong>{Localization.getText("No data")}</strong></td></tr>
                  )}
         </KeyValueTable>
                  </GlassFoldUdBox>

                  <GlassFoldUdBox 
                     group='Ejere'
                     title='Ejere'
                     foldetUd={!propertyDefaultCollapsed} 
                  >
                     <KeyValueTable>
                  <tr>
                     <th>Navn</th>
                     <th>CVR-nummer</th>
                     <th>Type</th>
                     <th>Andel</th>
                  </tr>
                  {data.ejere && data.ejere.map((ejer, i) => {
                     return (
                        <tr key={ejer.forretningsnoegle}>
                           <td >
                              {ejer.navn}
                           </td>
                           <td>
                              {ejer.forretningsnoegle ? 
                              // renderCVRLink(ejer.forretningsnoegle)
                              <RenderLinkToCompanyGraph cvrNr={ejer.forretningsnoegle} />
                              :
                              <RenderLinkToPersonGraph cvrPersonId={ejer.cvrPersonId} />
                              }
                           </td>
                           <td >
                              {(ejer.enhedstype || "").replace("ANDEN_DELTAGER","UDENLANDSK_AKTØR")}
                           </td>
                           <td>
                              {ejer.andel+"%"}
                           </td>
                        </tr>
                     );
                  }) || (
                   <tr><td><strong>{Localization.getText("No data")}</strong></td></tr>
                  )}
         </KeyValueTable>
                  </GlassFoldUdBox>

                  <GlassFoldUdBox 
                     group='Produktions Enheder'
                     title='Produktions Enheder'
                     foldetUd={!propertyDefaultCollapsed} 
                  >
                     <KeyValueTable>
                  <tr>
                     <th>Adresse</th>
                     <th>Kontaktopl</th>
                     <th>Ansatte</th>
                     <th>Årsværk</th>
                  </tr>
                  {data.punits && data.punits.map((unit, i) => {
                     return (
                        <tr key={unit.id}>
                           <td >
                              {unit.adresse}
                           </td>
                           <td>
                              {unit.kontaktoplysninger.reduce((result, txt, idx) => result+(idx>0?", ":"")+txt, "")}
                           </td>
                           <td>
                              {unit.beskaeftigelseKvt?.antalAnsatte || unit.beskaeftigelseAar?.antalAnsatte || ""}
                           </td>
                           <td>
                              {unit.beskaeftigelseKvt?.antalAarsvaerk || unit.beskaeftigelseAar?.antalAarsvaerk || ""}
                           </td>
                        </tr>
                     );
                  }) || (
                   <tr><td><strong>{Localization.getText("No data")}</strong></td></tr>
                  )}
         </KeyValueTable>
                  </GlassFoldUdBox>

               </ShowSelectedGroup>
               </div>
               {/* </ADModalBody> */}
            </AdvancedDragModal>
         );
      } else {
         return null;
      }

}
