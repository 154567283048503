import { BBOXTURF, MapState } from "src/compentsModus/EmbeddedVectorMapEnhanced";
import { MitLayerHandleMaplibre } from "./MapFacadeMaplibre";
import { MapFacadeAbstract, MitMap } from "./MapFacade";
import { Localization, SettingsManager, Utils } from "@viamap/viamap2-common";
import { FilterType } from "src/states/ExploreSearchState";
import { ESInterface } from "src/compentsModus/ESInterface";
import * as M from 'maplibre-gl';
import { FeatureLayer, LayerVariantValues, StandardLayerSpecs } from "./WmsLayerFunc";
import { PropertyInformation } from "./PropertyInformation";
import { VectorLayer, isVectorLayer } from "./VectorLayerFunc";
import { POIS } from "src/HLED/managers/PoiManager";
import { AWSAPIGatewayInterface } from "./AWSAPIGatewayInterface";
import { MaplibreUtils } from "./MaplibreUtils";
import { GeojsonWFS, isGeojsonWFS } from "./WFSGeojsonLayerFunc";

export const FEATURELAYERTYPE_DYNAMIC = "DYNAMIC";

export function parseFeatureLayer(a: any): FeatureLayer | undefined {
    if (a instanceof FeatureLayer) {
        return a
    }
    if (a instanceof DynamicDataDrivenLayer) {
        return a
    }
    if (a instanceof VectorLayer) {
        return a
    }
    if (a == undefined) {
        return a
    }
    if (isVectorLayer(a)) {
        return new VectorLayer(a)
    }
    if (isGeojsonWFS(a)) {
        return new GeojsonWFS(a)
    }

    if (a.type) {
        switch(a.type) {
            case "dynamicdatalayer":
            case "dynamicdatalayerpropertyinfo":
                    return new DynamicDataDrivenLayerPropertyInfo(a);
            case "dynamicdatalayerpoi":
                return new DynamicDataDrivenLayerPOI(a);
        }
    }
    return new FeatureLayer(a);

}


export abstract class DynamicDataDrivenLayer extends FeatureLayer {
    customHandleMaplibre?: MitLayerHandleMaplibre

    constructor(layer: {}) {
        super(layer)
    }

    public override getType() {
        return "DYNAMIC" as const;
    }

    public getLegendURL() {
        return "";
    }

    abstract getDataForBBOX(bbox: BBOXTURF): Promise<any>;

    abstract transform2GeoJsonItem(data: any): any;

    createGeoJsonSource() {
        return {
            type: "geojson",
            data: {
                "type": "FeatureCollection",
                "features": []
            }
        };
    }

    abstract createLayer(sourceId:string, layerId:string):any;

    onAdd(map: MapFacadeAbstract, newLocation: MapState): MitLayerHandleMaplibre {
        this.customHandleMaplibre = new MitLayerHandleMaplibre()
        let sourceId="data-source-" + this.layer.label;
        this.customHandleMaplibre.addSource(sourceId, this.createGeoJsonSource())
        let layerId = "data-layer-" + this.layer.label;
        this.customHandleMaplibre.addLayer(this.createLayer(sourceId, layerId));
        map.addLayer(this.customHandleMaplibre)
        this.setDynStyling(map, {})
        this.getDataForBBOX(newLocation.bbox || []).then((a) => {
            if (this.customHandleMaplibre)
                map.setData(this.customHandleMaplibre, a || {})
        })

        return this.customHandleMaplibre
    }

    onMove(map: MapFacadeAbstract, newLocation: MapState) {
        const minZoom = this.layer.minZoom ?? 16

        if (newLocation && newLocation.zoom >= minZoom) {
            this.getDataForBBOX(newLocation.bbox || []).then((a) => {
                if (this.customHandleMaplibre)
                    map.setData(this.customHandleMaplibre, a || {})
            })

            return this.customHandleMaplibre
        }
        return this.customHandleMaplibre;
    }

    abstract setDynStyling(map: MapFacadeAbstract, value);

}

export class DynamicDataDrivenLayerPropertyInfo extends DynamicDataDrivenLayer {

    defaultValuesToShow:any = {};

    constructor(layer: any) {
        super(layer);
        this.defaultValuesToShow = {"data-to_show":layer.propertiesToDisplay || ["ejf_ejere"]};
    }

    async getDataForBBOX(bbox: BBOXTURF): Promise<any> {
        const elasticIndex = SettingsManager.getSystemSetting("indexNamePropertySearch");
        let bboxFilter = {
            "bfe_ejendomsType": { title: "Property Type", filterType: FilterType.SelectText, default: ["SamletFastEjendom"] },
            "tmp_bbox": { title: "Temporary bbox", filterType: FilterType.BoundingBox, default: [11.68, 55.06, 12.29, 55.42] }
        } as any;
        let bboxFilterValue = { "bfe_ejendomsType": ["SamletFastEjendom"], "tmp_bbox": bbox };
        let ifx = new ESInterface(elasticIndex);
        let items = await ifx.doSearch(bboxFilter, bboxFilterValue, SettingsManager.getSystemSetting("maxPropertiesFromElasticsearch"))
            .catch((err) => {
                alert("Got error:" + (err.message || err))
            })
        return (this.transform2GeoJsonItem(items));
    }

    transform2GeoJsonItem(data: any): any {

        function formatHandelsInformation(item: any): string {
            let result = "Handlet ";
            if (item.handel_tidspunkt) {
                let d = new Date(item.handel_tidspunkt);
                result += d.toLocaleDateString() + "";
            }
            if (item.handel_samletKoebesum) {
                result += " til " + (item.handel_samletKoebesum / 1000000).toLocaleString(undefined, { maximumFractionDigits: 2 }) + " mio"
            }
            return result;
        }
        function formatEjerInformation(item: any): string {
            if (item.sfe_registreretArealSamlet > 0 && item.sfe_registreretArealSamlet === item.sfe_vejarealSamlet) {
                return ""; // Vej
            }
            return [...new Set((item.ejf_ejere_liste || []).map((a) => {
                return a.navn
            }).filter((a) => a !== "ukendt ejertype"))].join(",  ") ;
        }
        return data ? {
            "type": "FeatureCollection",
            "features": data.map((item: any) => {
                let format_handel_info = formatHandelsInformation(item);
                item.ejf_ejere = formatEjerInformation(item);
                return {
                    type: "Feature",
                    properties: {
                        ...item, ...formatESInterfaceData(item as ESInterfaceData), format_handel_info: format_handel_info
                    },
                    geometry: {
                        "type": "Point",
                        "coordinates": [item.koord_lng, item.koord_lat]
                    }
                }
            })
        } : {};
    }

    createLayer(sourceId:string, layerId:string):any {
        return {
            id: layerId,
            type: 'symbol',
            source: sourceId,
            layout: {
                "text-field": this.createTextFieldSpec(this.defaultValuesToShow),
                'text-font': ["Noto Sans Bold"],
                'text-size': 12,
                'text-allow-overlap': false
            },
            paint: {
                'text-color': '#454659',
                'text-halo-color': "#fffd",
                'text-halo-width': 1.5,
                'text-halo-blur': 0,
            },
            minzoom: this.layer.minZoom
        }
    }

    createTextFieldSpec(value:{"data-to_show":string[]}):any {
        const values = value?.["data-to_show"] ?? this.layer.variantValues?.["data-to_show"] ?? this.layer?.variantSelectors?.["data-to_show"]?.default ?? []
        let x = values.flatMap((a) => [["concat", ["get", a], ["case", ["has", a], "\n", ""]], { "font-scale": 1.0 }])
        let y = ["format", ...x]
        return y;
    }

    setDynStyling(map: MapFacadeAbstract, value) {
        if (value) {
            this.layer.variantValues = { ...this.layer.variantValues, ...value }
        }

        let y = this.createTextFieldSpec(value);

        let custom = this.customHandleMaplibre
        if (custom)
            map.setLayoutProperty(custom, { "text-field": y })
    }
}

export class DynamicDataDrivenLayerPOI extends DynamicDataDrivenLayer {
    private poiTypes:string[];

    /**
     * 
     * @param layer An object {..., layers:string,  }. layers must be a JSON-string of poi types. E.g. '["stop*,"doctor"]'
     */
    constructor(layer: any) {
        super({path:"dummy", label:"dummy", host:"dummy", ...layer});
        try {
        this.poiTypes = JSON.parse(layer.layers);
        } catch (err:any ) {
            throw new Error("Error parsing poiTypes: "+(err.message || err));
        }
    }

    async getDataForBBOX(bbox: BBOXTURF): Promise<any> {
        let serviceUrl = SettingsManager.getSystemSetting("poiServiceURL", "https://viamap.net/getpoi/");
        let bbox2 = Utils.formatString("{lat},{lng}%20{lat2},{lng2}", { lat: bbox[1], lng: bbox[0], lat2: bbox[3], lng2: bbox[2] });

        // Convert POIList to comma separated string
        let poiTypesString: string = ""; // Example "metro,train,strain,hospital,doctor,school,supermarket";
        this.poiTypes.forEach((poiType: string, idx: number) => {
            if (poiTypesString !== "") {
                poiTypesString += ",";
            }
            poiTypesString += poiType;
        }
        );

        let token = SettingsManager.getSystemSetting("viamapAPIToken");
        let query = serviceUrl + "?bbox=" + bbox2 + "&poitypes=" + poiTypesString + "&token=" + token;
        try {
            let data: { [poitype: string]: any } = {};
            if (poiTypesString == "") {
                for (const poitype of this.poiTypes) {
                    data[poitype] = { numfound: 0, POIs: [] }
                }
            } else if (SettingsManager.getSystemSetting("useMapitPOIServer", false)) {
                // Call Mapit POI service
                data = await AWSAPIGatewayInterface.getPois(bbox2, poiTypesString);
            } else {
                const response = await fetch(query);
                data = await response.json();
            }

            return (this.transform2GeoJsonItem(data));
        } catch (err: any) {
            throw new Error("Got error:" + (err.message || err))
        }
    }

    transform2GeoJsonItem(data: any): any {
        let featuresArray: any[] = [];
        // Process response
        Object.keys(data).forEach((key) => {
            let val = data[key];
            if (val.status && val.status === "none found") {
                // Nothing to display
            } else {
                let icon = SettingsManager.getSystemSetting("poiPinPrefix", "") + POIS[key].icon;
                let label = POIS[key].name;
                if (val && val.POIs && Array.isArray(val.POIs)) {
                    val.POIs.forEach((poi: any) => {
                        featuresArray.push({
                            type: "Feature",
                            properties: {
                                icon: icon,
                                label: label,
                                poiName: poi.name,
                                key: key
                            },
                            geometry: {
                                "type": "Point",
                                "coordinates": [poi.poilatlng[1], poi.poilatlng[0]]
                            }
                        }
                        );
                    });
                }
            }
        });

        return {
            "type": "FeatureCollection",
            "features": featuresArray
        };

    }

    createLayer(sourceId:string, layerId:string):any {
        return MaplibreUtils.makeLayerPoi(layerId, sourceId)
    }

    setDynStyling(map: MapFacadeAbstract, value) {
        // ToDo: Allow selection of poi types in popup
    }
}


export class DynamicDataDrivenLayerFunc {
    public async OnMoveHandler(map: MitMap, onMoveHandler: (map: MitMap, newLocation: MapState) => any) {
        function getBbox(map: MitMap): BBOXTURF {
            let bounds: M.LngLatBounds = map.getBounds();
            let bboxMap: any = bounds.toArray();
            let bbox = [...bboxMap[0], ...bboxMap[1]];
            return bbox;
        }

        let bbox = getBbox(map);
        let mapState = {
            bearing: map.getBearing(),
            pitch: map.getPitch(),
            zoom: map.getZoom(),
            center: (map.getCenter().get2dArr()),
            bbox: bbox
        }
        onMoveHandler(map, mapState);
    }
}




function formatESInterfaceData(item: ESInterfaceData): Partial<Record<string, any>> {
    return {
        handel_samletKoebesum_valutakode: item?.handel_samletKoebesum && formatCurrency(item.handel_samletKoebesum, item?.handel_valutaKode),
        handel_valutaKode: item?.handel_valutaKode,
        handel_tidspunkt: item?.handel_tidspunkt && formatDate(item.handel_tidspunkt),
        handel_maade: item?.handel_maade,
        bbr_anvendelse: item?.bbr_anvendelse && PropertyInformation.bygningsAnvendelsesTekst(item.bbr_anvendelse) + ` (${item.bbr_anvendelse})`,
        sfe_registreretArealSamlet: item?.sfe_registreretArealSamlet && formatArea(item?.sfe_registreretArealSamlet)
    }
}


function formatCurrency(a: string | number, currency: string) {
    if (typeof a == "string") {
        return (parseFloat(a) / 1000000).toLocaleString(Localization.getLocale(), { maximumFractionDigits: 2 }) + " Mio." + (currency ? " " + currency : "");
    }
    return (a / 1000000).toLocaleString(Localization.getLocale(), { maximumFractionDigits: 2 }) + " Mio." + (currency ? " " + currency : "");
}

function formatDate(a: string | number) {
    return new Date(a).toLocaleDateString(Localization.getLocale())
}

function formatUppercase(a: string | number) {
    if (typeof a === "number") {
        return
    }
    return a.slice(0, 1).toUpperCase() + a
}

function formatYear(a: string | number) {
    return a
}

function formatDistance(a: string | number) {
    return parseInt(a.toString()).toLocaleString(Localization.getLocale()) + " m"
}

function formatArea(a: string | number) {
    return parseInt(a.toString()).toLocaleString(Localization.getLocale()) + " m2"
}

function formatPrice(a: string | number) {

}


type ESInterfaceData = {
    handel_samletKoebesum: number
    handel_valutaKode: string,
    "id_namespace": string,
    "id_lokalId": string,
    "bfe_nr": string,
    "bfe_ejendomsType": string,
    "bfe_registreringFra": string,
    "bfe_virkningFra": string,
    "bfe_adresse": string,
    "koord_status": string,
    "koord_lat": number,
    "koord_lng": number,
    "koord_x": number,
    "koord_y": number,
    "geopoint": string,
    "bygningspunkt_DDKNcelle10km": string,
    "bygningspunkt_DDKNcelle1km": string,
    "bygningspunkt_DDKNcelle100m": string,
    "zone_zone": 1,
    "zone_status": string,
    "jords_matrikelnr": string,
    "jords_ejerlavskode": string,
    "sfe_registreretArealSamlet": number,
    "sfe_vejarealSamlet": number,
    "ejf_postnr": string,
    "ejf_kommunenr": string,
    "ejf_ejere_liste": [
        {
            "navn": string,
            "adresse": string,
            "postnr": string,
            "postdistrikt": string,
            "landekode": string,
            "CVRNummer": number,
            "andelProcent": number,
            "displayText": string
        }
    ],
    "ejf_afdoed": boolean,
    "ejf_cvrnr": number,
    "ejf_ejere": string,
    "ejf_ejereLang": string,
    "ejf_reklameBeskyttelse": boolean,
    "bbr_anvendelse": string,
    "bbr_opfoerelsesAAr": number,
    "bbr_tilbygningsAAr": number,
    "bbr_bebyggetAreal": number,
    "bbr_samletBygningsAreal": number,
    "bbr_antalEtager": 5,
    "bbr_bygningensSamledeBoligAreal": number,
    "bbr_koordinater": string,
    "bbr_status": string,
    "bbr_yderVaegMateriale": string,
    "bbr_tagMateriale": string,
    "bbr_samletErhvervsAreal": number,
    "bbr_varmeInstallation": string,
    "poi_dist_junction": number,
    "poi_dist_chargingstation": number,
    "poi_dist_supercharger": number,
    "poi_dist_stop": number,
    "poi_dist_train": number,
    "trafik_highest_hdt": number,
    "trafik_highest_trucks": number,
    "trafik_highest_dist": number,
    "trafik_highest_place": string,
    "trafik_highest_truckRatio": number,
    "handel_tidspunkt": string,
    "handel_forretningsh": null,
    "handel_maade": string,
    "handel_status": string,
    "import_batch_number": number
}