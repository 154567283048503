import { PersistenceObjectType, PersistenceScope, SettingsManager, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import { createContext, useReducer } from "react";

export type cfFieldkey = `cf_${string}`;
export type TEjendomsMetaData = {
  bfeNr: number,
  name: string
  created: Date
  changed: Date
  latLng: [number,number],
  tags: string[]
  [key:cfFieldkey]: any
}


export interface ProjectState {
  version: number,
  isEmpty: boolean,
  isLoading: boolean,
  isLoaded: boolean,
  info?: string,
  toBeAdded: TEjendomsMetaData[] 
  persistentTags: string[]
  data: TEjendomsMetaData[]
  ViamapPersistenceLayer : ViamapPersistenceLayer
  user: {userRef: string, customerRef: string}
}

export enum ProjectAction {
  Retry,
  AddPersistentTag,
  RemovePersistentTag,
  AddTag,
  RemoveTag,
  AddValue,
  RemoveValue,
  HandleAddEjendom,
  HandleAddEjendomAction,
  HandleRemoveEjendom,
  HandleToBeAdded,
  SetEjendomme,
  SetState,
  SetInfo
}

export interface Retry {
  type: ProjectAction.Retry
}

export interface AddPersistentTag {
  type: ProjectAction.AddPersistentTag,
  payload: {tag:string}
}
export interface RemovePersistentTag {
  type: ProjectAction.RemovePersistentTag,
  payload: {tag:string}
}

export interface AddTag {
  type: ProjectAction.AddTag,
  payload: {bfeNr:number, tag:string}
}

export interface RemoveTag {
  type: ProjectAction.RemoveTag,
  payload: {bfeNr:number, tag:string}
}

export interface AddValue {
  type: ProjectAction.AddValue,
  payload: {bfeNr:number ,valueKey:cfFieldkey, value:any}
}

export interface RemoveValue {
  type: ProjectAction.RemoveValue,
  payload: {bfeNr:number, valueKey:cfFieldkey}
}

export interface HandleAddEjendom {
  type: ProjectAction.HandleAddEjendom,
  payload: {bfeNr:number,name:string, latLng:[number,number] ,tags?:string[]}
}

export interface HandleAddEjendomAction {
  type: ProjectAction.HandleAddEjendomAction,
  payload: {bfeNr:number,name:string, latLng:[number,number] ,tags?:string[]}
}

export interface HandleRemoveEjendom {
  type: ProjectAction.HandleRemoveEjendom,
  payload: {bfeNr:number}
}

export interface SetEjendomme {
  type: ProjectAction.SetEjendomme,
  payload: {callb: (a: TEjendomsMetaData[]) => TEjendomsMetaData[]}
}

export interface SetInfo {
  type: ProjectAction.SetInfo,
  payload?: string
}

export interface SetState {
  type: ProjectAction.SetState,
  payload: {state: Partial<ProjectState>}
}

export interface HandleToBeAdded {
  type: ProjectAction.HandleToBeAdded,
  payload: {data: {bfeNr:number,name:string, latLng:[number,number] ,tags?:string[]}[]}
}

export const retry = (): Retry => ({
  type: ProjectAction.Retry
})

export const addPersistentTag = (tag:string):AddPersistentTag => ({
  type: ProjectAction.AddPersistentTag,
  payload: {tag}
})
export const removePersistentTag = (tag:string):RemovePersistentTag => ({
  type: ProjectAction.RemovePersistentTag,
  payload: {tag}
})

export const addTag = (bfeNr:number, tag:string):AddTag => ({
  type: ProjectAction.AddTag,
  payload: {bfeNr, tag}
})

export const removeTag = (bfeNr:number, tag:string):RemoveTag => ({
  type: ProjectAction.RemoveTag,
  payload: {bfeNr, tag}
})

export const addValue = (bfeNr:number ,valueKey:cfFieldkey, value:any):AddValue => ({
  type: ProjectAction.AddValue,
  payload: {bfeNr ,valueKey, value}
})

export const removeValue = (bfeNr:number, valueKey:cfFieldkey):RemoveValue => ({
  type: ProjectAction.RemoveValue,
  payload: {bfeNr, valueKey}
})

export const handleAddEjendom = (bfeNr:number, name:string, latLng:[number,number] ,tags?:string[]):HandleAddEjendom => ({
  type: ProjectAction.HandleAddEjendom,
  payload: {bfeNr, name, latLng ,tags}
})

export const handleAddEjendomAction = (bfeNr:number, name:string, latLng:[number,number] ,tags?:string[]):HandleAddEjendomAction => ({
  type: ProjectAction.HandleAddEjendomAction,
  payload: {bfeNr, name, latLng ,tags}
})

export const handleToBeAdded = (data: {bfeNr:number, name:string, latLng:[number,number] ,tags?:string[]}[]):HandleToBeAdded => ({
  type: ProjectAction.HandleToBeAdded,
  payload: {data}
})

export const handleRemoveEjendom = (bfeNr:number):HandleRemoveEjendom => ({
  type: ProjectAction.HandleRemoveEjendom,
  payload: {bfeNr}
})

export const setEjendomme = (callb: (a: TEjendomsMetaData[]) => TEjendomsMetaData[]):SetEjendomme => ({
  type: ProjectAction.SetEjendomme,
  payload: {callb}
})

export const setState = (state: Partial<ProjectState>):SetState => ({
  type: ProjectAction.SetState,
  payload: {state}
})

export const setInfo = (msg?: string):SetInfo => ({
  type: ProjectAction.SetInfo,
  payload: msg
})

type ProjectActions = 
  Retry |
  AddPersistentTag |
  RemovePersistentTag |
  SetInfo |
  AddTag |
  RemoveTag |
  AddValue |
  RemoveValue |
  HandleAddEjendom |
  HandleAddEjendomAction |
  HandleRemoveEjendom |
  HandleToBeAdded |
  SetEjendomme |
  SetState

export function ProjectReducer(state: ProjectState, action: ProjectActions):ProjectState {
  switch (action.type) {
    case ProjectAction.Retry: {
      return {...state}
    }
    case ProjectAction.AddPersistentTag: {
      if (action.payload.tag.trim() !== "") {
        return {...state, persistentTags: [...state.persistentTags.filter((a) => a !== action.payload.tag.trim()), action.payload.tag.trim()]}
      } 
      return state
    }
    case ProjectAction.RemovePersistentTag: {
      return {...state, persistentTags: [...state.persistentTags.filter((a) => !(a.startsWith(action.payload.tag+"-") || a == action.payload.tag))]}
    }
    case ProjectAction.AddTag:
      if (action.payload.tag.length === 0 || action.payload.tag.trim().length === 0) {
        return state
      }
      return {...state, data: state.data.map((proj) => {
        if (action.payload.bfeNr != proj.bfeNr) {
          return proj
        }
        return {...proj, changed: new Date(), tags: [...proj.tags.filter((oldTag) => oldTag !== action.payload.tag.trim()), action.payload.tag.trim()]}
      })}
    case ProjectAction.RemoveTag:
      return {...state, data: [...state.data.map((proj) => {
        if (action.payload.bfeNr != proj.bfeNr) {
          return proj
        }
        return {...proj, changed: new Date(), tags: [...(proj.tags.filter((oldTag) => oldTag != action.payload.tag))]}
      })]}
    case ProjectAction.AddValue:
      return {...state, data: [...state.data.map((proj) => {
        if (action.payload.bfeNr != proj.bfeNr) {
          return proj
        }
        delete proj[action.payload.valueKey]
        return {...proj, changed: new Date(), [action.payload.valueKey]:action.payload.value}
      })]} 
    case ProjectAction.RemoveValue:
      return {...state, data:[...state.data.map((listItem) => {
        if (action.payload.bfeNr != listItem.bfeNr) {
          return listItem
        }
        delete listItem[action.payload.valueKey]
        return {...listItem, changed: new Date()}
      })]}
    case ProjectAction.HandleAddEjendom:
      let Old = state.data.find((a) => a.bfeNr === action.payload.bfeNr)
      let newProject:TEjendomsMetaData = {
        ...Old,
        bfeNr: action.payload.bfeNr,
        name: action.payload.name,
        created: Old?.created ?? new Date(),
        changed: new Date(),
        latLng: action.payload.latLng,
        tags:[...new Set([...(Old?.tags ?? []), ...(action.payload.tags ?? [])].map((a) => a.trim()))]
      }
    return {...state, data: state.data ? [...state.data.filter((proj) => proj.bfeNr !== newProject.bfeNr), newProject] : [newProject]}
    // FIXME: Remove when uneeded
    case ProjectAction.HandleAddEjendomAction:
      return ProjectReducer(state, {...action, type: ProjectAction.HandleAddEjendom})
    case ProjectAction.HandleRemoveEjendom:
      if ((state.data.find((proj) => proj.bfeNr === action.payload.bfeNr)?.tags || []).length > 0) {
        alert("Kan ikke slette Ejendom med tag")
        return state
      }
    return {...state, data: state.data.filter((proj) => proj.bfeNr !== action.payload.bfeNr)}
    case ProjectAction.SetState: {
      return {...state, ...action.payload.state}
    }
    case ProjectAction.SetInfo: {
      return {...state, info: action.payload}
    }
    case ProjectAction.SetEjendomme:
      return {...state, data: action.payload.callb(state.data)}
    case ProjectAction.HandleToBeAdded:
      return {...state, toBeAdded: action.payload.data.map((a) => ({
        ...a,
        created: new Date(),
        changed: new Date(),
        tags: []
      }))}
  }
}

function migrateState(state: ProjectState,action: ProjectActions):ProjectActions {
  if (action.type === ProjectAction.SetState) {
    let newData = action.payload.state.data?.filter((a) => a.latLng && a.bfeNr)
    action.payload.state.data = newData || [];
  }
  return action
}

export function SaveProjectReducer(state: ProjectState, action: ProjectActions) {
  let migratedAction = migrateState(state,action)

  let NewState = ProjectReducer(state, migratedAction)
  if (NewState?.data?.length || NewState?.persistentTags?.length || (NewState?.data?.length == 0 && NewState?.persistentTags?.length == 0))
    UploadStateIfUpdated(state, NewState)
  if (NewState?.persistentTags?.length === undefined) {
    NewState!.persistentTags = []
  }
  return NewState
}

const initialStateFun = (userRef:string,customerRef:string):ProjectState => {
  let persistLayer = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket", ""))

  return {
    version: 1,
    isEmpty: true,
    isLoaded: false,
    isLoading: false,
    user: {userRef:userRef,customerRef:customerRef},
    data: [],
    toBeAdded: [],
    persistentTags: [],
    ViamapPersistenceLayer: persistLayer
  }
}

const initialSettings:ProjectState = {
  version: 1,
  isEmpty: true,
  isLoaded: false,
  isLoading: false,
  user: {userRef:"",customerRef:""},
  data: [],
  toBeAdded:[],
  persistentTags: [],
  ViamapPersistenceLayer: new ViamapPersistenceLayer("")
}

export const ProjectContext = createContext<{
  state: ProjectState;
  dispatch: React.Dispatch<ProjectActions>;
}>({
  state: initialSettings,
  dispatch: () => null
});

export function useSetupProjectState(SessionState: {customerRef:string, userRef:string}) {
  const [state, dispatch] = useReducer<any, ProjectState>(SaveProjectReducer, initialStateFun(SessionState.userRef,SessionState.customerRef), (a) => a)
  return {state: state as ProjectState,dispatch: dispatch as React.Dispatch<ProjectActions>}
}

var lastUpdated = {value: 1}
export function UploadStateIfUpdated(state, NewState) {
  let K = lastUpdated.value + 1;
  lastUpdated.value = K
  setTimeout(() => {
    if (lastUpdated.value == K) {
      state.ViamapPersistenceLayer.uploadObject(PersistenceScope.User, PersistenceObjectType.Settings, state.user.customerRef, state.user.userRef,  JSON.stringify(({data: NewState.data, persistentTags:NewState.persistentTags, version:NewState.version} as Omit<ProjectState,"ViamapPersistenceLayer" | "user"|"toBeAdded">)), "Projects.json")
    }
  },2000)
}