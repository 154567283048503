import { PropertyInformationDialog } from "src/components/PropertyInformationDialog"
import { closeWindow, ManagedWindow, WindowId } from "./WindowState"
import { ObliqueViewer } from "src/components/ObliqueViewer"
import { SearchWindow } from "src/compentsModus/SearchWindow"
import { PlotScreen } from "src/components/PlotScreen"
import OwnershipDiagram from "src/compentsModus/OwnershipDiagram"
import { BNBOAreaViewerDialog } from "src/BNBOModule/BNBOAreaViewerDialog"
import { BnboDialog} from "src/BNBOModule/BNBODialog"
import { BNBOOpgørelse } from "src/BNBOModule/BNBOOpgørelse"
import { BNBOSingleInfo, BNBOSFEStatus } from "src/BNBOModule/BNBOSFEStatus"
import { SettingsEditor } from "src/components/SettingsEditor"
import { useWindowContext } from "./useWindowContext"
import { CatchmentInterface } from "src/managers/CatchmentInterface"
import { PersistenceScope } from "@viamap/viamap2-common"
import { UserCustomerSettingsEditor } from "src/components/UserCustomerSettingsEditor"
import { BNBOAAreaEditorDialog } from "src/BNBOModule/BNBOAAreadEditorDialog"

export function WindowInstances({window}:{window : ManagedWindow}): JSX.Element | null {
   const {dispatch :windowDispath} = useWindowContext()

   if (typeof window.id !== 'number') {
      return <div>Window not found</div>
   }
   
   switch (window.id) {
      case WindowId.ShowSearchField:
      return <div>Search Field</div>
      case WindowId.InformationWindow:
      return <div>Information Window</div>
      case WindowId.GettingStarted:
      return <div>Getting Started</div>
      case WindowId.ShowReleases:
      return <div>Show Releases</div>
      case WindowId.Legend:
      return <div>Legend</div>
      case WindowId.ShowLegendButton:
      return <div>Show Legend Button</div>
      case WindowId.DemographyReportDialog:
      return <div>Demography Report Dialog</div>
      case WindowId.StreetView:
      return <div>Street View</div>
      case WindowId.SelectCircle:
      return <div>Select Circle</div>
      case WindowId.GenerateReportDialog:
      return <div>Generate Report Dialog</div>
      case WindowId.GenerateReportResultDialog:
      return <div>Generate Report Result Dialog</div>
      case WindowId.PropertyInformationDialog:
      return <PropertyInformationDialog
      showWindow={1}
      labelLatLng={window.windowParams || { latlng: {lat:0, lng:0}}}
      callBackOnClose={() => {
         {}
      }}
      allowExtendedInformation={true}
      cols={2}
      />
      case WindowId.CompanyInformationDialog:
      return <div>Company Information Dialog</div>
      case WindowId.ObliqueViewer:
      return <ObliqueViewer 
      showWindow={2}
      imageProps={window.windowParams}
      onFormClose={() => {}}
      />
      case WindowId.SaveMapDialog:
      return <div>Save Map Dialog</div>
      case WindowId.SaveLinkDialog:
      return <div>Save Link Dialog</div>
      case WindowId.SavedLinkDialog:
      return <div>Saved Link Dialog</div>
      case WindowId.CatchmentSelector:
      return <div>Catchment Selector</div>
      case WindowId.SpatialSelection:
      return <div>Spatial Selection</div>
      case WindowId.CustomerSettingsEditor:
      return <UserCustomerSettingsEditor
      scope={PersistenceScope.Customer}
      mangedWindow={window}
      />
      case WindowId.UserSettingsEditor:
      return <UserCustomerSettingsEditor
      scope={PersistenceScope.User}
      mangedWindow={window}
      />
      case WindowId.MapSettingsEditor:
      return <div>Map Settings Editor</div>
      case WindowId.PointDistanceTable:
      return <div>Point Distance Table</div>
      case WindowId.ConfirmLayerList:
      return <div>Confirm Layer List</div>
      case WindowId.FileTooBig:
      return <div>File Too Big</div>
      case WindowId.NameOnCreation:
      return <div>Name On Creation</div>
      case WindowId.DownloadLayerDialog:
      return <div>Download Layer Dialog</div>
      case WindowId.GDPRNoticeApproval:
      return <div>GDPR Notice Approval</div>
      case WindowId.Explore:
      return <SearchWindow 
      show={true}
      callbackOnClose={() =>  {}}
      />
      case WindowId.PlotScreen:
      return <PlotScreen callbackOnClose={() =>  {}}
      externalSettings={window.windowParams?.externalSettings as any} />
      case WindowId.LoadedSettings:
      return <div>Loaded Settings</div>
      case WindowId.UserAccessRights:
      return <div>User Access Rights</div>
      case WindowId.SimulateOtherUser:
      return <div>Simulate Other User</div>
      case WindowId.CloudSaveDialog:
      return <div>Cloud Save Dialog</div>
      case WindowId.ViewCloudSaveDialog:
      return <div>View Cloud Save Dialog</div>
      case WindowId.AdminCloudSaveDialog:
      return <div>Admin Cloud Save Dialog</div>
      case WindowId.PersonInformationDialog:
      return <div>Person Information Dialog</div>
      case WindowId.OwnerShipDiagram:
      return <OwnershipDiagram
      showWindow={1}
      search={window.windowParams}
      />
      case WindowId.CondominiumDialog:
      return <div>Condominium Dialog</div>
      case WindowId.MapLinkOverview:
      return <div>Map Link Overview</div>
      case WindowId.BNBODialog:
      return <BnboDialog
      showWindow={1}
      onFormClose={() => {}}
      />
      case WindowId.BNBOAreaEditor:
      return <BNBOAAreaEditorDialog 
      showWindow={1}
      managedWindow={window}
      lodsejerId={window.windowParams?.lodsejerId as string}
      // markId={window.windowParams?.markId as string}
      />
      case WindowId.BNBOMarkAreaEditor:
      return <BNBOAAreaEditorDialog 
      showWindow={1}
      managedWindow={window}
      lodsejerId={window.windowParams?.lodsejerId as string}
      // markId={window.windowParams?.markId as string}
      />
      case WindowId.BNBOSingleElement:
      return <BNBOSingleInfo 
      show={1}
      managedWindow={window}
      lodsejerId={window.windowParams?.lodsejerId as any}
      onClose={() => {}}
      />
      case WindowId.BNBOSFEStatus:
      return <BNBOSFEStatus 
      show={1}
      
      onClose={() => {}}
      managedWindow={window}
      filterBy={window.windowParams?.filterBy}
      />
      case WindowId.BNBOOpgørelse:
      return <BNBOOpgørelse
      show={1}
      onClose={() => {}}
      lodsejerId={window.windowParams?.lodsejerId}
      />
      case WindowId.BNBOAreaViewer:
      return <BNBOAreaViewerDialog 
      showWindow={1}
      lodsejerId={window.windowParams?.lodsejerId as string}
      defaultModus={window.windowParams?.defaultModus}
      />
      default:
      return <div>Window not found</div>
   }
}




