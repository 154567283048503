import { AbstractAutocompletePlugin, EjerlavAutocomplete, MatrikelAutocomplete } from "src/managers/SearchBarGeneralPlugin";
import { SearchBarGeneral, SearchBarGeneralContext, SearchBarGeneralPlugin, SearchBarGeneralPluginSuggestion } from "../components/SearchBarGeneral";
import { useContext } from "react";
import { MapitUtils } from "src/managers/MapitUtils";
import { BNBOState, BNBOContext } from "./BNBOState";


class BNBODgunrAutoComplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
   private searchString:string = '';
   private bnboState:BNBOState;
 
   constructor(bnboState:BNBOState, onSelection?:(data:any) => void) {
     super("DguNr", {key:"DguNr"}, 3, 30, 100, "DguNr", onSelection);
     this.bnboState = bnboState;
   }
 
   async calculateSuggestions(context: SearchBarGeneralContext, queryString: string): Promise<SearchBarGeneralPluginSuggestion[]> {
       let results:SearchBarGeneralPluginSuggestion[] = [];
       let alreadyFound:string[]=[]
       for (let lodsejerBnboer of Object.values(this.bnboState.bnboer)) {
         for (let bnbo of Object.values(lodsejerBnboer)) {
           if (bnbo.dguNr.toUpperCase().startsWith(queryString.toUpperCase())) {
             if (!alreadyFound.includes(bnbo.dguNr)) {
               results.push({
                 betegnelse: bnbo.dguNr + " " + bnbo.statusTekst,
                 code: bnbo.dguNr,
                 name: bnbo.dguNr,
                 data: bnbo,
                 onSelection: this.onSelection
               })
               alreadyFound.push(bnbo.dguNr);
             }
           }
         }
       }
       return results

   }
 }
 
 class BNBOSFEAutoComplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
   private searchString:string = '';
   private bnboState:BNBOState;
 
   constructor(bnboState:BNBOState, onSelection?:(data:any) => void) {
     super("SFEnr", {key:"SFE nr"}, 5, 30, 100, "SFEnr", onSelection);
     this.bnboState = bnboState;
   }
 
   async calculateSuggestions(context: SearchBarGeneralContext, queryString: string): Promise<SearchBarGeneralPluginSuggestion[]> {
       let results:SearchBarGeneralPluginSuggestion[] = [];
       let number = Number.parseInt(queryString);
       let isNumber = Number.isInteger(number);
       if (isNumber) {
       for (let lodsejer of Object.values(this.bnboState.lodsejer)) {
         if (lodsejer.sfeNummer.toString().startsWith(queryString)) {
               results.push({
                 betegnelse: lodsejer.sfeNummer + " "+ lodsejer.status,// (lodsejer.Adresse||"") + "("+lodsejer.ejdtype+")",
                 code: lodsejer.sfeNummer,
                 name: ""+lodsejer.sfeNummer,
                 data: lodsejer,
                 onSelection: this.onSelection
               })
         }
      }
   }
       return results
   }
 }
 
class BNBOEjerlavAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
   private bnboState:BNBOState;

   constructor(bnboState:BNBOState, onSelection?:(data:any) => void) {
       super("Ejerlavskode", {key:"Cadastral Region"}, 3, 30, 100, "ejerlav", onSelection);
       this.bnboState = bnboState;
   }

   async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
      let results:SearchBarGeneralPluginSuggestion[] = [];
      let alreadyFound:number[]=[]
      for (let lodsejerMatrikler of Object.values(this.bnboState.matrikler)) {
        for (let matrikel of Object.values(lodsejerMatrikler)) {
          if (matrikel.ejerlavsNavn.startsWith(queryString)) {
            let ejerlavsKode = matrikel.ejerlavsKode;
            if (!alreadyFound.includes(ejerlavsKode)) {
              results.push({
                betegnelse: `${matrikel.ejerlavsNavn} (${matrikel.ejerlavsKode})`,
                isContext: true,
                contextType: "Ejerlavskode",
                contextParse: `$, ${ejerlavsKode}`,
                code: matrikel.ejerlavsKode,
                name: matrikel.ejerlavsNavn,
                data: matrikel,
                onSelection: this.onSelection
              } as any)
              alreadyFound.push(matrikel.ejerlavsKode);
            }
          }
        }
      }
      return results
   }
}

class BNBOMatrikelAutocomplete extends AbstractAutocompletePlugin implements SearchBarGeneralPlugin {
   private bnboState:BNBOState;

   constructor(bnboState:BNBOState, onSelection:(data:any) => void) {
       super("Matrikel", {key:"Cadaster"}, 1, 50, 100, "matrikel", onSelection);
       this.bnboState = bnboState;
   }

   async calculateSuggestions(context: SearchBarGeneralContext, queryString:string) {
       let ejerlavsKode = context["Ejerlavskode"];
       let results:SearchBarGeneralPluginSuggestion[] = [];
       let alreadyFound:string[]=[]
       for (let lodsejerMatrikler of Object.values(this.bnboState.matrikler)) {
         for (let matrikel of Object.values(lodsejerMatrikler)) {
           if ((""+matrikel.ejerlavsKode) === ejerlavsKode && (""+matrikel.matrikelNummer).startsWith(queryString)) {
             let matrikelNr = matrikel.matrikelNummer;
             if (!alreadyFound.includes(matrikelNr)) {
               results.push({
                  betegnelse: matrikel.matrikelNummer,
                  isContext: false,
                  code: matrikel.matrikelNummer,
                  name: matrikel.matrikelNummer,
                  data: matrikel,
                  onSelection: this.onSelection
               } as any)
               alreadyFound.push(matrikelNr);
             }
           }
         }
       }
       results = results.sort((a, b) => {
         if (typeof a.code !== "string") { a.code = ""; }
         if (typeof b.code !== "string") { b.code = ""; }
 
         let res = MapitUtils.compareCadasterOrder(a.code, b.code);
         return res;
     })
     return results;
   }

}

// ----------------------------------------------------------------------------------------------------------------------------------

export const BNBOSearchBar = (props:{callBackOnFilter: (filter:SFEFilterType) => void}) => {
   const {state: bnboState, dispatch: bnboDispatch} = useContext(BNBOContext);

   function onAutoComplete(filter:SFEFilterType) {
      props.callBackOnFilter({
        dguNr: undefined,
        SFENummer: undefined,
        ejerlavsKode: undefined,
        ejerNavn: undefined,
        matrikelNummer: undefined,
        ...filter
      })
   }
   
   return (
      <SearchBarGeneral
          
      showWindow={true}
      autocompleters={[
        new BNBODgunrAutoComplete(bnboState, (data:any)=> {
          onAutoComplete({dguNr:data.dguNr});
        }),
        new BNBOSFEAutoComplete(bnboState, (data:any)=> {
          onAutoComplete({SFENummer:data.sfeNummer});
        }),
        new EjerlavAutocomplete((data:any)=> {
          onAutoComplete({ejerlavsKode:data.ejerlavskode});
        }),
        new MatrikelAutocomplete((data:any)=> {
         onAutoComplete({ejerlavsKode:data.ejerlavskode, matrikelNummer:data.matrikelnummer});
        }),
      ]}
      callbackOnSelection = {(type:string, props:any) => { alert (`got callback Type:${type} props: ${JSON.stringify(props)}`)} }
      />
   )
}

type SFEFilterType = {
  dguNr?:string,
  SFENummer?: number,
  ejerNavn?: string,
  matrikelNummer?: string,
  ejerlavsKode?: number
}