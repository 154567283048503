import {FormControl } from 'react-bootstrap';

import {Localization} from "@viamap/viamap2-common";
import {ValidationType, MapInfo} from '../common/managers/Types';
import { ADModalBody, ADModalFooter, ADModalInfo, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { GlassButton } from './GlassButtons';
import { GlassInfo } from './MitGlassComponents';
import { GlassInputControl, GlassInputGroup, GlassTextInputTransformer } from './GlassInput';
import { useState, useEffect } from 'react';

type Props= {
    showWindow:number;
    mapInfo: MapInfo;
    title?: string;
    callbackOnCancel: ()=>void;
    callbackOnCommit: (mapInfo: MapInfo, readOnly:boolean)=>void;
};

export function SaveMapDialog(props:Props) {
    const [readOnly,setReadOnly] = useState(false); // Readonly is false by default 
    const [name, setName] = useState(props.mapInfo.mapTitle || "")

    function nameOnChange(event:any) {
        setName(event.target.value);
    }

    useEffect(() => {
        setName(props.mapInfo.mapTitle || "")
    },[props.mapInfo.mapTitle])

    // function onChangeReadOnly(e:any) {
    //     setReadOnly(e.target.checked);    
    // }

    function onFormSubmit(e:any) {
        e && e.preventDefault();
        props.callbackOnCommit({...props.mapInfo, mapTitle: name || props.mapInfo.mapTitle}, readOnly);
    }

    function getValidationStateForName():ValidationType {
        const length = name && name.length || 1;
        if (length >= 1) { 
            return 'success'; 
        } else {
            // if (length >= 5) { 
            //     return 'warning'; 
            // } else {
            //     if (length >= 0) { 
                    return 'error'; 
            //     }
            // }
        }
    }
    function validateForm() {return getValidationStateForName() === "success";}

    function handleCancel(e:any) {
        e && e.preventDefault();
        props.callbackOnCancel();
    }

    
        if (props.showWindow) {
            return(
                <AdvancedDragModal 
                    variant={"NSDark"}
                    PosDefault={{top:"90px", left:"50%", transform:"translateX(-50%)", width:"600px"}}
                    title={props.title ? props.title : Localization.getText("Save map")}
                >
                    <ADModalInfo>
                        {Localization.getText("Savemap:description")}
                    </ADModalInfo>
                <ADModalBody>
                    
                    <GlassInputGroup
                        autoFocus={0}
                        onSubmit={(e) => {onFormSubmit(e); handleCancel(e)}}
                        onEscape={(e) => handleCancel(e)}
                    >
                    <GlassTextInputTransformer label={Localization.getText("File name")} >
                    <GlassInputControl>
                    <input
                                type="text"
                                autoComplete='off'
                                value={name}
                                placeholder={Localization.getText("Savemap:defaultFileName")}
                                onChange={(e) => nameOnChange(e)}
                            />
                    </GlassInputControl>
                    </GlassTextInputTransformer>
                    </GlassInputGroup>
            </ADModalBody>
            <ADModalFooter>
            <GlassButton onClick={(e)=> {onFormSubmit(e); handleCancel(e)}}>{Localization.getText("Download")}</GlassButton>
            <GlassButton onClick={(e)=>handleCancel(e)}>{Localization.getText("Cancel")}</GlassButton>
            </ADModalFooter>
            </AdvancedDragModal>
            ); 
            } else {
                return (null);
            }

            /*
            
                    <FormGroup 
                        size="sm"
                        controlId="formBasicText"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        validationState={getValidationStateForName()}
                    >
                        <ControlLabel>{Localization.getText("File name")}</ControlLabel>
                        <FormControl
                            size="sm"
                            type="text"
                            value={props.name}
                            placeholder={Localization.getText("Enter file name")}
                            onChange={(e) => nameOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup 
                        size="sm"
                        controlId="formBasicText"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        validationState={getValidationStateForName()}
                    >
                        <ControlLabel>{Localization.getText("Protect With Password")}</ControlLabel>
                        <FormControl
                            size="sm"
                            type="text"
                            value={props.name}
                            placeholder={Localization.getText("Enter password")}
                            onChange={(e) => nameOnChange(e)}
                        />
                    </FormGroup>
                    <FormGroup 
                        size="sm"
                        controlId="formBasicText"
                        style={{marginLeft:"10%", marginRight:"10%", marginBottom:"20px"}}
                        validationState={getValidationStateForName()}
                    >
                        <ControlLabel>{Localization.getText("Expire Date")}</ControlLabel>
                        <FormControl
                            size="sm"
                            type="date"
                            value={props.name}
                            placeholder={Localization.getText("Enter date")}
                            onChange={(e) => nameOnChange(e)}
                        />
                    </FormGroup>
            */

}
