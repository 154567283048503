import 'bootstrap/dist/css/bootstrap.css';
import 'react-nestable/dist/styles/index.css';
import { createRoot } from 'react-dom/client';
import App from './App';

// import of Mapit CSS
import './css/login.css'
import './index.css';
import './css/PopBtn.css'
import './css/mitGlassDesign.css'
import './css/Project.css'
import './HLED/index.css'

// Import special css
import './css/plotScreen.css'

// Import overridding themes
import './css/Theme-Newsec.css'

// ADM Body Print 
import './css/printADM.css'

const container = document.getElementById('root');
const root = createRoot(container!); 
root.render(<App />);
// root.render(<div>TEST</div>)


