import { useCallback, useEffect, useRef, useState } from "react"
import { MapFunc } from "../managers/MapFunc"
import * as M from 'maplibre-gl';
import { PoiManager } from "../managers/PoiManager";
import { BsMapFill, BsMap} from "react-icons/bs"

enum Layer {
  CurrentPos = "CurrentPos",
  CurrentPoi = "CurrentPoi",
  Route = "Route",
  AllPois = "AAllPois",
}

function FormatTimeFromSec(e: number) {
  if (e) {
    let min = Math.ceil(e/60)
    if (min < 75) {
      return min + " minut" + (min === 1 ? "" : "ter")
    }
    return Math.floor(min/60)+":"+(min%60 < 10 ? "0" + min%60 : min%60)
  }
}

function FormatDistance(e: number) {
  if (e > 999) {
    return Math.round(e/100)/10 + " km"
  } return Math.round(e) + " meter"
}

export function MapView(props:{mapFrame: any, type:string, big:boolean}) {
  let [lastType , setLastType] = useState("");
  let [lastBig , setLastBig] = useState(true);
  let [mapSettings, setMapSettings] = useState({} as {[index:string]:any})
  let transitionTimer = useRef<NodeJS.Timeout>();
  const [mapHasBeenInitialized, setMapHasBeenInitialized] = useState<boolean>(false);
  const hoveredStateId = useRef(0 as number | null);
  let [animationBoolean, setAnimeBoolean] = useState(false);
  let [subPOS, setSubPOS] = useState(0);
  let [subPOLY, setSubPOLY] = useState(0);
  let [subARR, setSubARR] = useState(0);
  let [subPOI, setSubPOI] = useState(0);
  let [isAerialActive, setIsAerialActive] = useState(false);

  let CallGetPois = useCallback(() => {
      let map = MapFunc.getMap();
      let {_sw:{lng:x,lat:y},_ne:{lng:dx,lat:dy}} = map.getBounds() as unknown as {_sw:{lng:number,lat:number},_ne:{lng:number,lat:number}}
      PoiManager.instance().getPoisBBox(props.type, {"y":x,"x":y,"dy":dx,"dx":dy})
  },[props])
  const callGetPoisRef = useRef(CallGetPois)

  useEffect(() => {
    if (MapFunc.getMap()) {
      return () => {}
    }
    PoiManager.instance().subscribe("POS",setSubPOS);
    PoiManager.instance().subscribe("POLY",setSubPOLY);
    PoiManager.instance().subscribe("ARR",setSubARR);
    PoiManager.instance().subscribe("POI",setSubPOI);
    let xmap:any = null

    MapFunc.initMap("hvorlangt").then((map) => {
      xmap = map
      let types = PoiManager.instance().getTypes()
      types.forEach(element => {
        let po = PoiManager.instance().getPoi(element);
        map.loadImage("/"+po.icon+".png" as string).then((image) => {
          {map.addImage(po.icon+"1", image.data)}
        });
      });

      // let x = map.getCanvasContainer().closest(".maplibregl-ctrl-attrib");
      // if (x) {
        // (x as HTMLElement).style.display = "none"; 
      // }


      (props.mapFrame.current as Element).addEventListener('transitionstart', 
        (ev) => {
          let prop = (ev as any).propertyName
          if (transitionTimer.current) {
            return;
          }
          if (prop === "height") {
            transitionTimer.current = setInterval(() => {
              map.resize()
            },1000/60) 
          }
        }
      );
      (props.mapFrame.current as Element).addEventListener('transitionend', 
        (ev) => {
          let prop = (ev as any).propertyName
          if (transitionTimer.current && prop === "height") {
            clearInterval(transitionTimer.current)
            map.resize();
          }
          map.resize();
        }
      )

      map.on('click', Layer.AllPois, (e) => {
        const properties = e!.features![0].properties! as any
        const coords = (e!.features![0].geometry as any).coordinates!
        PoiManager.instance().setAsCurrentPoi(coords[0],coords[1], properties.type, properties.name)
        });
        // Change the cursor to a pointer when the mouse is over the Layer.AllPoi layer.
      
      map.on('mousemove', Layer.AllPois, (e) => {
        map.getCanvas().style.cursor = 'pointer';
        if (e.features!.length > 0) {
          if (hoveredStateId.current !== null) {
          map.setFeatureState(
          { source: Layer.AllPois+"Source", id: hoveredStateId.current },
          { hover: false }
          );
          }
          hoveredStateId.current = e.features![0].id as number;
          map.setFeatureState(
          { source: Layer.AllPois+"Source", id: hoveredStateId.current },
          { hover: true }
          );
        }
      });
      map.on('mouseleave', Layer.AllPois, (e) => {
        map.getCanvas().style.cursor = '';
        if (hoveredStateId.current !== null) {
          map.setFeatureState(
          { source: Layer.AllPois+"Source", id: hoveredStateId.current },
          { hover: false }
          );
          }
          hoveredStateId.current = null;
      });
    
      setMapHasBeenInitialized(true);
    });
    return () => {
      MapFunc.remMap()
    }

  },[])

  useEffect(() => {
    if (subPOS === 0) {
      return;
    }
    let currentPos = PoiManager.instance().getCurrentPos()
    if (currentPos === undefined) {
      return
    }
    let [lng,lat] = currentPos;
    if (lng && lat) {
      let map = MapFunc.getMap()
      let jumpToConf: M.CameraOptions = {
        center: [lng, lat],
        zoom: 14,
      }
      map.jumpTo(jumpToConf)
      updateCurrentPos();
      updateCurrentPoi();
      updatePoiRoute();
      updatePoiArr();
      setTimeout(() => {
        fitBoundsPoly()
      }, 300);
    }

  },[subPOS])

  useEffect(() =>  {
    updatePoiRoute()
    setAnimeBoolean(true)
    setTimeout(() => {setAnimeBoolean(false)}, 200);

  },[subPOLY, subPOS])

  useEffect(() =>  {
    updatePoiArr()

  },[subARR, subPOS])

  if (lastBig !== props.big) {
    let map = MapFunc.getMap();
    if (props.big) {
      // let x = document.getElementsByClassName("maplibregl-ctrl-attrib");
      // if (x.length) {
      //   (x[0] as HTMLElement).style.display = "";
      // }
      let control = new M.NavigationControl({'showCompass': true,'showZoom': true})
      let scale = new M.ScaleControl({unit:"metric", maxWidth:100})
      // map.addControl(control, "top-left");
      // map.addControl(scale, 'bottom-left');
      setMapSettings({...mapSettings, "control": control, "scale": scale})
      callGetPoisRef.current = CallGetPois
      map.on('moveend', callGetPoisRef.current)
      setTimeout(() => {
        fitBoundsPoly()
      }, 300);
    } else {
      // let x = document.getElementsByClassName("maplibregl-ctrl-attrib");
      // if (x.length) {
      //   (x[0] as HTMLElement).style.display = "none"; 
      // }
      mapSettings["control"]?.off && map.removeControl?.(mapSettings["control"])
      mapSettings["scale"]?.off && map.removeControl?.(mapSettings["scale"])
      setMapSettings({...mapSettings, "control": undefined, "scale":undefined})
      if (mapHasBeenInitialized) {
        if (map.getLayer(Layer.AllPois)) {
          map.removeLayer(Layer.AllPois);
        }
        if (map.getLayer(Layer.AllPois+"Cluster")) {
          map.removeLayer(Layer.AllPois+"Cluster");
        }
        if (map.getLayer(Layer.AllPois+"ClusterText")) {
          map.removeLayer(Layer.AllPois+"ClusterText");
        }
        map.off?.('moveend', callGetPoisRef.current)
      }
    }
    setLastBig(props.big)
  }

  if (lastType !== props.type ) {
    if (mapHasBeenInitialized) {

      setLastType(props.type)
      updateCurrentPos()
      updateCurrentPoi();
      updatePoiRoute();
      updatePoiArr();

      fitBoundsPoly();
      if (props.big) {
        let map = MapFunc.getMap()
        map.off('moveend', callGetPoisRef.current)
        callGetPoisRef.current = CallGetPois
        map.on('moveend', callGetPoisRef.current)
      }
    }
    
  }

  useEffect(() => {
    updateCurrentPoi();
    PoiManager.instance().getPolyFor(props.type)

  },[subPOI, subPOS])


  function handleSelectTransMode(a:string) {
    PoiManager.instance().getPolyFor(props.type, a)
  }

  function fitBoundsPoly() {
    let tmpPoi = PoiManager.instance().getPoi(props.type)
    let map = MapFunc.getMap();
    if (tmpPoi === undefined) {
      return;
    }
    let poiPos:[number, number] = tmpPoi.tolatlng;
    let currentPos = PoiManager.instance().getCurrentPos()
    let _rev = (a:number[]) => {return ([a[1],a[0]])} 
    let _rH = (a: number[], b: number[], fun:(a:number,b:number) => number) => {
      return ([fun(a[0],b[0]) , fun(a[1],b[1])]);
    }
    if (tmpPoi.routepolyline && currentPos) {
      let [smal, big] = (tmpPoi.routepolyline as number[][]).reduce((pre,cur) => {
        let [preSmall, preBig] = pre;
        return [_rH(preSmall, cur, Math.min),_rH(preBig, cur, Math.max)]
      }, [_rH(_rev(currentPos), _rev(poiPos), Math.min),_rH(_rev(currentPos), _rev(poiPos), Math.max)])
      let padding: M.PaddingOptions = {
        top:55,
        bottom:20,
        left:20,
        right:20
      }
      map.fitBounds([[smal[1],smal[0]], [big[1],big[0]]], {padding: props.big ? 100 : padding})
    }
  }

  function updateCurrentPoi() {
    if (mapHasBeenInitialized === false) {
      return;
    }
    let tmpPoi = PoiManager.instance().getPoi(props.type)
    let map = MapFunc.getMap();
    let poiPos:[number, number] = tmpPoi.tolatlng;

    if (map.getLayer(Layer.CurrentPoi)) {
      map.removeLayer(Layer.CurrentPoi);
    }
    if (map.getSource(Layer.CurrentPoi+"Source")) {
      map.removeSource(Layer.CurrentPoi+"Source");
    }
    if (poiPos && poiPos[0] && poiPos[1]) {
      map.addSource(Layer.CurrentPoi+"Source", MapFunc.getJsonfromPoint(poiPos[1], poiPos[0], tmpPoi.icon+"1"))
      map.addLayer({
        id: Layer.CurrentPoi,
        type: 'symbol',
        source: Layer.CurrentPoi+"Source",
        layout: {
          "icon-image":["get", "symbol"],
          "icon-anchor":"bottom",
          "icon-size":0.5,
          'icon-allow-overlap': true,
          'icon-ignore-placement': true,
        }
      });
      
      map.moveLayer(Layer.CurrentPoi, Layer.CurrentPos)
    }
  }

  function updateCurrentPos() {
    if (mapHasBeenInitialized === false) {
      return;
    }
    let currentPos = PoiManager.instance().getCurrentPos()
    if (currentPos === undefined) {
      return;
    }
    let [lat, lng] = currentPos;
    let map = MapFunc.getMap();
    if (map.getLayer(Layer.CurrentPos)) {
      map.removeLayer(Layer.CurrentPos);
    }
    if (map.getSource(Layer.CurrentPos+"Source")) {
      map.removeSource(Layer.CurrentPos+"Source");
    }
    map.addSource(Layer.CurrentPos+"Source", MapFunc.getJsonfromPoint(lng,lat, "pin"))
    map.addLayer({
      id: Layer.CurrentPos,
      type: 'symbol',
      source: Layer.CurrentPos+"Source",
      layout: {
        "icon-image":["get", "symbol"],
        "icon-anchor":"bottom",
        'icon-allow-overlap': true,
        'icon-ignore-placement': true,
      }
    });

    map.moveLayer(Layer.CurrentPos)
  }

  function updatePoiRoute() {
    if (mapHasBeenInitialized === false) {
      return;
    }
    let tmpPoi = PoiManager.instance().getPoi(props.type)
    let map = MapFunc.getMap();
    if (map.getLayer(Layer.Route)) {
      map.removeLayer(Layer.Route);
    }
    if (map.getSource(Layer.Route + "Source")) {
      map.removeSource(Layer.Route + "Source");
    }
    if (map.getLayer(Layer.Route + "Start" )) {
      map.removeLayer(Layer.Route + "Start" );
    }
    if (map.getSource(Layer.Route + "StartSource")) {
      map.removeSource(Layer.Route + "StartSource");
    }
    if (map.getLayer(Layer.Route + "End" )) {
      map.removeLayer(Layer.Route + "End" );
    }
    if (map.getSource(Layer.Route + "EndSource")) {
      map.removeSource(Layer.Route + "EndSource");
    }
    if (tmpPoi.routepolyline === undefined) {
      return;
    }
    let currentPos = PoiManager.instance().getCurrentPos()
    if (currentPos) {
      let [lat, lng] = currentPos;
      map.addSource(Layer.Route+"StartSource", MapFunc.getJsonfromListLine([[lng,lat], tmpPoi.routepolyline[0]]))
      map.addLayer({
        id: Layer.Route+"Start",
        type: 'line',
        source: Layer.Route+"StartSource",
        layout: {
          "line-cap":"round",
          "line-join":"round"
        },
        'paint': {
          'line-color': '#3A8E87',
          'line-width': 4,
          'line-dasharray': [0.5,2],
          'line-opacity':0.6
        }
      });
      if (map.getLayer("building-3d")) {
        map.moveLayer(Layer.Route+"Start", "building-3d")
      }
    }
    if (tmpPoi.tolatlng && tmpPoi.tolatlng[1]) {
      let [lat, lng] = tmpPoi.tolatlng as [number,number];
      map.addSource(Layer.Route+"EndSource", MapFunc.getJsonfromListLine([tmpPoi.routepolyline[tmpPoi.routepolyline.length-1], [lng,lat]]))
      map.addLayer({
        id: Layer.Route+"End",
        type: 'line',
        source: Layer.Route+"EndSource",
        layout: {
          "line-cap":"round",
          "line-join":"round"
        },
        'paint': {
          'line-color': '#3A8E87',
          'line-width': 4,
          'line-dasharray': [0.5,2],
          'line-opacity':0.6
        }
      });
      if (map.getLayer("building-3d")) {
        map.moveLayer(Layer.Route+"End", "building-3d")
      }
    }
    map.addSource(Layer.Route+"Source", MapFunc.getJsonfromListLine(tmpPoi.routepolyline))
    map.addLayer({
      id: Layer.Route,
      type: 'line',
      source: Layer.Route+"Source",
      layout: {
        "line-cap":"round",
        "line-join":"round"
      },
      'paint': {
        'line-color': '#3A8E87',
        'line-width': 4
      }
    });
    if (map.getLayer("building-3d")) {
      map.moveLayer(Layer.Route, "building-3d")
    }
  }

  function updatePoiArr() {
    if (mapHasBeenInitialized === false) {
      return;
    }
    let tmpPoi = PoiManager.instance().getPoi(props.type)
    let map = MapFunc.getMap();

    
    if (props.big && tmpPoi.poiArr && tmpPoi.tolatlng && tmpPoi.poiArr.length > 0 ){
    if (!map.getSource(Layer.AllPois+"Source")) {
    map.addSource(Layer.AllPois+"Source", { type:"geojson",
    cluster: true,
    clusterMaxZoom: 11,
    clusterRadius: 45,
    data: MapFunc.getJsonfromListPoints(tmpPoi, tmpPoi.icon+"1", props.type)});
    } else {
      (map.getSource(Layer.AllPois+"Source") as any).setData(MapFunc.getJsonfromListPoints(tmpPoi, tmpPoi.icon+"1", props.type))
    }
    if (!map.getLayer(Layer.AllPois)) {
    map.addLayer({
      id: Layer.AllPois,
      type: 'symbol',
      source: Layer.AllPois+"Source",
      filter: ['!', ['has', 'point_count']],
      layout: {
        'text-field': ['get', 'name'],
        "icon-image":["get", "symbol"],
        "icon-anchor":"bottom",
        "icon-size":0.4,
        "icon-ignore-placement":true,
        "icon-allow-overlap":true,
        'text-anchor':"top",
        'text-radial-offset': 0.5,
        'text-font': ['Droid Sans Regular'],
        'text-size': 10,
        'text-allow-overlap':true,
        "text-ignore-placement":true,
        
      },
      paint: {
        "icon-opacity":0.8,
        "text-halo-color":"#fff",
        "text-halo-width":3,
        "text-opacity": [
          'case',
          ['boolean', ['feature-state', 'hover'], false],
          0.8,
          0
          ]
      }
    });
    map.addLayer({
      id: Layer.AllPois+"Cluster",
      type: 'circle',
      source: Layer.AllPois+"Source",
      filter: ['has', 'point_count'],
      paint: {
        'circle-color': "#334b49",
        'circle-radius': 20
      }
    });

    map.addLayer({
      id: Layer.AllPois+"ClusterText",
      type: 'symbol',
      source: Layer.AllPois+"Source",
      filter: ['has', 'point_count'],
      layout: {
      'text-field': '{point_count_abbreviated}',
      'text-font': ['Droid Sans Regular'],
      
      'text-size': 12
      },
      paint: {
        "text-color":"#fff"
      }
    });
    
    map.moveLayer(Layer.AllPois,Layer.CurrentPoi)
    map.moveLayer(Layer.AllPois+"ClusterText",Layer.AllPois)
    map.moveLayer(Layer.AllPois+"Cluster",Layer.AllPois+"ClusterText")
    }
  }
  }
  

  function kortClicked(e: any) {
    var layerId = 'orthophoto';
    let map = MapFunc.getMap();
    map.setLayoutProperty(layerId, 'visibility', 'none');
    map.resize();
    setIsAerialActive(false);
  }

  function luftfotoClicked(e: any) {
    var layerId = 'orthophoto';
    let map = MapFunc.getMap();
    map.setLayoutProperty(layerId, 'visibility', 'visible');
    map.resize();
    setIsAerialActive(true);
  }

  let currentPoi = PoiManager.instance().getPoi(props.type)
  return (
    <div id="hvorlangt" style={{width:"100%",height:"100%", background:"#f6f5f2"}}>
      {props.big ? 
      <>
      <div className="mapTitle">
        <div className="Type">{currentPoi.dk}</div>
        <div className="Name">{currentPoi.name}</div>
      </div>
      <div className="mapTransType">
        <div className="orthoBut" onClick={isAerialActive ? kortClicked : luftfotoClicked}><i title={isAerialActive ? "Kort":"Luftfoto"} className={isAerialActive ? "bi bi-map-fill" : "bi bi-map"} />{isAerialActive ? <BsMapFill /> : <BsMap />}</div>
        <div className="spacer"></div>
        <div className={currentPoi.mot === "foot" ? "transActive" : ""} onClick={(e) => {handleSelectTransMode("foot")}}><img title="Gang" alt="Gang"  src="/foot.svg" /></div>
        <div className={currentPoi.mot === "bicycle" ? "transActive" : ""} onClick={(e) => {handleSelectTransMode("bicycle")}}><img title="Cykel" alt="Cykel"  src="/bicycle.svg" /></div>
        <div className={currentPoi.mot === "car" ? "transActive" : ""} onClick={(e) => {handleSelectTransMode("car")}}><img title="Bil" alt="Bil"  src="/car.svg" /></div>
      </div>
      <div className={"mapRouteInfo" + (animationBoolean ? " mapRIAni" : "")}>
      <div>
        Transporttype: {{car:"Bil",bicycle:"Cykel",foot:"Gang"}[currentPoi.mot as string]}
      </div>
      <div>
        Afstand: {FormatDistance(currentPoi.routedmeters)}
      </div>
      <div>
        Rejsetid: {FormatTimeFromSec(currentPoi.travelseconds)}
      </div>
      </div>
      </> : null}
    </div>
  )
}