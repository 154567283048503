import { useEffect, useReducer, useState } from 'react';
import { Image } from 'react-bootstrap';
import { AppMessagesBoundary, LicensingBoundary, LicensingContext, MainApplicationFrame, ViamapLicenseActionButton } from '@viamap/viamap2-common';
import { AuthenticationBoundary } from '@viamap/viamap2-common';
import { ErrorBoundary } from '@viamap/viamap2-common';
import { GenericTransactionManager } from '@viamap/viamap2-common';
import { AppMessagesReducer, initialAppMessagesState, AppMessagesContext, AppMessagesActions, transactionalAppMessagesReducer } from '@viamap/viamap2-common';
import { SessionActions } from '@viamap/viamap2-common';
import { SessionContext } from '@viamap/viamap2-common';
import { sessionReducer } from '@viamap/viamap2-common';
import { transactionalSessionReducer } from '@viamap/viamap2-common';
import { initialSessionState } from '@viamap/viamap2-common';
import { EnvironmentConfiguration, EnvironmentLoader } from '@viamap/viamap2-common';
import { Application } from './Application';
import { DeepLinkManager } from './components/DeepLinkManager';

// import './common/components/login.css';

import img1 from '/images/backgrounds/1.png'
import img2 from '/images/backgrounds/2.png'
import img3 from '/images/backgrounds/3.png'
import img4 from '/images/backgrounds/4.png'
import img5 from '/images/backgrounds/5.png'
import img6 from '/images/backgrounds/6.png'
import img7 from '/images/backgrounds/7.png'
import img8 from '/images/backgrounds/8.png'
import img9 from '/images/backgrounds/9.png'
import splash from '/images/MapitSignupPage.png';
import estateSplash from '/images/ExplorerSignupPage.png';

import trial1 from '/images/trial-expired-signup-da.png';
import trial2 from '/images/trial-expired-nosignup-da.png';

import { initialViamapLicensingState, LicenseType, transactionalViamapLicensingReducer, ViamapLicensingActions, ViamapLicensingReducer } from '@viamap/viamap2-common';
import { ApplicationStateActions, ApplicationStateContext, ApplicationStateReducer, FeatureType, hasAccessToFeature, initialApplicationState, transactionalApplicationStateReducer } from './states/ApplicationState';
import { FeatureHelpFile } from '@viamap/viamap2-common/dist/managers/FeatureHelpManager';
import { Product } from '@viamap/viamap2-common/dist/managers/Types';
import { AccessRightsUpdater } from './components/AccessRightsUpdater';
import { ThemeLoader } from './components/ThemeManager';
import { SystemSettingsUpdater } from './components/SystemSettingsUpdater';
import { CatchmentStateActions, CatchmentStateContext, CatchmentStateReducer, initialCatchmentState, transactionalCatchmentStateReducer } from './states/CatchmentState';
import { SimplePathRouter } from './components/SimpleRouter';
import { PersonInfoQuery, PropertyInfoQuery } from './components/PropertyInfoQuery';
import { ErrorMessageModal, InfoMessageModal, LoadingScreen } from './components/AppMessagesComponents';
import { ADMResizeObserver } from './componentsUtils/AdvancedDragModal';
import { DeviceDetector } from './components/DeviceChecker';
import { BNBOManager } from './BNBOModule/BNBOManager';
import { AppMsgManager } from './components/AppMsgManager';
import { Setting } from 'iconsax-react';


function App() {
  const [environmentConfigs, setEnvironmentConfigs] = useState<any[]>([]) 
  let showSignupAsDefault = false;
  let filterLicensesByProductVariant:string|undefined = undefined;

  useEffect(() => {
    let imgUrls = [img1,img2,img3,img4,img5,img6,img7,img8,img9];
    let random = Math.floor(Math.random() * imgUrls.length);
    let r = document.querySelector(':root');
    (r as any)?.style.setProperty('--bgimgTiles',`url(../.${imgUrls[random]})`)

  },[]);

  let params = new URLSearchParams(window.location.search);

  // Routing
  if (params) {
    if (params.get("signup")) {
      // Route app to Signup PAge
      // Example https:/mapit3.viamap.net/?signup=ja
      showSignupAsDefault = true;
    }
    if (params.get("productVariant") && (typeof params.get("productVariant")) === 'string' && (params.get("productVariant") || "").length > 0) {
      // Example https:/mapit3.viamap.net/?signup=ja&productVariant="Explorer"
      filterLicensesByProductVariant = params.get("productVariant") as string;
    }
    if (params.get("language")) {
      // Set initial languagecode.
    }
  }

  
  useEffect(() => {
    
    Promise.all([
      import('./mit-settings.json'),
      import('./mit-soft-settings.json'),
      import('./mit-customer-settings.json'),
      import('./mit-environment-settings.json'),
      import('./localization/licenses.json'),
      import('./localization/Mit-translation.json'),
      import('./mit-help-new-features-4.json'),
      import('./mit-help-new-user.json'),
      import('./mit-soft-vector.json')
    ]).then((a) => {
      setEnvironmentConfigs(a.map((a) => a.default))
    })

  },[])
  

  // Globals
  const requiredAccessRole = "";

  /**
   * Patching session state to add a group to users from some domains.
   * ToDo: replace with proper solution
   * 
   * @param state 
   * @param action 
   * @returns 
   */
/*
  function mySessionReducer(state: SessionState, action: SessionActions): SessionState {
    function addUserGroupsFromUserSettings(userSession:CognitoUserSession):CognitoUserSession {
      let userData = userSession ? userSession.getIdToken().payload : {email:"", "cognito:groups":[]};
      let domain = userData.email.split("@")[1];
      let groups = userData["cognito:groups"] || [];
      let configUserRoles = econfig.customerSettings && econfig.customerSettings[domain] && econfig.customerSettings[domain].userRoles;
      let groupsHaveChanged:boolean = false;
      if (configUserRoles && Array.isArray(configUserRoles)) {
        configUserRoles.forEach((newRole) => {
          if (!groups.includes(newRole)) {
            groups.push(newRole);
            groupsHaveChanged = true;
          }
        })
      }
      if (groupsHaveChanged) {
        let myUserSession = userSession;
        let idToken = myUserSession.getIdToken();
        idToken.payload["cognito:groups"] = groups;
        myUserSession.getIdToken = function () { return idToken};
        return myUserSession;
      }
      // No change
      return userSession;

    }

    if (action.type === SessionActionType.Login) {
      action.results!.userSession = addUserGroupsFromUserSettings(action.results!.userSession);
    }
    if (action.type === SessionActionType.SetUserSession) {
      action.payload.session = addUserGroupsFromUserSettings(action.payload.session);
    }
    
    return sessionReducer(state, action);
  }
*/  
  const [appMessagesState, appMessagesDispatch] = useReducer(AppMessagesReducer, initialAppMessagesState());

  const [licensingState, licensingDispatch] = useReducer(ViamapLicensingReducer, initialViamapLicensingState());
  const [sessionState, sessionDispatch] = useReducer(sessionReducer, initialSessionState);
  const [applicationState, applicationDispatch] = useReducer(ApplicationStateReducer, initialApplicationState());
  const [catchmentState, catchmentDispatch] = useReducer(CatchmentStateReducer, initialCatchmentState());
  

  // const newUserInformation:FeatureHelpFile=require('./mit-help-new-user.json');
  // const releaseInformation:FeatureHelpFile=require('./mit-help-new-features.json');
  // const [mapFunctionalityState, mapFunctionalityDispatch] = useReducer(UserHelpReducer, initialUserHelpState(newUserInformation, releaseInformation));


  if (environmentConfigs.length === 0) {
    return null
  }
  
  function isEstate():boolean {
    return (window.location.hostname.includes("estate"))
  }
  
  const econfig: EnvironmentConfiguration = {
    contentsOfPackageJson: {name: process.env.packageContentName, version: process.env.packageContentVersion},
    globalSettings: {...environmentConfigs[0],...environmentConfigs[1],...environmentConfigs[8]},
    customerSettings: environmentConfigs[2],
    environmentSettings: environmentConfigs[3],
    translationTables: [environmentConfigs[4], environmentConfigs[5]
    ]
  }

  const product= "Mapit"; // SettingsManager.getSystemSetting("applicationSettings")["product"];
  function calculateExpirationDate(durationDays:number):Date {
    const expiration = new Date((new Date()).setDate((new Date()).getDate() + durationDays));
    return expiration;
  }
  let licenseActionButtons:ViamapLicenseActionButton[]=[
    { labelToBeTranslated:{key: "Mapit Visualize"},
      successMessageToBeTranslated:{key: "Trial Started"},
      disallowIfLicenseIsExpired:true,
      // cost:0.0,
      license: {
         apiVersion: "1.0",
         product: product,
         productVariant: "Visualize",
         active: true,
         expires: calculateExpirationDate(30),
         licenseType: LicenseType.Trial,
         note: "Self Trial "+product
      }
    },
    { labelToBeTranslated: {key:"Mapit Lookup"},
    successMessageToBeTranslated: {key:"Trial Started"},
    disallowIfLicenseIsExpired:true,
    // cost:0.0,
    license: {
       apiVersion: "1.0",
       product: product,
       productVariant: "Lookup",
       active: true,
       expires: calculateExpirationDate(30),
       licenseType: LicenseType.Trial,
       note: "Self Trial "+product
    }
  },
  { labelToBeTranslated: {key:"Mapit Surveyor"},
  successMessageToBeTranslated: {key:"Trial Started"},
  disallowIfLicenseIsExpired:true,
  // cost:0.0,
  license: {
     apiVersion: "1.0",
     product: product,
     productVariant: "Surveyor",
     active: true,
     expires: calculateExpirationDate(30),
     licenseType: LicenseType.Trial,
     note: "Self Trial "+product
  }
},
{ labelToBeTranslated: {key:"Estate Explorer"},
successMessageToBeTranslated: {key:"Trial Started"},
disallowIfLicenseIsExpired:true,
// cost:0.0,
license: {
   apiVersion: "1.0",
   product: product,
   productVariant: "Explorer",
   active: true,
   expires: calculateExpirationDate(30),
   licenseType: LicenseType.Trial,
   note: "Self Trial "+product
}
},

  //   { labelToBeTranslated: "1 Year Subscription (Basic)",
  //   successMessageToBeTranslated: "Subscription Started",
  //   disallowIfLicenseIsExpired:false,
  //   // cost:4999.0,
  //   license: {
  //      apiVersion: "1.0",
  //      product: product,
  //      productVariant: "Basic",
  //      active: true,
  //      expires: calculateExpirationDate(365),
  //      licenseType: LicenseType.Subscription,
  //      note: "Buy product "+product
  //   }
  // }
  ]
  if (isEstate()) {
    licenseActionButtons = [{ labelToBeTranslated: {key:"Estate Explorer (30 days)"},
    successMessageToBeTranslated: {key:"Trial Started"},
    disallowIfLicenseIsExpired:true,
    // cost:0.0,
    license: {
       apiVersion: "1.0",
       product: product,
       productVariant: "Explorer",
       active: true,
       expires: calculateExpirationDate(30),
       licenseType: LicenseType.Trial,
       note: "Self Trial "+product
    }
    },
    { labelToBeTranslated: {key:"Estate Explorer Pro (30 days)"},
    successMessageToBeTranslated: {key:"Trial Started"},
    disallowIfLicenseIsExpired:true,
    // cost:0.0,
    license: {
       apiVersion: "1.0",
       product: product,
       productVariant: "ExplorerPro",
       active: true,
       expires: calculateExpirationDate(30),
       licenseType: LicenseType.Trial,
       note: "Self Trial "+product
    }
    },
  ]
  }

  /**
   * Controlled by an url parameter; the  license list can be filtered.
   * If there is only one resulting license type the LicenseBoundary will select this without need for user interaction.
   */
  if (filterLicensesByProductVariant) {
    let filteredLicenseActionButtons = licenseActionButtons.filter((lic) => {
      return lic.license.productVariant === filterLicensesByProductVariant;
    })
    /**
     * For robustness. If the resulting license list becomes empty the original license list will be unchanged
     */
    if (filteredLicenseActionButtons.length > 0) {
      licenseActionButtons = filteredLicenseActionButtons;
    }
  }

  type LanguageCode = string;
  const signupSplash = splash;


  // ToDo: Teaser by language
  
  const ProductTeaser = (language:LanguageCode) => {
    
    const productName = isEstate() ? "Estate Explorer" : "Mapit";

    switch(language) {
      case 'da': return (
      <>
      <MainApplicationFrame
        applicationName={productName}
        productNameAndVersion={productName + '-' + "TODO"}
        releaseInformation={environmentConfigs[6]}
        newUserInformation={environmentConfigs[7]}
        disableAutomaticUserHelpPopup={true}
      >
        <div style={{backgroundColor:"rgb(255,251,243)"}}>
        <div style={{backgroundColor:"rgb(255,251,243)", paddingTop:"10px",display:"flex",justifyContent:"center", flexDirection: "column"}}>
        <h3>Prøv {productName} gratis i 30 dage</h3>
        <div style={{maxWidth:"625px", textAlign:"left"}}>
        </div>
            {productName} er en innovativ platform udviklet til at lette visualicering af data samt udstille komplekse datakilder i et brugervenligt interface 
        </div>
        <Image src={isEstate() ? estateSplash : signupSplash} style={{marginLeft:"auto",marginRight:"auto",maxWidth:"80%",padding:"10px", backgroundColor:"white"}} />
        <div style={{display:"flex",justifyContent:"center"}}>
        <h3>Vælg prøvelicens</h3>
        </div>
        </div>
      </MainApplicationFrame>
      </>
    );
    case 'en': return (
      <>
      <MainApplicationFrame
        applicationName={productName}        
        productNameAndVersion={productName+'-' + "TODO"}
        releaseInformation={environmentConfigs[6]}
        newUserInformation={environmentConfigs[7]}
        disableAutomaticUserHelpPopup={true}
      >
      <div style={{backgroundColor:"rgb(255,251,243)"}}>
        <div style={{backgroundColor:"rgb(255,251,243)", paddingTop:"10px",display:"flex",justifyContent:"center", flexDirection: "column"}}>
        <h3>Get af trial license for {productName} - up to 30 days</h3>
        <div style={{maxWidth:"625px", textAlign:"left"}}>
        </div>
            {productName} is a invoative platform designed to make it easier to visualize your spatial data.
        </div>
        <Image src={isEstate() ? estateSplash : signupSplash} style={{marginLeft:"auto",marginRight:"auto",maxWidth:"80%",padding:"10px", backgroundColor:"white"}} />
        <div style={{display:"flex",justifyContent:"center"}}>
        <h3>Select your trial license</h3>
        </div>
        </div>
        </MainApplicationFrame>
      </>
    );
    default: throw new Error("Unsupported langiage:"+language);
  }};

  const contentsIfLicenseIsExpired = (language:LanguageCode) => {
    switch(language) {
      case 'da': return (
      <div style={{fontSize:36, fontFamily:"Arial, Poppins"}}>
        {`Din licens til ${product} er udløbet!`}
        <div>
        <a href="https://www.viamap.net/trial-udloebet-signup/"><img src={trial1}/></a>
        <a href="https://www.viamap.net/trial-udloebet-tak/"><img src={trial2}/></a>
        </div>
      </div>
    );
    case 'en': return (
      <>
      <div style={{fontFamily:"Arial, Poppins"}}>
        {`Din licens til ${product} er udløbet!`}
        <div>
        {"Jeg ønsker at fortsætte som betalende bruger"}
        </div>
      </div>
      </>
    );
    default: throw new Error("Unsupported langiage:"+language);
  }};

  return (
    <div className="App" >
      <AppMsgManager />
      <ADMResizeObserver />
      <ErrorBoundary >
        <AppMessagesContext.Provider
          value={{
            state: appMessagesState,
            dispatch: GenericTransactionManager.dispatchMiddleware<AppMessagesActions>(appMessagesDispatch, transactionalAppMessagesReducer)
          }}
        >
          <AppMessagesBoundary
            loadingScreen={(a,b) => <LoadingScreen message={a} percentComplete={b} />}
            infoMessage={(a,b) => <InfoMessageModal message={a} onDismiss={b}  />}
            errorMessage={(a,b) => <ErrorMessageModal message={a} onDismiss={b}  />}
          >
            <ApplicationStateContext.Provider
              value={{
                state: applicationState,
                dispatch: GenericTransactionManager.dispatchMiddleware<ApplicationStateActions>(applicationDispatch, transactionalApplicationStateReducer),
                hasAccessToFeature: function tjek(feature: FeatureType) {
                  return hasAccessToFeature(feature, applicationState);
                }
              }
              }
            >
            <DeviceDetector />
            <EnvironmentLoader config={econfig}>
              <ThemeLoader />
              <SessionContext.Provider value={{ state: sessionState, dispatch: GenericTransactionManager.dispatchMiddleware<SessionActions>(sessionDispatch, transactionalSessionReducer) }}>
                <DeepLinkManager>
                <AuthenticationBoundary product={Product.Mapit} requiredAccessRole={requiredAccessRole} splashImagePath={"LoginSplashHvorLangt.png"} background={<><div className="LoginBG" ></div></>} default2signup = {showSignupAsDefault}>
                      <AccessRightsUpdater>
                        <LicensingContext.Provider
                          value={{
                            state: licensingState,
                            dispatch: GenericTransactionManager.dispatchMiddleware<ViamapLicensingActions>(licensingDispatch, transactionalViamapLicensingReducer)
                          }}
                        >
                          <LicensingBoundary 
                            product={Product.Mapit} 
                            productTeaser={ProductTeaser} 
                            licenseOptionsIfNoValidLicense={licenseActionButtons} 
                            autoSelectIfOnlyOneOption={Boolean(filterLicensesByProductVariant)} 
                            contentsIfLicenseIsExpired={contentsIfLicenseIsExpired}
                            allowExpiredLicense={Date.now() < new Date("2024-12-04").getTime()}
                          >
                            <AccessRightsUpdater>
                            <SystemSettingsUpdater>
                              <SimplePathRouter endPoints={
                                [
                                  {queryKey: "/propertyinfo", to: <PropertyInfoQuery />},
                                  {queryKey: "/personinfo", to: <PersonInfoQuery />}
                                ]
                              } >
                                <CatchmentStateContext.Provider
                                  value={{
                                    state: catchmentState,
                                    dispatch: GenericTransactionManager.dispatchMiddleware<CatchmentStateActions>(catchmentDispatch, transactionalCatchmentStateReducer),
                                  }
                                  }
                                >
                                  <BNBOManager>
                                    <MainApplicationFrame
                                      productNameAndVersion={'Mapit-' + "TODO"}
                                      // econfig.contentsOfPackageJson.version
                                      applicationName={licensingState.currentlicense ? licensingState.currentlicense.product : "No current license"}
                                      releaseInformation={environmentConfigs[6]}
                                      newUserInformation={environmentConfigs[7]}
                                      hideNavbar={true}
                                      disableAutomaticUserHelpPopup={true}
                                      >
                                      <Application tab="home" />
                                    </MainApplicationFrame>
                                  </BNBOManager>
                                </CatchmentStateContext.Provider>
                              </SimplePathRouter>
                              </SystemSettingsUpdater>
                            </AccessRightsUpdater>
                          </LicensingBoundary>
                        </LicensingContext.Provider>
                      </AccessRightsUpdater>
                    </AuthenticationBoundary>
                  </DeepLinkManager>
                </SessionContext.Provider>
              </EnvironmentLoader>
            </ApplicationStateContext.Provider>
          </AppMessagesBoundary>
        </AppMessagesContext.Provider>
      </ErrorBoundary>
      
    </div>
  );
}

export default App;


