export const message = {
   info: info,
   error: error,
   progress: progress,
}

function info(msg?:string) {
   let event = new CustomEvent("AppMessage", {
      detail: {
         msg: msg,
         type: "info",
      }
   })
   document.getElementById("appMsg")?.dispatchEvent(
      event
   )
}

function error(msg?:string) {
   let event = new CustomEvent("AppMessage", {
      detail: {
         msg: msg,
         type: "error",
      }
   })
   document.getElementById("appMsg")?.dispatchEvent(
      event
   )
}

function progress(msg?:string, pct?:""|number) {
   let event = new CustomEvent("AppMessage", {
      detail: {
         msg: msg,
         pct: pct,
         type: "progress",
      }
   })
   document.getElementById("appMsg")?.dispatchEvent(
      event
   )
}

export type ErrorTypes = Error | {msg: string} | string
export function basicErrorHandler(error: ErrorTypes) {
   if (error instanceof Error) {
      message.error(error.message)
   } else if (typeof error == "object" && "msg" in error) {
      message.error(error.msg)
   } else if (typeof error == "string") {
      message.error(error)
   } else {
      
   }
}