import { Image, Button } from 'react-bootstrap';
import {Localization} from "@viamap/viamap2-common";
import { CatchmentInterface } from '../managers/CatchmentInterface';
import { CatchmentStateContext } from 'src/states/CatchmentState';
import { MapitStateContext } from 'src/states/MapitState';
import { BsArrowUpRightSquareFill, BsBicycle, BsBusFront, BsCarFront, BsDownload, BsPerson, BsPersonWalking, BsX } from 'react-icons/bs';
import { LuSigma } from 'react-icons/lu';
import { VLine } from './MitGlassComponents';
import { useContext } from 'react';
import { BiSolidTraffic } from 'react-icons/bi';
import RushHour from '../svg/catchment/rushHour.svg?react'
import Transit from '../svg/catchment/transit_2.svg?react'
import TransitBike from '../svg/catchment/transit_bike.svg?react'


export type CatchmentSelectionState = string | null;

type Props = {
  showWindow: boolean;
  isCollapsed: boolean;
  showHideButton: boolean;
  horizontal?: boolean;
  hideCatchments: () => void;
  showDownloadButton: boolean;
  showRushHourSelector: boolean;
  showCombinedBikeAndTransit:boolean;
  onChange:(modeOfTransport:string, rushHourMode:boolean) => void
  onDownloadCatchments?: ()=>void;
  onSaveCatchments?: ()=>void;
  onToggleShowItemCounts?: () => void;
};

export const CatchmentSelector = (props: Props) => {
  const {state:catchmentState} = useContext(CatchmentStateContext);
  const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);

  function onClickApply() {
  }
  function onClickHideCatchment() {
    props.hideCatchments && props.hideCatchments();
  }

  function onClickDownloadCatchment() {
    props.onDownloadCatchments && props.onDownloadCatchments();
  }

  function onClickSigma() {
    props.onToggleShowItemCounts && props.onToggleShowItemCounts();
  }

  function onClickCreateLayerForCatchment() {
    props.onSaveCatchments && props.onSaveCatchments();
  }

  if (props.showWindow) {
    let renderToggleButtons = props.isCollapsed
      ? null
      : CatchmentInterface.transportModes.filter((key) => {
          // Only show 'transit of bike' icon if enabled.
          return props.showCombinedBikeAndTransit || key.type !== 'transitorbike';
        }).map((key, idx) => {
          let icon = key.file;
          let label = Localization.getText("ModeOfTransport:" + key.type);
          let imageClassSelected =
            catchmentState.selectedModeOfTransport === key.type ? " active" : "";
          return (
            <div
            key={key.type}
            title={label}
            onClick={() => {
              props.onChange && props.onChange(key.type, catchmentState.rushHourMode);
            }}
            className={"ExtendedMenuButton " + imageClassSelected}
            >
              <CatchmentIcons type={key.type} />
            </div>
          );
        });
    let img = props.isCollapsed ? "car" : "car_grey";
    let imageUrl2 = new URL("/images/" + img + ".svg", import.meta.url).href
    let label2 = Localization.getText("Click to hide travel times");
    let hideButton = props.showHideButton ? (
      <>
        <div className="ExtendedMenuButton"
        onClick={() => onClickHideCatchment()}
        title={label2}
        >
          <BsX />
        </div>
        <VLine />
      </>
    ) : null;
    let saveAsNewLayersButton = props.onSaveCatchments ? (
      <>
        <VLine />
        <button 
          onClick={() => onClickCreateLayerForCatchment()}
          title={Localization.getText("Click to save travel times as new layer")}
        className="ExtendedMenuButton">
          <BsArrowUpRightSquareFill
            style={{
              fontSize: "20px",
              fontWeight: "bolder",
            }}
          />
      </button>
        </>
    ) : null;
    let sigmaButton = props.onToggleShowItemCounts ? (
      <>
        <VLine />
        <button 
          onClick={() => onClickSigma()}
          title={Localization.getText("Click to toggle area item counts")}
        className={"ExtendedMenuButton" + (catchmentState.showSpatialItemsCounts ? " active" : "")}>
          <LuSigma
            style={{
              fontSize: "20px",
              fontWeight: "bolder",
              margin: "auto",
            }}
          />
        </button>
      </ >
    ) : null;
    let downloadButton = props.showDownloadButton ? (
      <>
        <VLine />
        <button className="ExtendedMenuButton"
        onClick={() => onClickDownloadCatchment()}
        title={Localization.getText("Click to download travel times")}
        >
          <BsDownload />
        </button>
      </>
    ) : null;
    let isActive = catchmentState.selectedModeOfTransport === "car";
    let imageClassSelectedRh =
      isActive && catchmentState.rushHourMode ? " active" : "";
    let imageClassInactiveRh = isActive ? "" : " inactive";
    let rushHourModeCheckBox = props.showRushHourSelector ? (
      <>
        <VLine />
        <button className={"ExtendedMenuButton" +
            imageClassSelectedRh +
            imageClassInactiveRh}
            title={Localization.getText("Click to toggle Rush Hour mode")}
            onClick={() => {
              if (isActive)  {
                props.onChange && props.onChange(catchmentState.selectedModeOfTransport!, !catchmentState.rushHourMode);
              }
            }}  
          >
          <RushHour />
        </button>
      </>
    ) : null;
    return (
      <>
      <div
        className="ExtendedSideBar CatchmentSelector " style={{top: "calc(1*var(--TopSpace))"}}
      >
        <div className='LeftInnerShadow'></div>
        <div className='SidePanelScrolling'>
            {hideButton}
            {renderToggleButtons}
            {rushHourModeCheckBox}
            {sigmaButton}
            {saveAsNewLayersButton}
            {downloadButton}
            <Button
              style={{
                display: "none",
                marginLeft: "auto",
                marginRight: "auto",
              }}
              onClick={() => onClickApply()}
            >
              Apply
            </Button>
        </div>
      </div>
      </>
    );
  } else {
    return null;
  }
}


export function CatchmentIcons(props:{type: "car" | "bike" | "walk" | "transit" | "transitorbike"|"rushHour"}) {
  switch (props.type) {
    case 'car':
      return <BsCarFront />
    case 'bike':
      return <BsBicycle />
    case 'walk':
      return <BsPersonWalking />
    case 'transit':
      return <Transit />
    case 'transitorbike':
      return <TransitBike />
    case 'rushHour':
      return <RushHour />
  }
}