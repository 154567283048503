import { LicensingContext, SessionContext } from "@viamap/viamap2-common";
import { ADModalBody, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { ApplicationStateContext, FeatureType, actionAddAvailableFeature, actionRemoveAvailableFeature } from "src/states/ApplicationState";
import { GlassCheckbox } from "./MitGlassComponents";
import { Fragment, useContext } from "react";

/** Show current user Access Rights. For debugging use */
export function UserAccessRightsWindow(props: { show: boolean, onClose: () => void }) {
   const { state: sessionState, dispatch: sessionDispatch } = useContext(SessionContext);
   const { state: applicationState, dispatch: applicationStateDispatch, hasAccessToFeature } = useContext(ApplicationStateContext);
   const { state: licensingState } = useContext(LicensingContext);
   // const [availableRoles, setAvailableRoles] = useState<string[]>();
   // const [availableFeatures, setAvailableFeatures] = useState<any>();

   function removeFeature(features: FeatureType[], removeFt: FeatureType): FeatureType[] {
      return features.filter((ft, idx) => {
         return ft !== removeFt;
      });
   }

   function addFeature(features: FeatureType[], addFt: FeatureType): FeatureType[] {
      // test for undefined to ensure that '0' values are also matched
      if (features.find((val) => val === addFt) === undefined) {
         features.push(addFt);
      }
      return features;
   }

   return (
      <AdvancedDragModal onClose={props.onClose} 
         title={"Shows the contents of Access Rights"}
         subHeaderContent={<span>Customer: "{sessionState.customerRef}" User: "{sessionState.userRef}"</span>}
      >
         <ADModalBody >
            <div style={{textAlign:"left"}}>
                  <h4 style={{height:"unset"}}>Roles</h4>
                           {applicationState.availableRoles ? applicationState.availableRoles.sort().map((role, idx) => {
                              return (
                                    <div key={role + idx} >{role}</div>
                              )
                           }) : "no roles"}
                  <h4 style={{height:"unset"}}>All Features</h4>
                        {applicationState.features ? applicationState.features.sort((a,b) => a <= b ? -1: 1).map((feature, idx) => {
                           return (
                              <Fragment key={feature + idx}>
                              <GlassCheckbox // prettier-ignore
                                 checked={applicationState.availableFeatures?.includes(feature)}
                                 onClick={(e, checked) => {
                                    if (applicationState.availableFeatures?.includes(feature)) {
                                       applicationStateDispatch(actionRemoveAvailableFeature(feature));
                                    } else {
                                       applicationStateDispatch(actionAddAvailableFeature(feature));
                                    }
                                 }
                                 }
                                 key={feature}
                              >{feature}</GlassCheckbox>
                              </Fragment>
                           )
                        }) : "no features2"}
               </div>
            </ADModalBody>
      </AdvancedDragModal>
   );
}