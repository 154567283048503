import {Localization} from "@viamap/viamap2-common";
import {Logger} from '@viamap/viamap2-common';
import {Cookies, MitCookies} from '@viamap/viamap2-common';
import { createPortal } from 'react-dom';
import cookie from '../svg/markersNewSec/cookie.svg'
import { GlassButton } from './GlassButtons';
import { useState } from "react";
import { ADModalBody, ADModalFooter, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
 



export default function CookieApproval() {
  let [show, setShow] = useState(!Cookies.getCookieBoolean(MitCookies.RememberCookieAccept));
  if (!show) {
    return null
  }

  function AproveCookies(...rest) {
    Cookies.setCookieBoolean(MitCookies.RememberCookieAccept, true);
    setShow(false);
    Logger.logAction("MapScreen","onCookieApprovalCommit","User approved Cookie notice");
  }

    let okbutton = <GlassButton onClick={AproveCookies} >{Localization.getText("CookieAcceptButton")}</GlassButton>;
    return createPortal(
      <AdvancedDragModal PosDefault={{left:"50%",top:"20%", transform: "translate(-50%,-20%)"}}
        title={<img style={{maxHeight:"100px", margin:"auto", display:"block"}} src={cookie} alt='Cookie' />}
      >
        <ADModalBody>
          <span style={{borderRadius:"0px"}} dangerouslySetInnerHTML={{__html:Localization.getText(MitCookies.RememberCookieAccept).replaceAll(". ",`.<br/>`)}} ></span>
        </ADModalBody>
        
      <ADModalFooter>
            {okbutton}
      </ADModalFooter>
    </AdvancedDragModal>
    , document.body)
  
}