import { useContext } from "react";
import { ApplicationStateContext, FeatureType } from "../states/ApplicationState";

/**
 * 
 * @param props.feature Requires Access Feature
 * @param props.children Children shown if access is granted
 * @param props.contentsIfNoAccess (optional) JSX.Element to show if no access. If not specified a standard text is displayed.
 *  
 * @returns 
 */
export const ProtectedFeature = (props:{feature:FeatureType | FeatureType[], children:any, contentsIfNoAccess?:any})=>  {

   let {state:applicationState, dispatch:applicationDispatch, hasAccessToFeature} = useContext(ApplicationStateContext);
   let hasAccess = false
   if (Array.isArray(props.feature)) {
     hasAccess = props.feature.every((a) => hasAccessToFeature(a))
   } else {
    hasAccess = hasAccessToFeature(props.feature)
   }


   return(
     <>
       {hasAccess ? props.children : props.contentsIfNoAccess ? props.contentsIfNoAccess : (
         <div>
         Sorry no access to {(!(typeof props.feature == "string") && props.feature?.[0]) || props.feature}
           </div>
       )}
     </>
   );
 }