import { Spinner } from "react-bootstrap";
import { ApplicationStateContext, Feature } from "src/states/ApplicationState";
import "../css/PersonInfoStyle.css"
import { AppMessagesContext, Cookies, Localization } from "@viamap/viamap2-common";
import { FiCopy, FiPrinter } from "react-icons/fi";
import { ProjectContext } from "src/states/ProjectState";
import { ProtectedFeature } from "./ProtectedFeature";
import { PersonInfoView } from "./PersonInformationView";
import { ViewButtonCheck, ViewButtonIcon } from "src/propertyInfoTemplates/PropertyComponents";
import { PiGraphFill } from "react-icons/pi";
import { actionSetShowWindow, MapitStateContext, MapitWindowId } from "src/states/MapitState";
import { AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { useState, useContext, useEffect } from "react";
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { openWindow, WindowId } from "src/WindowManager/WindowState";

type props = {
  showWindow: number;
  callBackOnClose: () => void;
  personInfo: any
}

export function PersonInformationDialog(props: props) {
  const [personDefaultCollapsed, setPersonDefaultCollapsed] =
  useState(Cookies.getCookieBoolean("PersonAutoColapsed"));
const { dispatch: mapitStateDispatch } = useContext(MapitStateContext)
const { hasAccessToFeature } = useContext(ApplicationStateContext);
const { dispatch: appMessageDispatch } = useContext(AppMessagesContext);
const { dispatch: windowDispatch } = useWindowContext();
const { state: projState, dispatch: projDispatch } =
  useContext(ProjectContext);

const [isLoading, setIsLoading] = useState(true);

useEffect(() => {
  setIsLoading(true);
}, [props.personInfo]);

if (!props.showWindow) {
  return;
}

function RemberCollapseSettings(val) {
  Cookies.setCookieBoolean("PersonAutoColapsed", !val);
}

let fold = (
  <ViewButtonCheck
  className={"no-print"}
  checked={!personDefaultCollapsed} 
  onCheck={(e, val) => {RemberCollapseSettings(val); setPersonDefaultCollapsed(!val)}}
>
  {Localization.getText("Expanded")}
</ViewButtonCheck>
);


if (!props.showWindow) {
  return <></>
}

return (
  <AdvancedDragModal
    PosDefault={{width:"800px", height: "calc(100% - 50px)", top: "50px", left: "50%", transform:"translateX(-50%)"}}
    PosDefaultNS={{width:"calc( 100% - 30px )", height: "calc(100% - 50px)", top: "50px", left:"15px"}}
    saveKey="PersonInfo"
    onClose={() => props.callBackOnClose()}
    title={"Person"}
    subHeaderContent={
      <>
        <ProtectedFeature
          feature={Feature.OpenPersonInfoDocument}
          contentsIfNoAccess={<></>}
        >
          <ViewButtonIcon
            className="no-print"
            onClick={() => {
              // ToDo:
            }
            //   window.open(
            //     `/Personinfo/?name=${props.props.name},dateOfBirth=${props.props.dateOfBirth}`,
            //     "_blank"
            //   )
            }
            title={Localization.getText("Popout")}
          >
            <FiCopy />
          </ViewButtonIcon>
        </ProtectedFeature>
        <ProtectedFeature
          feature={Feature.PrintPersonInfo}
          contentsIfNoAccess={<></>}
        >
          <>
            {!isLoading ? (
              <ViewButtonIcon
                className="no-print"
                onClick={() => {
                  print();
                }}
                title={Localization.getText("Print")}
              >
                <FiPrinter />
                {/* {Localization.getText("Print")} */}
              </ViewButtonIcon>
            ) : (
              <Spinner size="sm" />
            )}
          </>
        </ProtectedFeature>
        <ProtectedFeature feature={Feature.ExplorerOwnership} contentsIfNoAccess={<></>}>
          <ViewButtonIcon 
          title={Localization.getText("Show ownership graph")}
          className="no-print"
          onClick={() => {
            windowDispatch(openWindow(WindowId.OwnerShipDiagram, {personInfo: props.personInfo}))
          }}
          >
            <PiGraphFill />
          </ViewButtonIcon>
        </ProtectedFeature>
        {fold}
        
      </>
    }
  >
    {/* <div className="mit-printable-modal" > */}
    {/* <PrintHeader/> */}

    {(props.personInfo && (
        <PersonInfoView
          key={"peView"}
          foldetUd={!personDefaultCollapsed}
          cols={hasAccessToFeature(Feature.ThemeNewSec) ? 2 : 1}
          personInfo={props.personInfo}
          callBackOnLoaded={(a) => {
            setIsLoading(false);
          }}
        />
    )) ||
      "Missing Info"}
  </AdvancedDragModal>
);
}
