import { useState, useEffect, useRef } from "react";
import { Button, FormControl, FormLabel } from "react-bootstrap";

import {dawaAutocomplete} from "dawa-autocomplete2"

type pospickerprops = {
  row: any;
  onRowChange: (changes:any, save?:boolean) => void;
  onClose: (save?: boolean) => void; 
};

export function PosPicker(props:pospickerprops) {
  let [inputValue, setInputValue] = useState(props.row.resultAdr)
  let elem = useRef<HTMLInputElement | null>(null);

  function callbackOnSelectedAddress(a:any) {
    if (a.tekst && a.data) {
      props.onRowChange({...props.row, resultAdr: a.tekst, lng: a.data.x, lat: a.data.y}, false);
      setInputValue((old) => {return a.tekst})
    }
    
  }

  useEffect(() => {
    const dawaInput = dawaAutocomplete(elem.current, {
        select: (selected: any) => {
            callbackOnSelectedAddress(selected);
        },
        adgangsadresserOnly: true,
    });
    return () => {dawaInput.destroy()};
  },[])
  
  return (
    <div style={{background:"#000a", isolation:"isolate", position:"fixed",inset:"0px", zIndex:10000}}>
    <div style={{background:"white", position:"absolute", left:"50vw", top:"50vh", transform:"translate(-50%,-50%)", padding:"20px"}}>
      <FormLabel>Søg Adresse</FormLabel><br/>
      <FormLabel>Input: {props.row.adr}</FormLabel>
      <FormControl ref={elem} style={{fontSize:"0.8em", width:"320px"}} value={inputValue} onChange={(e) => {setInputValue(e.target.value)}} />
      <hr/>
      <Button onClick={() => {props.onClose();}} >Close</Button><Button onClick={() => {props.onClose(true);}} >Save</Button>
    </div>
    </div>
  );

}
