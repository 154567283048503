import { LicensingContext, SessionContext, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import { Product } from "@viamap/viamap2-common/dist/managers/Types";
import { actionInitializeLicenseManager } from "@viamap/viamap2-common";
import { FormControl } from "react-bootstrap";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { ADModalBody, ADModalFooter, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassInputControl, GlassInputGroup, GlassTextInputTransformer } from "./GlassInput";
import { GlassButton } from "./GlassButtons";
import { useState, useContext } from "react";

export const SimulateOtherUserDialog = (props: { show: boolean, onClose: () => void }) => {
   const [userEmail,setUserEmail] = useState<string>("");
   const {state:sessionState} = useContext(SessionContext);
   const {state:licensingState, dispatch:licencingDispatch} = useContext(LicensingContext);
   const {state:applicationState, dispatch:applicationStateDispatch} = useContext(ApplicationStateContext);
   const [originalUserRef] = useState<string>(sessionState.userRef);


   // useEffect(() => {
   //    if (sessionState.userRef != originalUserRef) {
   //       alert("new userref. License:"+licensingState.currentlicense?.productVariant);
   //       // SettingsManager.initializeAfterUserIsKnown({}, sessionState.customerRef, sessionState.userRef, "mapit");
   //       // applicationStateDispatch(actionUpdateAvailableFeatures(sessionState.userRoles, licensingState?.currentlicense));
   //       // Logger.initialize(licensingState.product, sessionState.userRef, sessionState.userName);
   //    } else {
   //       alert("unchanged")
   //    }
   // }, [sessionState.userRef]);

   function actionSimulateUser() {
      try {
         let userRef = userEmail;
         let customerRef =  ViamapPersistenceLayer.extractCustomerRef(userEmail)||"unknown";
         licencingDispatch(actionInitializeLicenseManager(Product.Mapit, customerRef, userRef));
         // SettingsManager.initializeAfterUserIsKnown({}, customerRef, userRef, Product.Mapit);
         alert("Simulerer nu access rights for bruger "+userEmail);
      } catch (err:any) {
         alert("Simulering fejlede. "+(err.message||err))
      }
      props.onClose();
   }

   return (
      <AdvancedDragModal onClose={props.onClose} 
         title={"Simulate other user login"}
      >
         
         <ADModalBody>
            <GlassInputGroup
            autoFocus={0}
            onSubmit={(e) => {
               actionSimulateUser();
            }}
            onEscape={(e) => {
               props.onClose();
            }}
            >
            <GlassTextInputTransformer label={"User Email"}>
               <GlassInputControl>
                  <FormControl type="text" disabled={false} value={userEmail} placeholder={"email"} onChange={(e) => {setUserEmail(e.target.value)}}/>
               </GlassInputControl>
            </GlassTextInputTransformer>
         </GlassInputGroup>
         </ADModalBody>
         <ADModalFooter>
         <GlassButton onClick={() => {
            actionSimulateUser();
         }}>Do it</GlassButton>
         </ADModalFooter>
      </AdvancedDragModal>
   );
}