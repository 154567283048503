import { useEffect, useMemo, useRef, useState } from 'react';
import * as M from 'maplibre-gl';
import MapboxDraw from '@mapbox/mapbox-gl-draw';
import { SettingsManager } from '@viamap/viamap2-common';
import { MapFacadeMaplibre } from 'src/managers/MapFacadeMaplibre';
import { linkToMapStyle } from 'src/managers/LinkToMapStyle';
// import * as turf from 'turf';

const zoomLevelClusterMax = 12;
const zoomLevelShowingItemsInsteadOfGroups=8;
export type BBOXTURF=number[];
export type ITEMKEY=number;

export type MapState={
  center:[number, number],
  pitch:number,
  zoom:number,
  bearing:number,
  bbox?:BBOXTURF
}

type Props = {
   orthoPhoto:boolean,
   cadasters?:boolean,
   dataSourceGeoJSON:any,
   mapState?:MapState,
   dataSourceItemsJSON?:any,
   dataSourceFilterJSON?: any,
   height?:string,
   onMove?:(newMapState:MapState) => void ,
   onPolygon?: (polygon:any) => void,
   selectBFE: (bfeNumber:number, coord:[number,number]) => void,
   selectedItems?:ITEMKEY[]
}

export const EmbeddedVectorMap = (props:Props) => {

  const [map, setMap] = useState<M.Map>();
  const [draw, setDraw] = useState<any>();
  const [bfe, setBFE] = useState<number>(0)
  let [orthoPhoto, setOrthoPhoto] = useState<boolean>(props.orthoPhoto);

  let blockMouseEvents:boolean = true;
  function setBlockMouseEvents(value:boolean) { blockMouseEvents = value; }

  function getBbox(map:M.Map) : BBOXTURF {
    let bounds: M.LngLatBounds = map.getBounds();
    let bboxMap:any = bounds.toArray();
    let bbox=[...bboxMap[0], ...bboxMap[1]];
    return bbox;
  }

  function updateArea(e:any, draw:any) {
    if (e.features.length > 0) {
      props.onPolygon && props.onPolygon(e);

      // Remove drawn element such that drawing the area can be controlled from caller.
      if (draw) {
        draw.deleteAll();
      } else {
        console.error("Draw not set");
      }
    }
  }

  function deleteArea(e:any) {
    props.onPolygon && props.onPolygon(undefined);
  }

  const Observer = useRef<any>(null);
  const mapContainer = useRef(null);
  const standardMarker = useMemo(()=> {
    constructMarkerForColor("red");
  }, []);

  const dataSource="hitCounts";
  const itemSource="items";
  const filterAreaSource="filterArea";
  const useClustering=true;
  let emptyJSONData = {
    "type": "FeatureCollection",
    "features": []
  };

  function constructMarkerForColor(color:string): {}|null {
    let markerObj = new M.Marker({
        'color': color
    });    

    return markerObj;
  }

  // MAP INITIALIZATION
  useEffect(() => {
    let options={};
    let vectorStylePath=linkToMapStyle(SettingsManager.getSystemSetting("vectorMapStyle",undefined))
    if (vectorStylePath) {
      // ToDo: Get from AWS S3 library
      options= {style:vectorStylePath};
      // Note: Uses viamap default style if not set.
    }
    let ECmap = new MapFacadeMaplibre(mapContainer.current);
    let cleaningMap: M.Map
    ECmap.once('load', (ev) => {
        const map = ev.target
          Observer.current = new ResizeObserver((entries) => {
            map.resize()
          })
          mapContainer.current && Observer.current.observe(mapContainer.current)
          
          // Save map object in state
          setMap(map);
          cleaningMap = map

          // map.addControl(new M.NavigationControl({}),'top-left');
          // map.addControl(new M.FullscreenControl({}),'top-right');
          
          var draw = new MapboxDraw({
            displayControlsDefault: false,
            controls: {
            polygon: true,
            trash: false,
            
            },
            clickBuffer:20
            });
          
          setDraw(draw);
          var control = {
            onAdd: (map) => {
              let y = draw.onAdd(map)
              y.classList.add("maplibregl-ctrl", "maplibregl-ctrl-group")
              return y
            },
            onRemove: () => {draw.onRemove(map)}
          }
          map.addControl(control);

            map.on('draw.create', (e) => updateArea(e, draw));
            map.on('draw.delete', deleteArea);
            map.on('draw.update', (e) => updateArea(e, draw));

            if (props.onMove && map) {
              map.on('moveend', () => {
                if (props.onMove && !blockMouseEvents) {
                 let bbox= getBbox(map);
                 props.onMove({
                    bearing: map.getBearing(),
                    pitch: map.getPitch(),
                    zoom: map.getZoom(),
                    center: (map.getCenter().toArray() as [number, number]),
                    bbox: bbox
                  })
                }
              })
              setBlockMouseEvents(false);
            }
          
          // ----------------------------------- load icons ----------------------------------
          // let scale=1;
          // map.loadImage(`https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png`, function(error, image) {
          //   if (error) throw error;
          //     map.addImage('blue-pin', image as any, {pixelRatio: scale}); // 38x55px, shadow adds 5px (for scale eq 1)
          // });
          // map.loadImage(`https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png`, function(error, image) {
          //   if (error) throw error;
          //     map.addImage('red-pin', image as any, {pixelRatio: scale}); // 38x55px, shadow adds 5px (for scale eq 1)
          // });

          // ----------------------------------- GROUPED BY 10 KM ---------------------------------------------


          map.addSource(dataSource, {
            type: "geojson",
            data: props.dataSourceGeoJSON ? props.dataSourceGeoJSON : emptyJSONData,
            cluster: useClustering,
            clusterMaxZoom: 8, // Max zoom to cluster points on
            clusterRadius: 50, // Radius of each cluster when clustering points (defaults to 50)
            clusterProperties: {
              sum: ["+", ["get", "doc_count", ["properties"]]],
            },
          });

          map.addLayer({
            id: 'point',
            type: 'circle',
            source: dataSource,
            filter: ["!", ["has", "point_count"]],
            paint: {
              'circle-color': '#5e85bc',
              'circle-radius': 20,
              'circle-blur': 0.25
            },
            maxzoom: zoomLevelShowingItemsInsteadOfGroups
          });

          map.addLayer({
            id: 'point-count',
            type: 'symbol',
            source: dataSource,
            filter: ["!", ["has", "point_count"]],
            layout: {
              'text-field': '{doc_count}',
              'text-font': ["Noto Sans Regular"],
              'text-size': 16
            },
            paint: {
              'text-color': 'white'
            },
            maxzoom: zoomLevelShowingItemsInsteadOfGroups
          });
          if (useClustering) {
            map.addLayer({
              id: 'clusters',
              type: 'circle',
              source: dataSource,
              filter: ['has', 'point_count'],
              paint: {
                'circle-color': '#5e85bc',
                'circle-radius': 30,
                'circle-stroke-color': '#cce0ff',
                'circle-stroke-width': 10,
                'circle-blur': 0.25
              },
              maxzoom: zoomLevelShowingItemsInsteadOfGroups
            });
  
            map.addLayer({
              id: 'cluster-count',
              type: 'symbol',
              source: dataSource,
              filter: ['has', 'point_count'],
              layout: {
                'text-field':  ["get", "sum"],
                'text-font': ["Noto Sans Regular"],
                'text-size': 16,
                'text-allow-overlap': true
            },
              paint: {
                'text-color': 'white'
              },
              maxzoom: zoomLevelShowingItemsInsteadOfGroups
            });
          }

          // ----------------------------------- FILTER AREA ---------------------------------------------

          map.addSource(filterAreaSource, {
            type: "geojson",
            data: props.dataSourceFilterJSON ? props.dataSourceFilterJSON : emptyJSONData
          });

          // Add a new layer to visualize the polygon.
          map.addLayer({
            'id': 'filterAreaFill',
            'type': 'fill',
            'source': filterAreaSource, // reference the data source
            'layout': {},
            'paint': {
            'fill-color': ['to-color', ['get','fillColor'], '#0080ff'] , // blue color fill
            'fill-opacity': 0.2
            }
          });
          // Add an outline around the polygon.
          map.addLayer({
          'id': 'filterAreaOutline',
          'type': 'line',
          'source': filterAreaSource,
          'layout': {},
          'paint': {
          'line-color': ['to-color', ['get','lineColor'], "blue"] ,
          'line-width': 1
          }
          });

          // ----------------------------------- INDIVITUAL ITEMS ---------------------------------------------

          map.addSource(itemSource, 
            {
              type: "geojson",
              data: emptyJSONData,
              cluster: useClustering,
              clusterMaxZoom: zoomLevelClusterMax, // Max zoom to cluster points on
              clusterRadius: 60 // Radius of each cluster when clustering points (defaults to 50)
            }
          );

          // map.addLayer({
          //   id: 'itemsDetails',
          //   type: 'circle',
          //   source: itemSource,
          //   filter: ["!", ["has", "point_count"]],
          //   paint: {
          //     'circle-color': '#BD9060',
          //     'circle-radius': 5,
          //   },
          //   minzoom:zoomLevelShowingItemsInsteadOfGroups
          // });
          let pinPrefix = SettingsManager.getSystemSetting("pinPrefix", "mit-pin");
          let activePin = SettingsManager.getSystemSetting("exploreActivePinColor")
          let normalPin = SettingsManager.getSystemSetting("exploreNormalPinColor")
          map.addLayer({
            id: 'itemsDetails',
            type: 'symbol',
            source: itemSource,
            filter: ["!", ["has", "point_count"]],
            'layout': {
              'icon-image': ["match" ,["get", "bfe_nr"], bfe , pin(pinPrefix, activePin),pin(pinPrefix, normalPin)],
              'icon-anchor': 'bottom',
              'icon-offset': [0, 5],
              'icon-allow-overlap': true
            },
            minzoom:zoomLevelShowingItemsInsteadOfGroups
          });

          // map.addLayer({
          //   id: 'itemsLabel',
          //   type: 'symbol',
          //   source: itemSource,
          //   layout: {
          //     'icon-image': ["match" ,["get", "bfe_nr"], bfe , 'red-pin','blue-pin'],
          //     'icon-anchor': 'bottom',
          //     'icon-offset': [0, 5],
          //     'icon-allow-overlap': true,
          //     // 'text-field': ["get","bfe_nr"],
          //     // 'text-font': ["Noto Sans Regular"],
          //     // 'text-size': 12,
          //     // 'text-anchor': "top",
          //     // 'text-offset' : [0,1],
          //     // 'text-allow-overlap': true
          //   },
          //   filter: ["!", ["has", "point_count"]],
          //   paint: {
          //     // 'text-color':"red"
          //   },
          //   minzoom:zoomLevelShowingItemsInsteadOfGroups
          // });

          map.addLayer({
            id: 'item-clusters',
            type: 'circle',
            source: itemSource,
            filter: ['has', 'point_count'],
            paint: {
              'circle-color': '#5e85bc',
              'circle-radius': 18,
              'circle-blur': 0.25
            },
            minzoom:zoomLevelShowingItemsInsteadOfGroups
          });

          map.addLayer({
            id: 'item-cluster-count',
            type: 'symbol',
            source: itemSource,
            filter: ['has', 'point_count'],
            layout: {
              'text-field':  ["get", "point_count"],
              'text-font': ["Noto Sans Regular"],
              'text-size': 16,
              'text-allow-overlap': true
            },
            paint: {
              'text-color': 'white'
            },
            minzoom:zoomLevelShowingItemsInsteadOfGroups
          });
          
          // ----------------------------------- popup on items --------------------------------------------

          // Create a popup, but don't add it to the map yet.
          var popup = new M.Popup({
            closeButton: false,
            closeOnClick: false
            });

            map.on('mouseenter', 'itemsDetails', function (e) {
            // Change the cursor style as a UI indicator.
            map.getCanvas().style.cursor = 'pointer';
 
            if (e && e.features && e.features[0]) {
              var coordinates = (e.features[0] as any).geometry.coordinates.slice() ?? 0;
              var description = (e.features[0] as any).properties.bfe_nr ?? "";
              description = Object.keys((e.features[0] as any).properties).filter((val) => {
                return /* */[
                  "bfe_nr",
                  "jords_ejerlavskode",
                  "jords_matrikeln",
                  "bbr_samletBygningsAreal",
                  "bbr_opfoerelsesAAr",
                  "sfe_registreretArealSamlet",
                  "handel_samletKoebesum",
                  "handel_tidspunkt",
                  "poi_dist_stop",
                  "poi_dist_junction",
                  "poi_dist_chargingstation",
                  "trafik_highest_hdt",
                  "trafik_highest_trucks",
                  "trafik_highest_dist",
                  "trafik_highest_place",
                  "ejf_ejere"
                ].includes(val); /* */
              }).reduce((prev, key, idx) => {
                let val = e.features && (e.features[0] as any).properties[key];
                if (key === "handel_tidspunkt") {
                  val = val && new Date(val).toLocaleDateString();
                }
                return prev + (idx > 0 ? "<br/>" : "")+ `${key}=${val}`;
              }, "");
            }
            // Ensure that if the map is zoomed out such that multiple
            // copies of the feature are visible, the popup appears
            // over the copy being pointed to.
            while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
            coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
            }
             
            // description = `<div style="max-height:70vh;overflow-y:scroll; overflow-x:wrap;scrollbar-width:thin" >
            // ${description}
            // </div>
            // `

            // Populate the popup and set its coordinates
            // based on the feature found.
            // popup.setLngLat(coordinates).setHTML(description).addTo(map);
            });

            const onMapClick = (e) => {
              const bfe_nr = (e?.features?.[0] as any).properties?.bfe_nr as string | undefined
              const coord = (e?.features?.[0] as any).geometry.coordinates as [number,number] | undefined

              let pinPrefix = SettingsManager.getSystemSetting("pinPrefix", "mit-pin");
  
              bfe_nr && coord && props.selectBFE?.(parseInt(bfe_nr), coord);
              bfe_nr && map.setLayoutProperty('itemsDetails','icon-image',["match" ,["get", "bfe_nr"], bfe_nr , pin(pinPrefix, activePin),pin(pinPrefix, normalPin) ])
            }

            map.on('click', 'itemsDetails', onMapClick);
            const delayedStart = () => {
              const preventTouchOnMove = () => {
                map.off('touchend', 'itemsDetails', onMapClick)
                map.off('move', preventTouchOnMove)
              }
              map.on('touchend','itemsDetails', onMapClick)
              map.on('move', preventTouchOnMove)
              setTimeout(() => {
                map.off('touchend', 'itemsDetails', onMapClick)
              }, 200)
            }
            map.on('touchstart', delayedStart);


            map.on('mouseleave', 'itemsDetails', function () {
            map.getCanvas().style.cursor = '';
            // popup.remove();
            });

          /* -------------------------------- matrikler ---------------------- */
          if (props.cadasters) {
          map.addSource('matrikel-lag', {
            'type': 'raster',
            // use the tiles option to specify a WMS tile source URL
            // https://maplibre.org/maplibre-gl-js-docs/style-spec/sources/
            'tiles': [
            'https://services.datafordeler.dk/MATRIKLEN2/MatGaeldendeOgForeloebigWMS/1.0.0/WMS?'+
            'bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&transparent=TRUE&crs=EPSG:3857&width=256&height=256'+
            '&layers=Jordstykke_Gaeldende&styles=Jordstykke_Gaeldende_Roed&username=QBXRBEJZGQ&password=Ballerup2100$'
            ],
            'tileSize': 256
            });
            map.addLayer(
            {
            'id': 'wms-test-layer',
            'type': 'raster',
            'source': 'matrikel-lag',
            'paint': {}
            }
            );
          }
          setTimeout(() => {
            if (props.onMove && !blockMouseEvents) {
              let bbox= getBbox(map);
              props.onMove({
                bearing: map.getBearing(),
                pitch: map.getPitch(),
                zoom: map.getZoom(),
                center: (map.getCenter().toArray() as [number, number]),
                bbox: bbox
              });
              (map as any).getSource(filterAreaSource).setData(props.dataSourceFilterJSON);
            }
          },500)
        })
    return () => cleaningMap?.remove()
  }, []); // Run only once

  useEffect(() => {
    if (map) {

      if (!map.getSource(dataSource)) {
          throw new Error("Datasource not found");
      } else {
        (map as any).getSource(dataSource).setData(props.dataSourceGeoJSON);
      }
    } else {
    }
  }, [props.dataSourceGeoJSON, map]);

  useEffect(() => {
    if (map && props.mapState && !blockMouseEvents) {
      setBlockMouseEvents(true);

      map.setZoom(props.mapState.zoom || 7);
      map.setPitch(props.mapState.pitch || 0);
      map.setBearing(props.mapState.bearing || 0);
      map.setCenter(props.mapState.center || [
        11.428477,
        55.375788
      ]);

      setBlockMouseEvents(false);
      map.resize();
    }
  }, [props.mapState]);

  useEffect(() => {
    map && map.setLayoutProperty('orthophoto', 'visibility', orthoPhoto ? 'visible' : 'none');
  }, [orthoPhoto, map]); // Run when map becomes available or showOrtoPhoto is changed

  useEffect(() => {
    if (map && draw) {

      if (props.dataSourceFilterJSON) { 
        if (!map.getSource(filterAreaSource)) {
            throw new Error("Datasource not found");
        } else {
          (map as any).getSource(filterAreaSource).setData(props.dataSourceFilterJSON);
        }
      }
      else {
        (map as any).getSource(filterAreaSource).setData(emptyJSONData);
      }
    };
  }, [props.dataSourceFilterJSON]); // Run when map becomes available or showOrtoPhoto is changed

  useEffect(() => {
    if (map && props.dataSourceItemsJSON) {
    if (!map.getSource(itemSource)) {
      throw new Error("Datasource not found");
    } else {
      (map as any).getSource(itemSource).setData(props.dataSourceItemsJSON);
    }
  }
}, [props.dataSourceItemsJSON]); // Run when map becomes available or showOrtoPhoto is changed

  // Map render function
  return (
    <div ref={mapContainer} style={{height: props.height || "100%"}} className="map-container" />
  );
}

function pin(prefix:string, color:string) {
  return `${prefix}-${color}`;
}
