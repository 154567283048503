
import { Typeahead } from 'react-bootstrap-typeahead';
import { BsStarFill } from "react-icons/bs";
import { Localization } from "@viamap/viamap2-common";
import { useState, useRef, useEffect } from "react";


export function StarRating({name, value, cfKey ,callbacks, ...rest}) {
  let fixedStar = value[cfKey];

  function handleClick(b:number) {
    callbacks.addV(value.bfeNr ,cfKey, b);
  } 
  
  return (
  <div>
  <h6>{name}</h6>
  <div {...rest} className='ResItem' style={{flex:"95%", display:"flex"}} >
  <div className="SmallStar">
    {[1,2,3,4,5].map((a) => <button className="StarButton" key={a} onClick={() => handleClick(a)} ><BsStarFill key={a} style={{color: (fixedStar && fixedStar >= a ? "#bd9060" : "#816c5d" )}} /></button>)}
    </div>
  </div>
  </div>
  )
}


export function TagChangerTypeAhead({id, value, options, callbacks, ...rest}) {

  function handleRemove(a:string) {
    callbacks.remT(value.bfeNr, a)
  }

  function handleAdd(a:string) {
    callbacks.addT(value.bfeNr, a)
  }

  return (
    <div {...rest} className='ResItem' style={{flex:"95%", display:"flex", justifyContent:"left"}} >
      <Typeahead
      className="EmneInfo"
      style={{width:"100%"}}
      id={"mytypeahead"}
      multiple={true}
      options={options}
      labelKey={"label"}
      allowNew={true}
      clearButton={false}
      minLength={0}
      placeholder={Localization.getText("Start typing to select tags")}
      newSelectionPrefix={Localization.getText("Add new tag") + " "}
      emptyLabel={"No matches found"}
      onChange={(selected) => {
        const removed = value.tags.filter((x) => !selected.includes(x));
        const added = selected.filter((x) => !value.tags.includes(x));
        (removed as any[]).forEach((tag) => { handleRemove(tag.label || tag)});
        (added as any[]).forEach((tag) => { handleAdd(tag.label || tag)});
      }}
      selected={value.tags}
      />
    </div>
  )
}



export function TagChanger({id, value, callbacks, ...rest}) {
  let [tag, setTag] = useState("")

  function handleRemove(a:string) {
    callbacks.remT(value.bfeNr, a)
  }

  function handleAdd(a:string) {
    callbacks.addT(value.bfeNr, a)
  }

  return (
    <div {...rest} className='ResItem' style={{flex:"95%", display:"flex", justifyContent:"center"}} >
    {value.tags.map((a:string) => <button key={a} onClick={() => handleRemove(a)} style={{color: "red"}} >{a}</button>)}
    <input  onChange={(e) => setTag(e.target.value)} placeholder="tag" /><button style={{color: "green"}} onClick={() => handleAdd(tag)} >Add</button>
    </div>
  )
}

export function TextInput({name, value, cfKey ,callbacks, ...rest}) {
  let fakeFieldRef = useRef<any>()

  function handler() {
    let val = fakeFieldRef.current.innerText.trim()
    if (val) {
      callbacks.addV(value.bfeNr, cfKey, val)
    } else {
      callbacks.remV(value.bfeNr, cfKey)
      fakeFieldRef.current.innerText = ""
    }
  }

  useEffect(() => {
    if (fakeFieldRef.current) {
      fakeFieldRef.current.innerText = value[cfKey] || new String()
    }
  },[])

  return (
    <div ref={fakeFieldRef} className="FakeTextField" data-placeholder={Localization.getText("Type here..")} style={{width:"100%", resize:"none"}} contentEditable="true" onBlur={() => {handler()}}  />
  )

}



export function FieldGroup1({title, children}) {
  return (
  <div className="proj pgroup pgroup1">
    <h5>{title}</h5>
    {children}
  </div>)
}

export function FieldGroup2({title, children}) {
  return (
  <div className="proj pgroup pgroup2">
    <h5>{title}</h5><h5>{title}</h5>
    {children}
  </div>
  )
}

export function FieldGroup3({title, children}) {
  return (
  <div className="proj pgroup pgroup3">
    <h5>{title}</h5>
    {children}
  </div>
  )
}