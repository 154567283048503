/**
 * Tools for Spiderify of duplicated points in a layer
 */

import chroma from 'chroma-js';
import { MitLatLng } from './MapFacade';

export class Spider {

    public static convertAllOverlaps(featuresArray:any[]):{hasOverlaps:boolean, convertedArray:any[]} {
    // Check if any points are overlapping
    let points:MitLatLng[] = featuresArray.map((ft) => {
        return new MitLatLng(ft.geometry.coordinates[1], ft.geometry.coordinates[0]);
      });
      // find duplicates
      function getCoordKey(p1:MitLatLng):number  {
        let result= p1.lat * 10000;
        result = Math.floor(result)*10000 + Math.floor(p1.lng*10000);
        return result;
      }
      function isOverlapping(p1:MitLatLng, p2:MitLatLng):boolean {
        return p1.lat === p2.lat && p1.lng === p2.lng;
      }
      function calculateAngleRad(latLngArr:number[], thisNumber:number, count:number):number {
        return ((2*Math.PI)/count) * (thisNumber);
      }
      function angleRadToDregrees(angleRad:number):number {
        return angleRad/Math.PI * 180;
      }
  
      function moveCoordinatesOnOverlap(latLngArr:number[], thisNumber:number, count:number): number[] {
        let angleRad = calculateAngleRad(latLngArr, thisNumber, count);
        let radiusX = 0.00003;
        let radiusY = 0.000045;
        let deltaX = Math.sin(angleRad)*radiusX;
        let deltaY = Math.cos(angleRad)*radiusY;
        return [latLngArr[0]+deltaY, latLngArr[1]+deltaX];
      };
  
      let overlapedIds:any[] = []; // Indicates the indexes that are overlapped and its unique number (1 first, 2 second (the first overlapped), 3)
      let overlapedKeyCount:any[] = []; // Indicates by coordinate key how many overlaps exists for this location
      // let linesSpider:MitLatLng[][] = []; 
      for (var i=0; i<points.length; i++) {
        let count=1;
        for (var j=i+1; j<points.length; j++) {
          if (isOverlapping(points[i], points[j])) {
            console.info("vvvvvvvvvvvvvvv Overlap", "Count", count);
            if (!overlapedIds[i]) {
                overlapedIds[i] = count;
            }  
            if (!overlapedIds[j]) {
              overlapedIds[j] = count+1;
              let key = getCoordKey(points[i]);
              if (!overlapedKeyCount[key] || overlapedKeyCount[key] < count+1) {
                overlapedKeyCount[key] = count+1;
              }
            } else {
              break; // Stop evaluating this point - as its duplicates has already been evaluated
            }
            count++;
          }
        }  
      }
  
      if (overlapedIds.length > 0) {
        for (var i=0; i<points.length; i++) {
          let key = getCoordKey(points[i]);
          if (overlapedIds[i] && overlapedKeyCount[key]) {
            let thisNumber = overlapedIds[i];
            let count = overlapedKeyCount[key];
            console.info("vvvvvvvvvvvvvvv Overlap", "This", thisNumber, "Count", count);
            let newCoords:number[] = moveCoordinatesOnOverlap(featuresArray[i].geometry.coordinates, thisNumber, count);
            featuresArray[i].geometry.coordinates = newCoords;
            let angleRad = calculateAngleRad(featuresArray[i].geometry.coordinates, thisNumber, count);
            featuresArray[i].properties.spiderangle = angleRadToDregrees(angleRad)+90;
            featuresArray[i].properties.spiderIdx = thisNumber;
            featuresArray[i].properties.spiderCount = count;
            // linesSpider.push([points[i], new MitLatLng(newCoords[1], newCoords[0])]);
          }  
        }  
      }

      return {hasOverlaps: overlapedIds.length > 0, convertedArray:featuresArray};
    }

    // private defaultOptions = {
    //     maxLeaves: 255,
    //     minZoomLevel: 0,
    //     zoomIncrement: 2,
    //     closeOnLeafClick: true,
    //     circleSpiralSwitchover: 10,
    //     circleOptions: {
    //       leavesSeparation: 50,
    //       leavesOffset: [0, 0],
    //     },
    //     spiralOptions: {
    //       legLengthStart: 25,
    //       legLengthFactor: 2.2,
    //       leavesSeparation: 30,
    //       leavesOffset: [0, 0],
    //     },
    //     spiderLegsAreHidden: false,
    //     spiderLegsWidth: 1,
    //     spiderLegsColor: 'rgba(100, 100, 100, .7)',
    //     spiderLeavesLayout: null, // default is cluster style layout
    //     spiderLeavesPaint: null, // default is cluster style paint
    //   }

    public static generateLegImage(pos1: number[], pos2: number[], width: number, colorString: string) {
        const color = chroma(colorString);
        const a = pos1[0] - pos2[0];
        const b = pos1[1] - pos2[1];
        const height = Math.round(Math.sqrt((a * a) + (b * b)));
        const bytesPerPixel = 4;
        const data = new Uint8Array(height * width * bytesPerPixel);

        for (let w = 0; w < width; w += 1) {
            for (let h = 0; h < height; h += 1) {
                const offs = (h * width + w) * bytesPerPixel;
                data[offs + 0] = color.get('rgb.r');
                data[offs + 1] = color.get('rgb.g');
                data[offs + 2] = color.get('rgb.b')
                data[offs + 3] = color.alpha() * 255;
            }
        }

        return { width, height, data };
    }

    public static calcAngleDegrees(x:number, y:number) {
        return (Math.atan2(y, x) * 180) / Math.PI;
    }

    /*
  static _generateLegs(points, drawCircle) {
    const { spiderLegsWidth, spiderLegsColor } = { spiderLegsWidth: 1, spiderLegsColor: 'rgba(100, 100, 100, .7)', };
    const legs = [];
    let legImg;

    points.forEach((point, index) => {
      if (!drawCircle || index === 0) {
        legImg = this.generateLegImage(
          [0, 0],
          point,
          spiderLegsWidth,
          spiderLegsColor,
        );
      }
      const leg = { img: legImg, rotation: 90 + this.calcAngleDegrees(point[0], point[1]) };
      legs.push(leg);
    });
    return legs;
  }

  _drawFeaturesOnMap(points, spiderLegs, layerId, coordinates) {
    const { layout, paint } = this.clickedParentClusterStyle;
    const { spiderLegsAreHidden, spiderLeavesLayout, spiderLeavesPaint } = this.options;

    points.forEach((point, index) => {
      const feature = {
        type: 'Feature',
        geometry: { type: 'Point', coordinates },
        properties: this.spiderifiedCluster?.leaves[index]?.properties || {},
      };

      if (!spiderLegsAreHidden) {
        if (this.map.hasImage(`${layerId}-spiderfy-leg${index}`)) {
          this.map.removeImage(`${layerId}-spiderfy-leg${index}`);
        }
        this.map.addImage(`${layerId}-spiderfy-leg${index}`, spiderLegs[index].img);
        this.map.addLayer({
          id: `${layerId}-spiderfy-leg${index}`,
          type: 'symbol',
          source: {
            type: 'geojson',
            data: { type: 'FeatureCollection', features: [feature] },
          },
          layout: {
            'icon-image': `${layerId}-spiderfy-leg${index}`,
            'icon-allow-overlap': true,
            'icon-anchor': 'bottom',
            'icon-rotate': spiderLegs[index].rotation,
          },
        });
        this.activeSpiderfyLayerIds.push(`${layerId}-spiderfy-leg${index}`);
        this.map.moveLayer(`${layerId}-spiderfy-leg${index}`, layerId);
      }

      this.map.addLayer({
        id: `${layerId}-spiderfy-leaf${index}`,
        source: {
          type: 'geojson',
          data: { type: 'FeatureCollection', features: [feature] },
        },
        type: 'symbol',
        layout: {
          ...(spiderLeavesLayout),
          ...(!spiderLeavesLayout ? layout : {}),
          'icon-allow-overlap': true,
          'icon-offset': point,
        },
        paint: {
          ...(spiderLeavesPaint),
          ...(!spiderLeavesPaint ? paint : {}),
          ...(!spiderLeavesPaint && !spiderLeavesLayout && paint['icon-color'] 
            ? { 'icon-color': paint['icon-color'].toString() } : {})
        },
      });
      this.activeSpiderfyLayerIds.push(`${layerId}-spiderfy-leaf${index}`);
    })
  }
  */

}