type MapStyle = 
 | "Newsec"
 | "Klokantech"
 | "Viamap"
 | "Danbolig"
 | "default"

export function linkToMapStyle(style?: MapStyle) {
   switch (style) {
      case "Newsec":
         return new URL("/mapStyles/newsecVectorStyle.json", import.meta.url).href
      case "Klokantech":
         return new URL("/mapStyles/klokantech.json", import.meta.url).href
      case "Viamap":
      default:
         return new URL("/mapStyles/mapit3StandardStyleV3.json", import.meta.url).href
   }
}