import { actionSetErrorMessage, Localization, Logger, PersistenceObjectType, PersistenceScope, SettingsFixedPriorities, SettingsManager, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import { Feature } from "src/states/ApplicationStateFeatures";
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { closeWindow, ManagedWindow } from "src/WindowManager/WindowState";
import { GroupInfo, SettingsEditor, SingleSetting } from "./SettingsEditor";
import { CatchmentInterface } from "src/managers/CatchmentInterface";

export function UserCustomerSettingsEditor(props: {scope: PersistenceScope, mangedWindow: ManagedWindow}): JSX.Element | null {
   const { dispatch: windowDispath } = useWindowContext()
   
   return ( 
      <SettingsEditor
         scope={props.scope}
         settingsManager={SettingsManager}
         priority={PersistenceScopeToSettingsFixedPriorities(props.scope)}
         showWindow={1}
         getSource={(key: string) => SettingsManager.getSystemSettingSource(key)?.priority || 0}
         allSettings={Settings}
         
         affectedMessageText={props.scope === PersistenceScope.User ? Localization.getText("Changed settings affect current user") : Localization.getText("Changed settings affect all employees from the same organization")}
         filteredSettingsGroup={props.mangedWindow.windowParams?.filter ? SettingsGroups.filter(props.mangedWindow.windowParams?.filter) : SettingsGroups}
         callbackOnClose={() => windowDispath(closeWindow(props.mangedWindow.id))}
         callbackToResetCatchment={(newState: any) => {
            CatchmentInterface.catchmentGeoJSONCache = {};
            throw new Error("Not implemented");
         }}
         callbackOnSaveChanges={(settings, sessionState, onClose, appMessageDispatch, source:SettingsFixedPriorities) =>{
         let scope = PersistenceScope.User
         switch (source) {
            case SettingsFixedPriorities.CustomerSettings:
            scope = PersistenceScope.Customer
            break;
            case SettingsFixedPriorities.UserSettings:
            scope = PersistenceScope.User
            break;
            default:
            return appMessageDispatch(actionSetErrorMessage(Localization.getText("Unknown location for settings")));
         }
         
         let inst = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"));
         let objectRef = inst.createJSONObjectRef(PersistenceObjectType.Settings, "Mapit");
         onClose();
         let metaData = {
            filename: encodeURIComponent(objectRef),
            lastModified: new Date().getTime().toString()
         };
         inst.uploadObject(scope, PersistenceObjectType.Settings, sessionState.customerRef, sessionState.userRef, JSON.stringify(settings), objectRef, metaData)
         .catch(error => {
            appMessageDispatch(actionSetErrorMessage(Localization.getText("Could not save settings")));
            Logger.logError("SettingsEditor", "onClickSaveCustomUserSettings", error);
         });
      }}
      /> 
   )
}


/**
* warning: key musn't be changed used in external components
*/
const SettingsGroups:GroupInfo = [
   {
      key: "import",
      da: "Import",
      en: "Import"
   },
   {
      key: "featurelayers",
      da: "Featurelag",
      en: "Feature layers"
   },
   {
      key: "legend",
      da: "Signaturforklaring",
      en: "Legend",
   },
   {
      key: "catchment",
      da: "Rejsetid",
      en: "Traveltime"
   },
   {
      key: "bnbo",
      da: "BNBO",
      en: "BNBO"
   },
   {
      key:"debugging",
      da: "Fejlfinding",
      en: "Debugging"
   }
]

const Settings:SingleSetting[] = [
   {
      access: Feature.BasicMap,
      group: "import",
      key: "AsDefaultCreatePopupInfoForAllFieldsInGeoJSONImports",
      type:"Boolean",
      default: true,
      true: {da: "Alle Attributter i Popup", en:"All attrib. in Popup"},
      false: {da: "Ingen Attibutter i Popup", en:"No attrib. in Popup"},
      da: "Json Import",
      en: "Json Import",
   },
   {
      access: Feature.BasicMap,
      group: "legend",
      key: "OrderBackgroundLayersInDrawingOrder",
      default: true,
      da: "Rækkefølge af Baggrundslag",
      en: "Order of Background layers",
      type:"Boolean",
      true: {da:"Som tegnet på kortet",en:"As shown on map"},
      false:{da:"Som vist på listen",en:"As ordered in layer list"}
   },
   {
      access: Feature.BasicMap,
      group: "featurelayers",
      key: "SaveOpendedFeatureLayers",
      default: false,
      da: "Husk tændte lag",
      en: "Remeber active layer",
      type:"Boolean",
      true: {da:"Slået til",en:"Activated"},
      false:{da:"Slået fra",en:"Deactivated"}
   },
   {
      access: Feature.PowerUser,
      group: "import",
      key: "timeBetweenLoadingMultipleFilesMS",
      type:"Integer",
      sufix: "ms",
      default: true,
      min: 0,
      max: 2000,
      da: "Tid mellem fil indlæsning",
      en: "Time between reading imported files",
   },
   {
      access: Feature.TravelTimeMultiple,
      group: "catchment",
      key: "catchmentStateDefaults.reverse",
      type: "Boolean",
      true: {da: "Mod Punkt", en: "Towards Points"},
      false: {da: "Fra Punkt", en: "From Points"},
      da: "Retning",
      en: "Direction",
   },
   {
      access: Feature.TravelTimeMultiple,
      group: "catchment",
      key: "catchmentStateDefaults.duration",
      type: "Integer",
      default: 90,
      sufix: "min",
      min: 5,
      max: 180,
      da: "Max rejsetid",
      en: "Max travel duration",
   }, 
   {
      access: Feature.TravelTimeMultiple,
      group: "catchment",
      key: "catchmentStateDefaults.date",
      default: 20230810,
      type: "Date",
      da: "Dato",
      en: "Date"
   },
   {
      access: Feature.TravelTimeMultiple,
      group: "catchment",
      key: "catchmentStateDefaults.time",
      type: "TimeADay",
      da: "Tidspunkt",
      en: "Travel time"
   },
   {
      access: Feature.TravelTimeMultiple,
      group: "catchment",
      key: "catchmentStateDefaults.earliestArrivalTime",
      default: 0,
      type: "TimeADay",
      da: "Tidligst ank./afr.",
      en: "Earliest arr./dep.",
   },
   {
      access: Feature.TravelTimeMultiple,
      group: "catchment",
      key: "catchmentStateDefaults.earliestArrival",
      default: true,
      type: "Boolean",
      true: {da: "Kortest rejsetid", en:"Shortest traveltime"},
      false: {da: "Kortest ventetid", en:"Shortest waiting time"},
      da: "Beregningsmetode",
      en: "Calculation method"
   },
   {
      access: Feature.TravelTimeMultipleBufferSetting,
      group: "catchment",
      key: "catchmentStateDefaults.buffer",
      default: 0.002,
      precision: 0.0001,
      type: "Float",
      min: 0,
      max: 0.002,
      da: "Rejsetids Buffer",
      en: "Traveltime Buffer"
   },
   {
      access: Feature.Debugging,
      scope: PersistenceScope.User,
      group: "debugging",
      key: "debuggingShowExtraDebugging",
      default: false,
      type: "Boolean",
      da: "Vis ekstra debugging knapper",
      en: "Show extra debugging buttons",
      true: {da:"Vis knapper", en:"show buttons"},
      false: {da:"Skjul knapper", en:"hide buttons"}
   },
   {
      access: Feature.Debugging,
      scope: PersistenceScope.User,
      group: "debugging",
      key: "debuggingShowExtraDebuggingPlot",
      default: false,
      type: "Boolean",
      da: "Vis ekstra debugging plot",
      en: "Show extra debugging plot",
      true: {da:"Vis ekstra", en:"show extra"},
      false: {da:"Skjul ekstra", en:"hide extra"}
    } 
]


function PersistenceScopeToSettingsFixedPriorities(scope: PersistenceScope) {
   switch (scope) {
      case PersistenceScope.User: return 60
      case PersistenceScope.Customer: return 50
      default: return 60
   }
}
