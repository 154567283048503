import { SettingsManager } from "@viamap/viamap2-common";
import { BNBOErstatningLinie, BNBOErstatningLinieNoTotal, BNBODel, BNBOMatrikel, BNBOOmrådeType, BNBOState, getMarkId, safeGetDoubleDepthObject } from "src/BNBOModule/BNBOState";
import { PropertyInfoInterface } from '../managers/PropertyInfoInterface';
import * as Turf from '@turf/turf';
import { BNBOFunc } from "./BNBOFunc";
import { MapitUtils } from "src/managers/MapitUtils";
import { constructMaskObj, getAllDeleForLodsejer, getAllFeaturesForLodsejer, getAllSubFeaturesLodsejer, getSFEAsFeatureList, nonOverlappingGeo, nonOverlappingGeoAdv } from "./BNBOAreaEditorExtra";
import { Margin } from "gojs";


export const BNBOBeregninger = {
   sumErstatninger,
   udregnErstatningEjendom,
   udregnErstatningMark,
   udregnErstatningEnkel,
   udregnMarkArealByType,
   getKommuneErstatningsProcent,
   getDefaultSats,
   faktorByMarkDelType,
   faktorByOmrådeType,
   faktorFormat,
   formatErstatningsBeregningMark,
   formatErstatningsBeregningEjendom,
   formatTotalErstatning,
};

function roundAndCalculateTotal(record: BNBOErstatningLinieNoTotal): BNBOErstatningLinie {
   let afrundetAntal = Math.round(record.antal);


   return {
      ...record,
      antal: afrundetAntal,
      erstatning: afrundetAntal * record.takst * record.faktor * record.procent
   };
}

function udregnErstatningEjendom(bnboState: BNBOState, lodsejerId: string): BNBOErstatningLinie[] {
   let lodsejer = bnboState.lodsejer[lodsejerId];
   // return new Promise<{ res: any[], erst: BNBOErstatningLinie[] }> ((resolve, reject) => { 
   let res: any[] = [];
   let erst: BNBOErstatningLinie[] = [];

   let dele = getAllDeleForLodsejer(bnboState, lodsejerId);
   let geo = nonOverlappingGeoAdv(dele, true, getSFEAsFeatureList(bnboState, lodsejerId), constructMaskObj(getAllSubFeaturesLodsejer(bnboState, lodsejerId)));
   for (let feat of geo) {
      // console.log("feat props", feat.properties);
      erst.push(roundAndCalculateTotal({
         geometry: feat.geometry,
         areaType: feat.properties.type,
         antal: feat.properties.arealNetto,
         takst: (feat.properties.isManualTakst && feat.properties.takst) || lodsejer.sats || getDefaultSats(),
         faktor: ((feat.properties.isManualTakst && 1) || feat.properties.faktor) ?? faktorByOmrådeType(feat.properties.type),
         procent: (feat.properties.isManualTakst && 1) || getKommuneErstatningsProcent(),
         isManualTakst: feat.properties.isManualTakst || false,
         note: feat.properties.note,
         mask: feat.properties.mask,
         matrikel: feat.properties.mask ? { ejerlavsKode: feat.properties.mask, ejerlavsNavn: "", matrikelNummer: feat.properties.mask } : undefined
         // mark: mark ? { markBlok: mark.markBlok, markNr: mark.markNr, afgrøde: mark.afgrøde } : undefined
      }));
   }  
   return erst;
}

async function udregnErstatningEjendomV1(state: BNBOState, lodsejerId: string): Promise<{ res: any[], erst: BNBOErstatningLinie[] }> {
   let lodsejer = state.lodsejer[lodsejerId];
   let matrikler = Object.values(state.matrikler[lodsejerId] || {});

   // let allFeatures = getAllFeaturesForLodsejer(state, lodsejerId);

   let res: any[] = [];
   let erst: BNBOErstatningLinie[] = [];
   let behandledeMatrikler: BNBOMatrikel[] = [];
   for (let mat of matrikler) {
  
      // let allFeaturesSkaaretTilMatrikel = nonOverlappingGeo(allFeatures, true, [Turf.feature(mat.geometry)]);
      // for (let feat of allFeaturesSkaaretTilMatrikel) {
      //    console.log("feat props", feat.properties);
      // }

      if (behandledeMatrikler.find((a) => a.matrikelNummer === mat.matrikelNummer && a.ejerlavsKode === mat.ejerlavsKode)) {
         // matriklen overlapper flere BNBOer. Behandles anderledes anden gang.
         mat.duplikatMatrikelVedFlereBNBOerForSammeMatrikel = true;
      }
      let { r, e } = await udregnErstatningMatrikel2(state, lodsejerId, mat);
      res = [...res, ...r];
      erst = [...erst, ...e];
      behandledeMatrikler.push(mat);
   }

   return { res, erst };
}

function safeObjectValuesLookup(objectPossiblyNull: any): any[] {
   if (objectPossiblyNull) {
      return Object.values(objectPossiblyNull);
   } else {
      return [];
   };
}
function safeObjectKeysLookup(objectPossiblyNull: any): any[] {
   if (objectPossiblyNull) {
      return Object.keys(objectPossiblyNull);
   } else {
      return [];
   };
}

async function udregnErstatningMatrikel2(state: BNBOState, lodsejerId: string, matrikel: BNBOMatrikel): Promise<{ r: any[], e: BNBOErstatningLinie[] }> {
   let matrikelProps = { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNr: matrikel.matrikelNummer }
   let erst: BNBOErstatningLinie[] = [];
   let bnboMatrikelAreal = matrikel.arealBrutto - matrikel.arealNetto;
   let bnboMatrikelMarkAreal = 0;
   let bnboMatrikelRestAreal = 0;
   let jordpris = state.lodsejer[lodsejerId].sats || getDefaultSats();

   let duplikat = Boolean(matrikel.duplikatMatrikelVedFlereBNBOerForSammeMatrikel);

   let allFeatures = getAllFeaturesForLodsejer(state, lodsejerId);

   let bnboer:any = await PropertyInfoInterface.getBNBO(matrikel.geometry);
   let bnboerUdenOverlap;
   if (bnboer.features.length > 1) {
      bnboerUdenOverlap = Turf.union(bnboer, {properties: {type: BNBOOmrådeType.Restareal}});
      // For debugging purposes to visualize the bnbo union:
      // erst.push(calculateTotal({
      //    geometry: bnboerUdenOverlap?.geometry,
      //    areaType: BNBOOmrådeType.ServitutAreal,
      //    antal: Turf.area(bnboerUdenOverlap!),
      //    takst: 0,
      //    faktor: 0,
      //    procent: 0,
      //    note: "BNBO efter union",
      //    matrikel: { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer },
      //    // mark: { markBlok: mark.markBlok, markNr: mark.markNr, afgrøde: mark.afgrøde }
      // }));
   } else {
      bnboerUdenOverlap = bnboer;
   }
   if (bnboerUdenOverlap.type === "FeatureCollection") {
      bnboerUdenOverlap.features.forEach((f) => {f.properties.type = BNBOOmrådeType.Restareal});

      allFeatures.push(...bnboerUdenOverlap.features); 
   }
   else {
      bnboerUdenOverlap.properties.type = BNBOOmrådeType.Restareal;

      allFeatures.push(bnboerUdenOverlap); 
   }
   
   function ft(geoObject:any) {
      if (!geoObject.type) {
         throw new Error("Unexpected geoObject to be missing type");
      }
      switch(geoObject.type) {
         case "Feature":
            return Turf.feature(geoObject.geometry, geoObject.properties);
         case "FeatureCollection":
            return geoObject.features[0];
         default:
            return Turf.feature(geoObject);
            throw new Error("Unexpected geoObject type " + geoObject);
      }
   }

   let matrikelOverlapMedBNBONatur = await getNatureAreas(matrikel.geometryNetto, matrikelProps);

   allFeatures.push(...matrikelOverlapMedBNBONatur.map((a) => Turf.feature(a.geometry, a.properties)));

   let allFeaturesSkaaretTilMatrikel = nonOverlappingGeo(allFeatures, true, [Turf.feature(matrikel.geometry, matrikelProps)]);
   for (let feat of allFeaturesSkaaretTilMatrikel) {
      // console.log("feat props", feat.properties);
      let mark;
      if (feat.properties.markId) {
         mark = state.marker[lodsejerId][feat.properties.markId];
      }
      erst.push(roundAndCalculateTotal({
         geometry: feat.geometry,
         areaType: feat.properties.type,
         antal: feat.properties.arealNetto,
         takst: jordpris,
         faktor: faktorByOmrådeType(feat.properties.type),
         procent: getKommuneErstatningsProcent(),
         note: feat.properties.note,
         matrikel: { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer },
         mark: mark ? { markBlok: mark.markBlok, markNr: mark.markNr, afgrøde: mark.afgrøde } : undefined
      }));
   }
   
   return { r: [], e: erst };
}

async function getNatureAreas(geometry: any, props:any): Promise<any[]> {
   let dele: any[] = [];

   let resP3: any = await PropertyInfoInterface.getBeskyttetNatur(BNBOFunc.clone(geometry));
   for (let p3 of resP3.features) {
      let overlap = Turf.intersect(Turf.featureCollection([Turf.feature(geometry), p3]), { properties: { ...BNBOFunc.clone(p3.properties), ...props } });
      if (overlap) {

         // Beregn også overlap med matrikelbnboområderne.
         // Areal for overlap pr bnbo område pr matrikel pr §3/fredskov

         let res = {
            id: MapitUtils.getNewUuid(),
            type: BNBOOmrådeType.Par3Areal,
            geometry: overlap.geometry,
            arealBrutto: Turf.area(p3),
            arealNetto: Turf.area(overlap),
            note: `Overlap med §3 (${p3.properties.Natyp_navn})`,
            art: p3.properties.Natyp_navn
         }
         dele.push(res);

      }
   }

   let resFredskov = await PropertyInfoInterface.fetchFredskovWFSforPolygonFilterIntersectsPost(BNBOFunc.clone(geometry));
   for (let freds of resFredskov.features) {
      let overlap = Turf.intersect(Turf.featureCollection([Turf.feature(geometry), freds]), { properties: { ...BNBOFunc.clone(freds.properties), ...props } });
      if (overlap) {

         let res = {
            id: MapitUtils.getNewUuid(),
            type: BNBOOmrådeType.FredskovsAreal,
            geometry: Turf.feature(overlap.geometry),
            arealBrutto: Turf.area(freds),
            arealNetto: Turf.area(overlap),
            note: `Overlap fredskov`,
            art: freds.properties["mat:tematype"]
         }
         dele.push(res);

      }
   }

   return dele;
}

async function udregnErstatningMatrikel(state: BNBOState, lodsejerId: string, matrikel: BNBOMatrikel): Promise<{ r: any[], e: BNBOErstatningLinie[] }> {
   let matrikelProps = { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNr: matrikel.matrikelNummer }
   let erst: BNBOErstatningLinie[] = [];
   let bnboMatrikelAreal = matrikel.arealBrutto - matrikel.arealNetto;
   let bnboMatrikelMarkAreal = 0;
   let bnboMatrikelRestAreal = 0;
   let jordpris = state.lodsejer[lodsejerId].sats || getDefaultSats();

   let duplikat = Boolean(matrikel.duplikatMatrikelVedFlereBNBOerForSammeMatrikel);

   let allFeatures = getAllFeaturesForLodsejer(state, lodsejerId);

   let bnboer:any = await PropertyInfoInterface.getBNBO(matrikel.geometry);
   let bnboerUdenOverlap;
   if (bnboer.features.length > 1) {
      bnboerUdenOverlap = Turf.union(bnboer);
      // For debugging purposes to visualize the bnbo union:
      erst.push(roundAndCalculateTotal({
         geometry: bnboerUdenOverlap?.geometry,
         areaType: BNBOOmrådeType.ServitutAreal,
         antal: Turf.area(bnboerUdenOverlap!),
         takst: 0,
         faktor: 0,
         procent: 0,
         note: "BNBO efter union",
         matrikel: { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer },
         // mark: { markBlok: mark.markBlok, markNr: mark.markNr, afgrøde: mark.afgrøde }
      }));
   } else {
      bnboerUdenOverlap = bnboer;
   }

   allFeatures.push(...bnboerUdenOverlap.features);
   
   function ft(geoObject:any) {
      if (!geoObject.type) {
         throw new Error("Unexpected geoObject to be missing type");
      }
      switch(geoObject.type) {
         case "Feature":
            return Turf.feature(geoObject.geometry, geoObject.properties);
         case "FeatureCollection":
            return geoObject.features[0];
         default:
            return Turf.feature(geoObject);
            throw new Error("Unexpected geoObject type " + geoObject);
      }
   }

   let matrikelBNBOoverlap = Turf.intersect(Turf.featureCollection([ft(bnboerUdenOverlap), Turf.feature(matrikel.geometry)]));
   if (!matrikelBNBOoverlap) {
      throw new Error("Unexpected No intersection between BNBO and matrikel");
   }
   bnboMatrikelAreal = Turf.area(matrikelBNBOoverlap);
   let matrikelOverlapMedBNBONatur = await getNatureAreas(matrikel.geometryNetto);
   let matrikelMarkOverlapMedBNBONatur: any[] = []

   for (let markId of safeObjectKeysLookup(state.markDele[lodsejerId])) {
      let markDele = state.markDele[lodsejerId][markId];
      let mark = state.marker[lodsejerId][markId];
      let markMatrikelBNOOverlap = Turf.intersect(Turf.featureCollection([matrikelBNBOoverlap, Turf.feature(mark.geometry)]));
      if (!markMatrikelBNOOverlap) {
         continue;
      }
      // Overlap med mark og BNBO
      let areal = Turf.area(markMatrikelBNOOverlap);
      erst.push(roundAndCalculateTotal({
         geometry: markMatrikelBNOOverlap.geometry,
         areaType: BNBOOmrådeType.MarkServitutareal,
         antal: areal,
         takst: jordpris,
         faktor: faktorByOmrådeType(BNBOOmrådeType.MarkServitutareal),
         procent: getKommuneErstatningsProcent(),
         note: "BNBO overlap med omdriftsareal på matriklen",
         matrikel: { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer },
         mark: { markBlok: mark.markBlok, markNr: mark.markNr, afgrøde: mark.afgrøde }
      }))
      bnboMatrikelMarkAreal += areal;

      let _matrikelMarkOverlapMedBNBONatur = await getNatureAreas(markMatrikelBNOOverlap.geometry);
      _matrikelMarkOverlapMedBNBONatur.forEach((ov) => {
         erst.push(roundAndCalculateTotal({
            geometry: ov.geometry,
            areaType: ov.type,
            antal: ov.arealNetto,
            takst: jordpris,
            faktor: ov.type === BNBOOmrådeType.Par3Areal ? -1 : -0.5,
            procent: getKommuneErstatningsProcent(),
            note: `Omdriftsareal dækket af fradragsareal: ${ov.type} ${"" + ov.art}`,
            matrikel: { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer },
            mark: { markBlok: mark.markBlok, markNr: mark.markNr, afgrøde: mark.afgrøde }
         }))
      });
      matrikelMarkOverlapMedBNBONatur = [...matrikelMarkOverlapMedBNBONatur, ..._matrikelMarkOverlapMedBNBONatur];
   }

   try {
      let markServiturAreal = Turf.intersect(Turf.featureCollection([ft(bnboerUdenOverlap), Turf.feature(matrikel.geometry)]));
      if (!markServiturAreal) {
         throw new Error("Unexpected No intersection between BNBO and matrikel");
      }
   } catch (e) {
      console.log("Got error finding overlap between BNBO and matrikel", e);
   }

if (false) {
   // Find overlap med arealet
   let marker = safeObjectValuesLookup(state.marker[lodsejerId]);
   for (let mark of marker) {
      let md = safeGetDoubleDepthObject(state.markDele, lodsejerId, getMarkId(mark));
      for (let mdel of md) {

         if (!duplikat) {
            erst.push(roundAndCalculateTotal({
               geometry: mdel.geometry,
               areaType: BNBOOmrådeType.MarkServitutareal,
               antal: mdel.arealNetto,
               takst: jordpris,
               faktor: faktorByOmrådeType(BNBOOmrådeType.MarkServitutareal),
               procent: getKommuneErstatningsProcent(),
               note: "BNBO overlap med omdriftsareal på matriklen",
               matrikel: { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer },
               mark: { markBlok: mark.markBlok, markNr: mark.markNr, afgrøde: mark.afgrøde }
            }))
            bnboMatrikelMarkAreal += mdel.arealNetto;

            let _matrikelMarkOverlapMedBNBONatur = await getNatureAreas(mdel.geometry);
            _matrikelMarkOverlapMedBNBONatur.forEach((ov) => {
               erst.push(roundAndCalculateTotal({
                  geometry: ov.geometry,
                  areaType: ov.type,
                  antal: ov.arealNetto,
                  takst: jordpris,
                  faktor: ov.type === BNBOOmrådeType.Par3Areal ? -1 : -0.5,
                  procent: getKommuneErstatningsProcent(),
                  note: `Omdriftsareal dækket af fradragsareal: ${ov.type} ${"" + ov.art}`,
                  matrikel: { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer },
                  mark: { markBlok: mark.markBlok, markNr: mark.markNr, afgrøde: mark.afgrøde }
               }))
            });
            matrikelMarkOverlapMedBNBONatur = [...matrikelMarkOverlapMedBNBONatur, ..._matrikelMarkOverlapMedBNBONatur];
         }
      }
   }
}
   bnboMatrikelRestAreal = bnboMatrikelAreal - bnboMatrikelMarkAreal;
   erst.push(roundAndCalculateTotal({
      areaType: BNBOOmrådeType.Restareal,
      antal: bnboMatrikelRestAreal,
      takst: jordpris,
      faktor: faktorByOmrådeType(BNBOOmrådeType.Restareal),
      procent: getKommuneErstatningsProcent(),
      note: "Rest BNBO overlap med matriklen, som ikke er marker",
      matrikel: { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer }
   }))

   // beregn matrikel naturområder m2 pr naturtype
   let naturTypeSumMark: { [type: string]: number } = {};
   matrikelMarkOverlapMedBNBONatur.forEach((natur) => {
      let currentSum = naturTypeSumMark[natur.type] || 0;
      currentSum += natur.arealNetto;
      naturTypeSumMark[natur.type] = currentSum;
   })
   
   let naturTypeSumMatrikel: { [type: string]: number } = {};
   matrikelOverlapMedBNBONatur.forEach((natur) => {
      let currentSum = naturTypeSumMark[natur.type] || 0;
      currentSum += natur.arealNetto;
      naturTypeSumMatrikel[natur.type] = currentSum;
   });

   allFeatures.push(...matrikelOverlapMedBNBONatur.map((a) => Turf.feature(a.geometry)));

   Object.keys(naturTypeSumMatrikel).forEach((natur) => {   
      erst.push(roundAndCalculateTotal({
         areaType: BNBOOmrådeType.FradragsAreal,
         geometry: undefined,
         antal: naturTypeSumMatrikel[natur] - (naturTypeSumMark[natur] || 0),
         takst: jordpris,
         faktor: natur === BNBOOmrådeType.Par3Areal ? -0.5 : 0,
         procent: getKommuneErstatningsProcent(),
         note: `Andetareal dækket af fradragsareal: ${natur} (${formatAreal(naturTypeSumMark[natur])} fratrukket som dækker marker)`,
         matrikel: { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer },
      }))   });

   // matrikelOverlapMedBNBONatur.forEach((mo) => {
   //    dele.push(mo);
   //    erst.push(calculateTotal({
   //       areaType: mo.type,
   //       geometry: mo.geometry,
   //       antal: mo.arealNetto - naturTypeSumMark[mo.type],
   //       takst: jordpris,
   //       faktor: -1 * faktorByOmrådeType(mo.type),
   //       procent: getKommuneErstatningsProcent(),
   //       note: `andetareal dækket af fradragsareal: ${mo.type} ${"" + mo.art} (${formatAreal(naturTypeSumMark[mo.type])} fratrukket som dækker marker)`,
   //       matrikel: { ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer },
   //    }))
   // });

   // arronderingsarealer
   for (let markId of safeObjectKeysLookup(state.markDele[lodsejerId])) {
      let markDele = state.markDele[lodsejerId][markId];
      let mark = state.marker[lodsejerId][markId];
      for (let markDel of markDele) {
         if (markDel.type === BNBOOmrådeType.UlempeAreal) {
            let arronderingOverlapMedMatrikel = Turf.intersect(Turf.featureCollection([Turf.feature(matrikel.geometry), Turf.feature(markDel.geometry)]));
            if (!arronderingOverlapMedMatrikel) {
               continue;
            }
            let areal = Turf.area(arronderingOverlapMedMatrikel);
            erst.push(roundAndCalculateTotal({
               areaType: markDel.type,
               geometry: markDel.geometry,
               antal: areal,
               takst: jordpris,
               faktor: faktorByOmrådeType(markDel.type),
               procent: getKommuneErstatningsProcent(),
               note: `Arronderingsareal`,
               // matrikel: {ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer},
               mark: { markBlok: mark.markBlok, markNr: mark.markNr, afgrøde: mark.afgrøde }
            }))
            // Find ud af hvor meget af arealet der er dækket at natur
            let ulempeOverlapMedBNBONatur = await getNatureAreas(arronderingOverlapMedMatrikel.geometry);
            ulempeOverlapMedBNBONatur.forEach((mo) => {
               erst.push(roundAndCalculateTotal({
                  geometry: mo.geometry,
                  areaType: mo.type,
                  antal: mo.arealNetto,
                  takst: jordpris,
                  faktor: -1 * faktorByOmrådeType(mo.type),
                  procent: getKommuneErstatningsProcent(),
                  note: `Arronderingsareal dækket af fradragsareal: ${mo.type} ${"" + mo.art}`,
                  // matrikel: {ejerlavsKode: matrikel.ejerlavsKode, ejerlavsNavn: matrikel.ejerlavsNavn, matrikelNummer: matrikel.matrikelNummer},
                  mark: { markBlok: mark.markBlok, markNr: mark.markNr, afgrøde: mark.afgrøde }
               }))
            });
         }
      };
   }

   async function getNatureAreas(geometry: any): Promise<any[]> {
      let dele: any[] = [];

      let resP3: any = await PropertyInfoInterface.getBeskyttetNatur(BNBOFunc.clone(geometry));
      for (let p3 of resP3.features) {
         let overlap = Turf.intersect(Turf.featureCollection([Turf.feature(geometry), p3]), { properties: { ...BNBOFunc.clone(p3.properties), ...matrikelProps } });
         if (overlap) {

            // Beregn også overlap med matrikelbnboområderne.
            // Areal for overlap pr bnbo område pr matrikel pr §3/fredskov

            let res = {
               id: MapitUtils.getNewUuid(),
               type: BNBOOmrådeType.Par3Areal,
               geometry: overlap.geometry,
               arealBrutto: Turf.area(p3),
               arealNetto: Turf.area(overlap),
               note: `Overlap med §3 (${p3.properties.Natyp_navn})`,
               art: p3.properties.Natyp_navn
            }
            dele.push(res);

         }
      }

      let resFredskov = await PropertyInfoInterface.fetchFredskovWFSforPolygonFilterIntersectsPost(BNBOFunc.clone(geometry));
      for (let freds of resFredskov.features) {
         let overlap = Turf.intersect(Turf.featureCollection([Turf.feature(geometry), freds]), { properties: { ...BNBOFunc.clone(freds.properties), ...matrikelProps } });
         if (overlap) {

            let res = {
               id: MapitUtils.getNewUuid(),
               type: BNBOOmrådeType.FredskovsAreal,
               geometry: overlap.geometry,
               arealBrutto: Turf.area(freds),
               arealNetto: Turf.area(overlap),
               note: `Overlap fredskov`,
               art: freds.properties["mat:tematype"]
            }
            dele.push(res);

         }
      }

      return dele;
   }

   let allFeaturesSkaaretTilMatrikel = nonOverlappingGeo(allFeatures, true, [Turf.feature(matrikel.geometry)]);
   for (let feat of allFeaturesSkaaretTilMatrikel) {
      // console.log("feat props", feat.properties);
   }

   return { r: [], e: erst };
}


function sumErstatninger(erstatningsListe: { erstatning: number }[]) {
   return erstatningsListe.reduce((pre, cur) => pre + cur.erstatning, 0)
}

function udregnErstatningMark(state: BNBOState, lodsejerId, markId) {
   let mark = state.marker[lodsejerId][markId];
   let markDele: BNBODel[] = safeGetDoubleDepthObject(state.markDele, lodsejerId, markId) || [];

   let delTyper: BNBOOmrådeType[] = [...new Set(markDele.map((a) => a.type))]

   return delTyper.map((a) => {
      let res = {
         type: a,
         antal: udregnMarkArealByType(state, lodsejerId, markId, a),
         takst: getDefaultSats(),
         faktor: faktorByMarkDelType(a),
         procent: getKommuneErstatningsProcent(),
      }
      return {
         ...res,
         erstatning: udregnErstatningEnkel(res)
      }
   })
}

function udregnErstatningEnkel(markDel: { antal: number, takst: number, faktor: number, procent: number }) {
   return markDel.antal * markDel.takst * markDel.faktor * markDel.procent
}

function udregnMarkArealByType(state: BNBOState, lodsejerId, markId, markType: BNBOOmrådeType): number {
   return state.markDele[lodsejerId][markId].reduce((pre, cur) => cur.type == markType ? cur.arealNetto + pre : pre, 0)
}


function getKommuneErstatningsProcent() {
   return SettingsManager.getSystemSetting("bnbo.kommuneErstatningsProcent") / 100
}

function getDefaultSats() {
   return SettingsManager.getSystemSetting("bnbo.landbrugsArealPris.min") || 123
}

function faktorByMarkDelType(markType: BNBOOmrådeType) {
   switch (markType) {
      // ToDo: get from settings!!!!
      case BNBOOmrådeType.ServitutAreal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.omdriftsAreal")
      case BNBOOmrådeType.FradragsAreal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.andetAreal")
      case BNBOOmrådeType.UlempeAreal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.andetAreal")
      case BNBOOmrådeType.Restareal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.paragraf3Areal")

      default:
         throw new Error("Unexpected markDelType: " + markType)
         
   }
}

function faktorByOmrådeType(markType: BNBOOmrådeType) {
   switch (markType) {
      case BNBOOmrådeType.ServitutAreal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.omdriftsAreal")
      case BNBOOmrådeType.FradragsAreal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.fradragsAreal")
      case BNBOOmrådeType.UlempeAreal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.ulempeAreal")
      case BNBOOmrådeType.Restareal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.servitutAreal")
      case BNBOOmrådeType.FredskovsAreal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.fredskovsAreal")
      case BNBOOmrådeType.Par3Areal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.paragraf3Areal")
      // case BNBOOmrådeType.AndetAreal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.fredskovsAreal")
      case BNBOOmrådeType.MarkServitutareal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.omdriftsAreal")
      case BNBOOmrådeType.ServitutAreal: return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.omdriftsAreal")

      default:
         return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer.omdriftsAreal")
         // throw new Error("Unexpected delType: " + markType)
   }
}

function faktorFormat(markType: BNBOOmrådeType) {
   switch (markType) {
      case BNBOOmrådeType.ServitutAreal: return "Omdriftsareal"
      case BNBOOmrådeType.FradragsAreal: return "Fradrag"
      case BNBOOmrådeType.UlempeAreal: return "Ulempe"
      case BNBOOmrådeType.Restareal: return "Restservitutareal"
      case BNBOOmrådeType.FredskovsAreal: return "Fredskov"
      case BNBOOmrådeType.MarkServitutareal: return "Markservitut"
      case BNBOOmrådeType.Par3Areal: return "Paragraf 3"
      default:
         return "Undefined";
         // throw new Error("Unexpected markType: " + markType)
   }
}

function formatErstatningsBeregningMark(input: any[]) {
   const pris = Intl.NumberFormat("da-dk", {
      style: "currency",
      currency: "dkk"
   })
   const areal = Intl.NumberFormat("da-dk", {
      maximumFractionDigits: 0,
   })
   const fraction = Intl.NumberFormat("da-dk")

   return input.map((a) => ({
      type: a.type,
      erstatning_formatted: pris.format(a.erstatning),
      type_formatted: faktorFormat(a.type),
      antal_formatted: areal.format(a.antal) + " m²",
      takst_formatted: pris.format(a.takst),
      faktor_formatted: fraction.format(a.faktor),
      procent_formatted: (a.procent * 100).toFixed(0) + "%",
      note: a.note || ""
   }))
}

function formatAreal(areal?: number) {
   return (areal ?? 0).toLocaleString("da-dk", {
      maximumFractionDigits: 0
   })
}

function formatErstatningsBeregningEjendom(input: BNBOErstatningLinie[]): any[] {
   const pris = Intl.NumberFormat("da-dk", {
      style: "currency",
      currency: "dkk"
   })
   const areal = Intl.NumberFormat("da-dk", {
      maximumFractionDigits: 0,
   })
   const fraction = Intl.NumberFormat("da-dk")

   return input.map((a) => ({
      ...a,
      type: a.areaType,
      erstatning_formatted: pris.format(a.erstatning),
      type_formatted: faktorFormat(a.areaType),
      antal_formatted: areal.format(a.antal) + " m²",
      takst_formatted: pris.format(a.takst),
      faktor_formatted: a.isManualTakst ? "-" : fraction.format(a.faktor),
      procent_formatted: a.isManualTakst ? "-" : (a.procent * 100).toFixed(0) + "%",
      note: a.note || ""
   }))
}

function formatTotalErstatning(erstatning?: number) {
   return (erstatning || 0).toLocaleString("da-dk", {
      style: "currency",
      currency: "dkk"
   })
}

