import { Button, Card } from "react-bootstrap";
import { Localization } from "@viamap/viamap2-common";
import { MitDialog } from "./ComponentUtils";
import { ADModalBody, ADModalFooter, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassButton } from "./GlassButtons";

type Props = {
  showWindow: boolean;
  onFormClose: () => void;
  noOfStarted: number;
  noOfCompleted: number;
  stats: { [stat: string]: number };
};

export function ImportResults(props: Props) {
  if (props.showWindow) {
    let RenderStats =
      props.stats &&
      Object.keys(props.stats).map((key, idx) => {
        const localizedKey = Localization.getText(key);
        return (
          <tr key={"stats" + idx}>
            <td>{localizedKey}</td>
            <td style={{ textAlign: "right" }}>{props.stats[key]}</td>
          </tr>
        );
      });
      
    if (!props.showWindow) {
      return <></>
    }

    return (
      <AdvancedDragModal 
        title={Localization.getText("Import Status")}
      >
        <ADModalBody>
        <table cellPadding={5}>
              <tbody style={{ textAlign: "left", color: "darkslategray" }}>
                <tr>
                  <td>{Localization.getText("Started")}</td>
                  <td>{props.noOfStarted}</td>
                </tr>
                <tr>
                  <td colSpan={2} style={{ height: "5pt" }}>
                    <hr />
                  </td>
                </tr>
                {RenderStats}
                <tr>
                  <td colSpan={2}>
                    <hr />
                  </td>
                </tr>
                <tr
                  style={{
                    background:
                      props.noOfStarted === props.noOfCompleted
                        ? "lightblue"
                        : "",
                  }}
                >
                  <td>{Localization.getText("Completed")}</td>
                  <td>{props.noOfCompleted}</td>
                </tr>
              </tbody>
            </table>
        </ADModalBody>
        <ADModalFooter>
        <GlassButton
              onClick={() => props.onFormClose()}
            >
              {Localization.getText("Close")}
            </GlassButton>
        </ADModalFooter>
      </AdvancedDragModal>
    );
  } else {
    return null;
  }
}
