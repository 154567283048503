import { Localization } from "@viamap/viamap2-common"

export function operatorFromDivision(division) {
   const div = division
   if (div.value || (div.from === null && div.to === null))
     return {op:"", text: div.value || <div className="NoValue">{Localization.getText("No value")}</div>}
   if (div.from === null) 
     return {op: "<=", text: div.to}
   if (div.to === null) 
     return {op: ">", text:div.from}
   return {op: "<=", text:div.to}
}
 