import { SettingsFixedPriorities } from "@viamap/viamap2-common";
import { GroupInfo, SingleSetting } from "src/components/SettingsEditor";
import { Feature } from "src/states/ApplicationStateFeatures";

const settingsGroups: GroupInfo = [
  {
    key: "bnbo",
    da: "BNBO",
    en: "BNBO"
  }]

const settings: SingleSetting[] = [
  {
    access: Feature.BNBOTool,
    group: "bnbo",
    key: "bnbo.kommuneNavn",
    default: "",
    type: "String",
    da: "Kommunenavn",
    en: "Municipality name",
  },
  {
    access: Feature.BNBOTool,
    group: "bnbo",
    key: "bnbo.kommuneErstatningsProcent",
    default: 60,
    min: 0,
    max: 100,
    type: "Integer",
    da: "Erstatningsprocent",
    en: "Erstatnings Procent",
    sufix: "%",
  },
  {
    access: Feature.BNBOTool,
    group: "bnbo",
    key: "bnbo.erstatningsFaktorer.omdriftsAreal",
    type: "Float",
    min: 0,
    default: 1,
    max: 1,
    da: "Faktor omdriftsareal",
    en: "Faktor Omdrifts Areal",
  },
  {
    access: Feature.BNBOTool,
    group: "bnbo",
    key: "bnbo.erstatningsFaktorer.servitutAreal",
    type: "Float",
    min: 0,
    default: 0.5,
    max: 1,
    da: "Faktor servitutareal (ikke omdrift)",
    en: "Faktor Servitut Areal",
  },
  {
    access: Feature.BNBOTool,
    group: "bnbo",
    key: "bnbo.erstatningsFaktorer.ulempeAreal",
    type: "Float",
    min: 0,
    default: 0.5,
    max: 1,
    da: "Faktor ulempeareal",
    en: "Faktor Ulempe Areal",
  },
  {
    access: Feature.BNBOTool,
    group: "bnbo",
    key: "bnbo.erstatningsFaktorer.paragraf3Areal",
    type: "Float",
    min: 0,
    default: 0,
    max: 1,
    da: "Faktor §3-areal",
    en: "Faktor Paragraf-3 Areal",
  },
  {
    access: Feature.BNBOTool,
    group: "bnbo",
    key: "bnbo.erstatningsFaktorer.fredskovsAreal",
    type: "Float",
    min: 0,
    default: 0.5,
    max: 1,
    da: "Faktor fredskovsareal",
    en: "Faktor Fredskovsareal",
  },
  {
    access: Feature.BNBOTool,
    group: "bnbo",
    key: "bnbo.erstatningsFaktorer.fradragsAreal",
    type: "Float",
    min: 0,
    default: 0.0,
    max: 1,
    da: "Faktor fradragsareal",
    en: "Faktor Fradrags Areal",
  },
  {
    access: Feature.BNBOTool,
    group: "bnbo",
    key: "bnbo.landbrugsArealPris.min",
    default: 22,
    min: 0,
    max: 100,
    type: "Integer",
    da: "Landbrugsareal Pris (Min)",
    en: "Landbrugsareal Pris (Min)",
    sufix: "dkk",
  },
  {
    access: Feature.BNBOTool,
    group: "bnbo",
    key: "bnbo.landbrugsArealPris.max",
    default: 24,
    min: 0,
    max: 100,
    type: "Integer",
    da: "Landbrugsareal Pris (Maks)",
    en: "Landbrugsareal Pris (Max)",
    sufix: "dkk",
  }
]

export const BNBOConst = {
  settingsFixedPriorities: 70 as SettingsFixedPriorities,
  settingsGroups: settingsGroups,
  settings: settings
}