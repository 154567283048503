
import {DemographyReport, DemographyReportMetaData} from './DemographyReport';
import { MitBody, MitDialog, MitHeader } from './ComponentUtils';

import {Localization} from "@viamap/viamap2-common";
import { SheetFunc } from '../managers/SheetFunc';
import { ProtectedFeature } from './ProtectedFeature';
import { Feature } from 'src/states/ApplicationState';
import { ExportData } from 'src/managers/ExportData';
import { GlassButton } from './GlassButtons';
import { ADModalBody, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { ViewButtonIcon } from 'src/propertyInfoTemplates/PropertyComponents';
import { BsDownload } from 'react-icons/bs';
import { FiPrinter } from 'react-icons/fi';
// import '../css/mit-controls.css';

type Props = {
    data:any;
    metaData:DemographyReportMetaData;
    callbackOnDismiss: ()=> void; 
    showWindow?:number;
    languageCode:string;
};

export function ReportResultDialog(props:Props) {
  const languageCode = ['da','en'].includes(props.languageCode) ? props.languageCode : "da";

  function handleDismiss() {
//    alert ("got dismiss 2");
    props.callbackOnDismiss();
  }

  function onClickDownload() {
    let ws = SheetFunc.create360NDataReport(props.metaData, props.data);
    let blob = SheetFunc.createBlob(ws);              
    let fileName = Localization.getText("FileName:Report") +".xlsx";
    ExportData.downloadBlob(fileName, blob);
  }

      let downloadButton = (
        <ViewButtonIcon
            onClick={()=> onClickDownload()}
            title={Localization.getText("Download")}
        >
          <BsDownload />
        </ViewButtonIcon>
      );
      let printButton = (
        <ViewButtonIcon
          onClick={()=>  {   print()}}
          title={Localization.getText("Print")}
        >
          <FiPrinter />
        </ViewButtonIcon>
      );

      // When this window is shown printing should not contain background map too
      let element:HTMLElement|null;
      element = document.getElementById("mit-mainwindow-map");
      if (props.showWindow) {
        element && element.classList.add("no-print");
      } else {
        element && element.classList.remove("no-print");
      }

      let contents = props.showWindow ? (
        <AdvancedDragModal onClose={handleDismiss} 
          title={Localization.getTextSpecificLanguage(languageCode,"Demography Report")}
          PosDefault={{width:"1200px", height: "calc(100% - 50px)", top: "50px", left:"20px"}}
          saveKey="DemoGraphy"
          variant={"NSWhite"}
          subHeaderContent={
            <>
            {downloadButton}
            <ProtectedFeature feature={Feature.PrintPropertyInfo} contentsIfNoAccess={<></>}>
              {printButton}
            </ProtectedFeature>
            </>
          }
        >
          <ADModalBody>
              <DemographyReport
                showWindow={true}
                data={props.data}
                metaData={props.metaData}
                callback={() => handleDismiss()}
              />
          </ADModalBody>
        </AdvancedDragModal>
      ) : null;

      return contents;

      // return (

      //   // <MyWindowPortal>
      //     {contents}
      //   // </MyWindowPortal>
      // );
  }
