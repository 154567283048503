import { useEffect, useRef, useState } from "react";
import { ErrorMessageModal, InfoMessageModal, LoadingScreen } from "./AppMessagesComponents";


export function AppMsgManager() {
   let [state, setState] = useState<MsgState>({
      info: undefined,
      error: undefined,
      progres: undefined,
   })

   let appMsgRef = useRef<HTMLDivElement>(null)


   useEffect(() => {
      if (appMsgRef.current) {
         appMsgRef.current.addEventListener("AppMessage", (e) => {
            console.log(e)
            if (validEvent(e)) {
               let dt = e.detail
               setTimeout(() => {
                  if (["info","error"].includes(dt.type)) {
                     setState((a) => ({...a, [dt.type]:dt.msg}))
                     return
                  }
                  setState((a) => ({...a, progres:{msg: dt.msg, pct: dt.pct}}))
               },0)
            }
         })
      }
   },[])


   return (
      <div id="appMsg" ref={appMsgRef} style={{position:"absolute", inset:"0px", zIndex:2000, pointerEvents: "none"}} >
         <InfoMessageModal message={state.info} onDismiss={(e) => setState((a) => ({...a, info:undefined}))} />
         <ErrorMessageModal message={state.error} onDismiss={(e) => setState((a) => ({...a, error:undefined}))} />
         <LoadingScreen percentComplete={state.progres?.pct} message={state.progres?.msg} />
      </div>
   )


}


type MsgState = {
   info?: string,
   error?: string,
   progres?: {msg?:string, pct?: number | ""}
}

function validEvent(e: any):e is ValidEvent {
   if (!("detail" in e)) return false
   if (!("msg" in e.detail)) return false 
   if (!("type" in e.detail)) return false
   return true
}

type ValidEvent = {
   detail: {
      type: string,
      msg?: string,
      pct?: "" | number,
   }
}