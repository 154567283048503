import { autoUpdate, FloatingPortal, offset, useDismiss, useFloating, useHover, useInteractions, useMergeRefs } from "@floating-ui/react"
import { useState, useId, useRef, useEffect, forwardRef, useContext } from "react"
import { BiMinus, BiCheck } from "react-icons/bi"
import { ApplicationStateContext } from "src/states/ApplicationState"
import { Feature } from "src/states/ApplicationStateFeatures"





export function LargeIconBtn({children, onClick}) {
    return (
        <button onClick={onClick} className="LargeIconBtn">
            {children}
        </button>
    )
}

export const SmallIconBtn = forwardRef<HTMLButtonElement, {children, onClick?, className?}>(function SmallIconBtn(props: {children, onClick?, className?}, ref) {
    return (
        <button ref={ref} onClick={props.onClick} className={"layerBtn" + (props.className ? ` ${props.className}` :"")}>
            {props.children}
        </button>
    )
})

export function PaddedContainer(props:{children, style?:any}) {
    return (
        <div className="PaddedContainer" style={props.style}>
            {props.children}
        </div>
    )
}

export function VLine({color}:{color?}) {
    return (
        <div className="VLine" style={color ? {borderBottomColor: color}: {}}></div>
    )
}


type MitCheckBoxProps = Omit<React.DetailedHTMLProps<React.InputHTMLAttributes<HTMLInputElement>, HTMLInputElement>,"onClick"> & {
    children?: React.ReactNode,
    checked?: boolean,
    indeterminate?: boolean,
    className?: string,
    onClick?: (evt:React.MouseEvent<HTMLInputElement, MouseEvent>, checked:boolean) => any,
 } 
export function GlassCheckbox({children, checked, indeterminate, onClick, style, className, ...props}:MitCheckBoxProps) {
    const [checkedS, setCheckedS] = useState<boolean>(!!checked)
    const [indeterminateS, setIndeterminateS] = useState<boolean>(!!indeterminate)
    const id = useId()
    const ref = useRef<HTMLInputElement>(null)

    useEffect(() => {
       setCheckedS(!!checked || false)
       setIndeterminateS(!!indeterminate || false)
       if (ref.current) {
          ref.current.indeterminate = indeterminateS ? true : false
       }

    },[checked, indeterminate])
    
    function handleOnClick(evt: React.MouseEvent<HTMLInputElement, MouseEvent>) {
       setCheckedS(!checkedS)
        setIndeterminateS(false)
       onClick?.(evt, !checkedS)
       return false
    }

    function handleOnChange(evt) {
       return
    }

    return (
       <div className={"GlassCheckbox" + (className ? ` ${className}` : "")} style={{...style}}>
          <input ref={ref} className={"GlassCheckbox_Input"} disabled={props.disabled} onChange={handleOnChange} onClick={handleOnClick} checked={!!checkedS} {...props} type='checkbox' id={props.id ? props.id : id}></input>
          <span style={{pointerEvents:"none", touchAction:"none"}} className={"GlassCheckbox_Mark" + ((indeterminateS || checkedS) ? " checked" : "")}>
             {indeterminateS ? <BiMinus /> : (checkedS ? <BiCheck /> : null)}
          </span>
          {children ? <label className={"GlassCheckbox_Label"} htmlFor={props.id ? props.id : id} >{children}</label> : null}
       </div>
    )
 }

 export function GlassInfo({children, ...props}) {
    return (
        <div className="GlassInfo" {...props}>
            <span>
            {children}
            </span>
        </div>
    )
 }

// MARK: Move to Legend 
 export function LegendGrid({children}) {
    return (
        <div className="LegendGrid" >
            {children}
        </div>
    )
 }

 export function GlassHorizontalScroll({children}) {
    return (
        <div className="ssHorizontalScroll" >
            {children}
        </div>
    )
 }

 export function MenuButton({children, toolTip, activeArrow = false, active = false, ...props}) {
    const {hasAccessToFeature} = useContext(ApplicationStateContext)
    const [tooltipOpen, setTooltipOpen] = useState(false);
    const {refs, floatingStyles, context: tooltipContext} = useFloating({
        whileElementsMounted: autoUpdate,
        placement: "right",
        open: tooltipOpen,
        middleware: [offset(10)],
        onOpenChange: setTooltipOpen,
      });
      const {
        getReferenceProps: getTooltipReferenceProps,
        getFloatingProps: getTooltipFloatingProps
      } =  useInteractions([useHover(tooltipContext), useDismiss(tooltipContext)]);
    //   {getReferenceProps: () => ({}), getFloatingProps: () => ({})}
    
      

      const {refs:refs2, floatingStyles:floatingStyles2, context: tooltipContext2} = useFloating({
        placement: "right",
        middleware: [offset(14)],
      });
    
    return (
        <>
        <button ref={(a) => {refs.setReference(a); refs2.setReference(a)}} {...props} onClick={props.onClick} className={"MenuButton " + props.className} type-title={toolTip} >
            {children}
        </button>
        {(tooltipOpen && !hasAccessToFeature(Feature.ThemeNewSec)) &&
        <FloatingPortal>
        <div ref={refs.setFloating} className="GlassToolTip Dark" style={floatingStyles} {...getTooltipFloatingProps()} >
            {toolTip || "ThisIsToolTIp"}
        </div>
        </ FloatingPortal>
        }
        {(active && activeArrow) &&
            <FloatingPortal>
            <div ref={refs2.setFloating} className="GlassMenuArrow" style={floatingStyles2} {...getTooltipFloatingProps()} >
            </div>
            </ FloatingPortal>}
        </>
    )
 }

 export function ExtendedMenuButton({children, toolTip, ...props}) {
    const {hasAccessToFeature} = useContext(ApplicationStateContext)

    const [tooltipOpen, setTooltipOpen] = useState(false);
    const {refs, floatingStyles, context: tooltipContext} = useFloating({
        whileElementsMounted: autoUpdate,
        placement: hasAccessToFeature(Feature.ThemeNewSec) ? "left" : "right",
        open: tooltipOpen,
        middleware: [offset(10)],
        onOpenChange: setTooltipOpen,
      });
      const {
        getReferenceProps: getTooltipReferenceProps,
        getFloatingProps: getTooltipFloatingProps
      } = 
        useInteractions([useHover(tooltipContext), useDismiss(tooltipContext)]);
    //   {getReferenceProps: () => ({}), getFloatingProps: () => ({})}
    

    return (
        <>
        <button ref={refs.setReference} {...props} onClick={props.onClick} className={"ExtendedMenuButton " + props.className} type-title={toolTip} >
            {children}
        </button>
        {(tooltipOpen) &&
        <FloatingPortal>
        <div ref={refs.setFloating} className="GlassToolTip" style={floatingStyles} {...getTooltipFloatingProps()} >
            {toolTip || "ThisIsToolTIp"}
        </div>
        </ FloatingPortal>
        }
        </>
    )
 }


export function GlassCard(props:{title, children, onClick?, variant?, style?}, ) {
    return (
        <div className={"GlassCard "+props.variant} style={props.style} >
            <div className="head" onClick={props.onClick} >
                {props.title}
            </div>
            <div className="body" >
                {props.children}
            </div>
        </div>
    )
}

export function GlassStack(props: {children}) {
    return (<div className="GlassStack">
        {props.children}
    </div>)
}

export function GlassList(props:{title, children, onClick?, variant?, style?}, ) {
    return (
        <div className={"GlassList "+props.variant} style={props.style} >
            <div className="head" onClick={props.onClick} >
                {props.title}
            </div>
            <div className="body" >
                {props.children}
            </div>
        </div>
    )
}

