import { Utils } from "@viamap/viamap2-common";
import { AppMode, DeviceScreenSize, UserRole2Features, FeaturesAccessibleByScreenSize, FeaturesAccessibleByMode, FeaturesAccessibleByLicenseType } from "./ApplicationStateFeatureMapping";
import { Feature as ASFeature, FeatureType as ASFeatureType} from './ApplicationStateFeatures';
import { licenseVariantToRoles } from "./ApplicationStateVariantMapping";
import { ViamapLicense, SettingsManager } from "@viamap/viamap2-common";
import { createContext } from "react";

export enum AppCommandType { 
   GoToCadaster="GoToCadaster",
   GoToAddress="GoToAddress",
   Signup="Signup",
   GrantLicense="GrantLicense"
}

export enum DeviceType {
   mouseDevice="MouseDevice",
   touchDevice="TouchDevice"
}

export interface AppCommandSignup {
   type: AppCommandType.Signup;
   payload: {languageCode:string}
}
export interface AppCommandGrantLicense {
   type: AppCommandType.GrantLicense;
   payload: {email:string, product:string, variant:string}
}
export type AppCommand = AppCommandSignup | AppCommandGrantLicense

export const Feature = ASFeature;
export type FeatureType = ASFeatureType ; 

export interface ApplicationState {
   productName: string;
   commandQueue:AppCommand[];
   deviceType: DeviceType;
   deviceScreenSize: DeviceScreenSize;
   appMode: AppMode;
   userRole2Features: any; // mapping
   licenseVariant2Roles: any; // mapping
   features: string[]; // Master list of all features in the application
   availableRoles: string[]; // The current user's roles
   availableFeatures: string[]; // the current user's accessible features. Depends on user roles and license productvariant.
}

export function initialApplicationState(): ApplicationState {

   return {
      productName: "",
      commandQueue: [],
      deviceType: window.matchMedia("(pointer: fine)") ? DeviceType.mouseDevice : DeviceType.touchDevice,
      appMode: AppMode.Normal,
      deviceScreenSize: DeviceScreenSize.Normal,
      userRole2Features: UserRole2Features,
      licenseVariant2Roles: licenseVariantToRoles,
      features: Object.keys(ASFeature), // Features are imported from an enum. This is to allow compiletime validation of use of existing features only.
      availableRoles: [],
      availableFeatures: []
   };
}

function removeFeature(features:FeatureType[], removeFt:FeatureType):FeatureType[] {
   return features.filter((ft,idx) => {
       return ft !== removeFt;
   });
}

function addFeature(features:FeatureType[], addFt:FeatureType):FeatureType[] {
   // test for undefined to ensure that '0' values are also matched
   if (features.find((val) => val === addFt) === undefined) {
       features.push(addFt);
   }
   return features;
}

export function hasAccessToFeature(feature: FeatureType, applicationState:ApplicationState): boolean {
   let hasAccess = (applicationState.availableFeatures || []).includes(feature.toString());
   if (!hasAccess) {
      if (!applicationState.features.includes(feature.toString())) {
         throw Utils.createErrorEventObject("Test for access to unknown feature: "+feature.toString())
      }
   }
   return hasAccess;
}

export enum ApplicationStateActionType {
   EnqueueAppCommand,
   DequeueAppCommand,
   SetAppMode,
   UpdateAvailableFeatures,
   AddAvailableFeature,
   RemoveAvailableFeature,
   SetDeviceScreenSize,
}

export interface EnqueueAppCommand {
   type: ApplicationStateActionType.EnqueueAppCommand;
   payload: { command: AppCommand };
}
export interface DequeueAppCommand {
   type: ApplicationStateActionType.DequeueAppCommand;
}
export interface SetAppMode {
   type: ApplicationStateActionType.SetAppMode;
   payload: { appMode: AppMode }
}

export interface UpdateAvailableFeatures {
   type: ApplicationStateActionType.UpdateAvailableFeatures;
   payload: { userRolesFromSession?:string[], license?:ViamapLicense }
}
export interface AddAvailableFeature {
   type: ApplicationStateActionType.AddAvailableFeature;
   payload: { feature:FeatureType }
}
export interface RemoveAvailableFeature {
   type: ApplicationStateActionType.RemoveAvailableFeature;
   payload: { feature:FeatureType }
}

export interface SetDeviceScreenSize {
   type: ApplicationStateActionType.SetDeviceScreenSize;
   payload: { deviceScreenSize: DeviceScreenSize }
}

// quick functions
export const actionEnqueueAppCommand = (command: AppCommand): EnqueueAppCommand => ({
   type: ApplicationStateActionType.EnqueueAppCommand,
   payload: { command }
});
export const actionDequeueAppCommand = (): DequeueAppCommand => ({
   type: ApplicationStateActionType.DequeueAppCommand
});
export const actionSetAppMode = (appMode: AppMode): SetAppMode => ({
   type: ApplicationStateActionType.SetAppMode,
   payload: { appMode }
});
export const actionUpdateAvailableFeatures = (userRolesFromSession?:string[], license?:ViamapLicense): UpdateAvailableFeatures => ({
   type: ApplicationStateActionType.UpdateAvailableFeatures,
   payload: { userRolesFromSession, license }
});
export const actionAddAvailableFeature = (feature: FeatureType): AddAvailableFeature => ({
   type: ApplicationStateActionType.AddAvailableFeature,
   payload: { feature }
});
export const actionRemoveAvailableFeature = (feature: FeatureType): RemoveAvailableFeature => ({
   type: ApplicationStateActionType.RemoveAvailableFeature,
   payload: { feature }
});
export const actionSetDeviceScreenSize = (deviceScreenSize: DeviceScreenSize):SetDeviceScreenSize => ({
   type: ApplicationStateActionType.SetDeviceScreenSize,
   payload: { deviceScreenSize }
})

export type ApplicationStateActionsTransActional = EnqueueAppCommand | DequeueAppCommand;
export type ApplicationStateActionsNonTransActional = EnqueueAppCommand | SetAppMode | SetDeviceScreenSize
   | UpdateAvailableFeatures | AddAvailableFeature | RemoveAvailableFeature;
export type ApplicationStateActions = ApplicationStateActionsTransActional | ApplicationStateActionsNonTransActional;

export const ApplicationStateContext = createContext<{
   state: ApplicationState,
   dispatch: React.Dispatch<ApplicationStateActions>,
   hasAccessToFeature: (feature: FeatureType) => boolean,
}>({
   state: initialApplicationState(),
   dispatch: () => undefined,
   hasAccessToFeature: (feature: FeatureType) => {return false}
});

export function transactionalApplicationStateReducer(action: ApplicationStateActionsTransActional, dispatch: any) {

   switch (action.type) {

      default:
         // proceed directly to non-transational reducer for other actions
         dispatch(action);
   }
}

export function ApplicationStateReducer(state: ApplicationState, action: ApplicationStateActions): ApplicationState {

   switch (action.type) {

      case ApplicationStateActionType.EnqueueAppCommand: {
         return { ...state, commandQueue: [...state.commandQueue, action.payload.command ] };
         break;
      }
      case ApplicationStateActionType.DequeueAppCommand: {
         return { ...state, commandQueue: state.commandQueue.slice(1) };
         break;
      }
      case ApplicationStateActionType.SetAppMode: {
         let availableFeatures = calculateFeatures({ ...state, appMode: action.payload.appMode }, state.availableRoles);
         return { ...state, appMode: action.payload.appMode, availableFeatures:availableFeatures };
         break;
      }
      case ApplicationStateActionType.UpdateAvailableFeatures: {
         let availableRoles = calculateRoles(state, action.payload.userRolesFromSession, action.payload.license);
         let availableFeatures = calculateFeatures(state, availableRoles, action.payload.license);
         return { ...state, availableRoles, availableFeatures }
      }
      case ApplicationStateActionType.AddAvailableFeature: {
         return { ...state, availableFeatures:[...state.availableFeatures, action.payload.feature.toString()]}
      }
      case ApplicationStateActionType.RemoveAvailableFeature: {
         return { ...state, availableFeatures:state.availableFeatures.filter((ftr) => { return ftr != action.payload.feature.toString()})}
      }
      case ApplicationStateActionType.SetDeviceScreenSize: {
         if (action.payload.deviceScreenSize !== state.deviceScreenSize) {
            return { ...state, deviceScreenSize: action.payload.deviceScreenSize}
         }
         return state
      }
      default:
         throw new Error("Unknown action " + (action as ApplicationStateActions).type);
   }
}

function calculateRoles(applicationState:ApplicationState, userRolesFromSession?:string[], license?:ViamapLicense):string[] {
   let userRoles: string[] = ["MINIMAL_FEATURES_FOR_ANY_USER"];
   if (userRolesFromSession) {
      userRoles = [...userRoles, ...userRolesFromSession];
   }
   if (license) {
      if(license.productVariant) {
      if (applicationState.licenseVariant2Roles[license.productVariant]) {
         userRoles = [...userRoles, ...applicationState.licenseVariant2Roles[license.productVariant]];
      }
   }
      if (license.roles && license.roles.length > 0) {
         userRoles = [...userRoles, ...license.roles];
      }
   }
   return userRoles;
}

function calculateFeatures(applicationState: ApplicationState, userRoles: string[], license?: ViamapLicense): string[] {
   // Construct feature set from role/groups memberships

   let featureSet: FeatureType[] = [];

   // Collect all 'allows' and 'denys' before evaluating to ensure correct result.
   let featuresToAdd: FeatureType[] = [];
   let featuresToRemove: FeatureType[] = [];

   // A: Allow/Deny features by user Role

   // phase 1: all allows
   userRoles && userRoles.forEach((role, idx) => {
      let featuresforgroup = applicationState.userRole2Features[role];
      if (featuresforgroup) {
         featuresforgroup.allow.forEach((ft) => {
            featuresToAdd = addFeature(featuresToAdd, ft);
         });
      }
   });
   // phase 2: all denys
   userRoles && userRoles.forEach((role, idx) => {
      let featuresforgroup = applicationState.userRole2Features[role];
      if (featuresforgroup) {
         // remove any items in deny list
         featuresforgroup.deny && featuresforgroup.deny.forEach(ft => {
            featuresToRemove = addFeature(featuresToRemove, ft);
         });
      }
   });

   // B: Allow Deny by Application Mode
   {
      let appMode = applicationState.appMode;
      let AllowDeny = FeaturesAccessibleByMode[appMode];
      if (AllowDeny) {
         AllowDeny.allow && AllowDeny.allow.forEach((ft) => {
            featuresToAdd = addFeature(featuresToAdd, ft);
         });
         AllowDeny.deny && AllowDeny.deny.forEach(ft => {
            featuresToRemove = addFeature(featuresToRemove, ft);
         });
      }
   }

   // C: Allow/Deny by DeviceScreenSize
   {
      let size = applicationState.deviceScreenSize;
      let AllowDeny = FeaturesAccessibleByScreenSize[size];
      if (AllowDeny) {
         AllowDeny.allow && AllowDeny.allow.forEach((ft) => {
            featuresToAdd = addFeature(featuresToAdd, ft);
         });
         AllowDeny.deny && AllowDeny.deny.forEach(ft => {
            featuresToRemove = addFeature(featuresToRemove, ft);
         });
      }
   }

   // D: Allow/Deny by LicenseType
   let checkByLicense = SettingsManager.getSystemSetting("enforceLicenseTypeFeatureRestrictions", true);
   if (checkByLicense && license) {
      let AllowDeny = FeaturesAccessibleByLicenseType[license.licenseType];
      if (AllowDeny) {
         AllowDeny.allow && AllowDeny.allow.forEach((ft) => {
            featuresToAdd = addFeature(featuresToAdd, ft);
         });
         AllowDeny.deny && AllowDeny.deny.forEach(ft => {
            featuresToRemove = addFeature(featuresToRemove, ft);
         });
      }
   }

   featureSet = featuresToAdd;
   featuresToRemove.forEach(ft => {
      featureSet = removeFeature(featureSet, ft);
   });

   // remove any duplicates
   let availableFeatures = [...new Set(featureSet)];
   return availableFeatures as string[];
}

