import { createRef, useContext, useEffect, useRef, useState } from "react";
import { FiLayers} from 'react-icons/fi';
import { Localization, SessionContext, SettingsManager } from "@viamap/viamap2-common";
import { GroupedLayerControl } from "./GroupedLayerControl";
import LayerList from "./LayerList";
import { MapFacadeAbstract, MitMap } from "src/managers/MapFacade";
import { RightPaneContext, RightPaneModus } from "src/states/RightPaneState";
import { FeatureLayer } from "src/managers/WmsLayerFunc";
import { ProtectedFeature } from "./ProtectedFeature";
import { Feature } from "src/states/ApplicationStateFeatures";
import { MapitStateActionType, MapitStateContext, actionAddDataLayer, actionClosePopups, actionRemoveDataLayer, actionRemoveFeatureLayerHistoryEntry, actionSelectBackgroundLayer, actionSetDataLayerVisibility, actionSetFeatureLayerVisibility, actionSetLayerHierarchy, actionSetSingleFeatureLayerVisibility, actionStyleFeature, actionUpdateNameInLayerHierarchy } from "src/states/MapitState";
import { LayerHierarchy, LayerInfo, LayerVariabilityType, ViewerState } from "src/common/managers/Types";
import { ApplicationStateContext } from "src/states/ApplicationState";
import { LayerStylingContainer } from "./LayerStyling";
import { GroupStyling } from "./GroupStyling";
import {GenerateGeom} from '../managers/GenerateGeom';
import { AWSAPIGatewayInterface } from "src/managers/AWSAPIGatewayInterface";
import ReactDOM from "react-dom";
import { FeatureLayerEditor } from "./FeatureLayerEditor";
import { ImportResultDetails } from "./ImportResultDetails";
import { setState } from "src/states/ProjectState";
import { LayerChangeAction } from "./MapScreen";
import { Persistence } from "src/managers/Persistence";

type LayerSideBarProps<T> = {
  show: boolean;
}

export function LayerContainer(props:LayerSideBarProps<any>) {
  let sideBarRef = useRef<any>();
  let [pinOpen, setPinOpen] = useState(false);
  let [overflow, setOverflow] = useState(false);
  
  const [layerToStyle, setLayerToStyle] = useState<number | null>(null);
  const [hierarchyToStyle, setHierarchyToStyle] = useState<LayerHierarchy | null>(null);
  const [reopenLayer, setReopenLayer] = useState<number | null>(null);
  const [openFeaturLayer, setOpenFeatureLayers] = useState<string[]>([]);
  const {state: RightPaneState} = useContext(RightPaneContext);
  const {state:mapitState, dispatch:mapitStateDispatch} = useContext(MapitStateContext);
  const {hasAccessToFeature} = useContext(ApplicationStateContext)
  const {state: sessionState} = useContext(SessionContext)
  
  useEffect(() => {
    let RSize = new ResizeObserver((entries) => {
      let overflow = false
      for (const entry of entries) { 
        let x = entry.target
        if (x.scrollHeight > x.clientHeight) {
          overflow = true
        }
      }
      setOverflow(overflow)
    })
    
    let DAelement = document.querySelector(".DaLayers .card-body")
    if (DAelement) {
      RSize.observe(DAelement)
    }
    let BGelement = document.querySelector(".BgLayers .Body")
    if (BGelement) {
      RSize.observe(BGelement)
    }
    
    return () => RSize.disconnect()
  },[])
  
  useEffect(() => {
    const observer = new ResizeObserver(entries => {
      if (sideBarRef.current) {
        sideBarRef.current!.style.setProperty('--content-Width', ""+entries[0].contentRect.width+"px")
      }
    })
    observer.observe(sideBarRef.current!)
    return () => sideBarRef.current && observer.unobserve(sideBarRef.current)
  },[])
  function openCloseHandler() {
    setPinOpen(!pinOpen)
  }
  
  
  function getViewerState():ViewerState | undefined {
    if (mapitState.map) {
      return {
        zoomFactor:mapitState.map.getZoom(),
        center:mapitState.map.getCenter()
      };
    } else {
      return undefined;
    }
  }

function updateALayer(map: MitMap, layerId:any, newLayerInfo:any, zoomToFocus:boolean) {
 mapitStateDispatch(actionRemoveDataLayer(layerId, true));
 mapitStateDispatch(actionAddDataLayer(newLayerInfo, zoomToFocus, true));
}
  

  /**
   * Toggles the visibility of a custom feature layer
   * @param customFeatureLayer Custom feature layer to toggle
   */
  function onCustomFeatureLayerToggle(customFeatureLayer: any) {
    mapitState.map && toggleALayerVisibility(mapitState.map, customFeatureLayer.layerId, customFeatureLayer);
  }

  /**
   * Converts a custom feature layer to a normal layer, deleting the layer from the AWS S3 user domain configuration in the process
   * @param customFeatureLayer Custom feature layer to convert to a normal layer
   */


  function callbackOnHierarchyChanges(layerHierarchy: LayerHierarchy): void {
    mapitStateDispatch(actionSetLayerHierarchy(layerHierarchy));
  }

  function setALayerVisibility(map: MitMap, index: any, layerInfo: any, show: boolean) {
    if (index || layerInfo.layerId) {
      mapitStateDispatch(actionSetDataLayerVisibility(index ||layerInfo.layerId, show))
    }
    console.assert((index || layerInfo.layerId), "Missing LayerId")
  }
 
  

  function toggleALayerVisibility(map: MitMap, index: any, layerInfo: LayerInfo) {
    setALayerVisibility(map, index, layerInfo, !layerInfo.visible)
  }
 

function hideALayerVisibility(map: MitMap, index: any, layerInfo: any) {
  setALayerVisibility(map, index, layerInfo, false);
 }

 function showALayerVisibility(map: MitMap, index: any, layerInfo: any) {
  setALayerVisibility(map, index, layerInfo, true);
 }
  
  function callbackOnLayerChanges(action:LayerChangeAction, layerInfo:LayerInfo, zoomToFocus:boolean, statCallback?:(info:any)=>void): number {
    let layerId=layerInfo.layerId;
    let index = -1;
    switch(action) {
      case "Add":
      
      // FIXME: Should not be random, check if hiearchy Mapit import work on
      if (layerId === -1) {
        index = Math.round(Math.random() * 10000000) + 1000; //mapitState.getNextLayerId(mapitState);
      }
      layerInfo.layerId = index
      mapitStateDispatch({type:MapitStateActionType.AddDataLayer, payload:{layerInfo:layerInfo}});
      mapitStateDispatch(actionClosePopups(true))
      return index;
      case "Remove":
      // Find array index of the layer to remove. By idx.
      console.info("Removing layer id:",layerInfo.layerId);
      mapitStateDispatch(actionRemoveDataLayer(layerInfo.layerId));
      mapitStateDispatch(actionClosePopups(true))
      break;
      case "Update":
      case "Styling":
      mapitState.map && updateALayer(mapitState.map, layerId, layerInfo, zoomToFocus);
      break;
      case "Toggle":
      mapitState.map && toggleALayerVisibility(mapitState.map, layerId, layerInfo);
      mapitStateDispatch(actionClosePopups(true))
      break;
      case "Hide":
      mapitState.map && hideALayerVisibility(mapitState.map, layerId, layerInfo);
      mapitStateDispatch(actionClosePopups(true))
      break;
      case "Show":
      mapitState.map && showALayerVisibility(mapitState.map, layerId, layerInfo);
      mapitStateDispatch(actionClosePopups(true))
    
      break;
      default:
      throw new Error("Unknown action: "+action);
    }
    return layerInfo.layerId;
  }

  function callbackOnLayerStyling(action: "styling" | "cancel", layerInfo?) {
    if (action == "cancel") {
      setLayerToStyle(null)
      return
    }
    callbackOnLayerChanges("Styling", layerInfo, false)
  }
  
  async function callbackToConvertCustomFeatureLayerToNormal(customFeatureLayer: any) {
    if (mapitState.map) {
      let email = sessionState.userRef;
      let domainName = sessionState.customerRef;
      let prefixPath = "customerConfig/" + domainName + "/customLayers/" + customFeatureLayer.awsFilename;
      let layerDeleted = await AWSAPIGatewayInterface.deleteFileS3(prefixPath);
      if (layerDeleted) {
        mapitStateDispatch(actionRemoveDataLayer(customFeatureLayer.layerId ,false))
        customFeatureLayer.layerVariability = LayerVariabilityType.NormalLayer;
        customFeatureLayer.awsFilename = undefined;
        mapitStateDispatch(actionAddDataLayer(customFeatureLayer))
      }
    }
  }
  
  function callBackonSettingsFeatureLayer(key: string, show: boolean) {
    setOpenFeatureLayers((a) => {
      if (a.includes(key)) {
        return a.filter((val) => val !== key)
      }
      return [...a, key];
    })
  }
  
  return (
    <>
    {/* <div className="FullContainer"> */}
    <div ref={sideBarRef} className={"LayerMenu"} style={{display: props.show ? "flex" : "none"}}>
    {/* <div className="hoverActivator"></div> */}
    {/* <button className="LayerIcon" data-pinned={pinOpen ? "true":""} onClick={(e) => openCloseHandler()}>
      <FiLayers />
      </button>
      <button className="PinIcon" data-pinned={pinOpen ? "true":""} onClick={(e) => openCloseHandler()} >
      <AiOutlinePushpin />
      </button> */}
      {/* <div className="contentHead">
        <h5 style={{paddingTop:"7px"}}> {Localization.getText("Layers")} </h5>
        </div> */}
        {/* <div  className="content"> */}
        
        <div className="TypeGroup DaLayers" >
        <LayerList 
        mapFacade={mapitState.map}
        layers={Object.values(mapitState.layers).filter(layer => layer.layerVariability !== LayerVariabilityType.FixedFeatureLayer)}
        callbackOnHierarchyChanges={(layerHierarchy) => mapitStateDispatch(actionSetLayerHierarchy(layerHierarchy))}
        callbackOnLayerChanges={(callbackOnLayerChanges as any)}
        selectedLayerToStyle={layerToStyle}
        selectLayerToStyle={(layer) => setLayerToStyle(layer)}
        layerHierarchy={(mapitState.layerHierarchy as any)}
        selectHierarchyToStyle={(a) => setHierarchyToStyle(a)}
        selectLayerToReopen={(e) => setReopenLayer(e)}
        />
        </div>
        <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={<></>} >
        {overflow ? <div style={{borderTop: "3px solid #6785B866", minHeight:"3px"}}></div> : <></>}
        </ProtectedFeature>
        <div className="TypeGroup BgLayers">
        <GroupedLayerControl
        addedAttributes={mapitState.addedAttributions}
        backgroundLayers={mapitState.backgroundLayers || {}}
        featureLayers={mapitState.featureLayers || {}}
        recentLayers={mapitState.featureLayerHistory}
        selectedBackgroundLayerKey={mapitState.selectedBackgroundLayerKey}
        selectedFeatureLayerKeys={mapitState.selectedFeatureLayerKeys}
        customFeatureLayers={Object.values(mapitState.layers).filter(layer => layer.layerVariability === LayerVariabilityType.FixedFeatureLayer)}
        editFeatureAvailable={hasAccessToFeature(Feature.EditCustomFeatureLayers)}
        callBackOnSelectBackgroundLayer={(key) => {
          mapitStateDispatch(actionSelectBackgroundLayer(key));
          mapitState.map.setBackgroundLayer(key);
        }}
        callBackOnSelectFeatureLayer={(key, checked, layer?, history?) => {
          if (Array.isArray(layer)) {
            mapitStateDispatch(actionSetFeatureLayerVisibility(key, checked, layer, history))
          } else {
            mapitStateDispatch(actionSetSingleFeatureLayerVisibility(key, checked, layer))
          }
        }}
        onCustomFeatureLayerToggle={(customFeatureLayer: LayerInfo) => customFeatureLayer && mapitStateDispatch(actionSetDataLayerVisibility(customFeatureLayer.layerId, !customFeatureLayer.visible))}
        callbackToConvertCustomFeatureLayerToNormal={(layer) => callbackToConvertCustomFeatureLayerToNormal(layer)}
        callBackonSettingsFeatureLayer={callBackonSettingsFeatureLayer}
        callBackRecentRecentEntry={(a) => mapitStateDispatch(actionRemoveFeatureLayerHistoryEntry(a))}
        
        />
        </div>
        {/* </div> */}
        </div>
        {/* </div> */}
        
        
        {layerToStyle ?
          <LayerStylingContainer
          layerInfo={mapitState.layers[layerToStyle]}
          sizeRanges={GenerateGeom.sizeRanges}
          colorRanges={GenerateGeom.colorRanges}
          colors={SettingsManager.getSystemSetting("standardSingleColorRange")}
          callbackOnLayerStyling={callbackOnLayerStyling}
          callbackToSaveLayerAsFeatureLayer = {() => console.error("Error: callbackToSaveLayerAsFeatureLayer not implemented")}
          editFeatureAvailable={hasAccessToFeature(Feature.EditCustomFeatureLayers)}
          />: null
        }
        {hierarchyToStyle ? 
          <GroupStyling
          group={hierarchyToStyle}
          callbackOnGroupStyling={(a) => {mapitStateDispatch(actionUpdateNameInLayerHierarchy(a as LayerHierarchy)); setHierarchyToStyle(null)}}
          />
          : null
        }
        
        {reopenLayer ?
          <ImportResultDetails
          showWindow={true}
          onFormClose={() => {setReopenLayer(null)}}
          layerInfo={mapitState.layers[reopenLayer]}
          dataUpdate={a => console.info("ImportResultDetails dataUpdate", a)}
          />
          : null
        }
        
        {ReactDOM.createPortal(
          <>
          <FeatureLayerEditor
          showWindow={openFeaturLayer.length} 
          layers={mapitState.featureLayers}
          activeLayers={mapitState.selectedFeatureLayerKeys}
          editingLayers={openFeaturLayer}
          onChange={(key, value) => mapitStateDispatch(actionStyleFeature(key as string, value as any))}
          // callBackOnStyleChange={(layerInfo) => callbackOnLayerChanges("Styling",layerInfo, false)}
          callBackToSetVisibility={(key, checked) => mapitStateDispatch(actionSetFeatureLayerVisibility(key, checked, [{key: key}]))}
          callBackToHideWindow={() => setOpenFeatureLayers([])}
          callBackToStopEditing={(key) => setOpenFeatureLayers((a) => a.filter((b) => b !== key))}
          /> 
          </>
          , document.getElementById("Mit-MapOverlay") || document.body)
        }
        
        </>
      )
    }