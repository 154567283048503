import { MapitStateContext, actionSelectBackgroundLayer } from "src/states/MapitState";
// import OrthoPhoto from "/images/OrthoView.png"
import { LuLayers } from "react-icons/lu";
import { useContext } from "react";

type props = ({
  current: string
  onSetBgLayer: (e: "Map" | "Orthophoto") => void
} | {}) & {
  anchor?: "normal" | "bottomLeft"
}

export function QuickOrthoPhoto(props: props) {
  const { state: mapitState, dispatch: mapitStateDispatch} = useContext(MapitStateContext);
  let anchor = props.anchor ?? "normal"

  function handleOnClick() {
    if ("current" in props) {
      const next = props.current === "Orthophoto" ? "Map" : "Orthophoto"; 
      props.onSetBgLayer(next)
    } else {
      const next = mapitState.selectedBackgroundLayerKey === "Orthophoto" ? "Map" : "Orthophoto"; 
      mapitStateDispatch(actionSelectBackgroundLayer(next))
      mapitState.map.setBackgroundLayer(next);
    }
  }

  return (
  <div onClick={handleOnClick} className={"QuickOrthoPhoto " + anchor}>
    <img onMouseDown={(e) => e.preventDefault?.()} onTouchStart={(e) => e.preventDefault?.()} src={new URL("/QuickOrtoFoto/kort.png", import.meta.url).href} />
    <img onMouseDown={(e) => e.preventDefault?.()} onTouchStart={(e) => e.preventDefault?.()} style={{opacity: !(mapitState.selectedBackgroundLayerKey === "Orthophoto") ? 1 : 0}} src={new URL("/QuickOrtoFoto/ortofoto.png", import.meta.url).href} />
  </div>
  )
}