import { Image } from 'react-bootstrap';
import {Localization} from '@viamap/viamap2-common';
import { MapitStateContext, actionSetPoiTypesToShow } from 'src/states/MapitState';
import { SettingsManager } from '@viamap/viamap2-common';
import { POIS } from 'src/HLED/managers/PoiManager';
import { BsArrowUpRightSquareFill } from 'react-icons/bs';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';
import { ExtendedMenuButton, VLine } from './MitGlassComponents';
import { useContext, useState, useEffect } from 'react';
import { MapFacadeMaplibre } from 'src/managers/MapFacadeMaplibre';

type PoiSelectornProps = {
  onSavePoisAsLayer?:(poiTypesToShow:string[]) => void
}

export function PoiSelector(props:PoiSelectornProps) {
  let POIsInUse = SettingsManager.getSystemSetting("POIsInUse", ["supermarket","stop","train","strain","metro","chargingstation","supercharger"]);
  const {hasAccessToFeature} = useContext(ApplicationStateContext);
  let {state, dispatch} = useContext(MapitStateContext)
  let [currZoom, setCurrZoom] = useState(0);

  useEffect(() => {
    if (state.map) {
      let map = (state.map as MapFacadeMaplibre)
      setCurrZoom(map.getZoom())
      const ZoomFunc = (e) => {
        setCurrZoom(e.target.getZoom())
      };
      map.on('move',ZoomFunc)
      return () => map.off('move',ZoomFunc)
    }
  },[state.map])
  
  let isInactive = !state.viewPoi;

  function inActive(key:string) {
    return (currZoom && ((POIS[key].maxZoom as number) ?? SettingsManager.getSystemSetting("MaxZoomPoi", 12, true) as number) > currZoom)
  }

  function onClickToogle(key:string) {
    let CurrentList = state.poiTypesToShow || []

    if (inActive(key)) {
      state.map.easeTo({
        zoom: POIS[key].maxZoom ?? SettingsManager.getSystemSetting("MaxZoomPoi", 12, true)
      })
    }
    
    let newList = [key,...CurrentList]
    if (CurrentList.includes(key) && isInactive) {
      newList = CurrentList
    } else if (CurrentList.includes(key)) {
      newList = CurrentList.filter((a) => a !== key)
    }
    dispatch(actionSetPoiTypesToShow(newList));
  }

  function onClickCreateLayerForPois() {
    props.onSavePoisAsLayer && props.onSavePoisAsLayer(state.poiTypesToShow);
  }

  return (
    <>
    <div className='SidePanelScrolling PoiSelector' >
    {POIsInUse.map((key) => <PoiIconButton key={key} pKey={key} inActive={inActive(key)} isSelected={state.poiTypesToShow?.includes(key)} onChange={(e:string) => onClickToogle(e)} />)}
    </div>
    <VLine color={"var(--viamap-green)"} />
        <div className='SettingsMore'>
        {hasAccessToFeature(Feature.PoiSaveAsNewLayer) ?
        <ExtendedMenuButton className=" ExtendedMenuButton"
          onClick={() => onClickCreateLayerForPois()}
          toolTip={Localization.getText("Click to save visible pois as new layer")}      
        >
        <BsArrowUpRightSquareFill />
        </ExtendedMenuButton>
          : <></>
          }
        </div>
      </>
  )

}


type PoiIconButtonProps = {
  pKey:string
  inActive:{}
  isSelected:boolean
  onChange:(e:string) => void
}
function PoiIconButton(props:PoiIconButtonProps) {
  if (!POIS[props.pKey]) {
    return null;
  }
  if (POIS[props.pKey].featureGroup) {
    return null;
  }
  let icon=POIS[props.pKey].icon;
  let label=POIS[props.pKey][Localization.getLanguage()];
  let isSelected = props.isSelected;
  let imageUrl:any
  try {
    if (document.body.classList.contains("Theme-Newsec")) {
      imageUrl = new URL("/images/markersNewSec/"+icon+".svg", import.meta.url).href;
    } else {
      imageUrl = new URL("/images/markersHeyDay/"+icon+".svg", import.meta.url).href;
    }
  } catch {
    imageUrl = new URL("/images/markers/"+icon+".svg", import.meta.url).href;
  }
  let backgroundColor = isSelected ? "white" : "";
  
  return (
      <ExtendedMenuButton onClick={(e)=> {props.onChange(props.pKey); e.preventDefault;}} className={"ExtendedMenuButton inverse" + (isSelected ? " active" : "") + (props.inActive ? " inactive" : "")}
        toolTip={props.inActive ?  Localization.getText("Zoom in to see POI") : label}
      >
      <Image
          // draggable={false}
          src={imageUrl} 
          // data-toggle="tooltip" 
      />
      </ExtendedMenuButton>
  );
}
