import { Product } from "@viamap/viamap2-common/dist/managers/Types";
import { LicenseType, ViamapLicense } from "@viamap/viamap2-common/dist/states/ViamapLicensingState";
import React, { Fragment, useEffect, useState } from "react";
import { Button, Form, Modal } from "react-bootstrap";
import { Container, Row, Col } from 'react-bootstrap';
import { BNBOProject, BNBOUserAccessRights } from "./BNBOState";
import { ADModalBody, ADModalFooter, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassInputControl, GlassInputGroup, GlassInputGroupEscape, GlassInputGroupTitle, GlassTextInput, GlassTextInputTransformer } from "src/components/GlassInput";
import { GlassButton } from "src/components/GlassButtons";
import { BsXLg } from "react-icons/bs";
import { ProtectedFeature } from "src/components/ProtectedFeature";
import { ApplicationStateContext, Feature, hasAccessToFeature } from "src/states/ApplicationState";
import { GlassCheckbox } from "src/components/MitGlassComponents";

interface FormData {
  userEmail:string,
  customerDomain:string,
  product: string;
  productVariant: string;
  expires: string;
  licenseType: string;
  note: string;
  customer: string;
  roles: SubFormEntryString[];
  accessRightsUsers: SubFormEntry[];
  accessRightsOrganizations: SubFormEntry[];
}

interface SubFormEntry {name : string, value:string };
type SubFormEntryString = string;

function SubForm(props:{subFormData:SubFormEntry[], setSubFormData:(value:SubFormEntry[])=> void}) {
  
  useEffect(() => {
    console.log("Subform data", props.subFormData)
  }, [props.subFormData]);

  const addSubFormEntry = () => {
    props.setSubFormData([...props.subFormData, { name: '', value: '' }]);
  };

  const removeSubFormEntry = (index: number) => {
    const updatedSubforms = [...props.subFormData];
    updatedSubforms.splice(index, 1);
    props.setSubFormData(updatedSubforms);
  };

  const handleSubFormChange = (
    index: number,
    field: string,
    event: React.ChangeEvent<any>
  ) => {
    const { name, value } = event.target;
    const updatedSubforms = props.subFormData.map((item, idx) => {
      return idx === index ? field==="name" ?
         {name:value, value:item.value} : {name:item.name, value:value} : item;      
    });
    props.setSubFormData(updatedSubforms);
  };

  return (
    <>
      <GlassInputGroupEscape >
      {props.subFormData.map((subform, index) => (
        <div key={index} className="GlassInputField" style={{marginBottom:"5px"}} >
          <GlassInputControl>
            <input type="text" value={subform.name} onChange={(e) => handleSubFormChange(index, "name", e)} placeholder="Email" autoComplete="off" />
          
          
            <select
              style={{flex:"auto 1 0"}}
              name="product"
              value={subform.value || ""}
              onChange={(e) => handleSubFormChange(index, "value", e)}
            >
              {subform.value == "" && <option value="">Select a access</option>}
              {[BNBOUserAccessRights.Read, BNBOUserAccessRights.Write, BNBOUserAccessRights.Admin].map((product:string, idx:number) => {
                 return (
                     <option key={product} value={product}>{product}</option>
                  )
               })}
            </select>
          
          
          </GlassInputControl>
          <div className="Setting_Butto" title="Slet bruger" >
          <BsXLg className="sm" onClick={() => removeSubFormEntry(index)} />
          </div>
        </div>
      ))}
      <GlassButton  style={{marginLeft:"auto"}} onClick={addSubFormEntry}>
        Add Entry
      </GlassButton >
      </GlassInputGroupEscape>
    </>
  );
};

function InputFormWithSubForm(props:{value:BNBOProject, onChange:(project:BNBOProject) => void, onClose: () => void}) {
  const { hasAccessToFeature } = React.useContext(ApplicationStateContext);
  const [data, setData] = useState<BNBOProject>(props.value);

  function onchange() {
    props.onChange(data);
  }

  const handleInputChange = (
    key: keyof BNBOProject, value
  ) => {
    setData((prevData) => ({ ...prevData, [key]: value }));
  };

  function transformData(data:Record<string, string[]>):SubFormEntry[] {
    return data ? Object.keys(data).map((key) => {
      return data[key]?.map?.((a) => {
        return {name:key, value:a}
      }) || [{name:key, value:""}]
    }).flat() : []
  }

  return (
    <>
    <ADModalBody>
      <GlassInputGroup onSubmit={(e) => onchange()} onEscape={props.onClose}>
        <GlassTextInputTransformer label={"Project ID"}>
          <input type="text" value={data.id} onChange={(e) => handleInputChange("id", e.target.value)} />
        </GlassTextInputTransformer>
        <GlassTextInputTransformer label={"Project name"}>
          <input type="text" value={data.name} onChange={(e) => handleInputChange("name", e.target.value)} />
        </GlassTextInputTransformer>
        <GlassTextInputTransformer label={"Project Description"}>
          <GlassInputControl>
          <textarea value={data.description} onChange={(e) => handleInputChange("description", e.target.value)} />
          </GlassInputControl>
        </GlassTextInputTransformer>
          <GlassTextInputTransformer label={"Protected"}>
            <GlassCheckbox disabled={!hasAccessToFeature(Feature.BNBOToolGlobalAdmin)} checked={data.protected} onClick={(e, val) => handleInputChange("protected", val)} ></GlassCheckbox>
            {!hasAccessToFeature(Feature.BNBOToolGlobalAdmin) ? (<div style={{marginLeft:"5pt",fontSize:"0.8em", color:"grey"}}>
              (Must be Global Admin to change)
              </div>):null}  
          </GlassTextInputTransformer>
        <GlassInputGroupTitle>Access Rights</GlassInputGroupTitle>
        <SubForm 
            subFormData={transformData(data.allowedUsersWithRoles)}
            setSubFormData={(value:SubFormEntry[]) => {
              let resData = {}
              value.forEach((entry) => {
                if (resData[entry.name]) {
                  resData[entry.name].push(entry.value)
                }
                else {
                  resData[entry.name] = [entry.value]
                }
              })
              setData((prevData) => ({ ...prevData, allowedUsersWithRoles: resData }));
            }}
         />
      </GlassInputGroup>
      </ADModalBody>
      <ADModalFooter>
        <GlassButton onClick={(e) => onchange()}>
          Submit
        </GlassButton>
        <GlassButton onClick={props.onClose}>
          Close
        </GlassButton>
    </ADModalFooter>
    </>
  );
};

export default InputFormWithSubForm;

export const BNBOProjectEditor = (props:{
   title?:string,
   show:boolean,
   value:BNBOProject,
   onClose: () => void,
   onChange: (project:BNBOProject) => void
}) => {

  if (!props.show) {
    return <></>
  }

   return (
      <Fragment key={props.value.id}>
      <AdvancedDragModal title={props.title ?? "Project Editor"} PosDefault={{width:"450px", left:"50%", transform:"translateX(-50%)", top:"100px"}} onClose={() => props.onClose()}>
        <InputFormWithSubForm value={props.value} onChange={(e) => props.onChange(e)} onClose={() => props.onClose()}/>
      </AdvancedDragModal>
    </Fragment>
   )
}