import { SettingsManager } from "@viamap/viamap2-common";

type IMG =
  | HTMLImageElement
  | ImageBitmap
  | ImageData
  | {
      width: number;
      height: number;
      data: Uint8Array | Uint8ClampedArray;
    };

export class MapImageCache {
  static cache: { [id: string]: Promise<IMG> | undefined } = {
    "undefined": new Promise((res) => {
      const img = new Image()
      img.src = new URL("/images/cross_grey.svg", import.meta.url).toString()
      img.onload = function () {
        res(img)
      }
    })
  };

  static loadImage(url:string & unknown, callback: (IMG) => void) {
    if (typeof url !== "string" || !url.trim() || url.toLowerCase() == "null") {
      this.cache["undefined"]!.then((a) => {
        callback(a);
      });
      return
    }
    if (this.cache[url] !== undefined) {
      this.cache[url]!.then((a) => {
        callback(a);
      });
      return
    }
    this.cache[url] = new Promise<HTMLImageElement>(async (resolve) => {
      const img = new Image();
      img.src = url as unknown as string; //Cause Url is a string
      img.crossOrigin = "anonymous";
      img.onload = function () {
        resolve(MapImageCache.resizeImage(img));
      };
      img.onerror = async function () {
        const corsProxy = SettingsManager.getSystemSetting("ImageCorsProxyUrl");
        const imgProx = new Image();
        let x = await fetch(
          (corsProxy +
            `https://${(url as string).split("://")[1]}`) as unknown as string,
          {
            headers: {
              accept: "image/png",
            },
          }
        );
        let blobUrl = URL.createObjectURL(await x.blob());
        imgProx.src = blobUrl; //Cause Url is a string
        imgProx.crossOrigin = "anonymous";
        imgProx.onload = function () {
          resolve(MapImageCache.resizeImage(imgProx));
        };
        imgProx.onerror = function () {
          const err = new HTMLImageElement();
          (err.width = 10), (err.height = 10), resolve(err);
        };
      };
    });
    this.cache[url]?.then((a) => {
      callback(a);
    });
  }

  static async resizeImage(img:HTMLImageElement):Promise<HTMLImageElement> { 
    const canvas = document.createElement("canvas");
    const ctx = canvas.getContext("2d");

    const largesValue = Math.max(img.width, img.height)
    let maxDiv = Math.ceil(largesValue / 1000); 
    if (maxDiv < 1) {
      maxDiv = 1
    }

    canvas.width = img.width / maxDiv
    canvas.height = img.height / maxDiv
    ctx?.drawImage(img, 0,0,canvas.width, canvas.height);
    const dataURI = canvas.toDataURL();
    let newImage = new Image()
    newImage.src = dataURI

    return await new Promise((resolve) => {
      newImage.onload = function () {
        canvas.remove()
        resolve(newImage);
      };
      newImage.onerror = function () {
        canvas.remove()
        const err = new HTMLImageElement();
        (err.width = 10), (err.height = 10), resolve(err);
      };
    })
  }
}
