import { Localization } from "@viamap/viamap2-common";
import { useEffect, useMemo, useState } from "react";
import { Button, DropdownButton, DropdownItem, FormControl, InputGroup, Modal, ProgressBar } from "react-bootstrap";
import ReactDataGrid from "react-data-grid";
import { AWSAPIGatewayInterface } from "src/managers/AWSAPIGatewayInterface";


/// Gereric type for Files from S3
export type mapFile = {
    filePath: string;
    loaded: boolean;
    fileType: FileTypes;
    data?: {[value: string]: any};
    
};

/// Differen type of supported Files
type FileTypes = "unknown" | "mapit";


enum ConfirmActions {
    Delete = "Delete",
    None = "None",
}


const ConfirmBox = (confProp:{show:boolean, action: ConfirmActions, value: any , confirm: () => void, close: () => void}) => {
    if (!confProp.show) {
        return null;
    }
    return (
        <Modal.Dialog>
        <Modal.Header>
        <Modal.Title>Are you sure you wanna Delete</Modal.Title>
        </Modal.Header>
        
        <Modal.Body>
        <p style={{maxHeight:"50vh"}}>{confProp.value}</p>
        </Modal.Body>
        
        <Modal.Footer>
        <Button onClick={(e) => confProp.close()} >Close</Button>
        <Button onClick={(e) => confProp.confirm()} >Confirm</Button>
        </Modal.Footer>
        </Modal.Dialog>
    );
};

export type FileTableProps = {
    columns: any[], 
    fileType?: FileTypes,
    fileList: () => Promise<string[]>,
    fileGetter: (FileList:string) => Promise<any>,
    dataParser?: (data:any) => {[value: string]: any},
    LockupMapper?: (e:mapFile,id:number,key:string) => {id: number, value: any},
};

export const FileTable = (prop: FileTableProps) => {
    
    let [fileList, setFileList] = useState([] as mapFile[]);
    let [sortLookup, setSortLookup] = useState([] as {id:number, value: any}[]);
    let [fileLoaded, setFileLoaded] = useState(-1);
    let [loadingPct, setLoadingPct] = useState(0);
    const [sortColumns, setSortColumns] = useState<any[]>([]);
    let [confirmValues, setConfirmValues] = useState({show: false, action: ConfirmActions.Delete, value:"tasd"} as {show:boolean,action:ConfirmActions, value:any});
    let [filterString, setFilerstring] = useState("");
    let [filterKey, setFilterKey] = useState(prop.columns[0] && prop.columns[0].key || "undefined");
    let [filterList, setFileterList] = useState([] as mapFile[]);
    let [textExport,setTextExport] = useState("");
    
    let actionColumn = useMemo (
        () => {
            
            const ActionFormatter = (colProp: any) => {
                return (
                    <>
                    <Button key={colProp.value+2} onClick={(e) => setConfirmValues({show:true, action: ConfirmActions.Delete ,value:colProp.row.fileName})}>{Localization.getText('Delete')}</Button>
                    <Button onClick={(e) => {
                        window.open(window.location.protocol + "//" + window.location.host + "/" + window.location.pathname + "?mapdef="  + colProp.row.fileName.split("/").at(-1),"_blank")}
                    } >Open</Button>
                    </>
                );
            };
            
            return [...prop.columns, {key: 'pathName', name: "Actions", width: 180, sortable: false, resizable: true, formatter: ActionFormatter}];
        },
        [prop.columns]
    );
    
    
    
    useEffect( // Load Folder, and filenames
        () => {
            if (fileLoaded === -1) {
                async function FetchList() {
                    try {
                        let filesOnPath = await prop.fileList();
                        let nameList:mapFile[] = filesOnPath.map(element => {
                            let k:mapFile = {
                                filePath:element,
                                loaded:false,
                                fileType: prop.fileType || "unknown",
                            };
                            return k;
                        });
                        setFileList(nameList);
                        
                    } catch (error) {
                        console.error("Error fileList error");
                    }
                }
                
                FetchList().then((e) => {
                    setFileLoaded(0);
                });
            }
        },
        []
    );
    
    useEffect(
        () => {
            if (fileLoaded === 0) {
                async function LoadFile(workers:number, offset:number) {
                    let i = 0;
                    let error = 0;
                    while (i*workers + offset < fileList.length) {
                        let IDX = i*workers + offset;
                        if (fileList[IDX].loaded === true) {
                            return;
                        }
                        let fileOnPath = (await prop.fileGetter(fileList[IDX].filePath).then((e) => {return e;}).catch((e) => {return undefined;}) as any);
                        if (fileOnPath === undefined) {
                            error = error + 1;
                            if (error >= 3) {
                                i = i + 1;
                            }
                            continue;
                        }
                        
                        error = 0;
                        let fxs = [...fileList];
                        fxs[IDX].data = {...(prop.dataParser &&  prop.dataParser(fileOnPath) || fileOnPath), fileName: fxs[IDX].filePath, pathName : fxs[IDX].filePath, loaded: fxs[IDX].loaded};
                        fxs[IDX].loaded = true;
                        setFileList(fxs); 
                        i = i + 1;
                    }
                }
                setFileLoaded(fileLoaded + 1); 
                LoadFile(6, 0);
                LoadFile(6, 1);
                LoadFile(6, 2);
                LoadFile(6, 3);
                LoadFile(6, 4);
                LoadFile(6, 5);
            }
        },
        [fileLoaded]
    );
    
    useEffect(
        () => {
            if (fileLoaded > 0) {
                setLoadingPct(fileList.reduce((sum, elem) => {return elem.loaded ? sum + 1 : sum;},0));
            }
        },
        [fileList]
    );
    
    useEffect(
        () => {
            if (filterString && filterString.length > 0) {
                let x:mapFile[] = [];
                x = fileList.filter((e) => e.data && e.data[filterKey] && e.data[filterKey].toString().includes(filterString));
                setFileterList(x);
            } else {
                setFileterList(fileList);
            }
        },
        [fileList, filterString, filterKey]
    );
    
    useEffect(
        () => {
            let res = filterList.map((e) => `${e.filePath}`).join("\n");
            setTextExport(res);
        },
        [filterList]
    );
    
    function handleGridSort (sortColumn:any, sortDirection:any) {
        if (sortDirection === "NONE") {
            setSortLookup([]);
            return;
        }
        let newSortLookup = ([] as {id: number, value: any}[]);
        if (prop.LockupMapper) {
            newSortLookup = filterList.map((elem, idx) => prop.LockupMapper!(elem,idx,sortColumn));
        } else {
            newSortLookup = filterList.map((elem, idx) => {return {id: idx, value: (elem.data && elem.data[sortColumn]) || undefined};});
        }
        
        const comparer = (a, b) => {
            if (a.value === b.value) {
                return 0;
            }
            if (a.value === undefined) {
                return -1;
            }
            if (b.value === undefined) {
                return 1;
            }
            if (a.value > b.value) {
                
                return 1;
            }
            return -1;
        };
        
        if (sortDirection === "ASC") {
            setSortLookup(newSortLookup.sort(comparer));
        } else {
            setSortLookup(newSortLookup.sort(comparer).reverse());
        }
    }
    
    function rowGet(idx: number) {
        return filterList[(sortLookup[idx] && sortLookup[idx].id) || idx].data || [];
    }
    
    function closePopup() {
        setConfirmValues({...confirmValues, show:false});
    }
    
    function confirmPopup() {
        closePopup();
        if (confirmValues.action === ConfirmActions.Delete) {
            let x = [...fileList];
            x.splice(x.findIndex((e) => e.filePath === confirmValues.value),1);
            setFileList(x);
            
            // Doen't work?
            AWSAPIGatewayInterface.deleteFileS3(confirmValues.value);
        }
    }
    
    function filterStringHandler(e:any) {
        setFilerstring(e.target.value || "");
    }
    
    let tableData = useMemo (() => {
        return filterList.map((a) => a.data).toSorted((a:any, b:any) => {
            for (const sort of sortColumns) {
                const comparator = getComparator(sort.columnKey);
                const compResult = comparator(a, b);
                if (compResult !== 0) {
                    return sort.direction === 'ASC' ? compResult : -compResult;
                }
            }
            return 0
        });
    },[filterList, sortColumns])
    
    
    return (
        <>
        <ConfirmBox {... confirmValues} confirm={confirmPopup} close={closePopup}  />
        {loadingPct < fileList.length ? <ProgressBar animated={true} striped={true} now={100} label={`${loadingPct} of ${fileList.length}`} />: null}
        
        <InputGroup>
        <DropdownButton 
        title={filterKey}
        id={`dropdown-filterKey`}
        style={{width:"100%"}}
        >
        {prop.columns.map((e, id) => {return <DropdownItem key={id} onClick={() => setFilterKey(e.key)}>{e.name}</DropdownItem>;})}
        </DropdownButton>
        <FormControl autoComplete="false" placeholder="Filter" onChange={(e) => filterStringHandler(e)} value={filterString} />
        <span>{filterList.length}</span>
        {/* Udkommenteret til flere features Såsom Export og Row show/hide */}
        {/* <DropdownButton 
            title={"🖊⚙"}
            id={"dropdown-options"}
            componentClass={InputGroup.Button}
            noCaret={true}
            >
            <MenuItem key={1} onClick={() => setConfirmValues({show:true, action:ConfirmActions.None, value: textExport})} >Export</MenuItem>
            <MenuItem key={2}>Select Columns</MenuItem>
            </DropdownButton> */}
            </InputGroup>
            <ReactDataGrid
            style={{flex:1}}
            className='rdg-light'
            columns={actionColumn}
            sortColumns={sortColumns}
            onSortColumnsChange={setSortColumns as any}
            rows={tableData}
            />
            </>
        );
    };
    
    
    // Borrowed from Portal new UserAdmin
    type Comparator<T> = (a: T, b: T) => number;
    function getComparator(sortColumn: string): Comparator<any> {
        switch (sortColumn) {
            case 'fileName':
            case 'status':
            case 'userName':
            case 'layerName':
            return (a, b) => {
                return (a[sortColumn] || "").localeCompare(b[sortColumn] || "");
            };
            case 'Enabled':
            return (a, b) => {
                return a[sortColumn] === b[sortColumn] ? 0 : a[sortColumn] ? 1 : -1;
            };
            case 'createDate':
            case 'expiraDate':
            return (a, b) => {
                return (a[sortColumn+"Sort"] || 0) - (b[sortColumn+"Sort"] || 0);
            };
            default:
            return (a, b) => {
                if (a > b) {return 1}
                if (a < b) {return -1}
                return 0
            }
        }
    }