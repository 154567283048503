import maplibregl, * as M from 'maplibre-gl';
import * as turf from '@turf/turf';
import circle from '@turf/circle';
// import * as turf from '@turf/helpers';
// import * as turfdist from '@turf/distance';
import {
   ViewerState} from '../common/managers/Types';
import {MitLatLng, GenerateGeomInterface, MitLayerHandle, MapFacadeAbstract, CircleSelectorAbstract, PriotisedMapLayerTypes} from './MapFacade';
import { GenerateGeom } from './GenerateGeom';
import { SettingsManager } from '@viamap/viamap2-common';
import { FeatureLayer } from './WmsLayerFunc';
import { createDonutChart } from './PieChartDonut';
import { MapImageCache } from './MapImageCache';
import { VectorLayer } from './VectorLayerFunc';
import { Spider } from './Spider';
import { AttributionControl } from 'maplibre-gl';
import { linkToMapStyle } from './LinkToMapStyle';
import { GeojsonWFS } from './WFSGeojsonLayerFunc';
import { WFStoGeoJson } from 'src/functions/WFSutils';

// export type MitEvent = M.MapboxEvent;

enum SelectionState { Disabled, ClickToSelectCenter, CenterSelected, RimSelected, WaitingForServer}



export class MitLayerHandleMaplibre implements MitLayerHandle {
   source:any;
   sourceId:string="";
   layerList:any[]=[];
   // layersConsumingEvents:string[]=[];
   layersWithPieCharts:string[]=[];

   static getUniqueId() {
      // todo: implement
   }
   
   constructor() {
      // super();
   }

   hasItems():boolean {
      // todo implement;
      return false;
   }

   clearLayers(map:MapFacadeMaplibre) {
      map?.removeLayer?.(this);
      this.source = undefined;
      this.layerList = [];
      this.layersWithPieCharts = [];
      this.sourceId = "";
   }

   updateSource(map: MapFacadeMaplibre, source: any) {
      this.source.data = source;
      (map.getMapPop().getSource(this.sourceId) as any)?.setData?.(this.source.data)
   }

   updateLayerFromHandle(map: MapFacadeMaplibre, layerHandler: MitLayerHandleMaplibre) {
      let nextLayers = layerHandler.getLayers()
      nextLayers.forEach((a) => a.source = this.sourceId)
      layerHandler.addSource(this.sourceId,{})
      map.addLayer(layerHandler, [this])
      this.sourceId = ""
      map.removeLayer(this)
      this.layerList = nextLayers
      this.sourceId = layerHandler.sourceId
   }

   addSource(id:string, source:any) { 
      this.source = source; this.sourceId = id; 
   }
   hasSource() { 
      return Boolean(this.source && this.sourceId); 
   }


   getSourceId() { 
      return this.sourceId; 
   }
   getSource() { 
      return this.source; 
   }

   addLayer(layer:any) { 
      this.layerList.push(layer); 
   }
   hasLayers() { 
      return Boolean(this.layerList && this.layerList.length > 0);
   }
   getLayers() { 
      return this.layerList; 
   }
   getLayerIds() { 
      // tslint:disable-next-line
      return this.layerList.map((layer) => { return layer['id'];});
   }
   remove() {
      // todo: implement
   }

   addLayerConsumingEvents(id:string) { 
      // this.layersConsumingEvents.push(id); 
   }
   getLayersConsumingEvents():string[] {
      // return this.layersConsumingEvents;
      return []
   }

   moveLayer(map:MapFacadeMaplibre) {
      map.moveLayer(this)
   }

   addTo(map:MapFacadeMaplibre) 
   addTo(map:MapFacadeMaplibre, before: MitLayerHandleMaplibre[])
   addTo(map:MapFacadeMaplibre, before?: MitLayerHandleMaplibre[]) {
      if (before?.length) {
         map.addLayer(this, before);
      } else {
         map.addLayer(this)
      }
   }

   addlayersWithPieCharts(id:string) { 
      this.layersWithPieCharts.push(id); 
   }

}


export class MapFacadeMaplibre implements MapFacadeAbstract {

   public readonly ControlPosition = { 
      TopRight:"top-right", 
      TopLeft:"top-left", 
      BottomRight:"bottom-right", 
      BottomLeft:"bottom-left" 
   };


   private map:M.Map;
   private mapLayoutRestore:{[key:string]:"visible" | "none" | undefined} = {};
   private _isInitialized=false;
   private generateGeomObj:GenerateGeomInterface|undefined;
   private markersBySourceId:{[sourceId:string]:{[id:number]:M.Marker}} = {};
   private layerControl:undefined;
   private catchment:MitLayerHandleMaplibre|undefined;
   private attributionList:string[]=[];
   private rotationCallbackHandler: ((rotation: number) => void)|undefined;
   private moveendCallbackHandler: ((viewState:ViewerState) => void)|undefined;
   private moveendFeatureLayer: {sourceID:string, minZoom:number, dataGetter:(bounds) => any, dataTransformer:(data, options:{zoom:any, bounds:any}) => any}[] = [];

   // store the map configuration properties in an object,
   // we could also move this to a separate file & import it if desired.
   // private config:L.MapOptions = { 
   // //                    center: [55.486051, 10.513315], // Odense Fjord
   // //                    zoom: 10, // start zoom
   //                     zoomControl: false,
   //                     center: [56.304411, 10.508832], // Ved Rønde på Djursland
   //                     zoom: 8, // start zoom
   //                     maxZoom: Utils.getSystemSetting("maxZoom", 18),
   //                     minZoom: Utils.getSystemSetting("minZoom", 3),
   // //                    scrollwheel: false,
   // //                    legends: true,
   // //                    infoControl: false,
   //                     attributionControl: false,
   //                     renderer: L.canvas()
   //                     //  preferCanvas: true, Note canvas performs better but IE is not supported
   //                 };

   constructor(containerElement:any, isOrthophotoGlobal?: boolean, options?: Partial<M.MapOptions>) {
      let protocol="https:";
      let userinfo="mapit";

      /* tslint:disable-next-line */
      let token="eyJkcGZ4IjogIm1hcGl0Z2xvYmFsIiwgInZlciI6ICIyIiwgInByaXZzIjogInIxWjByMEYwazZCdFdxUWNPVXlrQi95NlNVcEp2MlFiZ3lYZXRxNEhZNFhPLzNZclcwK0s5NjRIWTRYMVdPeHJSQU53V1E9PSJ9.R83KpfTlM0cpNxsbzbIjlW+srr1gwrEgJjuYQFblbLdacfT0Hnb4hQALt2WdGjPlN9a+ukt+3nJKKfjPeh+3CA";
      /* tslint:disable-next-line */

      // ToDo: make switchable to normal/newsec/other style
      let vectorStylePath=linkToMapStyle(SettingsManager.getSystemSetting("vectorMapStyle",undefined))
      let defaultBounds = SettingsManager.getSystemSetting("MapInitializationBounds", [[7.741269070919969,57.808475243053806],[13.511745485770007,54.47934487366999]]);
      let style = vectorStylePath;

      var map2 = new M.Map({
         container: containerElement,
         style: style,
         hash: true,
         trackResize:true,
         validateStyle: true,
         bounds: defaultBounds,
         transformRequest: (url, resourceType)=> {
            if(resourceType === 'Tile' && url.startsWith('http://13.48.91.12:8080/geoserver')) {
              return {
               url: url,
               headers: { 'Origin': '*' }
             };
            } else {
               return {
                  url: url,
                };
            }
          }
         ,...options
      })
      
      // document.getElementById("Mit-MapOverlay").append(control.onAdd(map2))

      
      map2.on('load', () => {
         this._isInitialized = true;
         const allMapLayers = map2.getStyle().layers
         const viavectorLayers = allMapLayers.filter((a) => (a && ["viavector","hillshade","orthophoto"].includes(a["source"])));
         viavectorLayers.forEach((a) => {
            this.mapLayoutRestore[a.id] = a.layout?.visibility
         })
      })
      this.initEventManagers(map2);
      map2.addControl(new M.NavigationControl({}), SettingsManager.getSystemSetting("NavigationControlLocation"));
      map2.addControl(new M.ScaleControl({}),'bottom-left');
      // ----------------------------------- load icons ----------------------------------
      let scale = 1;
      map2.loadImage(`https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-blue.png`).then((image) => {
         map2.addImage('blue-pin', image.data as any, { pixelRatio: scale }); // 38x55px, shadow adds 5px (for scale eq 1)
         
      })
      
      map2.loadImage(`https://cdn.rawgit.com/pointhi/leaflet-color-markers/master/img/marker-icon-red.png`).then((image) => {
         map2.addImage('red-pin', image.data as any, { pixelRatio: scale }); // 38x55px, shadow adds 5px (for scale eq 1)
      })
      

      let legImg = Spider.generateLegImage(
         [0, 0],
        [100,100],
         2,
         "black",
       );

      map2.addImage('spider-line', legImg, { pixelRatio: scale }); // 38x55px, shadow adds 5px (for scale eq 1)

      maplibregl.addProtocol("proxy", async (params, abortController): Promise<M.GetResourceResponse<{}>> => {
         const corsProxy = SettingsManager.getSystemSetting("ImageCorsProxyUrl")
         const resp = await fetch(corsProxy + `https://${params.url.split("://")[1]}`,{
            headers:{"Accept":"image/png"}
         });
         if (resp.status == 200) {
            return {data: await resp.arrayBuffer()}
         } else {
            throw new Error(`Tile fetch error: ${resp.statusText}`);
         }
      }); 
 
      this.map = map2;
   }

   /**
    * Registers a callback handler for when a rotation event occurs.
    * @param rotationHandler Function to occur on rotation event.
    */
   public registerRotationCallbackHandler(rotationHandler: (rotation: number) => void) {

      this.rotationCallbackHandler = rotationHandler;

   }

   public restoreNormalVisibility() {
      let layer = Object.keys(this.mapLayoutRestore)
      layer.forEach((a) => {
         this.map.setLayoutProperty(a, 'visibility', this.mapLayoutRestore[a] || "visible") 
      })
   }

   public updateMarkers(sourceId:string) {
      const newMarkers = {};
      const features = this.map.querySourceFeatures(sourceId);

      if (!this.markersBySourceId[sourceId]) {
        return //should do nothing
      } else {
        // remove all old markers
        Object.values(this.markersBySourceId[sourceId]).forEach((m) => {
           m.remove();
        });
      }

      // for every cluster on the screen, create an HTML marker for it (if we didn't yet),
      // and add it to the map if it's not there already
      for (let i = 0; i < features.length; i++) {
          const coords = (features[i].geometry as any).coordinates;
          const props = features[i].properties;
          if (!props.cluster) continue;
          const id = props.cluster_id;

          let marker = this.markersBySourceId[sourceId] && this.markersBySourceId[sourceId][id];
          if (marker) {
              // remove old marker
              marker.remove();
           }
           // create marker
           const el = createDonutChart(props);
           marker = new maplibregl.Marker({
                 element: el as any
           }).setLngLat(coords);
           marker.addTo(this.map);
           this.markersBySourceId[sourceId][id] = marker;
      }
  }

   /**
    * Registers a callback handler for when a moveend event occurs.
    * @param moveendHandler Function to occur on moveend event.
    * sends a state
    * {
         center:[number, number],
         pitch:number,
         zoom:number,
         bearing:number
      }
    */
   public registerMoveendCallbackHandler(moveendHandler: (viewerState:ViewerState) => void) {
      this.moveendCallbackHandler = moveendHandler;
   }

   public initEventManagers(map:M.Map) {
      map.on('styleimagemissing', (e:any) => {
         let id = e.id; // id of the missing image
         console.log("start "+e.id);
         if (id.startsWith("mit-pin")) {
            const color = id.split("mit-pin-")[1]
            GenerateGeom.GetEveryMitPin(color, (url) => {
               map.loadImage(url).catch((error) => console.error("Got error loading image: "+id+" :"+ error.message))
               .then((image) => {
                     if (map.hasImage(id))
                     return
                     image && map.addImage(id, image.data, {pixelRatio:2})
               })
            })
            return
         }
         if (id.startsWith("NS-pin")) {
            const color = id.split("NS-pin-")[1]
            GenerateGeom.GetEveryNSPin(color, (url) => {
               map.loadImage(url).catch((error) => console.error("Got error loading image: "+id+" :"+ error.message))
               .then((image) => {
                     if (map.hasImage(id))
                     return
                     image && map.addImage(id, image.data, {pixelRatio:2})
               })
            })
            return
         }
         if (id.startsWith("mit-poi-")) {
            const icon = id.split("mit-poi-")[1]
            GenerateGeom.GetEveryMitPOI(icon, (url) => {
               map.loadImage(url).catch((error) => console.error("Got error loading image: "+id+" :"+ error.message))
               .then((image) => {
                     if (map.hasImage(id))
                     return
                     image && map.addImage(id, image.data, {pixelRatio:2})
               })
            })
            return
         }
         if (id.startsWith("NS-poi-")) {
            const icon = id.split("NS-poi-")[1]
            GenerateGeom.GetEveryNSPOI(icon, (url) => {
               map.loadImage(url).catch((error) => console.error("Got error loading image: "+id+" :"+ error.message))
               .then((image) => {
                     if (map.hasImage(id))
                     return
                     image && map.addImage(id, image.data, {pixelRatio:2})
               })
            })
            return
         }
         if (id.startsWith("mit-patt-")) {
            const icon = id.split("mit-patt-")[1]
            GenerateGeom.getEveryMitPattern(id, (url) => {
               map.loadImage(url).catch((error) => console.error("Got error loading image: "+id+" :"+ error.message))
               .then((image) => {
                     if (map.hasImage(id))
                     return
                     image && map.addImage(id, image.data, {pixelRatio:2})
               })
            })
            return
         }
         MapImageCache.loadImage(id, (image) => {
            if (map.hasImage(id))
               return
            map.addImage(id, image);
         })
      });
      map.on('error', (ev) => {
         if ("sourceId" in ev && typeof ev.sourceId == "string" && ev.sourceId.includes("backgroundlayer") ) {
            if ("source" in ev) {
               let message = ErrorMessageMapfacade(ev.source as M.Source)
               document.getElementById("NonBlockingError")?.dispatchEvent(new CustomEvent("NonBlockingError", {"detail":message}))
            }  
         }

         console.error("Maplibre error: "+ev.error.message)
      })
      map.on('rotate', () => {
         this.rotationCallbackHandler && this.rotationCallbackHandler(-map.getBearing());
         });

      map.on('moveend', () => {
         let cen = new MitLatLng(map.getCenter().lat, map.getCenter().lng)
         const state = {
            bearing: map.getBearing(),
            pitch: map.getPitch(),
            zoom: map.getZoom(),
            center: cen
         };
         this.moveendCallbackHandler && this.moveendCallbackHandler(state);
         });
      map.once('idle', () => {
         let lHandle = new MitLayerHandleMaplibre()
         lHandle.addSource("idTest", {type:"geojson", data: turf.featureCollection([])});
         lHandle.addLayer({
            id: "testtt",
            type: 'line',
            source: "idTest",
            paint: {
               "line-color": ["match",
                  ["get","anvendelse"],
                  "S","red",
                  "VV","blue",

                  "green"
               ],
               'line-width': 3,
            }
         })
         lHandle.addTo(this);
      })
      map.on('moveend', (e) => {
         let bounds = e.target.getBounds();
         let zoom = e.target.getZoom();
         this.moveendFeatureLayer.map((a) => {
            if (a.minZoom > zoom) {
               return
            }
            a.dataGetter(bounds).then((data) => {
               (this.map.getSource(a.sourceID) as M.GeoJSONSource | undefined)?.setData(
                  a.dataTransformer(data,{zoom:zoom, bounds:bounds})
               )
            })
         })
      })
      // ----------------- PIE MARKERS -----------------

         

      // ----------------- PIE MARKERS -----------------

 
     // code for creating an SVG donut chart from feature properties
     


     // ----------------- PIE MARKERS -----------------
     // after the GeoJSON data is loaded, update markers on the screen and do so on every map move/moveend
         map.on('data', (e:any) => {
            if (!e.sourceId || !(e.sourceId as string).startsWith("layer-source-pie") || !e.isSourceLoaded) {
               return;
            }
            // update this specific layer from source
            this.updateMarkers(e.sourceId as string);

            const updateAllPieMarkers = () => {
               Object.keys(this.markersBySourceId).forEach((sourceId) => {
                  this.updateMarkers(sourceId);
               })
            }
            map.on('move', (e:any) => {
               updateAllPieMarkers()
            });
            map.on('moveend', (e:any) => {
               updateAllPieMarkers()
            });
         });   

         // ----------------- PIE MARKERS END -----------------

   }      

   public setMinZoom(minZoom?: number | null | undefined) {
      return this.map?.setMinZoom?.(minZoom)
   }

   public setMaxZoom(maxZoom?: number | null | undefined) {
      return this.map?.setMaxZoom?.(maxZoom)
   }

   public isInitialized():boolean {
      return Boolean(this._isInitialized);
   }

   public setFeatureState(feature, state) {
      this.map.setFeatureState(feature, state)
   }

   removeSource(source: string) {
      this.map.removeSource(source)
   }

   // public getGenerateGeom() {
   //    return this.generateGeomObj || (this.generateGeomObj = new GenerateGeom(), this.generateGeomObj);
   // }

   public getCircleSelector() {
      return null!;
   }

   public addZoomControl() {
      this.map && this.map.addControl(
         new M.NavigationControl(
            {
               'showCompass':true,
               'showZoom':true
            }
         ), 
         'top-left'
      );
   }

   public getZoomControl():string {
      // let c = new DOMParser().parseFromString(
      //    `
      // <div class="mapboxgl-ctrl mapboxgl-ctrl-group">
      //    <button class="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in" type="button"
      //       title="Zoom in" aria-label="Zoom in">
      //    </button>
      //    <button class="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out"
      //       type="button" title="Zoom out" aria-label="Zoom out">
      //    </button>
      //    <button
      //       class="mapboxgl-ctrl-icon mapboxgl-ctrl-compass" type="button" title="Reset bearing to north"
      //       aria-label="Reset bearing to north">
      //       <span class="mapboxgl-ctrl-compass-arrow"
      //          style="transform: rotate(0deg);">
      //       </span>
      //    </button>
      // </div>
      //    `, 
      //    'text/xml');
      // return c.body;
      return `
      <div id="mit-container-top-left" className="mit-control-container">
      <div className="mapboxgl-ctrl mapboxgl-ctrl-group">
<button className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-in" type="button"
  title="Zoom in" aria-label="Zoom in">
</button>
<button className="mapboxgl-ctrl-icon mapboxgl-ctrl-zoom-out"
  type="button" title="Zoom out" aria-label="Zoom out">
</button>
<button
  className="mapboxgl-ctrl-icon mapboxgl-ctrl-compass" type="button" title="Reset bearing to north"
  aria-label="Reset bearing to north">
  <span className="mapboxgl-ctrl-compass-arrow">
  </span>
</button>
</div>
      `;
   }

   public addScaleControl() {
      // this.map && this.map.addControl(new M.ScscaleControl({}), 'bottom-left');
   }

   public setBackgroundLayer(layer:string) {
      if (this.map) {
         // Switch on Areal Image
         switch (layer) {
            case 'Orthophoto': {
               this.restoreNormalVisibility()
               this.map.setLayoutProperty('orthophoto', 'visibility', 'visible');
               this.map.setLayoutProperty('building', 'visibility', 'none');
               this.map.setLayoutProperty('building-3d', 'visibility', 'none');
               this.map.getStyle().layers!.forEach(layer2 => {
                  // tslint:disable-next-line
                  if (layer2["source-layer"] && layer2["source-layer"] === 'transportation') {
                     // tslint:disable-next-line
                     this.map.setLayoutProperty(layer2["id"], 'visibility', 'none');
                  }
               });
               break;
            }
            case 'OrthophotoNoWaterMask': {
               this.restoreNormalVisibility()
               this.map.setLayoutProperty('orthophoto', 'visibility', 'visible');
               this.map.setLayoutProperty('building', 'visibility', 'none');
               this.map.setLayoutProperty('building-3d', 'visibility', 'none');
               this.map.setLayoutProperty('water_ocean', 'visibility', 'none');
               this.map.getStyle().layers!.forEach(layer2 => {
                  // tslint:disable-next-line
                  if (layer2["source-layer"] && layer2["source-layer"] === 'transportation') {
                     // tslint:disable-next-line
                     this.map.setLayoutProperty(layer2["id"], 'visibility', 'none');
                  }
               });
               break;
            }
            case 'Map': {
               this.restoreNormalVisibility();
               break;
            }
            case 'BlankMap': {
               let layer = Object.keys(this.mapLayoutRestore)
               layer.forEach((a) => {
                  this.map.setLayoutProperty(a, 'visibility', "none") 
               })
               break;
            }
            default:
               throw new Error("Unexpected background layer type: " + layer);
         }
      }
   }

   public addAttribution(att:any) {
      // this.map.addControl(new M.AttributionControl({
      //    customAttribution: [ att ]
      // }));
      this.attributionList.push(att);
   }

   /**
    * @
    * @deprecated true
    * @returns 
    */
   public getMapPop() {
      //TODO: Create way to add popups without getMap()
      return this.map
   }

   // public toggleLayerVisibility(layerHandle:L.Layer):boolean {
   //    let map = this.getMap();
   //    let isVisibleAfter;
   //    if(map.hasLayer(layerHandle)) {
   //       map.removeLayer(layerHandle);
   //       isVisibleAfter = false;
   //   } else {
   //       map.addLayer(layerHandle);        
   //       isVisibleAfter = true;
   //   }
   //   return isVisibleAfter;
   // }

   public setLayerVisibility(layerHandle:MitLayerHandleMaplibre, show: boolean) {
      layerHandle.hasLayers() && layerHandle.getLayerIds().forEach((layerId) => {
         this.map && this.map.setLayoutProperty(layerId, 'visibility', show ? 'visible' : 'none');
      });
      layerHandle.layersWithPieCharts.forEach((sourceId) => {
         if (show) {
            this.markersBySourceId[sourceId] = {}
         } else {
            Object.keys(this.markersBySourceId[sourceId] || {}).forEach((marker) => this.markersBySourceId[sourceId][marker].remove())
            delete this.markersBySourceId[sourceId]
         }
      })
   }

   public getLayerVisibility(layerHandle:MitLayerHandleMaplibre):boolean {
      // todo: Implement
      let layerId = layerHandle.getLayerIds()[0];
      // todo: this always returns undefined.
      let result = this.map && this.map.getLayoutProperty(layerId, 'visibility');
      return result;
   }

   public setViewerState(newState:ViewerState) {
      // wait a bit to mitigate bug in Leaflet when changing view.
      let waitMilliSeconds = 500;
      setTimeout(
        () => {
          let map1 = newState && newState.center && this.map && this.map.panTo(MitLatLng.fromL(newState.center).getLngLat());
          setTimeout(
            () => {
              let map2 = newState && newState.zoomFactor && this.map && (map1 || this.map).setZoom(newState.zoomFactor);
            }, 
            waitMilliSeconds);  
        }, 
        waitMilliSeconds);  
   }

   public easeTo(options: any, eventData?: any): void {
      this.map?.easeTo?.(options, eventData);
   }

   public zoomToFeature(target:MitLayerHandleMaplibre) {
         if (target.hasSource()) {
            this.zoomToData(target.getSource().data);
         } else {
            throw new Error("zoomToFeature: MitLayerHandleMaplibre has no source");
         }
   }

   public setData(layer: MitLayerHandleMaplibre, geoJson: any): void {
      if (layer.hasSource()) {
         (this.map?.getSource(layer.sourceId) as M.GeoJSONSource | undefined)?.setData(geoJson)
      }
   }
   
   public zoomToData(data:any, padding?: number) {
      let boundingBox = turf.bbox(data);
      try {
         this.map && this.map.fitBounds(
            ((boundingBox.length === 4) ? boundingBox : boundingBox.splice(0,4)) as [number,number,number,number],
            {
            // linear:true,
            padding: {top: padding ?? 25, bottom:padding ?? 25, left: padding ?? 25, right: padding ?? 25},
            maxZoom: 19
            }
         );
      } catch (err: any) {
         const evv = new CustomEvent('mit-error-handler', {detail: err.message || err});
         document.body.dispatchEvent(evv);
         
         // appMessageDispatch(actionSetErrorMessage(Localization.getText("Cannot display cadaster information for this point")));
      }
   }

   public newPoiLayer():MitLayerHandle {
      return new MitLayerHandleMaplibre();
   }

   public openPopup(latlng:MitLatLng, val:string) {
      // let popup=L.popup({
      //    closeButton:false,
      //    closeOnClick:true,
      //    className:"mit-report-popup"
      // });
      // popup.setContent(val);
      // popup.setLatLng(latlng);
      // popup.openOn(this.map);
   }

   public getPoints():MitLatLng[] {
      return [];
      // let result:MitLatLng[] = [];
      // this.map.eachLayer(function (layer:L.Layer) {
      //   // Lat, long of current point
      //   if (layer instanceof L.Marker) {
      //     let layer2:L.Marker = layer as L.Marker;
      //     result.push(layer2.getLatLng());
      //   } else {
      //     if (layer instanceof L.CircleMarker) {
      //       let layer3:L.CircleMarker = layer as L.CircleMarker;
      //       result.push(layer3.getLatLng());  
      //     }
      //   }
      // });
      // return result;
   }

   public remove() {
      this.map.remove();
   }
   
   on(event:string, handler:any):void;
   on(event:string, layerid:string,handler:any)
   on(event:string, layeHandler:any, handler?:any):void {
      if (handler) {
         this.map.on(event as keyof M.MapLayerEventType, layeHandler, handler);
         return
      }
      this.map.on(event, layeHandler)
   }

   off(event:string, handler:any):void;
   off(event:string, layerid:string,handler:any)
   off(event:string, layeHandler:any, handler?:any):void {
      if (handler) {
         this.map.off(event as keyof M.MapLayerEventType, layeHandler, handler);
         return
      }
      this.map.off(event, layeHandler)
   }

   once(event:string, handler:any):void;
   once(event:string, layerid:string,handler:any)
   once(event:string, layeHandler:any, handler?:any):void {
      if (handler) {
         this.map.once(event as keyof M.MapLayerEventType, layeHandler, handler);
         return
      }
      this.map.once(event, layeHandler)
   }

   getBounds() {
      return this.map.getBounds()
   }
   getBearing(): number {
      return this.map && this.map.getBearing() || 0;
   }
   getPitch(): number {
      return this.map && this.map.getPitch() || 0;
   }
   getCanvas() {
      return this.map.getCanvas()
   }
   queryRenderedFeatures(geometryOrOptions?, options?) {
      return this.map.queryRenderedFeatures(geometryOrOptions, options)
   }

   public setLayoutProperty(layer:MitLayerHandleMaplibre, property) {
      layer.getLayerIds().map((layerId) => {
         Object.keys(property).forEach((key) =>
            this.map.setLayoutProperty(layerId, key, property[key])
         )
      })
   }

   public setPaintProperty(layer:MitLayerHandleMaplibre, style) {
      layer.getLayerIds().map((layerId) => {
         Object.keys(style).forEach((key) =>
            this.map.setPaintProperty(layerId, key, style[key])
         )
      })
   }

   public hasImage(id:string) {return this.map.hasImage(id)}
   public loadImage(url: string) {return this.map.loadImage(url)}
   public addImage(id: string, image: HTMLImageElement | ImageBitmap | ImageData | { width: number; height: number; data: Uint8Array | Uint8ClampedArray; } | M.StyleImageInterface) {this.map.addImage(id, image)}

   public renderCatchments(polygons:any[], travelTimes:any[], colorScale:any[]) {
      // if (this.catchment) { 
      //    this.catchment.clearLayers();
      // } else {
      //    this.catchment = L.layerGroup(undefined, {}).addTo(this.map);
      // }

      // // let polygons:any[] = polygons.features;
      // let jsonForOneFeature;
      // let largestBounds;
      // let ordered:any[]=[];
      // const opacity = 0.75;
      // polygons && polygons.forEach((itm) => {
      //     let time = itm.properties.time;
      //     let timeindex = travelTimes.findIndex((value)=> { return value === time; });
      //     ordered[timeindex]=itm;
      // });
      // let reversed = ordered.reverse();
      // reversed && reversed.forEach((item, idx) => {
      //     let time = item.properties.time;
      //     let timeindex = travelTimes.findIndex((value)=> { return value === time; });
      //     let color = colorScale[timeindex];
      //     let layer = L.geoJSON(undefined, {
      //         style: (feature:any) => {
      //             return {
      //                 fillColor : color,
      //                 fillOpacity : opacity,
      //                 color : color,
      //                 opacity: opacity,
      //                 stroke : true,
      //                 fill : true,
      //                 weight: 2
      //             };
      //         }
      //       }).addTo(this.catchment);
      //     jsonForOneFeature = {
      //       //   ...result, // this is to include crs and properties from the original data
      //         features: [item]
      //     };
      //     layer.addData(jsonForOneFeature);
      //     // The first layer is the largest
      //     if (!largestBounds) {
      //         largestBounds = layer.getBounds();
      //     }
      // });
      // this.map.fitBounds(largestBounds);
   }

   public hideCatchments() {
      if (this.catchment) { 
         this.catchment.clearLayers(this);
      }
   }

   public getZoom() {
      return this.map && this.map.getZoom() || 0;
   }

   public getCenter() {
      return MitLatLng.fromM(this.map.getCenter());
   }

   public panTo(location:MitLatLng) {
      this.map && this.map.panTo(location.getLngLat());
   }

   public setZoom(zoom:number) {
      return this.map && this.map.setZoom(zoom);
   }

   public getLayers(): string[] {
      const layers = this.map.getStyle().layers;
      return layers.map(function (layer) {
          return layer.id;
      });
   }

   public removeLayer(layerHandle:MitLayerHandleMaplibre) {
      // remove layers first, then source
      layerHandle.getLayersConsumingEvents().forEach(layerId => {
         GenerateGeom.removeEventListeners(this.map, layerId);         
      });
      layerHandle.hasLayers() && layerHandle.getLayerIds().forEach((layerId) => {
         this.map && this.map.getLayer(layerId) && this.map.removeLayer(layerId);
      });
      layerHandle.hasSource() && this.map.getSource(layerHandle.getSourceId()) && this.map.removeSource(layerHandle.getSourceId());
   }
   
   public addLayer(layerHandle:MitLayerHandleMaplibre)
   public addLayer(layerHandle:MitLayerHandleMaplibre, beforeLayerHandles:MitLayerHandleMaplibre[])
   public addLayer(layerHandle:MitLayerHandleMaplibre, beforeLayerHandles?:MitLayerHandleMaplibre[]) {
      if (!this.map.getSource(layerHandle.getSourceId()))
      layerHandle.hasSource() && this.map && this.map.addSource(layerHandle.getSourceId(), layerHandle.getSource());
      layerHandle.hasLayers() && layerHandle.getLayers().forEach((layer) => {
         let dataLayerIds = this.getLayers().filter((a) => a.includes("layer-id"));
         let layerID = layer.id
         let layerPriKey = PriotisedMapLayerTypes.findIndex((a) => layerID.includes(a))
         let beforeTheseTypes = [...PriotisedMapLayerTypes].splice(0,layerPriKey + 1).filter((a) => a !== PriotisedMapLayerTypes[layerPriKey])
         let beforeThis: string | undefined = undefined;

         let layers = beforeLayerHandles?.map((a) => {
            const acceptedLayers = a.getLayerIds().find((a) => {
               const hpriKey = PriotisedMapLayerTypes.findIndex((b) => a.includes(b))
               return layerPriKey == hpriKey
            })
            return acceptedLayers;
         })
         beforeThis = layers && layers.find((a) => a !== undefined);



         beforeThis ??= dataLayerIds.find((a) => beforeTheseTypes.some((before) => a.includes(before)))
         if (this.map.getLayer(layer.id)) {
            this.map.removeLayer(layer.id)
         }
         if (beforeThis) {
            this.map && this.map.addLayer(layer, beforeThis);
            return
         }
         this.map && this.map.addLayer(layer);
      });
      layerHandle.layersWithPieCharts.forEach((sourceId) => {
         if (this.markersBySourceId[sourceId]) {
            Object.keys(this.markersBySourceId[sourceId]).forEach((marker) => {
               // clear before removing refs
               this.markersBySourceId[sourceId][marker].remove()
            })
         }
         this.markersBySourceId[sourceId] = {}
      })
   }

   public updateLayer(layerHandle:MitLayerHandleMaplibre) {
      if (layerHandle.hasSource() && this.map) {
         if (this.map.getSource(layerHandle.getSourceId())) {
            (this.map as any).getSource(layerHandle.getSourceId() as any)!.setData(layerHandle.getSource().data);
         } else {
            this.map.addSource(layerHandle.getSourceId(), layerHandle.getSource());
         }
      } 
      if (layerHandle.hasLayers() && this.map) {
         layerHandle.getLayers().forEach((layer) => {
            if (this.map.getLayer(layer.id)) {
               this.map.removeLayer(layer.id);
            }
            this.map.addLayer(layer);
      });
      }
   }

   public moveLayer(layerHandle:MitLayerHandleMaplibre) {
      // layerHandle.hasSource() && this.map && this.map.addSource(layerHandle.getSourceId(), layerHandle.getSource());
      layerHandle.hasLayers() && layerHandle.getLayers().forEach((layer) => {
         let dataLayerIds = this.getLayers().filter((a) => a.includes("layer-id"));
         let layerID = layer.id
         let layerPriKey = PriotisedMapLayerTypes.findIndex((a) => layerID.includes(a))
         let beforeTheseTypes = [...PriotisedMapLayerTypes].splice(0,layerPriKey + 1).filter((a) => a !== PriotisedMapLayerTypes[layerPriKey])
         let beforeThis: string | undefined = undefined;

         beforeThis ??= dataLayerIds.find((a) => beforeTheseTypes.some((before) => a.includes(before)))
         if (beforeThis) {
            this.map && this.map.moveLayer(layerID, beforeThis);
            return
         }
         this.map && this.map.moveLayer(layerID);
      });
   }

   public zoomToPoint(latlng:MitLatLng) {
      // let p:MitLatLngExpression = point;
      // let bounds: MitLatLngBoundsExpression = MitLatLngBounds(p, p);
      // // zoom to the area
      // this.map.fitBounds(bounds);
      this.map && this.map.panTo(latlng.getLngLat());
   }

   public createCircle(latlng:MitLatLng, radius:number):MitLayerHandleMaplibre {
      // let locationMarkers = new L.FeatureGroup();
      // let circle = L.circle(latlng, radius);
      // circle.addTo(locationMarkers);
      // locationMarkers.addTo(this.map);
      // return locationMarkers;
      return new MitLayerHandleMaplibre();
   }

   public removeLayerByHandle(handle:MitLayerHandleMaplibre) {
      this.removeLayer(handle);
   }

   public locate() {
      // this.map!.locate({setView: false, watch: true, maxZoom: 16});
   }

   public initializeBackgroundLayers() {
      // todo: implement
   }

   private createWMSSource(fl: FeatureLayer) {
      return {
         'type': 'raster',
         // use the tiles option to specify a WMS tile source URL
         'tiles': [
            fl.getTileURL()
            // Nolonger works 'https://services.datafordeler.dk/Matrikel/MatrikelGaeldendeOgForeloebigWMS/1.0.0/WMS?' +
            // 'bbox={bbox-epsg-3857}&format=image/png&service=WMS&version=1.3.0&request=GetMap&transparent=TRUE&crs=EPSG:3857&width=256&height=256' +
            // '&layers=Jordstykke_Gaeldende&styles=Jordstykke_Gaeldende_Roed&username=QBXRBEJZGQ&password=Ballerup2100$'
         ],
         'tileSize': 256,
         'maxzoom': fl.layer.maxZoom,
         'minzoom': fl.layer.minZoom,
      }
   }

   private createWMSLayer(id: string, source: string, fl: FeatureLayer) {
      return {
         'id': id,
         'type': 'raster',
         'source': source,
         'paint': fl.layer.paint || {},
         'maxzoom': fl.layer.maxZoom,
	      'minzoom': fl.layer.minZoom,
      }
   }

   public addVectorLayer(layer: VectorLayer| GeojsonWFS):MitLayerHandle {
      const sourceSpec = layer.getSource();
      const layerSpec = layer.getLayers();
      if (this.isInitialized()) {
         if (!this.map.getSource(layer.getSourceID()))
         this.map.addSource(layer.getSourceID() ,sourceSpec as any)
         let beforeThisLayer = this.map.getStyle().layers.map((a) => a.id).find((a) => a.startsWith("layer-id"))
         layerSpec.map((a, idx) => {
            if (!this.map.getLayer(layer.getLayerID(idx)))
            this.map.addLayer(a as any, beforeThisLayer);
         })
      }
      const handle = new MitLayerHandleMaplibre();
      handle.addSource(layer.getSourceID(), layer.getSource())
      if (layer instanceof GeojsonWFS) {
         let bounds = this.map.getBounds();
         let zoom = this.map.getZoom();
         if (layer.layer.minZoom < zoom) {
            fetch(layer.getDataSrc(bounds)).then(async (data) => {
               let json 
               if (data.headers.get("Content-Type")?.includes("text/xml")) {
                  json = WFStoGeoJson(await data.text());  
               } else {
                  json = await data.json()
               }
               (this.map.getSource(layer.getSourceID()) as M.GeoJSONSource | undefined)?.setData(
                  layer.getDataTransformer()(json, {zoom:zoom, bounds:bounds})
               ) 
            })
         }
         this.moveendFeatureLayer.push({
            sourceID: layer.getSourceID(),
            minZoom: layer.layer.minZoom,
            dataGetter: async (bounds) => {
               let data = (await fetch(layer.getDataSrc(bounds)))
               if (data.headers.get("Content-Type")?.includes("text/xml")) {
                  return WFStoGeoJson(await data.text());  
               } else {
                  return await data.json()
               }
            },
            dataTransformer: layer.getDataTransformer()
         })
      }
      layerSpec.map((a, idx) => {
         handle.addLayer(layer.getLayerID(idx));
      })
      return handle
   }

   public addFeatureLayer(key:string, fl:FeatureLayer):MitLayerHandle {
      let layerId = this.constructBackgroundLayerId(key);
      let sourceId = this.constructBackgroundLayerSourceId(key);
      let sourceSpec = this.createWMSSource(fl);
      let layerSpec = this.createWMSLayer(layerId, sourceId, fl);

      let before = fl.layer.before
      if (this.isInitialized()) {
         if (!this.map.getSource(sourceId))
         this.map.addSource(sourceId, sourceSpec as any);
         let beforeThisLayer = this.map.getStyle().layers.map((a) => a.id).find((a) => a.startsWith("layer-id") || (before && a == before))
         if (!this.map.getLayer(layerId))
         this.map.addLayer(layerSpec as any, beforeThisLayer);
      }
      
      let handle = new MitLayerHandleMaplibre();
      handle.addSource(sourceId, sourceSpec);
      handle.addLayer(layerId);
      

      return handle;
   }

   public updateVectorLayer(layer: VectorLayer | GeojsonWFS, value: {[key:string]:any}): MitLayerHandle {
      const key = Object.keys(value)
      let layerSpec = layer.getLayers()
      let source = layer.getSource()
      //TODO: Find what needs be changed


      let handle = new MitLayerHandleMaplibre();
      handle.addSource(layer.getSourceID(), layer.getSource());
      layerSpec.map((a, idx) => {
         if ("filter" in a) {
            this.map.setFilter(a.id, a.filter, {validate:true})
         }
         handle.addLayer(layer.getLayerID(idx));
      })
      return handle;
   }

   public updateFeatureLayer(key: string, fl: FeatureLayer):MitLayerHandle {
      let layerId = this.constructBackgroundLayerId(key);
      let sourceId = this.constructBackgroundLayerSourceId(key);
      let sourceSpec = this.createWMSSource(fl);
      let layerSpec = this.createWMSLayer(layerId, sourceId, fl);
      if (this.isInitialized()) {
         if (this.map.getSource(sourceId)) {
            Object.keys(layerSpec.paint || {}).forEach((a) => {
               this.map.setPaintProperty(layerId,a,layerSpec.paint[a])
            })
         }
      }
      let handle = new MitLayerHandleMaplibre();
      handle.addSource(sourceId, sourceSpec);
      handle.addLayer(layerId);
      

      return handle;
   }

   constructBackgroundLayerId(key:string) {
      return "backgroundlayer"+key;
   }
   constructBackgroundLayerSourceId(key:string) {
      return "backgroundlayerSource"+key;
   }

   public changeVectorVisibility(layer: VectorLayer, visibility: "none" | "visible") {
      layer.getLayerID().map((a) => {
         this.map && this.map.getLayer(a) && this.map.setLayoutProperty(a, 'visibility', visibility)
      })
   }

   public removeVectorLayer(layer: VectorLayer | GeojsonWFS) {
      layer.getLayerID().map((a) => {
         this.map && this.map.getLayer(a) && this.map.removeLayer(a)
      })
      this.map && this.map.getSource(layer.getSourceID()) && this.map.removeSource(layer.getSourceID())
      if (layer instanceof GeojsonWFS) {
         let x = this.moveendFeatureLayer.findIndex((a) => a.sourceID == layer.getSourceID())
         if (x !== -1) {
            this.moveendFeatureLayer.splice(x, 1)
         }
      }
   }

   public changeFeatureVisibility(key: string, visibility: "none" | "visible") {
      let a = this.constructBackgroundLayerId(key);
      this.map && this.map.getLayer(a) && this.map.setLayoutProperty(a, 'visibility', visibility)
   }

   public removeFeatureLayer(key:string) {
      try {
         let layerId = this.constructBackgroundLayerId(key);
         let sourceId = this.constructBackgroundLayerSourceId(key);
         // Todo: Mapbox does not support event listeners on raster layers. Need a general click handler and control it from there.
         // GenerateGeomMapbox.removeEventListenersForFeatureLayer(this.map, layerId);
         this.map && this.map.getLayer(layerId) && this.map.removeLayer(layerId);
         this.map && this.map.getSource(sourceId) && this.map.removeSource(sourceId);
      } catch (error) {
         throw new Error(`Could not remove feature layer ${key}: ${error}`);
      }
   }
  
   public getLatLngFromMouseEvent(clickEvent:any):MitLatLng {
      let e = clickEvent as M.MapMouseEvent;
      return new MitLatLng(e.lngLat.lat, e.lngLat.lng);
   }

   public showUserLocation(show: boolean): void {
      
      function createPointSource(lat:number, lng:number) {
         return {
            type: "geojson",
            data: {
               "type": "FeatureCollection",
               "features": [circle([lng, lat], 25, {units:"meters"})]
               }
         };
      }
   
      function createPointLayer(id: string, source: string) {

         return {
            id: id,
            type: 'fill',
            source: source,
            'layout': {
            },
            paint: {
               'fill-color':'#BD9060',
               'fill-opacity':0.4,
               'fill-outline-color':'#FFFFFF'
            },
         };
      }

      const layer="userLocationLayer";
      const source="userLocationSource";
      let errorMsg;
      function getLocation(map:M.Map, callback:(position)=>void) {
         if (navigator.geolocation) {
           navigator.geolocation.getCurrentPosition(callback, showError);
         } else {
           errorMsg = "Geolocation is not supported by this browser.";
           alert(errorMsg);
         }
       }
       
       function showPosition(map:M.Map, position:any, obj:MapFacadeMaplibre) {
         errorMsg = "Latitude: " + position.coords.latitude +
         "<br>Longitude: " + position.coords.longitude;
         // alert(errorMsg);
         let pointData=createPointSource(position.coords.latitude, position.coords.longitude);
         if (map.getSource(source)) {
            (map as any).getSource(source).setData(pointData.data);
         } else {
            map.addSource(source, pointData as any);
         }
         if (!map.getLayer(layer)) {
            map.addLayer(createPointLayer(layer, source) as any);
         }
         obj.zoomToData(pointData.data);
       }

       function showError(error) {
         switch(error.code) {
           case error.PERMISSION_DENIED:
             errorMsg = "User denied the request for Geolocation."
             alert(errorMsg);
             break;
           case error.POSITION_UNAVAILABLE:
             errorMsg = "Location information is unavailable."
             alert(errorMsg);
             break;
           case error.TIMEOUT:
             errorMsg = "The request to get user location timed out."
             alert(errorMsg);
             break;
           case error.UNKNOWN_ERROR:
             errorMsg = "An unknown error occurred."
             alert(errorMsg);
             break;
         }
       }
      if (show) {
         this.map && getLocation(this.map,(position)=>{showPosition(this.map, position, this)});
      } else {
         // alert("switch off");
         if (this.map && this.map.getLayer && this.map.getLayer(layer)) {
            this.map.removeLayer(layer);
         }
      }
   }
}

export class CircleSelector implements CircleSelectorAbstract {
   map:M.Map;
   callbackOnComplete:(latlng:MitLatLng, distance:number)=>void;
   callbackOnMove:(latlng:MitLatLng, distance:number)=>void;
   callbackOnCancel:()=>void;
   DefaultDistance:number;
   MinimumDistance:number; 
   MaximumDistance:number; 
   // State:{
   selectionState:SelectionState = SelectionState.Disabled;
   marker:M.Marker | undefined;
   coords:M.LngLat | undefined;
   distance:number|undefined;
   // }
   layer="circleselector";


   constructor(
      map:M.Map,
      callbackOnComplete:(latlng:MitLatLng, distance:number)=>void,    
      callbackOnMove:(latlng:MitLatLng, distance:number)=>void,
      callbackOnCancel:()=>void,
      DefaultDistance:number, 
      MinimumDistance:number, 
      MaximumDistance:number) {
      
      this.map = map;
      this.callbackOnComplete = callbackOnComplete;
      this.callbackOnMove = callbackOnMove;
      this.callbackOnCancel = callbackOnCancel;
      this.DefaultDistance = DefaultDistance;
      this.MinimumDistance = MinimumDistance;
      this.MaximumDistance = MaximumDistance;

   }

   public enable() {
      this.selectionState = SelectionState.ClickToSelectCenter;
      this.map.on("click", this.layer, this.mapClickHandler);
   }

   public disable() {
      this.cleanup();
   }

   public isDisabled() {
      return this.selectionState === SelectionState.Disabled;
   }

   public cleanup() {
      this.map && this.marker && this.marker.remove();
      this.map && this.map.off("click", this.layer, this.mapClickHandler);
      this.map && this.map.off("mousemove", this.layer, this.mapMoveHandler);
      // this.map && this.map.closePopup();
      this.selectionState = SelectionState.Disabled;
   }

   public remove() {
      this.map && this.marker && this.marker.remove();
   }

   public show(latlng?:MitLatLng, radius?:number) {
      // this.setOrUpdateMarker(latlng, radius);
   }

   public hide() {
      this.remove();
   }

   public getLatLng():MitLatLng|undefined {
      return this.coords && MitLatLng.fromM(this.coords);      
   }



   mapMoveHandler(e2:M.MapMouseEvent) {
      // find the distance between the center and mouse position.
      let f1 = this.coordToTurfFeature(e2.lngLat);
      let f2 = this.coordToTurfFeature(this.marker!.getLngLat());
      let dist = turf.distance(f1, f2, {units:'kilometers'});
      dist = dist / 1000; // to meters.
      dist = 10 * (Math.floor(dist/10)); // round to whole 10 meters
      if (!dist) { dist=1; } // minimum distance of 1 m (otherwise we get error on missing param from servrer)
      if (dist < this.MinimumDistance) { dist=this.MinimumDistance; } // minimum distance of 50 m (otherwise we get error on missing param from servrer)
      if (dist > this.MaximumDistance) { dist=this.MaximumDistance; } // maximum distance of 10000 m (otherwise we get error on missing param from servrer)
      this.setOrUpdateMarker(undefined, dist);
      // ToDo: migrate to Maplibre popup.
      // var popup=L.popup({
      //     closeButton:false,
      //     closeOnClick:true,
      //     className:"mit-report-popup"
      //   });
      // popup.setContent(""+dist.toFixed(0)+"m");
      // popup.setLatLng(e2.latlng);
      // popup.openOn(this.map!);
      this.distance = dist;
      // todo: implement
      // this.callbackOnMove(this.marker!.getLngLat(), dist);
     }
  
     setOrUpdateMarker(latlng?:MitLatLng, radius?:number) {
      // ToDo: migrate to Maplibre.
      // if(this.marker) {
      //     latlng && this.marker.setLatLng(latlng);
      //     radius && this.marker.setRadius(radius);
      // } else {
      //     console.assert(latlng, "Event object expected");
      //     console.assert(radius, "Distance expected");
      //     var marker = L.circle(
      //         latlng!, 
      //         {
      //         radius: radius || 0,
      //         color: 'red'
      //         });
      //     marker.addTo(this.map);
      //     this.marker=marker;
      //     this.coords=latlng!;    
      // }
     }
  
     mapClickHandler(e2:any) {
   // ToDo: migrate to Maplibre popup.
   //    switch(this.selectionState) {
   //        case SelectionState.ClickToSelectCenter:
   //            this.setOrUpdateMarker(e2.latlng, this.distance);
   //            this.map.on("mousemove", this.mapMoveHandler, this);
   //            this.selectionState = SelectionState.CenterSelected;
   //            this.callbackOnMove(this.coords, this.distance);
   //            break;
   //        case SelectionState.CenterSelected:
   //            this.map.off("mousemove", this.mapMoveHandler, this);
   //            this.selectionState = SelectionState.RimSelected;
   //            this.callbackOnComplete(this.coords, this.distance);
   //            break;
   //        case SelectionState.RimSelected:
   //            this.remove();
   //            this.selectionState = SelectionState.ClickToSelectCenter;
   //            this.marker = undefined;
   //            this.distance = this.DefaultDistance;
   //            break;
   //        default:
   //            throw Utils.createErrorEventObject("Unknown or unexpected state:"+this.selectionState);
   //    }
     }

     private coordToTurfFeature(coords:M.LngLat) {
      // var geometry:any = {
      //    "type": "Point",
      //    "coordinates": [coords.lng, coords.lat]
      //  };
      //  var feature = turf.feature(geometry);
       var feature = turf.point([coords.lng, coords.lat]);
       return feature;
     }
}

function ErrorMessageMapfacade(source: M.Source) {
   if ("tiles" in source && Array.isArray(source.tiles)) {
      return ErrorByTileSource(source.tiles[0])
   }
   return "Unknown error"
}

function ErrorByTileSource(a: string) {
   const errors = {
      "wms.cowi.com":"Cowi",
      "services.hxgncontent.com":"Hexagon",
      "lb.viamap.net":"Viamap-Geoserver",
      "api.dataforsyningen.dk":"Dataforsyningen",
      "services.datafordeler.dk":"Datafordeler",
      "geofa.geodanmark.dk":"Geodanmark",
      "gisp.bpst.dk":"Social- og Boligstyrelsen",
      "geoserver.plandata.dk":"Plandata",
      "arealeditering-dist-geo.miljoeportal.dk":"Miljøportal",
      "geocloud.vd.dk":"Vejdirektoratet",
      "www.kulturarv.dk":"Kulturarv",
      "wfs2-miljoegis.mim.dk":"Miljøgis",
      "data.geus.dk":"Geus",
      "vmgeoserver.vd.dk":"Vejdirektoratet",
      "b0501-prod-arldgeo-app.azurewebsites.net":"Areal Geo",
      "geodata.fvm.dk":"Landbrugs- og Fiskeristyrelsen",
      "gis.nst.dk":"Naturstyrelsen",
      "miljoegis.mim.dk":"Miljøgis",
      "miljoegis3.mim.dk":"Miljøgis",
      "arld-extgeo.miljoeportal.dk":"Miljøportal",
      "naturarealdatageo.miljoeportal.dk":"Miljøportal"
   }
   let errSource = Object.keys(errors).find((b) => a.includes(b)) 
   if (errSource) {
      return errors[errSource]
   }
   return "Unknown error"
}