import { Localization, SettingsManager } from "@viamap/viamap2-common";
import {
  LayerType,
  LayerInfo,
  ColorByValueStyling,
  DivisionStylingType,
  OtherLegendSpec,
  LayerHierarchy,
} from "../common/managers/Types";
import {
  ColorBoxEditable,
  ColorBox,
  MitCount,
} from "./ComponentUtils";
import { GenerateGeom } from "src/managers/GenerateGeom"
import { LegendScale } from "./LegendScale";
import { MapitUtils } from "src/managers/MapitUtils";
import { OldHierarchy } from "src/managers/OldHierarchy";
import { ADModalBody, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { Fragment } from "react";
import { FaMapMarker } from "react-icons/fa";
import { operatorFromDivision } from "./LegendUtils";
import { BsImage } from "react-icons/bs";

type Props = {
  showWindow: number;
  layers: LayerInfo[];
  layerHiearchy?: LayerHierarchy;
  callBackOnStyleChange: (layerInfo: LayerInfo) => number;
  callBackToHideWindow: () => void;
  otherLegends: { [legendId: string]: OtherLegendSpec };
};

export function Legend(props: Props) {

  function changeSimpleStylingPropertiesAndReStyleMap(
    layerInfo: LayerInfo,
    newStylingProps: {}
  ) {
    let newLayerInfo = {
      ...layerInfo,
      styling: { ...layerInfo.styling, ...newStylingProps },
    };
    props.callBackOnStyleChange(newLayerInfo);
  }

  function callBackOnStyleChange(
    layerInfo: LayerInfo,
    newStyle: ColorByValueStyling
  ) {
    layerInfo.styling.colorByValue!.divisions = newStyle.divisions;
    layerInfo.styling.colorByValue!.divisionStylingType =
      DivisionStylingType.Custom;
    props.callBackOnStyleChange(layerInfo);
  }

  let anyLayersToDisplay: boolean = false;
  const layersSorted = getLayerInSorted(props.layers, props.layerHiearchy)
  let layerx = layersSorted?.map((layerInfo: LayerInfo) => {
    if (!layerInfo.visible) {
      return null
    }
    anyLayersToDisplay = true;
    return (
      <Fragment key={layerInfo.layerId}>
        <LayerLegend layerInfo={layerInfo} onStyleChange={callBackOnStyleChange} onSimpleStyling={changeSimpleStylingPropertiesAndReStyleMap} />
      </Fragment>
    )
  })


  // MARK: OTHER LEGEND
  let otherLegends = Object.keys(props.otherLegends).map((lgd, idx) => {
    let ol = props.otherLegends[lgd];
    if (ol.showLegend()) {
      anyLayersToDisplay = true;
      return <div key={lgd}>{ol.renderLegend()}</div>;
    }
  });

  // MARK: DIALOG

  if (props.showWindow && anyLayersToDisplay) {
    return (
      <AdvancedDragModal 
        PosDefault={{left:"10px", top:"calc(100% - 10px)", transform:"translateY(-100%)"}}
        PosDefaultNS={{left: "calc(100% - 10px)", top:"calc(100% - 10px)", transform:"translate(-100%, -100%)"}}
        variant={["Small", "NSWhite"]}
        title={Localization.getText("Legend")}
        onClose={props.callBackToHideWindow}
      >
        <ADModalBody>
          <div className="Legend" style={{fontSize: "10pt"}}>
            {layerx || []}
            {otherLegends}
          </div>
        </ADModalBody>
      </AdvancedDragModal>
    );
  } else {
    return null;
  }
}

// MARK:SizeRange

function verticalSizeLegend(layerInfo: LayerInfo, showTitle: boolean): React.ReactNode {
  if (!layerInfo!.styling!.sizeByProperty) {
    return null
  }
  const sizeRanges= GenerateGeom.sizeRanges

  let renderDivRows = layerInfo?.styling?.sizeByValue?.divisions?.list?.map((div, idx) => {
      let size = sizeRanges[layerInfo.styling.sizeByValue!.useSizeRange || 0].sizes[idx];
      size = size / 2;
      let bgColor = layerInfo.styling.color || "grey";

      const {op, text} = operatorFromDivision(div)
      return (
        <div key={idx} className="Line">
          <div className="Color">
            <ColorBox color={bgColor} />
          </div>
          <div className="Operator">{op}</div>
          <div className="Text">{text}</div>
          <div className="Count">
            <MitCount
              show={Boolean(layerInfo.styling.showElementCounts)}
              count={div.count}
            />
          </div>
        </div>
      );
    });
  if (showTitle) {
    let column = layerInfo.styling.sizeByProperty;
    let title = column;
    return (
      <>
        <div className="Title" >
          {title}
        </div>
        {renderDivRows}
      </>
    )
  } else {
    return renderDivRows || [];
  }
}

// MARK: SINGLE COLOR

function areasWithNoDataLegend(layerInfo: LayerInfo, onChange) {
  if (!(MapitUtils.isAreaLayer(layerInfo.type) &&
  !layerInfo.styling.areaNoData?.show)) {
    return null
  }
  let value =
    layerInfo.styling.areaNoData?.color ||
    SettingsManager.getSystemSetting(
      "areaMapColorForAreasWithNoData",
      "black"
    );
  let text = Localization.getText("No data");
  let onCommit = (c) =>
    onChange(layerInfo, {...layerInfo.styling, areaNoData: {show:true, color:c}});
  return renderSingleColorLegend(layerInfo.readonly, text, value, onCommit);
}

function areaFillColorLegend(layerInfo: LayerInfo, onChange) {
  if (!(MapitUtils.isAreaLayer(layerInfo.type) &&
  !layerInfo!.styling!.colorByProperty)) {
    return null
  }

  let value =
    layerInfo.styling.areaFillColor ||
    SettingsManager.getSystemSetting("areaMapDefaultFillColor", "black");
  let text = Localization.getText("Area Fill Color");
  let onCommit = (c) =>
    onChange(layerInfo, {
      areaFillColor: c,
    });
  return renderSingleColorLegend(layerInfo.readonly, text, value, onCommit);
}

function renderSingleColorLegend(
  readOnly: boolean,
  label: string,
  value: string,
  onCommit: (color: string) => any,
  onCancel?: () => any
) {
  let text = "[" + label + "]";
  let op = "";
  let RenderColorBox =
    readOnly === true ? (
      <ColorBox color={value} />
    ) : (
      <ColorBoxEditable
        color={value}
        onCommit={onCommit}
        onCancel={onCancel}
      />
    );
  return (
    <div className="Line">
      <div className="Color">{RenderColorBox}</div>
      <div className="Operator">{op}</div>
      <div className="Text" style={{ textAlign: "left" }}>
        {text}
      </div>
    </div>
  );
}





// ColorRange

function verticalColorLegend(layerInfo: LayerInfo, showTitle: boolean, onColorByValueStyling, onStyling?) {
  if (! layerInfo!.styling!.colorByProperty) {
    return null
  }
  const colorRanges = GenerateGeom.colorRanges
  return (
    <div className="Group">
        <div className={"SubTitle"}>
        {layerInfo.styling.colorByProperty}
        </div>
      
      <LegendScale
        allowEdit={
          SettingsManager.getSystemSetting("allowEditingOfDivisions") &&
          !layerInfo.readonly
        }
        colorRanges={colorRanges}
        style={layerInfo.styling.colorByValue!}
        callBackOnStyleChange={(newStyle) => {
          onColorByValueStyling(layerInfo, newStyle);
        }}
        dataValues={layerInfo.geoJson.features.map(
          (a) => a.properties[layerInfo.styling.colorByProperty || ""]
        )}
        otherValue={layerInfo.styling.outsideColorRange}
        setOtherValue={(e) => {
          onStyling(layerInfo, {outsideColorRange: e});
        }}
        showElementCounts={Boolean(layerInfo.styling.showElementCounts)}
      />
    </div>
  );
}


function getLayerInSorted(layers, layerHiearchy) {
  if (layerHiearchy) {
    return OldHierarchy.getAllIdOrderedTopBottom(layerHiearchy)
      .map((a) => layers.find((b) => b.layerId === a))
      .filter((a) => a) as LayerInfo[];
  }
  return layers.sort(MapitUtils.sortByLayerId);
}


function LayerLegend(props:{layerInfo:LayerInfo,
  onStyleChange,
  onSimpleStyling,
}) {
  const layerInfo = props.layerInfo
  if (layerInfo.visible) {
    let name = layerInfo.datasetname;

    return (
      <div className="Group">
          <div className="Line">
          <div className="TitleIcon" ><LayerLegendIcon layerInfo={layerInfo} /></div>
          <div className="Title">{name}</div>
          <div className="Count">
          <MitCount
            show={Boolean(layerInfo!.styling!.showElementCounts)}
            count={
              Boolean(layerInfo!.styling!.showElementCounts)
                ? MapitUtils.getElementCount(layerInfo)
                : 0
            }
          />
          </div>
          </div>
          {verticalColorLegend(layerInfo, true, props.onStyleChange, props.onSimpleStyling)}
          {/* {otherValueDataLegend(layerInfo, props.onSimpleStyling)} */}
          {verticalSizeLegend(layerInfo, true)}
          {areasWithNoDataLegend(layerInfo, props.onSimpleStyling)}
          {areaFillColorLegend(layerInfo, props.onSimpleStyling)}
      </div>
    );
  }
}

function LayerLegendIcon(props:{layerInfo:LayerInfo}) {
  switch (props.layerInfo.type) {
    case LayerType.Point:
    case LayerType.PointWGS84:
    case LayerType.PointUTM32:
    case LayerType.PointCadaster:
    case LayerType.GeoJSON_Point: //Only important Test (most of types are Deprecated)
      return <FaMapMarker color={props.layerInfo!.styling!.color} />
    case LayerType.Unknown: //Not Deprecated
    case LayerType.Area:
    case LayerType.GeoCode:
    case LayerType.GeoJSON:  //Not Deprecated
    case LayerType.GeoJSON_Polygon: // Not Deprecated
    case LayerType.GeoJSON_Line: // Not Deprecated
    case LayerType.AreaMunicipality_RO:
    case LayerType.AreaMunicipalityId_RO:
    case LayerType.AreaMunicipalityName_RO:
    case LayerType.AreaMunicipality_DK_And_SydSlesvig:
    case LayerType.AreaMunicipalityId_DK_And_SydSlesvig:
    case LayerType.AreaMunicipalityName_DK_And_SydSlesvig:
    case LayerType.AreaParish:
    case LayerType.AreaParishId:
    case LayerType.AreaParishName:
    case LayerType.Grid:
    case LayerType.AreaCadaster:
    case LayerType.AreaProperty:
    case LayerType.AreaMunicipality:
    case LayerType.AreaRegion:
    case LayerType.AreaZipcodes:
    case LayerType.AreaMunicipalityId:
    case LayerType.AreaRegionId:
    case LayerType.AreaZipcodesId:
    case LayerType.AreaMunicipalityName:
    case LayerType.AreaRegionName:
    case LayerType.AreaZipcodesName:
    case LayerType.AreaCountry:
    case LayerType.AreaCountryName:
    case LayerType.AreaCountryId:
      return <BsImage color={props.layerInfo!.styling!.color} />
  }
}