import { ADModalBody, ADModalInfo, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassInfo } from "./MitGlassComponents";
import { ProgressBar, Spinner } from "react-bootstrap";
import { MdError, MdInfo } from "react-icons/md";




export function InfoMessageModal(props:{message?, onDismiss}) {

   if (!props.message) {
      return null
   }

   return (
      <AdvancedDragModal
      PosDefault={{left:"50%", top:"150px","transform":"translateX(-50%)"}}
      title={<><MdInfo style={{marginRight:"1ex"}} />Info</>}
      variant={["Info","NoMinHeight"]}
      onClose={props.onDismiss}
      >
         <ADModalInfo>
      {props.message}
         </ADModalInfo>
         <div style={{height:"15px"}}></div>
      </AdvancedDragModal>
   )
}

export function ErrorMessageModal(props:{message, onDismiss}) {

   if (!props.message) {
      return null
   }


   return (
      <AdvancedDragModal
      PosDefault={{left:"50%", top:"150px","transform":"translateX(-50%)"}}
      title={<><MdError style={{marginRight:"1ex"}} />Error</>}
      variant={["Info","NoMinHeight"]}
      onClose={props.onDismiss}
      >
         <ADModalInfo>
            {props.message}
         </ADModalInfo>
         <div style={{height:"15px"}}></div>
      </AdvancedDragModal>
   )
}

export function LoadingScreen(props:{message?, percentComplete}) {

   if (!props.message) {
      return null
   }

   return (
      <AdvancedDragModal
      PosDefault={{left:"50%",top:"150px","transform":"translateX(-50%)",width:"max(30%,200px)"}}
      variant={["Info","NoMinHeight"]}
      title={"Loading"}
      >
         <ADModalInfo>
         {props.message}
         </ADModalInfo>
         { props.percentComplete > 0 ?
               (<ProgressBar animated={true} now={props.percentComplete} style={{borderRadius:"0px"}} />)
            :
               (<center style={{marginTop:"12px"}}><Spinner size="sm" animation="border" variant="success" /></center>)
         }
         <div style={{height:"15px"}}></div>
      </AdvancedDragModal>
   )


}