/**
 * This dialog is used to display a URL link - which is used to open a map in read only mode.
 * Users will copy the link from a text area and e.g. send by email to recipients.
 */
import {Button, Nav, Modal, FormGroup, Form, FormControl, Alert } from 'react-bootstrap';

import {Localization} from "@viamap/viamap2-common";
import { useState } from 'react';
import { ADModalBody, ADModalBodyNoScroll, ADModalFooter, ADModalInfo, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { GlassButton, GlassButtonRow } from './GlassButtons';
import { GlassInfo } from './MitGlassComponents';
import { message } from './Message';

type Props= {
    showWindow:boolean;
    url:string;
    embedCode:string;
    expireDate?:Date;
    callbackOnCancel: ()=>void;
};

export function ReadOnlyMapDialog(props:Props) {
    const [showEmbedCode,setShowEmbedCode] = useState(false)

    function handleClose(e:any) {
        // hide this window
        e && e.preventDefault();
        props.callbackOnCancel();
    }

    function onSubmit(e:any) {
        e && e.preventDefault();
    }

    function handleCopyToClipboard(e:any) {
        /* Get the text field */
        let copyText = document.getElementById("mit-url-text-area") as HTMLDivElement;
        
        if (copyText) {
            copyText.innerText;

            navigator.clipboard.writeText(copyText.innerText).then(() => {
                message.info("Copied to clipboard")
            });


        } else {
//                alert("Field not found");
        }
    }

    function handleShowEmbedCode(showEmbedCode:boolean) {
        setShowEmbedCode(showEmbedCode)
    }

    if (!props.showWindow) {
        return null
    }


            const copytoclipboardbutton = <GlassButton onClick={(e)=>handleCopyToClipboard(e)}>{Localization.getText("Copy to Clipboard")}</GlassButton>;
            return(
                <AdvancedDragModal
                    PosDefault={{top:"20%",left:"50%", width:"460px", transform:"translateX(-50%)"}}
                    title={Localization.getText("Readonly Link has been Saved")}
                    onClose={handleClose}
                >
                <ADModalInfo>
                    {Localization.getText("Expires")} {props?.expireDate?.toLocaleDateString() || "ukendt"}
                </ADModalInfo>
                <ADModalBodyNoScroll templateRows={"5% 1fr 5%"}>
                    <div />
                    <div style={{marginTop:"20px"}} >
                    <GlassButtonRow>
                        <GlassButton 
                            className={showEmbedCode ? "EmbedCodebutton" : "EmbedCodeActive"}
                            onClick={() => {handleShowEmbedCode(false);}}

                        >
                            Link
                        </GlassButton>
                        <GlassButton 
                            className={showEmbedCode ? "EmbedCodeActive" : "EmbedCodebutton"}
                            onClick={() => {handleShowEmbedCode(true);}}

                        >
                            Embed Code
                        </GlassButton>
                    </GlassButtonRow>
                    <div id="mit-url-text-area" className='EmbedCode'>{showEmbedCode ? props.embedCode : props.url}</div>
                </div>
            </ADModalBodyNoScroll>
            <ADModalFooter>
                {copytoclipboardbutton}
            </ADModalFooter>
            </AdvancedDragModal>)
}

export function SavedMapDialog(props: {showWindow, refLink, callbackOnCancel, expireDate}) {
    const [call] = useState(location.origin + location.pathname)

    return (<ReadOnlyMapDialog
        showWindow={props.showWindow}
        url={call + "?mapdef=" + props.refLink}
        embedCode={"<iframe src=\"" + call + "?mapdef=" + props.refLink + "&embeddedmode=true\" width=\"350\" height=\"300\"></iframe>"}
        expireDate={props.expireDate}
        callbackOnCancel={props.callbackOnCancel} />)
}