import { Localization, SettingsManager } from "@viamap/viamap2-common";
import { Utils } from "@viamap/viamap2-common";
import { ADModalBody, ADModalFooter, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassButton } from "./GlassButtons";

type Props = {
  showWindow: number;
  onFormClose: () => void;
};

export function InfoScreen(props: Props) {
  function onClickClose() {
    props.onFormClose();
  }
  if (props.showWindow) {
    let contactInfo = SettingsManager.getSystemSetting("contactInformation");
    let linkToManual = contactInfo.linkToManual || "";
    let productInformationPage = contactInfo.productInformationPage || "";
    let contactPerson = contactInfo.contactPerson || "";
    let contactPhone = contactInfo.contactPhone || "";
    let contactEmail = contactInfo.contactEmail || "";
    let bodyTextWithPlaceHolders = Localization.getText(
      "InfoScreen:InfoTextHTML"
    );
    let bodyText = Utils.formatString(bodyTextWithPlaceHolders, {
      linkToManual,
      productInformationPage,
      contactPerson,
      contactPhone,
      contactEmail,
    });
    return (
        <AdvancedDragModal 
          variant={"NSWhite"}
          PosDefault={{left:"50%", top: "100px", width:"550px", height:"calc( 100% - 200px )", transform:"translateX(-50%)" }}
          title={Localization.getText("Info Page")}
          onClose={() => onClickClose()}
        >
          <ADModalBody>
            {Localization.getLanguage() === "da" ? 
            // Danish
              <>
              <p>
              Mapit er udviklet i tæt samarbejde mellem Viamap og Mølbak, og er en softwareplatform, vi selv benytter i vores daglige arbejdsfunktioner. 
              </p>
              <p>
              Der kan hentes hjælp i '<a href={linkToManual} target="_blank">Kom godt i gang</a>'
              </p>
              <h5>Databeskyttelse</h5>
              <p>
              Ingen af dine data gemmes på vores servere eller deles med andre. Alle dine data slettes, når du lukker browseren ned.
Vi gemmer cookies, logs og fejlmeddelelser udelukkende til intern brug, for at forbedre produktet.
              </p>
              <p>
              Ved brug af Mapit, accepteres dette.
              </p>
            
              </>
            : 
            // English
              <>
              <p>
              Mapit is developed in close collaboration between Viamap and Mølbak, and is a software platform we use ourselves in our daily work functions.
              </p>
              <p>
              Help can be found in '<a href={linkToManual} target="_blank">Getting Started</a>'
              </p>
              <h6>Data protection</h6>
              <p>
              None of your data is stored on our servers or shared with others. All your data is deleted when you close the browser.
              We store cookies, logs and error messages solely for internal use, to improve the product.
              </p>
              <p>
              By using Mapit, this is accepted.
              </p>
              </>
            }
          </ADModalBody>

          <ADModalFooter>
            <div id="mit-info-screen-version" style={{marginRight:"auto", color:"var(--viamap-green)"}}>
              {Utils.productNameAndVersion(process.env.REACT_APP_ENVIRONMENT)}
            </div>
            <GlassButton onClick={() => onClickClose()}>
              {Localization.getText("Close")}
            </GlassButton>
          </ADModalFooter>
        </AdvancedDragModal>
    );
  } else {
    return null;
  }
}
