import { Col, Row, Alert, FormGroup, Form, FormControl, Badge } from 'react-bootstrap';

import { Localization, SessionContext, SettingsManager, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import { Utils } from '@viamap/viamap2-common';
import { MapInfo,  DeploymentMode, MapExportImportFormat, ActiveFeatureLayerSpecs } from '../common/managers/Types';
import { AWSAPIGatewayInterface } from 'src/managers/AWSAPIGatewayInterface';
import { AWSUserAdmin, AWSUserInfo } from 'src/common/managers/AWSUserAdmin';
import AWS from 'aws-sdk';
import { useContext, useEffect, useState } from 'react';
import { UserSession } from '@viamap/viamap2-common/dist/managers/Types';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';
import { MapitStateContext } from 'src/states/MapitState';
import { FeatureLayerList } from 'src/managers/WmsLayerFunc';
import { BsChevronRight } from 'react-icons/bs';
import { FeatureLayers } from './GroupedLayerControl';
import { ADModalBody, ADModalFooter, ADModalInfo, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { GlassButton } from './GlassButtons';
import { GlassCheckbox, GlassInfo } from './MitGlassComponents';
import { GlassFoldUdBox } from 'src/propertyInfoTemplates/PropertyComponents';
import { GlassInputGroup, GlassTextInputTransformer } from './GlassInput';

enum ValidityState {
    Success = "success",
    Warning = "warning",
    Error = "error",
} 

type State = {
    mapInfo: MapInfo;
    showWindow: number;
    linkDurationText: string;
    minZoom: number;
    maxZoom: number;
    currentZoomLevel?: number;
    backgroundLayers: {};
    sessionInfo: UserSession;
    activeFeatureLayerKeys: any[];
    oldLinkOverwrite: boolean;
    oldLinkName: string;
    oldLinkNameMessage?: string;
    oldLinkNameValidationState?: ValidityState | null;
};

type Props = {
    mapInfo: MapInfo;
    showWindow: number;
    linkDuration: number;
    currentZoomLevel?: number;
    backgroundLayers: {};
    callbackOnCancel: () => void;
    callbackOnCommit: (mapInfo: MapInfo, linkDuration: number, minZoom: number, maxZoom: number, activeFeatureLayerKeys: any[], overWriteExstingLinkFile:boolean, oldLinkName?:string) => void;
};

export function SaveMapLinkDialog(props:Props) {
    const [state, _setState] = useState<State>(setFromProps());
    const {state:sessionState} = useContext(SessionContext);
    const {state:mapitState, dispatch: mapitDispatch} = useContext(MapitStateContext);
    const { state: applicationState, dispatch: applicationDispatch, hasAccessToFeature } = useContext(ApplicationStateContext);
    const [activeFeatureLayerKeys, setActiveFeatureLayerKeys] = useState<string[]>(mapitState.selectedFeatureLayerKeys || [])

    function setState(b: any) {
        _setState((a) => ({...a, ...b}))
    }


    useEffect(() => {
        _setState(setFromProps());
    },[props])

    function setFromProps() : State {
        return {
            mapInfo: props.mapInfo,
            showWindow: props.showWindow,
            linkDurationText: props.linkDuration + "",
            currentZoomLevel: props.currentZoomLevel,
            backgroundLayers: props.backgroundLayers,
            sessionInfo: undefined as unknown as UserSession, // ToDo: still used?
            activeFeatureLayerKeys: [],
            minZoom: SettingsManager.getSystemSetting("minZoom", 18),
            maxZoom: SettingsManager.getSystemSetting("maxZoom", 18),
            oldLinkName:"",
            oldLinkNameValidationState: ValidityState.Error,
            oldLinkOverwrite: false,
            oldLinkNameMessage: Localization.getText("Savemap:oldLinkFormatHelp")
        }
    }

    /**
     * Creates a dropdown menu with options in the provided numerical interval.
     * @param min Minimum numerical value.
     * @param max Maximum numerical value.
     * @param defaultZoomLevel The initial default value to show.
     * @param onChange Function to run when a value in the dropdown menu is changed.
     * @returns A dropdown menu as JSX.Element.
     */
    function createDropdownMenuInInterval(min: number, max: number, defaultZoomLevel: number, onChange: (event: any) => void, getValue:()=>number): JSX.Element {
        let options: any[] = [];
        for (let index = max; index >= min; index--) {
            let text = "";
            switch (index) {
                case defaultZoomLevel:
                    if (defaultZoomLevel === state?.currentZoomLevel) {
                        text = " (Default and current)";
                    } else {
                        text = " (Default)";
                    }
                    options.push(<option key={index+text} value={index}>{index + text}</option>);
                    break;
                case state.currentZoomLevel:
                    text = " (Current)";
                    options.push(<option key={index+text} value={index}>{index + text}</option>);
                    break;
                default:
                    options.push(<option key={index+text} value={index}>{index}</option>);
                    break;
            }
        }
        return (
            <select
                className="form-control"
                id="numerical-dropdown"
                onChange={onChange}
                value={getValue()}
            >
                {options}
            </select>
        );
    }

    function nameOnChange(event: any) {
        setState({mapInfo: {...state?.mapInfo, mapTitle: event.target.value}} );
    } 
    function onLinkOverwriteChange(e:any) {
        setState({oldLinkOverwrite: e.target.checked});
    }

    function getUserZ(region:string, userPoolId:string, userId:string):Promise<AWSUserInfo> {
        let result;
        let cognitoidentityserviceprovider = new AWS.CognitoIdentityServiceProvider();
        let params = {
           UserPoolId: userPoolId, /* required */
           Username: userId /* required */
           };
        let creds = AWS.config.credentials;
        console.info(creds ? creds.sessionToken : "creds is null" );
  
        return new Promise((resolve,reject) => {
           cognitoidentityserviceprovider.adminGetUser(params, function(err:any, data:any) {
           if (err) {
                 // an error occurred
                 console.error(err, err.stack);
                 reject(err.message);
           } else {
                 resolve(data);
           }
           });
        });
    }
    
    function copyActiveFeatureLayerKeySelection(activeFeatureLayerSpecs:ActiveFeatureLayerSpecs) {
        if (SettingsManager.getSystemSetting("showLayerTableSaveMapLink", false) && 
            activeFeatureLayerSpecs && 
            Object.keys(activeFeatureLayerSpecs).length > 0) {

            let featureLayers: FeatureLayerList = mapitState.featureLayers;
            let featureLayerKeys = {};
            featureLayers && Object.keys(featureLayers).forEach((key, idx) => {
                let ftl = featureLayers[key].layer;
                // First check for access rights and any isActive() logic.
                let label = ftl.translationTable ? Localization.getTextSpecificTable(ftl.label, ftl.translationTable) : ftl.label;
                featureLayerKeys[key] = label;
            });
    
            let selectionState: {} = {};
            Object.keys(featureLayerKeys).forEach((val, idx) => {
                if (Object.keys(activeFeatureLayerSpecs).includes(val)) {
                    selectionState[idx] = val;
                }
            });
            setActiveFeatureLayerKeys(Object.values(selectionState))
        }
    }
    
    function oldLinkNameOnChange(ev: any) {
        let val=ev.target.value;
        setState({ oldLinkName: val});
        let msg="";
        let vState = ValidityState.Success;

        if (val.length !== 36) {
            msg=Localization.getText("Savemap:oldLinkFormatHelp");
            vState= ValidityState.Error;
            setState({oldLinkNameMessage:msg, oldLinkNameValidationState:vState} );
        } else {
            // Read old link to se if we can safely overwrite it.
            let path= SettingsManager.getSystemSetting("mapLinkOnS3Path", "savedmaps/");
            AWSAPIGatewayInterface.loadStateS3(path+val)
            .then((result:MapExportImportFormat) => {
                if (sessionState.userRef !==  result.userId) {
                    // The map was saved by another user.
                    
                    // If allowed and not development mode lookup user to check if the organization is identical
                    if (
                        SettingsManager.getSystemSetting("allowMapLinkOverwriteInSameOrganization", true)
                        && Utils.getDeploymentMode() !== DeploymentMode.Development
                    ) {
                        // Check which user created it. In same organization?
                        let auth = SettingsManager.getSystemSetting("authData");
                        let userPoolId = auth && auth.UserPoolId;
                        result.userId && AWSUserAdmin.getUser(userPoolId, result.userId)
                        .then((userInfo) => {
                            let userEmailRec = userInfo.UserAttributes.find((item:{Name:any,Value:any}) => {
                                return item.Name === "email";
                            });
                            if (userEmailRec) {
                                let userEmail = userEmailRec.Value;
                                let savedLinkOrganization = ViamapPersistenceLayer.extractCustomerRef(userEmail);
                                let myOrganization = ViamapPersistenceLayer.extractCustomerRef(sessionState.userRef);
                                if (savedLinkOrganization === myOrganization) {
                                    let displayTime = (result.timeGenerated as Date).toLocaleDateString();
                                    let displayEndDate = result.endDate ? (result.endDate as Date).toLocaleDateString() : "";
                                    msg=Localization.getFormattedText("Savemap:oldLinkOtherUserInSameOrg",{time:displayTime, userName:result.userName, expires:displayEndDate});
                                    vState= ValidityState.Warning;

                                    setState({oldLinkNameMessage:msg, oldLinkNameValidationState:vState} );
                                    // Copy settings from saved link as default for changed link
                                    result.activeFeatureLayerSpecs && copyActiveFeatureLayerKeySelection(result.activeFeatureLayerSpecs);
                                } else {
                                    // user from a different organization
                                    msg=Localization.getText("Savemap:oldLinkOtherUserInDifferentOrg");
                                    vState= ValidityState.Error;
                                    setState({oldLinkNameMessage:msg, oldLinkNameValidationState:vState} );
                                }
                            } else {
                                // could not find email for user
                                msg=Localization.getText("Savemap:oldLinkOtherUser");
                                vState= ValidityState.Error;
                                setState({oldLinkNameMessage:msg, oldLinkNameValidationState:vState} );
                            }
                        })
                        .catch((err3) => {
                            msg=err3;
                            vState= ValidityState.Error;
                            setState({oldLinkNameMessage:msg, oldLinkNameValidationState:vState} );
                        });
                    } else {
                        msg=Localization.getText("Savemap:oldLinkOtherUser");
                        vState= ValidityState.Error;      
                        setState({oldLinkNameMessage:msg, oldLinkNameValidationState:vState} );
                    }
                } else {
                    let displayTime = (result.timeGenerated as Date).toLocaleDateString();
                    let displayEndDate = result.endDate ? (result.endDate as Date).toLocaleDateString() : "";
                    msg=Localization.getFormattedText("Savemap:oldLinkOk",{time:displayTime, userName:result.userName, expires:displayEndDate});
                    vState= ValidityState.Success;      
                    nameOnChange({target:{value: result.mapInfo.mapTitle}})
                    result.endDate && numberOnChange({target:{value: Math.round((result.endDate.getTime() - new Date().getTime())/(1000*60*60*24))}})
                    setState({
                        oldLinkNameMessage:msg, 
                        oldLinkNameValidationState:vState
                    });
                    // Copy settings from saved link as default for new changed link
                    result.activeFeatureLayerSpecs && copyActiveFeatureLayerKeySelection(result.activeFeatureLayerSpecs);
                }
            })
            .catch((err1) => {
                msg=Localization.getText("Savemap:oldLinkNotFound");
                vState= ValidityState.Error;      
                setState({oldLinkNameMessage:msg, oldLinkNameValidationState:vState} );
            });
        }
    }

    function numberOnChange(ev: any) {
        setState({ linkDurationText: ev.target.value });
    }

    function maxZoomOnChange(ev: any) {
        setState({ maxZoom: parseInt(ev.target.value, 10) });
    }

    function minZoomOnChange(ev: any) {
        setState({ minZoom: parseInt(ev.target.value, 10) });
    }

    function onFormSubmit(e: any) {
        // hide this window
        e && e.preventDefault();
        let val = Utils.parseNumber(state.linkDurationText + "");
        props.callbackOnCommit(state.mapInfo, val || 0, state.minZoom, state.maxZoom, activeFeatureLayerKeys, state.oldLinkOverwrite, state.oldLinkName);
    }
    
    function validateFileName(): "success" | "warning" | "error" | null | undefined {
        return state.mapInfo.mapTitle && state.mapInfo.mapTitle.length > 0 ? "success" : "error";
    }

    function validateForm():boolean { 
        let validationState = state.oldLinkOverwrite ? state.oldLinkNameValidationState : "success"; 
        validationState = validationState === "success" ? validateFileName() : validationState; 
        return validationState === "success" || validationState === "warning";
    }

    function handleCancel(e: any) {
        // hide this window
        e && e.preventDefault();
        props.callbackOnCancel();
    }

    function onFeatureLayerChange(key: string, checked: boolean, layersKeys: {key:string}[]) {
        if (checked) {
            setActiveFeatureLayerKeys(pre => ([...new Set([...pre, ...layersKeys.map((a) => a.key)])]));
        } else {
            let keys = layersKeys.map((a) => a.key);
            setActiveFeatureLayerKeys(pre => (pre.filter((a) => !keys.includes(a))));
        }
     }

    function countOfSelectedLayers():number {
        return activeFeatureLayerKeys ? activeFeatureLayerKeys.filter((key) => !mapitState.featureLayers[key].layer?.privateUseOnly).filter((key) => Boolean(key)).length : 0;
    }


        if (props.showWindow) {
            const zoomLevels = hasAccessToFeature(Feature.SaveLinkSetRestrictions) ? (
                <>
                    <RowSimplifier label={Localization.getText("Max zoom level")}>
                        {createDropdownMenuInInterval(1, 23, SettingsManager.getSystemSetting("maxZoom", 18), maxZoomOnChange, ()=> state.maxZoom)}
                    </RowSimplifier>
                    <RowSimplifier label={Localization.getText("Min zoom level")}>
                        {createDropdownMenuInInterval(1, 23, SettingsManager.getSystemSetting("minZoom", 3), minZoomOnChange, ()=> state.minZoom)}
                    </RowSimplifier>
                </>
            ) : null;



            const selectLayersToShare = hasAccessToFeature(Feature.SaveLinkSelectFeatureLayers) ? (
                <RowSimplifier label={<>
                    {Localization.getText("Give access to feature layers")}
                    <br/>
                    <Badge style={{borderRadius:0}} bg="success">
                        {countOfSelectedLayers() > 0 ? Localization.getFormattedText("Savemap:noOfLayersSelected", {count:countOfSelectedLayers()}) : null} 
                    </Badge></>}>
                    {activeFeatureLayerKeys.filter((key) => mapitState.featureLayers[key].layer?.privateUseOnly).length ?
                        <Badge style={{borderRadius:0, width:"100%", fontWeight:"200"}} bg="danger" >
                            {Localization.getText("Following active layers can't be shared;")}<br/>
                            {activeFeatureLayerKeys.filter((key) => mapitState.featureLayers[key].layer?.privateUseOnly).map((key) => {
                                let text = mapitState.featureLayers[key].layer.label;
                                let trText = mapitState.featureLayers[key].layer.translationTable?.[Localization.getLanguage()]?.[text] || text;
                                return trText
                            })}
                        </Badge>
                        : <></>
                    }
                    <div style={{ overflowY: "auto", overflowX: "hidden", flex:1, maxHeight: "200px"}}>
                    <FeatureLayers 
                        maxLayersToActivate={Number.MAX_SAFE_INTEGER}
                        layers={mapitState.featureLayers}
                        onChange={(a,b,c) => onFeatureLayerChange(a,b,c)}
                        selectedFeatureLayerKeys={activeFeatureLayerKeys}
                        />
                    </div>
                </RowSimplifier>
            ) : undefined;

            const overWriteExstingLinkFile = hasAccessToFeature(Feature.SaveLinkReuseMapLink) ? (
                <RowSimplifier label={Localization.getText("Reuse/Overwrite existing link")}>
                    <div style={{display:"flex", flexDirection:"column"}}>
                    <GlassCheckbox style={{textAlign:"left", marginBottom:"5px"}} checked={state.oldLinkOverwrite} onClick={(e:any) => onLinkOverwriteChange(e)} >
                        {/* {Localization.getText("Savemap:overwrite link")} */}
                    </GlassCheckbox>
                    {state.oldLinkOverwrite ? (
                        <FormGroup
                            controlId="formBasicText"
                            // validationState={this.getOldLinkValidationState()} TODO:
                            
                            style={{margin:"0px", marginTop:"10px"}}
                        >
                            <FormControl
                                style={{width:"100%"}}
                                size="sm"
                                type="text"
                                value={state.oldLinkName}
                                autoComplete="off"
                                placeholder={Localization.getText("Savemap:oldLinkName")}
                                onChange={(e) => oldLinkNameOnChange(e)}
                                />
                        <GlassInfo>{state.oldLinkNameMessage}</GlassInfo>
                        </FormGroup>
                    ) : null}
                    </div>
                </RowSimplifier>
                ) : undefined;

            return (
                <AdvancedDragModal
                    variant={"NSDark"}
                    PosDefault={{top:"100px", left:"50%", transform:"translateX(-50%)"}}
                    title={Localization.getText("Generate map link")}
                    
                >
                    <ADModalInfo>
                    {Localization.getText("Savemaplink:description")}
                    </ADModalInfo>

                        <ADModalBody >
                            <GlassInputGroup
                                onEscape={(e) => handleCancel(e)}
                                autoFocus={0}
                            >
                                <RowSimplifier label={Localization.getText("File name")}>
                                    <FormControl
                                        style={{width:"100%"}}
                                        size="sm"
                                        type="text"
                                        value={state.mapInfo.mapTitle || ""}
                                        autoComplete='off'
                                        placeholder={Localization.getText("Savemap:defaultFileName")}
                                        onChange={(e) => nameOnChange(e)}
                                        />
                                </RowSimplifier>
                                    
                                    <RowSimplifier label={Localization.getText("Link Duration")}>
                                        <select
                                            className="form-control"
                                            id="sel1"
                                            onChange={(e) => { numberOnChange(e); }}
                                            value={state.linkDurationText}
                                        >
                                            <option value="1">1 {Localization.getText("day")}</option>
                                            <option value="7">1 {Localization.getText("week")}</option>
                                            <option value="30">1 {Localization.getText("month")}</option>
                                            <option value="60">2 {Localization.getText("months")}</option>
                                            <option value="90">3 {Localization.getText("months")}</option>
                                            <option value="182">6 {Localization.getText("months")}</option>
                                            <option value="365">12 {Localization.getText("months")}</option>
                                            {["1","7","30","60","90","182","365"].includes(state.linkDurationText) ? <></> :
                                                <option value={state.linkDurationText}>{state.linkDurationText} {Localization.getText("days")}</option>
                                            }
                                        </select>
                                    </RowSimplifier>
                                    </GlassInputGroup>
                                    { hasAccessToFeature(Feature.SaveLinkSetRestrictions) || hasAccessToFeature(Feature.SaveLinkSelectFeatureLayers) || hasAccessToFeature(Feature.SaveLinkReuseMapLink) ?
                                    <GlassFoldUdBox
                                        title={Localization.getText("Expander:AdvancedSettings")}
                                        foldetUd={false}
                                        variant='Small'
                                    >
                                        <GlassInputGroup>
                                        {overWriteExstingLinkFile}
                                        {zoomLevels}
                                        {selectLayersToShare}
                                        </GlassInputGroup>
                                    </GlassFoldUdBox>
                                    : null }
                                
                        </ADModalBody>
                        <ADModalFooter>
                        <GlassButton onClick={(e) => onFormSubmit(e)} disabled={!validateForm()} >{Localization.getText("button:GenerateLink")}</GlassButton>
                        <GlassButton onClick={(e) => handleCancel(e)} >{Localization.getText("Cancel")}</GlassButton>
            </ADModalFooter>
            </AdvancedDragModal>
            );
        } else {
            return (null);
        }

}


function RowSimplifier({label, children}) {
    return <GlassTextInputTransformer label={label} >{children}</GlassTextInputTransformer>


    return (<Row style={{marginBottom:"5px"}}>
        <Col style={{textAlign:"right", minHeight:"30px"}} as={Form.Label} sm={4} lg={4}>{label}</Col>
        <Col sm={8} lg={8}>{children}</Col>
    </Row>)
}