import {ObliqueMatrixFunc} from './ObliqueMatrixFunc';
import {ObliqueCache} from './ObliqueCache';


// export type ObliqueFootprint = {
//   fp:number[][], 
//   center:number[], 
//   imageId:string, 
//   kappa:number,
//   folder:string,
//   json?: any
// };

export type UtmCoords = {x:number, y:number};
export type UtmCoords3 = {x:number, y:number, z:number};
export enum ViewDirection {NORTH="N", EAST="E", SOUTH="S", WEST="W", TOP="T"}
export type ObliquePath = {hostName:string, path:string, fullPath:string};
export type CameraCalibration = {
  xx0: number,
  yy0: number,
  c: number,
  pix: number,
  dimX: number,
  dimY: number,
  image_format_x: number,
  image_format_y: number,
  mount_rotation?: number
};
export type FootPrintCamaraData = {
  x: number,
  y: number,
  z: number,
  omega: number,
  phi: number,
  kappa: number,
  pictureDate: Date
};

export type ObliqueFootprint2 = {
  center:number[], 
  imageId:string, 
  folder:string,
  cc: CameraCalibration,
  fp: FootPrintCamaraData
};


export type LoadedImageSpec= {
  image: HTMLImageElement;
  height: number;
  width: number;
  x?: number;
  y?:number;
};

export type ImageMetaData = {
  pictureDate?: string,
  xCoord?: string,
  yCoord?: string,
  zCoord?: string,
  block?: string,
  path?: string,
  center?:string,
  zoomFactor?:string
};

export abstract class HostSpecificFunctions {
  getFootprintAndCameraDataByImageId?:(imageId:string) => Promise<ObliqueFootprint2>;
  getRawFootprintRecords?: (coords:UtmCoords, direction:ViewDirection, maxRows?:number) => Promise<any[]>;
  // getBestFootprintFromDatabase:(coords:UtmCoords, direction:string) => Promise<ObliqueFootprint2>,
  centerImageFunc?:(      
    totalWidth:number , 
    totalHeight:number ,
    resultingMatrixSize:number[] ,
    imagesToBeCombined:LoadedImageSpec[][] , 
    cropX:number , 
    cropY:number , 
    width:number , 
    height:number 
  ) => Promise<Buffer>;
}

export class Debuglog {
  static log(text:any) {
    // tslint:disable-next-line
    if (ObliqueFunc.debugMode) {
      console.log(""+text);
    }
  }
}

export class Timer {
  private static _timeLogs:{[key:string]:number}={};

  /* TIMER FUNC FOR DEBUGGING */
  static time(key:string) {
    Debuglog.log("Start timer: "+key);
    let startTime = Date.now();
    this._timeLogs[key]=startTime;
  }

  static timeEnd(key:string) {
    let startTime = this._timeLogs[key];
    if (startTime) {
      let endTime = Date.now();
      let timeParsed = endTime - startTime;
      Debuglog.log("Time("+key+"): "+timeParsed);
    } else {
      Debuglog.log("Timer("+key+"): not found");
    }
  }
}

export class ObliqueFunc {
  public static readonly minZoom=10;
  public static readonly maxZoom=14;
  public static readonly defaultZoom=13;
  static debugMode:boolean= false;
  static useCaching:boolean = true;
  static readonly maxFootprintRetries=4;
  static ImageSizeCache:{[imageId:string]:LoadedImageSpec} = {};

  static setDebugMode(mode:boolean) {
    ObliqueFunc.debugMode = mode;
    console.info("SetDebugMode: "+mode);
  }

  static convertStringToViewDirection(direction:string): ViewDirection {
    switch(direction) {
      case "E": return ViewDirection.EAST;
      case "W": return ViewDirection.WEST;
      case "N": return ViewDirection.NORTH;
      case "S": return ViewDirection.SOUTH;
      case "T": return ViewDirection.TOP;
      default:
        throw new Error("Unknown ViewDirection: "+direction);
    }
  }

  static rayVerse(cc: CameraCalibration, cd:FootPrintCamaraData, X:number, Y:number, Z:number):number[] {
    // Converts from degrees to radians.
    function radians(degrees:number) {
      return degrees * Math.PI / 180;
    }

    let xx0 = cc.xx0;
    let yy0 = cc.yy0;
    let c = cc.c;
    let pix = cc.pix;
    let dimX = cc.dimX;
    let dimY = cc.dimY;
    let X0 = cd.x;
    let Y0 = cd.y;
    let Z0 = cd.z;

    let o = radians(cd.omega);
    let p = radians(cd.phi);
    let k = radians(cd.kappa);
    let D11 =   Math.cos(p) * Math.cos(k);
    let D12 = - Math.cos(p) * Math.sin(k);
    let D13 =   Math.sin(p);
    let D21 =   Math.cos(o) * Math.sin(k) + Math.sin(o) * Math.sin(p) * Math.cos(k);
    let D22 =   Math.cos(o) * Math.cos(k) - Math.sin(o) * Math.sin(p) * Math.sin(k);
    let D23 = - Math.sin(o) * Math.cos(p);
    let D31 =   Math.sin(o) * Math.sin(k) - Math.cos(o) * Math.sin(p) * Math.cos(k);
    let D32 =   Math.sin(o) * Math.cos(k) + Math.cos(o) * Math.sin(p) * Math.sin(k);
    let D33 =   Math.cos(o) * Math.cos(p);

    let xDot = (-1)*c*((D11*(X-X0)+D21*(Y-Y0)+D31*(Z-Z0))/(D13*(X-X0)+D23*(Y-Y0)+D33*(Z-Z0)));
    let yDot = (-1)*c*((D12*(X-X0)+D22*(Y-Y0)+D32*(Z-Z0))/(D13*(X-X0)+D23*(Y-Y0)+D33*(Z-Z0)));

    let col = ((xDot-xx0)+(dimX))*(-1)/pix;
    let row = ((yDot-yy0)+(dimY))*(-1)/pix;

    return [cc.image_format_x-col,row];
  }

  static async centerImage(
    centerImageFunc:(      
      totalWidth:number , 
      totalHeight:number ,
      resultingMatrixSize:number[] ,
      imagesToBeCombined:LoadedImageSpec[][] , 
      cropX:number , 
      cropY:number , 
      width:number , 
      height:number
    ) => Promise<Buffer>,
    x:number, 
    y:number, 
    width:number, 
    height:number, 
    fp:ObliqueFootprint2, 
    zoomFactor:number, 
    tileX:number, 
    tileY:number, 
    maxTileX:number, 
    maxTileY:number, 
    img:LoadedImageSpec,
    imageMetaData: ImageMetaData
    ) {
    let resultingMatrixSize:number[]=[1,1];
    let targetImageCoordsInResultingMatrix:number[]=[0,0];
    const borderFraction=0.50;
    Debuglog.log("in center");
    Timer.time("center");
    if (x < width*borderFraction && tileX > 0) {
      resultingMatrixSize[0]=2;
      targetImageCoordsInResultingMatrix[0]=1;
    } else {
      if (x > width*(1-borderFraction) && tileX < maxTileX) {
        resultingMatrixSize[0]=2;
        targetImageCoordsInResultingMatrix[0]=0;  
      }
    }
    if (y < height*borderFraction && tileY > 0) {
      resultingMatrixSize[1]=2;
      targetImageCoordsInResultingMatrix[1]=1;
    } else {
      if (y > height*(1-borderFraction) && tileY < maxTileY) {
        resultingMatrixSize[1]=2;
        targetImageCoordsInResultingMatrix[1]=0;  
      }
    }
    Debuglog.log(resultingMatrixSize);
    Debuglog.log(targetImageCoordsInResultingMatrix);
    Timer.time("getImages");
    let imagesToBeCombined:LoadedImageSpec[][]=[[],[]];
    // load remaining images
    let totalWidth=0;
    let totalHeight=0;
    for (let tileOffsetX=0; tileOffsetX<resultingMatrixSize[0]; tileOffsetX++) {
      for (let tileOffsetY=0; tileOffsetY<resultingMatrixSize[1]; tileOffsetY++) {
        {
          let tX = tileX + tileOffsetX + (targetImageCoordsInResultingMatrix[0] === 0 ? 0 : -1);
          let tY = tileY + tileOffsetY + (targetImageCoordsInResultingMatrix[1] === 0 ? 0 : -1);
          let path2 = ObliqueFunc.createPath(fp.imageId, fp.folder, zoomFactor, tX, tY);
          let block = fp.imageId.substr(5,5);
          let imageMetaData2:ImageMetaData = {
            ...imageMetaData,
            path: path2,
          };
          let img2 = await ObliqueFunc.getAndSizeImageWithCache(path2, fp.imageId, zoomFactor, tX, tY, imageMetaData);
          imagesToBeCombined[tileOffsetX][tileOffsetY]=img2;
          totalWidth += img2.width;
          totalHeight += img2.height;
        }
      }
    }
    Timer.timeEnd("getImages");

    let newTargetCoordX = targetImageCoordsInResultingMatrix[0] === 0 ? x : x + imagesToBeCombined[0][targetImageCoordsInResultingMatrix[1]].width;
    let newTargetCoordY = targetImageCoordsInResultingMatrix[1] === 0 ? y : y + imagesToBeCombined[targetImageCoordsInResultingMatrix[0]][0].height;
    // calculate corner of area to crop
    let cropX = Math.max(0, newTargetCoordX - width/2);
    let cropY = Math.max(0, newTargetCoordY - height/2);
    // calculate new hight and width for cropped image
    width = Math.min(width,totalWidth-cropX);
    height =  Math.min(height,totalHeight-cropY);
    Debuglog.log("combining");
    let resImage = await centerImageFunc(totalWidth, totalHeight,resultingMatrixSize,imagesToBeCombined, cropX, cropY, width, height);
    newTargetCoordX -= cropX;
    newTargetCoordY -= cropY;
 
    return {resImage, newTargetCoordX, newTargetCoordY, width, height};
  }

  static isCloseToBorder(x:number, y:number, width:number, height:number):boolean {
    const borderFraction=0.25;
    return (
      x < width*borderFraction
      || x > width*(1-borderFraction)
      || y < height*borderFraction
      || y > height*(1-borderFraction)
    );
  }

  // static findBestFootprint(utmCoords:{x:number,y:number}, direction:string, candidateFootprints:ObliqueFootprint[]):ObliqueFootprint {

  //   let footPrintData = candidateFootprints;

  //   let minDistance=Number.MAX_SAFE_INTEGER;
  //   let found:ObliqueFootprint = {fp:[[0,0]], center:[0,0],imageId:"", folder:"", kappa:0.0};
  //   footPrintData.forEach(fp => {
  //     let distance = Math.sqrt(Math.pow(Math.abs(utmCoords.x - fp.center[0]),2)+Math.pow(Math.abs(utmCoords.y - fp.center[1]),2));
  //     if (distance < minDistance) {
  //       minDistance = distance;
  //       found = fp;
  //     }
  //   });
  //   return found;
  // }

  static createProjection(transformation:any, utmCoords:{x:number,y:number}, zCoord:number) {
      // Generate projection:
      let res= ObliqueMatrixFunc.matrixProjection(
        transformation,
        [utmCoords.x, utmCoords.y, zCoord]
      );
      return res;
  }

  static calculateTileOneDim(divSize: number, zoomFactor:number, pixCoord:number):{tile:number, pix:number} {
    console.assert(zoomFactor >= this.minZoom && zoomFactor <= this.maxZoom, "Illegal zoom factor");
    // scale 
    let pixScaleFactor = Math.pow(2,14-zoomFactor);
    let pix = pixCoord/pixScaleFactor;
    // find tile no (zerobased);
    let tile = Math.floor(pix / divSize);
    // find offset pix within tile
    let x = Math.floor(pix % divSize);

    // todo: error handling in case the object is out of the screen.
    // now just ignoring...
    x = Math.max(x,0);
    tile = Math.max(tile,0);

    return {tile:tile, pix:x};
  }

  static calculateTile(divSize: number, direction:string, zoomFactor:number, pixCoordX:number, pixCoordY:number) {
    console.assert(zoomFactor >= this.minZoom && zoomFactor <= this.maxZoom, "Illegal zoom factor");
    let resX = this.calculateTileOneDim(divSize, zoomFactor, pixCoordX);
    let resY = this.calculateTileOneDim(divSize, zoomFactor, pixCoordY);
    return { tileX:resX.tile, tileY: resY.tile, x:resX.pix, y:resY.pix};
  }

 /**
  * Formats a string by expanding any placeholders
  * example formatString("{name} bought {count} {fruit}", {name:"John", count:"2", fruit:"apples"})
  * @param input The text to be formatted
  * @param placeholders An object of form {placeholder:value, ...}
  * @returns The expanded string
  */ 
  static formatString(input:string, placeholders:object) {
  let s = input;
  for(let propertyName in placeholders) {
      if (placeholders.hasOwnProperty(propertyName)) {
      let re = new RegExp('{' + propertyName + '}', 'gm');
      s = s.replace(re, placeholders[propertyName]);
      }
  }    
  return s;
  }

  static createPath(imageId:string, folder:string, zoomFactor:number, tileX: number, tileY:number) {
    let imSplit = imageId.split("_");
    let block1 = imSplit[1];
    let block2 = imSplit[2];
    let type = "00"+imSplit[3];
    let sect = imSplit[4];
    let prefix = "https://skraafoto.kortforsyningen.dk/webprojects/denmark/obliques/";
    let zoom = zoomFactor || "this.minZoom";
    let x = tileX || "0";
    let y = tileY || "0";
    
    if (block1 === "83" && block2 === "30") {
      sect = "3"+sect.substr(1);
    }

    let path = this.formatString(
      "{prefix}{folder}/{type}/{sect}/{imageId}_files/{zoom}/{x}_{y}.jpg",
      {prefix:prefix, folder, type:type,sect:sect, imageId:imageId, zoom:zoom, x:x, y:y});
    
    return path;
  }

  static calcCenter(points:number[][]) {
    let count=points.length;
    let x = 0 , y = 0;
    points.forEach((p) => {
      let px = p[0];
      let py = p[1];
      x += px;
      y += py;
    });
    return [x/count, y/count];
  }

  static calcMinMax(points:number[][]) {
    let xMin = Number.MAX_SAFE_INTEGER , yMin = Number.MAX_SAFE_INTEGER;
    let xMax = 0 , yMax = 0;
    points.forEach((p) => {
      let px = p[0];
      let py = p[1];
      if (px < xMin) {
        xMin = px;
      }
      if (px > xMax) {
        xMax = px;
      }
      if (py < yMin) {
        yMin = py;
      }
      if (py > yMax) {
        yMax = py;
      }
    });
    return {xMin:xMin, xMax:xMax, yMin:yMin, yMax:yMax};
  }
 

  static ensureThreeDimmensions(points:number[][]):number[][] {
    // add z coodinate if not present.
    let result:number[][] = [];
    points.forEach((point,idx) => {
      if (point.length < 3) {
        point.push(0);
      }
      result.push(point);
    });
    return result;
  }

  static reorderPoints(points:number[][]):number[][] {
    // points sorted to be in order SouthWest, NorthWest, NorthEast, SouthEast
    let result:number[][] = [];
    let sortedByEasting:any[] = this.getCopyOfArray(points);
    sortedByEasting.sort((a:number[], b:number[]) => {return this.sortNumber(a[0], b[0]);});
    let westernPoints = [sortedByEasting[0], sortedByEasting[1]];
    if (westernPoints[0][1] < westernPoints[1][1]) {
      result.push(westernPoints[0]);
      result.push(westernPoints[1]);
    } else {
      result.push(westernPoints[1]);
      result.push(westernPoints[0]);
    }
    let easternPoints = [sortedByEasting[2], sortedByEasting[3]];
    if (easternPoints[0][1] > easternPoints[1][1]) {
      result.push(easternPoints[0]);
      result.push(easternPoints[1]);
    } else {
      result.push(easternPoints[1]);
      result.push(easternPoints[0]);
    }
    return result;
  }

  static sortNumber(a:number ,b:number):number {
    return a - b;
   }
   static getCopyOfArray(array:any[]):any[] {
    let result:any[] = [];
    array && array.map((value, index) => {
      result.push(value);
    } );
    return (result);
  }

  static checkForPointOrder(points:number[][]):boolean {
    // points expected to be in order SouthWest, NorthWest, NorthEast, SouthEast
    function check(test: boolean, message:string):boolean {
      if (test) {
        return true;
      } else {
        return false;
      }
    }
    let res = true;
    // 1: check easting
    let idx=0;
    res = res && check(points[0][idx] < points[2][idx],"check 1");
    res = res && check(points[0][idx] < points[3][idx],"check 2");
    res = res && check(points[1][idx] < points[2][idx],"check 3");
    res = res && check(points[1][idx] < points[3][idx],"check 4");
    // 2: check northing
    idx=1;
    res = res && check(points[0][idx] < points[1][idx],"check b1");
    res = res && check(points[0][idx] < points[2][idx],"check b2");
    res = res && check(points[3][idx] < points[1][idx],"check b3");
    res = res && check(points[3][idx] < points[2][idx],"check b4");
    return res;
  }

  static async getBestFootprintFromDatabaseX(
    getRawFootprintRecords: (coords:UtmCoords, direction:ViewDirection, maxRows?:number) => Promise<any[]>,
    utmCoords:{x:number,y:number}, 
    direction:ViewDirection):Promise<ObliqueFootprint2> {
    let result:ObliqueFootprint2;
    Debuglog.log("gf");
    let records = await getRawFootprintRecords(utmCoords, direction, 1) as any;
    records = (records && records.body)  ? JSON.parse(records.body) : records;
    if (records && records.length>0) {
      let fpr = records[0];
      let centroid = fpr.centroid && JSON.parse(fpr.centroid);
      let center= centroid && centroid.coordinates;
      let cc:CameraCalibration = {
        c:fpr.focal_length,
        dimX:fpr.image_extent_x,
        dimY:fpr.image_extent_y,
        image_format_x:parseInt(fpr.image_format_x,10),
        image_format_y:parseInt(fpr.image_format_y,10),
        mount_rotation:parseInt(fpr.mount_rotation,10),
        pix:fpr.pixel_size/1000,
        xx0:fpr.x_ppa,
        yy0:fpr.y_ppa
      };
      let fp:FootPrintCamaraData = {
        x:fpr.easting,
        y:fpr.northing,
        z:fpr.height,
        omega:fpr.omega,
        phi:fpr.phi,
        kappa:fpr.kappa,
        pictureDate: new Date(fpr.timeutc)
      };
      result = {
        center:center, 
        imageId:fpr.imageid, 
        folder:fpr.folder,
        cc:cc, 
        fp:fp
      };
    }
    Debuglog.log("gf res: "+JSON.stringify(result!));
    return result!;
  }

  // Returns the requested number footprints which contains the location. Ordered by closeness (of footprint center to location)
  static async getCandidateFootprintsFromDatabase(
    getRawFootprintRecords: (coords:UtmCoords, direction:ViewDirection, maxRows?:number) => Promise<any[]>,
    utmCoords:{x:number,y:number}, 
    direction:ViewDirection,
    maxRows: number
    ):Promise<ObliqueFootprint2[]> {
    let result:ObliqueFootprint2[] = [];
    Debuglog.log("gfs");
    let records = await getRawFootprintRecords(utmCoords, direction, maxRows) as any;
    records = (records && records.body)  ? JSON.parse(records.body) : records;
    if (records && records.length>0) {
      result = records.map((fpr, idx) => {
        let centroid = fpr.centroid && JSON.parse(fpr.centroid);
        let center= centroid && centroid.coordinates;
        let cc:CameraCalibration = {
          c:fpr.focal_length,
          dimX:fpr.image_extent_x,
          dimY:fpr.image_extent_y,
          image_format_x:parseInt(fpr.image_format_x,10),
          image_format_y:parseInt(fpr.image_format_y,10),
          mount_rotation:parseInt(fpr.mount_rotation,10),
          pix:fpr.pixel_size/1000,
          xx0:fpr.x_ppa,
          yy0:fpr.y_ppa
        };
        let fp:FootPrintCamaraData = {
          x:fpr.easting,
          y:fpr.northing,
          z:fpr.height,
          omega:fpr.omega,
          phi:fpr.phi,
          kappa:fpr.kappa,
          pictureDate: new Date(fpr.timeutc)
        };
        let res:ObliqueFootprint2 = {
          center:center, 
          imageId:fpr.imageid, 
          folder:fpr.folder,
          cc:cc, 
          fp:fp
        };
        return res;
      });
    }
    Debuglog.log("gfs res: "+JSON.stringify(result!));
    return result;
  }

//   static async getCandidateFootprintsFromCommonHandler(utmCoords:{x:number,y:number}, direction:string):Promise<ObliqueFootprint[]> {
//     try {
//       let footPrintData:ObliqueFootprint[] = [];
//       let success = await ObliqueInterfaces.getCommonHandler(utmCoords);
//       let dumpFeatures:any[]=[];
//       Debuglog.log("common handler returned"+JSON.stringify(success));
//       try {
//         success.ret01 && success.ret01.forEach(fpr=> {
//           let sjson = JSON.parse(fpr.aaff);
//           let path = fpr.aaed;
//           let pathSplit = path.split("/");
//           let directionCode = pathSplit[1];
//           let date = fpr.aafz;
//           let dir = ObliqueFunc.convertDirectionCode(directionCode);
//           if (direction === dir) {
//             let prefix = "";
//   //          Debuglog.log(ObliqueFunc.formatString("{id}: {direction} {prefix} {path}", {id:idx, direction:directionCode, prefix:prefix, path:path}));
    
//             let coords = sjson.coordinates[0].filter((obj,idx) => { return idx <= 3; });
//             let imsplit = pathSplit[3].split(".");
//             let imid = imsplit[0];
//             let noOfCoordinates = sjson.coordinates[0].length;
//             if (noOfCoordinates > 5) {
//               Debuglog.log(ObliqueFunc.formatString("gid {gid} imageid {imageid}: too many coordinates {no}",{gid:fpr.gid, imageid:imid, no:noOfCoordinates}));
// //              throw new Error(ObliqueFunc.formatString("gid {gid} imageid {imageid}: too many coordinates {no}",{gid:fpr.gid, imageid:imid, no:noOfCoordinates}));
//               return null;
//             }
//             let coords2 = this.reorderPoints(coords);
//             if (!ObliqueFunc.checkForPointOrder(coords2)) {
//               Debuglog.log("Unexpected point order");
//               return null;
//               // throw new Error("Unexpected order of points");
//             }
//             let coords3 = this.ensureThreeDimmensions(coords2);
//             let c:number[] = this.calcCenter(coords3);
//             let subPathSplit=pathSplit[0].split("block");
//             let blockPrefix = subPathSplit[0];
//             let blockSuffix= subPathSplit[1].substr(6);
//             footPrintData.push({
//               fp:coords3, 
//               center:c, 
//               imageId:imid, 
//               kappa:0.0, 
//               folder: "",
//               json : fpr
//             });

//           }

//         });
//         return(footPrintData);
//       } catch (err:any) {
//         Debuglog.log("3: got error"+err);
//         throw new Error(err);
//       }
//     } catch (err:any) {
//       Debuglog.log("4: got error"+err);
//       throw new Error(err);
//     }
//   }

  static convertDirectionCode(directionCode:string):ViewDirection {
    switch(directionCode) {
      case ("001"): 
        return ViewDirection.TOP;
      case ("002"): 
        return ViewDirection.NORTH;
      case ("003"): 
        return ViewDirection.SOUTH;
      case ("004"): 
        return ViewDirection.EAST;
      case ("005"): 
        return ViewDirection.WEST;
      default:
        throw new Error("Unknown direction vode: "+directionCode);
    }
  }


  static async getAndSizeImageWithCache(fullPath:string, imageId: string, zoomFactor:number, tileX:number, tileY:number, imageMetaData:ImageMetaData):Promise<LoadedImageSpec> {
    let res;
    if (ObliqueFunc.useCaching) {
      res = await ObliqueCache.getImageFromCacheByImageId(imageId, zoomFactor, tileX, tileY);
      if (res) {
        Debuglog.log("Found in raw cache "+res.width+"x"+res.height);
      }
    } 
    if (!res) {
        res = await ObliqueFunc.getAndSizeImage(fullPath);
        if (res && ObliqueFunc.useCaching) {
          Debuglog.log("Store in cache");
          await ObliqueCache.putImageToCacheByImageId(imageId, zoomFactor, tileX, tileY, res, imageMetaData);
          Debuglog.log("Saved to cache");
          return res;
        }
    }
    return res;
  }

  static async getAndSizeImage(fullPath:string):Promise<LoadedImageSpec> {
    // obtain the size of an image
    return new Promise((resolve, reject) => {
      fetch('https://'+fullPath).then((response) => {
        return response.blob()
      }).then((blob) => {
        const img = new Image()
        img.src = URL.createObjectURL(blob)
        img.onload = function() {
          resolve ({image: img, height:img.height, width:img.width});
        }
      }).catch((e) => {
        reject(e.message);
      })
    })
  }
}
