import { AppMessagesContext, Logger, actionSetErrorMessage } from '@viamap/viamap2-common';
import { Localization, SettingsManager } from "@viamap/viamap2-common";
import { useState, useContext, useEffect } from 'react';
import { ADModalBody, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';

type Props = {
    showWindow: number,
    onFormClose: () => void,
    token?:Token,
    latlng?: {lat:number, lng:number},
};

type Token = {
    "access_token": string,
    "token_type": string,
    "expires_in": number,
    "userName": string,
    ".issued": string,
    ".expires": string
};

 /**
     * Attempts to retrieve a COWI token stored in S3. 
     * If the token is close to expiration it will automatically try and update using COWI token generation API.
     * @link Stored COWI token: https://mapit2-staging.s3.eu-central-1.amazonaws.com/configuration/cowiToken.json
     * @link COWI token generation API: https://cmv.gis-hotel.dk/token
     */
 async function updateStreetToken(appMessageDispatch): Promise<Token | undefined> {
    
    
    var myHeaders = new Headers();
    myHeaders.append("Content-Type", "application/x-www-form-urlencoded");
    var urlencoded = new URLSearchParams();
    urlencoded.append("grant_type", "password");
    let userName = SettingsManager.getSystemSetting("cowiUsername", "");
    let password = SettingsManager.getSystemSetting("cowiPassword", "");
    let cowiProject = SettingsManager.getSystemSetting("cowiProject", "");
    let cowiMaximize = SettingsManager.getSystemSetting("cowiMaximise", "");

    if (!(userName && password)) {
        Logger.logError("StreetViewer", "updateToken", "COWI credentials for customer not specified");
        console.error("COWI credentials for customer not specified");
        appMessageDispatch(actionSetErrorMessage(Localization.getText("Streetviewer credentials missing")));
        return;
    } else if (!(cowiProject)) {
        Logger.logError("StreetViewer", "updateToken", "Streetviewer: default project is missing, (cowiProject)");
        console.error("Streetviewer: default project is missing, (cowiProject)");
        appMessageDispatch(actionSetErrorMessage(Localization.getText("Streetviewer: default project is missing")));
        return;
    } else if (!(cowiMaximize)) {
        Logger.logError("StreetViewer", "updateToken", "Streetviewer: default layer is Missing, (cowiMaximise)");
        console.error("Streetviewer: default layer is Missing, (cowiMaximise)");
        appMessageDispatch(actionSetErrorMessage(Localization.getText("Streetviewer: default layer is Missing")));
        return;
    }

    urlencoded.append("username", userName);
    urlencoded.append("password", password);

    var requestOptions: RequestInit = {
        method: 'POST',
        headers: myHeaders,
        body: urlencoded,
        redirect: 'manual'
    };

    let newToken: Token = await fetch("https://cmv.cowi.com/token", requestOptions)
        .then(response => {
            if (response.status === 200) {
                return response.json();
            } else {
                throw new Error("Could not get access token");
            }
        })
        .then(result => {
            return result;
        })
        .catch(error => {
            console.error(error);
            appMessageDispatch(actionSetErrorMessage(Localization.getText("Could not update street viewer token")));
            return undefined;
        });

    return newToken
}

export function StreetViewer(props:Props) {
    const [token, setToken] = useState<Token | null>(null);
    const {dispatch:appMessageDispatch} = useContext(AppMessagesContext);


    useEffect(() => {
        updateStreetToken(appMessageDispatch).then((a:Token | undefined) => {
            if (a) {
                setToken(a)
            }
        }).catch((err) => {
            console.error(err);
        })
    },[])
   
        

        if ( token && token.access_token) {
            if (props.showWindow && props.latlng) {
                const lng = props.latlng.lng;
                const lat = props.latlng.lat;
                let cowiProject = SettingsManager.getSystemSetting("cowiProject", "");
                let cowiMaximize = SettingsManager.getSystemSetting("cowiMaximise", "");

                // tslint:disable-next-line: max-line-length
                const url = `https://cmv.cowi.com/?origin=desktop&project=${cowiProject}&maximize=${cowiMaximize}&x=${lng}&y=${lat}&srid=4326&atoken=${token.access_token}`;
                    

                return (
                    <AdvancedDragModal
                        variant={"NSWhite"}
                        topUpdate={props.showWindow}
                        allowUserMiniMize={true}
                        allowFullscreen={true}
                        PosDefault={{width:"900px",height:"700px", left:"50%", top:"50%", transform:"translate(-50%, -50%)"}}
                        title={Localization.getText("Street Viewer")}
                        onClose={props.onFormClose}
                    >
                        <ADModalBody>
                            <iframe
                                width="100%"
                                height="100%"
                                src={url}
                            />
                        </ADModalBody>
                    </AdvancedDragModal>
                );
            } else {
                return null;
            }
        }
        return null;
}