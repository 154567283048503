import { Localization, Logger, Utils } from "@viamap/viamap2-common";

export class MapitServicesInterface {

   static async getDataS3(serviceHost:string, apiKey:string, bucket:string, path:string):Promise<any> {
      let query = 
        "https://"
        +serviceHost
        +"/"
        +"getdatas3";
      const response:Response = await fetch(query,{

        method:"POST",
        mode: "cors", // no-cors, cors, *same-origin
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "X-API-KEY":apiKey
        },
        body: JSON.stringify({
          "bucket": bucket, 
          "path": path        
        })
      });
      if (response.status === 200) {
        const data = await response.json();
        if (data.statusCode === 200) {
          let result = data.body ? Utils.parseJSONPreserveDates(data.body) : data;
          return result;
        } else {
          let errorResponse = this.createErrorResponse(data.statusCode, data.body);
          throw Utils.createErrorEventObject("getJSONFromS3. "+errorResponse);
        }
      } else {
        let errorResponse = this.createErrorResponse(response.status, response.body);
        throw Utils.createErrorEventObject("getJSONFromS3. "+errorResponse);
      }
  
   }

  static async putDataS3(serviceHost: string, apiKey: string, bucket: string, path: string, data: {}, metadata?: { [key: string]: string }, makePublic: boolean = false): Promise<any> {

    metadata && this.assertMetadata(metadata);
    let query =
      "https://"
      + serviceHost
      + "/"
      + "putdatas3";
    try {
      const promise = fetch(query, {
        method: "post",
        headers: {
          "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
          "X-API-KEY": apiKey
        },
        body: JSON.stringify({
          "bucket": bucket,
          "path": path,
          "data": data,
          "metadata": metadata,
          "makePublic": makePublic,
        })
      }).then((response) => {
        if (response.status === 200) {
          return response.json();
        }
        let errorResponse = this.createErrorResponse(response.status, response.body);
        throw Utils.createErrorEventObject("putDataS3. "+errorResponse);
      }).then((parse) => {
        if (parse.statusCode === 200) {
          return parse;
        }
        let errorResponse = this.createErrorResponse(parse.statusCode, parse.body);
        throw Utils.createErrorEventObject("putDataS3. "+errorResponse);
      }).catch((err) => {
        throw(err);
      });
      return promise;
    } catch (err:any) {
      throw Utils.createErrorEventObject("putDataS3. Error" + err);
    }
  }

   /**
    * Deletes a file from the company's AWS S3 bucket.
    * @param serviceHost Service host. Stored in environment settings
    * @param apiKey Key to the API. Stored in environment settings
    * @param bucket Name of the AWS bucket to delete a file from. Stored in environment settings
    * @param path The full path within the given bucket to delete a file in. The file's name included
    * @returns A boolean promise, returning true if deletion is successful, otherwise false
    */
  static async deleteFileFromS3(serviceHost: string, apiKey: string, bucket: string, path: string): Promise<boolean> {
    let query =
      "https://"
      + serviceHost
      + "/"
      + "deleteS3";
    const deleteSuccessful: boolean = await fetch(query, {

      method: "post",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "X-API-KEY": apiKey
      },
      body: JSON.stringify({
        bucket: bucket,
        key: path,
      })
    })
      .then(resp => {
        let deleted = Boolean(resp.json());
        if (deleted) {
          console.info(Localization.getText("Permanent layer converted to normal layer"));
        } else {
          throw new Error(Localization.getText("Failed to delete permanent layer from server"));
        }
        return deleted;
      })
      .catch(() => {
        throw new Error(Localization.getText("Failed to delete permanent layer from server"));
        return false;
      });
    return deleteSuccessful;
  }

   /**
    * Lists the contents of a folder in the company's AWS S3 bucket.
    * @param serviceHost Service host. Stored in environment settings
    * @param apiKey Key to the API. Stored in environment settings
    * @param bucket Name of the AWS bucket to delete a file from. Stored in environment settings
    * @param path The full path within the given bucket to delete a file in. The file's name included
    * @returns A promise of a list of complete file paths as strings for each file in the given path
    */
  static async listFolderS3(serviceHost: string, apiKey: string, bucket: string, prefix: string): Promise<any> {
    let query =
      "https://"
      + serviceHost
      + "/"
      + "listFolderS3";
    const response: Response = await fetch(query, {

      method: "post",
      mode: "cors",
      headers: {
        "Content-type": "application/x-www-form-urlencoded; charset=UTF-8",
        "X-API-KEY": apiKey
      },
      body: JSON.stringify({
        bucket: bucket,
        prefix: prefix
      })
    })
      .then(resp => resp.json())
      .catch((err) => {
        throw Utils.createErrorEventObject("listFolderS3. Error" + err);
      });

    return response;
  }
  
   static createErrorResponse (statusCode:number, body:any) {
    let errorResponse="";
    switch (statusCode) {
        // todo: add more custom error descriptions
        default:
            errorResponse="Err:Internal error ({statusCode}): {body}";
    }
    errorResponse = Utils.formatString(errorResponse, {statusCode:statusCode, body:body});
    return errorResponse;
   }

  static assertMetadata(metadata: { [key: string]: string }): boolean {
    if (Object.values(metadata).every(value => typeof value === "string")) {
      return true;
    } else {
      Logger.logError("assertMetadata", "MapitServicesInterface", "All metadata values must be strings");
      throw new Error("All metadata values must be strings");
    }
  }

}