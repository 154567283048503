import { Fragment, useContext, useEffect, useMemo, useRef, useState } from "react";
import { ADModalBody, ADModalBodyNoScroll, ADModalInfo, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { actionAddDataLayer, actionSetShowWindow, MapitStateContext, MapitWindowId } from "src/states/MapitState";
import { GlassCard, GlassCheckbox, GlassInfo, GlassStack } from "../components/MitGlassComponents";
import { Cell, Pie, PieChart } from "recharts";
import { GlassColorBox, GlassInputControl, GlassInputGroup, GlassTextInputTransformer } from "../components/GlassInput";
import { GlassFoldUdBox, KeyValueLine, KeyValueTable, ViewButtonIcon, ViewButtonText } from "src/propertyInfoTemplates/PropertyComponents";
import { actionSetErrorMessage, actionSetInfoMessage, AppMessagesContext, Localization, PersistenceScope, SessionContext, SettingsFixedPriorities, SettingsManager, ViamapPersistenceLayer } from "@viamap/viamap2-common";
import { Tab, Tabs } from "react-bootstrap";
import { GlassButton } from "../components/GlassButtons";
import React from "react";
import { BsEye, BsEyeSlash, BsFillEyeFill, BsFillEyeSlashFill, BsFillPostcardFill, BsPlusCircle, BsSlashCircle, BsSquare, BsSquareFill, BsXLg } from "react-icons/bs";
import { BNBOGenerateAfgørelse, handleGenerateDokument } from "./BNBODanFlettebrev";

import { MitLatLng } from "src/managers/MapFacade";
import { actionAddAvailableFeature, actionRemoveAvailableFeature, ApplicationStateContext, Feature } from "src/states/ApplicationState";
import { BNBOGenerateERids } from "src/BNBOModule/BNBODanERids";
import { ProtectedFeature } from "../components/ProtectedFeature";
import { Persistence } from "src/managers/Persistence";
import * as Turf from '@turf/turf';
import { LayerFunc } from "src/managers/LayerFunc";
import { LayerInfo } from "src/common/managers/Types";
import { Typeahead } from 'react-bootstrap-typeahead';
import { useFloating } from "@floating-ui/react";
import { MitStorage } from "src/managers/MitStorage";
import { BNBOSearchBar } from "./BNBOSearchBar";
import { useMitStorage } from "src/managers/UseMitStorage";
import { BNBOBeregninger } from "./BNBOBeregninger";
import { BNBOContext, initialize, replaceBNBOState, BNBOMatrikel, BNBOMark, BNBOLodsejer, BNBOBeskyttelsesOmråde, BNBOOmrådeType, getLodsejerData, setLodsejerStatus, BNBOLodsejerStatus, setLodsejerSagsbehandler, setLodsejerTags, setLodsejerNote, safeGetDoubleDepthObject, setLodsejerSats, setLodsejerErSpecialSats, getMarkId, BNBOState, initialBNBOState, setLodsejerDele, addLodsejerDel, BNBODel, BNBOProject, selectProject, loadSFEState, updateProject, FeaturesAccessibleBNBOUserAccessRights, loadSFE, getMyAccessRights, setLodsejerBNBOinactive } from "./BNBOState";
import { useWindowContext } from "src/WindowManager/useWindowContext";
import { closeWindow, ManagedWindow, minimizeWindow, openWindow, sendParams, WindowId } from "src/WindowManager/WindowState";
import { BNBOFunc } from "./BNBOFunc";
import { BNBOProjectsPersistence } from "./BNBODataStore";
import { BNBOConst } from "./BNBOConst";
import { keyValueObject, SettingsEditor } from "src/components/SettingsEditor";
import { createPortal } from "react-dom";
import { a } from "vitest/dist/suite-CRLAhsm0";
import { RightPaneContext, RightPaneModus } from "src/states/RightPaneState";
import { message } from "src/components/Message";


type BNBOFilter = {
   search?: {
      dguNr?: string,
      SFENummer?: number,
      ejerlavsKode?: number,
      matrikelNummer?: string,
   }
   status?: string[],
   tags?: string[]
}

export function BNBOSFEStatus(props:{
   show: number,
   onClose: () => void,
   filterBy?: (a) => boolean,
   managedWindow: ManagedWindow,
}) {
   const { state: bnboState, dispatch: bnboDispatch } = useContext(BNBOContext);
   const { dispatch: appMessageDispatch } = useContext(AppMessagesContext); 
   const { dispatch: mapitStateDispatch } = useContext(MapitStateContext)
   const {state: sessionState} = useContext(SessionContext);
   const { hasAccessToFeature, dispatch:applicationStateDispatch } = useContext(ApplicationStateContext)
   const { dispatch: windowDispatch} = useWindowContext();

   const [filter, setFilter] = useMitStorage("BNBO_SFEViewFilter", {} as BNBOFilter);
   const [lastUsedProjectId, setLastUsedProjectId] = useMitStorage<string|undefined>("BNBO_LastProjectId",  undefined);

   const [lastVisited, _setLastVisited] = useState<number|undefined>(MitStorage.getValue<number>("BNBO_LastVisited_SFE"))
   const [selectedSFE, setSelectedSFE] = useState<number[]>([]);

   const [showSettings, setShowSettings] = useState<boolean>(false);
   const [projSettings, setProjSettings] = useState<any>();
   const projRef = useRef<any>()
 
   useEffect(() => {
     if (bnboState.selectedProject) {
       setProjSettings(bnboState.selectedProject.settings);
       projRef.current = bnboState.selectedProject.settings;
       // Clear filter when changing project
       setFilter({});
     }
   }, [bnboState.selectedProject]);


   function setLastVisited(sfe:number) {
      MitStorage.setValue<number>("BNBO_LastVisited_SFE",sfe);
      _setLastVisited(sfe)
   }

   useEffect(() => {
      document.getElementById("pølse_"+lastVisited)?.scrollIntoView()
   },[props.show])

   useEffect(() => {
      if (!bnboState.isInitialized) {
         bnboDispatch(initialize(SettingsManager.getSystemSetting("bnbo.projectsElasticIndex", "")));
      }
   
      let myProjects = bnboState.availableProjectsList.filter(filterProjectsByAccessRights);
      if (!bnboState.selectedProjectId && lastUsedProjectId && myProjects.length) {
         bnboDispatch(selectProject(lastUsedProjectId));
      }  
    }, []); // [sessionState.customerRef,sessionState.userRef]);

   useEffect(() => {
      let myProjects = bnboState.availableProjectsList.filter(filterProjectsByAccessRights);
      if (!bnboState.selectedProjectId && myProjects && myProjects.length > 0) {
         if (myProjects?.length == 1) {
            bnboDispatch(selectProject(myProjects[0].id));
         } else {
            if (lastUsedProjectId && myProjects.find((a) => a.id == lastUsedProjectId)) {
               bnboDispatch(selectProject(lastUsedProjectId));
            }
         }
      }
   }, [bnboState.availableProjectsList]);

   useEffect(() => {
      if (bnboState.selectedProject && bnboState.selectedProject.esIndexName) {
         bnboState.selectedProject && bnboDispatch(loadSFEState(bnboState.selectedProject.esIndexName));
      }
      // Update access rights
      if (bnboState.selectedProject) { 
         SettingsManager.clearByPriority(BNBOConst.settingsFixedPriorities);
         SettingsManager.addSettings(bnboState.selectedProject.settings, {filename:"LiveSetting", priority:BNBOConst.settingsFixedPriorities});
         let accessRights = getMyAccessRights(bnboState.selectedProject, sessionState.userRef, sessionState.customerRef);
         // Mapping of BNBO oriented access rights to the general access rights
         if (accessRights && accessRights.length == 1) {
            // For now only a single access right is supported
            let myAllowDeny = FeaturesAccessibleBNBOUserAccessRights[accessRights[0]];
            for (let feature of myAllowDeny.allow) {
                  applicationStateDispatch(actionAddAvailableFeature(feature));
            }
            for (let feature of myAllowDeny.deny) {
               applicationStateDispatch(actionRemoveAvailableFeature(feature));
            }
         } else {
            appMessageDispatch(actionSetErrorMessage("No access rights found for this project"));
         }
      }
   }, [bnboState.selectedProject]);

   let uploadRef = useRef<HTMLInputElement>(null);
   let linkRef = useRef<HTMLAnchorElement>(null);

   let availableTags = useMemo(() => {
      return [...(new Set([...Object.values(bnboState.lodsejer).flatMap((a) => a.tags).filter((a) => (typeof a == "string")), ...filter.tags || []]))]
   },[bnboState.lodsejer]) || [""]

   function toggleTag(tag:string) {
      setFilter((pre) => { 
         return {...pre, tags: pre.tags?.includes(tag) ? pre.tags.filter((a) => a !== tag) : [...(pre.tags || []), tag]}
      })
   }

   function filterProjectsByAccessRights(project:BNBOProject):boolean {
      let accessRights = getMyAccessRights(project, sessionState.userRef, sessionState.customerRef);
      let result = hasAccessToFeature(Feature.BNBOTool) && accessRights && accessRights.length > 0;
      return Boolean(result);
   }

   const statusOptions = SettingsManager.getSystemSetting("bnbo.statusOptions",[], true) as {val:string,color:string}[];
   function statusColor(name) {
      return statusOptions.find((a) => a.val == name)?.color || "#000"
   }

   function toogleFilter(key) {
      setFilter((filter) => {
         if (filter.status?.includes(key)) {
            return {...filter, status: filter.status.filter((fKey) => fKey !== key)}
         }
         return {...filter, status: [...(filter.status || []), key]}
      })
   }

   function evaluateStatusFilters(objectToEvaluate):boolean {
      if ((filter.status || []).length !== 0 && !filter.status?.includes(objectToEvaluate.status)) {
         return false;
      }
      return true
   }

   function evaluateAllFilters(objectToEvaluate):boolean {
      let activeFilter = filter;
      if (!activeFilter) {
         return true
      }
      if (props.filterBy) {
         if (!props.filterBy(objectToEvaluate)) {
            return false;
         }
      }
      if ((filter.tags || []).length !== 0 && !filter.tags?.every((a) => objectToEvaluate.tags?.includes(a))) {
         return false;
      }
      if (activeFilter?.search?.dguNr) {
         let lodsejerBnboer = bnboState.bnboer[objectToEvaluate.id];
         let bnboFound=false;
         for (let bnbo of Object.values(lodsejerBnboer)) {
            if (bnbo.dguNr === activeFilter?.search?.dguNr) {
               bnboFound = true;
               break; // no need to search further
            }
         }
         if (!bnboFound)
            return false;
      }
      if (activeFilter?.search?.SFENummer) {
         if (objectToEvaluate.id !== activeFilter?.search?.SFENummer) {
            return false;
         }
      }
      if (activeFilter?.search?.ejerlavsKode && activeFilter?.search?.matrikelNummer) {
         let lodsejerMatrikler = bnboState.matrikler[objectToEvaluate.id];
         for (let matrikel of Object.values(lodsejerMatrikler)) {
            if (""+matrikel.ejerlavsKode === ""+activeFilter?.search?.ejerlavsKode && matrikel.matrikelNummer === activeFilter?.search?.matrikelNummer) {
               return true;
            }
          }
         return false;
      }
      return true;
   }

   function clearAllFilters() {
      setFilter((a) => {return {"status": a.status}});
   }

   function clearStatus() {
      setFilter((a) => {return {"search": a.search, "tags": a.tags}});
   }

   function countActiveFilters():number {
      return Object.keys(filter.search || {}).filter((a) => filter.search?.[a]).length + (filter.tags || []).length;
   }

   function describeActiveFilters() {
      return (
         [Object.keys(filter.search || {}).filter((a) => filter.search?.[a]).map((a) => {
            return `${a}: ${filter.search?.[a] || ""}`;
         }),(filter.tags || []).map((a) => {
            return `Tag: ${a}`;
         })].flat().join(",\n")
      )
   }

   function hasActiveFilters():boolean {
      return countActiveFilters() > 0;
   }

   let SFEListe = useMemo(() => {
      let values = Object.values(bnboState.lodsejer);
      values = values.filter((item) => evaluateAllFilters(item)).sort((a,b) => a.sfeNummer - b.sfeNummer);
      return [...values];
   },[bnboState.lodsejer, props.filterBy, filter])

   let pieData = useMemo(() => {
      let data = SettingsManager.getSystemSetting("bnbo.statusOptions",[], true).reduce((a,b) => {a[b.val] = 0; return a}, {})
      // let data = {}
      SFEListe.forEach((a) => {
         if (data[a.status]) {
            data[a.status] += 1;
         } else {
            data[a.status] = 1;
         }
      })
      return (Object.keys(data).map((a) => {
         return {name: a, value: data[a]}
      }))
   },[SFEListe])

   if (!props.show) {
      return null
   }

   const SelectProject = (props: { title: string, onSelect: (value: string) => void }) => {
      return (
        <>
        <div className="GlassInputControl BnboProjectSelect" >
          <select onChange={e => {
            let id =e.target.value;
            props.onSelect(id);
          }}
            className="glassInput"
            style={{ width: "auto" }}
            value={bnboState.selectedProjectId}>
            {bnboState.selectedProjectId ? <></> : <option key={"default"} value={""} >{"Vælg project"}</option> }
            {(bnboState.availableProjectsList || []).filter(filterProjectsByAccessRights).map((p: BNBOProject) => {
              return (
                <option key={p.id} value={p.id}>{p.name}</option>
              );
            })}
          </select>
          </div>
        </>
      )
    }

   function saveToFile(e:any) {
      var file = new Blob([JSON.stringify(bnboState)], {type: "json"});
      if (linkRef.current) {
        linkRef.current.href = URL.createObjectURL(file);
        linkRef.current.setAttribute('target', '_blank');
        linkRef.current.download = "BnboState.json";
        linkRef.current.click()
      }
    }

    function importFile(e:React.DragEvent<HTMLElement>|React.ChangeEvent<HTMLInputElement>) {
      let x = (e as React.ChangeEvent<HTMLInputElement>).target?.files?.[0] || (e as React.DragEvent<HTMLElement>).dataTransfer.files?.[0]
      if (x) {
        x.text().then((a) => {
         if (validateBNBOState(JSON.parse(a))) {
            bnboDispatch(replaceBNBOState(JSON.parse(a)))
         } else {
            appMessageDispatch(actionSetErrorMessage("File didn't match format"))
         }
         });
      }
      if (uploadRef.current) {
        uploadRef.current.value=""
        uploadRef.current.type=""
        uploadRef.current.type="file"
      }
      e.preventDefault();
      e.stopPropagation();
    }

   function exportStateAsNewLayers() {
      function onCreateLayer (title: string, visible: boolean, features: any[], propertiesToShow?:string[]) {
        Persistence.createLayersFromGeoJSON(title, Turf.featureCollection(features), new Date(), (li: LayerInfo) => {

          li.visible = visible;
          // Fallback: show all properties if not specified
          li.propertiesToDisplay = (propertiesToShow || LayerFunc.extractPropertiesFromGeoJSON(Turf.featureCollection(features))).reduce<any[]>((result, prop) => {
            return [...result, { name: prop }];
          }, []);
          li.propertiesInGeoJson = LayerFunc.extractPropertiesFromGeoJSON(Turf.featureCollection(features));

          mapitStateDispatch(actionAddDataLayer(li));
        })
      }

      function createLayerIfAnyFeatures(title, visible, features:any[], propertiesToShow?:string[]) {
         if (features.length > 0) {
             onCreateLayer(title, visible, features, propertiesToShow);
         } else {
             console.log(`No '${title}' layer created as it is empty!`);
         }
      }

      // Collect features for new layers
      let ftBNBO: any[] = [];
      let ftMark: any[] = [];
      let ftMatrikel: any[] = [];
      let ftMarkOverlap: any[] = [];
      let ftMatrikelOverlap: any[] = [];
      let ftMatrikelDel: any[] = [];
      let ftSamletFastEjendom: any[] = [];
      let sfeNummerList: Array<number> = [];

      let matrikler=Object.values(bnboState.matrikler).reduce<BNBOMatrikel[]>((result, lodsejerMatrikler) => {
         return [...result, ...Object.values(lodsejerMatrikler)];
      }, [])
      matrikler.forEach((m) => {
         ftMatrikel.push(Turf.feature(m.geometry, {"sfeNummer":m.lodsejerId}));
      })

      let marker=Object.values(bnboState.marker).reduce<BNBOMark[]>((result, lodsejerMarker) => {
         return [...result, ...Object.values(lodsejerMarker)];
      }, [])
      marker.forEach((m) => {
         ftMark.push(Turf.feature(m.geometry, {"markNr":m.markNr, "markBlok":m.markBlok}));
      })

      let ejendomme=Object.values(bnboState.lodsejer).reduce<BNBOLodsejer[]>((result, lodsejer) => {
         return [...result, lodsejer];
      }, [])
      ejendomme.forEach((m) => {
         m.geometry.features.forEach((ft) => {
            ftSamletFastEjendom.push(Turf.feature(ft.geometry , {"sfeNummer":m.id, "adresse":m.addr, "ejerNavn":m.ejereTekst, "antalMatrikler":m.geometry.features.length, "status":m.status}));   
         })
      })

      let bnboEr = Object.values(bnboState.bnboer).reduce<BNBOBeskyttelsesOmråde[]>((result, lodsejerBNBOer) => {
         // removed duplicates
         return [...(new Set([...result, ...Object.values(lodsejerBNBOer)]))];
      }, [])
      bnboEr.forEach((m) => {
         ftBNBO.push(Turf.feature(m.geometry, {"Dgunr":m.dguNr, "StatusTekst":m.statusTekst, "StatusKode":m.statusCode, "Kommune":m.kommuneNavn}));
      })

      // createLayerIfAnyFeatures("Beskyttede naturtyper", false, resNatur?.features || [], ["Natyp_navn", "Status"]);
      createLayerIfAnyFeatures("BNBOer", true, ftBNBO, ["Dgunr", "StatusTekst", "StatusKode", "Kommune"]);
      createLayerIfAnyFeatures("Påvirkede marker", true, ftMark);
      createLayerIfAnyFeatures("Påvirkede matrikler", true, ftMatrikel, ["mat:matrikelnummer","mat:ejerlavskode","mat:registreretAreal","mat:vejareal", "bnbo_ejereTekst", "bnbo_ejdtype","bnbo_ejdAdresse","bnbo_vurdejdtype","bnbo_areal_m2","sfeNummer"]);
      createLayerIfAnyFeatures("Mark servitutareal", false, ftMarkOverlap);
      createLayerIfAnyFeatures("Matrikel servitutareal", false, ftMatrikelOverlap, ["mat:matrikelnummer","mat:ejerlavskode","mat:registreretAreal","mat:vejareal", "bnbo_ejereTekst", "bnbo_ejdtype","bnbo_ejdAdresse","bnbo_vurdejdtype","bnbo_areal_m2","sfeNummer"]);
      createLayerIfAnyFeatures("Matrikel dele", true, ftMatrikelDel, 
          ["mat:matrikelnummer","mat:ejerlavskode","mat:registreretAreal","mat:vejareal", "bnbo_ejereTekst", "bnbo_ejdtype","bnbo_ejdAdresse","bnbo_vurdejdtype","bnbo_areal_m2","sfeNummer",
              "mark_areal_m2", "bnbo_faktor", "bnbo_deltype", "bnbo_id", "Markblok", "Marknr"
          ]
      );
      createLayerIfAnyFeatures("SFEer", false, ftSamletFastEjendom, ["sfeNummer","adresse", "ejerNavn", "antalMatrikler", "status"]);

   }

   return (
      <>
      <AdvancedDragModal
         title={"BNBO SFE Liste"}
         window={props.managedWindow}
         PosDefault={{left:"20%", top:"20%", width:"750px", height:"750px"}}
         onClose={(e) => windowDispatch(closeWindow(WindowId.BNBOSFEStatus))}
         allowUserMiniMize={true}
         subHeaderContent={
            <>
            <BNBOSearchBar
               callBackOnFilter={(filter) => setFilter((e) => {
                  return {...e, search: {...filter}}
               })}
            />
            <ProtectedFeature feature={Feature.BNBOTool} contentsIfNoAccess={<></>}>
               <ViewButtonText
                  title="Eksporter data som Kortlag"
                  onClick={() => {
                     exportStateAsNewLayers()
                  }}
               >
                  Eksporter data som Kortlag
               </ViewButtonText>
            </ProtectedFeature>
            <ViewButtonIcon title={Localization.getText("Export to Json")} onClick={(e) => saveToFile(e)} ><img src="./images/NewSecSVG/json.svg"></img></ViewButtonIcon>
            <ProtectedFeature feature={Feature.BNBOToolGlobalAdmin} contentsIfNoAccess={<></>} ><ViewButtonIcon title={Localization.getText("Import and overwrite from Json")} onClick={(e) => uploadRef.current?.click()} onDrop={(e) => importFile(e)} ><img src="./images/NewSecSVG/importer.svg"></img></ViewButtonIcon></ProtectedFeature>
            {bnboState.selectedProjectId ? <ViewButtonText
               title="Erstatningsparametre"
               onClick={() => setShowSettings(true)}
                  // windowDispatch(openWindow(WindowId.CustomerSettingsEditor, {filter: (a) => (a.key == "bnbo")}))}
            >
               Erstatningsparametre
            </ViewButtonText> : <></>}
            </>
         }
      >  
      <input type="file" ref={uploadRef} onChange={(e) => importFile(e)} style={{display:"none"}}/>
      <a ref={linkRef} style={{opacity:0, position:"absolute"}}></a>
         <ADModalBodyNoScroll
            templateRows={"250px 1fr"}
         >
            <GlassCard title={
               <>Projekt : 
               <SelectProject title={"Vælg projekt"} onSelect={(projectId => {
                  windowDispatch(closeWindow(WindowId.BNBOSingleElement));
                  windowDispatch(closeWindow(WindowId.BNBOAreaEditor));
                  windowDispatch(closeWindow(WindowId.BNBOAreaViewer));
                  windowDispatch(closeWindow(WindowId.BNBOOpgørelse));
                  clearAllFilters();
                  clearStatus();

                  setLastUsedProjectId(projectId);
                  bnboDispatch(selectProject(projectId));
               })}/>
               </>
            } variant={"GlassCard-FlexBody"} >
               <PieChart className="CenterChart" width={200} height={200}>
                  <Pie dataKey={"value"} data={pieData}
                     fill="var(--viamap-green)"
                     innerRadius={50}
                     
                  >
                     {pieData.map((entry, index) => (
                        <Cell
                           onClick={(e) => toogleFilter(entry.name)}
                           key={`cell-${index}`} fill={statusColor(entry.name)} opacity={((filter.status || []).length == 0 || filter.status?.includes(entry.name)) ? 1 : 0.4} />
                     ))}
                  </Pie> 
               </PieChart>
               {
                  pieData.filter((a) => a.value || filter.status?.includes(a.name)).map((a) => {
                     return (
                        <div key={a.name} style={{display:"flex", alignItems:"center", gap:"0.5em",
                           cursor: "pointer",
                           opacity: ((filter.status || []).length == 0 || filter.status?.includes(a.name)) ? 1 : 0.4
                        }}
                           onClick={(evt) => toogleFilter(a.name)}
                        >
                        <GlassColorBox color={statusColor(a.name)} />
                        <span style={{color:statusColor(a.name)}} >
                        {a.name}
                        </span>
                        <span style={{marginLeft:"auto", paddingRight:"0.7ex", textAlign:"center"}}>{a.value}</span>
                     </div>
                     )
                  })
               }
               <div style={{marginTop:"auto", paddingTop:"15px"}}></div>
              <BNBOStatsForOrganization/>
            </GlassCard>   
            <GlassCard title={
            <>
            <span>Ejendomme {countActiveFilters() ? <span title={describeActiveFilters()} style={{fontSize:"12px"}}>({countActiveFilters()} {countActiveFilters() == 1 ? "Aktivt filter": "Aktive filtre"})</span> : <></>}</span>
            
            <div style={{marginLeft:"auto", display:"flex", gap:"4px"}}>
               <MultiStatus selectedSFE={selectedSFE} reset={() => setSelectedSFE([])} />
               <FilterBtn AvailableTags={availableTags} filterBy={filter.tags} toogleTag={toggleTag}  />
               {hasActiveFilters() ? <ViewButtonIcon onClick={() => {
                  clearAllFilters();
               }}><BsXLg /></ViewButtonIcon> : <></>}
            </div>
            {
               props.filterBy ?
               <div style={{marginLeft:"auto", fontSize:"0.8em", verticalAlign:"center"}} onClick={(e) => windowDispatch(openWindow(WindowId.BNBOSFEStatus, {}))}>
               Filter <BsXLg />
               </div>
               : <></>
            }
            </>
            }>
            {
               SFEListe.filter(evaluateStatusFilters).map((a, idx) => {
                  return <BNBOPølse key={"pølse"+idx} elem={a} selectedSFE={selectedSFE} 
                     callback={(ctrlKey, sfe) => {
                        if (ctrlKey) {
                           setSelectedSFE((a) => a.includes(sfe) ? a.filter((a) => a !== sfe) : [...a, sfe])
                        } else {

                           // Load latest data version for this lodsejerId from server
                           // ...in case another user has updated the data
                           bnboDispatch(loadSFE(sfe))

                           setLastVisited(sfe)
                        }
                     }
                     }
                   />
               })
            }
            {SFEListe.filter(evaluateStatusFilters).length == 0 ? 
                  <GlassInfo>Her er lidt tomt, skift projekt eller ændr filter</GlassInfo>
               : <></> }
            </GlassCard>
         </ADModalBodyNoScroll>
      </AdvancedDragModal>
      {showSettings && bnboState.selectedProject ? createPortal(
        <SettingsEditor
        showWindow={1}
        affectedMessageText="Settings for the selected project"
        allSettings={BNBOConst.settings}
        callbackOnClose={() => setShowSettings(false)}
        callbackToResetCatchment={() => {throw new Error("Not implemented")}}
        filteredSettingsGroup={BNBOConst.settingsGroups}
        priority={BNBOConst.settingsFixedPriorities}
        settingsManager={{
          extractSettingsByPriority: (source:SettingsFixedPriorities, priority:SettingsFixedPriorities, includeDisabled:boolean) => {
            if ((priority as any) === BNBOConst.settingsFixedPriorities) {
              return (projRef.current||{})
            }
            return SettingsManager.extractSettingsByPriority(source, priority, includeDisabled);
          },
          clearByPriority: (source:SettingsFixedPriorities) => {
            SettingsManager.clearByPriority(source);
          },
          addSettings: (settings:keyValueObject, {filename, priority}:{filename:string, priority:SettingsFixedPriorities}) => {
            setProjSettings(settings)
            projRef.current = settings;
            SettingsManager.addSettings(settings, {filename:filename, priority:priority});
          },
          getSystemSetting: (key:string, defaultValue:any, includeDisabled:boolean) => {
            return (projRef.current||{})[key] ?? SettingsManager.getSystemSetting(key, defaultValue, includeDisabled);},
          getState: () => {return (projRef.current||{})},
        }}
        scope={"Project" as any}
        getSource={(key:string): any => {
          return (projRef.current||{})[key] != undefined ? BNBOConst.settingsFixedPriorities : SettingsManager.getSystemSettingSource(key)?.priority
        }}
        callbackOnSaveChanges={(localSettings, sessionState, onClose, appMessageDispatch, source) => {
          bnboDispatch(updateProject(bnboState.selectedProjectId!, {...bnboState.selectedProject!, settings: localSettings}));
          setShowSettings(false);
        }}
      />, document.getElementById("Mit-NonBlockingOverlay")||document.body) : null}

      </>
   )
}

export function BNBOStatsForOrganization() {
   const { state: bnboState } = React.useContext(BNBOContext);
   const statusOptions = SettingsManager.getSystemSetting("bnbo.statusOptions",[], true) as {val:string,color:string, afsluttet?:boolean}[];
   let x = Object.values(bnboState.markDele).flat()
   let y = x.flatMap((a) => Object.values(a)).flat()   
   let areal = y.filter((a) => a.type == BNBOOmrådeType.ServitutAreal).map((a) => a.arealBrutto).reduce((a,b) => (a+b),0)

   let afsluttetStati = statusOptions.filter((a) => a.afsluttet).map((a) => a.val) || []
   let x1 = Object.keys(bnboState.lodsejer).filter((a) => afsluttetStati.includes(bnboState.lodsejer?.[a]?.status))
   let y1 = x1.flatMap((a) => Object.values(bnboState.markDele[a] || {})?.flat().filter((a) => (a.type || "") == BNBOOmrådeType.ServitutAreal).map((a) => a?.arealBrutto || 0).reduce((a,b) => (a+b),0))
   let areal1 = y1.reduce((a,b) => a+b,0)

   const stats = [
      {key: "Antal", val: Object.keys(bnboState.lodsejer).length},
      {key: "Afsluttet", val: Object.values(bnboState.lodsejer).filter((a) => afsluttetStati.includes(a.status)).length},
      {key: "Antal m²", val: areal.toFixed(0)},
      {key: "Afsluttet m²", val: areal1.toFixed(0)}
   ]

   return (
      <>
         {stats&&stats.map((item, idx) => {
            let key = item.key;
            let value = item.val;
            return(
            <KeyValueLine key={"org"+idx} valKey={<small><span>{key}</span></small>} value={value} />
            );
         })}
      </>
   )
}

export function BNBOSingleInfo(props: {
   show: number,
   lodsejerId?: string,
   managedWindow: ManagedWindow,
   onClose: () => void
}) {
   const { state: rightPaneState} = useContext(RightPaneContext);
   const { hasAccessToFeature } = useContext(ApplicationStateContext)
   const {state: bnboState, dispatch: bnboDispatch} = useContext(BNBOContext);
   const {dispatch: mapitStateDispatch} = useContext(MapitStateContext);
   const { dispatch: appMessageDispatch } = useContext(AppMessagesContext); 
   const {dispatch: windowDispatch} = useWindowContext();
   const statusOptions = SettingsManager.getSystemSetting("bnbo.statusOptions",[], true) as {val:string,color:string}[];
   const [documents, setDocuments] = useState<string[]>([]);
   const linkRef = useRef<HTMLAnchorElement>(null)

   const AvailableTags = useMemo(() => {
      return [...(new Set(Object.values(bnboState.lodsejer).flatMap((a) => a.tags).filter((a) => (typeof a == "string"))))]
   },[props.lodsejerId]) || [""]

   
   useEffect(() => {
      const signal = {abort: () => {signal.aborted = true}, aborted: false}
      async function getFiles() {
         let persistence = new ViamapPersistenceLayer(SettingsManager.getSystemSetting("viamapStoreS3Bucket"));
         let listing = await persistence.getListingS3(`BNBOModuleFiles/${bnboState.selectedProjectId}/Templates`);
         let filtered = listing.filter((a) => a.Key.endsWith(".docx")).map((a) => a.Key.split("/").at(-1));
         if (!signal.aborted) {
            setDocuments(filtered);
         }
      }

      getFiles()
      return () => {
         signal.abort()
      }
   }, [props.lodsejerId])

   if ((!props.show) || props.lodsejerId == undefined) {
      return null
   }


   
   let lodsejerId = props.lodsejerId+"";

   return (
      <AdvancedDragModal
         title={"BNBO Ejendom"}
         window={props.managedWindow}
         topUpdate={props.show}
         allowUserMiniMize={true}
         PosDefault={{left:"25%", top:"25%", width:"1200px"}}
         onClose={(e) => windowDispatch(closeWindow(WindowId.BNBOSingleElement))}
         subHeaderContent={
            <>
            {getLodsejerData(bnboState, lodsejerId) && getLodsejerData(bnboState, lodsejerId).centroide
               ? <ViewButtonIcon onClick={() => windowDispatch(openWindow(WindowId.PropertyInformationDialog, {latlng: MitLatLng.fromL(getLodsejerData(bnboState, lodsejerId).centroide!)}))} ><BsFillPostcardFill /></ViewButtonIcon>
               : <></>
            }
            <ViewButtonText
                    onClick={()=>{
                     if (rightPaneState.current == RightPaneModus.Map_Measure) {
                        message.info("Kan ikke åbne Erstatningseditor mens tegnemodus er åben")
                     } else {
                        windowDispatch(openWindow(WindowId.BNBOAreaEditor, {lodsejerId: lodsejerId}))
                        windowDispatch(closeWindow(WindowId.BNBOAreaViewer))
                        windowDispatch(minimizeWindow(WindowId.BNBOSingleElement))
                        windowDispatch(minimizeWindow(WindowId.BNBOSFEStatus))
                     }
                    }}>Editor</ViewButtonText>
            <ViewButtonText
                  onClick={()=>{
                  windowDispatch(openWindow(WindowId.BNBOAreaViewer, {lodsejerId: lodsejerId}))
                  windowDispatch(closeWindow(WindowId.BNBOAreaEditor))
                  windowDispatch(minimizeWindow(WindowId.BNBOSingleElement))
                  windowDispatch(minimizeWindow(WindowId.BNBOSFEStatus))
                  }}>Overblik</ViewButtonText>
            </>
         }
      >
         <ADModalInfo>
            <div style={{textAlign:"left"}}>{bnboState.lodsejer[lodsejerId].addr}</div>
            <div style={{textAlign:"left"}}>SFE nr. {bnboState.lodsejer[lodsejerId].sfeNummer}</div>
            <div style={{textAlign:"left"}}>{bnboState.lodsejer[lodsejerId].status}</div>
         </ADModalInfo>
         
         <Tabs  defaultActiveKey={"Ejendom"} className="ADModalTabBS" >
            <Tab eventKey={"Ejendom"}title={"Ejendom"}>
            <ADModalBodyNoScroll templateRows={"auto 1fr"} >
               <GlassCard title={"Process"} variant={"GlassCard-FlexBody"}>
               <GlassInputGroup style={{maxWidth:"420px"}}>
                  <GlassTextInputTransformer label={"Status"}>
                     <GlassInputControl>
                     <select disabled={!(hasAccessToFeature(Feature.BNBOProjectWrite))} onChange={(e) => bnboDispatch(setLodsejerStatus(e.target.value as BNBOLodsejerStatus , lodsejerId))} value={getLodsejerData(bnboState, lodsejerId)?.status} >
                        {statusOptions.map((key, idx) => {
                           return (
                              <option key={"dds"+idx} value={key.val} >{key.val}</option>
                           )
                        })}
                     </select>
                     </GlassInputControl>
                  </GlassTextInputTransformer>
                  <GlassTextInputTransformer label={"Sagsbehandler"}>
                     <input disabled={!(hasAccessToFeature(Feature.BNBOProjectWrite))} type="Text" onChange={((a) => bnboDispatch(setLodsejerSagsbehandler(a.target.value, lodsejerId)))} value={getLodsejerData(bnboState, lodsejerId)?.sagsbehandler || ""}></input>
                  </GlassTextInputTransformer>
                  <GlassTextInputTransformer label={"Tags"} >
                     <Typeahead
                        className="EmneInfo"
                        id={"mytypeahead"}
                        multiple={true}
                        options={
                           AvailableTags.filter((a) => a && !(getLodsejerData(bnboState, lodsejerId)?.tags?.includes(a))).map((a) => {return {label:a, nlabel:a}}) || []
                        }
                        allowNew={true}
                        labelKey={"label"}
                        clearButton={false}
                        style={{maxWidth:"100%"}}
                        minLength={0}
                        placeholder={Localization.getText("Start typing to select tags")}
                        newSelectionPrefix={Localization.getText("Add new tag") + " "}
                        emptyLabel={"No matches found"}
                        onChange={(selected: any) => {
                          bnboDispatch(setLodsejerTags(selected.map((a) => a.label), lodsejerId))
                        }}
                        selected={(getLodsejerData(bnboState, lodsejerId)?.tags?.filter((a) => typeof a == "string") || []).map((a) => {return {label:a}})}
                     />
                  </GlassTextInputTransformer>
               </GlassInputGroup>
               <div style={{marginTop:"auto"}}>
               <GlassFoldUdBox title={"Note"} variant="Small" foldetUd={true} >
                  <div style={{paddingTop:"4px"}}>
                  <GlassInputControl>
                  <textarea disabled={!(hasAccessToFeature(Feature.BNBOProjectWrite))} placeholder="Note..." value={getLodsejerData(bnboState, lodsejerId)?.note || ""} onChange={(a) => bnboDispatch(setLodsejerNote(a.target.value, lodsejerId))}  rows={5} >
                  </textarea>
                  </GlassInputControl>
                  </div>
               </GlassFoldUdBox>
               <div style={{height:"8px"}}></div>

               </div>
               </GlassCard>
               <GlassCard title={"Information"}>
                  <GlassFoldUdBox title={"Ejerinfo"} foldetUd={true} variant="Small">
                     <>
                     {bnboState.lodsejer[props.lodsejerId] && Object.keys(bnboState.lodsejer[props.lodsejerId]).filter((key) => key === "EjerNavn").map((key, idx) => {
                        let value = safeGetDoubleDepthObject(bnboState.lodsejer,props.lodsejerId!,key);
                        return(
                        <KeyValueLine key={"kvl1"+idx} valKey={<small><span>{key}</span></small>} value={value} />
                        );
                     })}
                     </>
                  </GlassFoldUdBox>
                  <GlassFoldUdBox title={"BNBO'er på ejendommen"} foldetUd={false} variant="Small">
                     <>
                     {bnboState.bnboer[props.lodsejerId]&&Object.keys(bnboState.bnboer[props.lodsejerId]).map((key, idx) => {
                        let value = safeGetDoubleDepthObject(bnboState.bnboer,props.lodsejerId!,key);
                        let formatTekst = `${value.anlaegsNavn} - ${value.dguNr}`;
                        let Buttons = () => {
                           return (
                              // <div style={{marginLeft:"auto"}}>
                              <>
                                 {value.inactive ? 
                                    (
                                       <ViewButtonIcon title={"Make Active"} style={{filter: "brightness(1.5) saturate(0.2)"}} onClick={() => {
                                          bnboDispatch(setLodsejerBNBOinactive(props.lodsejerId!, key, false))
                                       }}><BsPlusCircle /></ViewButtonIcon>
                                    ): 
                                    (
                                       <ViewButtonIcon title={"Make Inactive"} onClick={() => {
                                          bnboDispatch(setLodsejerBNBOinactive(props.lodsejerId!, key, true))
                                       }}><BsSlashCircle /></ViewButtonIcon>
                                    )}
                                    </>
                              // </div>
                           )
                        }
                        return(
                        <KeyValueLine key={"kvl1"+idx} valKey={<div style={{display:"flex", paddingBottom:"5px"}}>{Buttons()}<small style={{paddingTop:"2px", textDecoration:(value.inactive ? "line-through":"")}}><span>{value.statusTekst}</span></small></div>} value={<span style={{textDecoration:(value.inactive ? "line-through":"")}}>{formatTekst}{" "}</span>} />
                        );
                     })}
                     </>
                  </GlassFoldUdBox>
                  <GlassFoldUdBox title={"Påvirkede matrikler"} foldetUd={false} variant="Small">
                     <>
                     {(bnboState.matrikler[props.lodsejerId]||[]).map((mat,idx) => {
                        return (
                           <Fragment key={mat.matrikelNummer+mat.ejerlavsKode+idx}>
                              <KeyValueLine valKey value={`${mat.matrikelNummer} ${mat.ejerlavsNavn}(${mat.ejerlavsKode})`} >

                              </KeyValueLine>

                        <center></center>
                           </Fragment>
                        );
                     })}
                     </>
                  </GlassFoldUdBox>
                  <GlassFoldUdBox title={"Påvirkede marker"} foldetUd={false} variant="Small">
                     <>
                     {Object.keys(bnboState.marker[props.lodsejerId]||[]).map((markId, idx) => {
                        let mark = bnboState.marker[props.lodsejerId!][markId];
                        return (
                           <Fragment key={`${mark.markBlok}-${mark.markNr}${idx}`}>
                              <KeyValueLine valKey={`${mark.markBlok}-${mark.markNr}`}
                                 value={`${mark.afgrøde} - ${mark.arealBrutto?.toFixed(0)} m²`}
                              />
                           </Fragment>
                        );
                     })}
                     </>
                  </GlassFoldUdBox>
                     <GlassButton disabled={!(hasAccessToFeature(Feature.BNBOProjectWrite))} onClick={(e) => {
                        let lodsejer:BNBOLodsejer = bnboState.lodsejer[props.lodsejerId!];
                        // ToDo: For now clear the list when re-generating.
                        let filteredDele = bnboState.dele[lodsejer.id].filter((a) => {
                           return !(a.type == BNBOOmrådeType.Par3Areal || a.type == BNBOOmrådeType.FredskovsAreal)
                        });
                        bnboDispatch(setLodsejerDele(lodsejer.id, filteredDele));
                        BNBOFunc.loadFradragsarealerForSFE(lodsejer, (lodsejer:BNBOLodsejer, del:BNBODel) => {
                           return new Promise((resolve, reject) => {
                              bnboDispatch(addLodsejerDel(lodsejer.id, del));
                              resolve();
                           });
                        })
                        .then((a) => {
                           appMessageDispatch(actionSetInfoMessage("Fradragsarealer hentet"))
                        })
                        .catch((e) => {
                           appMessageDispatch(actionSetErrorMessage("Fradragsarealer kunne ikke hentes"))
                        });
                     }}>Hent $3 og fredskov</GlassButton>
               </GlassCard>
            </ADModalBodyNoScroll>
            </Tab>
            {/* <Tab eventKey={"Markarealer"} title={`Markarealer (${Object.values(bnboState.marker[props.lodsejerId]||{}).length})`}  >
            <ADModalBody >
            <div style={{padding:"1em"}}>
               <BNBOMarkResult lodsejerId={props.lodsejerId} disabled={!(hasAccessToFeature(Feature.BNBOToolWriteAccess))} />
            </div>
            </ADModalBody>
            </Tab> */}
            <Tab eventKey={"Opgørelse"} title={"Opgørelse"} >
               <ADModalBodyNoScroll templateRows={"1fr 380px"} >
                  <GlassCard title={"Parametre"}>
                     
                  <h6 style={{color:"var(--viamap-green)", marginBottom:"2px"}} >Jordpris inden for prisramme</h6>
         <div style={{textAlign:"right", display:"flex", justifyContent:"space-between", alignItems:"center"}} >
          <div style={{display:"flex", maxWidth:"100%", flex:"100% 0 1"}}>
            {/* <div style={{width:"66%"}}> */}
          { bnboState.lodsejer[props.lodsejerId].erSpecialSats ? 
          <>
          <input disabled={!(hasAccessToFeature(Feature.BNBOProjectWrite))} style={{width:"100%", textAlign:"right", paddingBlock:"0px", borderRadius:"8px", border:"1px solid var(--viamapGreen)", lineHeight:"1.2em"}} pattern="[0]?[0-9]*([\.,\,][0-9]*)?" min={0} step={0.05} type="number" value={bnboState.lodsejer[props.lodsejerId!].sats} onChange={(e) => props.lodsejerId && bnboDispatch(setLodsejerSats(Math.max(0,Math.min(parseFloat(e.target.value) || 0.0,99999999)), props.lodsejerId))} /> 
          <div>kr/m²</div>
          </>
          : 
          <>
          <input disabled={!(hasAccessToFeature(Feature.BNBOProjectWrite))} className="showMinMaxSlider" style={{width:"100%", display:"flex", accentColor:"var(--viamapGreen)"}} type="range" value={bnboState.lodsejer[props.lodsejerId!].sats || "0"} min={SettingsManager.getSystemSetting("bnbo.landbrugsArealPris.min",22)} max={SettingsManager.getSystemSetting("bnbo.landbrugsArealPris.max",24)} step={1} onChange={(a) => {props.lodsejerId && bnboDispatch(setLodsejerSats(parseFloat(a.target.value) || 0.0, props.lodsejerId))}} />
          <div style={{color:"#444", textWrap:"nowrap", marginLeft:"0.5ex"}}>{(bnboState.lodsejer[props.lodsejerId].sats || "0").toLocaleString("da-dk") +" kr/m²"}</div>
          </>
          }
          </div>
          {/* </div> */}
          </div>

         <div style={{display:"flex", alignItems:"center", marginRight:"auto"}}>
         <GlassCheckbox disabled={!(hasAccessToFeature(Feature.BNBOProjectWrite))} checked={bnboState.lodsejer[props.lodsejerId].erSpecialSats}
            onClick={(e,checked) => {
            if (props.lodsejerId) {
               bnboDispatch(setLodsejerErSpecialSats(checked, props.lodsejerId));
               bnboDispatch(setLodsejerSats(Math.min(Math.max(SettingsManager.getSystemSetting("bnbo.landbrugsArealPris.min",22), bnboState.lodsejer[props.lodsejerId].sats || 0), SettingsManager.getSystemSetting("bnbo.landbrugsArealPris.max",24)), props.lodsejerId));
            }
         }} >Angiv specialpris</GlassCheckbox>

         </div>


                  </GlassCard>
                  <GlassCard title={"Dokumenter"} >
                  <a ref={linkRef} style={{opacity:0, position:"absolute", zIndex:-2}}></a>
               {/* <GlassButton >Gem Ændringer</GlassButton> */}
               <GlassStack>
               {documents.map((a) => {
                  return (
                     <Fragment key={a}>
                     <GlassButton onClick={(e) => {
                        handleGenerateDokument(linkRef, bnboState, props.lodsejerId, a)
                     }}>
                     {a}
                     </GlassButton>
                     </Fragment>
                  )
               })}
               <GlassButton 
                  onClick={(e) => {
                     windowDispatch(openWindow(WindowId.BNBOAreaViewer, {lodsejerId: lodsejerId}))
                     windowDispatch(minimizeWindow(WindowId.BNBOSingleElement))
                     windowDispatch(minimizeWindow(WindowId.BNBOSFEStatus))
                     // mapitStateDispatch(actionSetShowWindow(MapitWindowId.BNBOAreaViewer, true, {lodsejerId: lodsejerId}))
                  }
                  // window.open(new URL("/demoDataBNBO/BNBO Kortbilag.pdf", import.meta.url).href, "_blank")
               }
               >Kortbilag</GlassButton>
               <GlassButton 
                  onClick={(e) => {
                     windowDispatch(openWindow(WindowId.BNBOAreaViewer, {lodsejerId: lodsejerId}))
                     windowDispatch(minimizeWindow(WindowId.BNBOSingleElement))
                     windowDispatch(minimizeWindow(WindowId.BNBOSFEStatus))
                     // mapitStateDispatch(actionSetShowWindow(MapitWindowId.BNBOAreaViewer, true, {lodsejerId: lodsejerId}))
                  }
               }
               >eRids</GlassButton>
               {/* <BNBOGenerateERids lodsEjer={props.elem.id} /> */}
               <GlassButton 
                  onClick={(e) => windowDispatch(openWindow(WindowId.BNBOOpgørelse, {lodsejerId: lodsejerId}))}
               >Opgørelse</GlassButton>
               </GlassStack>
                  </GlassCard>
               </ADModalBodyNoScroll>
            </Tab>
            </Tabs>
         
      </AdvancedDragModal>
   )
}

export function BnboMarkerArea(props: {lodsejerId: string, id:string}) {
   const { state: mapitState, dispatch: mapitStateDispatch, windowToBeShownOrder: windowToBeShownOrder, windowToBeShownParameters } = React.useContext(MapitStateContext);
   const {state: bnboState} = useContext(BNBOContext);

   const numberFomat = new Intl.NumberFormat("da-dk", {maximumFractionDigits:0})

   let markDele = bnboState.markDele[props.lodsejerId][props.id]
   return markDele && markDele.length > 0 ? (<KeyValueTable>
                       <thead>
                         <tr>
                           {/* <th>Navn</th> */}
                           <th>Type</th>
                           <th>Note</th>
                           <th>Areal</th>
                         </tr>
                       </thead>
                <tbody>
          <>
          {
             markDele?.map((ft, idx) => {
               return (
                   <tr key={"dd"+idx}>
                {/* <td>{ft.name}</td> */}
                <td>{ft.type}</td>
                <td>{ft.note || ""}</td>
                <td style={{textAlign:"right"}} >{numberFomat.format(ft.arealNetto|| 0)} m²</td>
                </tr>
                );
             })
          }
          </>
                </tbody>
   </KeyValueTable>) : null;
}

export function BnboMarkErstatningsBeregning(props: {lodsejerId:string, id:string}) {
   const { state: mapitState, dispatch: mapitStateDispatch, windowToBeShownOrder: windowToBeShownOrder, windowToBeShownParameters } = React.useContext(MapitStateContext);
   const {state: bnboState} = useContext(BNBOContext);

   let erstList = BNBOBeregninger.udregnErstatningMark(bnboState, props.lodsejerId, props.id);
   let erstListFormat = BNBOBeregninger.formatErstatningsBeregningMark(erstList);

   if (!erstList || erstList.length == 0) {
      return null;
   }

   let totalErstatning = BNBOBeregninger.sumErstatninger(erstList);
   return (<KeyValueTable>
                       <thead>
                         <tr>
                           <th>Type</th>
                           <th>Antal</th>
                           <th>Takst</th>
                           <th>Faktor</th>
                           <th>Pct</th>
                           <th>Total</th>
                         </tr>
                       </thead>
                <tbody>
          <>
          {
             erstListFormat.map((ft, idx) => {
               return (
                  <tr key={"dd"+idx}>
                     <td>{ft.type_formatted}</td>
                     <td>{ft.antal_formatted}</td>
                     <td>{ft.takst_formatted}</td>
                     <td>{ft.faktor_formatted}</td>
                     <td>{ft.procent_formatted}</td>
                     <td style={{textAlign:"right"}} >{ft.erstatning_formatted}</td>
                  </tr>
                );
             })
          }
            <tr >
                <td colSpan={5}><strong>{"Total"}</strong></td>
                <td style={{textAlign:"right"}} ><strong>{BNBOBeregninger.formatTotalErstatning(totalErstatning)}</strong></td>
            </tr>
          </>
                </tbody>
   </KeyValueTable>);
}

export function BNBOMarkResult(props: { lodsejerId: string, disabled:boolean }) {
   const { state: bnboState, dispatch: bnboDispatch } = React.useContext(BNBOContext);
   const { dispatch: windowDispatch } = useWindowContext();
   const { state: mapitState, dispatch: mapitStateDispatch, windowToBeShownOrder: windowToBeShownOrder, windowToBeShownParameters } = React.useContext(MapitStateContext);

   let marker = Object.values(bnboState.marker[props.lodsejerId]||[]);
   return marker && marker.length > 0 ? (
      <KeyValueTable>
         <thead>
            <tr>
               {/* <th>Status</th> */}
               <th>Marknr.</th>
               <th>Markblok</th>
               <th>Afgrøde</th>
               <th>Servitut</th>
               <th>Fradrag</th>
               <th>Ulempe</th>
               {/* <th>Total</th> */}
               {/* <th>Erstatning</th> */}
            </tr>
         </thead>
         <tbody>
            <>
               {
                  marker.map((ft, idx) => {
                     let erst = BNBOBeregninger.udregnErstatningMark(bnboState, props.lodsejerId, ft.id)
                     let erstFormat = BNBOBeregninger.formatErstatningsBeregningMark(erst)
                     let total = BNBOBeregninger.sumErstatninger(erst)
                     return (
                        <tr key={"dd" + idx}>
                           {/* <td>{ft.status}</td> */}
                           {/* <td></td> */}
                           <td>{ft.markNr}</td>
                           <td>{ft.markBlok}</td>
                           <td>{ft.afgrøde}</td>
                           {/* <td>{ft.bnbo_Jordtype}</td>                 */}
                           <td style={{ textAlign: "right" }} >{erstFormat.find((a) => (a.type == BNBOOmrådeType.ServitutAreal))?.antal_formatted}</td>
                           <td style={{ textAlign: "right" }} >{erstFormat.find((a) => (a.type == BNBOOmrådeType.FradragsAreal))?.antal_formatted}</td>
                           <td style={{ textAlign: "right" }} >{erstFormat.find((a) => (a.type == BNBOOmrådeType.UlempeAreal))?.antal_formatted}</td>
                           {/* <td style={{ textAlign: "right" }} >{numberFomat.format(ft.fradragsAreal || 0)} m²</td>
                           <td style={{ textAlign: "right" }} >{numberFomat.format(ft.ulempeAreal || 0)} m²</td> */}
                           {/* <td style={{ textAlign: "right" }}>{BNBOBeregninger.formatTotalErstatning(total)}</td> */}
                        </tr>
                     );
                  })
               }
            </>
         </tbody>
      </KeyValueTable>
   ) : null;
}

function BNBOPølse(props:{elem:BNBOLodsejer, selectedSFE:number[], callback:(ctrlKey, sfe) => void}) {
   const {windowToBeShownParameters, dispatch: mapitStateDispatch} = useContext(MapitStateContext);
   const {state: windowState, dispatch: windowDispatch } = useWindowContext();
   const {state: bnboState} = useContext(BNBOContext)

   return (
      <div
         className="BNBOPølse"
         id={"pølse_"+props.elem.sfeNummer}
         onClick={(e) => {
            if (!e.ctrlKey) {
               // windowDispatch(minimizeWindow(WindowId.BNBOSFEStatus));
               if (windowState.find((a) => a.id == WindowId.BNBOAreaViewer)) {
                  windowDispatch(sendParams(WindowId.BNBOSingleElement, {lodsejerId: props.elem.sfeNummer}))
               }  else {
                  windowDispatch(openWindow(WindowId.BNBOSingleElement, {lodsejerId: props.elem.sfeNummer}))
               }
               windowDispatch(sendParams(WindowId.BNBOAreaViewer, {lodsejerId: props.elem.sfeNummer}))
            } 
            props.callback(e.ctrlKey ,props.elem.sfeNummer)
         }}
         
         style={{
            overflow:"hidden",
            position:"relative",
            backgroundColor: windowState.find((a) => a.id == WindowId.BNBOSingleElement)?.windowParams?.elem?.sfeNummer == props.elem.sfeNummer ? "#b6ccb6" : "#fff",
            marginBlock:"5px", marginInline:"5px", borderRadius:"15px", padding:"0.5em", boxShadow:"1px 1px 5px #dddd", cursor:"pointer"}}>
         {props.selectedSFE.includes(props.elem.sfeNummer) ? <div style={{position:"absolute", width:"200px", backgroundColor:"#0aa", zIndex:"0", height:"10px", transform:"translateX(-90px) rotate(-45deg)"}}></div> : <></>}
         <div style={{zIndex:1, position:"relative"}}>{props.elem.addr || "Ukendt adresse"}</div>
         <div style={{display:"flex", justifyContent:"space-between"}}>
            <div>
               SFE nr. {props.elem.sfeNummer} {Object.values(bnboState.marker[props.elem.id]||{}).length ? ` (${Object.values(bnboState.marker[props.elem.id]||{}).length} Markarealer) `:""}
            </div>
            <div>
               {props.elem.status}
            </div>
         </div>
         <div className="TagList" >{props.elem.tags?.map((a) => {
            return (<div key={"tag"+a} className="TagItem" >{a}</div>)
         })}</div>
      </div>
   )
}

function validateBNBOState(state:BNBOState) {
   let keys = Object.keys(initialBNBOState())
   return keys.every((a) => {
      if ((a in state)) {
         return true
      }
      console.log(a)
      return false
   })
}

function FilterBtn(props:{AvailableTags: string[], filterBy?: string[], toogleTag: (tag:string) => void}) {
   const [inEditMode, setInEditMode] = useState(false)
   const {refs, floatingStyles} = useFloating({
      // whileElementsMounted: autoUpdate,
      placement:"bottom-end",
   });

   return (
      <>{inEditMode ? (
         // <FloatingPortal >
         <div ref={refs.setFloating}  style={{...floatingStyles, fontSize:"14px", zIndex:"1001", minHeight:"0px", height:"auto", bottom:"unset"}} className="BurgerMenu" >
            <div className="scroll" style={{minHeight:"0px"}}>
            {props.AvailableTags.map((a) => {
               return (<div key={"tag"+a} className='BurgerBtn' onClick={() => {
                  props.toogleTag(a)
                  setInEditMode((a) => false)
               }}>
                  <div className='icon' >{props.filterBy?.includes(a) ? <BsSquareFill /> : <BsSquare />}</div>
                  <div className='text' title={a} >{a}</div>
                </div>)   
            })}
            </div>
         </div>
         // </FloatingPortal>
         ) : <></>}
      <button ref={(e) => refs.setReference(e)} onClick={((a) => setInEditMode((a) => !a))} className={"ViewButton ViewButtonText "} >
         Filter
      </button>
      </>
   )
}

function MultiStatus(props:{selectedSFE, reset:() => void}) {
   const {state, dispatch:bnboDispatch} = useContext(BNBOContext);
   const [inEditMode, setInEditMode] = useState(false)
   const {refs, floatingStyles} = useFloating({
      // whileElementsMounted: autoUpdate,
      placement:"bottom-end",
   });

   const options = useMemo(() => {
      return SettingsManager.getSystemSetting("bnbo.statusOptions")
   },[])

   function setValueForAllSelected(val) {
      props.selectedSFE.forEach(element => {
         bnboDispatch(setLodsejerStatus(val, element))
      });
      setInEditMode((a) => false)
   }

   function reset() {
      props.reset()
      setInEditMode((a) => false)
   }

   if (!props.selectedSFE.length) {
      return <></>
   }

   return (
      <>{inEditMode ? (
         // <FloatingPortal >
         <div ref={refs.setFloating}  style={{...floatingStyles, fontSize:"14px", zIndex:"1001", minHeight:"0px", height:"auto", bottom:"unset"}} className="BurgerMenu" >
            <div className="scroll" style={{minHeight:"0px"}}>
               <div style={{width:"100%", display:"flex"}} >
               <button onClick={reset} style={{marginLeft:"auto"}} className={"ViewButton ViewButtonText "}>Nulstil</button>
               </div>
               <select  onChange={((a) => a.target.value && setValueForAllSelected(a.target.value))} style={{background:"black", color:"white", width:"100%"}} defaultValue={""} value={""} >
                  <option value={""} >Vælg status</option>
                  {options.map((a) => {
                     return <option style={{color:a.color}} value={a.val}>{a.val}</option>
                  })}
               </select>
            </div>
         </div>
         // </FloatingPortal>
         ) : <></>}
      <button ref={(e) => refs.setReference(e)} onClick={((a) => setInEditMode((a) => !a))} className={"ViewButton ViewButtonText "} >
         ({props.selectedSFE.length}) Selected 
      </button>
      </>
   )
}


function describeSearchFilter(a:"dguNr"|"SFENummer"|"ejerlavsKode"|"matrikelNummer") {
   switch (a) {
      case "dguNr": return "DguNr"
      case "SFENummer": return "SFE-nummer"
      case "ejerlavsKode": return "Ejerlavskode"
      case "matrikelNummer":   return "Matrikelnummer"
   }
}
