import { MapitLayerFactory } from 'src/components/MapitLayerFactory';
import {LabelStyling, LabelTabStylingList, LayerType} from '../common/managers/Types';
import { MapitUtils } from './MapitUtils';
import { ReferenceGeomComposite } from './ReferenceGeomComposite';
import { SettingsManager } from '@viamap/viamap2-common';

export class Migration {

  static async migrateOldState(targetVer:string, currentState:any):Promise<any> {
   let result;
   if (currentState.interfaceVersion === targetVer) {
     return currentState;
   } else {
     switch(currentState.interfaceVersion) {
       // case "1.2": 
       //   result = this.migrate12to13(currentState);
       //   break;
       case "1.3": 
         result = await this.migrate13to14(currentState);
         break;
       case "1.4": 
         result = await this.migrate14to15(currentState);
         break;
         case "1.5": 
         result = await this.migrate15to30(currentState);
         break;
       default:
         // no upgrade exists for this version.
         return null;
     }      
   }
   // recursively upgrade
   return await this.migrateOldState(targetVer, result);
 }

 // Due to a mistake version 1.2.4 contained a change to the way labels are stored - without changeing the interface version number.
 // Before: 
 /*
 export type MapExportImportFormat = {
        interfaceVersion:string;
        mapInfo:MapInfo;
        layers:LayerInfo[];
        userName:string;
        readOnly:boolean;
        timeGenerated:Date;   
        endDate?:Date;         
        securityKey?:string;     
        viewerState?: ViewerState;
        enabledFeatures?: Feature[]; // Only relevant for readOnly mode
    };
    
 export type LayerInfo = {
    ...
   styling: {
       ...
       label?: LabelStyling,
       ...
   }
 };
 export type LabelStyling = {
        labelDataList:LabelColumnStyling[],
    };

 After:
 export type LayerInfo = {
    ...
   styling: {
      ...
       labelTabs?: LabelTabStylingList,
      ...
   }
 };
 export type LabelTabStylingList = LabelTabStyling[];
 export type LabelTabStyling = {
        title:string;
        labels:LabelStyling
    };

*/
// We need to accept old format specs.
  static migrate14to15(currentState:any) {
   let migrateLabelDef = function(oldLabelDef:LabelStyling):LabelTabStylingList {
     let st:LabelTabStylingList = [{
       title : "(migrated)",
       labels:
         oldLabelDef        
     }];
     return st;
   };

   let migrateStyles = function (oldStyle:any):any {
     if (oldStyle.label) {
       let newLabels = migrateLabelDef(oldStyle.labels);
       delete oldStyle.label;
       let newStyles = {...oldStyle, labelTabs:newLabels};
       return newStyles;
     } else {
       return oldStyle;
     }
   };

   let newLayerInfos = currentState.layers.map((layerInfo) => {
     return {...layerInfo, styling: migrateStyles(layerInfo.styling) };
   });

   // todo: implement
   return ({...currentState,
     "interfaceVersion": "1.5",
     "layers": newLayerInfos
   });
 }

//  static migrate14to15(currentState:any) {
//    let migrateLabelDef = function(oldLabelDef:LabelStyling):LabelTabStylingList {
//      let st:LabelTabStylingList = [{
//        title : "",
//        labels:
//          oldLabelDef        
//      }];
//      return st;
//    };

//    let migrateStyles = function (oldStyle:any):any {
//      if (oldStyle.labels) {
//        let newLabels = migrateLabelDef(oldStyle.labels);
//        delete oldStyle.labels;
//        let newStyles = {...oldStyle, labelTabs:newLabels};
//        return newStyles;
//      } else {
//        return oldStyle;
//      }
//    };

//    let newLayerInfos = currentState.layers.map((layerInfo) => {
//      return {...layerInfo, styling: migrateStyles(layerInfo.styling) };
//    });

//    // todo: implement
//    return ({...currentState,
//      "interfaceVersion": "1.5",
//      "layers": newLayerInfos
//    });
//  }

 static migrate13to14(currentState:any) {
   return ({...currentState,
     "interfaceVersion": "1.4",
     "mapInfo": {
         "popUpPictureLayout": "Center",
         "popUpPictureSize": "Medium"
     },
   });
 }

 static async migrate15to30(currentState:any) {
  // throw new Error("Migration Not implemented yet");
  let newLayers = await currentState.layers.map(async (layerInfo) => {
    let geoJson = {}
    let propertiesInGeoJson:string[] = [];
    let propertiesToDisplay:{name:string}[] = [];
    // Extract old values
    let labels: {
        "labelDataColumn": string, // One-based
        "labelText": string
      }[] = layerInfo.styling?.labelTabs?.[0]?.labels.labelDataList;
    let colorByValueColumn = layerInfo.styling.colorByValue &&  layerInfo.styling.colorByValue.dataColumn;
    let sizeByValueColumn = layerInfo.styling.sizeByValue &&  layerInfo.styling.sizeByValue.dataColumn;
    let iconByValueColumn = layerInfo.styling.markerByValue &&  layerInfo.styling.markerByValue.dataColumn;
    
    if (layerInfo.importedSheetData || layerInfo.type.includes(LayerType.GeoJSON)) {
      let headerToArray:{[header:string]:any[]}={};
      Object.keys(layerInfo.importedSheetData || {}).forEach((columnLetter) => {
        let colData = layerInfo.analysisResult.columns.find((cd) => {
          return cd.columnLetter === columnLetter;
        });
        if (!colData) {
          throw new Error(`Data for column ${columnLetter} not found`);
        }
        let propertyName = colData.name;
        propertiesInGeoJson.push(propertyName);
        headerToArray[propertyName]= layerInfo.importedSheetData[columnLetter];
      });
      // Construct sheetData equivalent from headerToArray:{[header:string]:any[]} structure
      let jsonFromSheetEquiv:{[header:string]:any}[]=[];
      Object.keys(headerToArray || {}).forEach((header) => {
        let array = headerToArray[header];
        array.forEach((value, idx) => {
          if (!jsonFromSheetEquiv[idx]) {
            jsonFromSheetEquiv[idx]={};
          }
          jsonFromSheetEquiv[idx][header]=value
        })
      })
      await ReferenceGeomComposite.ensureRequiredGeomData(layerInfo.type);
      geoJson = MapitLayerFactory.getGeometryFromLayerInfoAndJSONData(layerInfo, jsonFromSheetEquiv);
      propertiesToDisplay = labels?.map((lbl) => {
        let idxPlusOne = Number.parseInt(lbl.labelDataColumn);
        return {name: propertiesInGeoJson[idxPlusOne -1]}
      })
      propertiesToDisplay ??= Object.keys((geoJson as any)?.features?.[0]?.properties || {}).map((a) => ({name:a}))
      if (propertiesInGeoJson.length === 0) {
        propertiesInGeoJson = propertiesToDisplay.map((a) => a.name)
      }
    }

    let AddedStyling = {}
    if (MapitUtils.isAreaLayer(layerInfo.type)) {
      AddedStyling = {
        lineColor: SettingsManager.getSystemSetting("areaMapColorForBorders"),
        areaFillOpacity: colorByValueColumn ? 0.7 : undefined,
        color: layerInfo.color || layerInfo.fillColor || layerInfo.areaFillColor || SettingsManager.getSystemSetting("areaMapDefaultFillColor"),
        lineWidth: 3,
      }
    }

    return {
      layerId:layerInfo.layerId,
      datasetname:layerInfo.datasetname,
      type: MapitUtils.isAreaLayer(layerInfo.type) ? LayerType.GeoJSON_Polygon : LayerType.GeoJSON,
      visible:layerInfo.visible,
      analysisResult:layerInfo.analysisResult,
      styling: {...layerInfo.styling,
        colorByProperty: colorByValueColumn && propertiesInGeoJson[colorByValueColumn-1],
        sizeByProperty: sizeByValueColumn && propertiesInGeoJson[sizeByValueColumn-1],
        iconByProperty: iconByValueColumn && propertiesInGeoJson[iconByValueColumn-1],
        ...AddedStyling
      },
      columnMapping:layerInfo.columnMapping,
      // data:layerInfo.data,
      // importedSheetData:importedSheetData,
      // crs: layerInfo.crs,
      readonly: layerInfo.readOnly,
      hasNoDataBehind: true,
      layerVariability: layerInfo.layerVariability,
      minZoom: layerInfo.minZoom,
      maxZoom: layerInfo.maxZoom,
      geoJson: geoJson,
      propertiesInGeoJson : propertiesInGeoJson,
      propertiesToDisplay: propertiesToDisplay,
    };
  })
  return ({...currentState,
    layers: await Promise.all(newLayers),
    "interfaceVersion": "3.0"
  });
}

}