import { Localization } from "@viamap/viamap2-common"
import React, { Fragment, useContext, useRef, useState } from "react"
import { BsList, BsMenuApp, BsTrash } from "react-icons/bs"
import { GlassButton } from "src/components/GlassButtons"
import { ApplicationStateContext } from "src/states/ApplicationState"
import { Feature } from "src/states/ApplicationStateFeatures"

type favBoxes = {
  [favName:string]:string[]
}

const BoxList:{group:string, feature?: Feature}[] = [
  {group:"Ortofoto"},
  {group:"Område"},
  {group:"Arealer"},
  {group:"Højder"},
  {group:"Handelsoplysninger"},
  {group:"Ejerforhold"},
  {group:"Samlet Fast Ejendom"},
  {group:"Mat Oversigt", feature: Feature.KortUdsnitEjendom},
  {group:"Matrikler"},
  {group:"Matrikler Seperat"},
  {group:"BBR Oversigt", feature: Feature.KortUdsnitEjendom},
  {group:"BBR"},
  {group:"BBR Teknik"},
  {group:"Tingbog - Ejendom"},
  {group:"Tingbog - Hæftelser"},
  {group:"Tingbog - Servitutter"},
  {group:"Tingbog - Adkomster"},
  {group:"Virksomheder på Ejendom"},
  {group:"Vurd 2020"},
  {group:"Vurd 2019"},
  {group:"Vurd 2018"},
  {group:"Vurd 2017"},
  {group:"Vurd 2012"},
  {group:"Lokalplaner"},
  {group:"Kommuneplan"},
]

export function FavEditor(props:{
  show: boolean,
  favBoxes: favBoxes,
  setFavBoxes: (favBoxes:favBoxes) => void,
  onClose: () => void
}) {
  const {hasAccessToFeature} = useContext(ApplicationStateContext)
  const conRef = useRef<HTMLDivElement>(null)

  function dragElement(key: string, idx:number) {
    let x = window.structuredClone(props.favBoxes);
    let lastIDX = x["minFavorit"].findIndex((a) => a == key)
    x["minFavorit"] = x["minFavorit"].filter((a) => a !== key)
    x["minFavorit"].splice(lastIDX > idx ? idx : Math.max(0, idx - 1), 0, key)
    props.setFavBoxes(x)
  }

  function deleteBox(key:string) {
    let x = window.structuredClone(props.favBoxes);
    x["minFavorit"] = x["minFavorit"].filter((a) => a !== key)
    props.setFavBoxes(x)
  }

  function addBox(key:string) {
    let x = window.structuredClone(props.favBoxes);
    x["minFavorit"] = [...x["minFavorit"], key]
    props.setFavBoxes(x)
  }

  if (!props.show) {
    return <></>
  }

  return (
    <div className="ModernBox" style={{left:"50%",transform:"translateX(-50%)", top:"25%", position:"absolute", backgroundColor:"White", borderRadius:"7px", minWidth:"200px", minHeight:"300px", boxShadow:"0px 0px 3px 3px #0002", zIndex:"3"}}>
      <h4>{Localization.getText("Favorite")}</h4>
      <div ref={conRef} className="FavItemCon"
        style={{display:"flex", flexDirection:"column"}}
      >
        {props.favBoxes["minFavorit"].map((a,idx) => {
          return (
            
            <Fragment key={a}>
              <DropArea areaNr={idx} droppedKey={(key) => dragElement(key, idx)} />
              <FavItem key={a} favKey={a} onDelete={() => deleteBox(a)} />
            </Fragment>
          )
        })}
        <DropArea areaNr={-1} droppedKey={(key) => dragElement(key, props.favBoxes["minFavorit"].length)} />
      </div>

      <select style={{textAlign: "center", marginInline:"2px", borderWidth:"2px"}} value={""} onChange={(ev) => addBox(ev.target.value)} >
        <option disabled selected value={""}> -- {Localization.getText("Add")} -- </option>
        {BoxList.filter((a) => !props.favBoxes["minFavorit"].includes(a.group) && (a.feature == undefined || hasAccessToFeature(a.feature))).map((val) => {
            return <option value={val.group} key={val.group} >{val.group}</option>
          })
        }
      </select>
      <GlassButton onClick={() => props.onClose()} >{Localization.getText("Close")}</GlassButton>
    </div>
  )
}

function DropArea(props: {
  areaNr: number,
  droppedKey: (key:string) => void,
}) {
  const [drag, setDrag] = useState(false)

  return <div onDragEnter={() => setDrag(true)}
    onDragLeave={() => setDrag(false)}
    onDrop={(ev) => {
      let favKey = ev.dataTransfer.getData("text/plain")
      favKey && props.droppedKey(favKey)
      setDrag(false)
    }}
  className={"DropArea "+(drag?" Act":"")} >

  </div>
}

function FavItem(props: {
  favKey:string;
  onDelete?: () => void;
  onDrag?:() => void;
}) {
  const [mouseOver, setMouseOver] = useState<boolean>(false);

  return (
    <>
    <div draggable={mouseOver} className={"FavItem"} onDragStart={(ev) => 
        ev.dataTransfer.setData("text/plain",props.favKey)
      }>
      <div onMouseEnter={() => setMouseOver(true)} onMouseLeave={() => setMouseOver(false)}  className={"ItemHandle"}><BsList/></div>
      <div>{props.favKey}</div>
      <div onClick={() => props.onDelete?.()}><BsTrash /></div>
    </div>
    </>
  )
}