import * as Turf from '@turf/turf';
import { MitLatLng } from '../managers/MapFacade';
import { BNBOActions, setServitutAreal, nyMatrikel, getMatrikelId, nyMark, getMarkDelId, nyMarkMatrikel, getMarkId, nyMatrikelDel, BNBOLodsejer, getLodsejerId, BNBOLodsejerStatus, BNBOLodsejerRegister, BNBOLodsejerPåvirkedeBNBOer, BNBOBeskyttelsesOmråde, getBNBOId, safeAddToDoubleDepthObject, BNBOLodsejerPåvirkedeMarker, BNBOMark, BNBOMatrikel, BNBOLodsejerPåvirkedeMatrikler, BNBODel, BNBOLodsejerMarkDele, safeGetDoubleDepthObject, BNBOOmrådeType, LoadedBNBOState, BNBOLodsejerDele } from 'src/BNBOModule/BNBOState';
import { PropertyInfoInterface } from '../managers/PropertyInfoInterface';
import { GenerateGeomUtils } from '../managers/GenerateGeomUtils';
import { parse } from 'wkt';
import { MapitUtils } from 'src/managers/MapitUtils';

export class BNBOFunc {

    public static formatErstatning(erst:number):string {
      return erst.toLocaleString("da-dk",{style:"currency",currency:"dkk", maximumFractionDigits:0});
    }
    
    static async FindIntersects(
        e: React.MouseEvent<HTMLButtonElement, MouseEvent>, 
        feature: any, 
        onCreateLayer: (title: string, visible: boolean, features: any[], propertiesToShow?:string[]) => void,
        onBnboDispatch: (action: BNBOActions) => void
      ) {
        BNBOFunc._findIntersects(feature, onCreateLayer, onBnboDispatch);
      }

      static async _findIntersects(
        feature: any, 
        onCreateLayer: (title: string, visible: boolean, features: any[], propertiesToShow?:string[]) => void,
        onBnboDispatch: (action: BNBOActions) => void
      ) {
        console.log("FindIntersects", feature.geometry);
    
        onBnboDispatch(setServitutAreal(feature));
    
        // Clone geometry
        let resMark = await PropertyInfoInterface.getMarkData(JSON.parse(JSON.stringify(feature.geometry)));
        let resMatr = await PropertyInfoInterface.fetchMat2CadasterWFSforPolygonFilterIntersectsPost(JSON.parse(JSON.stringify(feature.geometry)));
        let resNatur = await PropertyInfoInterface.getBeskyttetNatur(JSON.parse(JSON.stringify(feature.geometry)));

        let bnboAreaPolygon = Turf.polygon([PropertyInfoInterface.getCoords(feature.geometry)], feature.properties);
    
        // Collect features for new layers
        let ftMark: any[] = [];
        let ftMatrikel: any[] = [];
        let ftMarkOverlap: any[] = [];
        let ftMatrikelOverlap: any[] = [];
        let ftMatrikelDel: any[] = [];
        let ftSamletFastEjendom: any[] = [];
        let sfeNummerList: Array<number> = [];
    
        for (let matrikel of resMatr.features) {
          let matOverlap = Turf.intersect(Turf.featureCollection([bnboAreaPolygon, matrikel]), { properties: JSON.parse(JSON.stringify(matrikel.properties)) });
          if (matOverlap) {
            // Enrich with ownerdata
            let ejerlavsKode = matrikel.properties["mat:ejerlavskode"];
            let matrikelNr = matrikel.properties["mat:matrikelnummer"];
            let sfeNummer = matrikel.properties["mat:samletFastEjendomLokalId"];
            matrikel.sfeNummer = sfeNummer;

            let ejdProps = await PropertyInfoInterface.getEjendomData(ejerlavsKode, matrikelNr);
            if (ejdProps) {
              matrikel.properties.bnbo_ejere = ejdProps.ejere;
              matrikel.properties.bnbo_ejereTekst = ejdProps.ejere.reduce((result, val, idx) => { return result + (idx>0 ? ", " : "")+ val.displayText}, "");
              matrikel.properties.bnbo_ejdtype = ejdProps.ejdtype;
              matrikel.properties.bnbo_ejdAdresse = ejdProps.ejdAdresse;
              matrikel.properties.bnbo_vurdejdtype = ejdProps.vurdejdtype;
            }
            matrikel.properties.bnbo_areal_m2 = Math.round(Turf.area(matrikel));
            ftMatrikel.push(matrikel);
            onBnboDispatch(nyMatrikel(getMatrikelId(matrikel), matrikel));
            ftMatrikelOverlap.push(matOverlap);
          } else {
            throw new Error("Unexpected no overlap, mat1");
          }
        }


    
        for (let mark of resMark.features) {
          ftMark.push(mark);
          let markOverlap = Turf.intersect(Turf.featureCollection([bnboAreaPolygon, mark]), { properties: JSON.parse(JSON.stringify(mark.properties)) });
          if (markOverlap) {
            markOverlap.properties.bnbo_areal_m2 = Math.round(Turf.area(markOverlap));
            ftMarkOverlap.push(markOverlap);
            onBnboDispatch(nyMark(markOverlap));
          } else {
            throw new Error("Unexpected no overlap, mark");
          }
        }
    
        for (let matrikel of resMatr.features) {
          let matrikelId = getMatrikelId(matrikel.properties);
          let matOverlap = Turf.intersect(Turf.featureCollection([bnboAreaPolygon, matrikel]), { properties: JSON.parse(JSON.stringify(matrikel.properties)) });
          if (matOverlap) {
            let matrikelRemainderFeature = Turf.feature(matOverlap.geometry, matOverlap.properties);
            matrikelRemainderFeature.properties.bnbo_areal_m2 = Math.round(Turf.area(matrikelRemainderFeature));
            matrikelRemainderFeature.properties.bnbo_faktor = 0.5;
            matrikelRemainderFeature.properties.bnbo_deltype = BNBOOmrådeType.UlempeAreal;
    
            for (let mark of resMark.features) {
              let matMarkOverlap = Turf.intersect(Turf.featureCollection([matOverlap, mark]), {
                properties:
                  { ...JSON.parse(JSON.stringify(mark.properties)), ...JSON.parse(JSON.stringify(matrikel.properties)) }
              });
              if (matMarkOverlap) {
                sfeNummerList.push(matMarkOverlap.properties.sfeNummer);
                
                matMarkOverlap.properties.mark_areal_m2 = Math.round(Turf.area(mark));
                matMarkOverlap.properties.bnbo_areal_m2 = Math.round(Turf.area(matMarkOverlap));
                matMarkOverlap.properties.bnbo_faktor = 1.0;
                matMarkOverlap.properties.bnbo_deltype = BNBOOmrådeType.ServitutAreal;
                matMarkOverlap.properties.bnbo_id = getMarkDelId(matMarkOverlap.properties);
    
                ftMatrikelDel.push(matMarkOverlap);
                onBnboDispatch(nyMarkMatrikel(getMarkId(matMarkOverlap.properties), matMarkOverlap));
                onBnboDispatch(nyMatrikelDel(matrikelId, matMarkOverlap.properties.bnbo_id, BNBOOmrådeType.ServitutAreal, matMarkOverlap));
    
                // Subtract the overlap from the remaining area
                let subs = Turf.difference(Turf.featureCollection([matrikelRemainderFeature, matMarkOverlap]));
                if (subs) {
                  subs.properties!.bnbo_areal_m2 = Math.round(Turf.area(subs));
                  subs.properties!.bnbo_faktor = 0.5;
                  subs.properties!.bnbo_deltype = BNBOOmrådeType.UlempeAreal;
                  matrikelRemainderFeature = subs as any;
                }
              }
            }
            ftMatrikelDel.push(matrikelRemainderFeature as any);
            onBnboDispatch(nyMatrikelDel(matrikelId, "rest", BNBOOmrådeType.UlempeAreal, matrikelRemainderFeature));
          } else {
            throw new Error("Unexpected no overlap, mat2");
          }
        }

        for (let sfeNummer of sfeNummerList) {
          let geoJson = await PropertyInfoInterface.getGeojsonOfSFE(sfeNummer)
          .then(result => result)
          .catch((error) => {
            throw new Error("Error getting sfe info: "+error);
          });
          geoJson.type = "FeatureCollection";
          for (let ft of geoJson.features) {
            ft.properties.sfeNummer = sfeNummer;
            ft.properties.sfeNummerTxt = "sfe"+sfeNummer;
            ftSamletFastEjendom.push(ft);
          }
        }
    
        function createLayerIfAnyFeatures(title, visible, features:any[], propertiesToShow?:string[]) {
        if (features.length > 0) {
            onCreateLayer(title, visible, features, propertiesToShow);
        } else {
            console.log(`No '${title}' layer created as it is empty!`);
        }

    }
    // create Map layers from collected information
    createLayerIfAnyFeatures("Beskyttede naturtyper", false, resNatur?.features || [], ["Natyp_navn", "Status"]);
    createLayerIfAnyFeatures("Påvirkede marker", false, ftMark);
    createLayerIfAnyFeatures("Påvirkede matrikler", false, ftMatrikel, ["mat:matrikelnummer","mat:ejerlavskode","mat:registreretAreal","mat:vejareal", "bnbo_ejereTekst", "bnbo_ejdtype","bnbo_ejdAdresse","bnbo_vurdejdtype","bnbo_areal_m2","sfeNummer"]);
    createLayerIfAnyFeatures("Mark servitutareal", false, ftMarkOverlap);
    createLayerIfAnyFeatures("Matrikel servitutareal", false, ftMatrikelOverlap, ["mat:matrikelnummer","mat:ejerlavskode","mat:registreretAreal","mat:vejareal", "bnbo_ejereTekst", "bnbo_ejdtype","bnbo_ejdAdresse","bnbo_vurdejdtype","bnbo_areal_m2","sfeNummer"]);
    createLayerIfAnyFeatures("Matrikel dele", true, ftMatrikelDel, 
        ["mat:matrikelnummer","mat:ejerlavskode","mat:registreretAreal","mat:vejareal", "bnbo_ejereTekst", "bnbo_ejdtype","bnbo_ejdAdresse","bnbo_vurdejdtype","bnbo_areal_m2","sfeNummer",
            "mark_areal_m2", "bnbo_faktor", "bnbo_deltype", "bnbo_id", "Markblok", "Marknr"
        ]
    );
    createLayerIfAnyFeatures("SFEer", true, ftSamletFastEjendom, ["sfeNummer"]);

  }

  public static clone(s:any) {
    return JSON.parse(JSON.stringify(s));
  } 

  static async loadDataForSFE(sfeNummer:number):Promise<any> {
    let result:any;



      // 1: find matrikler for SFE
      let matriklerForSFE = await PropertyInfoInterface.getGeojsonOfSFE(sfeNummer)

      // 1b: find marker der overlapper matriklerne
      let uniqueMarker:any[] = [];
      for (let ft of matriklerForSFE.features) {
        let markJSON:any = await PropertyInfoInterface.getMarkData(this.clone(ft.geometry));
        for (let markFt of markJSON.features) {
          // overlappets størrelse
          let overlap = Turf.intersect(Turf.featureCollection([ft, markFt]));
          if (overlap) {
          let overlapArea = Turf.area(overlap);
          if (!uniqueMarker.find((val) => val.id === markFt.id)
            && overlapArea > 10.0) {
              markFt.properties.matOverlap = overlapArea;
              uniqueMarker.push(markFt);
            }
          } else {
            console.log("no overlap or too little");
            // throw new Error("Unexpected no overlap");
          }
        }
      }
      let markerJSON = Turf.featureCollection(uniqueMarker);  

      // 2: find BNBO'er (med status === "X") der overlapper mindst én matrikel. Gem BNBO'er, Gem fællesmængden
      let uniqueBNBOs:any[] = [];
      for (let ft of matriklerForSFE.features) {
        let bnboJson:any = await PropertyInfoInterface.getBNBO(this.clone(ft.geometry));
        for (let bnboFt of bnboJson.features) {
          if (bnboFt.properties.Status_bnbo_kode === 3 // Gennemgået, indsats nødvendig
            && !uniqueBNBOs.find((val) => val.id === bnboFt.id)) {
              uniqueBNBOs.push(bnboFt);
            }
        }
      }
      let matrikelramteBNBO = Turf.featureCollection(uniqueBNBOs);
      // 3. find marker der overlapper fællesmængden. Gem marken, Gem den del der overlapper
  
      let påvirkedeMarker:any[] = [];
      let påvirkedeMarkerServitutAreal:any[] = [];
      for (let markFt of markerJSON.features) {
        for (let bnboFt of matrikelramteBNBO.features) {
          let overlap = Turf.intersect(Turf.featureCollection([markFt as any, bnboFt]));
          if (overlap) {
            påvirkedeMarker.push(markFt);
            påvirkedeMarkerServitutAreal.push(overlap);
          }
      }
    }
      let markerPaavirkedeJSON = Turf.featureCollection(påvirkedeMarker);
      let markerServitutArealJSON = Turf.featureCollection(påvirkedeMarkerServitutAreal);

      // 4. find §3 og fortidsminder,arealer der overlapper matriklerne
    let uniqueParagraf3:any[] = [];
    for (let ft of matriklerForSFE.features) {
      let p3JSON:any = await PropertyInfoInterface.getBeskyttetNatur(this.clone(ft.geometry));
      for (let p3Ft of p3JSON.features) {
        // overlappets størrelse
        let overlap = Turf.intersect(Turf.featureCollection([ft, p3Ft]));
        if (overlap) {
          let overlapArea = Turf.area(overlap);
          if (!uniqueParagraf3.find((val) => val.id === p3Ft.id)
            && overlapArea > 1.0) {
              p3Ft.properties.matOverlap = overlapArea;
              uniqueParagraf3.push(p3Ft);
            }
        } else {
          console.log("no overlap or too little");
          // throw new Error("Unexpected no overlap");
        }
      }
    }
    let paragraf3JSON = Turf.featureCollection(uniqueParagraf3);  
      
    result= {matriklerForSFE, markerJSON, matrikelramteBNBO, markerPaavirkedeJSON, markerServitutArealJSON, paragraf3JSON}

    return result;

  }

  /**
   * Version where the focus is the property. (Not only fields)
   * @param feature 
   * @param nyProperty 
   * @param nyMatrikel 
   * @param nyMark 
   * @param nyMarkDel 
   */
  static async loadDataForGeoJsonVersion2(geojson: any, replaceAll:boolean, addTags:string[], currentState?:LoadedBNBOState, progressCallback?: (progressPct:number) => void): Promise<any> {
    let result = {};
    let lodsejerRegister: BNBOLodsejerRegister = {};
    let lodsejerBNBOer : BNBOLodsejerPåvirkedeBNBOer = {};
    let lodsejerMarker : BNBOLodsejerPåvirkedeMarker = {};
    let lodsejerDele: BNBOLodsejerDele = {};
    let lodsejerMatrikler: BNBOLodsejerPåvirkedeMatrikler = {};
    let markDele: BNBOLodsejerMarkDele = {};
    const TmpNewLodsejerTag = "_TMP_NEW_LODSEJER_";

    if (!replaceAll && currentState) {
      lodsejerRegister = this.clone(currentState.lodsejerRegister);
      lodsejerBNBOer = this.clone(currentState.lodsejerBNBOer);
      lodsejerMarker = this.clone(currentState.lodsejerMarker);
      lodsejerDele = this.clone(currentState.dele);
      lodsejerMatrikler = this.clone(currentState.lodsejerMatrikler);
      markDele = this.clone(currentState.markDele);
    }

    if (geojson.type === "Feature") {
      geojson = Turf.featureCollection([geojson]);
    }
    if (geojson.type !== "FeatureCollection") {
      throw new Error("FeatureCollection expected");
    }
    let count=geojson.features.length;
    let completed=0;
    let lazyLoad = false;
    for (let ft of geojson.features) {
      await BNBOFunc.loadDataForPolygonVersion2(ft, lazyLoad,
        /* nyLodsejer */ async (lodsejer: BNBOLodsejer, ejerlavsKode: number, matrikelNr: string, punkt: MitLatLng) => {
          if (!lodsejerRegister[lodsejer.id]) {
            // Ny lodsejer
            console.log("nyLodsejer");
            if (!lodsejer.tags) {
              lodsejer.tags = [];
            } 
            lodsejer.tags.push(TmpNewLodsejerTag); // Mark this record for postprocessing
            lodsejerRegister[lodsejer.id] = lodsejer;
          }
        },
        /* nySFEBNBO: */ (lodsejerId:string, bnbo: BNBOBeskyttelsesOmråde) => {
          safeAddToDoubleDepthObject(lodsejerBNBOer, lodsejerId, bnbo.id, bnbo);
          let tags = lodsejerRegister[lodsejerId].tags ||[];
          if (bnbo.statusTekst && bnbo.statusTekst != "" && !tags.includes(bnbo.statusTekst)) {
            tags.push(bnbo.statusTekst);
            lodsejerRegister[lodsejerId].tags = tags;
          }
        },
        /* nyLodsejerDel: */ (lodsejerId: string, del: BNBODel) => {
          if (lodsejerDele[lodsejerId] && lodsejerDele[lodsejerId].find((val) => val.id === del.id)) {
            console.log("Skipping nyLodsejerDel");          
          } else {
            if (!lodsejerDele[lodsejerId]) {
              lodsejerDele[lodsejerId] = [];
            }
            console.log("nyLodsejerDel");          
            lodsejerDele[lodsejerId].push(del);
          }
        },
        /* nyMatrikel*/ async (matrikel: BNBOMatrikel) => {
          console.log("nyMatrikel");
          let matrikelListe = lodsejerMatrikler[matrikel.lodsejerId] ||[];
          if (matrikelListe.find((val) => val.matrikelNummer === matrikel.matrikelNummer && val.ejerlavsKode === matrikel.ejerlavsKode)) {
            console.log("Matrikel already there (from other overlapping BNBO?).")
            // Keep it (to know the overlap)
            // But mark as special such that areas are not counted twice
            matrikel.duplikatMatrikelVedFlereBNBOerForSammeMatrikel = true;
          } else {
          matrikelListe.push(matrikel);
          lodsejerMatrikler[matrikel.lodsejerId] = matrikelListe;
          let lodsejer = lodsejerRegister[matrikel.lodsejerId];
          if (lodsejer && !lodsejer.ejereTekst) {
            if (!lazyLoad) {
              try {
                let ejdProps = await PropertyInfoInterface.getEjendomData(""+matrikel.ejerlavsKode, matrikel.matrikelNummer);
                if (ejdProps) {
                  lodsejer.ejere = ejdProps.ejere;
                  lodsejer.ejereTekst = ejdProps.ejere.reduce((result, val, idx) => { return result + (idx > 0 ? ", " : "") + val.displayText }, "");
                  lodsejer.ejdtype = ejdProps.ejdtype;
                  lodsejer.addr = ejdProps.ejdAdresse;
                  lodsejer.vurdejdtype = ejdProps.vurdejdtype;
                  lodsejer.EjerNavn = lodsejer.ejereTekst;
                  lodsejer.Adresse = ejdProps.ejdAdresse;
                }
              } catch (error) {
                // It can happen that property data is not available. Log and continue
                console.error("Error getting ejendomsdata: "+error);
                lodsejer.ejereTekst = `Ingen Ejendomdata fundet for Ejerlav ${matrikel.ejerlavsKode} Mat ${matrikel.matrikelNummer}`;
              }
            }
            lodsejer.Adresse2 = "";
            lodsejer.JournalNr = "j.nr";
            lodsejer.RefNr = "ref.nr";
            lodsejer.MatrNr = matrikel.matrikelNummer;
            lodsejer.Ejerlav = ""+matrikel.ejerlavsKode;
            lodsejer.Kommune = "";
            lodsejerRegister[matrikel.lodsejerId] = lodsejer;
          }
          if (lodsejer && !lodsejer.centroide) {
            lodsejer.centroide = matrikel.centroide;
            lodsejerRegister[matrikel.lodsejerId] = lodsejer;
          }
        }
        },
        /* nyMark */ async (mark: BNBOMark) => {
          console.log("nyMark");
          safeAddToDoubleDepthObject(lodsejerMarker, mark.lodsejerId, mark.id, mark);
        },
        /* nyMarkDel */ async (lodsejerId:string, markId:string, markDel: BNBODel) => {
          console.log("nyMarkDel");
          let md = safeGetDoubleDepthObject(markDele, lodsejerId, markId) || [];
          md.push(markDel);
          safeAddToDoubleDepthObject(markDele, lodsejerId, markId, md);
        }
      );
      completed++;
      progressCallback && progressCallback(completed*100/count);
    }

    // Sæt tags afhængigt af om der er marker eller ej og om der er flere BNBOer på ejendommen
    for (let lodsejerId of Object.keys(lodsejerRegister)) {

      let tags = lodsejerRegister[lodsejerId].tags ||[];
      if (tags.includes(TmpNewLodsejerTag)) {
        // Process new lodsejer
        tags = tags.filter((val) => val !== TmpNewLodsejerTag);

        let nyTag:string;
        if (lodsejerMarker[lodsejerId]) {
          nyTag = "Har marker";
        } else {
          nyTag = "Ingen marker";
        }
        if (!tags.includes(nyTag)) {
          tags.push(nyTag);
        }
        if (lodsejerBNBOer[lodsejerId]) {
          if (Object.keys(lodsejerBNBOer[lodsejerId]).length > 1) {
            let multiBNBOTag="Flere BNBOer på ejendommen";
            if (!tags.includes(multiBNBOTag)) {
              tags.push(multiBNBOTag);
            }
          }
        } else {
          console.log("No BNBOer on property");
        }
        if (addTags && addTags.length > 0) {
          for (let tag of addTags) {
            if (!tags.includes(tag)) {
              tags.push(tag);
            }
          }
        }
        lodsejerRegister[lodsejerId].tags = tags;
      }
    }
    result = {lodsejerRegister, lodsejerBNBOer, lodsejerMarker, lodsejerMatrikler, markDele, lodsejerDele}
    return result;
  }

  /**
   * Version where the focus is the property. (Not only fields)
   * @param feature 
   * @param nySFE 
   * @param nyMatrikel 
   * @param nyMark 
   * @param nyMarkDel 
   */
  static async loadDataForPolygonVersion2(
    feature: any,
    lazyLoad: boolean,
    nySFE: (lodsejer: BNBOLodsejer, ejerlavsKode: number, matrikelNr: string, punkt: MitLatLng) => Promise<void>,
    nySFEBNBO:(lodsejerId:string, bnbo: BNBOBeskyttelsesOmråde) => void,
    nyLodsejerDel: (lodsejerId: string, del: BNBODel) => void,
    nyMatrikel: (matrikel: BNBOMatrikel) => Promise<void>,
    nyMark: (mark: BNBOMark) => Promise<void>,
    nyMarkDel: (lodsejerId:string, markId:string, mark: BNBODel) => Promise<void>
  ) {
    console.log("loadDataForPolygonVersion2", feature.geometry);

    let bnbo: BNBOBeskyttelsesOmråde;
    bnbo = { 
      dguNr:feature.properties.Dgunr,
      id: getBNBOId(feature.properties),
      geometry: feature.geometry,
      statusCode: feature.properties.Status_bnb || feature.properties.Status_bnbo_kode,
      statusTekst: feature.properties.Status_b_1 || feature.properties.Status_bnbo,
      anlaegsNavn: feature.properties.Anlaegsnav,
      kommuneNavn: feature.properties.Kommunenav,
      cvr_navn: feature.properties.CVR_navn,
      cvr_kode: feature.properties.CVR_kode,
    };
    // onBnboDispatch(setServitutAreal(feature));

    // Clone geometry
    let resMatr = await PropertyInfoInterface.fetchMat2CadasterWFSforPolygonFilterIntersectsPost(JSON.parse(JSON.stringify(feature.geometry)));

    let bnboAreaPolygon = Turf.polygon([PropertyInfoInterface.getCoords(feature.geometry)], feature.properties);

    let bnboAreaRemainder = this.clone(bnboAreaPolygon);
    bnboAreaRemainder.properties.bnbo_areal_m2 = Math.round(Turf.area(bnboAreaRemainder));
    bnboAreaRemainder.properties.bnbo_faktor = 0.5;

    
    bnboAreaRemainder.properties.bnbo_deltype = BNBOOmrådeType.Restareal;

    // Collect features for new layers
    let sfeNummerList: Array<number> = [];

    for (let matrikel of resMatr.features) {
      // Enrich with ownerdata
      let ejerlavsKode = matrikel.properties["mat:ejerlavskode"];
      let matrikelNr = matrikel.properties["mat:matrikelnummer"];
      let sfeNummer = matrikel.properties["mat:samletFastEjendomLokalId"];
      matrikel.sfeNummer = sfeNummer;
      sfeNummerList.push(sfeNummer);

      let sfeGeometry;
      let geoJson = await PropertyInfoInterface.getGeojsonOfSFE(sfeNummer);
      if (geoJson) {
        sfeGeometry = geoJson;
      }

      // Ejerlavsnavne er ikke retuneret fra WFS
      let EjerlavsNavne = {}
      let jordstykkeCentroideISFE;
      let sfe = await (await PropertyInfoInterface.sfeLookupByBfeNr(Number(sfeNummer))).json()
      sfe.features[0].properties.jordstykke.forEach((jordstykke) => {
        EjerlavsNavne = {...EjerlavsNavne, [jordstykke.properties.ejerlavskode]:jordstykke.properties.ejerlavsnavn}
        jordstykkeCentroideISFE = jordstykke.properties.centroide
      })

      let coords;
      if (jordstykkeCentroideISFE) {
        let tmp = parse(jordstykkeCentroideISFE[0].geometri);
        if (tmp?.coordinates[0]) {
          coords = GenerateGeomUtils.convertFromUTMtoLatLng({x:tmp.coordinates[0],y:tmp.coordinates[1]})
        }
      }
      let lodsejerId = getLodsejerId({ sfeNummer });
      await nySFE({
        id: lodsejerId,
        sfeNummer: sfeNummer,
        status: BNBOLodsejerStatus.Ny,
        geometry: sfeGeometry,
        tags: bnbo.statusTekst ? [bnbo.statusTekst] : [],
      },
        ejerlavsKode,
        matrikelNr,
        coords ? new MitLatLng(coords.lat, coords.lng) : new MitLatLng(1, 1)
      )
      await nySFEBNBO(lodsejerId, bnbo);
      await nyLodsejerDel(lodsejerId,
        {
          id: bnbo.id,
          type: BNBOOmrådeType.ServitutAreal,
          geometry: bnbo.geometry,
          arealBrutto: Turf.area(bnboAreaPolygon),
          arealNetto: Turf.area(bnboAreaPolygon),
          note: `BNBO areal for ${bnbo.dguNr}`,
        }
      )

      let matBNBOOverlap = Turf.intersect(Turf.featureCollection([bnboAreaPolygon, matrikel]), { properties: JSON.parse(JSON.stringify(matrikel.properties)) });
      if (matBNBOOverlap) {

        matrikel.properties.bnbo_areal_m2 = Math.round(Turf.area(matrikel));
        let center = Turf.centroid(matrikel.geometry);
        await nyMatrikel({
          lodsejerId: lodsejerId,
          id: getMatrikelId(matrikel.properties),
          geometry: matrikel.geometry,
          geometryNetto: matBNBOOverlap.geometry,
          centroide: {lat: center.geometry.coordinates[1], lng: center.geometry.coordinates[0]},
          matrikelNummer:matrikelNr,
          ejerlavsKode:ejerlavsKode,
          ejerlavsNavn: EjerlavsNavne[ejerlavsKode],
          arealBrutto: matrikel.properties.bnbo_areal_m2,
          arealNetto: Math.round(Turf.area(matBNBOOverlap)),
          bnboId: bnbo.id
        })
      } else {
        throw new Error("Unexpected no overlap, mat1");
      }
    }

    if (!lazyLoad) {
      let resMark = await PropertyInfoInterface.getMarkData(JSON.parse(JSON.stringify(feature.geometry)));
      // Lav relation mellem mark og matrikler
    let ftMarkMatrikelOverlap: any[] = [];
    for (let mark of resMark.features) {

      for (let matrikel of resMatr.features) {

        let matMarkOverlap = Turf.intersect(Turf.featureCollection([matrikel, mark]), {
          properties:
            { ...JSON.parse(JSON.stringify(mark.properties)), ...JSON.parse(JSON.stringify(matrikel.properties)) }
        });
        if (matMarkOverlap) {

          let matMarkBNBOOverlap = Turf.intersect(Turf.featureCollection([bnboAreaPolygon, matMarkOverlap]), { properties: this.clone(matMarkOverlap.properties) });
          let markBNBOOverlap = Turf.intersect(Turf.featureCollection([bnboAreaPolygon, mark]), { properties: this.clone(mark.properties) });

          if (matMarkBNBOOverlap && markBNBOOverlap) {

            ftMarkMatrikelOverlap.push(matMarkOverlap);
            
            await nyMark({
              lodsejerId: matrikel.sfeNummer,
              id: getMarkId(mark.properties),
              geometry: mark.geometry,
              geometryNetto: matMarkBNBOOverlap.geometry,
              afgrøde: matMarkOverlap.properties.Afgroede,
              markBlok: matMarkOverlap.properties.Markblok,
              markNr: matMarkOverlap.properties.Marknr,
              påvirketAfBnboIder: [],
              arealBrutto: Turf.area(mark),
              arealNetto: Turf.area(markBNBOOverlap),
              // sats: SettingsManager.getSystemSetting("bnbo.landbrugsArealPris.min",22)
            })

            matMarkBNBOOverlap.properties.bnbo_areal_m2 = Math.round(Turf.area(matMarkBNBOOverlap));
            await nyMarkDel(matrikel.sfeNummer, getMarkId(mark.properties), {
              id: getMarkId(mark.properties)+"-"+matMarkBNBOOverlap.properties.bnbo_areal_m2,
              geometry: matMarkBNBOOverlap.geometry,
              type: BNBOOmrådeType.ServitutAreal,
              arealBrutto: Turf.area(matMarkBNBOOverlap),
              arealNetto: Turf.area(matMarkBNBOOverlap),
              note: "",
              farve: "blue",
            })

            // Subtract the overlap from the remaining area
            let subs = Turf.difference(Turf.featureCollection([bnboAreaRemainder, matMarkBNBOOverlap]));
            if (subs) {
              subs.properties!.bnbo_areal_m2 = Math.round(Turf.area(subs));
              subs.properties!.bnbo_faktor = 0.5;
              subs.properties!.bnbo_deltype = BNBOOmrådeType.Restareal;
              bnboAreaRemainder = subs as any;
            }
          }
        }          
        }
      }    
    }

  }

  static async loadFradragsarealerForSFE(
    lodsejer: BNBOLodsejer,
    nyLodsejerOmråde: (lodsejer: BNBOLodsejer, del: BNBODel) => Promise<void>
  ) {
    if (lodsejer.geometry.features && lodsejer.geometry.features.length > 0) {
      for (let ft of lodsejer.geometry.features) {
        await this._loadFradragsarealerForSFE(ft, lodsejer, nyLodsejerOmråde);
      }
    } else {
      await this._loadFradragsarealerForSFE(lodsejer, lodsejer, nyLodsejerOmråde);
    }
  }

  static async _loadFradragsarealerForSFE(
    feature: any,
    lodsejer: BNBOLodsejer,
    nyLodsejerDel: (lodsejer: BNBOLodsejer, del: BNBODel) => Promise<void>
  ) {
    try {
    let resP3: any = await PropertyInfoInterface.getBeskyttetNatur(JSON.parse(JSON.stringify(feature.geometry)));

    function fixGeoJSONType(geometry) {
      if (geometry.type === "MultiPolygon") {
        return geometry;
      } else {
        return Turf.multiPolygon([geometry.coordinates], geometry.properties).geometry;
      }
    }
    // Paragraf 3 områder
    for (let p3 of resP3.features) {
      let overlap = Turf.intersect(Turf.featureCollection([feature, p3]), { properties: this.clone(p3.properties) });
      if (overlap) {

        let id="";
        await nyLodsejerDel(lodsejer,
          {
            id: MapitUtils.getNewUuid(),
            type: BNBOOmrådeType.Par3Areal,
            geometry: p3.geometry,
            arealBrutto: Turf.area(p3),
            arealNetto: Turf.area(overlap),
            note: `Overlap med §3 (${p3.properties.Natyp_navn})`
          }
        )
      } else {
        throw new Error("Unexpected no overlap");
      }
    }
    // Fredskovsområder
    let resFredskov = await PropertyInfoInterface.fetchFredskovWFSforPolygonFilterIntersectsPost(JSON.parse(JSON.stringify(feature.geometry)));
    for (let fred of resFredskov.features) {
      let overlap = Turf.intersect(Turf.featureCollection([feature, fred]), { properties: this.clone(fred.properties) });
      if (true || overlap) {

        let id="";
        await nyLodsejerDel(lodsejer,
          {
            id:MapitUtils.getNewUuid(),
            type: BNBOOmrådeType.FredskovsAreal,
            geometry: fixGeoJSONType(fred.geometry),
            arealBrutto: Turf.area(fred),
            arealNetto: (overlap && Turf.area(overlap))||0,
            note: `Fredskov`
          }
        )

      } else {
        throw new Error("Unexpected no overlap");
      }
    }
  } catch (error) {
    console.error("Error loading fradragsarealer: "+error);
    throw new Error("Error loading fradragsarealer: "+error);
  }
}

   /** Converts EPSG:3857 web mercator (Maplibre, Turf, ...) polygon to UTM32N. Then calculates planar area */
   public static calculatePlanarAreaFromWebMercatorCoords(feature: any): number {
    let transformedGeometry = GenerateGeomUtils.geoJsonCoordsTransformer<number>(feature.geometry, (coord) => {
       let utm = GenerateGeomUtils.convertFromLatLngToUTM({ lng: coord[0], lat: coord[1] })
       return [utm.x, utm.y];
    })
     
    let outsideArea = areaOfUTM32NPolygon(transformedGeometry.coordinates[0][0]);
    let insideArea=0;
    // Subtract area of any 'holes' in the polygon 
    for (let i = 1; i < transformedGeometry.coordinates[0].length; i++) {
       insideArea += areaOfUTM32NPolygon(transformedGeometry.coordinates[0][i]);
    }
    return outsideArea - insideArea;

    function areaOfUTM32NPolygon(coordList: number[][]) {
       let coordGrid = coordList.map((coord) => { return [coord[0], coord[1]]; });
       let sum = 0;
       for (let i = 0; i < coordGrid.length - 1; i++) {
          sum += coordGrid[i][0] * coordGrid[i + 1][1] - coordGrid[i + 1][0] * coordGrid[i][1];
       }
       let area = Math.abs(sum / 2);
       return area;
    }
 }
}

export function formatMatrikelNumre(features: {properties: {"Matrikelnummer":string,"Ejerlavskode":string,"Ejerlavsnavn":string}}[]) {
  function valueFormat(value: string[]) {
     if (value.length === 1) {
        return value[0]
     } else if (value.length === 2) {
        return `${value[0]} & ${value[1]}`
     } else {
        return value.slice(0,-1).join(", ") + " & " + value.slice(-1)
     }
  }

  let simplify = features.map((a) => a.properties)
  let matByEjerlav:{[key:string]:string[]} = simplify.reduce((acc,cur) => {
     if (acc[cur.Ejerlavsnavn]) {
        acc[cur.Ejerlavsnavn].push(cur.Matrikelnummer)
     } else {
        acc[cur.Ejerlavsnavn] = [cur.Matrikelnummer]
     }
     return acc
  },{})
  return Object.entries(matByEjerlav).map(([key, value]) => `${valueFormat(value)} ${key}`).join(" \n ")
}
