import { ADModalBody, ADModalFooter, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassColorBox, GlassInputControl, GlassInputGroup } from "./GlassInput";
import { Fragment, useContext, useEffect, useMemo, useState } from "react";
import { MapitStateContext } from "src/states/MapitState";
import { GlassButton, GlassButtonRow } from "./GlassButtons";
import { DataDivisionType, LayerInfo } from "src/common/managers/Types";
import { Typeahead } from "react-bootstrap-typeahead";
import { Localization } from "@viamap/viamap2-common";
import { GenerateGeomUtils } from "src/managers/GenerateGeomUtils";
import jsPDF, { TextOptionsLight } from "jspdf";
import { PropertyInfoInterface } from "src/managers/PropertyInfoInterface";
import { PropertyInformation } from "src/managers/PropertyInformation";
import chroma from "chroma-js";
import { VLine } from "./MitGlassComponents";
import { BsArrowLeft, BsDownload, BsHouse, BsPrinter } from "react-icons/bs";
import { ViewButtonIcon, ViewButtonText } from "src/propertyInfoTemplates/PropertyComponents";
import { Spinner } from "react-bootstrap";

export function Datarapport(props: {onGenerate: (pdf: jsPDF) => void}) {
   const {state: mapitState, dispatch} = useContext(MapitStateContext);
   
   const [data, setData] = useState<any[]>([]);
   const [loading, setLoading] = useState(false);

   let usableLayers = useMemo(() => {
      return Object.values(mapitState.layers).filter(layer => layer.propertiesInGeoJson?.includes("SFEnummer"));
   },[mapitState.layers])

   const [layerId, setLayerId] = useState<number[]>(usableLayers.map((a) => a.layerId));

   useEffect(() => {
      setLayerId((a) => a.filter((b) => usableLayers.map((a) => a.layerId).includes(b)));
   },[mapitState.layers])

   let colorList = useMemo(() => {
      return SFEAndColorList(
         layerId.map((a) => mapitState.layers[a])
      );
   },[layerId])

   const dataUrl = useMemo(() => {
      return docDataUrl(createPDF(data, colorList))
   },[data, colorList])

   return (
      <>
         <ADModalBody>
            <GlassInputGroup>
               <label>{Localization.getText("Document")}</label>
                  <GlassInputControl>
                     <select disabled={true}>
                        <option value={"Matrikeloversigt"} >{Localization.getText("Cadaster Overview")}</option>
                     </select>
                  </GlassInputControl>
               <label>{Localization.getText("Layers")}</label>
                  <Typeahead
                     multiple={true}
                     id="layerTypeahead"
                     options={usableLayers.map((a) => ({id: a.layerId, datasetname: a.datasetname}))}
                     labelKey={"datasetname"}
                     placeholder={Localization.getText("please select") + " " + Localization.getText("layer")}
                     emptyLabel={Localization.getText("No results found")}
                     onChange={(selected) => setLayerId(selected.map((a: any) => a.id))}
                     selected={usableLayers.map((a) => ({id: a.layerId, datasetname: a.datasetname})).filter((a) => layerId.includes(a.id))}
                  />
               <label></label>
               <GlassButtonRow>
               <GlassButton onClick={() => setLayerId(usableLayers.map((a) => a.layerId)) }>{Localization.getText("Select All")}</GlassButton>
               <GlassButton onClick={() => setLayerId([])}>{Localization.getText("Remove All")}</GlassButton>
               </GlassButtonRow>
               <VLine color={"#9d9d9d"}/><VLine color={"#9d9d9d"}/>
               <label>{Localization.getText("Properties")} {colorList.length}</label>
               <div style={{display: "flex", flexWrap: "wrap", gap:"2px"}}>
                  {colorList.map((a) => {
                     return (
                     <div
                      title={a.sfe+""}
                      style={{background:a.color, borderRadius:"5px", padding:"3px", display:"flex", justifyContent:"center"}}>
                      <BsHouse style={{aspectRatio:"1", color:"white"}} />
                     </div>
                    )
                  })}

                  
               </div>
            </GlassInputGroup>


         </ADModalBody>
         <ADModalFooter>
            {loading ? <Spinner /> : <GlassButton disabled={!colorList.length} onClick={async () => {
               setLoading(true);
               let pdf = createPDF(await hentDataOgBelligenhed(colorList), colorList);
               if (pdf) {
                  props.onGenerate(pdf);
               }
            }}
            >{Localization.getText("Generate")}</GlassButton>}
         </ADModalFooter>
      </>
   );
}

async function hentData(liste: {sfe: number}[]) {
   let x = Promise.all(liste.map(async (a) => {
      return await (await PropertyInfoInterface.sfeLookupByBfeNr(a.sfe)).json();
   }))
   let data = (await x).map((a) => a.features.filter((b) => b.properties.status == "Gældende")[0].properties);
   console.log(data);
   return data;
}

async function hentBelligenhed(liste: {sfe: number}[]) {
   let x = Promise.all(liste.map(async (a) => { 
      return await (await PropertyInfoInterface.EBRadresseForBFE(a.sfe+""));
   }))
   return ((await x).map((a) => EBRToActualAdresse(a)));
}

async function hentDataOgBelligenhed(liste: {sfe: number}[]) {
   let data = await hentData(liste);
   let adresser = await hentBelligenhed(liste);
   return data.map((item, idx) => ({...item, adresse: adresser[idx]}));
}

function EBRToActualAdresse(ebr: any):string {
   console.log(ebr);
   let data = (ebr.features.filter((a) => a.properties.status == "gældende"))[0]?.properties;
   if (data.betegnelse) {
      return data.betegnelse as string;
   }
   if (data.husnummer.length) { 
      return data.husnummer.map((a) => a.adgangsadressebetegnelse)[0] as string;
   }
   return "Ukendt adresse";
}


export function DataRapportDialog(props: {show: number, onClose: () => void}) {
   const [data, setData] = useState<jsPDF|null>(null);
   const [printUrl, setPrintUrl] = useState<string|null>(null);

   const dataUrl = useMemo(() => {
      return docDataUrl(data);
   },[data])

   useEffect(() => {
      if (!props.show) {
         setData(null);
         setPrintUrl(null);
      }
   },[props.show])

   if (!props.show) {
      return null;
   }

   if (data !== null) {
      return (
         <AdvancedDragModal topUpdate={props.show}
      PosDefault={{top: "100px", left: "100px", width: "500px", height: "700px"}}
      title={Localization.getText("Preview")} onClose={() => {props.onClose()}}
      subHeaderContent={<>
      <ViewButtonIcon title={Localization.getText("Go back")} onClick={() => {setData(null); setPrintUrl(null);}}><BsArrowLeft /></ViewButtonIcon>
      <ViewButtonIcon title={Localization.getText("Save")} onClick={() => docSave(data)}><BsDownload /></ViewButtonIcon><ViewButtonIcon title={Localization.getText("Print")} onClick={() => docPrint(data, (e) => setPrintUrl(docDataUrl(e)))}><BsPrinter/></ViewButtonIcon></>}
      >
         <ADModalBody>
         {printUrl ? <iframe src={printUrl+"#toolbar=0"} style={{height:"0px"}} ></iframe>: null}
         {dataUrl ? <iframe src={dataUrl+"#toolbar=0"} style={{flex:1, marginTop:"1em"}} ></iframe>: null}
         </ADModalBody>
      </AdvancedDragModal>
      )
   }  

   return (
      <AdvancedDragModal topUpdate={props.show}
      PosDefault={{top: "100px", left: "100px", width: "382px"}}
      title={Localization.getText("Data report")} onClose={props.onClose}>
         <Datarapport onGenerate={(pdf: jsPDF) => setData(pdf)} />
      </AdvancedDragModal>
   )
}

function SFEAndColorList(layerInfoArr: LayerInfo[]) {
   let list:{sfe:number, color: string}[] = [] 
   let sfeList = new Set<number>();
   let validLayers = layerInfoArr.filter(layer => layer.propertiesInGeoJson?.includes("SFEnummer"));

   validLayers.forEach(layer => {
      let getColor = (p) => layer.styling.color && chroma(layer.styling.color).hex("rgb") || "black";
      if (layer.styling.colorByProperty) {
         if (layer.styling.colorByValue?.divisions?.type == DataDivisionType.Discrete) {
            let style = layer.styling
            getColor = (properties) => {
               if (style.colorByProperty) {
                  let x = GenerateGeomUtils.getColorByValueDiscrete(undefined as any, properties[style.colorByProperty], style.colorByValue?.divisions, style.outsideColorRange);
                  return x.color && chroma(x.color).hex("rgb") || "black";
               }
               return "black";
            }
         } else if (layer.styling.colorByValue?.divisions?.type == DataDivisionType.Continous) {
            let style = layer.styling
            getColor = (properties) => {
               if (style.colorByProperty) {
                  let x = GenerateGeomUtils.getColorByValueNummeric(undefined as any, properties[style.colorByProperty], style.colorByValue?.divisions, style.outsideColorRange);
                  return x.color && chroma(x.color).hex("rgb") || "black";
               }
               return "black";
            }
         }
      }
      layer.geoJson?.features?.forEach(feature => {
         let sfe = feature.properties?.SFEnummer;
         if (sfe && !sfeList.has(sfe)) {
            sfeList.add(sfe);

            list.push({sfe: sfe, color: getColor(feature.properties)});
         }
      });
   });
   return list;
}

function createPDF(data: any[], colorList: {sfe: number, color: string}[]): jsPDF | null {

   let doc = new jsPDF({orientation: "p", unit: "mm", format: "a4"});
   
   const arealFormat = (a:number) => Intl.NumberFormat("da-DK", {style: "decimal", minimumFractionDigits: 2, maximumFractionDigits: 2}).format(a);
   const vejFormat = (a:number) => Intl.NumberFormat("da-DK", {style: "decimal", minimumFractionDigits: 0, maximumFractionDigits: 0}).format(a);

   const matSort = (a, b) => {
      let parseEjerlav =
        parseInt(a.properties.ejerlavskode, 10) -
        parseInt(b.properties.ejerlavskode, 10);
      if (parseEjerlav !== 0) {
        return parseEjerlav;
      }
      return PropertyInformation.matrikelSortFunc(
        a.properties.matrikelnummer || "",
        b.properties.matrikelnummer || ""
      );
    };

   if (data.length == 0) {
      return null;
   }

   doc.text("Matrikeloversigt", 110, 10, {align: "center"});
   doc.setFontSize(7);
   doc.text("Matrikeloversigt", 15, 10);
   doc.text("Mapit", 15, 13);
   doc.text("Dato: " + new Date().toLocaleDateString("da-dk"),220 - 25, 10, {align: "right"});
   doc.text("Side: 1", 220 - 25, 13, {align: "right"});
   let currentPage = 1;


   let top = 15;
   let left = 15;
   let height = 5;

   let header = (doc as any).cellInitialize?.();
   let headerHeight = 14;
   let totalHeight = 5;
   doc.setFontSize(12);
   data.forEach((a, idx) => {
      let length = a.jordstykke.filter((a) => a.properties.status == "Gældende").length * height + headerHeight + totalHeight;
      if (top + length > 260) {
         doc.cellAddPage();
         currentPage++;
         doc.setFontSize(7);
         doc.setFont(undefined as any, "normal");
         doc.text("Ejendomstabel", 15, 10);
         doc.text("Mapit", 15, 13);
         doc.text("Dato: " + new Date().toLocaleDateString("da-dk"),220 - 25, 10, {align: "right"});
         doc.text("Side: "+currentPage, 220 - 25, 13, {align: "right"});
         top = 15;
      } else if (idx > 0) {
         top += 2;
         doc.setFillColor("#000000");
         // customCell(doc, left, 5, 10, 18, "", -1, "left");
         doc.setFillColor("#000000");
      }
      let start = top;
      
      // doc.cellAddPage();
      console.log(a);
      doc.setFontSize(12);
      customCell(doc, left, top, 10, 14, " ", 0, "left");
      customCell(doc, left+10, top, 170, 14,a.adresse, 0, "left");
      doc.setFontSize(9);
      doc.text("SFE: "+a.BFEnummer, 27, top+7.5);
      doc.setFontSize(12);
      top += 9;
      customCell(doc, left, top, 10, height, " ", 1, "left")
      customCell(doc, left+10, top, 30, height, "Matr.nr", 1, "left", true)
      customCell(doc, left+40, top, 80, height, "Ejerlav", 1, "left", true)
      customCell(doc, left+120, top, 30, height, "Areal", 1, "left", true)
      customCell(doc, left+150, top, 30, height, "Vejareal", 1, "left", true)
      let jordstykker = a.jordstykke.filter((a) => a.properties.status == "Gældende").toSorted(matSort).map((a) => a.properties);
      jordstykker.forEach((a, idx) => {
         top += 5;
         customCell(doc, left, top, 10, height, " ", 2+idx, "left")
         customCell(doc, left+10, top, 30, height, a.matrikelnummer || " ", 2+idx, "left")
         customCell(doc, left+40, top, 80, height, a.ejerlavsnavn || " ", 2+idx, "left")
         customCell(doc, left+120, top, 30, height, arealFormat(a.registreretAreal/10000)+" ha" || " ", 2+idx, "right")
         customCell(doc, left+150, top, 30, height, vejFormat(a.vejareal)+"m²" || " ", 2+idx, "right")
      });
      top += 5;
      // customCell(doc, left, top, 10, height, " ", 2+jordstykker.length, "left")
      // customCell(doc, left+10, top, 30, height, "", 2+jordstykker.length, "left")
      customCell(doc, left, top, 120, height, "Total", 2+jordstykker.length, "right", true)
      customCell(doc, left+120, top, 30, height, arealFormat(jordstykker.reduce((sum, a) => sum + a.registreretAreal, 0)/10000)+" ha", 2+jordstykker.length, "right", true)
      customCell(doc, left+150, top, 30, height, vejFormat(jordstykker.reduce((sum, a) => sum + a.vejareal, 0))+"m²", 2+jordstykker.length, "right", true)

      colorList.filter((b) => b.sfe == a.BFEnummer)[0] && doc.setFillColor(colorList.filter((b) => b.sfe == a.BFEnummer)[0].color);
      doc.rect(15, start, 10, length, "FD");

      top = start + length;
   });

   return doc;
}

function docDataUrl(doc?: jsPDF | null) {
   return doc?.output("dataurlstring", {filename: "Matrikeloversigt.pdf"}) || "";
}

function docSave(doc?: jsPDF | null) {
   if (doc) {
      doc.save("Matrikeloversigt.pdf");
   } else {
      console.error("No document to save");
   }
}

function docPrint(doc?: jsPDF | null, update?: (a: jsPDF) => void) {
   if (doc) {
      doc.autoPrint();
      update && update(doc);
   } else {
      console.error("No document to print");
   }
}

function colorCell(doc: jsPDF, x, y, width, height, value, ln, align) {
   doc.setDrawColor("#000000");
   doc.setFillColor(value);
   doc.rect(x, y, width, height, "FD");
}

function customCell(doc: jsPDF, x, y, width, height, value, ln, align?: TextOptionsLight["align"], bold?: boolean) {
   doc.setDrawColor("#000000");
   doc.rect(x, y, width, height, "S");
   if (bold) {
      doc.setFont(undefined as any, "bold");
   } else {
      doc.setFont(undefined as any, "normal");
   }

   if (align == "right") { 
      doc.text(value+"" || " ", x+width-2, y+4, {align: align || "left"});
   } else {
      doc.text(value+"" || " ", x+2, y+4, {align: align || "left", });
   }
}
