import { SettingsManager } from "@viamap/viamap2-common";

/**
 * @class ViamapApi
 * @description Makes it easier to call ViamapAPI
 */
export class ViamapApi {

  static async recall(tries:number, delayMS:number, call : Promise<any>) {
    return new Promise(async (resolve, rejects) => {
      let x = 0;
      let succes = false
      while (!succes && x < tries) {
        call.then((succes) => {
          resolve(succes);
        })
        await (new Promise<void>((resolve, reject) => {setTimeout(() => resolve(), delayMS)}))
        x++;
      }
      rejects("error");
    });
  }

  /**
   * @param types The type - code for the poi
   * @returns Returns an promise with the nearest poi
   */
  static callNearestPoi(types: string, lat :number, lng: number) {
    return this._makeCall("nearestpoi",`&fromlatlng=${lat},${lng}&poitypes=${types}&decodepolyline`)
  }

  /**
   * @returns Returns the nearest addres to the coordinates
   */
  static callReverseGeoCode(lat:number, lng:number):Promise<any> {
    return this._makeCall("address",`&reverselatlng=${lat},${lng}`)
  }

  /**
   * @returns returns the Route between to points
   */
  static callRoute(mode:string,lat:number,lng:number,dlat:number,dlng:number) {
    return this._makeCall("router",`&mot=${mode}&fromlatlng=${lat},${lng}&tolatlngs=${dlat},${dlng}&zoomlevel=22&decodepolyline`)
  }

  /**
   * @returns returns the Pois of type: type, inside the bbox
   */
  static callPois(type:string, bbox:any) {
    let {x,y,dx,dy} = bbox;
    return this._makeCall("getpoi",`&poitypes=${type}&bbox=${x},${y}+${dx},${dy}&decodepolyline`)
  }

  static async _makeCall(apiCall:string ,addInfo: string):Promise<any> {
    /* tslint:disable-next-line */
    let token = SettingsManager.getSystemSetting("viamapAPIToken");
    let prefix = "mapit";
    let url = "https://" + prefix + ".poi.viamap.net/v1/"+apiCall+"/?token="+token+addInfo
    const request = await fetch(url);
    return request.json()
  }
}