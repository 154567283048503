import * as React from "react";
const SvgCatchment = (props) => /* @__PURE__ */ React.createElement("svg", { width: 46.360699, height: 46.794941, viewBox: "0 0 12.266268 12.381161", id: "svg1", xmlSpace: "preserve", "sodipodi:docname": "catchment.svg", "inkscape:version": "1.4-beta (62f545ba5e, 2024-04-22)", "xmlns:inkscape": "http://www.inkscape.org/namespaces/inkscape", "xmlns:sodipodi": "http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd", xmlns: "http://www.w3.org/2000/svg", "xmlns:svg": "http://www.w3.org/2000/svg", ...props }, /* @__PURE__ */ React.createElement("sodipodi:namedview", { id: "namedview1", pagecolor: "#ffffff", bordercolor: "#000000", borderopacity: 0.25, "inkscape:showpageshadow": 2, "inkscape:pageopacity": 0, "inkscape:pagecheckerboard": 0, "inkscape:deskcolor": "#d1d1d1", "inkscape:zoom": 8.7082063, "inkscape:cx": 9.9331592, "inkscape:cy": 28.306633, "inkscape:window-width": 1920, "inkscape:window-height": 1009, "inkscape:window-x": 1918, "inkscape:window-y": -8, "inkscape:window-maximized": 1, "inkscape:current-layer": "g3" }), /* @__PURE__ */ React.createElement("defs", { id: "defs1" }, /* @__PURE__ */ React.createElement("style", { id: "style1" }, ".cls-1{fill:currentColor;}.cls-2{fill:none;stroke:currentColor;stroke-linecap:round;}"), /* @__PURE__ */ React.createElement("style", { id: "style1-1" }, ".cls-1{fill:currentColor;}.cls-2{fill:none;stroke:currentColor;stroke-linecap:round;}")), /* @__PURE__ */ React.createElement("g", { id: "layer1", transform: "translate(-0.1850796,-0.15395817)" }, /* @__PURE__ */ React.createElement("g", { id: "g1", transform: "matrix(4.1478755,0,0,4.1478755,-1.0304393,42.006132)" }, /* @__PURE__ */ React.createElement("g", { id: "g2", transform: "matrix(0.07592066,0,0,0.07592066,1.649716,-8.2333562)" }, /* @__PURE__ */ React.createElement("g", { id: "g3", transform: "translate(4.5461842e-6,-3.3027215)" }, /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#29f032",
  fillOpacity: 0.892922,
  stroke: "none",
  strokeWidth: 2.11047,
  strokeDasharray: "6.33139, 6.33139",
  strokeDashoffset: 0,
  strokeOpacity: 1
}, d: "m -4.0542187,7.7825923 -8.9100563,3.5640227 -3.983319,-0.419298 0.419298,3.668848 -0.73377,2.620604 8.2811103,0.209648 1.3627147,-2.935077 10.167946,1.782012 4.402616,-4.297793 8.49076,-1.782011 c 0,0 1.677187,5.76533 2.201308,5.76533 0.52412,0 2.72543,-1.677186 2.72543,-1.677186 l 0.209647,-5.6605059 -6.499099,-2.6206054 3.564022,-4.2977911 -7.7569908,0.5241208 4.1929688,-4.50744 c 0,0 3.77367,-1.0482427 4.192968,-1.0482427 0.419295,0 0.104821,-2.9350765 0.104821,-2.9350765 l -8.176287,1.4675387 -3.2495502,3.3543733 -9.4341769,1.15306674 -8.0714629,-1.88683564 -5.660505,-4.297792 -1.153066,4.7170889 5.136385,1.67718673 0.628945,3.56402227 5.4508576,1.0482418 z", id: "path3" }), /* @__PURE__ */ React.createElement("path", { style: {
  fill: "#63a704",
  fillOpacity: 0.89292198,
  stroke: "none",
  strokeWidth: 1.94252,
  strokeDasharray: "5.82755, 5.82755",
  strokeDashoffset: 0,
  strokeOpacity: 1
}, d: "M 5.2861961,1.3277169 10.110315,-2.6280616 6.5404666,2.5819882 5.3826783,4.029224 7.1193616,5.0905301 9.8208687,4.6081182 9.7243857,3.4503299 12.329411,3.7397765 10.785693,5.6694251 8.3736321,6.9236956 11.268105,7.7920372 13.873129,7.9850025 12.908305,8.7568611 c 0,0 -2.508542,0.9648239 -2.9909541,0.6753772 -0.4824119,-0.2894475 -4.4381904,0 -4.4381904,0 L -0.8886776,13.002087 -6.0022444,11.458368 -13.045459,14.352841 -0.79219538,7.5025906 -3.3007372,3.9327418 -7.6424454,1.4241991 0.17262848,2.485506 Z", id: "path4" }), /* @__PURE__ */ React.createElement("g", { id: "g6-8-7-5", transform: "matrix(1.2373315,0,0,1.2373315,4.8950712,-33.83986)" }, /* @__PURE__ */ React.createElement("path", { className: "cls-1", d: "m -3.5132781,10.892715 c -3.3995957,0.352706 -6.2270913,2.971281 -6.7661859,6.252517 -0.28605,1.81697 0.09902,3.52706 0.9351641,4.937885 l 5.4239505,9.116918 c 0.5721002,0.929861 1.9693455,0.929861 2.5194419,0 l 5.4239501,-9.116918 c 0.6491137,-1.100871 1.034182,-2.383439 1.034182,-3.740822 0,-4.403483 -3.9166868,-7.919856 -8.5595007,-7.438892 z m 0.8581508,11.115586 c -2.0793649,0 -3.7626597,-1.635274 -3.7626597,-3.655318 0,-2.020044 1.6832948,-3.655317 3.7626597,-3.655317 2.07936436,0 3.7626593,1.635273 3.7626593,3.655317 0,2.020044 -1.68329494,3.655318 -3.7626593,3.655318 z", id: "path2-2-1-7", style: {
  fill: "currentColor",
  strokeWidth: 1.08439
} })))))));
export default SvgCatchment;
