import { useContext, useEffect } from "react";
import { ApplicationStateContext, actionSetDeviceScreenSize } from "src/states/ApplicationState";
import { DeviceScreenSize } from "src/states/ApplicationStateFeatureMapping";

export type DeviceType = "Mobile"|"Tablet"|"Desktop"

export function DeviceDetector(props:{debug?: boolean}) {
   const {dispatch: applicationStateDispatch} = useContext(ApplicationStateContext)
   
   useEffect(() => {
      if (window) {
         const x = window
         window.onresize = (ev:UIEvent) => {
            let smallestSize = Math.min(x.innerHeight, x.innerHeight)
            if (x.innerWidth < 500) {
               applicationStateDispatch(actionSetDeviceScreenSize(DeviceScreenSize.Phone))
            } else if (x.innerWidth < 900) {
               applicationStateDispatch(actionSetDeviceScreenSize(DeviceScreenSize.Tablet))
            } else {
               applicationStateDispatch(actionSetDeviceScreenSize(DeviceScreenSize.Normal))
            }
            
         }
         if (x.innerWidth < 500) {
            applicationStateDispatch(actionSetDeviceScreenSize(DeviceScreenSize.Phone))
         } else if (x.innerWidth < 850) {
            applicationStateDispatch(actionSetDeviceScreenSize(DeviceScreenSize.Tablet))
         } else {
            applicationStateDispatch(actionSetDeviceScreenSize(DeviceScreenSize.Normal))
         }
      }

      if (iOS()) {
         document.body.classList.add("OnIOS")
      } else {
         document.body.classList.add("NotIOS")
      }

      
   },[])
   
   return <>
   {}
   </>
}


function iOS() {
   return [
     'iPad Simulator',
     'iPhone Simulator',
     'iPod Simulator',
     'iPad',
     'iPhone',
     'iPod'
   ].includes(navigator.platform)
   // iPad on iOS 13 detection
   || (navigator.userAgent.includes("Mac") && "ontouchend" in document)
 }