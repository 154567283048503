/**
 * Class for caching mechanism for Oblique Services
 */
import {Debuglog, UtmCoords, LoadedImageSpec, ImageMetaData} from './ObliqueFunc';

import {DynamoDB} from 'aws-sdk';
import * as AWS from 'aws-sdk';

// const doc = require('dynamodb-doc');

const dynamo = new DynamoDB();

const tableName = "ObliqueCache";

export enum CacheType {
   // Name = "directory in S3"
   RawImageCache="rawcache",
   ResultImageCache="resultcache"
}

/**
 * Caching of images
 */
export class ImageCacheStore {
   static s3 = new AWS.S3();
   static bucket = "obliquephoto";
   // static directory = "cache";

   static async get(cacheType:CacheType, cacheKey:string):Promise<LoadedImageSpec> {
      let directory = cacheType.toString();
      var params = {
         Bucket: ImageCacheStore.bucket, 
         Key: directory+"/"+cacheKey
        };
      let result:LoadedImageSpec|undefined=undefined;
      
      return new Promise<LoadedImageSpec>((resolve, reject) => {
         ImageCacheStore.s3.getObject(params, (err, response) => {
            if(err) {
               reject("Could not read: "+err);
            } else {
               Debuglog.log("Got result from getObject. Metadata: "+JSON.stringify(response.Metadata));
               let result2 = {
                  image: response.Body as HTMLImageElement,
                  /* tslint:disable */
                  height: response.Metadata && response.Metadata["height"] && Number.parseInt(response.Metadata["height"]) || 0,
                  width: response.Metadata && response.Metadata["width"] && Number.parseInt(response.Metadata["width"]) || 0,
                  x: response.Metadata && response.Metadata["x"] && Number.parseInt(response.Metadata["x"]) || 0,
                  y: response.Metadata && response.Metadata["y"] && Number.parseInt(response.Metadata["y"]) || 0,
                  pictureDate: (response.Metadata && response.Metadata["picturedate"] && new Date(response.Metadata["picturedate"])) || undefined
                  /* tslint:enable */
               };
               resolve(result2);
            }
         });
      });
   }

   static async put(cacheType:CacheType, cacheKey:string, data:LoadedImageSpec, imageMetaData:ImageMetaData, x?:number, y?:number) {
      let directory = cacheType.toString();
      // Convert meta data to strings to store in S3.
      let metaDataAsStrings = Object.keys(imageMetaData).reduce<{}>(
         (prev:{}, curr:string, idx:number) => { 
            prev[curr] = imageMetaData[curr] ? imageMetaData[curr].toString() : "";
            return prev;
         }, 
         {}
         );
      var params = {
         Bucket: ImageCacheStore.bucket, 
         Key: directory+"/"+cacheKey,
         "Body": data.image,
         Metadata: {
            height: data.height.toString(),
            width: data.width.toString(),
            timestamp: Date.now().toString(),
            x:(x && x.toString() ) || "",
            y:(y && y.toString() ) || "",
            ...metaDataAsStrings
         }
        };
      
      const response:any = await ImageCacheStore.s3.upload(params, (err) => {
         if(err) {
            throw new Error("Could not write "+cacheKey+" err:"+err);
         }
      });
   }

}

export class ObliqueCache {
   static precisionMeters=1;

   static createCacheKeyByCoords(coords:UtmCoords, direction:string, zoomFactor:number, shallImageBeCentered:boolean):string {
      function reducePrecision (coords2:UtmCoords) {
         function toPrecisionMeters (coord:number, precision:number) {
            return Math.floor(coord/precision)*precision;
         }
         return {x:toPrecisionMeters(coords2.x,ObliqueCache.precisionMeters),y:toPrecisionMeters(coords2.y,ObliqueCache.precisionMeters)};
      }
      let coarseCoords=reducePrecision(coords);
      let cacheKey = ""+direction+"_"+zoomFactor+"_"+"("+coarseCoords.x+","+coarseCoords.y+")"+(shallImageBeCentered?"[C]":"");
      return cacheKey;
   }

   static createCacheKeyByImageId(imageId: string, zoomFactor:number, tileX:number, tileY:number):string {
      let cacheKey = ""+imageId+"_"+zoomFactor+"_"+"("+tileX+","+tileY+")";
      return cacheKey;
   }

   static async getImageFromCacheByImageId(imageId: string, zoomFactor:number, tileX:number, tileY:number):Promise<LoadedImageSpec> {
      // Lookup in database
      let cacheKey = this.createCacheKeyByImageId(imageId, zoomFactor, tileX, tileY);
      let cacheResult;
      try {
         cacheResult = await ImageCacheStore.get(CacheType.RawImageCache, cacheKey);
      } catch (error:any) {
         console.error("Not found in cache "+error);
      }
      return cacheResult;
   }

   static async putImageToCacheByImageId(imageId: string, zoomFactor:number, tileX:number, tileY:number, image:LoadedImageSpec, metaData:ImageMetaData):Promise<any> {
      // Store in database
      let cacheKey = this.createCacheKeyByImageId(imageId, zoomFactor, tileX, tileY);
      try {
         await ImageCacheStore.put(CacheType.RawImageCache, cacheKey, image, metaData);
      } catch (error:any) {
         console.error("Could not write to cache "+error);
      }
      return;
   }


   static async getImageFromCacheByCoords(coords:UtmCoords, direction:string, zoomFactor:number, shallImageBeCentered:boolean):Promise<{imagebase64:string, height:number, width:number}> {
      // Lookup in database
      let cacheKey = this.createCacheKeyByCoords(coords, direction, zoomFactor, shallImageBeCentered);
      let cacheResult;
      try {
         cacheResult = await ImageCacheStore.get(CacheType.ResultImageCache, cacheKey);
      } catch (error:any) {
         console.error("Not found in cache "+error);
      }
      return cacheResult;
   }
   
   static async putImageToCacheByCoords(coords:UtmCoords, direction:string, zoomFactor:number, shallImageBeCentered:boolean, image:LoadedImageSpec, metaData:ImageMetaData, x?:number, y?:number)
   :Promise<any> {
      // Store in database
      let cacheKey = this.createCacheKeyByCoords(coords, direction, zoomFactor, shallImageBeCentered);
      try {
         await ImageCacheStore.put(CacheType.ResultImageCache, cacheKey, image, metaData, x, y);
      } catch (error:any) {
         console.error("Could not write to cache "+error);
      }
      return;
   }
}