import MapboxDraw, { DrawCustomMode } from "@mapbox/mapbox-gl-draw";
import area from "@turf/area";
import { feature, featureCollection } from "@turf/helpers";
import { difference, intersect, truncate, union } from "@turf/turf";
import { BNBOBeskyttelsesOmråde, BNBOOmrådeType, TurfFeature } from "./BNBOState";
import { SettingsManager } from "@viamap/viamap2-common";

export const BNBOSimpleSelect: DrawCustomMode = {
    ...MapboxDraw.modes.simple_select
}


export const BNBODirectSelect: DrawCustomMode = {
    ...MapboxDraw.modes.direct_select
}

BNBODirectSelect.onSetup = function(opts) {
    let state = MapboxDraw!.modes!.direct_select!.onSetup!.call(this, opts)
    const featureId = opts.featureId;
    const feature = this.getFeature(featureId);
  
    if (!feature) {
      throw new Error('You must provide a featureId to enter direct_select mode');
    }
  
    // if (feature.type === Constants.geojsonTypes.POINT) {
    //   throw new TypeError('direct_select mode doesn\'t handle point features');
    // }
  
    state = {
      ...state,
      featureId,
      feature,
      dragMoveLocation: opts.startPos || null,
      dragMoving: false,
      boxSelectStartLocation: null,
      boxSelectElement: undefined,
      boxSelecting: false,
      canBoxSelect: false,
      canDragMove: false,
      selectedCoordPaths: opts.coordPath?.flat?.() || [],
    };
  
    this.setSelectedCoordinates((this as any).pathsToCoordinates(featureId, state.selectedCoordPaths));
    this.setSelected(featureId);
    // doubleClickZoom.disable(this);
  
    // this.setActionableState({
    //   trash: true
    // });
  
    return state;
    
}

BNBODirectSelect.onTouchEnd = BNBODirectSelect.onMouseUp = function(state, e: any) {
    if (state.boxSelecting) {
        const bbox = [
          state.boxSelectStartLocation,
          mouseEventPoint(e.originalEvent, this.map.getContainer())
        ];
        const featuresInBox = featuresAt(null as any, bbox as any, (this as any)._ctx) as any;
        const idsToSelect =  [...new Set(featuresInBox.features)]
        if (featuresInBox.coords) {
          this.changeMode("direct_select", {
              featureId: featuresInBox.features[0].properties.parent,
              coordPath: featuresInBox.coords,
              startPos: e.lngLat
            });
            
        } else {
          return MapboxDraw!.modes!.direct_select!.onTouchEnd!.call(this, state, e)
        }
        (MapboxDraw.modes.direct_select as any).stopExtendedInteractions(state);
    }
}

BNBODirectSelect.onTouchStart = BNBODirectSelect.onMouseDown = function(state, e: any) {
    if (this.drawConfig.boxSelect && e?.originalEvent?.shiftKey) return (MapboxDraw.modes.simple_select as any).startBoxSelect.call(this,state, e);
    return MapboxDraw.modes.direct_select.onMouseDown?.call(this, state, e);
};

BNBOSimpleSelect.onTouchEnd = BNBOSimpleSelect.onMouseUp = function(state, e: any) {
    // End any extended interactions
    // 

    if (state.dragMoving) {
        (MapboxDraw.modes.simple_select as any).fireUpdate();
    } else if (state.boxSelecting) {
      const bbox = [
        state.boxSelectStartLocation,
        mouseEventPoint(e.originalEvent, this.map.getContainer())
      ];
      const featuresInBox = featuresAt(null as any, bbox as any, (this as any)._ctx) as any;
      const idsToSelect =  [...new Set(featuresInBox.features)]
      if (featuresInBox.coords) {
        this.changeMode("direct_select", {
            featureId: featuresInBox.features[0].properties.parent,
            coordPath: featuresInBox.coords,
            startPos: e.lngLat
          });
          
      } else {
        return MapboxDraw!.modes!.simple_select!.onTouchEnd!.call(this, state, e)
      }
    }
    (MapboxDraw.modes.simple_select as any).stopExtendedInteractions(state);
  };




  function mouseEventPoint(mouseEvent, container) {
    const rect = container.getBoundingClientRect();
    return {
      x: mouseEvent.clientX - rect.left - (container.clientLeft || 0),
      y: mouseEvent.clientY - rect.top - (container.clientTop || 0)
    }
  }


    function featuresAt(event, bbox, ctx) {
        if (ctx.map === null) return [];
    
        const box = bbox;
    
        const queryParams:any = {};
    
        if (ctx.options.styles) queryParams.layers = ctx.options.styles.map(s => s.id).filter(id => ctx.map.getLayer(id) != null);
    
        const features = ctx.map.queryRenderedFeatures(box, queryParams)
        .filter(feature => META_TYPES[feature.properties.meta] !== undefined);
    
        const featureIds:any = []
        const uniqueFeatures:any = [];
        const coords:any = [];
        features.forEach((feature) => {
        const featureId = feature.properties.id;
        if (feature.properties.coord_path) {
            coords.push(feature.properties.coord_path)
        }
        if (featureIds.includes(featureId)) return;
        featureIds.push(featureId);
        uniqueFeatures.push(feature);
        });
    
        return {features: uniqueFeatures, coords: coords};
    }

    const META_TYPES = {
        "feature":1,
        "midpoint":2,
        "vertex":3,
    }
  

export function nonOverlappingGeo(unSortedFeature: any[], calc_area?:boolean, intersectGeom?: any[]) {
  if (unSortedFeature.filter((a) => a).length == 0) return [];
  let feature = unSortedFeature.toSorted(sortFeatureBytype).map((a) => {
    if (!intersectGeom) return truncate(a);
    let intersectResult = intersect(featureCollection([...intersectGeom, a]),{properties: a.properties});
    let next = intersectResult ? truncate({...intersectResult, id:a.id}) : null;
    return next;
 }).filter((a) => a);
  let res:any[] = [feature[0]];
  feature.forEach((element, idx) => {
     if (idx == 0) {
        res[idx].properties.arealNetto = area(res[idx]);
        return;
     }
     try {
        let curr = difference(featureCollection([element,...res]));
        if (curr) {
           curr.id = element.id;
           if (calc_area) {
              curr.properties ??= {};
              curr.properties.arealNetto = area(curr);
           }
           res.push(curr);
        }
     } catch (err) {
        console.error("Err Turf difference :");
        console.error(err);
     }
  });
  return res;
}


function PriorityByType(a:BNBOOmrådeType) {
  return {
    [BNBOOmrådeType.Par3Areal]: 2,
    [BNBOOmrådeType.FredskovsAreal]: 3,
    [BNBOOmrådeType.FradragsAreal]: 4,
    [BNBOOmrådeType.Restareal]: 5.5,
    [BNBOOmrådeType.ServitutAreal]: 6,
    [BNBOOmrådeType.MarkServitutareal]: 7,
    [BNBOOmrådeType.UlempeAreal]:8,
  }[a] || 10
}

export function faktorByType(a:BNBOOmrådeType): number {
  let settingsName:string = {
    [BNBOOmrådeType.FredskovsAreal]: "fredsskovsAreal",
    [BNBOOmrådeType.Par3Areal]: "paragraf3Areal",
    [BNBOOmrådeType.FradragsAreal]: "fradragsAreal",
    [BNBOOmrådeType.Restareal]: "servitutAreal",
    [BNBOOmrådeType.ServitutAreal]: "servitutAreal",
    [BNBOOmrådeType.MarkServitutareal]: "omdriftsAreal",
    [BNBOOmrådeType.UlempeAreal]: "ulempeAreal"
  }[a] // Skal matche op med settings i system settings
  if (SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer."+settingsName,999) == 999) {
    console.error("+++++++++++++++++++++++Faktor ikke fundet for type: "+a)
    return 1
  }
  return SettingsManager.getSystemSetting("bnbo.erstatningsFaktorer."+settingsName, 1)
}

export function sortFeatureBytype(a, b) {
  return PriorityByType(a.properties.type) - PriorityByType(b.properties.type)
}

export function getAllDeleForLodsejer(bnboState, lodsejerId) {
  let ejendomsDele = structuredClone(
    bnboState.dele?.[lodsejerId] ||[]
  )
  let ejendomsFeatures = ejendomsDele.map((a,idx) => {
    return {
      type: "Feature",
      id: idx,
      geometry: a.geometry,
      properties: {
        id: idx,
        farve: a.farve,
        note: a.note,
        faktor: a.faktor,
        isManualTakst: a.isManualTakst,
        takst: a.takst,
        mask: a.mask,
        areal: (a as any).areal,
        type: a.type,
      }
    } 
  })
  return ejendomsFeatures
}

export function getSFEAsFeatureList(bnboState, lodsejerId):TurfFeature[] {
  if (bnboState.lodsejer[lodsejerId]?.geometry.features.length > 1)
    return [union(featureCollection(bnboState.lodsejer[lodsejerId]?.geometry.features), {properties:{type:"Ejendom"}})]
    return [{...bnboState.lodsejer[lodsejerId]?.geometry.features[0], properties: {...bnboState.lodsejer[lodsejerId]?.geometry?.features?.[0]?.properties,type:"Ejendom"}}]

}

export function getAllSubFeaturesLodsejer(bnboState, lodsejerId, startIdx?: number) {
  let length = startIdx || 0
  let matLength = bnboState.lodsejer[lodsejerId]?.geometry.features.length;
  let SfeGeom = getSFEAsFeatureList(bnboState, lodsejerId)
  return [...bnboState.lodsejer[lodsejerId]?.geometry.features.map((a, idx) => {
    return feature(a.geometry, { ...a.properties, type: "Jordstykke", id: idx + length, maskKey: a.properties.Matrikelnummer + "-" + a.properties.Ejerlavskode }, { id: idx + length })
  }) || [],
  ...(Object.values(bnboState.marker[lodsejerId]||{}).map((a: any, idx) => {
    return {
      ...intersect(featureCollection([...SfeGeom, feature(a.geometry)]), {
        properties: {
          afgrøde: a.afgrøde,
          arealBrutto: a.arealBrutto,
          arealNetto: a.arealNetto,
          type: "Mark",
          id: idx + length + matLength,
          lodsejerId: a.lodsejerId,
          markBlok: a.markBlok,
          markNr: a.markNr,
          maskKey: `${a.markBlok}-${a.markNr}`,
          påvirketAfBnboIder: a.påvirketAfBnboIder,
        }
      }), id: idx + length + matLength
    }
  }))]
}

export function getMaskObjFromState(bnboState, lodsejerId) {

}

export function getAllFeaturesForLodsejer(bnboState, lodsejerId) {
  let ejendomsDele = structuredClone(
    bnboState.dele?.[lodsejerId] ||[]
  )
  const bnboGeom = Object.values(bnboState.bnboer[lodsejerId]).map((a: any) => (
    {type:"Feature", 
    geometry: a.geometry,
    properties:{Dgunr:a.dguNr,
      farve:"blue",
      note: `Dgunr: ${a.dguNr}`,
      nonEditble:true,
      type: "ServitutAreal",
      faktor: 0,
      id: Math.round(Math.random()*100000)
    }
    }
  ))
  let ejendomsFeatures = ejendomsDele.map((a,idx) => {
    return {
      type: "Feature",
      id: idx,
      geometry: a.geometry,
      properties: {
        id: a.id,
        farve: a.farve,
        note: a.note,
        areal: (a as any).areal,
        type: a.type,
      }
    } 
  })
  // TODO:  Cut markFeatures to match the geometry of the mark
  let markFeatures = Object.keys(bnboState.markDele[lodsejerId] || {})?.map((a,idx) => {
    if (
      !(bnboState.marker[lodsejerId][a].geometry &&
      bnboState.markDele[lodsejerId][a])
    ) {
      return []
    }

    let dele = structuredClone(bnboState.markDele[lodsejerId][a]) || []
    return dele.map((delDel, idy) => {
      return {...intersect(featureCollection([
        feature(bnboState.marker[lodsejerId][a].geometry),
        feature(delDel.geometry)
      ])),...{properties: {
          id: delDel.id,
          farve: delDel.farve,
          note: delDel.note,
          nonEditble: true,
          areal: (a as any).areal,
          type: delDel.type,
          markId: a,
        }},
        id: idx * 1000 + idy
        }
      });
  }).flat()
  let result = [...bnboGeom,...markFeatures, ...ejendomsFeatures]
  return result
}


export function constructMaskObj(maskFeatures: TurfFeature[]) {
  let resMask: {[key:string]: TurfFeature} = {};
  maskFeatures.forEach((a) => {
     resMask[a.properties.maskKey || "999"] = a;
  })
  return resMask;
}



export function nonOverlappingGeoAdv(unSortedFeature: any[], calc_area?:boolean, intersectGeom?: any[], clipMask?: {[key: string]: TurfFeature}) {
  if (unSortedFeature.filter((a) => a).length == 0) return [];
  let feature = unSortedFeature.toSorted(sortFeatureBytype).map((a) => {
    if (!intersectGeom && !clipMask) return truncate(a);
    let clipGeom = clipMask?.[a.properties.mask] && [clipMask?.[a.properties.mask]];
    let intersectResult = intersect(featureCollection([...(intersectGeom || []), ...(clipGeom || []), a]),{properties: a.properties});
    let next = intersectResult ? truncate({...intersectResult, id:a.id}) : null;
    return next;
 }).filter((a) => a);
  let res:any[] = [feature[0]];
  feature.forEach((element, idx) => {
     if (idx == 0) {
        res[idx].properties.arealNetto = area(res[idx]);
        return;
     }
     try {
        let curr = difference(featureCollection([element,...res]));
        if (curr) {
           curr.id = element.id;
           if (calc_area) {
              curr.properties ??= {};
              curr.properties.arealNetto = area(curr);
           }
           res.push(curr);
        }
     } catch (err) {
        console.error("Err Turf difference :");
        console.error(err);
     }
  });
  return res.filter((a) => a);
}
 
export function bnboActiveFilter(value: BNBOBeskyttelsesOmråde): boolean {
  return !Boolean(value.inactive);
}



