import {Localization, SettingsManager} from "@viamap/viamap2-common";
import {Logger} from '@viamap/viamap2-common';
import {Cookies, MitCookies} from '@viamap/viamap2-common';
import { MitCheckBox } from './ComponentUtils';
import { ApplicationStateContext, Feature } from 'src/states/ApplicationState';
import { ADModalBody, ADModalFooter, AdvancedDragModal } from 'src/componentsUtils/AdvancedDragModal';
import { GlassButton } from './GlassButtons';
import { useState, useContext } from "react";
import { GlassCheckbox } from "./MitGlassComponents";

type Props = {
    showWindow: number;
    callbackOnCancel: () => void;
    callbackOnCommit: () => void;
    rememberMyAccept?:boolean;
    currentCookie?: MitCookies;
};

export function GDPRNoticeApproval(props:Props) {
   const [rememberMyAccept,setRemeberMyAccept] = useState(props.currentCookie ? Cookies.getCookieBoolean(props.currentCookie) : false)
   const {hasAccessToFeature} = useContext(ApplicationStateContext)
   

function onClickApply() {
   // ToDo: Refactor to make this Form stateless and move Logging and Cookies up to calling function.
   Logger.logLegalAction("GDPRNoticeApproval","onClickApply","User asked to remember GDPR notice accept of cookie: " + props.currentCookie);
   rememberMyAccept && props.currentCookie && Cookies.setCookieBoolean(props.currentCookie, rememberMyAccept);
   props.callbackOnCommit();
}

function onClickCancel() {
    props.callbackOnCancel();
}

function onChangeRememberMyAccept(e: React.MouseEvent<any, any>) {
   let show=(e.target as HTMLInputElement).checked;
   setRemeberMyAccept(show)
}

function validateForm():boolean {
    return (true);
}

function getGDPRtextContent(cookie: MitCookies | undefined) {

   if (cookie === undefined) {
      return null;
   } 

   let htmlString: string;

   switch (cookie) {
      case MitCookies.RememberMapLinkGDPRAccept:
                   htmlString = hasAccessToFeature(Feature.ThemeNewSec) ?  Localization.getText("GDPRgenererLinkExplorer") : Localization.getText("GDPRgenererLink");
                   break;

      case MitCookies.RememberSaveCustomLayerGDPRAccept:
                  htmlString = hasAccessToFeature(Feature.ThemeNewSec) ?  Localization.getText("GDPRgemFastLagExplorer") : Localization.getText("GDPRgemFastLag");
                   break;
      default:
         htmlString = "";
   }
   let htmlContent = <div dangerouslySetInnerHTML={{__html: htmlString}}/>;
   return htmlContent;
}

    if (props.showWindow) {
      let commitbutton:JSX.Element|null=null;
      let cancelbutton:JSX.Element|null=null;
      commitbutton = <GlassButton onClick={onClickApply} disabled={!validateForm()} >{Localization.getText("I accept")}</GlassButton>;
      cancelbutton = <GlassButton onClick={onClickCancel} >{Localization.getText("Cancel")}</GlassButton>;

      let OptionallyRenderRememberAcceptanceCheckBox = SettingsManager.getSystemSetting("allowRememberGDPRAcceptance",false) ? (
         <GlassCheckbox style={{marginRight:"auto"}} checked={rememberMyAccept} onClick={onChangeRememberMyAccept}>{Localization.getText("Remember my accept")}</GlassCheckbox>
      ) : null;
      
      let text = getGDPRtextContent(props.currentCookie);

      return (
        <AdvancedDragModal
         variant={"NSDark"}
         PosDefault={{left:"50%", top:"5%", transform:"translateX(-50%)", width: "800px"}}
         title={Localization.getText("GDPR Statement")}
        >
            {/* <Modal.Header>
               <strong>
               </strong>
            </Modal.Header> */}
            <ADModalBody>
               {text}
            </ADModalBody>
            <ADModalFooter >
               {OptionallyRenderRememberAcceptanceCheckBox}
                  {commitbutton}{cancelbutton}
            </ADModalFooter>
        </AdvancedDragModal>
      );
    } else {
      return(null);
    }
}