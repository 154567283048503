import { useEffect, useState } from "react"
import { EmbeddedVectorMapEnhanced, MapInstanceController } from "src/compentsModus/EmbeddedVectorMapEnhanced"
import { MitLatLng } from "src/managers/MapFacade"
import circle from '@turf/circle';
import * as turf from '@turf/turf';

type Props = {
   center:MitLatLng,
   radiusMeters:number
}

export const CircleAreaMap = (props:Props) => {
   let [mapInstanceController, setMapInstanceController] = useState<MapInstanceController>();
   
      const EmptyJSON = {
         "type": "FeatureCollection",
         "features": []
      };
   let [data, setData] = useState<any>(EmptyJSON);

   // useEffect(()=> {
   //    mapInstanceController && mapInstanceController.zoomToFeatureJSON(createSource(createSource(data)));
   // }, [data]);

   
   function createSource(data: any) {
      return {
         type: "geojson",
         data: data
      };
   }

   function createLayer(id: string, source: string) {
      return {
         id: id,
         type: 'fill',
         source: source,
         layout: {
         },
         paint: {
            'fill-color': "#BD9060",
            'fill-opacity': 0.5,
            'fill-outline-color': "#816c5d"
         }
      };
   }

   useEffect(() => {
      if (props.center && props.radiusMeters) {
         let distanceKm = props.radiusMeters/1000.0
         let circleJSON = circle(turf.point([props.center.lng, props.center.lat]), distanceKm, {});
         setData(circleJSON);
      }
   }, [props]);
   
   useEffect(() => {
      mapInstanceController && data && mapInstanceController.zoomToFeatureJSON(createSource(data));
   }, [mapInstanceController, data]);

   return (
      <EmbeddedVectorMapEnhanced
            orthoPhoto={false} 
            height="200px"
            width="400px"
            sources={{
               'circleData':createSource(data)
            }}
            layers={{
               'circle':createLayer('circle','circleData')
            }}
            fullScreenControl={false}
            drawControls={false}
            navigationControls={false}
            onMapInitialization={(mapController:MapInstanceController) => {
               setMapInstanceController(mapController);
            }}
            />
   );
}