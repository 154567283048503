import { SimpleTranslationTable } from "@viamap/viamap2-common"
import { CustomLayerSpecs, FeatureLayer, customLayerDefault } from "./WmsLayerFunc"
import { featureCollection, point } from "@turf/helpers"
import polylabel from "polylabel"
import { circle } from "@turf/turf"

type updatedProperties = {
   type: "paint"|"layout",
   prop: string,
   value: any,
}

type Variants = {
   title: string,
   layers: {
      'type':string,
      'paint': object,
      'layout': object,
   }[],
}

type WFSGeojsonLayerSpec = {
   licenseFeature:string,
   label: string,
   type: "geojsonWFS",
   group: string,
   src: string,
   layers: {
      'type':string,
      'paint': object,
      'layout': object,
   }[],
   minZoom?: number,
   attrib?: string,
   sourceID: string,
   dataTransform: any[]
   legend: boolean|string,
   propertiesToDisplay?: string[],
   translationTable?: SimpleTranslationTable,
   variantSelectors?: any,
   variantValues?: any,
}


function EmptyString<T extends object,E extends keyof T & (string | number)>(key: E, object:T): object is (T & Record<E, string>) {
   return (key in object && typeof object[key] === "string" && object[key] !== "")
}

function isObject(T: unknown): T is Partial<WFSGeojsonLayerSpec> {
   return (typeof T === "object")
}

export function isGeojsonWFS(layer: unknown): layer is WFSGeojsonLayerSpec {
   if (!isObject(layer)) {
      return false
   }
   if (!EmptyString("label", layer)) {
      return false
   }
   if (!(EmptyString("type", layer) && layer.type === "geojsonWFS")) {
      return false
   }
   if (!EmptyString("group", layer)) {
      return false
   }
   return true 
}

//TODO: Change such BadHack isn't necessary
function BadHack(layer: CustomLayerSpecs) {
   let newLayer = window.structuredClone(layer)
   Object.keys(newLayer).map((a) => {
      newLayer[a] ||="a" 
   })
   return newLayer
}

function constructDefaultMitValues(layer: WFSGeojsonLayerSpec):{[key:string]:any} {
   let defaults = {}
   if ("mitValuesControls" in layer && typeof layer.mitValuesControls === "object" ) { 
      Object.keys(layer.mitValuesControls as any).forEach((a) => {
         if (a in (layer.mitValuesControls as any)) {
            defaults[a] = (layer.mitValuesControls as any)[a]?.[1]
         }
      })
   }
   if ("variantSelectors" in layer && typeof layer.variantSelectors === "object" ) {
      Object.keys(layer.variantSelectors as any).forEach((a) => {
         if (a in (layer.variantSelectors as any)) {
            defaults[a] = (layer.variantSelectors as any)[a]?.default?.[0]
         }
      })
   }
   return defaults
}

export class GeojsonWFS extends FeatureLayer {
   vectorHandle: WFSGeojsonLayerSpec;
   mitValues: {[key:string]:any};
   
   constructor(layer: GeojsonWFS | WFSGeojsonLayerSpec) {
      if (isGeojsonWFS(layer)) {
         super({...BadHack(customLayerDefault),...layer})
         this.vectorHandle = layer
         this.mitValues = constructDefaultMitValues(this.vectorHandle)
         return this
      }
      super({...BadHack(customLayerDefault),...layer.vectorHandle})
      this.vectorHandle = layer.vectorHandle
      this.mitValues = constructDefaultMitValues(this.vectorHandle)
      return this
   }
   
   public copy(): GeojsonWFS {
      return new GeojsonWFS(window.structuredClone(this.vectorHandle))
   }
   
   public getType(): string {
      return "geojsonWFS";
   }
   
   public getWithKey() {
      return {[this.vectorHandle.label] : this}
   }
   
   public get minZoom(): number {
      return this.vectorHandle.minZoom || 13 
   }
   
   public setStyling(value: any): void {
      Object.keys(value).forEach((a) => {
         this.mitValues[a] = value[a][0]
      })
   }
   
   // public getUpdatedProperties(value): updatedProperties[] {
   
   // }
   
   public getTileURL(): string {
      return ""
   }
   
   public getLayerID() : string[]
   public getLayerID(idx: number)
   public getLayerID(idx?: number) {
      if (idx !== undefined) {
         return `${this.getSourceID()}-L${idx}-${this.vectorHandle.layers[idx].type}`
      }
      return this.vectorHandle.layers?.map((a, idx) => {
         return this.getLayerID(idx)
      })
   }
   public getSourceID() {
      return this.vectorHandle.sourceID
   }
   public getDataSrc(bounds?) {
      let bbox_old = (bounds.toArray() as number[][]).flat().join();
      let bbox_new = (bounds.toArray() as number[][]).flatMap((a) => [a[1],a[0]]).join();

      return bounds && this.vectorHandle.src.replace("{BBOX_4326}", bbox_new).replace("{BBOX_4326_old}", bbox_old) || this.vectorHandle.src
   }
   public getDataTransformer() {
      let transformations = this.vectorHandle.dataTransform;
      function RecursiveDataTransformer(data, options, dataTransform: any[]) {
         if (dataTransform?.[0] == "polylabel") {
            return featureCollection(data.features.map((feature) => {
               let coords = polylabel(feature.geometry.coordinates, dataTransform?.[1] || 0.1)
               return point(coords, {...feature.properties, polylabelDist: coords.distance })
            }))
         }
         if (dataTransform?.[0] == "addZoom") {
            return featureCollection(RecursiveDataTransformer(data, options, dataTransform[1]).features.map((feature) => {
               return {...feature, properties: {...feature.properties, zoom: options.zoom}}
            }))
         }
         if (dataTransform?.[0] == "nothing") {
            return data
         }
         if (dataTransform?.[0] == "combine") {
            return featureCollection([...RecursiveDataTransformer(data, options, dataTransform[1]).features,...RecursiveDataTransformer(data, options, dataTransform[2]).features])  
         }
         if (dataTransform?.[0] == "circle")
            return featureCollection(data.features.map((feature) => {return circle(feature, dataTransform[1])}));
         return data
      }
      return (data, options) => RecursiveDataTransformer(data, options, transformations);
   }

   public getSource() {
      return {
         type: "geojson",
         data: {
            'type': 'FeatureCollection',
            'features': []
         }
      }
   }
   
   public getLegendURL() {
      if (typeof this.vectorHandle.legend == "string") {
         return this.vectorHandle.legend
      }
      return ""
   }
   
   public getLayers() {
      return this.vectorHandle.layers.map((a, idx) => {
         return {
            "minzoom": this.vectorHandle.minZoom ?? 9, //TODO: Make system setting for vector layer default zoom
            ...replaceMitTagsValues(this.mitValues,this.vectorHandle.layers[idx]),
            id: this.getLayerID(idx),
            source: this.getSourceID(),
         }
      })
   }
}

function replaceMitTagsValues(values: {[key:string]:any}, object: unknown) {
   if (typeof object == "string") {
      if (object.includes("@")) {
         const key = object.split("@")[1]
         return values[key]
      }
   }
   if (Array.isArray(object)) {
      return object.map((a) => {
         return replaceMitTagsValues(values, a)
      })
   }
   if (typeof object == "object" && object) {
      const next = {}
      Object.keys(object).forEach((a) => {
         next[a] = replaceMitTagsValues(values, object[a])
      })
      return next
   }
   return object
}
