import { Localization } from "@viamap/viamap2-common";
import { useEffect, useRef, useState } from "react";
import { v4 as uuid } from "uuid";

export function NonBlockingError() {
   let [msg, setMsg] = useState<{mid:string, msg:string}[]>([]);
   let errorRef = useRef(null as any);

   useEffect(() => {
      if (errorRef.current) {
         const displayError = (e) => {
            if (isNonBlockingError(e)) {
               setMsg((a) => {
                  const messageToDisplay = Localization.getFormattedText("Problems at {service}", {service:e.detail});
                  if (a.map((e) => e.msg).includes(messageToDisplay)) return a;
                  setTimeout(() => {
                     setMsg((a) => a.filter((err) => err.msg !== messageToDisplay));
                  },3000)
                  return [...a, {mid: uuid(), msg: messageToDisplay}];
               });
            }
         }
         (errorRef.current as HTMLDivElement).addEventListener("NonBlockingError", displayError);
         return () => {
            document.getElementById("NonBlockingError")?.removeEventListener("NonBlockingError", displayError);
            // (errorRef?.current as HTMLDivElement)?.removeEventListener("NonBlockingError", displayError);
         }
      }
   },[])


   return (<div ref={errorRef} id="NonBlockingError" >
      {msg.map((e,i) => <div onClick={(() => {
         setMsg((a) => a.filter((a) => a.mid !== e.mid));
      })} key={e.mid}>{e.msg}</div>)}
   </div>)
}


function isNonBlockingError(e: any): e is {detail: string} {
   return e.detail !== undefined;
}