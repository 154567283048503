import {AppMode, UserSession, SessionInfo, DeploymentMode} from './common/managers/Types';
import { ErrorBoundary, LicensingContext, Logger } from '@viamap/viamap2-common';

import 'react-bootstrap-typeahead/css/Typeahead.css';
import 'react-data-grid/lib/styles.css';
import FileImporter from './components/FileImporter';
import { SessionContext } from '@viamap/viamap2-common';
import { SessionActionType } from '@viamap/viamap2-common';
import NavigationBar from './components/NavigationBar';
import RightPane from './components/RightPane';
import { ProjectManager } from './components/Projects';

import { MapitButtonsAndOverlays } from './components/MapitButtonsAndOverlays';
import { GenericTransactionManager, MapitStateActions, MapitStateContext, MapitStateReducer, MapitWindowId, initialMapitState, transactionalMapitStateReducer, windowToBeShownImpl, windowToBeShownParametersImpl } from './states/MapitState';
import { RightPaneContext, useSetupRightPane } from './states/RightPaneState';
import MapScreen from './components/MapScreen';
import { useState, useContext, useReducer, useEffect } from "react";
import { ProtectedFeature } from './components/ProtectedFeature';
import { Feature } from './states/ApplicationStateFeatures';
import { WindowManager } from './WindowManager/WindowManager';

export function Application(props:{tab?:string}) {
  const [sessionInfo, setSessionInfo] = useState<SessionInfo>();
  const {state : newUserSession, dispatch: sessionDispatch} = useContext(SessionContext);
  const {state:licensingState} = useContext(LicensingContext);
  const [restoring, setRestoring] = useState(false)

  const [mapitState, mapitStateDispatch] = useReducer(MapitStateReducer, initialMapitState(true));

  const modusState = useSetupRightPane()
  useEffect(() => {
    if (restoring) {
      setTimeout(() => setRestoring(false), 100)
    }
  },[restoring])

  useEffect(() => {
    const getTitle = () => {
      let hasLicense = licensingState.currentlicense;
      if (!hasLicense) {
        return "Unlicensed"
      }
      let variant = licensingState.currentlicense?.productVariant;
      if (variant?.includes("Explorer")) {
        return 'Estate Explorer'
      }
      return `${licensingState.currentlicense?.product} ${licensingState.currentlicense?.productVariant}`
    }
    document.title = getTitle()
  },[licensingState.currentlicense])

  useEffect(() => {
    mapitState.map?.once?.("webglcontextlost", () => {
      console.info('%c Map Content Lost ', 'color: #bada55')
      setRestoring(true)
    })
  },[mapitState.map])

  useEffect(() => {

    let us:UserSession  = {
      id: newUserSession.userName || "",
      userName: newUserSession.userName,
      email: newUserSession.userRef,
      jwtToken: newUserSession.userSession?.getAccessToken().getJwtToken() || "",
      name: newUserSession.userName,
      userRoles: [], // [...(newUserSession.userRoles ? newUserSession.userRoles : []),...license.valid.map((a) => a.productVariant)],
      deploymentMode: DeploymentMode.Testing,
      isAnonymousSession: false,
    }

    setSessionInfo({ 
      userSession: us,
      mapReferenceData: undefined,
      appMode: AppMode.Normal,
      commandList: undefined
    })

    Logger.logAction("Application", "User session started", "Map Loaded")
  },[newUserSession, licensingState])


  
  function LogOut() {
    sessionDispatch({
      type: SessionActionType.Logoff,
      payload: {
          userName: sessionInfo?.userSession?.userName ?? newUserSession.userName
      }})
  }

  if (sessionInfo !== undefined)
  return (
      <WindowManager>
      <MapitStateContext.Provider
      value={{
        state: mapitState,
        dispatch: GenericTransactionManager.dispatchMiddleware<MapitStateActions>(mapitStateDispatch, transactionalMapitStateReducer),
        windowToBeShownOrder: (windowId: MapitWindowId) => {
          return windowToBeShownImpl(mapitState, windowId);
        },
        windowToBeShownParameters: (windowId: MapitWindowId) => {
          return windowToBeShownParametersImpl(mapitState, windowId);
        }
      }}

    >
    <ErrorBoundary>
    
    <RightPaneContext.Provider value={modusState} >
    <ProjectManager >
    {/* <button onClick={(e) => setRestoring(true)} style={{position:"absolute", zIndex:"10001"}}>Test</button> */}
    {/* <MapitV4Banner/> */}
    <FileImporter>
      <ProtectedFeature feature={Feature.ThemeNewSec} contentsIfNoAccess={<></>} >
      <NavigationBar />
      </ProtectedFeature>
      <RightPane>
      
      { restoring ?
        <></>
      :
      <MapScreen 
      callbackToLogout={()=> {LogOut()}} 
      showTrialSignupDialog={false} 
      callBackOnTrialSignup={(name, company, phoneNo, termsApproved) => console.info(name, company, phoneNo, termsApproved)}
      callBackMap={(a) => {}}
      />
      }
        </RightPane>
    </FileImporter>
    </ProjectManager>
    </RightPaneContext.Provider>
    </ErrorBoundary>
    </MapitStateContext.Provider>
    </WindowManager>
  );

  return (null)
}


