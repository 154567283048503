import * as XLSX from 'xlsx';
import { Logger } from '@viamap/viamap2-common';
import { SheetAnalysisResultColumn, SheetAnalysisResult, MitDataType, ImportDataType} from '../common/managers/Types';
import {Utils} from '@viamap/viamap2-common';
import {ReferenceGeomDenmark} from './ReferenceGeomDenmark';
import {ReferenceGeomWorld} from './ReferenceGeomWorld';
import {ReferenceGeomRomania} from './ReferenceGeomRomania';
import {ReferenceGeomParish} from './ReferenceGeomParish';
import { AddressInterface } from './AddressInterface';
import { GenerateGeom } from './GenerateGeom';
import { SettingsManager } from '@viamap/viamap2-common';

export interface SheetDataAccessor {
    getColumnMin():number; // The index of the first column in the sheet
    getColumnMax():number; // The index of the last column in the sheet
    getRowMin():number;  // The index of the first row in the sheet
    getRowMax():number; // The index of the last row in the sheet

    getCellValue(column:number, row:number):{val:any, z?:string}; // The the value in the speficied cell. May throw ErrorEvent
}

export class ExcelSheetDataAccessor implements SheetDataAccessor {
    private range:any;

    constructor(readonly sheet:any) {
        try {
            this.range = XLSX.utils.decode_range(sheet['!ref']);
        } catch (e:any) {
            Logger.logError("ExcelAccessor", "Decode range", e);
            this.range = null;
        }
    }

    getColumnMin():number { 
        // The index of the first column in the sheet
        return (this.range && this.range.s.c);
    }

    getColumnMax():number {
        // The index of the last column in the sheet
        return (this.range && this.range.e.c);
    }

    getRowMin():number {
        // The index of the first row in the sheet
        return (this.range && this.range.s.r);
    }

    getRowMax():number {
        // The index of the last row in the sheet
        return (this.range && this.range.e.r);
    }

    getCellValue(column:number, row:number):{val:any, z?:string} { 
        // The the value in the speficied cell. May throw ErrorEvent  
        let z;
        try {
            var cell = this.sheet[XLSX.utils.encode_cell({c: column, r: row})];
        } catch (e:any) {
            Logger.logError("ExcelAccessor", "Get cell value", e);
            return {val: null};
        }
        // let ft = XLSX.SSF.get_table();
        let val = '_UNKNOWN ' + column; // <-- replace with your desired default
        try {
            if (cell && cell.t) { 
                val = XLSX.utils.format_cell(cell); 
                z = cell.z;
            }
        } catch (e:any) {
            Logger.logError("ExcelAccessor", "Get cell format", e);
        }
        if (val.indexOf('_UNKNOWN') === 0) { 
//            Logger.logError("ExcelAccessor", "Get cell format", "Column "+column+", Row "+row+" is empty");
            val = "";
        }
        return({val:val, z:z});
    }
}

export class Parser {

    static generateColumnSelectionList(sheet:any) {
        var headers:any[]|null = [];
        try {
            var range = XLSX.utils.decode_range(sheet['!ref']);

            var C;
            var R = range.s.r; /* start in the first row */
        
            /* walk every column in the range */
            for (C = range.s.c; C <= range.e.c; ++C) {
            // find the cell in the first row
            try {
                var cell = sheet[XLSX.utils.encode_cell({c: C, r: R})];
            } catch (e:any) {
                Logger.logError("Parser", "Read Cell", "Got cell exception:"+e);
                cell = null;
            }
            
            var hdr = '_UNKNOWN ' + C; // <-- replace with your desired default
            try {
                if (cell && cell.t) { hdr = XLSX.utils.format_cell(cell); }
            } catch (e:any) {
                Logger.logError("Parser", "Read Cell", "Got cell exception:"+e);
            }
            if (hdr.indexOf('_UNKNOWN') === 0) { continue; }

            // ToDo: does not work if more than 28 columns 
            var label = String.fromCharCode(65 + C) +
                            " ( " + hdr + " )";
            headers.push({label:label, value:C});
            }
        } catch (e:any) {
            Logger.logError("Parser", "Read Cell", "Got cell exception:"+e);
            headers = null;
        }
        return headers;
      }

    static testMunicipalityIdLookup(toInt:number):boolean { return ReferenceGeomDenmark.lookupByMunicipalityCode(toInt) !== undefined; }
    static testRegionIdLookup(toInt:number):boolean {return ReferenceGeomDenmark.lookupByRegionCode(toInt.toString()) !== undefined; }
    static testZipcodeLookup(toInt:number):boolean {return ReferenceGeomDenmark.lookupByZipCode(toInt) !== undefined;}
    static testCountryIdLookup(toInt:number):boolean {return ReferenceGeomWorld.lookupByCountryCode(toInt.toString()) !== undefined; }
    static testAddressLookup(value:string):boolean {return AddressInterface.addressTest(value);}
    static testMunicipalityNameLookup(value:string):boolean {return ReferenceGeomDenmark.lookupByMunicipalityName(value) !== undefined; }
    static testRegionNameLookup(value:string):boolean {return ReferenceGeomDenmark.lookupByRegionName(value) !== undefined;}
    static testZipcodeNameLookup(value:string):boolean {return ReferenceGeomDenmark.lookupByZipCodeName(value) !== undefined; }
    static testCountryNameLookup(toInt:number):boolean {return ReferenceGeomWorld.lookupByCountryName(toInt.toString()) !== undefined; }
    // static testMunicipalityName_RO_Lookup(value:string):boolean {return ReferenceGeomRomania.lookupByMunicipalityName(value) !== undefined; }
    static testIsHttp(value:string):boolean {return value.toLowerCase().startsWith("http");}
    static testParishNameLookup(value:string):boolean {return ReferenceGeomParish.lookupByParishName(value) !== undefined; }
    static testParishIdLookup(value:number):boolean {return ReferenceGeomParish.lookupByParishCode(value) !== undefined; }

    static analyzeInputSheet(sheet:any):SheetAnalysisResult {
        /*  1. header names
            2. find data types for each column
            3. number of rows
            4. suggest input format and field mapping
        */

        var da:ExcelSheetDataAccessor = new ExcelSheetDataAccessor(sheet);

        return this._analyzeInputSheet(da);
    }

    static _analyzeInputSheet(da:SheetDataAccessor):SheetAnalysisResult {
        var headers:Object[] = [];
        var C;
        var R = da.getRowMin(); /* start in the first row */

        let enabledRomania = SettingsManager.getSystemSetting("enableRomaniaGeom", false);

        const noOfColumns:number = (1 + da.getColumnMax() - da.getColumnMin());
        const noOfRows:number = (1 + da.getRowMax() - da.getRowMin());
        var columnNames:string[] = [];
        var columns:SheetAnalysisResultColumn[]=[];

        /* walk every column in the range */
        for (C = da.getColumnMin(); C <= da.getColumnMax(); ++C) {
            // find the cell in the first row
            let cv = da.getCellValue(C,R);
            var hdr = cv.val;

            var columnLetter = Utils.toColumnNameZeroBased(C);
            var cellFormat;

//            Utils.dispatchToLoadingProgress("Column:"+columnLetter);

            var label = columnLetter +
                            " ( " + hdr + " )";
            headers.push({label:label, value:C});
            columnNames.push(hdr);

            var firstRowsAsString:string[]=[];

            // Analyze column type
            // read up to 10 rows
            const NumberOfRowsToAnalyze=10;
            var numberOfRowsAnalyzed=0;

            var minFloatValue = Number.MAX_VALUE;
            var minIntValue = Number.MAX_SAFE_INTEGER;
            var maxFloatValue = Number.MIN_VALUE;
            var maxIntValue = Number.MIN_SAFE_INTEGER;
            var floatFound:boolean = false;
            var intFound:boolean = false;

            // Count strings matching lookup tables.
            var intMunicipalityIdMatch:number = 0;
            var stringMunicipalityNameMatch:number = 0;
            var intRegionIdMatch:number = 0;
            var stringRegionNameMatch:number = 0;
            var intZipcodeMatch:number = 0;
            var stringZipcodeNameMatch:number = 0;
            var stringAddressMatch:number = 0;
            var stringCountryIdMatch:number = 0;
            var stringCountryNameMatch:number = 0;
            var stringMunicipalityNameROMatch:number = 0;
            var stringParishNameMatch:number = 0;
            var intParishIdMatch:number = 0;
            // Check to see if string is a category (only a few distinct values).
            var distinctStringValues:any = {};
            var stringHttpValues:number = 0;

            for (var row = da.getRowMin()+1; row <= Math.min(da.getRowMax(),da.getRowMin()+NumberOfRowsToAnalyze); row++) {
                let cv2 = da.getCellValue(C, row);
                var value = cv2.val;
                cellFormat = cv2.z;
                firstRowsAsString.push(value);
                var toInt = Number.parseInt(value, 10);
                var toFloat = Number.parseFloat(value);
                var containfDotOrcomma = value.indexOf('.') >= 0 ||  value.indexOf(',') >= 0;

                // Check that the number of not just part of the string
                if (toInt && value.length === toInt.toString().length) {intFound=true;}
                // An Integer is also a float. Need to distinguish

                // ToDo: some formats contain comma as decimal sep
                if (toFloat && containfDotOrcomma) {floatFound=true;}
                if (toInt && toInt > maxIntValue) {maxIntValue=toInt;}
                if (toInt && toInt <= minIntValue) {minIntValue=toInt;}
                if (toFloat && toFloat > maxFloatValue) {maxFloatValue=toFloat;}
                if (toFloat && toFloat <= minFloatValue) {minFloatValue=toFloat;}

                if (intFound) {
                    if (Parser.testMunicipalityIdLookup(toInt)) {intMunicipalityIdMatch++;}
                    if (Parser.testParishIdLookup(toInt)) {intParishIdMatch++;}
                    if (Parser.testRegionIdLookup(toInt)) {intRegionIdMatch++;}
                    if (Parser.testZipcodeLookup(toInt)) {intZipcodeMatch++;}
                }
                // if it is a string try to match string based criteria
                if (!intFound && !floatFound) {
                    if (Parser.testAddressLookup(value)) {stringAddressMatch++;}
                    if (Parser.testMunicipalityNameLookup(value)) {stringMunicipalityNameMatch++;}
                    if (Parser.testRegionNameLookup(value)) {stringRegionNameMatch++;}
                    if (Parser.testZipcodeNameLookup(value)) {stringZipcodeNameMatch++;}
                    if (Parser.testCountryIdLookup(value)) {stringCountryIdMatch++;}
                    if (Parser.testCountryNameLookup(value)) {stringCountryNameMatch++;}
                    if (Parser.testParishNameLookup(value)) {stringParishNameMatch++;}
                    // if (enabledRomania && Parser.testMunicipalityName_RO_Lookup(value)) {stringMunicipalityNameROMatch++;}
                    distinctStringValues[value]=value;
                    if (Parser.testIsHttp(value)) {stringHttpValues++;}
                }
                numberOfRowsAnalyzed++;
            }
            var mdt:MitDataType;
            var dataType:ImportDataType;

            var minValue:any = undefined;
            var maxValue:any = undefined;
            if (floatFound) {
                minValue = minFloatValue;
                maxValue =maxFloatValue;
                dataType = ImportDataType.Float;
            } else {
                if (intFound) {
                    minValue = minIntValue;
                    maxValue =maxIntValue;
                    dataType = ImportDataType.Integer;
                } else {
                    dataType = ImportDataType.String;                    
                }
            }

            // Wgs84 in DK (Long: 8-15, Lat: 54-58)
            switch (dataType) {
                case ImportDataType.String:
                    if (intMunicipalityIdMatch === numberOfRowsAnalyzed) {
                        mdt=MitDataType.AdmReg_DK_MunicipalityId;
                    } else {
                        if (stringMunicipalityNameMatch === numberOfRowsAnalyzed) {
                            mdt=MitDataType.AdmReg_DK_MunicipalityName;
                        } else {
                            if (stringMunicipalityNameROMatch === (numberOfRowsAnalyzed*0.9)) {
                                mdt=MitDataType.AdmReg_RO_MunicipalityName;
                            } else {
                                if (intRegionIdMatch === numberOfRowsAnalyzed) {
                                    mdt=MitDataType.AdmReg_DK_RegionId;
                                } else {
                                    if (stringRegionNameMatch === numberOfRowsAnalyzed) {
                                        mdt=MitDataType.AdmReg_DK_RegionName;
                                    } else {
                                        if (stringParishNameMatch === (numberOfRowsAnalyzed*0.9)) {
                                            mdt=MitDataType.AdmReg_DK_ParishName;
                                        } else {
                                            if (intZipcodeMatch === numberOfRowsAnalyzed) {
                                                mdt=MitDataType.AdmReg_DK_ZipCodeId;
                                            } else {
                                                if (stringZipcodeNameMatch === numberOfRowsAnalyzed) {
                                                    mdt=MitDataType.AdmReg_DK_ZipCodeName;
                                                } else {
                                                    if (stringCountryIdMatch === numberOfRowsAnalyzed) {
                                                        mdt=MitDataType.AdmReg_INT_CountryId;
                                                    } else {
                                                        if (stringCountryNameMatch === numberOfRowsAnalyzed) {
                                                            mdt=MitDataType.AdmReg_INT_CountryName;
                                                        } else {
                                                            if (stringHttpValues >= (numberOfRowsAnalyzed*0.7)) {
                                                                mdt=MitDataType.String_HTTP;
                                                            } else {
                                                                if (stringAddressMatch >= (numberOfRowsAnalyzed*0.7)) {
                                                                    mdt=MitDataType.Address;
                                                                } else {                                                            
                                                                    var dv:number = Object.keys(distinctStringValues).length;
                                                                    if (dv > 0 && dv <= 4) {
                                                                        mdt=MitDataType.String_Category;
                                                                    } else {
                                                                        mdt=MitDataType.String;
                                                                    }
                                                                }
                                                            }
                                                        }
                                                    }
                                                }                                                                   
                                            }  
                                        }
                                    }                                                  
                                }                                            
                            }
                        }                                    
                    }                      
                    break;
                case ImportDataType.Float:
                    // Check for coordinate types
                    if (minFloatValue >= GenerateGeom.coordinateBoundsLonLower && maxFloatValue <= GenerateGeom.coordinateBoundsLonUpper) {
                        mdt = MitDataType.Coord_WGS84_Lon;
                    } else {
                      if (minFloatValue >= GenerateGeom.coordinateBoundsLatLower && maxFloatValue <= GenerateGeom.coordinateBoundsLatUpper) {
                        mdt = MitDataType.Coord_WGS84_Lat;
                        } else {
                            // todo: UTM bounds should be configurable too
                            if (minFloatValue >= 450000 && maxFloatValue <= 750000) {
                                mdt = MitDataType.Coord_UTM32_X;
                            } else {
                              if (minFloatValue >= 6000000 && maxFloatValue <= 6500000) {
                                mdt = MitDataType.Coord_UTM32_Y;
                                } else {
                                    mdt=MitDataType.Value;
                                }
                            }
                        }
                    }
                    break;
                case ImportDataType.Integer:
                    if (minIntValue >= 100 && maxIntValue <= 900) {
                        mdt = MitDataType.AdmReg_DK_MunicipalityId;
                    } else {
                        if (minIntValue >= 1080 && maxIntValue <= 1090) {
                            mdt = MitDataType.AdmReg_DK_RegionId;
                        } else {
                            if (minIntValue >= 7000 && maxIntValue <= 9999) {
                                mdt = MitDataType.AdmReg_DK_ParishId;
                            } else {
                                if (minIntValue >= 800 && maxIntValue <= 9999) {
                                    mdt = MitDataType.AdmReg_DK_ZipCodeId;
                                } else {
                                    if (minIntValue >= 450000 && maxIntValue <= 750000) {
                                        mdt = MitDataType.Coord_UTM32_X;
                                    } else {
                                        if (minIntValue >= 6000000 && maxIntValue <= 6500000) {
                                            mdt = MitDataType.Coord_UTM32_Y;
                                        } else {
                                            mdt=MitDataType.Number;
                                        }
                                    }
                                }
                            }
                        }
                    }
                    break;
                default:
                    mdt=MitDataType.Number;
            }

            columns.push({
                columnLetter: columnLetter,
                name:columnNames[C],
                firstRowsAsString:firstRowsAsString,
                dataType: dataType,
                minValue: minValue,
                maxValue: maxValue,
                mitDataType: mdt,
                excelCellFormat: cellFormat
            });
        }

        var result:SheetAnalysisResult = {
            noOfRows: noOfRows,
            noOfColumns: noOfColumns,
            headers: headers,
            columnNames: columnNames,
            columns: columns,
        };
        return(result);
    }
}

