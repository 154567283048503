
import { Localization } from "@viamap/viamap2-common";
import { LayerHierarchy } from "../common/managers/Types";
import ReactDOM from "react-dom";
import { GlassButton } from "./GlassButtons";
import { ADModalBody, ADModalFooter, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassInputGroup, GlassTextInputTransformer } from "./GlassInput";
import { useState } from "react";

type Props = {
  group: LayerHierarchy;
  callbackOnGroupStyling: (group: LayerHierarchy) => void;
};

/**
 * React group-styling window.
 */
export function GroupStyling(props: Props) {
  const [name, setName] = useState<string>(props.group.name);

  return ReactDOM.createPortal(
    <AdvancedDragModal 
    onClose={(e) => props.callbackOnGroupStyling({ ...(props.group), name: name })}
      title={Localization.getText("Styling")}
    >
      <ADModalBody>
        <GlassInputGroup
          autoFocus={0}
          onSubmit={(e) => {
            props.callbackOnGroupStyling({ ...(props.group), name: name });
          }}
          onEscape={(e) => {
            props.callbackOnGroupStyling({ ...(props.group), name: name });
          }}
        >
          <GlassTextInputTransformer label={Localization.getText("Dataset name")}>
              <input
                type="text"
                disabled={false}
                value={name}
                placeholder={Localization.getText("Enter name of dataset")}
                onChange={(e) => setName(e.target.value)}
              />
          </GlassTextInputTransformer>
        </GlassInputGroup>
        </ADModalBody>
        <ADModalFooter>
          <GlassButton
            onClick={(e) => {
              e.preventDefault;
              props.callbackOnGroupStyling({ ...(props.group), name: name });
            }}
          >
            {Localization.getText("Close")}
          </GlassButton>
        </ADModalFooter>
      </AdvancedDragModal>
  , document.getElementById("Mit-MapOverlay") || document.body);
}
