// Persistence related 
import { v4 } from 'uuid';

import {MapInfo, DeploymentMode, LayerInfo, MapExportImportFormat, ViewerState, 
  SheetAnalysisResult,
  LayerHierarchy, LayerVariabilityType, ActiveFeatureLayer, LayerInfoStyling,
  MapitFormatCompressed,
  MapitImportFormat} from '../common/managers/Types';
import {Utils} from '@viamap/viamap2-common';
import {Logger} from '@viamap/viamap2-common';
import {Localization, SettingsManager} from "@viamap/viamap2-common";
import {AWSAPIGatewayInterface} from './AWSAPIGatewayInterface';
import {Migration} from './Migration';
import { OldHierarchy } from './OldHierarchy';
import { GenerateGeom } from './GenerateGeom';
import { FeatureType } from 'src/states/ApplicationState';
import { DivisionCalculator } from './DivisionCalculator';
import { LayerFunc } from './LayerFunc';
import { GenerateGeomUtils } from './GenerateGeomUtils';
import { ReferenceGeomComposite } from './ReferenceGeomComposite';
import { MapitLayerFactory } from 'src/components/MapitLayerFactory';
import { MapitState } from 'src/states/MapitState';
import JSZip from 'jszip';

export type UpdateMapDataSpec = {
  interfaceVersion:string,
  mapId:string,
  layerId:number,
  keyColumn:string,
  updatesList: {
    key:any;
    newData: {
      [column:string]:any
    }; // column må ikke være keyColumn
  }[];
};


export type GeoJsonImportFormat = {
  type:string,
  name?:string,
  crs?:{type:string, properties:{name:string}},
  features: {
    type:string,
    properties: {
      [name:string]:string
    },
    geometry: {
      type:string,
      coordinates:any
    }
  }[]    
};

export class Persistence {

  static chance = v4();
  
  static async extractMapState(
    mapitState: MapitState, 
    readOnly: boolean, 
    mapInfo:MapInfo, 
    layers:LayerInfo[], 
    userName:string, 
    createdDate:Date, 
    removeUnusedProperties: boolean, 
    userId?: string, 
    viewerState?:ViewerState, 
    endDate?:Date, 
    securityKey?:string, 
    layerHierarchy?:LayerHierarchy, 
    enabledFeatures?:FeatureType[],
    minZoom?: number,
    maxZoom?: number,
    sharedFeatureLayers?:any[],
    activeFeatureLayers?:any[]
    ):Promise<any> {

    let layerExport:LayerInfo[] = [];
    let data;
    let importedSheetData;
    layerExport = layers.map<LayerInfo>((layerInfo) => {

      // Default zoom levels if not specified.
      minZoom ??= SettingsManager.getSystemSetting("minZoom",3);
      maxZoom ??= SettingsManager.getSystemSetting("maxZoom",18);

      let geoJson = layerInfo.geoJson;
      let geoJsonDeflated = undefined;
      if (layerInfo.geometryReferenceFromMapit) {
        geoJsonDeflated = {...layerInfo.geoJson, 
          features: layerInfo.geoJson.features.map((feature) => {
            return {...feature, geometry: undefined, properties: feature.properties }
          })
        }
        geoJson = undefined ;
      }

      // Remove unused properties from geojson to save space - only when saveing a map LINK
      if (removeUnusedProperties) {

        // Properties that overrides styling etc.
        const specialProperties = [
          "fillcolor",
          "fillopacity",
          "linecolor",
          "lineopacity",
          "opacity",
          "weight"
        ]

        /**
         * Returns the properties that are used in styling.
         * @param styling The stiling
         * @returns Array of property names in use by styling.
         */
        function propertiesInStyling(styling:LayerInfoStyling):string[] {
          function pushIfPresent(val:string|undefined) {
            if (val) result.push(val);
          }
          let result:string[] = [];
          pushIfPresent(styling.colorByProperty);
          pushIfPresent(styling.heightByProperty);
          pushIfPresent(styling.iconByProperty);
          pushIfPresent(styling.sizeByProperty);
          pushIfPresent(styling.textByProperty);
          return result;
        }
        /**
         * Filters the properties object to include only the properties to keep
         * @param props A property object
         * @param propertiesToStore Array of property names to keep
         * @returns The filtered property object
         */
        function filterProperties(props:any, propertiesToStore:string[]):any {
          let result = {};
          if (propertiesToStore && propertiesToStore.length > 0) {
            Object.keys(props).forEach((key) => {
              if (propertiesToStore.includes(key)) {
                let val = props[key];
                result[key] = val;
              }
            })
          }
          return result;
        }
      
        if (geoJson && geoJson.features) {
          let propertiesToStore:string[] = [...specialProperties, ...propertiesInStyling(layerInfo.styling)];
          (layerInfo.propertiesToDisplay ||[]).forEach((k) => { propertiesToStore.push(k.name)});
          let geoJsonOnlyUsedProperties = {
            ...geoJson, 
            features: geoJson.features.map((ft) => {
              return {
                ...ft,
                properties: filterProperties(ft.properties, propertiesToStore)
              }
            })
          };
          geoJson = geoJsonOnlyUsedProperties;
        }
      }
            
      return {
        layerId:layerInfo.layerId,
        datasetname:layerInfo.datasetname,
        type:layerInfo.type,
        visible:layerInfo.visible,
        analysisResult:layerInfo.analysisResult,
        styling:layerInfo.styling,
        columnMapping:layerInfo.columnMapping,
        readonly: readOnly,
        hasNoDataBehind: Boolean(layerInfo.hasNoDataBehind),
        layerVariability: layerInfo.layerVariability,
        minZoom: minZoom,
        maxZoom: maxZoom,
        propertiesInGeoJson : layerInfo.propertiesInGeoJson,
        propertiesToDisplay: layerInfo.propertiesToDisplay,
        geoJson: geoJson,
        geoJsonDeflated: geoJsonDeflated,
        geometryReferenceFromMapit: layerInfo.geometryReferenceFromMapit,
        group: layerInfo.group
      };
    });

    // Only save visible or normal layers.
    layerExport = layerExport.filter(layer => Boolean(layer.visible) || (layer.layerVariability !== LayerVariabilityType.FixedFeatureLayer));

    let layerHierarchyCopy;

    if (layerHierarchy) {
      
      layerHierarchyCopy = {
        id: layerHierarchy.id,
        name: layerHierarchy.name,
        isCollapsed: layerHierarchy.isCollapsed,
        children: layerHierarchy.children,
        isReadonly: layerHierarchy.isReadonly,
      };
      
      layerHierarchyCopy = OldHierarchy.setAllGroupsReadonly(readOnly, layerHierarchyCopy);
    }
    let layerHierarchyExport = layerHierarchyCopy ? layerHierarchyCopy : layerHierarchy;
    
    // Lookup custom layers
    let activeFeatureLayerSpecs:{[fl:string]:ActiveFeatureLayer} = {};
    
    if (sharedFeatureLayers) {
      sharedFeatureLayers.filter((key) => 
        ! mapitState.featureLayers[key].layer?.privateUseOnly
      ).forEach((key) => {
        activeFeatureLayerSpecs[key] = mapitState.featureLayers[key].layer
        activeFeatureLayerSpecs[key].activeByDefault = activeFeatureLayers?.includes(key)
      });
    }


    let zip = new JSZip();
    zip.file("layer", JSON.stringify(layerExport));
    let zip_layers = await zip.generateAsync({type:"base64", compression:"DEFLATE"})


    let saveState:MapitFormatCompressed = {
      interfaceVersion: SettingsManager.getSystemSetting("saveDiagramInterfaceVersion", "1.1"),
      mapInfo:{...mapInfo, mapTitle: mapInfo.mapTitle || Localization.getText("Savemap:defaultFileName")},
      zip_layers: zip_layers,
      userName:userName,
      userId:userId,
      readOnly:readOnly,
      timeGenerated: new Date(),
      endDate: endDate,
      securityKey: securityKey || "",
      viewerState: viewerState,
      enabledFeatures: enabledFeatures,
      layerHierarchy: layerHierarchyExport,
      minZoom: minZoom,
      maxZoom: maxZoom,
      activeFeatureLayerSpecs: activeFeatureLayerSpecs,
      selectedBackgroundLayerKey: mapitState.selectedBackgroundLayerKey,
    };
    return saveState;
  }

    /**
     * @returns Number of features created
     */
  static createLayersFromGeoJSON(
    fileName:string,
    json:{}, 
    currentDate:Date, 
    callbackToCreateLayer:(li:LayerInfo)=> void | number, 
    areaFillColor?: string,
    areaFillOpacity?: number
    ):number {
  let currentFile = {name:fileName ? fileName : "geojson"};
  let loadedState:GeoJsonImportFormat;
  try {
    loadedState = json as GeoJsonImportFormat;
  } catch (ex:any) {
    throw Utils.createErrorEventObject(Localization.getFormattedText("File {file}. Not correct GEOJSON format {error}",{file:currentFile.name,error:ex.message}));
  }

  // Figure out the coordinate system
  let crsString = loadedState.crs && loadedState.crs.properties && loadedState.crs.properties.name;
  let crs:number = 4326; // WGS84 is default
  if (crsString) {
    if (crsString.includes("25832")) {
      loadedState = GenerateGeomUtils.GeoJsonCastToFeatureCollection(loadedState as any, "25832")
      delete loadedState.crs
      // TODO: Remind user crs in geojson has ben obsolete since August 2016
    } else {
      if (crsString.includes("4326") || crsString.includes("CRS84")) {
        crs = 4326;
      } else {
        throw Utils.createErrorEventObject(Localization.getFormattedText("File {file}. Unsupported coordinate system {crs}",{file:currentFile.name,crs:crs}));
      }
    }
  } 

  Logger.logAction("Persistence","Load map","GEOJSON");

  let datasetname = currentFile.name;

  /* Geojson Specs 
  (Obsolete) https://geojson.org/geojson-spec 
  (current) https://datatracker.ietf.org/doc/html/rfc7946
  */
  let isPointLayer:boolean = true;
  loadedState.features.forEach((feature) => {
    if (feature.geometry && feature.geometry.type !== "Point") {
      isPointLayer = false;
    }
  });
  let noOfFeatures = loadedState.features && loadedState.features.length;
  const layerInfo:LayerInfo = LayerFunc.createLayerInfoFromGeoJsonFeatureList(loadedState.features, datasetname);
  if (SettingsManager.getSystemSetting("AsDefaultCreatePopupInfoForAllFieldsInGeoJSONImports",false)) {
    let x = layerInfo.propertiesInGeoJson
    layerInfo.propertiesToDisplay = x?.map((a) => ({name:a}))
  }
  callbackToCreateLayer(layerInfo);
  return (noOfFeatures);
}

  /**
   * @returns Number of layers created
   */
  static async createLayersFromJSON(
      json:{}, 
      currentDate:Date, 
      callbackToCreateHierarchy: (lh: LayerHierarchy, layerRenumberingMap: any) => void,
      callbackToSetMapInfo:(mi:MapInfo) => void, 
      callbackToCreateLayer:(li:LayerInfo)=> number, 
      callbackToSetViewerState:(vs:ViewerState)=>void,
    ):Promise<number> {
    let currentFile = {name:"url load"};
    let loadedState:MapitImportFormat;
    try {
      loadedState = json as MapitImportFormat;
    } catch (ex:any) {
      throw Utils.createErrorEventObject(Localization.getFormattedText("File {file}. Not correct JSON format {error}",{file:currentFile.name,error:ex.message}));
    }

    if (!(loadedState.interfaceVersion)) {
      throw Utils.createErrorEventObject(Localization.getText("Data is not a Mapit diagram"));
    }

    if ("zip_layers" in loadedState) {
      const zip = new JSZip()
      let layers = await zip.loadAsync(loadedState.zip_layers as string, {base64: true});
      loadedState["layers"] = JSON.parse(await layers.file("layer")?.async("string") ?? "[]")
      delete loadedState.zip_layers
    }
    
    if (!("layers" in loadedState)) {
      throw Utils.createErrorEventObject(Localization.getText("Data is not a Mapit diagram"));
    }

    loadedState.layers = loadedState.layers.map(layer => {
      if (layer.layerVariability === LayerVariabilityType.FixedFeatureLayer) {
        layer.readonly = true;
      }
      return layer;
    });
    

    Logger.logAction(
      "Persistance", 
      "createLayersFromJSON", 
      Utils.formatString("UserName: {userName}, TimeGenerated: {timeGenerated}", {userName:loadedState.userName, timeGenerated:loadedState.timeGenerated.toString()})
    );

    // Validate format version
    let expectVersion = SettingsManager.getSystemSetting("saveDiagramInterfaceVersion", "1.1");

    // Migrate if possible
    let migratedState = await Migration.migrateOldState(expectVersion, loadedState);
    if (migratedState) {
      loadedState = migratedState as MapExportImportFormat;
    }

    if(loadedState.interfaceVersion !== expectVersion) {
      throw Utils.createErrorEventObject(
        Localization.getFormattedText(
          "File {file} version do not match. Expected: {expected}, Found: {found}",
          {file:currentFile.name, expected:expectVersion, found:loadedState.interfaceVersion}));
    }

    // Validate date information

    if (loadedState.endDate && ((loadedState.endDate || 0) < currentDate)) {
      throw Utils.createErrorEventObject(
        Localization.getFormattedText(
          "File {file} has expired on {date}.",
          {file:currentFile.name, date:(loadedState.endDate || 0).toString()}));
    }

    Logger.logAction(
      "Persistence", 
      "Load map",
      Utils.formatString(
        "User:{user} Created:{created} Expires:{expires}",
        {
          user:loadedState.userName,
          created:loadedState.timeGenerated.toString(), 
          expires:loadedState.endDate ? loadedState.endDate.toString():"not set"
        }
      )
    );

    // Restore map settings
    loadedState.mapInfo && callbackToSetMapInfo(loadedState.mapInfo);
    
    // Restore layers
    let countLoaded=0;
    let layerRenumberingMap = {};

    // Generated ColorRangeRemapper from existing Colorranges
    let layerColorRangeRemapper: {[i:number]:number} = {};
    GenerateGeom.colorRanges.forEach((e,i:number) => layerColorRangeRemapper[i] = i);

    if (loadedState.layers && loadedState.layers.length > 0) {
      Logger.logAction("MapScreen", "Load map", "Layers= " + loadedState.layers.length);

      let idx = 0;

      let promises = loadedState.layers.map((item) => {
        return new Promise<void>(async (resolve, reject) => {
          // LoadingScreen.showProgress(idx * 100 / loadedState.layers.length);
          try {
          let RefGeom: null|{} = null;
          if (item.geometryReferenceFromMapit && item.geoJsonDeflated) {
            let type=item.geometryReferenceFromMapit.geoType;
            let isByCode = MapitLayerFactory.getAdmGeomByCodeFromAreaLayerType(item.geometryReferenceFromMapit.geoType);
            let allPropertiesList:any[] = item.geoJsonDeflated.features.map((ft) => {
              return ft.properties;
            })
            let keyPropertyList:any[] = item.geoJsonDeflated.features.map((ft) => {
              let code = ReferenceGeomComposite.selectCodeElement(type, ft);
              return code;
            })
            await ReferenceGeomComposite.ensureRequiredGeomData(type) ;
            RefGeom = MapitLayerFactory.reCombineDataAndGeom(
              {type: type},
              true,
              keyPropertyList,
              allPropertiesList);
          }

              // Change how specialValues works like OutsideColorRange and AreasNoData
              if ("areasWithNoDataColor" in item.styling &&
                "hideAreasWithNoData" in item.styling
              ) {
                item.styling.areaNoData = {
                  show: !item.styling.hideAreasWithNoData,
                  color: item.styling.areasWithNoDataColor as string || SettingsManager.getSystemSetting(
                    "areaMapColorForAreasWithNoData",
                    "black"
                  )
                }
                delete item.styling.hideAreasWithNoData
                delete item.styling.areasWithNoDataColor
              }

              if ("valueOutsideRangeColor" in item.styling) {
                item.styling.outsideColorRange = {
                  show: true,
                  color: item.styling.valueOutsideRangeColor as string || SettingsManager.getSystemSetting("mapColorForOtherDataValue", "cyan")
                }
                delete item.styling.valueOutsideRangeColor
              }


          
          const layerInfo: LayerInfo = {
            layerId: item.layerId,
            datasetname: item.datasetname,
            type: item.type,
            hasNoDataBehind: item.hasNoDataBehind,
            readonly: item.readonly,
            columnMapping: item.columnMapping,
            analysisResult: item.analysisResult,
            styling: item.styling,
            visible: item.visible,
            filename: item.filename,
            crs: item.crs,
            layerVariability: item.layerVariability,
            geoJson: RefGeom ?? item.geoJson,
            group: item.group,
            geometryReferenceFromMapit: item.geometryReferenceFromMapit,
            propertiesInGeoJson : item.propertiesInGeoJson,
            propertiesToDisplay: item.propertiesToDisplay
          };
  
          // Validate that the applied colorRange exists as standard (it may be a custom color range). Otherwise create it.
          if (layerInfo.styling && layerInfo.styling.colorByValue) {
            // (layerInfo.styling!.colorByValue!.useColorRange||0)
            let x = layerColorRangeRemapper[(layerInfo.styling!.colorByValue!.useColorRange || 0)];
            if (x !== undefined) {
              // useColorRange exist in map use mapped value
              layerInfo.styling!.colorByValue!.useColorRange = x;
            } else {
              // The color range does not exist. Add a colorRange constructed from the colors used in the layer.
              let cr = layerInfo.styling.colorByValue!.divisions && DivisionCalculator.extractColorRangeFromDivision(layerInfo.styling.colorByValue!.divisions!);
              cr && GenerateGeom.addToColorRanges(cr);
              // Add new colorRange to map
              layerInfo.styling!.colorByValue!.useColorRange = layerColorRangeRemapper[layerInfo.styling!.colorByValue!.useColorRange || 0] = GenerateGeom.colorRanges.length - 1;
            }
          }
          let createdId = callbackToCreateLayer(layerInfo);
          // Wait a millisecond to allow each layer to be rendered on larger loads.
          layerRenumberingMap[item.layerId] = createdId;
          // ToDo: error handling. Only count layers successfully created
          countLoaded++;
          idx++;

          resolve();
        } catch (err:any) {
          reject("Load layer failed: "+(err.message || err))
        }
        })
      })
      await Promise.allSettled(promises).then(async (itemPromise) => {
        itemPromise.forEach(async (values) => {
          if (values.status !== "fulfilled") {
            console.error("Import Failed for layer")
            return
          }
        });
      });
      // LoadingScreen.hide();
      // Reassign ID's in layer hierarchy.
      // if (loadedState.layerHierarchy) {
      //   loadedState.layerHierarchy = OldHierarchy.mapNumbersToIds(loadedState.layerHierarchy, layerRenumberingMap, {});
      // }
      loadedState.layerHierarchy && callbackToCreateHierarchy(loadedState.layerHierarchy, layerRenumberingMap);

      // set viewerstate (zoomLevel, center)
      loadedState.viewerState && callbackToSetViewerState(loadedState.viewerState);
      // set the access rights that the user may use in read only state
      //      loadedState.enabledFeatures && callbackToSetSessionFeatures(loadedState.enabledFeatures);
      
      // return success
      return (countLoaded);
    } else {
      // set viewerstate (zoomLevel, center) even if no layers were saved
      loadedState.viewerState && callbackToSetViewerState(loadedState.viewerState);

      return (countLoaded);
//      throw Utils.createErrorEventObject(Localization.getFormattedText("File {file} did not contain any layers",{file:currentFile.name}));
    }

  }

  static createStateReference(state:any /* MapExportImportFormat*/, path: string, ref?: string) {
    let refOrUUID = ref ? ref : v4();

    const metadata = {
      mapTitle: encodeURI(state.mapInfo ? state.mapInfo.mapTitle : ""),
      expirationDate: state.endDate ? state.endDate.toISOString() : "",
      userId: state.userId,
      userName: state.userName
    };

    let promise = AWSAPIGatewayInterface.persistState(refOrUUID, state, path, metadata)
    .then(
      (success) => {
        Logger.logInfo("Persistence","Save state", refOrUUID);
        return refOrUUID;
      })
    .catch(
      (error) => {
        throw new Error(Localization.getFormattedText("Persist state failed: {error}",{error:error.message || error.stack}));
      }
    );
    return promise;
  }

  static async lookupStateByReference(ref:string) /*MapExportImportFormat*/ {
    let result:MapExportImportFormat;
    let isProduction:boolean = Utils.getDeploymentMode(process.env.REACT_APP_ENVIRONMENT) === DeploymentMode.Production;

    let promise = AWSAPIGatewayInterface.loadState(ref).then(
      (success) => {
        if (success.Body) {
          return JSON.parse(success.Body.toString())
        }
        result = success.Item && success.Item.Link || success.body || success;
        Logger.logInfo("Persistence","Load state", ref);      
        return result;
      }).catch((err) => {
        throw(new Error(err.message || err));
    }); 
    return promise;
  }

  /**
   * 
   * @param columnName 
   * @param analysisResult
   * @returns One-based index for columns 
   */
  static findDataIdByColumnName(columnName:string, analysisResult:SheetAnalysisResult):number {
    let dataId:number = -1; // one-based column index
    analysisResult && analysisResult.columns.forEach((item,idx) => {
      if (item.name === columnName) {
        dataId = idx+1;
      }
    });
    return dataId;
  }

  static findColumnInternalNameByDataId(keyColumnId:number, columnMapping:{[internalName:string]:number|string}):(string)[] {
    let result:any[]=[];
    Object.keys(columnMapping).forEach((item,idx) => {
      if (columnMapping[item].toString() === keyColumnId.toString()) {
        result.push(item);
      }
    });
    return result;
  }

  // ----------------------------------------------------------------------------------------
  static updateSavedMapLink(updateSpec:UpdateMapDataSpec, map:any /*MapExportImportFormat*/):any /*MapExportImportFormat*/ {
    const expectedVersion="1.0";
    if (!(updateSpec && updateSpec.interfaceVersion && updateSpec.keyColumn && updateSpec.updatesList)) {
      throw Utils.createErrorEventObject("Incorrect format of updatespec");
    }
    if (updateSpec.interfaceVersion !== expectedVersion) {
      throw Utils.createErrorEventObject(
        Utils.formatString("Unexpected interface version. Expected:{expected}, Found:{found}",{expected:expectedVersion, found:updateSpec.interfaceVersion})
      );
    }
    // find keycolumn
    let keyColumn = updateSpec.keyColumn;
    let layer = map.layers[updateSpec.layerId];
    if (!layer) {
      throw Utils.createErrorEventObject(Utils.formatString("Layer {layerId} not found",{layerId:updateSpec.layerId}));
    }
    let keyColumnId:number = -1; // one-based column index
    keyColumnId = this.findDataIdByColumnName(keyColumn, layer.analysisResult);
    if (keyColumnId < 0) {
      throw Utils.createErrorEventObject(Utils.formatString("Key Column {column} not found",{column:keyColumn}));
    }
    let listOfKeyColumns = this.findColumnInternalNameByDataId(keyColumnId, layer.columnMapping);
    if (!(listOfKeyColumns && listOfKeyColumns.length > 0)) {
      throw Utils.createErrorEventObject(Utils.formatString("Key Column List not found for {column} {comment}",{column:keyColumnId,
      comment:"A key field is used which is not displayed/used/stored on the map"}));
    }
    let keyColumnValues:any[] = layer.data && layer.data[listOfKeyColumns[0]];
    if (!keyColumnValues) {
      throw Utils.createErrorEventObject(Utils.formatString("Key Column Values {column} not found",{column:listOfKeyColumns[0]}));
    }
    updateSpec.updatesList.forEach((item,idx) => {
      let value=item.key;
      // find index to update.
      let index = keyColumnValues.findIndex((keyVal) => { return keyVal === value;});
      if (index < 0) {
        throw Utils.createErrorEventObject(Utils.formatString("New key value {value} not found in column {col} list {list}",{value:value, col:keyColumnId, list:JSON.stringify(listOfKeyColumns)}));
      }
      Object.keys(item.newData).forEach(element => {
        // find data to update
        let dataId = this.findDataIdByColumnName(element, layer.analysisResult);
        if (dataId < 0) {
          throw Utils.createErrorEventObject(Utils.formatString("New value Column {column} not found",{column:element}));
        }
        // A value may recide in several lists if used for several stylings e.g. both color and label
        let listOfColumnsToUpdate = this.findColumnInternalNameByDataId(dataId, layer.columnMapping);
        if (listOfColumnsToUpdate && listOfColumnsToUpdate.length > 0) {
          let newValue = item.newData[element];
          listOfColumnsToUpdate.forEach((colInternalName) => {
            console.info(Utils.formatString(
              "Changing item {item} column {col} {from} => {to} ",
              {item:item.key,col:colInternalName,from:layer.data![colInternalName][index],to:newValue}));
            layer.data![colInternalName][index] = newValue;
          });
        } else {
          // Ignore if new value does not exist (then it is not shown on map, popup or used in styling)
        }
      });      
      // update end date - extend the map by x days.
      let daysValidity = SettingsManager.getSystemSetting("exported-link-validity-days", 30);
      let expireDate = Utils.addDays(new Date(), daysValidity);
      map.endDate = expireDate;
    });
    return map;
  }
}