import { AppMessagesContext } from "@viamap/viamap2-common";
import { useContext, useEffect, useRef } from "react";
import { useState } from "react";
import { MitLatLng } from "src/managers/MapFacade";
import { MitLayerHandleMaplibre } from "src/managers/MapFacadeMaplibre";
import { actionSetMapInteractionState, MapInteractionState, MapitStateContext } from "src/states/MapitState";
import * as turf from '@turf/turf';
import circle from '@turf/circle';


export type Props = {
   showWindow:number,
   active?:boolean;
   minDistanceMeters?:number,
   maxDistanceMeters?:number,
   callbackOnStatus?: (centerSelected:boolean, rimSelected:boolean, center:MitLatLng, distanceMeters:number) => void,
   callbackOnCircle?: (center:MitLatLng, distanceMeters:number) => void
}

export const SelectCircle = (props:Props) => {
   const { dispatch: appMessageDispatch } = useContext(AppMessagesContext);
   const { state: mapitState, dispatch: mapitStateDispatch } = useContext(MapitStateContext);
   let [active, setActive] = useState<boolean>(Boolean(props.active));
   let [sizing, setSizing] = useState<boolean>(false);
   let [dist, setDist]=useState<number>(0);
   let [center, setCenter] = useState<{ lng: number, lat: number }>();
   let [periphery, setPeriphery] = useState<{ lng: number, lat: number }>();
   let [circleLayer, setCircleLayer] = useState<MitLayerHandleMaplibre>();

   useEffect(() => {
      if (props.active) {
         mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Override))
      }
      return () => mapitStateDispatch(actionSetMapInteractionState(MapInteractionState.Normal))
   },[props.active])

   const EmptyJSON = {
      "type": "FeatureCollection",
      "features": []
   };

   function createSource(data: any) {
      return {
         type: "geojson",
         data: data
      };
   }

   function createLayer(id: string, source: string) {
      return {
         id: id,
         type: 'fill',
         source: source,
         layout: {
         },
         paint: {
            'fill-color': "#BD9060",
            'fill-opacity': sizingRef.current ? 0.2 : 0.5,
            'fill-outline-color': "#816c5d"
         }
      };
   }

   function createLayerLine(id: string, source: string) {
      return {
         id: id,
         type: 'line',
         source: source,
         layout: {
         },
         paint: {
            'line-color': 'black',
         }
      };
   }

   function createLayerPoint(id: string, source: string) {
      return {
         id: id,
         type: 'symbol',
         source: source,
         layout: {
            'icon-image':'blue-pin',
            'text-field': [ 'get', 'distance']
         },
         paint: {
            'text-color': 'black',
         }
      };
   }

   const sizingRef = useRef(sizing);
   const activeRef = useRef(active);

   function clickhandler(e) {
      e.preventDefault();
      if (activeRef.current) {
         if (!sizingRef.current) {
            setCenter(e.lngLat);
            setPeriphery(e.lngLat);
            setSizing(true);
         } else {
            setSizing(false);
            setPeriphery(e.lngLat);
            props.callbackOnCircle && props.callbackOnCircle(new MitLatLng(center!.lat, center!.lng), dist * 1000);
         }
      }
   }
   function moveHandler(e) {
      e.preventDefault();
      if (activeRef.current) {
         if (sizingRef.current) {
            setPeriphery(e.lngLat);
         }
      }
   }

   useEffect(() => {
      activeRef.current = active;
      if (mapitState.map.on) {
         if (active) {
            mapitState.map.on('click', clickhandler);
            mapitState.map.on('mousemove', moveHandler);
      
            const delayedStart = () => {
              const preventTouchOnMove = () => {
                mapitState.map.off('touchend', clickhandler)
                mapitState.map.off('move', preventTouchOnMove)
              }
              mapitState.map.on('touchend', clickhandler)
              mapitState.map.on('move', preventTouchOnMove)
              setTimeout(() => {
                mapitState.map.off('touchend', clickhandler)
              }, 200)
            }
            mapitState.map.on('touchstart', delayedStart);
            return () => {
               mapitState.map.off('touchstart', delayedStart);
               mapitState.map.off('click', clickhandler);
               mapitState.map.off('mousemove', moveHandler);
            }
         }
      }
   }, [active, center]);

   useEffect(() => {
      sizingRef.current = sizing;
   }, [sizing]);

   useEffect(() => {
      var distance: number | null = null
      if (periphery && center) {
         var cent = turf.point([center.lng, center.lat]);
         var peri = turf.point([periphery.lng, periphery.lat]);

         distance = turf.distance(cent, peri, {units:'kilometers'});
         if (distance*1000 > (props.maxDistanceMeters || Number.MAX_SAFE_INTEGER)) {
            // to ensure that we hit the maximum and not som randum number depening on resolution/zoom level.
            distance = (props.maxDistanceMeters!)/1000.0;
         }
         if (distance*1000 < (props.minDistanceMeters || Number.MIN_SAFE_INTEGER)) {
            // to ensure that we hit the maximum and not som randum number depening on resolution/zoom level.
            distance = (props.minDistanceMeters!)/1000.0;
         }
         let circleJSON = circle(cent, distance, {});
         let l = new MitLayerHandleMaplibre();
         l.addSource('circleData', createSource(circleJSON));
         l.addLayer(createLayer('circle', 'circleData'));
         mapitState.map.updateLayer(l);
         setCircleLayer(l);
         // var centJSON = turf.point([center.lng, center.lat],{distance:distance.toLocaleString(undefined, {minimumFractionDigits:3})+" km"});
         // // let lineJSON = turf.lineString([[center.lng, center.lat], [periphery.lng, periphery.lat]], {distance:distance.toLocaleString(undefined, {minimumFractionDigits:3})+" km"});
         // // var chunk = turf.lineChunk(lineJSON, distance/2, {units: 'kilometers'});
         // let l2 = new MitLayerHandleMaplibre();
         // l2.addSource('lineData', createSource(centJSON));
         // // l2.addLayer(createLayerLine('line', 'lineData'));
         // l2.addLayer(createLayerPoint('point', 'lineData'));
         // mapitState.map.updateLayer(l2);
      }
      props.callbackOnStatus && props.callbackOnStatus(Boolean(center), !sizingRef.current,  center ? new MitLatLng(center.lat, center.lng) : new MitLatLng(0,0), (distance ?? dist) * 1000);
   }, [periphery, center, sizing]);

   useEffect(() => {
      // Cleanup function
      return (() => {
         circleLayerRef.current && mapitState.map.removeLayer(circleLayerRef.current);
      })
   }, []);

   let circleLayerRef = useRef(circleLayer);

   useEffect(() => {
      circleLayerRef.current = circleLayer;
   }, [circleLayer]);
   
   return props.showWindow ? (
      <div style={{position:"absolute", top:"20px", left:"50px", zIndex:1000 + props.showWindow, isolation:"isolate"}}>
         <></>
      </div>
   ) : null;
}