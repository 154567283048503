// GenerateGeom


import * as M from 'maplibre-gl';
import * as turf from '@turf/turf';
import circle from '@turf/circle';
import proj4 from 'proj4';

import {Utils} from '@viamap/viamap2-common';
import { GeoJsonObject } from 'geojson';
import {ColorRange, ProjectionType, LayerInfo, LayerInfoStyling, 
  SizeRangeList,
  SizeRange,
  DataDivisionList,
  DataDivision,
  DataDivisionType,
  RowDataQuality, LabelButton, ClusteringType,  MapInfo, PictureLayoutType, PictureSize, PopupLayout,
  LayerVariabilityType} from '../common/managers/Types';
import { Logger } from '@viamap/viamap2-common';
import { AWSAPIGatewayInterface } from './AWSAPIGatewayInterface';

import chroma from 'chroma-js';
import { GenerateGeomUtils, PointMetaData } from './GenerateGeomUtils';
import { MitLatLng, MitLayerHandle, MitMap } from './MapFacade';
import { MaplibreUtils } from './MaplibreUtils';
import { SettingsManager } from '@viamap/viamap2-common';
import { AllowedMapLayerType, getMitLayerHandle } from './MapFacade';
import { MapitLayerFactory } from 'src/components/MapitLayerFactory';
import { Feature, FeatureType } from 'src/states/ApplicationState';
import { DivisionCalculator } from './DivisionCalculator';
import booleanPointInPolygon from '@turf/boolean-point-in-polygon';
import { MapitState } from 'src/states/MapitState';
import { POIS } from 'src/HLED/managers/PoiManager';
import { MapImageCache } from './MapImageCache';

export type PoiDecl = {[poitype:string]:{name:string, icon:string, transtype:string, featureGroup?: FeatureType}};
export type FeatureLayerOptions = any;

class HeightNormalizer {
  constructor(heights:number[]) {
    // ToDo:
  }

  normalize(height:number):number {
    // ToDo:
    return height;
  }
}
export type SpatialReturnType = {[layerId: string]: number[]} ;

export class GenerateGeom /*Maplibre*/ /* implements GenerateGeomInterface */ {

  static _instance:GenerateGeom;
  static listenerCache: {} = {};
  static dataIsInitialized:boolean=false;
  static markers = {
      'colors': () => SettingsManager.getSystemSetting("standardSingleColorRange"),
      'layers': new Array<string>(),
      'indexOfNextColorToUse': 0 
    }
  static sizeRanges:SizeRangeList=[];
  // static colorRanges:ColorRangeList=[];


  static addedColorRanges: ColorRange[] = []

  static get colorRanges() {
    let colorRanges = [...GenerateGeomUtils.getColorRanges(),...GenerateGeom.addedColorRanges];
    let customColorRanges:any[]=SettingsManager.getSystemSetting("customColorRanges", [], true);
    if (customColorRanges) {
      let maxOrder=0;
      colorRanges.forEach((cr)=> {if (cr.order > maxOrder) {maxOrder=cr.order;}});
      customColorRanges.forEach((ccr:{name:string, colors: string[]})=> {
        maxOrder++;
        colorRanges.push({
          ...ccr,
          order: maxOrder,
          isCustom: true
        });
      });
    }

    let orderSorter = (v1:ColorRange|SizeRange, v2: ColorRange|SizeRange) => { 
      return v1.order === v2.order ? 0: v1.order > v2.order ? 1: -1; 
    };
    
    colorRanges.sort(orderSorter);
    return colorRanges;
  }

  static markerClusterOptions = () => ({
    maxClusterRadius: SettingsManager.getSystemSetting("maxClusterRadius", 80),
  });
/* tslint:disable */
static categoryField = '5074'; // This is the fieldname for marker category (used in the pie and legend)
static metadata;
static rmax=30;

  static coordinateBoundsLonLower = 8 // SettingsManager.getSystemSetting("coordinateBoundsLonLower", 8);  
  static coordinateBoundsLonUpper = 16 // SettingsManager.getSystemSetting("coordinateBoundsLonUpper", 16);  
  static coordinateBoundsLatLower = 54 // SettingsManager.getSystemSetting("coordinateBoundsLatLower", 54);  
  static coordinateBoundsLatUpper = 58 // SettingsManager.getSystemSetting("coordinateBoundsLatUpper", 58);  
 
  // todo: Localize poi names.

  static POITYPES:PoiDecl = {
    'daycare': {
      name: 'Daginstutition',
      icon: 'daycare',
      transtype: 'foot'
    },
    'doctor': {
      name: 'Læge',
      icon: 'doctor',
      transtype: 'foot'
    },
    'hospital': {
      name: 'Hospital',
      icon: 'hospital',
      transtype: 'car'
    },
    'junction': {
      name: 'Motorvej',
      icon: 'junction',
      transtype: 'car'
    },
    'metro': {
      name: 'Metro',
      icon: 'metro',
      transtype: 'foot'
    },
    'school': {
      name: 'Skole',
      icon: 'school',
      transtype: 'foot'
    },
    'stop': {
      name: 'Stoppested',
      icon: 'stop',
      transtype: 'foot'
    },
    'strain': {
      name: 'S-Tog',
      icon: 'strain',
      transtype: 'foot'
    },
    'supermarket': {
      name: 'Supermarked',
      icon: 'supermarket',
      transtype: 'foot'
    },
    'train': {
      name: 'Regionaltog',
      icon: 'train',
      transtype: 'foot'
    },
    'library': {
      name: 'Bibliotek',
      icon: 'library',
      transtype: 'foot'
    },
    'pharmacy': {
      name: 'Apotek',
      icon: 'pharmacy',
      transtype: 'auto'
    },
    'coast': {
      name: 'Kyst',
      icon: 'coast',
      transtype: 'foot'
    },
    'forest': {
      name: 'Skov',
      icon: 'forest',
      transtype: 'foot'
    },
    'lake': {
      name: 'Sø',
      icon: 'lake',
      transtype: 'foot'
    },
    'airport': {
      name: 'Lufthavn',
      icon: 'airport',
      transtype: 'car'
    },
    'sportshall': {
      name: 'Idrætshal',
      icon: 'sportshall',
      transtype: 'foot'
    },
    'publicbath': {
      name: 'Svømmehal',
      icon: 'publicbath',
      transtype: 'foot'
    },
    'soccerfield': {
      name: 'Fodboldbane',
      icon: 'soccerfield',
      transtype: 'foot'
    },
    'roadtrain': {
      name: 'Modulvogntog',
      icon: 'roadtrain',
      transtype: 'car'
    },
    'lightrail': {
      name: 'Letbane',
      icon: 'lightrail',
      transtype: 'foot'
    },
    "supercharger": {
      name: "Lynlader",
      icon: "supercharger",
      transtype: "car",
      featureGroup: Feature.POIEVCharger,
    },
    "chargingstation": {
      name: "Ladestation",
      icon: "chargingstation",
      transtype: "car",
      featureGroup: Feature.POIEVCharger,
    },

  }
  
  static addToColorRanges(colorRange:ColorRange) {

    GenerateGeom.addedColorRanges.push(colorRange);
  }
  
  static async loadAllImages(map: MitMap, iconLoadList: { path: string, iconKey: string }[], callback: () => void) {
   if (iconLoadList.length > 0) {
     Promise.all(iconLoadList.map(async (iconSpec:{path: string, iconKey: string}) => {
      await new Promise<void>((resolve) => this.loadImage(map, iconSpec!.iconKey, iconSpec!.path, () => {resolve()}))
     })).then(() => {
      callback?.()
     })
   } else {
     callback && callback();
   }
 }

 static loadImage(map: MitMap, key: string, path: string, callback: () => void) {
    MapImageCache.loadImage(path, (image) => {
      const hasimg : boolean = map.hasImage(key);
      if (!hasimg) {
        map.addImage(key, image);
        callback()
      }
      callback()
    })
 }

 static setupBaseData(map: MitMap) {
    // ----- Initialize Markers ------------------------------
  
    let sizeRanges = GenerateGeomUtils.getSizeRanges();
    let orderSorter = (v1:ColorRange|SizeRange, v2: ColorRange|SizeRange) => { 
      return v1.order === v2.order ? 0: v1.order > v2.order ? 1: -1; 
    };
      
    // sort by order
    sizeRanges.sort(orderSorter);
    GenerateGeom.sizeRanges = sizeRanges;
    GenerateGeom.dataIsInitialized = true;
  }

  // ensure that the number is returned nummeric (and not string)
  static  toN(numStr:string) { return (Number.parseInt(numStr,10));}

  static getNextMarker(markers:{}) {
    let i = this.markers.layers[this.markers.indexOfNextColorToUse];
    // Clone the icon
//    let iconToReturn = this.extend({}, this.markers.layers[this.markers.indexOfNextColorToUse]);
    let iconToReturn = this.markers.layers[this.markers.indexOfNextColorToUse];

    let nameToReturn = this.markers.colors()[this.markers.indexOfNextColorToUse];
    this.markers.indexOfNextColorToUse += 1;
    if (this.markers.indexOfNextColorToUse > this.markers.colors().length - 1) {
      this.markers.indexOfNextColorToUse = 0;
    }
    return {  
      'name': nameToReturn,
      'icon': iconToReturn
    };
  }

  static peekNextColor() {
    return this.markers.colors()[this.markers.indexOfNextColorToUse];
  }

   getNextColor() {
    let colorToReturn = GenerateGeom.peekNextColor();
    GenerateGeom.markers.indexOfNextColorToUse += 1;
    if (GenerateGeom.markers.indexOfNextColorToUse > GenerateGeom.markers.colors().length - 1) {
      GenerateGeom.markers.indexOfNextColorToUse = 0;
    }
    return colorToReturn;
  }

  static constructMarkerForColor(color:string): {}|null {
    let markerObj;
    let idx:number = this.markers.colors().indexOf(color);
    if (idx>=0) {
      // Standard color. One of https://github.com/pointhi/leaflet-color-markers
      markerObj = {
        'name': color,
        'icon': this.markers.layers[idx]
      };
    } else {
      // Not a standard color
      markerObj = {
          'name': color,
          'icon': "",
      };    
    }
    return markerObj;
  }

//   static cloneIcon(icon:L.Icon):L.Icon {
//     return new L.Icon({
//                 'iconUrl':icon.options.iconUrl,
//                 'iconSize': icon.options.iconSize,
//                 'iconAnchor': icon.options.iconAnchor,
//                 'popupAnchor': icon.options.popupAnchor,
//                 'shadowSize': icon.options.shadowSize
//               });
//   }

  static MitAreaSvg(color, stroke?:any) {
    let fillColor = color
    let strokeColor = stroke || ((color && chroma.valid(color)) ? chroma(color).darken(2).css() : "green"); // fallback

    return `<?xml version="1.0" encoding="UTF-8"?>
    <svg stroke="${strokeColor}" fill="${fillColor}" stroke-width="0" viewBox="0 0 1024 1024" class="ClickAble" data-toggle="tooltip" height="1em" width="1em" xmlns="http://www.w3.org/2000/svg" >
    <path d="M928 160H96c-17.7 0-32 14.3-32 32v640c0 17.7 14.3 32 32 32h832c17.7 0 32-14.3 32-32V192c0-17.7-14.3-32-32-32zM338 304c35.3 0 64 28.7 64 64s-28.7 64-64 64-64-28.7-64-64 28.7-64 64-64zm513.9 437.1a8.11 8.11 0 0 1-5.2 1.9H177.2c-4.4 0-8-3.6-8-8 0-1.9.7-3.7 1.9-5.2l170.3-202c2.8-3.4 7.9-3.8 11.3-1 .3.3.7.6 1 1l99.4 118 158.1-187.5c2.8-3.4 7.9-3.8 11.3-1 .3.3.7.6 1 1l229.6 271.6c2.6 3.3 2.2 8.4-1.2 11.2z">
    </path>
    </svg>
    `
  }

  
  static MitOldPinSvg(color, stroke?:any) {
    let fillColor = color
    let strokeColor = stroke || ((color && chroma.valid(color)) ? chroma(color).darken(2).css() : "green"); // fallback

    return `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="50" height="82" version="1.1" viewBox="0 0 13 22" xml:space="preserve"
      xmlns="http://www.w3.org/2000/svg"
      xmlns:xlink="http://www.w3.org/1999/xlink">
      <defs>
        <filter id="filter15-2" x="-.22" y="-.22" width="1.4" height="1.4" color-interpolation-filters="sRGB">
          <feGaussianBlur stdDeviation="0.33741999"/>
        </filter>
        <filter id="filter24" x="-.026" y="-.016" width="1.1" height="1" color-interpolation-filters="sRGB">
          <feGaussianBlur stdDeviation="0.073482583"/>
        </filter>
        <linearGradient id="linearGradient16" x1="6.6" x2="6.8" y1="22" y2=".76" gradientUnits="userSpaceOnUse">
          <stop stop-color="rgb(254,254,254)" stop-opacity="0" offset=".26"/>
          <stop stop-color="rgb(254,254,254)" stop-opacity=".31" offset="1"/>
        </linearGradient>
      </defs>
      <g clip-rule="evenodd" fill-rule="evenodd">
        <path d="m6.7 4.4c3.2.021 3.3 5 0 5-3.3-.021-3.2-5 0-5z" fill="rgb(254,254,254)" filter="url(#filter15-2)" stroke-opacity="0"/>
        <path transform="matrix(1 0 0 1 -.31 -.72)" d="m6.7 1.3c-3.3 0-6.1 2.9-6.1 6 0 1.4.8 3.2 1.4 4.4l4.7 9.1 4.7-9.1c.57-1.2 1.4-2.9 1.4-4.4 0-3.1-2.7-6-6.1-6zm0 3.6c1.3.0086 2.4 1.1 2.4 2.4s-1.1 2.4-2.4 2.4c-1.3-.0086-2.4-1.1-2.4-2.4 0-1.3 1.1-2.4 2.4-2.4z" fill="${fillColor}" filter="url(#filter24)" stroke="${strokeColor}" stroke-linecap="round" stroke-width=".27" style="mix-blend-mode:normal;paint-order:normal"/>
        <path transform="matrix(1 0 0 1 -.013 -.42)" d="m6.7 1.3c-3.3 0-6.1 2.9-6.1 6 0 1.4.8 3.2 1.4 4.4l4.7 9.1 4.7-9.1c.57-1.2 1.4-2.9 1.4-4.4 0-3.1-2.7-6-6.1-6zm.047 3.1c1.6 4e-7 2.9 1.3 2.9 2.9 0 1.6-1.3 2.8-2.9 2.8-1.6-3e-4-2.9-1.3-2.9-2.8 0-1.6 1.3-2.9 2.9-2.9z" fill="url(#linearGradient16)" stroke="rgb(254,254,254)" stroke-linecap="round" stroke-opacity=".47" stroke-width=".36" style="mix-blend-mode:normal"/>
      </g>
    </svg>
    `;
  }

  static MitPinSvg(color, stroke?:any) {
    let fillColor = color
    let strokeColor = stroke || ((color && chroma.valid(color)) ? chroma(color).darken(2).css() : "green"); // fallback

    return `<?xml version="1.0" encoding="UTF-8" standalone="no"?>
<svg
   id="icn-location2"
   width="22.482571"
   height="37.994141"
   viewBox="0 0 22.482571 37.994142"
   version="1.1"
   xml:space="preserve"
   sodipodi:docname="MapitPin.svg"
   inkscape:version="1.4-beta (62f545ba5e, 2024-04-22)"
   xmlns:inkscape="http://www.inkscape.org/namespaces/inkscape"
   xmlns:sodipodi="http://sodipodi.sourceforge.net/DTD/sodipodi-0.dtd"
   xmlns:xlink="http://www.w3.org/1999/xlink"
   xmlns="http://www.w3.org/2000/svg"
   xmlns:svg="http://www.w3.org/2000/svg"><sodipodi:namedview
     id="namedview1"
     pagecolor="#ffffff"
     bordercolor="#000000"
     borderopacity="0.25"
     /><defs
     id="defs1"><linearGradient
       id="linearGradient4"><stop
         style="stop-color:#ffffff;stop-opacity:0.8;"
         offset="0"
         id="stop3" /><stop
         style="stop-color:#ffffff;stop-opacity:1;"
         offset="0.93906808"
         id="stop4" /></linearGradient><linearGradient
       id="linearGradient62"><stop
         style="stop-color:#000000;stop-opacity:0.3;"
         offset="0"
         id="stop61" />
         <stop
         style="stop-color:#ffffff;stop-opacity:0.3;"
         offset="1"
         id="stop62" /></linearGradient><linearGradient
       id="linearGradient59"><stop
         style="stop-color:${color};stop-opacity:0.81264636;"
         offset="0"
         id="stop59" /><stop
         style="stop-color:${color};stop-opacity:0;"
         offset="1"
         id="stop60" /></linearGradient><linearGradient
       xlink:href="#linearGradient59"
       id="linearGradient60"
       x1="6.862709"
       y1="22.817499"
       x2="30.345285"
       y2="22.817499"
       gradientUnits="userSpaceOnUse"
       gradientTransform="translate(-7.3627153,-3.8204289)" /><linearGradient
       xlink:href="#linearGradient62"
       id="linearGradient61"
       gradientUnits="userSpaceOnUse"
       x1="6.862709"
       y1="22.817499"
       x2="30.345285"
       y2="22.817499"
       gradientTransform="translate(-7.3627153,-3.8204289)" /><radialGradient
       inkscape:collect="always"
       xlink:href="#linearGradient4"
       id="radialGradient3"
       cx="11.240729"
       cy="11.215602"
       fx="11.240729"
       fy="11.215602"
       r="4.3642583"
       gradientUnits="userSpaceOnUse"
       gradientTransform="translate(0,0.04661858)" /></defs><path
     d="M 11.240383,2.5383116e-7 C 5.0320487,1.5383116e-7 0.03518852,5.0339526 1.4801947e-4,11.242187 -0.04647098,19.501738 10.925618,37.99414 11.209133,37.99414 11.492648,37.99414 22.48257,19.466511 22.48257,11.242187 22.48257,5.0338538 17.448717,2.5383116e-7 11.240383,2.5383116e-7 Z m 0,6.87695284616884 c 2.410979,-3.07e-4 4.365541,1.954255 4.365234,4.3652339 -7.71e-4,2.410217 -1.955017,4.363588 -4.365234,4.363281 -2.4094553,-7.7e-4 -4.3625113,-1.953826 -4.3632823,-4.363281 -3.07e-4,-2.4102169 1.953065,-4.3644629 4.3632823,-4.3652339 z"
     style="fill:${color};fill-opacity:0.98;stroke:none"
     id="path4" /><path
     d="M 11.240383,2.5383116e-7 C 5.0320487,1.5383116e-7 0.03518902,5.0339526 1.4801947e-4,11.242187 -0.04647198,19.501738 10.925618,37.99414 11.209133,37.99414 11.492648,37.99414 22.48257,19.466511 22.48257,11.242187 22.48257,5.0338538 17.448717,2.5383116e-7 11.240383,2.5383116e-7 Z m 0,6.87695284616884 c 2.410979,-3.07e-4 4.365541,1.954255 4.365234,4.3652339 -7.71e-4,2.410217 -1.955017,4.363588 -4.365234,4.363281 -2.4094553,-7.7e-4 -4.3625113,-1.953826 -4.3632823,-4.363281 -3.07e-4,-2.4102169 1.953065,-4.3644629 4.3632823,-4.3652339 z"
     style="fill:url(#linearGradient60);fill-opacity:0.7;stroke:none"
     id="path4-2" /><path
     d="M 11.240383,2.5383116e-7 C 5.0320487,1.5383116e-7 0.03518892,5.0339523 1.4791947e-4,11.242187 -0.04647208,19.501738 10.925618,37.99414 11.209133,37.99414 11.492648,37.99414 22.48257,19.466511 22.48257,11.242187 22.48257,5.0338533 17.448717,2.5383116e-7 11.240383,2.5383116e-7 Z m 0,6.87695284616884 c 2.410979,-3.07e-4 4.365541,1.954255 4.365234,4.3652339 -7.71e-4,2.410217 -1.955017,4.363588 -4.365234,4.363281 -2.4094553,-7.7e-4 -4.3625113,-1.953826 -4.3632823,-4.363281 -3.07e-4,-2.4102169 1.953065,-4.3644629 4.3632823,-4.3652339 z"
     style="fill:url(#linearGradient61);fill-opacity:0.7;stroke:none"
     id="path4-2-1"
     sodipodi:nodetypes="sssssccccc" /><path
     d="m 11.239754,6.8979621 c 2.410979,-3.07e-4 4.365541,1.9542539 4.365234,4.3652229 -7.71e-4,2.410217 -1.955017,4.363588 -4.365234,4.363281 C 8.8302978,15.625696 6.8772418,13.67264 6.8764708,11.263185 6.8761638,8.852978 8.8295358,6.8987331 11.239754,6.8979621 Z"
     style="fill:url(#radialGradient3);stroke:none"
     id="path1" /></svg>

    `;
  }

  static async GetEveryMitPin(color:string, addImage: (url) => void) {
    var canvas = document.createElement('canvas') as HTMLCanvasElement;
    // var canvas = document.getElementById('canvas') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');

    if (ctx === null) {
      return
    }

    let fillColor = color;

    var svg = new Blob([GenerateGeom.MitPinSvg(color)], {
      type: "image/svg+xml;charset=utf-8"
    });
    
    var url = URL.createObjectURL(svg);

    var img = new Image();
    img.width = 22;
    img.height = 37;
    canvas.width = 54;
    canvas.height= 88; 

    img.addEventListener('load', e => {
      ctx!.drawImage(img, 0, 0, 54, 88);
      addImage(canvas.toDataURL("image/png"));
      URL.revokeObjectURL(url);
    });
    img.src = url
  }

  static async SVGToImage(svg: string, width: number, height: number): Promise<string> {
    var canvas = document.createElement('canvas') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
    if (ctx === null) {
      return ""
    }

    var blob = new Blob([svg], {
      type: "image/svg+xml;charset=utf-8"
    });
    
    var img = new Image();
    img.width = width;
    img.height = height;
    canvas.width = width;
    canvas.height= height; 

    return new Promise<string>((resolve) => {
      var url = URL.createObjectURL(blob);
      img.addEventListener('load', e => {
        ctx!.drawImage(img, 0, 0, width, height);
        resolve(canvas.toDataURL("image/png"));
        URL.revokeObjectURL(url);
      });
      img.src = url
    });
  }

  /**
   * 
   * @param icon valueArray joined by -, first two mit and patt
   * @param callbackOnComplete 
   */
  static async getEveryMitPattern(icon: any, callbackOnComplete: (url: any) => void) {
    var canvas = document.createElement('canvas') as HTMLCanvasElement;
    canvas.width = 64;
    canvas.height = 64;

    var ctx = canvas.getContext('2d');

    let arr = icon.split("-")
    let pattern = arr[2];
    let fgColor = arr[3];
    let bgColor = arr[4];

    switch (pattern as "pill"|"line"|"dots") {
      case 'pill': await GenerateGeom.generatePill.apply(this, [ctx, fgColor, ...arr.slice(5)] as any)
      case 'line': await GenerateGeom.generateLine.apply(this, [ctx, fgColor, ...arr.slice(5)] as any)
      case 'dots': await GenerateGeom.generateDots.apply(this, [ctx, fgColor, ...arr.slice(5)] as any)
    }


    callbackOnComplete(canvas.toDataURL("image/png"));
  }

  static async generatePill(ctx?: CanvasRenderingContext2D | null, color?:string, b?:any) {

  }  
  static async generateLine(ctx?: CanvasRenderingContext2D | null, color?:string, direction=3, width=2) {
    const gridSize = 64 / 4
    const offset = gridSize / 2;
    if (!ctx) {
      return
    }

    ctx.strokeStyle = color || "#000";
    ctx.lineWidth = width;

    ctx.moveTo(-10,10)
    ctx.lineTo(10,-10)

    ctx.moveTo(-10+0,10+32)
    ctx.lineTo(10+32,-10+0)

    ctx.moveTo(-10+0,10+64)
    ctx.lineTo(10+64,-10+0)

    ctx.moveTo(-10+32,10+64)
    ctx.lineTo(10+64,-10+32)

    ctx.moveTo(-10+64,10+64)
    ctx.lineTo(10+64,-10+64)

    ctx.stroke()
  }  
  static async generateDots(ctx?: CanvasRenderingContext2D | null, color?:string, b?:any) {

  }    

  
  static async GetEveryMitPOI(icon:string, addImage: (url) => void) {
    var canvas = document.createElement('canvas') as HTMLCanvasElement;
    // var canvas = document.getElementById('canvas') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
  
    let PoiBox = "PoiBox";
    let block = new URL("/images/markersNewSec/PoiBox.svg", import.meta.url).href // Working while poiBox isn't
    // let block = require("../images/markersNewSec/cookie.svg")
    // block = block.default ?? block
    let svg = new URL("/images/markersHeyDay/"+icon+".svg", import.meta.url).href
    // svg = svg.default ?? svg
    var img = new Image();
    img.width = 40;
    img.height = 40;
    canvas.width = 80;
    canvas.height= 80; 

    var imgbg = new Image();
    imgbg.width = 80;
    imgbg.height = 80;
    
    let arrs = [
      new Promise<HTMLImageElement>((resolve) =>
      imgbg.addEventListener('load', e => {
        resolve(imgbg)
      })), 
      new Promise<HTMLImageElement>((resolve) =>
      img.addEventListener('load', e => {
        resolve(img)
      })),
    ]

    img.src = svg
    imgbg.src = block

    let imgs = await Promise.all(arrs)
    // ctx?.rect(0,0,canvas.width, canvas.height);
    // ctx!.fillStyle = "#ffffffa0" 
    // ctx?.fill()
    ctx!.drawImage(imgs[0], 0, 0, canvas.width, canvas.height); 
    imgs.forEach((a, idx) => {
      if (idx !== 0) {
        ctx!.drawImage(a, 13, 13, canvas.width-26, canvas.height-26);
      } else {
        ctx!.drawImage(imgs[0], 0, 0, canvas.width, canvas.height); 
      }
    })
    addImage(canvas.toDataURL("image/png"));
  }

  static async GetEveryNSPOI(icon:string, addImage: (url) => void) {
    var canvas = document.createElement('canvas') as HTMLCanvasElement;
    // var canvas = document.getElementById('canvas') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');
  
    let PoiBox = "PoiBox";
    let block = new URL("/images/markersNewSec/PoiBox.svg", import.meta.url).href // Working while poiBox isn't
    // let block = require("../images/markersNewSec/cookie.svg")
    // block = block.default ?? block
    let svg = new URL("/images/markersNewSec/"+icon+".svg", import.meta.url).href
    // svg = svg.default ?? svg
    var img = new Image();
    img.width = 40;
    img.height = 40;
    canvas.width = 80;
    canvas.height= 80; 

    var imgbg = new Image();
    imgbg.width = 80;
    imgbg.height = 80;
    
    let arrs = [
      new Promise<HTMLImageElement>((resolve) =>
      imgbg.addEventListener('load', e => {
        resolve(imgbg)
      })), 
      new Promise<HTMLImageElement>((resolve) =>
      img.addEventListener('load', e => {
        resolve(img)
      })),
    ]

    img.src = svg
    imgbg.src = block

    let imgs = await Promise.all(arrs)
    // ctx?.rect(0,0,canvas.width, canvas.height);
    // ctx!.fillStyle = "#ffffffa0" 
    // ctx?.fill()
    ctx!.drawImage(imgs[0], 0, 0, canvas.width, canvas.height); 
    imgs.forEach((a, idx) => {
      ctx!.drawImage(a, 0, 0, canvas.width, canvas.height);
    })
    addImage(canvas.toDataURL("image/png"));
  }
  
  static async GetEveryNSPin(color:string, addImage: (url) => void) {
    var canvas = document.createElement('canvas') as HTMLCanvasElement;
    // var canvas = document.getElementById('canvas') as HTMLCanvasElement;
    var ctx = canvas.getContext('2d');

    if (ctx === null) {
      return
    }

    let fillColor = color;
    let strokeColor = chroma.valid(fillColor) ? chroma(fillColor).darken(2).css() : "green"; // fallback

    canvas.width

    let iconSVG= (fillColor, strokeColor) => `<?xml version="1.0" encoding="UTF-8"?>
    <svg width="24" height="29"
       id="Lag_1"
       viewBox="0 0 23.2799 28.4501"
       xmlns="http://www.w3.org/2000/svg">
      <defs
         id="defs1">
        <style
           id="style1">.cls-1{fill:${fillColor};}</style>
      </defs>
      <path
         class="cls-1"
         d="M 11.6399,0 C 5.21,0 0,5.21 0,11.6401 c 0,9.05 11.6399,16.81 11.6399,16.81 0,0 11.64,-7.76 11.64,-16.81 C 23.2799,5.21 18.0699,0 11.6399,0 Z m -0.06,15.9101 c -2.30998,0 -4.18,-1.87 -4.18,-4.18 0,-2.31005 1.87002,-4.1801 4.18,-4.1801 2.31,0 4.18,1.87005 4.18,4.1801 0,2.31 -1.87,4.18 -4.18,4.18 z"
         id="path1" />
    </svg>
    `;

    var svg = new Blob([iconSVG(fillColor, strokeColor)], {
      type: "image/svg+xml;charset=utf-8"
    });
    
    var url = URL.createObjectURL(svg);

    var img = new Image();
    img.width = 24;
    img.height = 29;
    canvas.width = 64;
    canvas.height= 80; 
    
    img.addEventListener('load', e => {
      ctx!.drawImage(img, 0, 0, canvas.width, canvas.height);
      addImage(canvas.toDataURL("image/png"));
      URL.revokeObjectURL(url);
    });
    img.src = url
  }


  static coordinatesAreOutOfBounds(lat: number, lon:number):boolean {
    return !((lon >= GenerateGeom.coordinateBoundsLonLower && lon <= GenerateGeom.coordinateBoundsLonUpper) 
      && (lat >= GenerateGeom.coordinateBoundsLatLower && lat <= GenerateGeom.coordinateBoundsLatUpper));
  }

  // --------------------------------------------------------
  static  constructPopupLabelsAndButtons(

    index: number,
    lat: number,
    lng: number,
    labelButtonList: LabelButton[],
    getLabelHtml?: (index: number) => string,
    geometry?: any): HTMLElement {

    let popopContents = getLabelHtml && getLabelHtml(index);

    let popupLabels = document.createElement('div');
    popupLabels.innerHTML = popopContents || "undefined";
    let popupContainer = document.createElement('div');

    if (labelButtonList.some(button => Boolean(button.function))) {
      let menu:any = document.createElement('div');
      menu.classList.add("mit-popup-label-menu");

      labelButtonList.forEach(labelButton => {
        if (!(labelButton.iconText || labelButton.imageSrc)) {
          Logger.logWarning("GenerateGeom.ts", "constructPopupLabelsAndButtons", "Both iconText and imageSrc missing on button with title "+labelButton.title+"!");
          console.warn("Both iconText and imageSrc missing on button with title "+labelButton.title+"!");
          return;
        }

        if (labelButton.function) {
          let buttonToAdd: any = document.createElement('div');
          buttonToAdd.classList.add("btn");
          buttonToAdd.classList.add("btn-default");
          buttonToAdd.classList.add("btn-xs");

          let buttonImg: any;
          if (labelButton.imageSrc && (typeof labelButton.imageSrc === "string") && labelButton.imageSrc.includes("glyphicon")) { // If source is glyphicon
            buttonImg = document.createElement('div');
            buttonImg.classList.add("mit-popup-label-glyph");
            buttonImg.classList.add("glyphicon");
            buttonImg.classList.add(labelButton.imageSrc);
            buttonImg.setAttribute('data-toggle', "tooltip");
          } else if (labelButton.imageSrc) {
            buttonImg = document.createElement('img');
            buttonImg.setAttribute('src', labelButton.imageSrc);
          } else if (labelButton.iconText) {
            buttonImg = document.createElement('div');
            buttonImg.classList.add("mit-popup-button");
            buttonImg.innerText = labelButton.iconText;
          }
          buttonImg && buttonImg.setAttribute('title', labelButton.title);

          if (geometry !== undefined) {
            buttonImg.data = JSON.stringify(geometry);
            buttonToAdd.data = JSON.stringify(geometry);

            buttonToAdd.addEventListener('click', (ev:any) => {

              let geo = JSON.parse(ev.target.data);
              labelButton.function && labelButton.function(geo);
              // close all popups
              // ToDo:
            //   map.closePopup();
            }); 
          } else {
            buttonImg.data = JSON.stringify({ lat: lat, lng: lng });
            buttonToAdd.data = JSON.stringify({ lat: lat, lng: lng });

            buttonToAdd.addEventListener('click', (ev: any) => {
              
              let d = JSON.parse(ev.target.data);
              let latlng = new MitLatLng(d.lat, d.lng);
              labelButton.function && labelButton.function(latlng);
              // close all popups
            //   map.closePopup();
            });
          }

          buttonToAdd.appendChild(buttonImg);
          menu.appendChild(buttonToAdd);
        }
      });

      popupContainer.appendChild(menu);
    }

    popupContainer.appendChild(popupLabels);

    return popupContainer;
  }

  
  // static  constructPopupLabelsAndButtonsReact(
  //   index: number,
  //   lat: number,
  //   lng: number,
  //   labelButtonList: LabelButton[],
  //   getLabelHtml?: (index: number) => string,
  //   geometry?: any): string {
      
  //   let result="";
  //   result = renderToString(ButtonPopUpContents({index, lat, lng, labelButtonList, getLabelHtml, geometry}));
  //   return result;
  // }

  static markerMouseEnterFunction(e: any, map: M.Map) {
    map.getCanvas().style.cursor = 'pointer';
  }



  static markerMouseLeaveFunction(e: any, map: M.Map) {
    map.getCanvas().style.cursor = '';
  }

  static setupEventListeners(map: M.Map, layerName: string, listener:(e:any)=> void) {
    // store anonomous function such that we can unset it later.
    let key = 'click' + layerName;
    GenerateGeom.listenerCache[key] = listener;
    map.on('click', layerName, listener);
    map.on('mouseenter', layerName, (e) => { this.markerMouseEnterFunction(e, map); });
    // Change it back to a pointer when it leaves.
    map.on('mouseleave', layerName, (e) => { this.markerMouseLeaveFunction(e, map); });
  }

  static removeEventListeners(map: M.Map, layerName: string) {
    // find listener from cache
    let key = 'click' + layerName;
    let listener = GenerateGeom.listenerCache[key];
    key && delete GenerateGeom.listenerCache[key];
    listener && map.off('click', layerName, listener);
    map.off('mouseenter', layerName, (e) => { this.markerMouseEnterFunction(e, map); });
    // Change it back to a pointer when it leaves.
    map.off('mouseleave', layerName, (e) => { this.markerMouseLeaveFunction(e, map); });
  }

  static setupEventListenersForFeatureLayer(map: M.Map, layerName: string, listener:(e:any)=> void) {
    // store anonomous function such that we can unset it later.
    let key = 'click' + layerName;
    GenerateGeom.listenerCache[key] = listener;
    map.on('click', layerName, listener);
  }

  static removeEventListenersForFeatureLayer(map: M.Map, layerName: string) {
    // find listener from cache
    let key = 'click' + layerName;
    let listener = GenerateGeom.listenerCache[key];
    key && delete GenerateGeom.listenerCache[key];
    listener && map.off('click', layerName, listener);
  }

  static constructPopupLabelsAndButtonsGeoJSON(
    geometry:any,
    getLabelHtml?:(index:number)=>string,
    callbackOnTravelTimeButton?:(latlng:MitLatLng)=>void,
    callbackOnSpatialSelectionButton?:(latlng:MitLatLng)=>void
    ):HTMLElement {
    
    let popopContents = getLabelHtml && getLabelHtml(0);

    let popupLabels = document.createElement('div');
    popupLabels.innerHTML = popopContents || "undefined";
    let popupContainer = document.createElement('div');

    if (callbackOnTravelTimeButton || callbackOnSpatialSelectionButton) {
      let menu:any = document.createElement('div');
      menu.classList.add("mit-popup-label-menu");
      // if (callbackOnTravelTimeButton) {
      //   let button:any = document.createElement('div');
      //   button.classList.add("btn");
      //   button.classList.add("btn-default");
      //   button.classList.add("btn-xs");
      //   button.setAttribute('data-toggle',"tooltip"); 
      //   button.setAttribute('title',"Vis rejsetid"); 
      //   let img:any = document.createElement('img');
      //   let car = require("/images/car_3.svg");
      //   img.setAttribute('src',car);
      //   // img.data = JSON.stringify({lat:lat,lng:lng});
      //   // button.data = JSON.stringify({lat:lat,lng:lng});
      //   button.addEventListener('click', (ev:any) => {
      //     let d = JSON.parse(ev.target.data);
      //     let latlng = MitLatLng(d.lat, d.lng);
      //     callbackOnTravelTimeButton && callbackOnTravelTimeButton(latlng);
      //     // close all popups
      //     map.closePopup();
      //   });
      //   button.appendChild(img);
      //   menu.appendChild(button); 
      // }

      if (callbackOnSpatialSelectionButton) {
        let button2:any = document.createElement('div');
        button2.classList.add("btn");
        button2.classList.add("btn-default");
        button2.classList.add("btn-xs");
        let glyph:any = document.createElement('div');
        glyph.classList.add("mit-popup-label-glyph");
        glyph.classList.add("glyphicon");
        glyph.classList.add("glyphicon-filter");
        glyph.setAttribute('data-toggle',"tooltip"); 
        // todo: Localization
        glyph.setAttribute('title',"Udvælg punkter i området"); 
        glyph.data = JSON.stringify(geometry);
        button2.data = JSON.stringify(geometry);
        button2.addEventListener('click', (ev:any) => {
          let geo = JSON.parse(ev.target.data);
          callbackOnSpatialSelectionButton && callbackOnSpatialSelectionButton(geo);
          // close all popups
         //  map.closePopup();
        });
        button2.appendChild(glyph);
        menu.appendChild(button2);        
      }
      popupContainer.appendChild(menu);
    }

    popupContainer.appendChild(popupLabels);
    return popupContainer;
  }

  //------------------------------------------------

  static showLayerMarkerSimple(
    layerInfo:LayerInfo,
   layerUniqueName:string,
    map:MitMap, 
    projectionType:ProjectionType, 
    coord1:any[], 
    coord2:any[],
    marker:any,
    useCustomIcon:boolean,
    labelButtonList: LabelButton[],
    customIconUrl?:string[],
    getLabelHtml?:(index:number)=>any,
    useClustering?:boolean):MitLayerHandle {

    console.assert(coord1 && coord1.length, "showLayerMarkerByValue, Unexpected empty coord1");
    console.assert(coord2 && coord2.length, "showLayerMarkerByValue, Unexpected empty coord2");
    let pointLayer;
    let result = getMitLayerHandle();

    // paint the items
    let featuresArray: any[] = []; 
    let iconLoadList: { path: string, iconKey: string }[] = [];


    // paint the items
    coord1.forEach((c,j) => {
      let coords;
      if (projectionType === ProjectionType.UTM32) {
        coords = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j], coord2[j]]);
      } else {
        coords = [coord1[j], coord2[j]];
      }

      // Validate coords
      let lat = coords[0];
      let lon = coords[1];
      if (GenerateGeomUtils.coordinatesAreOutOfBounds(lat, lon)) {
        //        Logger.logError("GenerateGeomMapbox", "showLayerMarkerSimple", "Coordinates out of bounds "+JSON.stringify(coords));
      } else {
        let fta = {
          type: "Feature",
          properties: {
            "labelHTML": "", 
            "mitMetaData": GenerateGeomUtils.encodePointMetaData(j, lat, lon, getLabelHtml && getLabelHtml(j) || "no labels", labelButtonList)
          },
          geometry: {
            "type": "Point",
            "coordinates": [lon, lat]
          }
        };
        if (useCustomIcon) {
          let url=customIconUrl && customIconUrl[j];
          if (url) {
            iconLoadList.push(
              {
                path: url,
                iconKey: url
              }
            );
            (fta.properties as any) = {...fta.properties, "iconUrl":url};
          }
        }
        featuresArray.push(fta);
      }
    });

    let sourceId = GenerateGeom.constructSourceId(layerUniqueName);
    let layerId = GenerateGeom.constructLayerId(layerUniqueName,"symbol-unclustered-point");
    let layerId2 = GenerateGeom.constructLayerId(layerUniqueName,"circle-marker-cluster");
    let layerId3 = GenerateGeom.constructLayerId(layerUniqueName,"circle-cluster-count");

    
    // ToDo: virker ikke helt endnu
    // if (marker.svg) {
    //   let img = new Image(20,20)
    //   img.onload = ()=>{
    // .addImage("mit-pin-"+marker.name, img, function done() {
    //             // Force reload of the layer such that the newly loaded icons will be displayed correctly.
    //         .setLayoutProperty(layerId, 'visibility', 'none');
    //         .setLayoutProperty(layerId, 'visibility', 'visible');
    //   });
    // }
    //   img.src = marker.svg;
    // }

    result.addSource(sourceId, MaplibreUtils.makeSourceSpec(featuresArray, Boolean(useClustering)));
    // Cluster
    if (useClustering) {
      result.addLayer(MaplibreUtils.makeLayerClusterMarkers(layerId2, sourceId));
      result.addLayer(MaplibreUtils.makeLayerClusterCount(layerId3, sourceId));
    }

    result.addLayer(MaplibreUtils.makeLayerSpecSimple(layerId, sourceId, layerInfo.styling, marker.name || 'blue', Boolean(useCustomIcon)));
    result.addLayerConsumingEvents(layerId);

    if (useCustomIcon) {
      GenerateGeom.loadAllImages(map, iconLoadList, () => {
        if (layerInfo.visible) {
          setTimeout(() => {
          map.setLayoutProperty(result,{'visibility': 'none'})
          map.setLayoutProperty(result,{'visibility': 'visible'})
          },200)
        }
      });
    }

    return (result as MitLayerHandle);
  }

  static constructSourceId(layerUniqueName:string, type?: "pie" | "grid" | "geoJson") {
   if (type) {
     return "layer-source-"+type+"-"+layerUniqueName; 
   } 
   return "layer-source-"+layerUniqueName;
 }
 static constructLayerId(layerUniqueName:string, type:AllowedMapLayerType) {
   return "layer-id-"+type+"-"+layerUniqueName;
 }


 static  showLayerMarkerByValue(
     layerUniqueName:string,
                map:MitMap, 
                projectionType:ProjectionType, 
                coord1:any[], 
                coord2:any[], 
                defaultColor:string,
                sizeByValue: boolean, 
                colorByValue: boolean,
                heightByValue: boolean,
                styling: LayerInfoStyling,
                labelButtonList: LabelButton[],
                colors?:ColorRange,
                sizes?:SizeRange, 
                value?:any[], 
                valueSize?:any[],
                valueHeight?:any[],
                divisions?:DataDivisionList,
                divisionsSize?:DataDivisionList,
                getLabelHtml?:(index:number)=>any,
                useClustering?:boolean,
                clusteringType?:ClusteringType
                ):MitLayerHandle {
  // ToDo: Support PicCharts again

  
    console.assert(coord1 && coord1.length, "showLayerMarkerByValue, Unexpected empty coord1");
    console.assert(coord2 && coord2.length, "showLayerMarkerByValue, Unexpected empty coord2");
    console.assert(!colorByValue || (value && value.length && colors && divisions), "showLayerMarkerByValue, Unexpected empty value");
    console.assert(!sizeByValue || (valueSize && valueSize.length && sizes && divisionsSize), "showLayerMarkerByValue, Unexpected empty value");

    const piePropertyPrefix="itemCount_";
    const pieColorPrefix="itemColor_";
    const pieLabelPrefix="itemLabel_";
    const piePropertyOther="Other";

    let featuresArray: {}[] = [];

    let heightNormalizer = valueHeight && new HeightNormalizer(valueHeight);

    for (let j = 0; j < coord1.length; j++) {
      // default color and radius
      let color = defaultColor;
      let radius = SettingsManager.getSystemSetting("defaultMarkerSize", 50);
      let hideMarker = false;
      let colorDivisionId: number = -1; // default, means 'Other'

      // Colour code icons based on value
      if (colorByValue && colors && divisions && value && value[j] !== undefined) {
        let val = value[j];
        if (divisions.type === DataDivisionType.Discrete) {
          let x = GenerateGeomUtils.getColorByValueDiscrete(colors, ""+val, divisions, styling.outsideColorRange);
          color = x.color;
          hideMarker = x.hideMarker;
          colorDivisionId = x.divisionId;
        } else {
          let numValue: number;
          let t = typeof (val);
          if (typeof (val) === "string") {
            numValue = Number.parseFloat(val);
          } else {
            numValue = val;
          }
          let x = GenerateGeomUtils.getColorByValueNummeric(colors, val, divisions, styling.outsideColorRange);
          color = x.color;
          hideMarker = x.hideMarker;
          colorDivisionId = x.divisionId;
        }
      }

      let divIdx;
      // Map value to division to determine radius
      if (sizeByValue && sizes && divisionsSize && valueSize && valueSize[j]) {
        let valSize = valueSize[j];
        divIdx = DivisionCalculator.getDivisionId(divisionsSize, valueSize[j]);
        console.assert(divIdx >= 0, "Divisions not found for value: " + valSize);
        radius = sizes.sizes[divIdx];
      }

      let coords;
      if (projectionType === ProjectionType.UTM32) {
        coords = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j], coord2[j]]);
      } else {
        coords = [coord1[j], coord2[j]];
      }

      function formatLabelFromDivisionInformation(div:DataDivision):string {
        let result="";
        if (div) {
          if (div.label) {
            result = div.label;
          } else {
            if(div.value) {
              // Discrete values
              result = div.value;
          } else {
             let op,text;
              // Numeric Values
              if (div.from === null) {
                  op = "<=";
                  text = div.to;
              } else {
                  if (div.to === null) {
                      op = ">";
                      text = div.from;
                  } else {
                      op = "<=";
                      text = div.to;
                  }
              }
            result = op+" "+text;
          }
          }
        }
        return result;
      }

      // Validate coords
      let lat = coords[0];
      let lon = coords[1];
      if (!hideMarker && !GenerateGeomUtils.coordinatesAreOutOfBounds(lat, lon)) {
        // convert points to circles
        function convertToCircle(g:any, radiusKm:number) {
          let circleJSON = circle(turf.point([g.coordinates[0], g.coordinates[1]]), radiusKm , {});
          return circleJSON.geometry;
        }

        let geometry =  {
          "type": "Point",
          "coordinates": [lon, lat]
        };
        if (heightByValue) {
          // ToDo: There should be 3 or more layers with different radius, to be used at different zoom levels
          geometry = convertToCircle(geometry, 0.050 /* km */);
        }
        let popupContainer = GenerateGeom.constructPopupLabelsAndButtons(j, lat, lon, labelButtonList, getLabelHtml);
        let ht = popupContainer.innerHTML;
        featuresArray.push({
          type: "Feature",
          properties: {
            labelHTML: "", // getLabelHtml && getLabelHtml(j) || "", 
            mitMetaData: GenerateGeomUtils.encodePointMetaData(j, lat, lon, getLabelHtml?.(j), labelButtonList),
            divIdx: divIdx,
            "itemcolor": color,
            hideMarker: hideMarker,
            radius: radius
          },
          geometry: {
            "type": "Point",
            "coordinates": [lon, lat]
          }
        });
      }
    }

    let result = getMitLayerHandle();
    let sourceId = GenerateGeom.constructSourceId(layerUniqueName);
    let layerId = GenerateGeom.constructLayerId(layerUniqueName,"symbol-unclustered-point");
    let layerId2 = GenerateGeom.constructLayerId(layerUniqueName,"circle-marker-cluster");
    let layerId3 = GenerateGeom.constructLayerId(layerUniqueName,"circle-cluster-count");    

    // Clustering
    if (useClustering) {
      if (clusteringType === ClusteringType.Piechart && divisions) {
        // change source id such that we can find all 'pie' sources later.
        sourceId = GenerateGeom.constructSourceId(layerUniqueName,"pie");
        // create a list [0,1,2,3,...(maxidx of division),"Other"]
        let piePropertyPrefixList = Array.from({ length: divisions.list.length }, (value, index) => ""+index);
        piePropertyPrefixList.push(piePropertyOther);
        result.addSource(sourceId, MaplibreUtils.makeSourceSpecPieChart(featuresArray, piePropertyPrefix, pieColorPrefix, pieLabelPrefix, piePropertyPrefixList));
        // Note. A Pie layer is not created. Pie markers are added automatically when source is updated. In MapFacadeMapLibre.ts
      } else {
        result.addSource(sourceId, MaplibreUtils.makeSourceSpec(featuresArray, true));
        result.addLayer(MaplibreUtils.makeLayerClusterMarkers(layerId2, sourceId));
        result.addLayer(MaplibreUtils.makeLayerClusterCount(layerId3, sourceId));
      }
    } else {
      result.addSource(sourceId, MaplibreUtils.makeSourceSpec(featuresArray, false));
    }
    result.addLayer(MaplibreUtils.makeLayerSpecStyled(layerId, sourceId, heightByValue));
    result.addLayerConsumingEvents(layerId);
    return (result as MitLayerHandle);
  }
    


  static showLayerArea(
        layerUniqueName:string,
        v2:GeoJsonObject, 
        map:MitMap, 
        admGeo:any[], 
        value:any[], 
        title:string, 
        colors:ColorRange, 
        divisions:DataDivisionList, 
        hideNoDataAreas:boolean, 
        styling:LayerInfoStyling, 
        findKeyIndexByFeature:(codes:any[], jsonFeature:any) => number,
        getLabelHtml?:(index:number, jsonFeature:any)=>string,
        callbackOnSpatialSelection?:(metaData:{}[])=>any
        ):MitLayerHandle {

        console.assert(admGeo && admGeo.length, "showLayerArea, Unexpected empty admGeo");

        let result = getMitLayerHandle();
        let defaultColor = styling.areaFillColor || SettingsManager.getSystemSetting("areaMapDefaultFillColor", "#FC4E2A");
        let areasWithNoDataColor = styling.areaNoData?.color || SettingsManager.getSystemSetting("areaMapColorForAreasWithNoData",'black');
        let borderColor = styling.areaBorderColor || SettingsManager.getSystemSetting("areaMapColorForBorders", 'white');
    
        // Process geojson
        let v3: any = v2;
        let features = v3.features as any[];
        let newFeatures: any[] = features.map((feature, idx) => {
          let index = findKeyIndexByFeature(admGeo, feature);
          if (index >= 0) {
            let val = value && value[index];
            let color = defaultColor;
            let hideMarker;
            let label: string = (getLabelHtml && getLabelHtml(index, feature)) || "no label";
            let geometry = feature && feature.geometry;
            let popupContainer = GenerateGeom.constructPopupLabelsAndButtonsGeoJSON(
              geometry,
              (x: any) => label,
              undefined,
              undefined
            );
            let ht = popupContainer.innerHTML;
            if (divisions && value && val !== undefined) {
              if (divisions.type === DataDivisionType.Discrete) {
                let x = GenerateGeomUtils.getColorByValueDiscrete(colors, ""+val, divisions, styling.outsideColorRange);
                color = x.color;
                hideMarker = x.hideMarker;
              } else {
                let x = GenerateGeomUtils.getColorByValueNummeric(colors, val, divisions, styling.outsideColorRange);
                color = x.color;
                hideMarker = x.hideMarker;
              }
            }
            return {
              ...feature,
              id: idx,
              properties: {
                ...feature.properties,
                opacity: (hideNoDataAreas && (index < 0) || hideMarker) ? 0.0: 0.80,
                fillcolor: index >= 0 ? color : areasWithNoDataColor,
                labelHTML: ""
              }
            };
          } else {
            return {
              ...feature,
              id: idx,
              properties: {
                ...feature.properties,
                opacity: 0.50,
                fillcolor: areasWithNoDataColor,  
                labelHTML: "area not found"  // ToDo: better handling of areas not in data
              }
            };
          }
        });
        v3.features = newFeatures;
        let sourceId = GenerateGeom.constructSourceId(layerUniqueName);
        let layerId = GenerateGeom.constructLayerId(layerUniqueName,"fill");
        result.addSource(sourceId, {
          type: "geojson",
          generateId: false,
          data: v3 as any
        });
        result.addLayer({
          id: layerId,
          type: "fill",
          source: sourceId,
          layout: {
          },
          'paint': {
            'fill-color': ['get', 'fillcolor'],
            'fill-opacity': ['number',['get', 'opacity'],(styling.opacity ?? 1)]
          }
        });
        result.addLayerConsumingEvents(layerId);
        return (result as MitLayerHandle);
      }

    static     showLayerGrid(
         layerUniqueName:string,
              map:MitMap, 
              projectionType:ProjectionType, 
              coord1:any[], 
              coord2:any[], 
              sizeM:any[],
              dataQuality:any[], 
              colors:ColorRange, 
              colorByValue:boolean,
              styling: LayerInfoStyling,
              labelButtonList: LabelButton[],
              value?:any[], 
              divisions?:DataDivisionList,
              getLabelHtml?:(index:number)=>string,
       ):MitLayerHandle
    {
      console.assert(coord1 && coord1.length, "showLayerGrid, Unexpected empty coord1");
      console.assert(coord2 && coord2.length, "showLayerGrid, Unexpected empty coord2");
  
      let result = getMitLayerHandle();
      let featuresArray: {}[] = [];
  
      for (let j = 0; j < coord1.length; j++) {
        // default color
        let color = styling.color || "blue";
        let hideMarker = false;
  
        // check data quality;
        let quality = dataQuality[j] || RowDataQuality.Good;
        hideMarker = quality === RowDataQuality.Error;
  
        // Colour code icons based on value
        if (!hideMarker && colorByValue && colors && divisions && value && (value![j] !== undefined)) {
          let val = value && value![j];
          if (divisions!.type === DataDivisionType.Discrete) {
            let x = GenerateGeomUtils.getColorByValueDiscrete(colors, ""+val, divisions, styling.outsideColorRange);
            color = x.color;
            hideMarker = x.hideMarker;
          } else {
            let x = GenerateGeomUtils.getColorByValueNummeric(colors, val, divisions, styling.outsideColorRange);
            color = x.color;
            hideMarker = x.hideMarker;
          }
        }
  
        if (!hideMarker) {
          let boxOptions = {
            fillColor: color,
            color: '#fff',
            weight: 1,
            opacity: 0.5,
            fillOpacity: 0.8
          };
  
          let sw:number[];
          let ne:number[];
          let nw:number[];
          let se:number[];
          let coords;
          if (projectionType === ProjectionType.UTM32) {
            let size = sizeM[j];
            coords = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j], coord2[j]]);
            sw = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j], coord2[j]]);
            ne = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j] + size, coord2[j] + size]);
            nw = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j] + size, coord2[j] ]);
            se = GenerateGeomUtils.convertFromUTMtoLatLng2([coord1[j], coord2[j] + size]);
          } else {
            throw Error("Only UTM32 is supported");
          }
  
          let lat = coords[0];
          let lon = coords[1];
  
          let latlongs = [sw.reverse(), nw.reverse(), ne.reverse(), se.reverse()];
          // Use polygon as grid is not parallel with screen coords.
          let popupContainer = GenerateGeom.constructPopupLabelsAndButtons(
            j, lat, lon, 
            labelButtonList,
            getLabelHtml
          );
          let ht = popupContainer.innerHTML;
          let feature = {
            type: "Feature",
            properties: {
              labelHTML: ht, // getLabelHtml && getLabelHtml(j) || "", 
              mitMetaData: GenerateGeomUtils.encodePointMetaData(j, lat, lon, labelButtonList),
              "itemcolor": color,
              hideMarker: hideMarker,
              sizeM: sizeM[j],
            },
            "geometry": {
              "type": "Polygon",
              "coordinates": [
                latlongs
              ]
            },
          };
  
          featuresArray.push(feature);
        }
      }
      
      let sourceId = GenerateGeom.constructSourceId(layerUniqueName,"grid");
      let layerId = GenerateGeom.constructLayerId(layerUniqueName,"fill-grid");
      result.addSource(sourceId, MaplibreUtils.makeSourceSpec(featuresArray, false));
      result.addLayer(MaplibreUtils.makeLayerCells(layerId, sourceId));
      result.addLayerConsumingEvents(layerId);
  
      return (result as MitLayerHandle);
  }

  static showLayerGeoJSON(
         layerUniqueName:string,
         v2:GeoJsonObject, 
         map:MitMap, 
         markerStyle:any, 
         featureStyle:LayerInfoStyling, 
         crs?:number, 
         getLabelHtml?:(jsonFeature:any)=>string, 
         callbackOnSpatialSelection?:(metaData:{}[])=>any) : MitLayerHandle
      {
         console.assert(v2, "showLayerGeoJSON, Unexpected empty Geo");
         let result = getMitLayerHandle();
         let v3: any = v2;
     
         function recursivelyConvertCoords(item:any, converter:(easting:number, northing:number) => number[]):any {
           // Start case. Not inside the 'coordinates' field, yet.
           return _recursivelyConvertCoords(item, false, converter);
         }
     
         function _recursivelyConvertCoords(item:any, isInCoordinatesField:boolean, converter:(easting:number, northing:number) => number[]):any {
     
           if (item instanceof Array) {
             if (isInCoordinatesField && (item.length === 2) && !(item[0] instanceof Array)) {
               // Found coordinate Pair inside the 'coordinates' field for conversion
               return converter(item[0], item[1]);
             } else {
               return item.map((i, idx) => { return _recursivelyConvertCoords(i, isInCoordinatesField, converter); });
             }
           } else {
             if (item instanceof Object) {
               let res = {};
               Object.keys(item).forEach((key) => {
                 res[key] = _recursivelyConvertCoords(item[key], key === "coordinates", converter);
               });
               return res;
             } else {
               return item;
             }
           }
     
         }
     
         if (v3.crs && (JSON.stringify(v3.crs).includes("25832"))) {
           // this is UTM32. Need to convert all coordinates to LngLat.
           let newFeatures = recursivelyConvertCoords(
             v3.features, 
             (northing, easting) => {
               // do coordinate conversion - reverse coordinates for Mapbox
               return GenerateGeomUtils.convertFromUTMtoLatLng2([northing, easting]).reverse();
             }
           );
           v3.features = newFeatures;
           v3.crs = 4326 
         }
        //  const v4 = v3.features.map((a) => {
        //   if (getLabelHtml) {
        //     a.properties.labelHtml = getLabelHtml(a);
        //   }
        //   return a
        //  })

         let sourceId = GenerateGeom.constructSourceId(layerUniqueName,"geoJson");
         let layerId = GenerateGeom.constructLayerId(layerUniqueName,"fill");
         let layerId2 = GenerateGeom.constructLayerId(layerUniqueName,"line");
         let layerId3 = GenerateGeom.constructLayerId(layerUniqueName,"symbol");

         const mul = featureStyle.lineDashMultitude ?? 5
         const rat = featureStyle.lineDashRatio ?? 1
         const dashArray = rat === 1 ? {} : {'line-dasharray' : [rat*mul, (1-rat)*mul]}
     
         result.addSource(sourceId, {
           type: "geojson",
           data: v3 as any,
           generateId: false,
         });
         result.addLayer({
           id: layerId,
           type: "fill",
           source: sourceId,
           layout: {
           },
           'paint': {
             'fill-color': ['to-color', ['get','fillcolor'], featureStyle.areaFillColor || featureStyle.color] ,
             'fill-opacity': ['number', ['get', 'fillopacity'], ['get', 'opacity'] ,(featureStyle.areaFillOpacity ?? 1) * (featureStyle.opacity ?? 1)],
           },
           "filter": ["==", "$type", "Polygon"],
         });
         result.addLayer({
           id: layerId2,
           type: "line",
           source: sourceId,
           layout: {
           },
           'paint': {
             'line-color': ['to-color', ['get', 'linecolor'], featureStyle.lineColor ?? featureStyle.color ?? 1],
             'line-width': ['number', ['get', 'weight'], featureStyle.lineWidth ?? SettingsManager.getSystemSetting("geoJSONLineWeight", 4)],
             'line-opacity': ['number', ['get', 'lineopacity'], ['get', 'opacity'] ,(featureStyle.lineOpacity ?? 1)  * (featureStyle.opacity ?? 1)],
             ...dashArray
           },
           "filter":  ["!=", "$type", "Point"],
         });
         result.addLayer({
           id: layerId3,
           type: "symbol",
           source: sourceId,
           'paint': {
            "icon-opacity": ['number', ['get', 'opacity'], (featureStyle.opacity ?? 1)]
           },
           'layout': {
             "icon-image": SettingsManager.getSystemSetting("pinPrefix", "mit-pin")+"-"+featureStyle.color,
             "icon-size": 1,
             "icon-allow-overlap": true,
             "icon-anchor": "bottom",

           },
           "filter": ["==", "$type", "Point"],
         });
         result.addLayerConsumingEvents(layerId);
     
         return(result as MitLayerHandle);
      }

  // ----------------------------------------------------
  
    // todo: move call to GeoDataInterface class
 static poiList: string[] = []
 static async AsyncDisplayPOIforBounds(map:MitMap, poiLayer:MitLayerHandle, poiList:string[]) {
  if (poiList.length && poiList.length !== this.poiList.length) {
    this.poiList = poiList
    Logger.logInfo("GenerateGeom", "Find Pois", "Types: " + poiList.join(", "));
  }
  
  if (!poiList || poiList.length < 1) {
    poiLayer && poiLayer.clearLayers(map);
    return(poiLayer);
  }

  let currentZoom = map.getZoom();
  let bounds: M.LngLatBounds = map.getBounds();
  let serviceUrl = SettingsManager.getSystemSetting("poiServiceURL", "https://viamap.net/getpoi/");
  let bbox = Utils.formatString("{lat},{lng}%20{lat2},{lng2}", { lat: bounds.getSouth(), lng: bounds.getWest(), lat2: bounds.getNorth(), lng2: bounds.getEast() });

  let featuresArray: any[] = []; 


  // Convert POIList to comma separated string
  let poitypes: string = ""; // Example "metro,train,strain,hospital,doctor,school,supermarket";
  poiList.forEach((poiType: string, idx: number) => {
    if (((POIS[poiType].maxZoom as number) ?? SettingsManager.getSystemSetting("MaxZoomPoi", 12, true)) > map.getZoom()) {
      return
    }
    if (poitypes !== "") {
      poitypes += ",";
    }
    poitypes += poiType;
  }
  );
  
  let token = SettingsManager.getSystemSetting("viamapAPIToken");
  let query = serviceUrl + "?bbox=" + bbox + "&poitypes=" + poitypes + "&token="+ token;
  try {
    let data: { [poitype: string]: any } = {};
    if (poitypes == "") {
      for (const poitype of poiList) {
        data[poitype] = {numfound: 0, POIs:[]}
      }
    } else if (SettingsManager.getSystemSetting("useMapitPOIServer", false)) {
      // Call Mapit POI service
      data = await AWSAPIGatewayInterface.getPois(bbox, poitypes);
    } else {
      const response = await fetch(query);
      data = await response.json();
    }

    // Process response
    Object.keys(data).forEach((key) => {
      let val = data[key];
      if (val.status && val.status === "none found") {
        // Nothing to display
      } else {
        let icon = SettingsManager.getSystemSetting("poiPinPrefix","")+ POIS[key].icon;
        let label = POIS[key].name;
        if (val && val.POIs && Array.isArray(val.POIs)) {
            val.POIs.forEach((poi:any) => {
              featuresArray.push({
                type: "Feature",
                id: featuresArray.length,
                properties: {
                  icon: icon,
                  label: label,
                  poiName: poi.name,
                  key: key
                },
                geometry: {
                  "type": "Point",
                  "coordinates": [poi.poilatlng[1], poi.poilatlng[0]]
                }
              }
              );
            });
        } 
      }
    });

    if (poiLayer.hasLayers()) {
      map.setData(poiLayer,MaplibreUtils.makeSourceSpec(featuresArray, false).data)
      // poiLayer.getSource().setData();
    } else {
      
      let sourceId = "poi-source-" + "1";
      let layerId = "poi-unclustered-icon";
      poiLayer.addSource(sourceId, MaplibreUtils.makeSourceSpec(featuresArray, false));
      poiLayer.addLayer(MaplibreUtils.makeLayerPoi(layerId, sourceId));
      poiLayer.addLayerConsumingEvents(layerId);
      map.addLayer(poiLayer)
    }
    return(poiLayer);
  } catch (err) {
    Logger.logError("GenerateGeom", "Get and show Poi", err);
    throw new Error("Got error Get and show Poi"+err);
  }
}

// -------------------------------------------------------

//  static _ZAP_createPOIMarker(
//      map:L.Map, 
//      latlng:number[], 
//      icon:string, 
//      label:string, // The display text for poi type. E.g. "Daginstitution"
//      name:string, // The name of the poi E.g. "Læge Per Petersen"
//      poitype:string):L.Layer {  // The type code for poi. E.g. 'metro' 

//    let ll = {lat:latlng[0], lng:latlng[1]};

//    //    let fRad = 500;
//    // let circle = L.circle(ll, fRad, {
//    //   opacity: 0.9 ,
//    //   weight: 1 ,
//    //   fillOpacity: 0.1
//    //   , color: '#7CFC00'
//    // }).addTo(map);

//    let iconUrl = require("/images/markers/"+poitype+".svg");

//    let myIcon = L.icon({
//      iconUrl: iconUrl,
//      iconSize: [32, 32],
//      iconAnchor: [16, 20],
//      popupAnchor: [0, -16]
//    });
//    let marker = L.marker(ll, {icon: myIcon});
//    marker.bindPopup("<div class='mit-poi-popup'>"+label+" "+name+"</div>");
//    marker.on('mouseover', function(e:any) {
//      var popup = e.target.getPopup();
//      popup.setLatLng(e.latlng).openOn(map);
//    });
//    marker.on('click', function(e:any) {
//      var popup = e.target.getPopup();
//      popup.setLatLng(e.latlng).openOn(map);
//    });
//    marker.on('mouseout', function(e:any) {
//      var popup = e.target.getPopup();
//      popup.closePopup();
//    });
//    return marker;
//  }

 // ----------------------------------------------------------------------

  // static zoomIntoView(map:L.Map, target:L.Popup) {
  //       // set the map's center & zoom so that it fits the geographic extent of the layer
  //       map && map.fitBounds(target.getContent().getBounds());
  // }

  static getColorByValueB(colors:ColorRange, value:number, divisions?:DataDivisionList, mapColorForOtherDataValue?:string):{color:string, hideMarker:boolean} {
   let color=SettingsManager.getSystemSetting("areaMapDefaultFillColor","#FC4E2A");
   let otherDataColor = mapColorForOtherDataValue || SettingsManager.getSystemSetting("mapColorForOtherDataValue",'cyan');

   let hideMarker = false;

   if (divisions && divisions.list.length > 0) {
       console.assert(divisions.list.length >= 1, "Too few devisions");
       let divIdx = DivisionCalculator.getDivisionId(divisions, value);
       if (divIdx === -1) {
         color= otherDataColor;
       } else {
         console.assert(divIdx >= 0, "Divisions not found for value: "+value);
         console.assert(divisions.list.length > divIdx, "Too few colors for division" );
         color= divisions.list[divIdx].color;
         hideMarker = Boolean(divisions.list[divIdx].hide);
       }
   }

   return {color, hideMarker};
 } 

 static getColorByValueDiscrete(colors:ColorRange, value:string, divisions?:DataDivisionList, mapColorForOtherDataValue?:string):{color:string, hideMarker:boolean} {
   let color=SettingsManager.getSystemSetting("areaMapDefaultFillColor","#FC4E2A");
   let otherDataColor = mapColorForOtherDataValue || SettingsManager.getSystemSetting("mapColorForOtherDataValue",'cyan');
   let hideMarker = false;

   if (divisions && divisions.list.length > 0) {
       console.assert(divisions.list.length >= 1, "Too few devisions");
       let divIdx = DivisionCalculator.getDivisionIdDiscrete(divisions, value);
       if (divIdx === -1) {
         color= otherDataColor;
       } else {
         console.assert(divisions.list.length > divIdx, "Too few colors for division" );
         color= divisions.list[divIdx].color;
         hideMarker = Boolean(divisions.list[divIdx].hide);
       }
   }

   return {color, hideMarker};
 } 

 static styleB( borderColor:string, color?:string, weight?:number, fillOpacity?:number) {

   return {
       fillColor: color || undefined,
       weight: weight ?? 2,
       opacity: 1,
       color: borderColor=="none" ? borderColor : chroma(borderColor).css(),
       dashArray: null,
       fillOpacity: fillOpacity ?? 1,
   };
 }

      static findIndexWithEqualityFunc(codes:any[], value:any, equalityFunc:(a:any,b:any)=>boolean):number {
        let result = -1; // if not found
        for(let i=0; i<codes.length; i++) {
          if (equalityFunc(value, codes[i])) {
            result = i;
            break;
          }
        }
        return result;
      }

      static convertFromUTMtoLatLng2(utmCoord:number[]):number[] {
        let cx = GenerateGeom.convertFromUTMtoLatLng({x:utmCoord[0], y:utmCoord[1]});
        return [cx.lat, cx.lng];
      }
 
      static convertFromUTMtoLatLng(utmCoord:{x:number, y:number}):{lat:number, lng:number} {
        let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees';
        let utm32 = '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs';
        let coords;
        coords = proj4(utm32, wgs84, [utmCoord.x, utmCoord.y]).reverse();

        return ({lat:coords[0], lng:coords[1]});
      }

      static convertFromLatLngToUTM(latlng:{lat:number, lng:number}):{x:number,y:number} {
        let wgs84 = '+proj=longlat +ellps=WGS84 +datum=WGS84 +units=degrees';
        let utm32 = '+proj=utm +zone=32 +ellps=GRS80 +units=m +no_defs';
        let coords = proj4(wgs84, utm32, [latlng.lng, latlng.lat]);

        return {x:coords[0], y:coords[1]};
      }

      // static encodePointMetaData(index:number, lat:number, lng:number):string {
      //   let md:PointMetaData = {index:index, lat:lat, lng:lng};
      //   return JSON.stringify(md);
      // }

      // static decodePointMetaData(json:string):PointMetaData {
      //   !json && Logger.logInfo("GenerateGeom","decodePointMetaData","Point metadata unexpectedly empty");
      //   return json && JSON.parse(json);
      // }

      // ------------------ SPATIAL SELECTION -------------------

      
      

      static spatialSelection(mapState:MapitState, center:MitLatLng, radiusMeters:number,inverse:boolean=false):SpatialReturnType {
        let test = (latLng:MitLatLng):boolean => {
             let distanceFromCenterPoint = latLng.distanceTo(center);
             let testResult = distanceFromCenterPoint * 1000 <= radiusMeters;
             return inverse ? !testResult : testResult;
        };
        return GenerateGeom._spatialSelection(mapState, test);
      }
    
      static spatialSelectionGeoJSON(mapState:MapitState, geometry:any, inverse:boolean=false):SpatialReturnType {
        let preparedGeometry = geometry.type == "Polygon" || geometry.type == "LineString" ? [geometry.coordinates] : geometry.coordinates
        const jsonLayer = turf.multiPolygon(preparedGeometry);
        let test = (latLng:MitLatLng):boolean => {
          let testResult = this.isMarkerInsidePolygon(latLng, jsonLayer as any);
          return inverse ? !testResult : testResult;
        };
        return this._spatialSelection(mapState, test);
      }

      static expandToFeatures(feature:any):any[] {
        if (feature.type == "Feature")
          return [feature]
        if ("features" in feature) {
          return feature.features
        }
        return []
      } 

      static polygonsInsidePolygons(mapitState:MapitState, geometry) {
        let layers = Object.values(mapitState.layers).filter((a) => a.layerVariability != LayerVariabilityType.FixedFeatureLayer)
        let res:{name: string, features: any[]}[] = []
        layers.forEach((a) => {
          let curRef = {name:a.datasetname, features: [] as any[]}
          let featureInLayer = this.expandToFeatures(a.geoJson) 
          featureInLayer.forEach((a) => {
            if (a.geometry.type == "MultiPolygon") {
              let multiCoords = [] as any[]
              a.geometry.coordinates.forEach((coords) => {
                let poly = turf.polygon(coords)
                if (turf.booleanContains(geometry, poly) || turf.booleanIntersects(geometry, poly)) {
                  multiCoords.push(coords)
                }
              })
              if (multiCoords.length) {
                a.geometry.coordinates = multiCoords;
                curRef.features.push(a)
              }
              return
            }
            if (turf.booleanContains(geometry, a) || turf.booleanIntersects(geometry, a)) {
              curRef.features.push(a)
            }
          })
          if (curRef.features.length > 0) {
            res.push(curRef)
          }
        });
        return res
      }

      static isPointLayerAndVisible(layer:LayerInfo):boolean {
        return (layer.type.includes("Point") && layer.visible) ?? false
      }

      static _spatialSelection(mapitState:MapitState, test:(latLng:MitLatLng) => boolean):SpatialReturnType {
        let spatialResult:SpatialReturnType = {}
        Object.keys(mapitState.layers).forEach((layerStateKey) => {
          let layer = mapitState.layers[layerStateKey];
          if (!this.isPointLayerAndVisible(layer)) {
            return
          }
          let featuresToInclude: number[] = []
          let features = layer.geoJson.features
          features.forEach((feature, idx) => {
            const [lng, lat, ..._] = (feature.geometry.coordinates as number[])
            if (test(new MitLatLng(lat,lng))) {
              featuresToInclude.push(idx)
            }
          })
          if (featuresToInclude.length > 0) {
            spatialResult[layerStateKey] = featuresToInclude
          }           
        })
        return spatialResult
      }

      static isMarkerInsidePolygon(latLng:MitLatLng, poly:any):boolean {
        const {lng:a,lat:b} = latLng
        return booleanPointInPolygon(turf.point([a,b]), poly as any)
    }

    static recursivelyFindMetaData(marker:any, test:(latLng: any) => boolean):PointMetaData[] {
      let result:PointMetaData[]=[];
      // Works for cluster markers in "leaflet.markercluster" package. They have optionally _markers (normal markers) and _childClusters (nested clusters)
      // 1) Markers at this level
      marker._markers && marker._markers.forEach((m:any) => {
        if (test(m.getLatLng())) {
          /* tslint:disable-next-line */
          let md = GenerateGeomUtils.decodePointMetaData(m["mitMetaData"]);
          if (md) {
            result.push(md);
          }
        }
      });
      // 2) Nested cluster markers
      marker._childClusters && marker._childClusters.forEach((m:any) => {
        let mdList = GenerateGeom.recursivelyFindMetaData(m, test);
        mdList && mdList.forEach((m2:PointMetaData) => {
          result.push(m2);
        });
      });
      return result;
    }

    static backgroundClickFunction(e: any, map: M.Map, options: FeatureLayerOptions) {
      // let coordinates = e && e.features && e.features[0].geometry.coordinates.slice();
      let coordinates = e && e.lngLat;
      let description = options.label; // e && e.features && e.features[0].properties && e.features[0].properties.labelHTML;
  
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }
  
      new M.Popup()
        .setLngLat(coordinates)
        .setHTML(description)
        .addTo(map);
    }

    // TODO: Remove
    static markerClickFunction(e: any, map: M.Map) {
      // let coordinates = e && e.features && e.features[0].geometry.coordinates.slice();
      let coordinates = e && e.lngLat;
      let description = e && e.features && e.features[0].properties && e.features[0].properties.labelHTML;
  
      // Ensure that if the map is zoomed out such that multiple
      // copies of the feature are visible, the popup appears
      // over the copy being pointed to.
      while (Math.abs(e.lngLat.lng - coordinates[0]) > 180) {
        coordinates[0] += e.lngLat.lng > coordinates[0] ? 360 : -360;
      }

      // Wonders: make content on Click ??
      let p = e && e.features && e.features[0].properties
      let md:PointMetaData = p && GenerateGeomUtils.decodePointMetaData(p["mitMetaData"]);
      let labelButtonList:LabelButton[] = md && md.labelButtonList || [];
      let labelPairs:any = md && md.labelPairs || {};
      let mapInfo: MapInfo = {
        popUpPictureLayout: PictureLayoutType.Center,
        popUpPictureSize: PictureSize.Medium,
        popUpLayout: PopupLayout.ShowAll
      }

      let funcLabel = (index:number) => {
        return ((MapitLayerFactory.funcLabelRaw(mapInfo, labelPairs)));
      };
      // dispatch(<NewSecPopup index={md.index} lat={coordinates.lat} lng={coordinates.lng} labelButtonList={labelButtonList} getLabelHtml={funcLabel} />)
    }
  }
