import { useEffect, useRef, useState } from "react"

export type IgnoreBlock = {start: number, end: number}

export function LegendEditorPlot(props: {canvasId: string, callBack?:(next: IgnoreBlock[]) => void}) {
  const imgRef = useRef<HTMLImageElement | null>(null)
  const [ignoreBlock, setIgnoreBlock] = useState<IgnoreBlock[]>([]) 

  useEffect(() => {
    let preCanvas = document.getElementById(props.canvasId) as HTMLCanvasElement
    if (imgRef.current) {
      imgRef.current.src = preCanvas?.toDataURL()
    }
  })

  function changeHandler(next:IgnoreBlock) {

    setIgnoreBlock((pre) => {
      const nMin = Math.min(next.start, next.end);
      const nMax = Math.max(next.start, next.end);
      const clicked = nMax - nMin < 3;
      let overlap = pre.filter((a) => nMin < a.start && nMax > a.end);
      let startInside = pre.filter((a) => a.end > next.start && next.start > a.start);
      let endInside = pre.filter((a) => a.end > next.end && next.end > a.start);

      const onlyEnd = startInside.length == 0 && endInside.map((a) => (next.end == nMax ? {start:next.start, end:a.end}:{start:a.start,end:next.start}))
      const toRemove = [...overlap, ...startInside, ...endInside]
      const toAdd = (clicked && []) || (onlyEnd) || [...startInside.map((a) => (next.start == nMax ? {start:next.start, end:a.end}:{start:a.start, end:next.start})) ,...endInside.map((a) => (next.end == nMax ? {start:next.end, end:a.end}:{start:a.start,end:next.end}))]
      props.callBack?.([...pre.filter((a) => !toRemove.includes(a)), ...(toAdd.length > 0 ? toAdd : [{start:nMin, end:nMax}])].toSorted((a,b) => a.start - b.start))
      return (
        [...pre.filter((a) => !toRemove.includes(a)), ...(toAdd.length > 0 ? toAdd : (clicked && []) || [ {start:nMin, end:nMax}])]
      )
    }
    );
  }

  return (
    <div className={"LegendEditorPlot"} >
      <div style={{position:"relative"}}>
        {ignoreBlock.map((a, idx) => {
            const min = (a.start < a.end) ? a.start : a.end;
            const max = (a.start > a.end) ? a.start : a.end;
            const height = max - min;
            return (
              <div key={idx} className="Removed" style={{top: min+"px", height:height+"px"}} ></div>
            )
          })
        }
        <img
        style={{cursor:"n-resize"}}
        onDragStart={(e) => e.preventDefault()}
        onPointerDown={(e) => {
          e.preventDefault()
          handleEditPlot(e, changeHandler)
        }}
        ref={imgRef} 
         />
      </div>
    </div>
  )
}

function handleEditPlot(e:React.PointerEvent<HTMLImageElement>, changeHandler: (a:IgnoreBlock) => void) {
  const rect = (e.target as HTMLElement).getBoundingClientRect();
  const start = Math.max(Math.min(e.clientY - rect.top, rect.height),0)

  const PointerUp = (e:PointerEvent) => {
    const end = Math.max(Math.min(e.clientY - rect.top, rect.height),0)
    changeHandler({start, end})
    document.removeEventListener("pointerup", PointerUp)
  }
  document.addEventListener("pointerup", PointerUp)

}