import { Fragment } from "react/jsx-runtime"
import { useWindowContext } from "./useWindowContext"
import { WindowInstances } from "./WindowInstances"

export function WindowDisplayer() {
   const {state} = useWindowContext()

   return (
      state.map((w) =>
         <Fragment key={w.uid || w.id}>   
         <WindowInstances window={w} />
         </Fragment>
      )
   )
}