import { Feature } from "src/states/ApplicationStateFeatures"
import { AppMessagesContext, Localization, Logger, 
  SessionContext, SettingsFixedPriorities, 
  SettingsManager, 
  PersistenceScope, 
  // PersistenceObjectType, 
  // ViamapPersistenceLayer, 
  actionSetErrorMessage, actionSetInfoMessage } from "@viamap/viamap2-common"
import { Fragment, useContext, useEffect, useState } from "react"
import { ApplicationStateContext } from "src/states/ApplicationState"
import { SETTINGS_PATH_SELECTOR_SEPARATOR, SettingsValues } from "@viamap/viamap2-common/dist/managers/SettingsManager"
import { AiOutlineCreditCard, AiOutlineGlobal, AiOutlineProject, AiOutlineQuestion, AiOutlineTeam, AiOutlineUser } from "react-icons/ai"
import { MdSettingsBackupRestore } from "react-icons/md"
import { FaMinusCircle, FaRegEdit } from "react-icons/fa"
import { ADModalBody, ADModalFooter, ADModalInfo, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal"
import { GlassButton } from "./GlassButtons"
import { GlassInfo } from "./MitGlassComponents"
import { GlassInputControl, GlassInputGroup, GlassTextInputTransformer } from "./GlassInput"

type SingleTranslation = {
  da: string,
  en: string
}

type MinMax = {
  min?: number,
  max?: number
}

export type GroupInfo = ({
  key: string,
} & SingleTranslation)[]

type SettingsType = 
  | {type: "String"}
  | {type: "Select", options: ({value: any} & SingleTranslation)[]}
  | {type: "Integer", sufix?: string} & MinMax
  | {type: "Float", precision?:number} & MinMax
  | {type: "Boolean", true:SingleTranslation, false:SingleTranslation }
  | {type: "TimeADay"}
  | {type: "Date"} & MinMax
  | {type: "DateTime"} & MinMax
  | {type: "Year"} & MinMax

export type SingleSetting = {
    access: Feature | Feature[],
    scope?: PersistenceScope,
    default?: boolean|string|number,
    key: string,
    group: string,
  } & SettingsType & SingleTranslation

export type keyValueObject = {[key:string]:any}

export interface SettingsManagerInterface {
  extractSettingsByPriority: (source:SettingsFixedPriorities, priority:SettingsFixedPriorities, includeDisabled:boolean) => keyValueObject
  clearByPriority: (source:SettingsFixedPriorities) => void
  addSettings: (settings:keyValueObject, {filename, priority}:{filename:string, priority:SettingsFixedPriorities}) => void
  getSystemSetting: (key:string, defaultValue:any, includeDisabled:boolean) => any
}

export interface SettingsManagerInterface {
  extractSettingsByPriority: (source:SettingsFixedPriorities, priority:SettingsFixedPriorities, includeDisabled:boolean) => keyValueObject
  clearByPriority: (source:SettingsFixedPriorities) => void
  addSettings: (settings:keyValueObject, {filename, priority}:{filename:string, priority:SettingsFixedPriorities}) => void
  getState: () => any
  getSystemSetting: (key:string, defaultValue:any, includeDisabled:boolean) => any
}

export function SettingsEditor(props: {
  showWindow:number,
  settingsManager: SettingsManagerInterface,
  scope: PersistenceScope,
  priority: SettingsFixedPriorities,   
  filteredSettingsGroup: GroupInfo[number][],
  allSettings: SingleSetting[],
  affectedMessageText: string,
  getSource: (key:string) => number
  callbackOnClose:() => any,
  callbackToResetCatchment:(a:any) => any
  callbackOnSaveChanges:(localSettings, sessionState, onClose, appMessageDispatch, source:SettingsFixedPriorities) => any
}) {
  const [settingValues, setSettingValue] = useState<{[key:string]:{value: any, disabled: boolean, reset:boolean}}>({})
  const {state: sessionState} = useContext(SessionContext);
  const {dispatch: appMessageDispach} = useContext(AppMessagesContext);

  useEffect(() => {
    if (props.showWindow) {
      let priority = props.priority;
      let settingValues = {...props.settingsManager.extractSettingsByPriority(priority,priority, true)};
      Object.keys(settingValues).forEach((key) => {
        settingValues[key] = {value: settingValues[key], disabled:(props.getSource(key) !== priority), reset:false}
      })
      setSettingValue((a) => ({...(settingValues)}))
    } else {
      setSettingValue({})
    }
  },[props.scope, props.showWindow])

  function savePersistentSettings() {
    onClickSaveCustomUserSettings(settingValues, sessionState, props.callbackOnClose, appMessageDispach, props.priority)
  }

  function setSetting(key, value) {
    setSettingValue((a) => ({...a, [key]:value}));
    let newSettings = {...settingValues, [key]:value};
    const source = props.priority;
    const settings = removeUndefined(newSettings)
    props.settingsManager.clearByPriority(source);
    props.settingsManager.addSettings(settings, {filename:"LiveSetting", priority: source});
    let settingValuesExtracted = {...props.settingsManager.extractSettingsByPriority(source,source, true)};
    Object.keys(settingValuesExtracted).forEach((key) => {
      settingValuesExtracted[key] = {value: settingValuesExtracted[key], disabled:(props.getSource(key) < source), reset:false}
    })
    setSettingValue((a) => ({...(settingValuesExtracted)}))
  }

  function closeWindow() {
    savePersistentSettings()
    props.callbackOnClose()
  }

  async function onClickSaveCustomUserSettings(localSettings, sessionState, onClose, appMessageDispatch, source:SettingsFixedPriorities): Promise<void> {
    let settings = removeUndefined(localSettings);
    return props.callbackOnSaveChanges(settings, sessionState, onClose, appMessageDispatch, source);
  }

  let filteredSettingsGroup = props.filteredSettingsGroup;

  return (
    <AdvancedDragModal
      variant={"NSWhite"}
      title={Localization.getText("Settings")}
      PosDefault={{left:"50%", top:"5%", width:"800px", transform:"translateX(-50%)"}}
      onClose={closeWindow}
    >
      <ADModalInfo>
        {props.affectedMessageText}
      </ADModalInfo>
      <ADModalBody >
        
      <GlassInputGroup
        autoFocus={0}
        onSubmit={savePersistentSettings}
        onEscape={closeWindow}
      >
      {(filteredSettingsGroup).map((a) => {
        return <Fragment key={a.key}><Group settingsManager={props.settingsManager} scope={props.scope} group={a} values={settingValues} allSettings={props.allSettings} getSource={props.getSource} onChange={(key, value) => setSetting(key,value)} /></Fragment>
      })}
      </GlassInputGroup>
      </ADModalBody>
    </AdvancedDragModal>
  )
}

function Group(props:{settingsManager:SettingsManagerInterface, scope: PersistenceScope, group:GroupInfo[number], values: keyValueObject, allSettings: SingleSetting[], getSource:(key:string) => number, onChange:(key,value) => any}) {
  const {hasAccessToFeature} = useContext(ApplicationStateContext)
  const availableSettings = props.allSettings.filter((ss) => {
    return ss.group == props.group.key && (typeof ss.access == "string" ? hasAccessToFeature(ss.access) : ss.access.every((a) => hasAccessToFeature))
  })
  if (availableSettings.length === 0) {
    return
  }
  return (
    <>
    <div className="GlassInputGroupTitle">{props.group[Localization.getLanguage()]}</div>
    {availableSettings.map((ss) => {
      return <Fragment key={ss.key}><SettingRow settingsManager={props.settingsManager} scope={props.scope} setting={ss} values={props.values} getSource={props.getSource} onChange={props.onChange} /></Fragment>
    })}
    </>
  )
}

function IconBySource(props:{value:SettingsFixedPriorities}) {
  let value = props.value
  switch (value) {
    case (0 as SettingsFixedPriorities) :
    case SettingsFixedPriorities.GlobalSettings:
    case SettingsFixedPriorities.EnvironmentSettings:
    case SettingsFixedPriorities.ProductVariantSettings:
      return <div className="SourceIcon" title={Localization.getText("Default")}><AiOutlineGlobal /></div>
    case SettingsFixedPriorities.LicenseSettings:
      return <div className="SourceIcon" title={Localization.getText("License")}><AiOutlineCreditCard /></div>
    case SettingsFixedPriorities.CustomerSettings:
      return <div className="SourceIcon" title={Localization.getText("Customer")}><AiOutlineTeam /></div>
    case SettingsFixedPriorities.UserSettings:
      return <div className="SourceIcon" title={Localization.getText("User")}><AiOutlineUser /></div>
    case 70 as SettingsFixedPriorities:
      return <div className="SourceIcon" title={Localization.getText("Project")}><AiOutlineProject /></div>
    default:
      return <div className="SourceIcon" title={Localization.getText("Unknown")}><AiOutlineQuestion /></div>
  }
}

function SettingRow(props:{settingsManager: SettingsManagerInterface, scope:PersistenceScope, setting:SingleSetting, values:keyValueObject, getSource:(key:string) => number, onChange:(key,value) => any}) {
  const value = props.values[props.setting.key]?.value ?? props.settingsManager.getSystemSetting(props.setting.key, props.setting.default, true)
  const wrongScope = (props.setting.scope !== undefined && props.scope !== props.setting.scope);
  const disabled = wrongScope || (props.values[props.setting.key]?.disabled ?? true)
  const lang: "da"|"en" = Localization.getLanguage() as "da" | "en";
  return <>
    <GlassTextInputTransformer label={props.setting[lang]}>
      <GlassInputControl>
      <IconBySource value={props.getSource(props.setting.key)} />
      {props.values[props.setting.key]?.reset ?
        <span className={"disabled"}>{Localization.getText("Reset to default")}</span>
      : <>
      <SettingsInput disabled={disabled} value={value} setting={props.setting} onChange={(value) => {props.onChange(props.setting.key, {value: stringToValue(props.setting,value), disabled: disabled, reset:false})}} />
      <span className={(disabled ? "disabled":"")}>{"sufix" in props.setting ? props.setting.sufix : ""}</span>
      </>}
      </GlassInputControl>
    <div className="Setting_Butto">
    {wrongScope ? <>
      <FaMinusCircle color="grey" title={Localization.getText("Editing setting is disabled")} />
    </> : disabled ? 
    <FaRegEdit title={Localization.getText("Edit")} color="grey" className="sm" onClick={() => {props.onChange(props.setting.key, {value: value, disabled: false, reset:false})} } /> 
    : 
    <MdSettingsBackupRestore onClick={() => {props.onChange(props.setting.key, {value: value, disabled: true, reset:true})}} color="grey" title={Localization.getText("Reset")} />}
    </div>
    </GlassTextInputTransformer>
  </>
}

function stringToValue(setting:SingleSetting, value) {
  if (value === undefined) {
    return undefined
  }
  switch (setting.type) {
    case "Boolean": return value === "true" ? true : false
    case "TimeADay": {
      let split = value.split(":")
      return parseInt(split[0]) * 3600 + parseInt(split[1]) * 60 
    }
    // case "DateTime": 
    case "Date": return parseInt(new Date(value).toISOString().split("T")[0].replaceAll("-",""))
    case "Year": //next
    case "Integer": {
      let valueInt = value !== "" ? parseInt(value) : (setting.min ?? 0);
      return Math.max(Math.min(setting.max ?? valueInt,valueInt),setting.min ?? valueInt)}
    case "Float": return parseFloat(value)
    default: return value?.toString() || value
  }
}

function valueToString(setting:SingleSetting, value) {
  if (value === undefined) {
    return ""
  }
  switch (setting.type) {
    case "TimeADay": return `${format2Digits(Math.floor(value / 3600))}:${format2Digits(Math.floor(value/60) % 60)}`
    case "Integer": return value === "" ? (setting.min ?? "0") : value;
    case "Date": {
      let copy = ((value?.toString() || "").length == 8 ? value.toString() : "00000000").split("");
      return `${copy.splice(0,4).join("")}-${copy.splice(0,2).join("")}-${copy.splice(0,2).join("")}`
    }
    case "Select": return value === "" ? "0" : value
    case "Float":
    case "Boolean":
    case "DateTime":
    case "Year":
    default: return value?.toString() || value
  }
}

function SettingsInput(props:{setting:SingleSetting, value:any, disabled?:boolean, onChange: (value) => any }) {
  const lang: "da"|"en" = Localization.getLanguage() as "da" | "en";

  const value = valueToString(props.setting, props.value)

  function onChange(ev) {
    props.onChange(ev.target.value)
  }

  let si = props.setting;
  switch (si.type) {
    case "String": return <input disabled={props.disabled} value={value} onChange={onChange}></input>
    case "Select": return <select disabled={props.disabled} value={value} onChange={onChange}>{si.options.map((a) => <option value={a.value} >{a[lang]}</option>)}</select>
    case "Integer": return <input disabled={props.disabled} value={value} onChange={onChange} type="number" max={si.max} min={si.min} />
    case "Float": return <input disabled={props.disabled} value={value} onChange={onChange} type="number" step={si.precision ?? 0.1} max={si.max} min={si.min} />
    case "Boolean": return <select disabled={props.disabled} value={value} onChange={onChange}><option value={"true"} >{si.true[lang]}</option><option value={"false"} >{si.false[lang]}</option></select>
    case "TimeADay": return <input disabled={props.disabled} value={value} onChange={onChange} type="time" />
    case "Date": return <input disabled={props.disabled} value={value} onChange={onChange} type="date" />
    case "DateTime": return <input disabled={props.disabled} value={value} onChange={onChange} type="datetime-local" />
    case "Year": return <input disabled={props.disabled} value={value} onChange={onChange} type="number" min={2000} max={2900} />
    default: return <>{props.value}</>
  }
  return <></>
}


function removeUndefined(node) {
  let newNode = node
  let keys = Object.keys(newNode).filter((a) => {
    // Remove undefined
    return newNode[a] != undefined
  }).filter((a) => {
    // Remove resetted
    return !(newNode[a].reset || false)
  }).filter((a) => {
    // Remove disabled
    return !(newNode[a].disabled || false)
  })
  let result = {}
  keys.forEach((a) => {
    result = {...result, [a]: newNode[a].value}
  }) 
  return result
}

function format2Digits(number:number) {
  return number.toLocaleString('dk-da', {minimumIntegerDigits:2})
}

