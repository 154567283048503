
import { useEffect, useRef, useState } from "react";
import {Cookies} from '@viamap/viamap2-common';
import { Localization } from "@viamap/viamap2-common";
import ReactDOM from "react-dom";
import { MitCheckBox, MitHeader } from "./ComponentUtils";
import { ADModalBody, ADModalFooter, ADModalInfo, AdvancedDragModal } from "src/componentsUtils/AdvancedDragModal";
import { GlassButton } from "./GlassButtons";
import { GlassCheckbox, GlassInfo } from "./MitGlassComponents";
import { ProtectedFeature } from "./ProtectedFeature";
import { Feature } from "src/states/ApplicationStateFeatures";

export type ConfirmationDialogEvent = {
  type: "auto" | "submit" | "cancel",
  checked: boolean,
};

export function ConfirmationDialog (
  props: {
    showWindow?:number,
    header?:string, 
    children?:JSX.Element | null | string,
    cookieTag?:string,
    defaultChecked?:boolean,
    onSubmit?:(evt: ConfirmationDialogEvent)=>void,
    onCancel?:(evt: ConfirmationDialogEvent)=>void,

  } = {
    showWindow:1,
    header:"",
    children:(<></>),
    defaultChecked:false,
    cookieTag:"",
    onSubmit: (evt: ConfirmationDialogEvent) => {return;},
    onCancel: (evt: ConfirmationDialogEvent) => {return;}
  }
  ) {
  const checkboxRef = useRef(null);
  const [checked, setChecked] = useState(false);

  useEffect(() => {
    if (props.cookieTag && props.cookieTag !== "") {
      if (Cookies.getCookieBoolean( props.cookieTag )) {
        if (props.onSubmit) {
        props.onSubmit({type: "auto", checked:true});
        }
      }
    }
    },
            [props.cookieTag]
    );

  function onSubmitHandler(e:React.MouseEvent<HTMLButtonElement, MouseEvent>) {
    if (checked && props.cookieTag && props.cookieTag !== "") {
      Cookies.setCookieBoolean( props.cookieTag , checked);
    }
    if (props.onSubmit) {
      props.onSubmit({type: "submit", checked: checked});
    }
  }

  function onCancelHandler() {
    if (props.onCancel) {
      props.onCancel({type: "cancel", checked: checked});
    }
  }

  function onCheckedChange(e: any) {
    setChecked(e.target.checked);
  }

  if (props.cookieTag && props.cookieTag !== "") {
    if (Cookies.getCookieBoolean( props.cookieTag )) {
      if (props.onSubmit) {
        return null;
      }
    }
  }

  
  if (props.showWindow) {
    return ReactDOM.createPortal(
      
      <AdvancedDragModal 
        variant={"NSDark"}
        PosDefault={{top:"40%", width:"450px", left:"50%", transform:"translate(-50%, -50%)"}}
        title={props.header && Localization.getText(props.header)}
        onClose={onCancelHandler}
      >
        <ADModalInfo>
          {props.children}
        </ADModalInfo>
      <ADModalBody >
        <></>
      </ADModalBody>
      <ADModalFooter >
         {(props.cookieTag && props.cookieTag !== "") ? (
            <GlassCheckbox onClick={onCheckedChange} style={{fontSize: "14px", marginRight:"auto"}}>
              {Localization.getText("Do not ask me again")}
            </GlassCheckbox>
         ) : null}
        
        <GlassButton onClick={(e) => onSubmitHandler(e)} >{Localization.getText("Submit")}</GlassButton>
        {/* <ProtectedFeature feature={Feature.ThemeNewSec} > */}
          <GlassButton onClick={(e) => onCancelHandler()} >{Localization.getText("Cancel")}</GlassButton>
        {/* </ProtectedFeature> */}
       </ADModalFooter>
      </AdvancedDragModal>
    
    ,document.getElementById("Mit-MapOverlay") || document.body);
  }
  return null;

}
/*



*/