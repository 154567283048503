import { MitLayerHandleMaplibre } from "./MapFacadeMaplibre";

export class CatchmentHelperMaplibre {

   static CATCHMENTSOURCEID="polygons";
   static CATCHMENTLAYERID="polygonLayer";
   static ROUTESSOURCEID="routes";
   static ROUTESLAYERID="routesLayer";

   static createLayerForPolygons(polygons: unknown, catchmentState: { travelTimes: number[], colorScale: string[], catchmentIn3d: boolean, heightFactor:number }) {
      let layer = new MitLayerHandleMaplibre();
      layer.addSource(CatchmentHelperMaplibre.CATCHMENTSOURCEID, CatchmentHelperMaplibre.createCatchmentSource(CatchmentHelperMaplibre.CATCHMENTSOURCEID, polygons!));
      layer.addLayer(CatchmentHelperMaplibre.createCatchmentLayer(CatchmentHelperMaplibre.CATCHMENTLAYERID, CatchmentHelperMaplibre.CATCHMENTSOURCEID, catchmentState));
      return layer;
   }

   static createCatchmentSource(id: string, polygons: any) {
      return {
         type: "geojson",
         data: polygons
      };
   }

   static createCatchmentLayer(id: string, source: string, catchmentState: { travelTimes: number[], colorScale: string[], catchmentIn3d: boolean, heightFactor:number }) {
      function createColorTable(cState: { travelTimes: number[], colorScale: string[] }): any[] {
         return cState.travelTimes.reduce<any[]>((prev, time, index) => {
            let color = cState.colorScale[index];
            return [...prev,
            ["<=", ["get", "time"], time], color
            ]
         }, []);
      }
      // height stops function
      function getHeightPolygons(travelTimes, heightFactor) {
         return [
            [travelTimes[0], travelTimes.length * (10 * heightFactor)],
            [travelTimes[travelTimes.length - 1], travelTimes.length * heightFactor]
         ]
      }

      // color stop function
      function getColorPolygons(times, colors) {
         const colorsConfig = times.map((time, idx) => {
            return [times[idx], colors[idx]];
         });
         return colorsConfig;
      }

      if (catchmentState.catchmentIn3d) {
               // 3d polygons
               return {
                  'id': 'polygons',
                  'type': 'fill-extrusion',
                  'layout': {},
                  'source': source,
                  'paint': {
                     'fill-extrusion-base': 0,
                     'fill-extrusion-height': {
                        'property': 'time',
                        'stops': getHeightPolygons(catchmentState.travelTimes, catchmentState.heightFactor) // 40 seems a good number
                     },
                     'fill-extrusion-color': {
                        'property': 'time',
                        'stops': getColorPolygons(catchmentState.travelTimes, catchmentState.colorScale)
                     },
                     'fill-extrusion-opacity': 0.7 // to reduce flickering
                  }
               }

      } else{
         // simple area polygons
         return {
            id: id,
            type: 'fill',
            source: source,
            layout: {
            },
            paint: {
               'fill-color': [ 'case', 
                  ...createColorTable(catchmentState),
               'cyan' ]
            }
          };

      }
   }

   static createRouteLayer(id: string, source: string, options:any) {
      return {
      id: id,
      type: 'line',
      source: source,
      layout: {
          'line-join': 'round',
          'line-cap': 'round'
      },
      paint: {
          'line-color': [
              'match',
              ['get', 'travelType'],
              'TRANSIT', 'red',
              'WALK', 'green',
              'gray'
          ],
          'line-width': 4
      },
      filter: ['==', ['geometry-type'], 'LineString']
          };

      }

      static createRouteLabelLayer(id: string, source: string, options:any) {
         return {
            "id": id,
            "type": "symbol",
            "source": source,
            "layout": {
                "symbol-placement": "line-center",
                "text-field": ['concat', ['get', 'travelType'], ' ', ['get', 'routeShortName'] ],
                'text-font': ["Noto Sans Regular"],
                "text-size": 16,
                'text-allow-overlap': true,
                'text-ignore-placement': true
            },
            "paint": {},
            filter: ['==', ['geometry-type'], 'LineString']
         };
      }

      

   static createLayerForRoutes(routes: any, options: any) {
      let layer = new MitLayerHandleMaplibre();
      layer.addSource(CatchmentHelperMaplibre.ROUTESSOURCEID, CatchmentHelperMaplibre.createCatchmentSource(CatchmentHelperMaplibre.ROUTESSOURCEID, routes[0]));
      layer.addLayer(CatchmentHelperMaplibre.createRouteLayer(CatchmentHelperMaplibre.ROUTESLAYERID, CatchmentHelperMaplibre.ROUTESSOURCEID, {}));
      layer.addLayer(CatchmentHelperMaplibre.createRouteLabelLayer(CatchmentHelperMaplibre.ROUTESLAYERID+"Label", CatchmentHelperMaplibre.ROUTESSOURCEID, {}));
      return layer;
   }
}