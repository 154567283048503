import {SheetAnalysisResult, SheetAnalysisResultColumn, 
  ColumnMapping, LayerType, LayerInfo, MitDataType,
  
  DawaAutoCompleteResult, DawaAddressLookupResult, LabelTabStylingList} from '../common/managers/Types';
  import {Utils} from '@viamap/viamap2-common';
  import { Localization } from "@viamap/viamap2-common";
  import * as Turf from "@turf/turf";
  import { MitLatLng } from './MapFacade';
 
  
  
  type downloadOptions = {
    filename?:string,
    forceGeoJson?: boolean,
  }
  export class LayerFunc {
    static createLayerInfoForManyPoints(title:string, points:{name:string, latlng:MitLatLng, properties:{}}[]):LayerInfo {
      
      let layerName=title;
      
      
      let variablePropertyColumnNames:string[]= [];
      let variablePropertyHeaders:{}[] = [];
      let variablePropertyColumns:SheetAnalysisResultColumn[] = [];
      let variablePropertyColumnMapping = {};
      let variablePropertyData= {};
      let propertyKeys = points.reduce<string[]>((prev, curr, idx) => {
        return [...new Set([...prev, ...Object.keys(curr.properties)])];
      }, []);
      if (propertyKeys.length > 0) {
        // Mapping for variable number of for properties
        [ // MitDataType.Label,
        MitDataType.Label2,
        MitDataType.Label3,
        MitDataType.Label4,
        MitDataType.Label5,
        MitDataType.Label6,
        MitDataType.Label7,
        MitDataType.Label8,
        MitDataType.Label9,
        MitDataType.Label10,
        MitDataType.Label11,
        MitDataType.Label12,
        MitDataType.Label13,
        MitDataType.Label14,
        MitDataType.Label15,
        MitDataType.Label16,
        MitDataType.Label17,
        MitDataType.Label18,
        MitDataType.Label19,
        MitDataType.Label20,
      ].forEach((obj,idx) => {
        if (idx < propertyKeys.length) {
          let propKey = propertyKeys[idx];
          let val = points.map((val) => { return ""+val.properties[propKey]; });
          variablePropertyColumnMapping[obj]=idx+4;
          variablePropertyData[obj] = val;
          variablePropertyColumnNames.push(propKey);
          let columnLetter = Utils.toColumnNameZeroBased(idx+3);
          variablePropertyHeaders.push({"label":columnLetter+" "+propKey,"value":idx+3});
          variablePropertyColumns.push({ 
            columnLetter:columnLetter,
            name:propKey,
            firstRowsAsString:[], // data for first 10 rows
            dataType:"string",
            minValue:1, // Of first 10 rows
            maxValue:1, // Of first 10 rows
            mitDataType: MitDataType.String
          });
        }
      });
    }
    
    // See if we can bypass fieldselection and create the map automatically
    let sa:SheetAnalysisResult = {
      noOfRows:1,
      noOfColumns:5,
      headers:[
        {"label":"A Lat","value":0},
        {"label":"B Lng","value":1},
        {"label":"C Navn","value":2},
        ...variablePropertyHeaders
      ],
      columnNames:[
        "Lat",
        "Lng", 
        Localization.getText("Name"),
        ...variablePropertyColumnNames
      ],
      columns: [    
        { 
          columnLetter:"A",
          name:"lat",
          firstRowsAsString:[], // data for first 10 rows
          dataType:"number",
          minValue:1, // Of first 10 rows
          maxValue:1, // Of first 10 rows
          mitDataType: MitDataType.Coord_WGS84_Lat
        },
        { 
          columnLetter:"B",
          name:"lng",
          firstRowsAsString:[], // data for first 10 rows
          dataType:"number",
          minValue:1, // Of first 10 rows
          maxValue:1, // Of first 10 rows
          mitDataType: MitDataType.Coord_WGS84_Lon
        },
        { 
          columnLetter:"C",
          name:"navn",
          firstRowsAsString:[], // data for first 10 rows
          dataType:"string",
          minValue:1, // Of first 10 rows
          maxValue:1, // Of first 10 rows
          mitDataType: MitDataType.String
        },
        ...variablePropertyColumns
      ]
    };
    
    // Mapping to column index (0 = Column A, 1 = Column B, ...)
    let columnMapping:ColumnMapping = { [MitDataType.Coord_WGS84_Lat]: 1, [MitDataType.Coord_WGS84_Lon]: 2, [MitDataType.Label]:3, ...variablePropertyColumnMapping};
    
    let data = { 
      [MitDataType.Coord_WGS84_Lat]: points.map((val) => { return val.latlng.lat; }), 
      [MitDataType.Coord_WGS84_Lon]: points.map((val) => { return val.latlng.lng; }),
      [MitDataType.Label]: points.map((val) => { return val.name; }),
      ...variablePropertyData };
      
      let st:LabelTabStylingList = [{
        title : "",
        labels:{
          labelDataList:[
            {
              labelDataColumn: 2,
              labelText: Localization.getText("Name"),
            }
          ]
        }
        
      }];
      
      const layerInfo:LayerInfo = {   
        layerId: -1,
        datasetname: layerName,
        filename: "Manual Multi Point Layer",
        type: LayerType.GeoJSON_Point,
        readonly: false,
        hasNoDataBehind: true,
        columnMapping: columnMapping,
        analysisResult: sa,
        geoJson: Turf.featureCollection(points.map((a) => (Turf.point([a.latlng.lng, a.latlng.lat], {Name: a.name, ...a.properties})))),
        propertiesInGeoJson: Object.keys(points[0].properties || {}),
        data: data,
        styling: {},
        crs:4326
      };
      
      return layerInfo;
    }
    
    static createLayerInfoFromGeoJsonFeature(feature, title):LayerInfo {
      const fc = Turf.featureCollection([feature]);
      return {   
        layerId: -1,
        datasetname: title,
        type: LayerFunc.getTypeOfGeoJson([feature]),
        readonly: false,
        hasNoDataBehind: true,
        columnMapping: {["GeoJSON_Features_String"]:0},
        analysisResult: {noOfRows: 1, noOfColumns:1, headers:[], columnNames: ["coordinates"], columns:[]},
        styling: {},
        data: {["GeoJSON_Features_String"]:fc},
        visible: true,
        filename: "Manual GeoJsonLayer",
        geoJson: fc,
        propertiesInGeoJson: LayerFunc.extractPropertiesFromGeoJSON(fc),
        propertiesToDisplay: []
      };
    }
    
    static createLayerInfoFromGeoJsonFeatureCollection(featureCollection, title):LayerInfo {
      return {   
        layerId: -1,
        datasetname: title,
        type: LayerFunc.getTypeOfGeoJson(featureCollection.features),
        readonly: false,
        hasNoDataBehind: true,
        columnMapping: {["GeoJSON_Features_String"]:0},
        analysisResult: {noOfRows: featureCollection.features.length, noOfColumns:1, headers:[], columnNames: ["coordinates"], columns:[]},
        styling: {},
        data: {["GeoJSON_Features_String"]:featureCollection},
        visible: true,
        filename: "Manual GeoJsonLayer",
        geoJson: featureCollection,
        propertiesInGeoJson: LayerFunc.extractPropertiesFromGeoJSON(featureCollection),
        propertiesToDisplay: []
      };
    }
    
    static createLayerInfoFromGeoJsonFeatureList(featureList, title:string):LayerInfo {
      const fc = Turf.featureCollection(featureList);
      return {   
        layerId: -1,
        datasetname: title,
        filename: "Manual GeoJsonLayer",
        type: LayerFunc.getTypeOfGeoJson(featureList),
        readonly: false,
        hasNoDataBehind: true,
        columnMapping: {["GeoJSON_Features_String"]:0},
        styling: {},
        analysisResult: {noOfRows: featureList.length, noOfColumns:1, headers:[], columnNames: ["coordinates"], columns:[]},
        data:  {["GeoJSON_Features_String"]: fc},
        geoJson: fc,
        propertiesInGeoJson: LayerFunc.extractPropertiesFromGeoJSON(fc),
        propertiesToDisplay: []
      };
    }
    
    static extractPropertiesFromGeoJSON(featureCollection:any /*Turf.FeatureCollection*/):string[] {
      return featureCollection?.features?.[0].properties && Object.keys(featureCollection.features[0].properties) || [];
    }
    
    static isGeoJsonLayer(layerInfo):boolean {
      return Boolean(layerInfo.geoJson);
    }
    
    static getTypeOfGeoJson(featureList:any[] /*Turf.Feature[]*/) {
      let type = featureList[0].geometry.type
      switch (type) {
        case "Point":
        return LayerType.GeoJSON_Point
        case "Polygon":
        return LayerType.GeoJSON_Polygon
        case "LineString":
        return LayerType.GeoJSON_Line
        default:
        return LayerType.GeoJSON
      }
    }
    
    static createLayerInfoForOnePoint(latlng:MitLatLng, title:string, properties?:{}):LayerInfo {
      let layerName=title;
      
      const layerInfo:LayerInfo = {   
        layerId: -1,
        datasetname: layerName,
        filename: "Manual Point Layer",
        type: LayerType.GeoJSON_Point,
        readonly: false,
        hasNoDataBehind: true,
        columnMapping: {},
        analysisResult: {noOfRows: 1, noOfColumns: 10, headers:[], columnNames:[], columns:[]},
        data: {},
        geoJson: Turf.featureCollection([Turf.point([latlng.lng, latlng.lat], properties || {})]),
        propertiesInGeoJson: Object.keys(properties || {}),
        propertiesToDisplay: Object.keys(properties || {}).map((a) => ({name:a})),
        styling: {},
        crs:4326
      };
      
      return layerInfo;
    }
    
    static createLayerInfoForOneAddressV2(vejnavn:string, husnr:string, koordinater:number[]):LayerInfo {

      let layerName=vejnavn+" "+husnr;
      // See if we can bypass fieldselection and create the map automatically

      const layerInfo:LayerInfo = {   
        layerId: -1,
        datasetname: layerName,
        filename: "Address Search Result",
        type: LayerType.GeoJSON_Point,
        readonly: false,
        hasNoDataBehind: true,
        columnMapping: {},
        analysisResult: {noOfRows:1,noOfColumns:5,headers:[],columnNames:[],columns:[]},
        data: [],
        geoJson: Turf.featureCollection([Turf.point([...(koordinater) as number[]], {})]),
        styling: {},
        crs:4326
      };
      
      return layerInfo;
    }
    
    static createLayerInfoForOneAddress(addressInfo:DawaAutoCompleteResult, addressLookupResponse:DawaAddressLookupResult):LayerInfo {
      let layerName=addressInfo.data.vejnavn+" "+addressInfo.data.husnr;
      // See if we can bypass fieldselection and create the map automatically
      
      const layerInfo:LayerInfo = {   
        layerId: -1,
        datasetname: layerName,
        filename: "Address Search Result",
        type: LayerType.GeoJSON_Point,
        readonly: false,
        hasNoDataBehind: true,
        columnMapping: {},
        analysisResult: {noOfRows:1,noOfColumns:5,headers:[],columnNames:[],columns:[]},
        data: [],
        geoJson: Turf.featureCollection([Turf.point([...(addressLookupResponse.adgangspunkt.koordinater) as number[]], {})]),
        styling: {}
      };
      
      return layerInfo;
    }
    
    static createLayerInfoForOnePhoto(layerName:string, fileName:string, exifData:{lat:number, lon:number, timestamp:string, imageType:string, img:string}):LayerInfo {
      const defaultDisplay = ["Filename", "img", "timestamp"]
      const layerInfo:LayerInfo = {   
        layerId: -1,
        datasetname: layerName,
        filename: fileName,
        type: LayerType.GeoJSON_Point,
        geoJson: Turf.featureCollection([Turf.point([exifData.lon,exifData.lat],{Filename: fileName, ...exifData})]),
        readonly: false,
        hasNoDataBehind: true,
        columnMapping: {},
        propertiesInGeoJson: Object.keys({Filename: fileName, ...exifData}),
        propertiesToDisplay: defaultDisplay.map((a) => ({name: a})),
        analysisResult: {noOfRows:1,noOfColumns:1, headers: [],columnNames:[],columns:[]},
        styling: {},
      };
      
      return layerInfo;
    }
    
  }
  